import { PositionUI } from '../support/PositionUI'
import { ChargeFormUI } from './ChargeFormUI'

export class ChargeListUI extends PositionUI<ChargeFormUI> {
  constructor(
    public readonly personneId?: string,
    public readonly list: ChargeFormUI[] = [],
  ) {
    super(list)
  }

  public displayableCharge(): ChargeFormUI[] {
    return this.list
  }

  public clone() {
    return new ChargeListUI(this.personneId, [...this.list])
  }

  public async store(handleChargeFormUI: (personneId: string, charge: ChargeFormUI) => Promise<ChargeFormUI>) {
    const updatedCharges = await Promise.all(this.list.map(charge => handleChargeFormUI(this.personneId!, charge)))
    updatedCharges.forEach((updatedCharge, index) => this.update(index, updatedCharge))
  }
}
