import React, { FunctionComponent } from 'react'

import styles from '../Header.module.css'
import { getSaisieErrorMessage } from '../../../../utils'


interface SaisieCardErrorsProps {
  error: string
}

export const SaisieCardErrors: FunctionComponent<SaisieCardErrorsProps> = ({ error }) => (
  <p className={styles.header__contentItem}>
    {getSaisieErrorMessage(error)}
  </p>
)

