import React from 'react'
import { func, string } from 'prop-types'

import { ThreeDots } from '@mmb/ui-components'

import styles from '../MoreActions.module.css'

ShowActionsButton.propTypes = {
  onClick: func.isRequired,
  label: string,
}

ShowActionsButton.defaultProps = {
  label: 'Modification ou suppression d\'un utilisateur',
}

export function ShowActionsButton({ onClick, label }) {
  return (
    <button
      className={styles.moreActions__button}
      onClick={onClick}
      aria-label={label}
    >
      <ThreeDots className={styles.moreActions__threeDots} />
    </button>
  )
}
