import React from 'react'

import { ProtectionDonnees } from '@core/partenaire-frontend'
import { Layout } from '@partenaire/frontend-ui'

function ProtectionDesDonnees() {
  return (
    <Layout>
      <ProtectionDonnees />
    </Layout>
  )
}

export default ProtectionDesDonnees
