import { Component } from 'react'
import { func, node, string } from 'prop-types'

/**
 * Gestionnaire d'erreur qui va rediriger vers une page donnée en utilisant '@core/routing'.
 * Du fait de l'utilisation de '@core/routing' ce gestionnaire doit être positionné en dessous du routeur Gatsby pour que la redirection fonctionne
 * (donc sur les routes dynamiques et non pas dans wrap-with-state)
 */
export class RedirectErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.props.onComponentDidCatch(error, errorInfo)
    this.state.hasError = true
  }

  render() {
    if (this.state.hasError) {
      this.props.navigate(this.props.url)
      return null
    }
    return this.props.children
  }
}
RedirectErrorBoundary.propTypes = {
  children: node.isRequired,
  onComponentDidCatch: func,
  navigate: func,
  url: string,
}

RedirectErrorBoundary.defaultProps = {
  url: '/service-indisponible',
  onComponentDidCatch: () => {},
  navigate: () => {},
}

