import { SupportStateUI } from '../support/SupportStateUI'
import { HandleStoreRevenuFormUI } from './HandlersUI'
import { getDefaultRevenuFormUI, RevenuFormUI, TypeRevenu } from './RevenuFormUI'
import { RevenuListUI } from './RevenuListUI'
import { ALLOCATION_FAMILIALE_BELOW_2_CHILDREN, ALLOCATION_FAMILIALE_2_CHILDREN, ALLOCATION_FAMILIALE_ABOVE_2_CHILDREN } from './AllocationsFamiliales'

export class RevenusUI extends SupportStateUI {
  private hasPalier?: boolean

  private handleStoreRevenuFormUI: HandleStoreRevenuFormUI

  constructor(
    public readonly emprunteurRevenus: RevenuListUI,
    public readonly coEmprunteurRevenus: RevenuListUI,
    public readonly hasCoEmprunteur: boolean = false,
    hasPalier: boolean = false,
    private nombreEnfantsACharge: number = 0) {
    super()
    this.hasPalier = hasPalier
  }

  protected clone() {
    return new RevenusUI(
      this.emprunteurRevenus.clone(),
      this.coEmprunteurRevenus.clone(),
      this.hasCoEmprunteur,
      this.hasPalier,
      this.nombreEnfantsACharge,
    ).withUpdateState(this.setState).withHandleStoreRevenu(this.handleStoreRevenuFormUI)
  }

  public async store(): Promise<void> {
    await this.emprunteurRevenus.store(this.handleStoreRevenuFormUI!, this.hasPalier!)
    if (this.hasCoEmprunteur) {
      await this.coEmprunteurRevenus.store(this.handleStoreRevenuFormUI!, this.hasPalier!)
    }
    this.refreshState()
  }

  public getTotalRevenus(): number {
    return [...this.emprunteurRevenus.list, ...this.coEmprunteurRevenus.list]
      .map(revenu => revenu.montant)
      .map(montant => Number(montant) || 0)
      .reduce((total, montant) => total + Number(montant), 0)
    + this.getMontantAllocationFamiliales()
  }

  public toogleBaisseRevenu(isSecured: boolean = true, toogleOnEmprunteur: boolean = true): string | undefined {
    const id = this.selectRevenusListUI(toogleOnEmprunteur).toogleBaisseRevenu(isSecured)
    this.refreshState()
    return id
  }

  public update(index: number, revenu: RevenuFormUI, updateOnEmprunteur: boolean = true): void {
    this.selectRevenusListUI(updateOnEmprunteur).update(index, revenu)
    this.refreshState()
  }

  public updatePalier(isAmortissementPalier: boolean) {
    this.hasPalier = isAmortissementPalier && this.hasBaisseRevenu()
    this.refreshState()
  }

  public updateBaisseRevenu(value: string, updateOnEmprunteur: boolean): void {
    this.selectRevenusListUI(updateOnEmprunteur).updateBaisseRevenu(value)
    this.refreshState()
  }

  public add(addOnEmprunteur: boolean = true) {
    this.selectRevenusListUI(addOnEmprunteur).add(getDefaultRevenuFormUI())
    this.refreshState()
  }

  public delete(index: number, deleteOnEmprunteur: boolean = true): string | undefined {
    const id = this.selectRevenusListUI(deleteOnEmprunteur).list[index]?.id
    this.selectRevenusListUI(deleteOnEmprunteur).delete(index)
    this.refreshState()
    return id
  }

  public static isListable(revenu: RevenuFormUI) : boolean {
    return revenu.type !== TypeRevenu.ALLOCATIONS_FAMILIALES
  }

  public getMontantAllocationFamiliales() : number {
    if (this.nombreEnfantsACharge < 2) {
      return ALLOCATION_FAMILIALE_BELOW_2_CHILDREN
    }
    if (this.nombreEnfantsACharge === 2) {
      return ALLOCATION_FAMILIALE_2_CHILDREN
    }
    return ALLOCATION_FAMILIALE_ABOVE_2_CHILDREN
  }

  public hasBaisseRevenu(): boolean {
    return this.emprunteurRevenus.hasBaisseRevenu() || (this.hasCoEmprunteur && this.coEmprunteurRevenus.hasBaisseRevenu())
  }

  public withHandleStoreRevenu(handleStoreRevenu: HandleStoreRevenuFormUI): RevenusUI {
    this.handleStoreRevenuFormUI = handleStoreRevenu
    return this
  }

  private selectRevenusListUI(emprunteur: boolean = true) {
    return emprunteur ? this.emprunteurRevenus : this.coEmprunteurRevenus
  }

  public containsType(type: TypeRevenu) : boolean {
    return Boolean([...this.emprunteurRevenus.list, ...this.coEmprunteurRevenus.list].find(revenu => revenu.type === type))
  }

  public setNombreEnfantsACharge(nombreEnfantsACharge: number) {
    this.nombreEnfantsACharge = nombreEnfantsACharge
    this.refreshState()
  }
}
