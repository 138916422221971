import { NatureCharge } from '@partenaire/common'
import { TOOLTIP_INFORMATIONS, TooltipUI } from './TooltipUI'
import { CHARGE_OPTIONS, ChargeOptionUI } from './ChargeOptionUI'
import { PositionableFormUI } from '../support/PositionableFormUI'


export const HAS_LOYER_FICTIF = (nature : NatureCharge | undefined) => (nature && nature === NatureCharge.LOYER_FICTIF)

export class ChargeFormUI implements PositionableFormUI {
  public readonly id?: string

  public readonly montant?: number

  public readonly nature?: NatureCharge

  public position?: number | undefined

  private constructor(id?: string, montant?: number, nature?: NatureCharge, position?: number) {
    this.id = id
    this.montant = montant
    this.nature = nature
    this.position = position
  }

  public getTooltip(): TooltipUI | undefined {
    return TOOLTIP_INFORMATIONS.find(({ nature }) => nature === this.nature)
  }

  public static buildChargeFormUI({ id, montant, nature, position }: { id?: string, montant?: number, nature?: NatureCharge, position?: number }) {
    if (HAS_LOYER_FICTIF(nature) && montant === 0) {
      return new ChargeFormUI(id, 500, nature, position)
    }
    return new ChargeFormUI(id, montant, nature, position)
  }

  public getChargeOption(): ChargeOptionUI | undefined {
    return CHARGE_OPTIONS.find(({ value: nature }) => nature === this.nature)
  }
}

export const DEFAULT_CHARGE: ChargeFormUI = ChargeFormUI.buildChargeFormUI({ montant: 0 })
