import { isNil } from 'lodash'

export interface Palier {
  dureeEnMois: number
  echeance: number
}
export interface PlanFinancement {
  duree: number
  listPalier: Palier[]
  mensualite: number
  mensualiteAvecAssurance: number
  taeg: number
  taux: number
  usure: number
}

export interface Dossier {
  id?: string
  planFinancement : PlanFinancement
}

export interface PlanFinancementProps {
    planFinancement : PlanFinancement
}

export enum NaturePret {
  PRET_IMMOBILIER_HYPOTHECAIRE = 'PRET_IMMOBILIER_HYPOTHECAIRE',
  PRET_IMMOBILIER_NON_HYPOTHECAIRE = 'PRET_IMMOBILIER_NON_HYPOTHECAIRE',
  PRET_TAUX_ZERO_HYPOTHECAIRE = 'PRET_TAUX_ZERO_HYPOTHECAIRE',
  PRET_TAUX_ZERO_NON_HYPOTHECAIRE = 'PRET_TAUX_ZERO_NON_HYPOTHECAIRE',
  PRET_DE_RESTRUCTURATION_HYPOTHECAIRE = 'PRET_DE_RESTRUCTURATION_HYPOTHECAIRE',
  PRET_DE_RESTRUCTURATION_NON_HYPOTHECAIRE = 'PRET_DE_RESTRUCTURATION_NON_HYPOTHECAIRE',
  LOCATION_AVEC_OPTION_D_ACHAT = 'LOCATION_AVEC_OPTION_D_ACHAT',
  PRET_EMPLOYEUR = 'PRET_EMPLOYEUR',
  PRET_PATRONAL = 'PRET_PATRONAL',
  PRET_PERSONNEL_NON_HYPOTHECAIRE = 'PRET_PERSONNEL_NON_HYPOTHECAIRE',
  PRET_PERSONNEL_HYPOTHECAIRE = 'PRET_PERSONNEL_HYPOTHECAIRE',
  PRET_TRAVAUX_NON_HYPOTHECAIRE = 'PRET_TRAVAUX_NON_HYPOTHECAIRE',
  PRET_TRAVAUX_HYPOTHECAIRE = 'PRET_TRAVAUX_HYPOTHECAIRE',
  PRET_VOITURE = 'PRET_VOITURE',
  CREDIT_RENOUVELABLE = 'CREDIT_RENOUVELABLE',
  DECOUVERT = 'DECOUVERT',

  DETTE_FISCALE = 'DETTE_FISCALE',
  DETTE_PRIVEE = 'DETTE_PRIVEE',
  DETTE_HUISSIER = 'DETTE_HUISSIER',
  RETARD_DE_LOYER = 'RETARD_DE_LOYER',
  RETARD_DE_CHARGES_DE_COPRO = 'RETARD_DE_CHARGES_DE_COPRO',
  DETTE_SOCIETE_DE_RECOUVREMENT = 'DETTE_SOCIETE_DE_RECOUVREMENT',
  SOULTE = 'SOULTE',
}

export const NATURE_PRET_MAP = new Map<NaturePret | undefined, string>([
  [NaturePret.PRET_IMMOBILIER_HYPOTHECAIRE, 'Prêt immobilier hypothécaire'],
  [NaturePret.PRET_IMMOBILIER_NON_HYPOTHECAIRE, 'Prêt immobilier non hypothécaire'],
  [NaturePret.PRET_TAUX_ZERO_HYPOTHECAIRE, 'Prêt taux zéro hypothécaire'],
  [NaturePret.PRET_TAUX_ZERO_NON_HYPOTHECAIRE, 'Prêt taux zéro non hypothécaire'],
  [NaturePret.PRET_DE_RESTRUCTURATION_HYPOTHECAIRE, 'Prêt de restructuration hypothécaire'],
  [NaturePret.PRET_DE_RESTRUCTURATION_NON_HYPOTHECAIRE, 'Prêt de restructuration non hypothécaire'],
  [NaturePret.LOCATION_AVEC_OPTION_D_ACHAT, 'Location avec option d\'achat'],
  [NaturePret.PRET_EMPLOYEUR, 'Prêt employeur'],
  [NaturePret.PRET_PATRONAL, 'Prêt patronal'],
  [NaturePret.PRET_PERSONNEL_NON_HYPOTHECAIRE, 'Prêt personnel non hypothécaire'],
  [NaturePret.PRET_PERSONNEL_HYPOTHECAIRE, 'Prêt personnel hypothécaire'],
  [NaturePret.PRET_TRAVAUX_NON_HYPOTHECAIRE, 'Prêt travaux non hypothécaire'],
  [NaturePret.PRET_TRAVAUX_HYPOTHECAIRE, 'Prêt travaux hypothécaire'],
  [NaturePret.PRET_VOITURE, 'Prêt voiture'],
  [NaturePret.CREDIT_RENOUVELABLE, 'Crédit renouvelable'],
  [NaturePret.DECOUVERT, 'Découvert'],

  [NaturePret.DETTE_FISCALE, 'Dette fiscale'],
  [NaturePret.DETTE_PRIVEE, 'Dette privée'],
  [NaturePret.DETTE_HUISSIER, 'Dette huissier'],
  [NaturePret.RETARD_DE_LOYER, 'Retard de loyer'],
  [NaturePret.RETARD_DE_CHARGES_DE_COPRO, 'Retard de charges de copro'],
  [NaturePret.DETTE_SOCIETE_DE_RECOUVREMENT, 'Dette société de recouvrement'],
  [NaturePret.SOULTE, 'Soulte'],
  [NaturePret.DECOUVERT, 'Découvert'],
])

export class Pret {
  public cdcAvecIra: string

  public taux: string

  public echeance: string

  public nature: string

  constructor(private crd: number,
    private ira: number,
    private tauxNumber: number,
    private echeanceNumber: number,
    public repris: boolean,
    private natureEnum: NaturePret,
    public nomPreteur : string) {
    this.cdcAvecIra = !isNil(this.crd) ? `${Pret.formatMontant(this.crd + (this.ira ?? 0))} €` : ''
    this.taux = tauxNumber ? `${Pret.formatMontant(this.tauxNumber)}%` : ''
    this.echeance = this.echeanceNumber ? `${Pret.formatMontant(this.echeanceNumber)} €` : ''
    this.nature = NATURE_PRET_MAP.get(this.natureEnum) || ''
  }


  private static formatMontant = (montant: number) : string => montant.toLocaleString('fr', { minimumFractionDigits: 2 })
}

