import React, { useState } from 'react'
import { func, node, string } from 'prop-types'
import cn from 'classnames'

import { useUtilisateurContext } from '@core/partenaire-common'
import { PORTAIL_CGP, PORTAIL_PARTENAIRE, capitalize } from '@core/common'
import { useIzanamiFeatureByName, useIzanamiConfigurationByName, Link, BandeauInfo } from '@core/ui'
import { Avatar, Popover } from '@mmb/ui-components'
import { WithPortals } from '../WithPortals'

import { Header } from '../../components/Header'
import { Menu } from '../../components/Menu'
import { TopBar } from '../../components/TopBar'

import styles from './Layout.module.css'
import { SelectionCabinetHeader, SelectionCabinetModale } from '../SelectionCabinet'
import { MonProfil, Deconnexion } from './UserActions'

Layout.propTypes = {
  children: node,
  className: string,
  menuLinks: func.isRequired,
}

Layout.defaultProps = {
  children: null,
  className: '',
}

export function Layout({ children, className, menuLinks: MenuLinks }) {
  const { loggedIn, nom, prenom, idCabinetTravail, cabinets } = useUtilisateurContext()
  const [isOpen, setOpen] = useState(false)
  const fullName = nom ? `${capitalize(prenom)} ${nom.toUpperCase()}` : ''
  const libelleCabinet = idCabinetTravail ? cabinets.find(c => c.id === idCabinetTravail).libelle : null
  const { configuration } = useIzanamiConfigurationByName('contenu-bandeau-info')
  const { active: activeBandeauInfo, firstFetchPending } = useIzanamiFeatureByName('bandeau-info')
  const displayBandeau = activeBandeauInfo && !firstFetchPending


  return (
    <div className={cn(styles.layout, { [styles.layout__with_bandeau]: displayBandeau })}>
      <BandeauInfo className={styles.layout__bandeau} message={configuration?.contenu} />
      <Header className={cn(styles.layout__header, { [styles.layout__header__with_bandeau]: displayBandeau })}>
        <TopBar>
          {loggedIn ? (
            <>
              <WithPortals portals={[PORTAIL_PARTENAIRE, PORTAIL_CGP]}>
                <Link className={styles.layout__link} to="/app/contact/">Contact</Link>
              </WithPortals>
              <WithPortals portals={[PORTAIL_CGP]}>
                <a
                  href="https://zme93g.axshare.com/#id=cfhosb&p=bienvenue_sur_l_espace_formation_cgp&c=En1"
                  className={styles.layout__link}
                  target="_blank"
                  rel="noreferrer"
                >
                  Aide
                </a>
              </WithPortals>
              <Popover
                title="Actions utilisateur"
                open={isOpen}
                className={styles.layout__popover}
                content={(
                  <nav className={styles.layout__content}>
                    <WithPortals portals={[PORTAIL_CGP]}>
                      <SelectionCabinetHeader setOpen={setOpen} />
                    </WithPortals>
                    <MonProfil setOpen={setOpen} />
                    <Deconnexion setOpen={setOpen} />
                  </nav>
                )}
              >
                <button className={styles.layout__infos} onClick={() => setOpen(!isOpen)}>
                  <Avatar className={styles.layout__avatar} />
                  <span>{fullName}</span>
                  <WithPortals portals={[PORTAIL_CGP]}>
                    {libelleCabinet &&
                      (<span> {`- ${libelleCabinet}`}</span>)}
                  </WithPortals>
                </button>
              </Popover>
            </>
          ) : (
            <Link className={styles.layout__link} to="/app/connexion">Connexion</Link>
          )}
        </TopBar>

        {loggedIn && (
          <Menu linkComponent={Link}>
            <MenuLinks />
          </Menu>
        )}

      </Header>
      <div className={cn(styles.layout__body, { [styles.layout__body__with_bandeau]: displayBandeau }, className)}>
        {children}
      </div>
      <WithPortals portals={[PORTAIL_CGP]}>
        <SelectionCabinetModale />
      </WithPortals>
    </div>
  )
}
