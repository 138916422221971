import React from 'react'

import { PageBanner } from '@core/ui'

import { UnauthenticatedLayout } from '../UnauthenticatedLayout'
import { SignUpConfirmation } from './Contents/SignUpConfirmation'
import { SignUpForm } from './Contents/SignUpForm'
import { useCreateAccount } from './SignUp.hooks'
import styles from './SignUp.module.css'

export function SignUp() {
  const { email, sent, submitSignup, showErrors, errors, setCaptcha, signUpErrors, form, setFormFieldFromEvent } = useCreateAccount()

  return (
    <UnauthenticatedLayout className={styles.signUp__layout}>
      <PageBanner className={styles.signUp__banner}>
        Création de votre compte
      </PageBanner>
      <div className={styles.signUp__body}>
        {sent ? (<SignUpConfirmation email={email} />) : (
          <form onSubmit={submitSignup}>
            <SignUpForm
              email={email}
              showErrors={showErrors}
              setCaptcha={setCaptcha}
              errors={errors}
              signUpErrors={signUpErrors}
              form={form}
              setFormFieldFromEvent={setFormFieldFromEvent}
            />
          </form>
        )}
      </div>
    </UnauthenticatedLayout>
  )
}
