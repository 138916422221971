import { SupportStateUI } from './SupportStateUI'

export abstract class CrudUI<T> extends SupportStateUI {
  constructor(public readonly list: T[]) {
    super()
  }

  public addAll(items: T[]): void {
    this.list.push(...items)
    this.refreshState()
  }

  public add(item: T): void {
    this.list.push({ ...item })
    this.refreshState()
  }

  public update(index: number, item: T): T {
    this.list[index] = item
    this.refreshState()
    return item
  }

  public delete(index: number): void {
    if (index + 1 <= this.list.length) {
      this.list.splice(index, 1)
      this.refreshState()
    }
  }
}
