import { isValidDate, parseDate } from '@core/common'
import { isAfter } from 'date-fns'
import { ErrorMessage } from '../../components/SaisieErrorMessages'
import { RevenuFormUI, RevenuListUI, RevenusUI, TypeRevenu, buildValidationError } from '../../model'
import { DATE_MM_YYYY } from '../../model/format'
import { BaisseRevenuUI, buildDefaultRevenu } from '../../model/revenus/BaisseRevenuUI'

export function validateRevenu(revenu: RevenuFormUI) {
  const errors: any[] = []
  if (!revenu?.type) {
    errors.push(buildValidationError('nature', 'WRONG_VALUE'))
  } else if (revenu.type !== TypeRevenu.RETRAITE && revenu?.debut) {
    errors.push(buildValidationError('nature', 'INVALID_VALUE'))
  }
  if (!revenu?.montant || (revenu?.montant === 0)) {
    errors.push(buildValidationError('montant', 'WRONG_VALUE'))
  }
  if (revenu?.montant) {
    if (revenu.montant < 0) {
      errors.push(buildValidationError('montant', 'NEGATIVE_VALUE'))
    }
    if (revenu.montant >= 10_000_000) {
      errors.push(buildValidationError('montant', 'INVALID_VALUE'))
    }
  }
  return errors
}

const hasRevenuLowertoBaisse = (valideRevenus: RevenuFormUI[], baisseRevenu: BaisseRevenuUI) => (
  valideRevenus.some(revenu => (
    !!revenu?.montant && !!baisseRevenu?.montant && revenu?.montant < baisseRevenu?.montant
  ))
)

export function validateBaisseRevenu(revenuListUI: RevenuListUI, isSecured: boolean = true): any[] {
  if (revenuListUI.hasBaisseRevenu()) {
    const errors: any[] = []
    const valideRevenus = revenuListUI.list.filter(r => ['SALAIRE', 'BIC_BNC'].includes(r.type?.toString() || ''))
    const baisseRevenu = revenuListUI.getBaisseRevenu()!
    const dateDebutReference = buildDefaultRevenu(isSecured).debut!
    if (!baisseRevenu.montant || baisseRevenu.montant === 0) errors.push(buildValidationError('montant', 'WRONG_VALUE'))
    if (baisseRevenu.debut && !isValidDate(baisseRevenu.debut, DATE_MM_YYYY)) {
      errors.push(buildValidationError('debut', 'DATE_FORMAT_INVALID'))
    }
    if (!baisseRevenu.debut || isAfter(parseDate(dateDebutReference, DATE_MM_YYYY)!, parseDate(baisseRevenu?.debut, DATE_MM_YYYY)!)) {
      errors.push(buildValidationError('debut', isSecured ? 'WRONG_VALUE_DCS' : 'WRONG_VALUE_DCU'))
    }
    if (!valideRevenus.length) errors.push(buildValidationError('baisse', 'WRONG_VALUE'))
    if (hasRevenuLowertoBaisse(valideRevenus, baisseRevenu)) errors.push(buildValidationError('montant', 'WRONG_REVENU'))
    return errors
  }
  return []
}

function validateGlobalRevenus(revenusUI: RevenusUI, otherErrors: any[]) {
  if (revenusUI.emprunteurRevenus.list.length + revenusUI.coEmprunteurRevenus.list.length === 0) {
    return [buildValidationError('revenus', 'REVENU_REQUIRED')]
  }

  return otherErrors
}

export function validateRevenus({ revenusUI, isSecured }:
  { revenusUI: RevenusUI, isSecured: boolean}) {
  const emprunteurErrors = revenusUI.emprunteurRevenus.list.map(revenu => validateRevenu(revenu))
  const coEmprunteurErrors = revenusUI.coEmprunteurRevenus.list.map(revenu => validateRevenu(revenu))
  const baisseRevenuEmprunteurErrors = validateBaisseRevenu(revenusUI.emprunteurRevenus, isSecured)
  const baisseRevenuCoEmprunteurErrors = validateBaisseRevenu(revenusUI.coEmprunteurRevenus, isSecured)
  const globalErrors = validateGlobalRevenus(revenusUI, [
    ...(emprunteurErrors?.flatMap(p => p) || []),
    ...(coEmprunteurErrors?.flatMap(p => p) || []),
    ...baisseRevenuEmprunteurErrors,
    ...baisseRevenuCoEmprunteurErrors,
  ])
  const hasErrors = (
    (globalErrors.length || 0) +
    (emprunteurErrors.flatMap(p => p)?.length || 0) +
    (coEmprunteurErrors.flatMap(p => p)?.length || 0) +
    (baisseRevenuEmprunteurErrors.length || 0) +
    (baisseRevenuCoEmprunteurErrors.length || 0)
  ) > 0

  return {
    hasErrors,
    globalErrors,
    emprunteurErrors,
    coEmprunteurErrors,
    baisseRevenuEmprunteurErrors,
    baisseRevenuCoEmprunteurErrors,
  }
}

export function getRevenuErrorMessage(error) : ErrorMessage|null {
  if (error.message === 'WRONG_VALUE') {
    if (error.field === 'nature') {
      return { title: 'nature', message: 'La nature des revenus ajoutés doit être renseignée.' }
    }
    if (error.field === 'montant') return { title: 'montant', message: 'Le montant des revenus ajoutés doit être renseigné.' }
    if (error.field === 'baisse') {
      return { title: 'Baisse de revenu', message: 'La baisse de revenu est possible si le client a un revenu de type Salaire ou BIC/BNC.' }
    }
  }
  if (error.message === 'INVALID_VALUE') {
    let message
    if (error.field === 'montant') {
      message = 'Le montant du revenu n’est pas valide.'
    } else if (error.field === 'nature') {
      message = 'Un revenu a une date de début dans le futur. Merci de le supprimer.'
    }
    return { title: 'Revenu', message }
  }

  if (error.field === 'debut') {
    if (error.message === 'WRONG_VALUE_DCU') {
      return { title: 'date début', message: 'La date de début du premier palier doit être supérieure à 13 mois pour une demande en Sans garantie' }
    }
    if (error.message === 'WRONG_VALUE_DCS') {
      return { title: 'date début', message: 'La date de début du premier palier doit être supérieure à 15 mois pour une demande en Hypothécaire' }
    }
    if (error.message === 'DATE_FORMAT_INVALID') {
      return { title: 'date début', message: `Veuillez renseigner une date au format ${DATE_MM_YYYY.toLowerCase()}` }
    }
  }
  if (error.message === 'REVENU_REQUIRED') return { title: 'Information requise', message: 'Veuillez renseigner a minima un revenu pour le foyer.' }
  if (error.message === 'WRONG_ALLOCATION') {
    return {
      title: 'Revenu',
      // eslint-disable-next-line max-len
      message: 'Au vu des enfants mineurs à charge, vous devez modifier les allocations familiales : pour 1 enfant = 0 €, pour 2 enfants = 140 €, pour 3 enfants et plus = 319 €.',
    }
  }
  if (error.message === 'WRONG_REVENU' && error.field === 'montant') {
    return { title: 'Baisse de revenu', message: 'Le montant prévisionnel de revenu ne peut pas être supérieur au revenu actuel saisi.' }
  }
  return null
}
