export enum TransmissionStatut {
    DOSSIER_INVALIDE = 'DOSSIER_INVALIDE',
    EN_ATTENTE = 'EN_ATTENTE',
    EN_ERREUR = 'EN_ERREUR',
    TRANSMIS = 'TRANSMIS',
    NOT_STARTED = 'NOT_STARTED'
}

export enum TransmissionInvalidDetail {
    DOSSIER_INVALIDE = 'DOSSIER_INVALIDE',
    SITUATION_MARITALE_INVALIDE = 'SITUATION_MARITALE_INVALIDE',
}
