import React from 'react'
import { UnavailableService } from '@mmb/ui-components'
import { UnauthenticatedLayout } from '@core/partenaire-frontend'

import { Head } from '../components/Head'

import styles from './service-indisponible.module.css'

const UnavailableServicePage = () => (
  <>
    <Head />
    <UnauthenticatedLayout>
      <div className={styles.serviceIndisponible}>
        <UnavailableService isPage />
      </div>
    </UnauthenticatedLayout>
  </>
)

export default UnavailableServicePage

