import React from 'react'
import classnames from 'classnames'
import { bool, func } from 'prop-types'

import { ToggleButton, Person, PersonCouple } from '@mmb/ui-components'

import styles from './EmprunteursButtons.module.css'

const emprunteursData = [{
  label: <span className={styles.emprunteursButtons__icones}><Person />1 emprunteur</span>,
  smallLabel: <span><Person className={styles.emprunteursButtons__icone} /></span>,
  value: false,
  arialLabel: 'unEmprunteur',
}, {
  label: <span className={styles.emprunteursButtons__icones}><PersonCouple className={styles.emprunteursButtons__couple} />2 emprunteurs</span>,
  smallLabel: <span><PersonCouple className={styles.emprunteursButtons__icone} /></span>,
  value: true,
  arialLabel: 'deuxEmprunteurs',
}]

EmprunteursButtons.propTypes = {
  small: bool,
  hasCoEmprunteur: bool,
  disabled: bool,
  setHasCoEmprunteur: func,
}

EmprunteursButtons.defaultProps = {
  small: false,
  hasCoEmprunteur: true,
  disabled: false,
  setHasCoEmprunteur: () => {},
}

export function EmprunteursButtons({ small, hasCoEmprunteur, disabled, setHasCoEmprunteur }) {
  return (
    <div className={classnames(styles.emprunteursButtons__radios, { [styles.emprunteursButtons__radiosDisabled]: disabled })}>
      {emprunteursData.map(({ smallLabel, label, value, arialLabel }) => {
        const customLabel = small ? smallLabel : label
        const isChecked = hasCoEmprunteur === value
        return (
          <ToggleButton
            label={customLabel}
            name="nombreEmprunteurs"
            aria-label={`${arialLabel}`}
            className={classnames(styles.emprunteursButtons__option, {
              [styles.emprunteursButtons__emprunteursChecked]: isChecked,
              [styles.emprunteursButtons__optionSmall]: small,
              [styles.emprunteursButtons__optionSmallNotChecked]: small && !isChecked,
            })}
            labelClassName={classnames(
              styles.emprunteursButtons__label, {
                [styles.emprunteursButtons__labelEmprunteursChecked]: isChecked,
                [styles.emprunteursButtons__labelEmprunteursSmall]: small,
                [styles.emprunteursButtons__labelSmallNotChecked]: small && !isChecked },
            )}
            checked={isChecked}
            value={String(value)}
            onChange={() => setHasCoEmprunteur(value)}
            disabled={disabled}
            key={value}
          />
        )
      })}
    </div>
  )
}
