import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import { DossierStatut, Role } from '@partenaire/common'
import { getStatutLabel } from '@partenaire/frontend-common'
import { formatFullDate, capitalize } from '@core/common'
import { Historique, Entry, EntryDetail } from '@mmb/ui-components'

import { useDossierHistory } from './DetailHistorique.hooks'
import styles from './DetailHistorique.module.css'
import { getStatusLabel, isWarning } from './statutsLabel'


DetailHistorique.propTypes = {
  id: string.isRequired,
  gamme: string,
}

DetailHistorique.defaultProps = {
  gamme: '',
}

export function DetailHistorique({ id, gamme }) {
  const { historique, emprunteurs, offres, hasSignatureElectronique, shouldShowDetail, shouldShowEmprunteur } = useDossierHistory(id, gamme)
  return (
    <Historique aria-label="Historique dossier" className={styles.detailHistorique}>
      {historique?.map(({ statut, dateModif }) => (
        <Entry
          contentClassName={styles.detailHistorique__entryContent}
          label={getStatutLabel(gamme, DossierStatut[statut], hasSignatureElectronique)}
          date={dateModif}
          key={statut}
        >
          {shouldShowDetail(statut) && (
            emprunteurs
              ?.map(emprunteur => ({ emprunteur, offre: emprunteur?.role === Role.EMPRUNTEUR ? offres?.offreEmprunteur : offres?.offreCoemprunteur }))
              ?.filter(({ offre }) => shouldShowEmprunteur(offre))
              ?.map(({ emprunteur: { id: idEmprunteur, nom, prenom }, offre }) => {
                return (
                  <div key={idEmprunteur}>
                    <EntryDetail
                      dateClassName={styles.detailHistorique__statutMessage}
                      dateLabel={formatDateCreation(offre!.dateCreation)}
                      label={capitalize(`${nom} ${prenom}`)}
                      className={styles.detailHistorique__statut}
                    />
                    {statut && (
                    <p className={styles.detailHistorique__sousStatut}>
                      {'Dernier statut : '}
                      <span className={classNames(styles.rubrique, { [styles.detailHistorique__sousStatutWarning]: isWarning(offre!.statut) })}>
                        {getStatusLabel(offre!.statut)}
                      </span>
                    </p>)}
                  </div>
                )
              })
          )}
        </Entry>
      ),
      )}
    </Historique>
  )
}


function formatDateCreation(dateCreation) {
  return dateCreation ? `Le ${formatFullDate(dateCreation)}` : ''
}
