import { useEffect, useState } from 'react'
import { gql, useQueryWithError } from '@core/graphql'
import { NetworkStatus } from '@apollo/client'
import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import { toast } from '@core/ui'
import { AssuranceUI } from '../../../model/assurance/AssuranceUI'
import { toAssuranceUI } from './toModel'

const GET_ASSURANCE = gql`
query getAssurance($id: String!, $idPersonne: String!) {
  partenaire_assurance(id: $id, idPersonne: $idPersonne) {
    type
    nom
    prenom
    dateNaissance
    situationAdhesion {
      date
      value
    }
    amorcageAdhesion {
      statut
      erreur
    }
    assuranceGroupDCU {
      quotite
      organisme
      formule
      tauxStandard
      tauxFinal
      cotisation
      statut
      dateStatut
    }
    assuranceGroupDCS {
      quotite
      organisme
      formule
      tauxStandard
      tauxFinal
      cotisation
      numeroAdhesion
      statut
      detailStatut
      dateStatut
      webcreditorUrl
    }
    delegationDCS {
      organisme
    }
    sansAssurance {
      typeRefus
      dateRefus
    }
  }
}
`

export const useAssurance = (id: string, idPersonne: string, isSecured: boolean): { loading: boolean, assuranceUI?: AssuranceUI, isLoadingDCS: boolean } => {
  const { data = {}, loading, networkStatus, startPolling, stopPolling } = useQueryWithError(GET_ASSURANCE,
    { variables: { id, idPersonne },
      skip: !idPersonne,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    })
  const [refetchCounter, setRefetchCounter] = useState<number>(0)
  const [assuranceUI, setAssuranceUI] = useState<AssuranceUI>()
  const [isShowingErrors, setIsShowingErrors] = useState<boolean>(false)
  const [isLoadingDCS, setIsLoadingDCS] = useState<boolean>(false)
  const REFETCH_LIMIT = 20

  const startRefetching = () => {
    setIsShowingErrors(true)
    setIsLoadingDCS(true)
    setRefetchCounter(0)
    startPolling(2000)
  }

  const showErrorIfExists = (currentAssuranceUI: AssuranceUI) => {
    if (currentAssuranceUI?.getErrorMessage() && currentAssuranceUI?.shouldStopRefetching()) {
      toast({ message: currentAssuranceUI?.getErrorMessage(), type: 'ERROR' })
    }
  }

  useEffect(() => {
    if (!data.partenaire_assurance || isNetworkRequestInFlight(networkStatus)) {
      return
    }

    if (networkStatus === NetworkStatus.ready) {
      const currentAssuranceUI = toAssuranceUI(data.partenaire_assurance, id, idPersonne, isSecured, setAssuranceUI, startRefetching)
      setRefetchCounter(refetchCounter + 1)

      if (currentAssuranceUI.shouldStopRefetching() || refetchCounter >= REFETCH_LIMIT) {
        if (isShowingErrors) {
          showErrorIfExists(currentAssuranceUI)
        }
        stopPolling()
        setIsLoadingDCS(false)
      }
    }
  }, [networkStatus])

  return { loading, assuranceUI, isLoadingDCS }
}
