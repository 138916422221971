import React, { FunctionComponent } from 'react'
import { SimulationFieldHorsNorme } from '../../../../model'

import styles from '../Header.module.css'
import { getSimulationHorsNormeMessage } from '../../../../utils'
import { useGamme } from '../../../Gamme'


interface HorsNormeCardErrorsProps {
  errors: SimulationFieldHorsNorme[]
}

export const HorsNormeCardErrors: FunctionComponent<HorsNormeCardErrorsProps> = ({ errors }) => {
  const gammeProps = useGamme()
  return (
    <ul>
      {errors.map(error => (
        <li key={error.field} aria-label={error.field} className={styles.header__contentItem}>{getSimulationHorsNormeMessage(error, gammeProps)}</li>
      ))}
    </ul>
  )
}
