export enum AzureADFlowType {
  SIGNIN = 'SIGNIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

/** Résultat de l'appel aux hooks useAzureAD */
export interface AzureADHook {
  /** URL sur laquelle on doit naviguer */
  navigateToUrl?: string
  /** URL sur laquelle le navigateur doit être redirigé (mire de connexion AzureAD) */
  redirectToUrl?: string
  /** Flag indiquant le token d'activation du compte utilisateur est invalide (l'activation du compte utilisateur est tentée quand un token
   * est présent dans les query params de l'URL courante */
  invalidToken?: boolean
  /** Flag indiquant qu'une erreur technique est apparue */
  technicalIssue?: boolean
}

export interface HandleCallbackFromAzureAdHook {
  success?: boolean
  error?: string
  previousUrl?: string
  flowType?: string
}
