import React, { FunctionComponent } from 'react'

import { Button } from '@mmb/ui-components'
import { Pin } from './Pin'

import styles from './PinnedAction.module.css'

interface PinnedActionProps {
  title: string
  label: string
  action: () => void
}

export const PinnedAction: FunctionComponent<PinnedActionProps> = ({ title, label, action }) => {
  return (
    <div className={styles.pinnedAction}>
      <Pin className={styles.pinnedAction__icon} />
      <div className={styles.pinnedAction__content}>
        {title}
        <Button
          type="button"
          outline
          className={styles.pinnedAction__button}
          onClick={action}
        >
          {label}
        </Button>
      </div>
    </div>
  )
}
