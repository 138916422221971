import React from 'react'
import classnames from 'classnames'

import { Card } from '@mmb/ui-components'
import { arrayOf, bool, shape, string } from 'prop-types'

import styles from './Body.module.css'
import { BodyPalier } from './BodyPalier'

BodyWithPaliers.propTypes = {
  paliers: arrayOf(
    shape({
      debut: string,
      duree: string,
      echeance: string,
      fin: string,
      resteAVivre: string,
      variation: string,
      variationWithAssurance: string,
    }),
  ),
  hasSecured: bool,
}

BodyWithPaliers.defaultProps = {
  paliers: [],
  hasSecured: false,
}

export function BodyWithPaliers({ paliers, hasSecured }) {
  const showDate = date => {
    const dateSplit = date.split('-')
    return `${dateSplit[1]}/${dateSplit[0]}`
  }

  return paliers.map((palier, index) => (
    <div key={`${index + 1}`}>
      <Card
        className={classnames(
          styles.simulation__produit__body__row,
          styles.simulation__produit__palier,
        )}
      >
        <span className={styles.simulation__produit__palier__content}>Palier {index + 1}</span>
        <span className={styles.simulation__produit__palier__date}>{showDate(palier?.debut)} - {showDate(palier?.fin)}</span>
      </Card>
      <div className={styles.simulation__produit__body__cards}>
        <BodyPalier product={palier} hasSecured={hasSecured}>
          <Card
            className={classnames(
              styles.simulation__produit__body__container,
              styles.simulation__produit__headerContainer,
            )}
          >
            <span>Durée</span>
            <span
              aria-label={`Durée palier ${index + 1}`}
              className={classnames(
                styles.simulation__produit__palier__content,
                styles.simulation__produit__palier__color,
              )}
            >
              {`${palier.duree} mois`}
            </span>
          </Card>
        </BodyPalier>
      </div>
    </div>
  ))
}
