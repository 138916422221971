import React, { Fragment } from 'react'
import { bool, any } from 'prop-types'
import { Card, ErrorMessage, Spinner } from '@mmb/ui-components'

Redirection.propTypes = {
  invalidToken: bool,
  technicalIssue: bool,
  wrapper: any,
}

Redirection.defaultProps = {
  invalidToken: undefined,
  technicalIssue: undefined,
  wrapper: Fragment,
}

export function Redirection({ invalidToken, technicalIssue, wrapper: Wrapper }) {
  return (
    <Wrapper>
      <Card>
        <div>
          {invalidToken && (
            <ErrorMessage title="Le lien de confirmation de l'Email n'est plus valide. Pour des raisons de sécurité, veuillez recommencer." />
          )}
          {technicalIssue && (
            <ErrorMessage title="La demande a échoué en raison d'un problème technique. Nous vous invitons à renouveler votre demande ultérieurement." />
          )}
          {!invalidToken && !technicalIssue && (
            <>
              <h3>Redirection en cours</h3>
              <p>Veuillez patienter pendant que nous vous redirigeons vers la page souhaitée</p>
              <Spinner />
            </>
          )}
        </div>
      </Card>
    </Wrapper>
  )
}
