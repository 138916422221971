import React from 'react'
import { useParams } from '@core/routing'

import { Assurance } from './Assurance'
import { DetailLayout } from '../Detail/DetailLayout'

import { useEmprunteursAssurance } from './Assurances.hooks'

import styles from './Assurances.module.css'


export function Assurances() {
  const { id } = useParams()
  const { loading, emprunteurId, coEmprunteurId, isSecured } = useEmprunteursAssurance(id)
  return !loading && (
    <DetailLayout id={id} title="Assurance" className={styles.assurances}>
      {emprunteurId && (<Assurance id={id} idPersonne={emprunteurId} isSecured={isSecured} />)}
      {coEmprunteurId && (<Assurance isCoEmprunteur id={id} idPersonne={coEmprunteurId} isSecured={isSecured} />)}
    </DetailLayout>
  )
}
