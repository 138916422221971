import { gql, useQuery, DO_NOT_CHECK_CACHE } from '@core/graphql'
import { Pret } from '../../model/DetailPlanFinancement.model'

export const GET_PRETS = gql`
query getPrets($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    propositionCredit {
      planFinancement {
        prets {
          repris
          nature
          nomPreteur
          crd
          ira
          taux
          echeance
        }
      }
    }
  }
}
`

export const formatPrets = (prets: any[]) => (prets || []).map(({ crd, ira, taux, echeance, repris, nature, nomPreteur }) : Pret => {
  return new Pret(crd, ira, taux, echeance, repris, nature, nomPreteur)
})

export function usePrets(id: string) {
  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_PRETS, {
    ...DO_NOT_CHECK_CACHE,
    errorPolicy: 'all',
    variables: { id },
  })

  // ça casse dans le test e2e
  if (loading) return { loading, prets: [] }

  return {
    error,
    loading,
    // eslint-disable-next-line camelcase
    prets: formatPrets(data?.partenaire_dossierTransmis?.propositionCredit?.planFinancement?.prets),
  }
}
