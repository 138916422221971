import React, { useEffect, useMemo } from 'react'
import { Button, Retry } from '@mmb/ui-components'

import { useSimulation } from '../Contexts'
import { SimulationContent } from './SimulationContent'

import { DownloadButton } from '../Buttons'
import styles from './Simulation.module.css'

export const Simulation = () => {
  const [{ loading, status, called, error, showSimulation, isSimulationRequested, isFormDirty }, { requestSimulation }] = useSimulation()

  const buttonContent = useMemo(() => {
    if (loading) return { icon: () => null, label: 'Étude en cours' }
    if (isSimulationRequested) return { icon: () => null, label: 'Sauvegarde en cours' }
    if (called) return { icon: Retry, label: 'Actualiser la simulation' }
    return { icon: () => null, label: 'Lancer la simulation' }
  }, [loading, called, isFormDirty, isSimulationRequested])

  useEffect(() => {
    if (!loading && called) document?.getElementById('simulation')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [loading, called])

  return (
    <div className={styles.simulation} id="simulation">
      <div className={styles.simulation__buttons}>
        <Button
          className={styles.simulation__simulationButton}
          leftIcon={buttonContent.icon}
          loading={loading || isSimulationRequested}
          onClick={requestSimulation}
        >
          {buttonContent.label}
        </Button>
        <DownloadButton />
      </div>
      {showSimulation && <SimulationContent status={status} error={error} loading={loading} />}
    </div>
  )
}
