/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react'

import { Card } from '@mmb/ui-components'

import styles from '../DetailPlanFinancement.module.css'
import { MainValues } from './MainValues'
import { PlanFinancementProps } from '../../../model/DetailPlanFinancement.model'


export const WithPalier: FunctionComponent<PlanFinancementProps> = ({ planFinancement }) => {
  const { listPalier } = planFinancement
  if (!listPalier || listPalier.length === 0) return null
  return (
    <>
      <div className={styles.planFinancement}>
        <MainValues planFinancement={planFinancement} />
      </div>
      <div>
        {listPalier.map((palier: any, index: number) => {
          const numero = index + 1
          return (
            <div key={`palier ${numero}`}>
              <Card
                className={styles.planFinancement__palier}
              >
                <p className={styles.planFinancement__palier__content}>Palier {numero}</p>
              </Card>
              <div className={styles.planFinancement__palier__cards}>
                <Card
                  className={styles.planFinancement__palier__headerContainer}
                >
                  <p className={styles.planFinancement__produit__palier__title}>Durée</p>
                  <p
                    aria-label={`Durée palier ${index + 1}`}
                    className={styles.planFinancement__produit__palier__content}
                  >
                    {`${palier.dureeEnMois} mois`}
                  </p>
                </Card>
                <Card
                  className={styles.planFinancement__palier__headerContainer}
                >
                  <p className={styles.planFinancement__produit__palier__title}>Echéance</p>
                  <p
                    aria-label={`Durée palier ${index + 1}`}
                    className={styles.planFinancement__produit__palier__content}
                  >
                    {`${palier.echeance} €/mois`}
                  </p>
                </Card>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
