import React, { useRef } from 'react'
import { string } from 'prop-types'
import classnames from 'classnames'

import { Card } from '@mmb/ui-components'
import { FormHeader } from '@core/partenaire-ui'
import { AssuranceForm } from './AssuranceForm'
import { ColumnName } from '../ColumnName'
import { useDelayedFormBlur } from '../../utils'

import styles from './Assurance.module.css'

import { useAssurance } from './Assurance.hooks'
import { AssuranceUI } from '../../model'
import { EntryFormStateName } from '../FormState/EntryFormState'
import { AssuranceFormPropsUI } from '../../model/assurance/AssuranceFormUI'
import { SaisieErrorMessages } from '../SaisieErrorMessages'
import { SaisieWarningMessages } from '../SaisieWarningMessages'

Assurance.propTypes = {
  className: string,
}

Assurance.defaultProps = {
  className: '',
}

export function Assurance({ className }) {
  const { loading, assuranceUI, errors, showErrors, warnings, handleSubmit } = useAssurance()
  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)
  return (
    <div id={`${EntryFormStateName.ASSURANCE}`}>
      <Card className={className}>
        <FormHeader>Assurance</FormHeader>
        <div className={styles.assurance__explain}>
          Renseignez l'assurance vendue à votre client car votre sélection alimentera automatiquement l'offre électronique.<br />
          En cas de modification, il faudra l'indiquer avant l'édition pour ne pas rééditer les offres.
        </div>
        <ColumnName />
        <form
          className={styles.assurance}
          data-testid="assurance-form"
          ref={formRef}
          onSubmit={handleSubmit}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          <div className={styles.assurance__labels}>
            <p className={classnames(styles.assurance__label)}>Choix assurance</p>
            {assuranceUI.shouldDisplayGarantie() && (
              <>
                <p className={classnames(styles.assurance__label, styles.assurance__labelCenter)}>Garanties couvertures</p>
                <p className={classnames(styles.assurance__label, styles.assurance__labelCenter)}>Quotité assurée</p>
              </>
            )}

          </div>
          <AssuranceForm
            loading={loading}
            assuranceFormUI={assuranceUI.emprunteurAssurance}
            errors={errors.emprunteurErrors}
            showErrors={showErrors}
            updateAssuranceFormUI={(assuranceFormUI: AssuranceFormPropsUI) => assuranceUI.updateAssuranceFormUI(assuranceFormUI, true)}
            suffixes={AssuranceUI.SUFFIXE_EMPRUNTEUR}
          />
          {assuranceUI.hasCoEmprunteur && (
            <AssuranceForm
              loading={loading}
              assuranceFormUI={assuranceUI.coEmprunteurAssurance}
              errors={errors.coEmprunteurErrors}
              showErrors={showErrors}
              updateAssuranceFormUI={(assuranceFormUI: AssuranceFormPropsUI) => assuranceUI.updateAssuranceFormUI(assuranceFormUI, false)}
              suffixes={AssuranceUI.SUFFIXE_COEMPRUNTEUR}
            />
          )}
        </form>
        {showErrors && (
          <>
            <SaisieErrorMessages
              data-testid="assurance__errors"
              errors={errors.globalErrors}
            />
            <SaisieWarningMessages className={styles.assurance__warnings} warnings={warnings?.warningMessages ?? []} />
          </>
        )}
      </Card>
    </div>
  )
}
