import React, { useEffect } from 'react'
import { any, bool, string } from 'prop-types'
import { useLocation, useNavigate, Redirect } from '@core/routing'
import { Spinner } from '@mmb/ui-components'

import { Can, useUtilisateurContext } from '@core/partenaire-common'
import { UnauthenticatedLayout } from '../UnauthenticatedLayout'
import { Redirection } from '../Redirection'


AuthenticatedRoute.propTypes = {
  component: any.isRequired,
  layout: any.isRequired,
  scope: string,
  allCabinets: bool,
  requireContextCabinet: bool,
}

AuthenticatedRoute.defaultProps = {
  scope: '*',
  allCabinets: false,
  requireContextCabinet: false,
}

export function AuthenticatedRoute({ layout: Layout, component: Component, scope, allCabinets, requireContextCabinet, ...props }) {
  const { loading, loggedIn, idCabinetTravail } = useUtilisateurContext()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  useEffect(() => {
    if (!loading && loggedIn === false) {
      navigate('/app/connexion', { state: { previousUrl: `${pathname}${search}` } })
    }
  }, [loggedIn])

  if (loading) {
    return <Redirection wrapInUnauthenticatedLayout />
  }
  if (!loggedIn) {
    return (
      <UnauthenticatedLayout>
        <Spinner primary />
      </UnauthenticatedLayout>
    )
  }

  /** Ajout de cette contrainte pour avoir la popup de selection quand on se connecte sur un onglet auquel on aurait
   * pas accès sans contexte de cabinet (sinon, on aurait la popup mais seulement une fois sur la page d'erreur 404)
   */
  if (requireContextCabinet && !idCabinetTravail) {
    return (
      <Layout />
    )
  }

  return (
    <Layout>
      <Can scope={scope} allCabinets={allCabinets}>
        <Component {...props} />
      </Can>
      <Can not scope={scope} allCabinets={allCabinets}>
        <Redirect to="/app/404" noThrow />
      </Can>
    </Layout>
  )
}
