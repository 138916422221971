import { ActiviteFormUI } from '../../../../model/activite/ActiviteFormUI'
import { DATE_MM_YYYY } from '../../../../model/format'
import { dateFromGraphQL, dateToGraphQL } from '../../../../utils'

export const toGraphQL = (activite: ActiviteFormUI) => ({
  ...activite,
  type: activite.type ? activite.type : null,
  typeContrat: activite.typeContrat ? activite.typeContrat : null,
  catSocio: activite.catSocio ? activite.catSocio : null,
  debut: dateToGraphQL(activite.debut, DATE_MM_YYYY),
})


const activiteFromGraphql = ({ __typename, ...activite }) => ({
  ...activite,
  debut: dateFromGraphQL(activite.debut, DATE_MM_YYYY),
})

export const fromGraphql = (activites: any): ActiviteFormUI[] => activites ? activites.map(activiteFromGraphql) : []
