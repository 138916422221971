export const GAMMES_OPTIONS = [{
  label: 'Sans garantie',
  value: 'UNSECURED',
  arialLabel: 'UNSECURED',
}, {
  label: 'Hypothécaire',
  value: 'SECURED',
  arialLabel: 'SECURED',
}]

export const CIVILITES_OPTIONS = [{
  label: 'Monsieur',
  value: 'MR',
}, {
  label: 'Madame',
  value: 'MME',
}]

export const SITUATION_FAMILIALE_OPTIONS = {
  single: [{
    label: 'Célibataire',
    value: 'CELIBATAIRE',
  }, {
    label: 'Veuf',
    value: 'VEUF',
  }, {
    label: 'Divorcé',
    value: 'DIVORCE',
  }, {
    label: 'Instance de divorce',
    value: 'INSTANCE_DE_DIVORCE',
  }, {
    label: 'Marié en séparation de biens',
    value: 'MARIE',
  }],
  couple: [{
    label: 'Pacsé',
    value: 'PACSE',
  }, {
    label: 'Marié',
    value: 'MARIE',
  }, {
    label: 'Concubin',
    value: 'EN_CONCUBINAGE',
  }],
}

export const REGIME_MATRIMONIAL_OPTIONS = [{
  label: 'Séparation de biens',
  value: 'SEPARATION_DE_BIENS',
}, {
  label: 'Communauté réduite aux acquets',
  value: 'COMMUNAUTE_REDUITE_AUX_ACQUETS',
}, {
  label: 'Communauté universelle',
  value: 'COMMUNAUTE_UNIVERSELLE',
}, {
  label: 'Participation aux acquets',
  value: 'PARTICIPATION_AUX_ACQUETS',
}]

export const TYPE_INSTANCE = [{
  label: 'Consentement mutuel',
  value: 'PAR_CONSENTEMENT_MUTUEL',
}, {
  label: 'Non conciliation',
  value: 'PAR_NON_CONCILIATION',
}]

export const AMORTISSEMENT_OPTIONS = [{
  label: 'Linéaire',
  value: 'LINEAIRE',
}, {
  label: 'A palier',
  value: 'PALIER',
}]

export const ETAPES_HISTORIQUE = [{
  labelSecured: 'Réceptionné',
  labelUnsecured: 'Réceptionné',
  value: 'ARRIVE',
}, {
  labelSecured: 'Pris en charge',
  labelUnsecured: 'Pris en charge',
  value: 'PRIS_EN_CHARGE',
}, {
  labelSecured: "En attente d'info complémentaires",
  labelUnsecured: "En attente d'info complémentaires",
  value: 'EN_ENQUETE',
}, {
  labelSecured: 'En avis favorable sous réserve',
  value: 'AVIS_FAVORABLE_SOUS_RESERVE',
}, {
  labelSecured: 'Refusé',
  labelUnsecured: 'Refusé',
  value: 'REFUSE',
}, {
  labelSecured: 'Sans-suite',
  labelUnsecured: 'Sans-suite',
  value: 'SANS_SUITE',
}, {
  labelSecured: 'En accord',
  labelUnsecured: 'En accord',
  value: 'ACCORDE',
}, {
  labelSecured: 'Offre éditée',
  labelUnsecured: 'Offre éditée',
  value: 'EDITE',
}, {
  labelSecured: 'Offre envoyée',
  labelUnsecured: 'Offre envoyée',
  value: 'ENVOYE',
}, {
  labelSecured: 'Offre acceptée par le client',
  labelUnsecured: 'Offre acceptée par le client',
  value: 'ACCEPTE',
}, {
  labelSecured: 'Actes envoyés au notaire',
  value: 'ACTES',
}, {
  labelSecured: 'Actes signés',
  labelUnsecured: 'Fonds envoyés',
  value: 'SIGNE',
}]

export const STATUT_ADRESSE_OPTIONS = [
  {
    value: 'PROPRIETAIRE',
    label: 'Propriétaire',
  },
  {
    value: 'LOCATAIRE',
    label: 'Locataire',
  },
  {
    value: 'HEBERGE_FAMILLE',
    label: 'Hébergé par la famille',
  },
  {
    value: 'HEBERGE_EMPLOYEUR',
    label: 'Hébergé par l\'employeur',
  },
  {
    value: 'HEBERGE_TIERS',
    label: 'Hébergé par un tiers',
  },
  {
    value: 'AUTRE',
    label: 'Autre',
  },
]

export const DEFAULT_NON_VALIDE_ERROR_MESSAGE = 'La simulation est non valide car aucune solution financière n\'a pu être calculée.'
