/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useState } from 'react'
import { useQueryWithError } from '@core/graphql'
import { compareDates } from '@core/common'

import { DocumentUI, GroupeUI } from '../../model/OutilsUI'
import { GET_DOCUMENTS, GET_DOCUMENTS_FAVORIS, GET_GROUPES } from './query'


export const useDocumentsFavoris = (skip : boolean, idRubrique) => {
  const { loading, data } = useQueryWithError(GET_DOCUMENTS_FAVORIS, { variables: { idRubrique }, skip })
  const [documents, setDocuments] = useState<DocumentUI[]>([])
  useEffect(() => {
    if (!loading && data) {
      setDocuments(([...data?.outils_documentsFavoris] as DocumentUI[]).sort((a, b) => compareDates(a.dateUpdate, b.dateUpdate)))
    }
  }, [loading, data])
  return { loading, documents }
}

export const useGroupes = (skip : boolean, idRubrique: string) : {loading:boolean, groupes: GroupeUI[]} => {
  const { loading, data } = useQueryWithError(GET_GROUPES, { variables: { idRubrique }, skip })
  return { loading, groupes: data?.outils_groupes || [] }
}

export const useDocuments = (skip : boolean, idRubrique: string, idGroupe?: string) : {loading:boolean, documents: DocumentUI[]} => {
  const { loading, data } = useQueryWithError(GET_DOCUMENTS, { variables: { idRubrique, idGroupe }, skip })
  const [documents, setDocuments] = useState<DocumentUI[]>([])
  useEffect(() => {
    if (!loading && data) {
      setDocuments(([...data?.outils_documents] as DocumentUI[]).sort((a, b) => compareDates(a.dateUpdate, b.dateUpdate)))
    }
  }, [loading, data])
  return { loading, documents }
}
