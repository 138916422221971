import React from 'react'
import { any } from 'prop-types'

import { AuthenticatedRoute as CoreAuthenticatedRoute } from '@core/partenaire-frontend'
import { Layout } from '@partenaire/frontend-ui'

import styles from './AuthenticatedRoute.module.css'

AuthenticatedRoute.propTypes = {
  component: any.isRequired,
}

export function AuthenticatedRoute({ component, ...props }) {
  return <CoreAuthenticatedRoute layout={Layout} component={component} className={styles.layout} {...props} />
}
