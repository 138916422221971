import { gql, useQuery, DO_NOT_CHECK_CACHE } from '@core/graphql'
import { formatCondenseDate } from '@core/common'
import { isErrorFound } from '../../utils/errors'

export const GET_DOSSIER = gql`
query getDossier($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    id
    nomProduit
    montantFinance
    gamme
    propositionCredit {
      modaliteDeblocage {
        dateDeblocage
        isAnticipe
        jourPrelevement
        notaire {
          nom
          prenom
          adresse {
            numero
            voie
            complement
            codePostal
            ville
          }
        }
      }
    }
  }
}
`
export function useDossier(id) {
  const {
    data,
    loading,
    error,
  } = useQuery(GET_DOSSIER, {
    ...DO_NOT_CHECK_CACHE,
    errorPolicy: 'all',
    variables: { id },
  })

  // Casse dans le test e2e
  if (loading) return { loading }

  if (error && !isErrorFound(formatErrors(error?.graphQLErrors), ['NOT_FOUND'])) { throw error }
  // eslint-disable-next-line camelcase
  if (!data?.partenaire_dossierTransmis) return {}

  // eslint-disable-next-line camelcase
  const { montantFinance, propositionCredit, ...dossier } = data?.partenaire_dossierTransmis || {}
  const modaliteDeblocage = propositionCredit?.modaliteDeblocage ?? {}

  return {
    error,
    ...dossier,
    ...(modaliteDeblocage ?? {}),
    dateDeblocage: formatCondenseDate(modaliteDeblocage?.dateDeblocage),
    montant: montantFinance,
  }
}
function formatErrors(errorsApi) {
  return errorsApi && errorsApi.length > 0 && errorsApi.map(({ extensions }) => extensions?.code)
}
