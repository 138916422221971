import React, { useContext } from 'react'
import styles from './ToPrintDocument.module.css'
import { FormStateContext } from '../../Contexts/FormStateContext'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { ActiviteFormUI } from '../../../model/activite/ActiviteFormUI'
import { ACTIVITE_CAT_SOCIO_PROF_LABEL_MAP, ACTIVITE_CONTRAT_LABEL_MAP, ACTIVITE_LABEL_MAP } from '../../../utils/activites'

/**
 * Contenu de dossier à imprimer ou à télécharger
 */


function getActivityType(activity: ActiviteFormUI) {
  if (!activity) {
    return ''
  }

  return activity.isPrincipale ? 'Principale' : 'Secondaire'
}

function ActivitesWithTwoEmprunteurs(activities) {
  const emprunteur = activities.activitesUI.emprunteursActivites
  const coEmprunteur = activities.activitesUI.coEmprunteursActivites
  const numberMaxLines = Math.max(emprunteur.list.length, coEmprunteur.list.length)

  return (
    <>
      {new Array<number>(numberMaxLines).fill(0).map((_, index) => (
        <div>
          <p className={styles.printDocument__cell}>
            <span className={styles.printDocument__inLines__column__1} />
            <span className={styles.printDocument__inLines__column__2__title}>{emprunteur.list[index] ? 'Emprunteur 1' : ''}</span>
            <span className={styles.printDocument__inLines__column__3__title}>{coEmprunteur.list[index] ? 'Emprunteur 2' : ''}</span>
          </p>
          <p className={styles.printDocument__cell}>
            <span className={styles.printDocument__inLines__column__1}>Activité</span>
            <span className={styles.printDocument__inLines__column__2}>{getActivityType(emprunteur.list[index])}</span>
            <span className={styles.printDocument__inLines__column__3}>{getActivityType(coEmprunteur.list[index])}</span>
          </p>
          <p className={styles.printDocument__cell}>
            <span className={styles.printDocument__inLines__column__1}>Type d'activité</span>
            <span className={styles.printDocument__inLines__column__2}>{emprunteur.list[index]?.type ?
              ACTIVITE_LABEL_MAP.get(emprunteur.list[index].type) : ''}
            </span>
            <span className={styles.printDocument__inLines__column__3}>{coEmprunteur.list[index]?.type ?
              ACTIVITE_LABEL_MAP.get(coEmprunteur.list[index].type) : ''}
            </span>
          </p>
          <p className={styles.printDocument__cell}>
            <span className={styles.printDocument__inLines__column__1}>Cat. Socio-professionnelle</span>
            <span className={styles.printDocument__inLines__column__2}>{emprunteur.list[index]?.catSocio ?
              ACTIVITE_CAT_SOCIO_PROF_LABEL_MAP.get(emprunteur.list[index].catSocio) : ''}
            </span>
            <span className={styles.printDocument__inLines__column__3}>{coEmprunteur.list[index]?.catSocio ?
              ACTIVITE_CAT_SOCIO_PROF_LABEL_MAP.get(coEmprunteur.list[index].catSocio) : ''}
            </span>
          </p>
          <p className={styles.printDocument__cell}>
            <span className={styles.printDocument__inLines__column__1}>Profession</span>
            <span className={styles.printDocument__inLines__column__2}>{emprunteur.list[index]?.profession ?? ''}</span>
            <span className={styles.printDocument__inLines__column__3}>{coEmprunteur.list[index]?.profession ?? ''}</span>
          </p>
          <p className={styles.printDocument__cell}>
            <span className={styles.printDocument__inLines__column__1}>Type de contrat</span>
            <span className={styles.printDocument__inLines__column__2}>{emprunteur.list[index]?.typeContrat ?
              ACTIVITE_CONTRAT_LABEL_MAP.get(emprunteur.list[index].typeContrat) : ''}
            </span>
            <span className={styles.printDocument__inLines__column__3}>{coEmprunteur.list[index]?.typeContrat ?
              ACTIVITE_CONTRAT_LABEL_MAP.get(coEmprunteur.list[index].typeContrat) : ''}
            </span>
          </p>
          <p className={styles.printDocument__cell}>
            <span className={styles.printDocument__inLines__column__1}>Depuis le </span>
            <span className={styles.printDocument__inLines__column__2}>{emprunteur.list[index]?.debut ?? ''}</span>
            <span className={styles.printDocument__inLines__column__3}>{coEmprunteur.list[index]?.debut ?? ''}</span>
          </p>
        </div>
      ))}
    </>
  )
}

function ActivitiesWithOneEmprunteur(activities) {
  return (
    <div>
      {activities.list.map(activity => (
        <div className={styles.printDocument__column}>
          {/* column 1 */}
          <div className={styles.printDocument__column__titles__withEmprunteur}>
            <p className={styles.printDocument__cell}>Activité</p>
            <p className={styles.printDocument__cell}>Type d'activité</p>
            <p className={styles.printDocument__cell}>Cat. Socio-professionnelle</p>
            <p className={styles.printDocument__cell}>Profession</p>
            <p className={styles.printDocument__cell}>Type de contrat</p>
            <p className={styles.printDocument__cell}>Depuis le </p>
          </div>
          <div className={styles.printDocument__column__answer}>
            <div className={styles.printDocument__column__answer__text}>
              {/* column 2 */}
              <p className={styles.printDocument__header}> Emprunteur 1</p>
              <p className={styles.printDocument__cell}>{activity.isPrincipale ? 'Principale' : 'Secondaire'} </p>
              <p className={styles.printDocument__cell}>{ACTIVITE_CONTRAT_LABEL_MAP.get(activity.type)}</p>
              <p className={styles.printDocument__cell}>{ACTIVITE_CAT_SOCIO_PROF_LABEL_MAP.get(activity.catSocio)}</p>
              <p className={styles.printDocument__cell}>{activity.profession}</p>
              <p className={styles.printDocument__cell}>{ACTIVITE_CONTRAT_LABEL_MAP.get(activity.typeContrat)}</p>
              <p className={styles.printDocument__cell}>{activity.debut}</p>

            </div>
          </div>
        </div>
      ),
      )}
    </div>
  )
}

export function ToPrintSituationProfessionnelle() {
  const formState = useContext(FormStateContext)
  const { value: activities } = formState.getEntryFormState(EntryFormStateName.ACTIVITE)
  const emprunteurActivities = activities.activitesUI.emprunteursActivites
  return (
    <div>
      <h1 className={styles.printDocument__headband}>
        <div className={styles.printDocument__headband__text}>
          SITUATION PROFESSIONNELLE
        </div>
      </h1>
      {activities.activitesUI.hasCoEmprunteur ? ActivitesWithTwoEmprunteurs(activities) : ActivitiesWithOneEmprunteur(emprunteurActivities)}

      {/* {activities.activitesUI.hasCoEmprunteur ?
        <ActivitesWithTwoEmprunteurs {...activities} /> : <ActivitiesWithOneEmprunteur {...emprunteurActivities} />} */}
    </div>
  )
}
