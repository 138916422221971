export abstract class SupportStateUI {
  protected setState?: (_: any) => void

  constructor() {
    this.setState = () => {}
  }

  public withUpdateState(setState?: (_: any) => void) {
    this.setState = setState
    return this
  }

  public refreshState(value?: any): void {
    if (this.setState) {
      this.setState(value || this.clone())
    }
  }

  protected abstract clone(): any
}
