import React from 'react'
import { string, number } from 'prop-types'

import { Montant } from '@mmb/ui-components'

import { useDetailPret } from './DetailFinancement.hooks'

import styles from './DetailFinancement.module.css'

DetailFinancement.propTypes = {
  id: string.isRequired,
}

export function DetailFinancement({ id }) {
  const { loading, detailFinancementItems } = useDetailPret(id)
  if (loading) return null

  return (
    <div className={styles.detailFinancement__list} aria-label="Détail du plan de financement">
      {detailFinancementItems.map(({ value, label }) => {
        if (value !== 0 && !value) return ''
        return (
          <div key={label} className={styles.detailFinancement__item}>
            <span>{label}</span>
            <MontantFinancement value={value} />
          </div>
        )
      })}
    </div>
  )
}
function MontantFinancement({ value }) {
  return (<Montant
    wholeClassName={styles.detailFinancement__whole}
    separatorClassName={styles.detailFinancement__separator}
    decimalClassName={styles.detailFinancement__decimal}
    dark
    value={value}
  />)
}

MontantFinancement.propTypes = {
  value: number.isRequired,
}
