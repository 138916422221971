/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react'
import { nth } from 'lodash'

import { AddButton } from '@partenaire/frontend-ui'
import { SaisieErrorMessages } from '../../../SaisieErrorMessages'
import { ActiviteForm } from '../ActiviteForm'
import { ActivitesLabels } from '../ActivitesLabels'

import styles from './Activites.module.css'
import { useDelayedFormBlur } from '../../../../utils'
import { ActivitePlaceholder } from '../ActivitePlaceholder'
import { useActivites } from './Activites.hook'

const suffixes = {
  emprunteur: {
    label: 'de l\'emprunteur',
    name: 'emprunteur',
  },
  coEmprunteur: {
    label: 'du co-emprunteur',
    name: 'coEmprunteur',
  },
}

export function Activites() {
  const { loading, activitesUI, emprunteurErrors, coEmprunteurErrors, errorMessages, showErrors, handleSubmit, handleRemoveActivite } = useActivites()
  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)
  const nbrmOfLabels = activitesUI.emprunteursActivites.list.length > activitesUI.coEmprunteursActivites.list.length ?
    activitesUI.emprunteursActivites.list.length : activitesUI.coEmprunteursActivites.list.length
  return (
    <>
      <form
        data-testid="activites-form"
        ref={formRef}
        onSubmit={handleSubmit}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <div className={styles.activites}>
          <div>
            {Array.from(Array(nbrmOfLabels)).map((_, index) => (<ActivitesLabels key={`activiteLabel_${index}`} />))}
          </div>
          <div>
            {activitesUI.emprunteursActivites.list.map((activite, index) => (
              <ActivitePlaceholder loading={loading} suffixes={suffixes.emprunteur} index={index} key={`${suffixes.emprunteur.name}-activite_${index}`}>
                <ActiviteForm
                  activite={activite}
                  setActivite={newActivite => activitesUI.update(index, newActivite, true)}
                  suffixes={suffixes.emprunteur}
                  removeActivite={(_, idx) => handleRemoveActivite(idx, true)}
                  className={styles.activites__form}
                  index={index}
                  errors={nth(emprunteurErrors, index)}
                  showErrors={showErrors}
                />
              </ActivitePlaceholder>
            ))}
          </div>
          <div>
            {activitesUI.hasCoEmprunteur && (
              activitesUI.coEmprunteursActivites.list.map((activite, index) => (
                <ActivitePlaceholder loading={loading} suffixes={suffixes.coEmprunteur} index={index} key={`${suffixes.coEmprunteur.name}-activite_${index}`}>
                  <ActiviteForm
                    activite={activite}
                    setActivite={newActivite => activitesUI.update(index, newActivite, false)}
                    suffixes={suffixes.coEmprunteur}
                    removeActivite={(_, idx) => handleRemoveActivite(idx, false)}
                    className={styles.activites__form}
                    index={index}
                    errors={nth(coEmprunteurErrors, index)}
                    showErrors={showErrors}
                  />
                </ActivitePlaceholder>
              ))
            )}
          </div>
        </div>
        <div className={styles.activites__button}>
          <AddButton
            label="Ajouter une activité"
            aria-label={`Ajouter une activite pour ${suffixes.emprunteur.name}`}
            className={styles.activiteForm__button}
            disabled={loading}
            onClick={() => activitesUI.add(true)}
          />
          {activitesUI.hasCoEmprunteur && (
          <AddButton
            label="Ajouter une activite"
            aria-label={`Ajouter une activite pour ${suffixes.coEmprunteur.name}`}
            className={styles.activiteForm__button}
            disabled={loading}
            onClick={() => activitesUI.add(false)}
          />)}
        </div>
      </form>
      {showErrors && (<SaisieErrorMessages errors={errorMessages} />)}
    </>
  )
}
