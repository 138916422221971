// eslint-disable-next-line import/no-duplicates
import { format, addYears } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import isAfter from 'date-fns/isAfter'
// eslint-disable-next-line import/no-duplicates
import isBefore from 'date-fns/isBefore'
// eslint-disable-next-line import/no-duplicates
import parse from 'date-fns/parse'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { DATE_DD_MM_YYYY_FR } from './date'
import { EMAIL_REGEXP } from './emails'

function isEmpty(value) {
  return !value || value === ''
}

/**
 * Parse une date telle que saisie par l'utilisateur, au format jj/mm/aaaa. Le jour ou le mois peuvent être exprimés sur un seul chiffre,
 * mais l'année obligatoirement sur 4 chiffres
 * @param value la valeur à vérifier (chaîne de caractères)
 * @returns si le parsing est OK, undefined sinon
 */
export function parseDate(value, pattern = DATE_DD_MM_YYYY_FR) {
  if (isEmpty(value)) {
    return undefined
  }
  const result = parse(value, pattern, new Date())
  if (Number.isNaN(result.getDate()) // la date n'a pas pu être parsée
    || result.getFullYear() < 1000 || result.getFullYear() > 9999) { // l'année spécifiée n'est pas sur 4 chiffres
    return undefined
  }
  return result
}

/**
 * Vérifie que la date est au format jj/mm/aaaa. Le jour ou le mois peuvent être exprimés sur un seul chiffre,
 * mais l'année obligatoirement sur 4 chiffres
 * @see parseDate
 * @param value {string} la valeur à vérifier (chaîne de caractères)
 * @returns {boolean} true si la valeur est vide, n'est pas une date ou est valide, false sinon
 */
export function isValidDate(value, pattern = DATE_DD_MM_YYYY_FR) {
  return isEmpty(value) || Boolean(parseDate(value, pattern))
}

/**
 * Parse une date avec le pattern sourcePattern et renvoit une date formattée avec le pattern targetPattern
 * @param value la valeur à vérifier (chaîne de caractères)
 * @param sourcePattern le pattern utilisé pour parser la date (chaîne de caractères)
 * @param targetPattern la pattern utiliser pour formatter la nouvelle date (chaîne de caractères)
 * @returns la date dans le format targetPattern
*/
export function reformatDate(value, sourcePattern, targetPattern) {
  const date = parseDate(value, sourcePattern)
  return date && format(date, targetPattern)
}

/**
 * Vérifie que la date est une date dans le passée avec un nombre minimal d'années.
 * @param value la valeur à vérifier
 * @param minAge l'age minimal
 * @param dateToCompare la date avec laquelle on compare
 * @returns true si la valeur est vide, n'est pas une date ou est valide, false sinon
 */
export function isValidDateMinAge(value, minAge, dateToCompare) {
  const parsedDate = parseDate(value)
  if (!parsedDate) {
    return true
  }
  return isBefore(addYears(parsedDate, minAge), dateToCompare || new Date())
}

/**
 * Vérifie que la date est une date dans le passée avec un nombre maximal d'années.
 * @param value la valeur à vérifier
 * @param maxAge l'age maximal
 * @param dateToCompare la date avec laquelle on compare
 * @returns {true si la valeur est vide, n'est pas une date ou est valide, false sinon
 */
export function isValidDateMaxAge(value, maxAge, dateToCompare) {
  const parsedDate = parseDate(value)
  if (!parsedDate) {
    return true
  }
  return isAfter(addYears(parsedDate, maxAge), dateToCompare || new Date())
}

function parseFrenchPhoneNumber(value) {
  try {
    const phoneNumber = parsePhoneNumberFromString(value)
    if (phoneNumber && phoneNumber.isValid() && phoneNumber.country === 'FR') { return phoneNumber }
  } catch (error) {
    // erreur lors du parsing du numéro de téléphone : on ne laisse pas l'erreur remonter
  }
  return undefined
}

export function isValidFrenchPhoneNumber(value) {
  return isEmpty(value) || Boolean(parseFrenchPhoneNumber(value))
}

export function isValidFrenchMobileNumber(value) {
  if (isEmpty(value)) {
    return true
  }
  const phoneNumber = parseFrenchPhoneNumber(value)
  if (phoneNumber) {
    const indicatif = phoneNumber.nationalNumber.toString().charAt(0)
    return indicatif === '6' || indicatif === '7'
  }
  return false
}

export const isValidCodePostalFrance = value => !value || value === '' || value.search(/^(([0-9][0-9])|(9[0-5])|(2[AB]))[0-9]{3}$/) === 0

// Même Regexp que celle utilisée par le flow AzureAD B2C
// (récupérée dans la source de la page de login)
// eslint-disable-next-line max-len
const emailRegexp = new RegExp(EMAIL_REGEXP)

export function isValidEmail(value) {
  return isEmpty(value) || (value.length >= 3 && value.length <= 254 && emailRegexp.test(value))
}

export function isValidLength(value, length) {
  return isEmpty(value) || value.length <= length
}
