import React from 'react'
import { node, string } from 'prop-types'

import { Layout as CoreLayout } from '@core/partenaire-ui'
import { useUtilisateurContext } from '@core/partenaire-common'
import { LayoutMenuLink } from './LayoutMenuLink'

Layout.propTypes = {
  className: string,
  children: node,
}

Layout.defaultProps = {
  className: '',
  children: null,
}

export function Layout({ className, children }) {
  const links = [
    { path: '/app/accueil', name: 'Accueil' },
    { path: '/app/dossiers', name: 'Dossiers' },
    { path: '/app/outils', name: 'Outils' },
  ]
  if (['ADMINISTRATEUR', 'SUPER_ADMIN'].includes(useUtilisateurContext()?.role)) links.push({ path: '/app/utilisateurs', name: 'Utilisateurs' })

  return (
    <CoreLayout
      className={className}
      menuLinks={() => (
        <>
          {links.map(link => (<LayoutMenuLink key={link.name} link={link} />))}
        </>
      )}
    >
      {children}
    </CoreLayout>
  )
}
