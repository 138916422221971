import { gql, useQuery } from '@core/graphql'
import { formatFullDate } from '@core/common'
import { DossierStatut, OffreEmprunteurs, SignatureType, Document, Gamme } from '@partenaire/common'

const GET_CONTRAT = gql`
query getContrat($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    id
    historique {
      statut
      dateModif
    }
    contrat {
      documents {
        id
        filename
        libelle
      }
    }
    offres {
      offreEmprunteur {
        signatureType
        statut
        dateCreation
      }
    }
    gamme
  }
}
`

export function useContrat(id: string) : {
  documents: Document[]
  dateEnvoi?: string
  totalCount: number
  loading: boolean
  gamme?: Gamme
  isSignatureManuscrite: boolean
} {
  const {
    data,
    loading,
  } = useQuery(GET_CONTRAT, {
    variables: { id },
  })

  // eslint-disable-next-line camelcase
  const dossier = data?.partenaire_dossierTransmis
  const datesEnvoi = dossier?.historique
    ?.filter(({ statut }) => statut === DossierStatut.EDITE_EN_ATTENTE_ACCEPTATION)
    ?.map(({ dateModif }) => new Date(dateModif))
    ?.sort((date1: Date, date2: Date) => new Date(date2).getTime() - new Date(date1).getTime()) ?? []
  const dateEnvoi = datesEnvoi[0]
  const documents: Document[] = dossier?.contrat?.documents || []
  const offres: OffreEmprunteurs = dossier?.offres
  const isSignatureManuscrite: boolean = offres?.offreEmprunteur?.signatureType === SignatureType.MANUSCRITE
  const gamme: Gamme = dossier?.gamme

  return {
    loading,
    documents,
    dateEnvoi: dateEnvoi ? formatFullDate(dateEnvoi) : undefined,
    totalCount: documents.length,
    gamme,
    isSignatureManuscrite,
  }
}
