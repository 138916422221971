import React, { FunctionComponent } from 'react'
import { DocumentUI } from '../../../model/OutilsUI'
import { DocumentLink } from './DocumentLink'
import styles from './DocumentsListe.module.css'

interface DocumentListeProps {
  documents: DocumentUI[]
}

export const DocumentListe: FunctionComponent<DocumentListeProps> = ({ documents }) => (
  <ul className={styles.outils__documents__liste}>
    {documents && documents.map((document, idx) => (
      <DocumentLink document={document} key={`${idx + 1}__${document.id}`} />
    ))}
  </ul>
)
