/* eslint-disable camelcase */
import { gql, useQueryWithError } from '@core/graphql'

const GET_EMPRUNTEURS_ASSURANCES = gql`
query getEmprunteursAssurances($id: String!) {
  partenaire_assurances(id: $id) {
    gamme
    assuranceEmprunteur {
      id
    }
    assuranceCoEmprunteur {
      id
    }
  }
}
`

export function useEmprunteursAssurance(id) {
  const { data = {}, loading } = useQueryWithError(GET_EMPRUNTEURS_ASSURANCES, {
    notifyOnNetworkStatusChange: true,
    variables: { id },
  })
  const isSecured = data?.partenaire_assurances?.gamme === 'SECURED'

  if (loading) {
    return { loading, isSecured }
  }
  if (!data?.partenaire_assurances) {
    return { isSecured }
  }
  return {
    emprunteurId: data.partenaire_assurances?.assuranceEmprunteur?.id,
    coEmprunteurId: data.partenaire_assurances.assuranceCoEmprunteur?.id,
    isSecured,
    id,
  }
}
