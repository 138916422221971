import { isEmpty } from 'lodash'
import { NavigateFunction } from 'react-router-dom'
import { DossierStatut } from '@partenaire/common'
import { SupportStateUI } from '../SupportStateUI'
import { DocumentCategorie } from './CategorieUI.model'
import { DocumentsAndCategoriePieceUI, DocumentsUI } from './DocumentsUI.model'
import { DocumentUI } from './DocumentUI.model'
import { PieceStatutUI, PiecesUI } from './PiecesUI.model'

export interface LiasseUIProps {
  dossierId: string
  id: string
  commentaire: string
  dateCreation: string
  documentsUI?: DocumentsUI
  piecesUI?: PiecesUI
  dossierStatut?: DossierStatut
}

export interface LiasseUICallback {
  deleteDocument(documentId: string): Promise<void>
  updateCommentaire(dossierId: string, commentaire: string): Promise<void>
  uploadDocument(file: File, categorie: DocumentCategorie): Promise<Response>
  dossierTransfertLiasse(): Promise<any>
  refetchDocuments(dossierId: string): Promise<DocumentUI[]>
}

export class LiasseUI extends SupportStateUI {
  private isDisconnected

  constructor(public readonly props: LiasseUIProps, private callback: LiasseUICallback, private navigate: NavigateFunction) {
    super()
    this.isDisconnected = false
  }


  public async deleteDocument(documentId: string): Promise<void> {
    await this.callback.deleteDocument(documentId)
    this.props.documentsUI = new DocumentsUI(this.props.documentsUI?.documents.filter(d => d.props.id !== documentId) || [])
    this.refreshState()
  }

  public getDownloadDocumentURL(backendUrl: string, documentId: string) {
    return DocumentsUI.getDownloadDocumentURL(backendUrl, this.props.dossierId, documentId)
  }

  public updateCommentaire(): Promise<void> {
    return this.callback.updateCommentaire(this.props.dossierId, this.props.commentaire)
  }

  public updateCommentaireProps(commentaire: string) {
    this.props.commentaire = commentaire
    this.refreshState()
  }

  public uploadDocument(file: File, categorie: DocumentCategorie) {
    return this.callback.uploadDocument(file, categorie)
      .then(response => {
        if (response.status === 401) {
          this.isDisconnected = true
        }
        return response
      })
  }

  public async refetchDocuments() {
    const documents: DocumentUI[] = await this.callback.refetchDocuments(this.props.dossierId)
      .catch(error => {
        if (error?.message === 'You are not authorized for this resource') {
          this.navigate('/app/deconnexion')
        }
        return []
      })
    this.props.documentsUI = new DocumentsUI(documents)
    this.refreshState()
  }

  public async transfert(): Promise<void> {
    await this.callback.updateCommentaire(this.props.dossierId, this.props.commentaire)
    return this.callback.dossierTransfertLiasse().then(() => this.resetLiasse())
  }

  public isTransferable(hasExistingTransfert?: boolean): boolean {
    if (this.isDisconnected) {
      return false
    }
    if (!hasExistingTransfert) {
      return this.hasDocuments()
    }
    return this.hasDocuments() || this.hasCommentaire()
  }

  private getAlwaysUploadableDocumentCategories(): DocumentCategorie[] {
    return this.props.dossierStatut !== DossierStatut.ENTREE_EN_CA ? [DocumentCategorie.AUTRE] : []
  }

  private getDocumentCategorieUplodable(hasExistingTransfert: boolean): DocumentCategorie[] {
    return hasExistingTransfert ?
      [...new Set([
        ...this.props.piecesUI?.getCategoriesByStatut(PieceStatutUI.MANQUANT) ?? [],
        ...this.getAlwaysUploadableDocumentCategories(),
      ])].filter(category => category) :
      Object.keys(DocumentCategorie).map(k => DocumentCategorie[k as any])
  }

  public findUploadableDocumentsByCategoriePiece(hasExistingTransfert: boolean): DocumentsAndCategoriePieceUI[] {
    const documentsByCategorie: DocumentsAndCategoriePieceUI[] = this.getDocumentCategorieUplodable(hasExistingTransfert)
      .map(value => ({ categoriePiece: DocumentsUI.getCategorieDisplayable(value), documents: [] }))
      .map(documentAndCategorie => ({
        categoriePiece: documentAndCategorie.categoriePiece,
        documents: (this.props.documentsUI?.documents || [])
          .filter(document => document.props.categorie === documentAndCategorie?.categoriePiece?.value) }))
      .filter(({ categoriePiece }) => categoriePiece)
    return documentsByCategorie
  }

  private hasDocuments(): boolean {
    return !isEmpty(this.props.documentsUI?.documents)
  }

  private hasCommentaire(): boolean {
    return !isEmpty(this.props.commentaire)
  }

  private resetLiasse(): void {
    this.props.commentaire = ''
    this.props.documentsUI = new DocumentsUI([])
    this.refreshState()
  }

  public countCurrentLiasseDocuments() : number {
    return this.props.documentsUI?.documents?.length ?? 0
  }

  protected clone() {
    return new LiasseUI(this.props, this.callback, this.navigate)
  }
}
