import React, { useEffect } from 'react'
import { string, func, array } from 'prop-types'
import { Select } from '@mmb/ui-components'

import styles from './SelectCity.module.css'

SelectCity.propTypes = {
  value: string,
  name: string,
  options: array,
  onChange: func,
}

SelectCity.defaultProps = {
  value: undefined,
  name: undefined,
  options: [],
  onChange: () => {},
}

export function SelectCity({ value, onChange, name, options, ...props }) {
  useEffect(() => {
    const setCity = city => onChange({ target: { getAttribute: () => name, value: city } })

    if (options.length > 2 && value) {
      const city = findCityInOptions(value, options)

      if (city) {
        setCity(city)
      }
    } else if (options.length === 2 && options[1].value !== value) {
      setCity(options[1].value)
    }
  }, [options])

  return (
    <Select
      fieldClassName={styles.select__input}
      value={value}
      options={options}
      onChange={onChange}
      name={name}
      {...props}
    />
  )
}

function findCityInOptions(city, options) {
  const normalizedValue = normalizeAndLowercase(city)
  const optionsValues = options.map(option => option.value)

  return optionsValues.find(optionValue => {
    return normalizeAndLowercase(optionValue) === normalizedValue
  })
}

function normalizeAndLowercase(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
}
