import React from 'react'
import { useNavigate } from '@core/routing'

import { Button, Plus, Section } from '@mmb/ui-components'

import { Link } from '@core/ui'
import { getConsent } from '@core/partenaire-ui'
import { smartProfileTrackWithParams } from '@core/analytics'

import styles from './Accueil.module.css'

import { DelaisPriseCharge } from '../DelaisPriseCharge'
import { RatioHypothecaire } from '../RatioHypothecaire'
import { DossiersList } from './DossiersList'
import { useAccueil } from './Accueil.hooks'

export function Accueil() {
  const navigate = useNavigate()
  const hasPermissionDossierWrite = useAccueil()
  const bannerUrl = `${window.GATSBY_SITE_URL}/Banner_Accueil.png`

  smartProfileTrackWithParams('&_title=Accueil', getConsent())

  return (
    <div className={styles.accueil}>
      <div>
        <img className={styles.accueil__image} src={bannerUrl} alt="Bienvenue sur My Access Pro" />
        <div className={styles.accueil__dossier}>
          <Section title="Mes dossiers modifiés">
            <DossiersList />
            <div className={styles.accueil__divLink}>
              <Link className={styles.accueil__link} to="/app/dossiers/">Tous mes dossiers</Link>
            </div>
          </Section>
        </div>
      </div>
      <div>
        <Button
          className={styles.accueil__bouton}
          disabled={!hasPermissionDossierWrite}
          onClick={() => navigate('/app/dossiers/saisie')}
          iconClassName={styles.accueil__plus}
          leftIcon={props => <Plus {...props} />}
        >
          Créer un dossier
        </Button>
        <RatioHypothecaire />
        <DelaisPriseCharge />
      </div>
    </div>
  )
}
