import { useEffect, useState } from 'react'
import { useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../Contexts'
import { useGetEntityFormState, useHandleSubmit, useValidation } from '../FormState/form.state.hooks'
import { EntryFormStateName } from '../FormState/EntryFormState'
import { PatrimoinesUI, PatrimoineUI } from '../../model'
import { toPatrimoineUIList, toBienGraphQL, toPatrimoineGraphQL, toPatrimoineUI } from './mapper'
import {
  GET_PATRIMOINE_DOSSIER,
  CREATE_PATRIMOINE_DOSSIER,
  UPDATE_PATRIMOINE_DOSSIER,
  DELETE_PATRIMOINE_DOSSIER,
  UPDATE_DEMANDE_GARANTIE_BIENS,
} from './graphql-queries'
import { validateAdresses } from '../../utils/ValidationErrors/adresseValidationErrors'
import { ErrorMessage } from '../SaisieErrorMessages'
import { PatrimoinesErrors, getPatrimoineErrorByField, getPatrimoinesErrorMessage } from '../../utils/ValidationErrors/validatePatrimoine'

export function usePatrimoines() {
  const navigate = useNavigate()
  const { saisieUI: { props: { dossierId, emprunteurId, hasCoEmprunteur, gamme } } } = useSaisie()
  const [updateDemandeGarantieBiens, { loading: updateDemandeLoading }] = useMutation(UPDATE_DEMANDE_GARANTIE_BIENS)
  const [patrimoinesUI, setPatrimoinesUI] = useState<PatrimoinesUI>(new PatrimoinesUI([], true))
  const [isAdresseCopiable, setIsAdresseCopiable] = useState<boolean>(false)
  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.PATRIMOINE)
  const { entryFormState: adresseState } = useGetEntityFormState(EntryFormStateName.ADRESSE)
  const emprunteurAdresse = adresseState?.value?.emprunteurAdresse
  const coEmprunteurAdresse = adresseState?.value?.coEmprunteurAdresse
  const [loadingPatrimoinesUI, setLoadingPatrimoinesUI] = useState(true)
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([])
  const [biensErrorMessages, setBiensErrorMessages] = useState<ErrorMessage[][]>([])
  const { data, loading: queryLoading }
    = useQueryRedirectError(GET_PATRIMOINE_DOSSIER, navigate, { variables: { id: dossierId } })
  const [updatePatrimoineByDossierId, { loading: updateMutationLoading, error: updateMutationError }] = useMutation(UPDATE_PATRIMOINE_DOSSIER)
  const [createPatrimoineByDossierId, { loading: createMutationLoading, error: createMutationError }] = useMutation(CREATE_PATRIMOINE_DOSSIER)
  const [deletePatrimoineByDossierId, { loading: deleteMutationLoading, error: deleteMutationError }] = useMutation(DELETE_PATRIMOINE_DOSSIER)
  const { errors, showErrors } : { errors: PatrimoinesErrors, showErrors: boolean }
    = useValidation(EntryFormStateName.PATRIMOINE, queryLoading, { patrimoines: patrimoinesUI }, { gamme })

  useEffect(() => { setPatrimoinesUI(patrimoinesUI.withUpdateState(setPatrimoinesUI)) }, [patrimoinesUI])
  useEffect(() => {
    if (adresseState && !adresseState.loading) {
      const { hasErrors: hasErrorsAdresse }
        = validateAdresses({ emprunteurAdresse, coEmprunteurAdresse, hasCoEmprunteur, gamme })
      setIsAdresseCopiable(!hasErrorsAdresse)
    }
  }, [adresseState])

  useEffect(() => {
    setErrorMessages(errors
      .globalErrors
      .map(getPatrimoinesErrorMessage)
      .filter(e => e) as ErrorMessage[])
    setBiensErrorMessages(
      (errors?.bienErrors || [])
        .map(bienError => (bienError || [])
          .map(getPatrimoineErrorByField)
          .filter(e => e) as ErrorMessage[]))
  }, [errors])

  useEffect(() => {
    if (!queryLoading) {
      // eslint-disable-next-line camelcase
      const [emprunteurData, coEmprunteurData] = data?.partenaire_dossierEnCours?.emprunteurs || []
      const formatedPatrimoines: PatrimoineUI[] = [
        ...toPatrimoineUIList(emprunteurData),
        ...toPatrimoineUIList(coEmprunteurData),
      ]
      patrimoinesUI.refreshState(new PatrimoinesUI(formatedPatrimoines))
      setLoadingPatrimoinesUI(false)
    }
  }, [data, queryLoading])

  const doRemovePatrimoine = async (patrimoineUI: PatrimoineUI, index: number) => {
    patrimoinesUI.delete(index)
    if (patrimoineUI.id) {
      await deletePatrimoineByDossierId({ variables: { id: dossierId, personneId: patrimoineUI.personneId, patrimoineId: patrimoineUI.id } })
    }
    patrimoinesUI.refreshState()
  }

  const updateIsGarantie = async (patrimoinesList: PatrimoineUI[]) => {
    const biens = patrimoinesList.map(patrimoine => patrimoine.bien).map(toBienGraphQL)
    await updateDemandeGarantieBiens({ variables: { id: dossierId, biens } })
  }

  const updatePatrimoine = async ({ personneId, ...patrimoine }: PatrimoineUI): Promise<PatrimoineUI> => {
    const { data: partenaireData } = await updatePatrimoineByDossierId({
      variables: {
        id: dossierId,
        personneId,
        patrimoine: toPatrimoineGraphQL(patrimoine),
      },
    })
    return toPatrimoineUI(partenaireData?.partenaire_updatePatrimoine, personneId!)
  }

  const createPatrimoine = async ({ personneId, ...patrimoine }: PatrimoineUI): Promise<PatrimoineUI> => {
    const { data: partenaireData } = await createPatrimoineByDossierId({
      variables: {
        id: dossierId,
        personneId,
        patrimoine: toPatrimoineGraphQL(patrimoine),
      },
    })
    return toPatrimoineUI(partenaireData?.partenaire_createPatrimoine, personneId!)
  }

  const insertNewIds = (newPatrimoine: PatrimoineUI, oldPatrimoine: PatrimoineUI) : PatrimoineUI => ({
    ...oldPatrimoine,
    id: newPatrimoine.id,
    bien: {
      ...oldPatrimoine.bien,
      id: newPatrimoine?.bien?.id,
    },
  })

  const updateOrCreatePatrimoines = async () => {
    const patrimoines: PatrimoineUI[] = await Promise.all(patrimoinesUI!.getSortedList()
      .map(patrimoine => patrimoine.id ? updatePatrimoine(patrimoine) : createPatrimoine(patrimoine)))
    const updatedPatrimoines = patrimoines.map(patrimoine => {
      const oldPatrimoine = patrimoinesUI.list.find(patrimoineFromUI => patrimoine.position === patrimoineFromUI.position)
      return insertNewIds(patrimoine, oldPatrimoine!)
    })
    await updateIsGarantie(updatedPatrimoines)
    patrimoinesUI.refreshState(new PatrimoinesUI(updatedPatrimoines))
  }

  const handleSubmit = async event => doHandleSubmit(updateOrCreatePatrimoines, event)
  const removePatrimoine = (index: number) => (patrimoineUI: PatrimoineUI) => doHandleSubmit(() => doRemovePatrimoine(patrimoineUI, index), undefined, true)
  const copyAdresse = (index: number) => () => {
    if (!isAdresseCopiable) return
    const adresse = adresseState.value.emprunteurAdresse
    const patrimoine = patrimoinesUI.getSortedList()[index]

    const codePostalVille = `${adresse.codePostal ?? ''}${adresse.codePostal && adresse.ville ? ' ' : ''}${adresse.ville ?? ''}`

    const bien = {
      ...patrimoine.bien,
      complement: adresse.complement,
      numero: adresse.numero,
      voie: adresse.voie,
      lieuDit: adresse.lieuDit,
      codePostalVille,
      codeInsee: '',
    }

    const newPatrimoine = {
      ...patrimoine,
      bien,
    }
    patrimoinesUI.update(index, newPatrimoine)
  }

  return {
    queryLoading: loadingPatrimoinesUI,
    loading: loadingPatrimoinesUI || createMutationLoading || updateMutationLoading || deleteMutationLoading || updateDemandeLoading,
    error: updateMutationError || createMutationError || deleteMutationError,
    patrimoinesUI,
    showErrors,
    errors,
    errorMessages,
    biensErrorMessages,
    removePatrimoine,
    addPatrimoine: () => patrimoinesUI.add({ personneId: emprunteurId!, bien: { isGarantie: true } }),
    handleSubmit,
    copyAdresse,
    gamme,
    isAdresseCopiable,
  }
}
