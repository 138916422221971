import React, { FunctionComponent, useContext } from 'react'
import classNames from 'classnames'
import { InputSearch } from '@core/ui'
import { SelectFilter, Select, Separator, GammeSelector } from '@core/partenaire-ui'
import { searchContext } from '@core/partenaire-common'
import { Agence, DOSSIER_STATUTS_OPTIONS, DossierStatut, Gamme } from '@partenaire/common'
import { GAMMES } from '@partenaire/frontend-common'

import style from './Filter.module.css'

const agenceToOption = (agence: Agence) : { value: string, label: string } => ({
  label: agence.nom, value: JSON.stringify(agence),
})


export const Filter: FunctionComponent<any> = ({ className, ...props }) => {
  const contextValue = useContext(searchContext)
  const [, setSearch] = contextValue.search
  const [agences] : [Agence[]] = contextValue.agences
  const [, setSelectedAgences] : [Agence[], any] = contextValue.selectedAgences
  const [, setStatut] : [DossierStatut | '', any] = contextValue.statut
  const { selectedGamme: [selectedGamme, setSelectedGamme] } = useContext(searchContext)

  const agenceOptions = agences?.map(agenceToOption)
  const onSelectAgence = (values : string[]) => {
    setSelectedAgences(values?.map((value: string) => JSON.parse(value)))
  }

  const onSelectStatus = (values: any) => {
    setStatut(values.value)
  }

  const gammes = [Gamme.SECURED, Gamme.UNSECURED].map(gamme => ({
    label: GAMMES[gamme].label,
    value: gamme,
    colorClassName: gamme === Gamme.SECURED ? style.filter__gammeSecured : style.filter__gammeUnsecured,
  }))

  return (
    <div
      className={classNames(style.filter, className)}
      {...props}
    >
      <div className={style.filter__unit}>
        <InputSearch
          className={style.filter__search}
          name="dossierfilter"
          label="Rechercher un dossier"
          placeholder="Nom, prénom ou référence"
          onDebouncedChange={setSearch}
        />
      </div>
      <Separator />
      <div className={style.filter__unit}>
        <GammeSelector
          className={style.filter}
          selectedGamme={selectedGamme}
          setSelectedGamme={setSelectedGamme}
          gammes={gammes}
        />
      </div>
      <Separator />
      <div className={style.filter__unit}>
        <h4 className={style.filter__label}>Statut</h4>
        <Select
          className={style.filter__statut}
          name="statutSelect"
          options={DOSSIER_STATUTS_OPTIONS}
          onChange={onSelectStatus}
          placeholder="Tous les statuts"
        />
        <h4 className={style.filter__label}>Agences</h4>
        <SelectFilter
          name="agenceSelect"
          options={agenceOptions}
          onSelect={onSelectAgence}
          placeholder="Toutes les agences"
        />
      </div>
    </div>
  )
}
