import React from 'react'
import { string } from 'prop-types'
import { Section } from '@mmb/ui-components'

import styles from './InformationMessage.module.css'

InformationMessage.propTypes = {
  message: string.isRequired,
  title: string.isRequired,
}

export function InformationMessage({ message, title }) {
  return (
    <Section
      title={title}
    >
      <div className={styles.informationMessage}>
        {message}
      </div>
    </Section>
  )
}
