/* eslint-disable react/no-array-index-key */
import React from 'react'
import { uniq } from 'lodash'
import { string, arrayOf, bool, shape, oneOf, func } from 'prop-types'

import { ErrorMessage, Button, Password, Card, Input, ToggleButton, Arrow } from '@mmb/ui-components'
import { PASSWORD_VALIDATION } from '@core/common-frontend'
import { Captcha, Link } from '@core/ui'

import { hasErrorForName as hasError } from '../../../utils/signUpValidationErros'
import styles from '../SignUp.module.css'

SignUpForm.propTypes = {
  email: string.isRequired,
  showErrors: bool,
  errors: arrayOf(shape({ field: string, message: string })),
  signUpErrors: arrayOf(shape({ field: string, message: string })),
  form: shape({ nom: string, prenom: string, password: string, confirmPassword: string, civilite: oneOf(['MR', 'MME']) }),
  setCaptcha: func.isRequired,
  setFormFieldFromEvent: func.isRequired,
}

SignUpForm.defaultProps = {
  showErrors: false,
  errors: [],
  signUpErrors: [],
  form: { nom: '', prenom: '', password: '', confirmPassword: '', civilite: null },
}
export function SignUpForm({ email, showErrors, errors, setCaptcha, signUpErrors, form, setFormFieldFromEvent }) {
  const civilites = [
    { label: 'Monsieur', value: 'MR' },
    { label: 'Madame', value: 'MME' },
  ]
  const hasErrorForName = name => showErrors && hasError(name, signUpErrors)

  return (
    <>
      <Card className={styles.signUp__card}>
        {showErrors && uniq(errors).map((error: any, index) => (
          <ErrorMessage key={`error message ${index}`} error={error} className={styles.signUp__error} />
        ))}
        <p className={styles.signUp__information}><b>Votre compte est enregistré à l'adresse Email suivante:</b></p>
        <p className={styles.signUp__email}>Vous en aurez besoin pour vous connecter</p>
        <Input className={styles.signUp__email} value={email} name="email" label="" disabled />
      </Card>
      <Card className={styles.signUp__card}>
        <p>Renseignez les informations pour continuer la création de votre compte:</p>
        <div className={styles.signUp__civilite}>
          <p>Civilité</p>
          <div className={styles.signUp__toggleButton}>
            {civilites.map(civiliteElement => (
              <ToggleButton
                {...civiliteElement}
                name="civilite"
                key={civiliteElement.value}
                onChange={setFormFieldFromEvent}
                checked={form.civilite === civiliteElement.value}
                aria-invalid={hasErrorForName('civilite')}
                hasError={hasErrorForName('civilite')}
              />
            ))}
          </div>
        </div>
        <Input
          type="text"
          name="nom"
          label="Nom"
          onChange={setFormFieldFromEvent}
          value={form.nom}
          aria-invalid={hasErrorForName('nom')}
          hasError={hasErrorForName('nom')}
        />
        <Input
          type="text"
          name="prenom"
          label="Prénom"
          onChange={setFormFieldFromEvent}
          value={form.prenom}
          aria-invalid={hasErrorForName('prenom')}
          hasError={hasErrorForName('prenom')}
        />
        <p className={styles.signUp__password}> Créer un mot de passe sécurisé </p>
        <p> Il vous sera demandé à chaque connexion. </p>
        <Password
          name="password"
          label="Mot de passe"
          hideLabel
          onChange={setFormFieldFromEvent}
          value={form.password}
          passwordValidation={PASSWORD_VALIDATION}
          displayStrength
          autoComplete="new-password"
          aria-invalid={hasErrorForName('password')}
          hasError={hasErrorForName('password')}
        />
        <p className={styles.creation__tips}>
          Le mot de passe doit comporter au moins 12 caractères et doit inclure au moins 3 des 4 caractères suivants :
          une minuscule, une majuscule, un chiffre et un caractère spécial.
        </p>
        <Password
          name="confirmPassword"
          label="Confirmez le mot de passe"
          onChange={setFormFieldFromEvent}
          value={form.confirmPassword}
          passwordValidation={PASSWORD_VALIDATION}
          autoComplete="new-password"
          aria-invalid={hasErrorForName('confirmPassword')}
          hasError={hasErrorForName('confirmPassword')}
        />
        <Captcha onValidation={setCaptcha} />
        <p>
          En cliquant sur "Continuer", je reconnais avoir pris connaissances des
          <Link className={styles.signUp__conditions} to="/conditions-generales/"> Conditions Générales </Link>
          et les accepter.
        </p>
        <Button className={styles.signUp__submit} rightIcon={Arrow}> Continuer </Button>
        <p> <Link className={styles.signUp__protection} to="/protection-des-donnees-unauth/"> Politique de protection de mes données personnelles </Link>  </p>
      </Card>
    </>
  )
}
