import { Pret, PretSource, Regime } from '@partenaire/common'
import { DetteFormUI, EncoursType, PretFormUI } from '../../model'
import { DATE_MM_YYYY } from '../../model/format'
import { dateFromGraphQL, dateToGraphQL } from '../../utils'

export const toDetteFormUI = (dette: any, personneId: string, newId?: string): DetteFormUI => {
  const id = newId || dette.id
  const { __typename: _, ...detteData } = dette
  return {
    ...detteData,
    id,
    personneId,
    type: EncoursType.DETTE,
    debut: dateFromGraphQL(dette.debut, DATE_MM_YYYY),
    fin: dateFromGraphQL(dette.fin, DATE_MM_YYYY),
  }
}
export const toDettesFormUI = (emprunteur: any): DetteFormUI[] => (emprunteur?.dettes || []).map(dette => toDetteFormUI(dette, emprunteur?.id))

export const toDetteGraphQL = (dette: DetteFormUI) => {
  const { titre, type, personneId, ...detteData } = dette
  return {
    ...detteData,
    debut: dateToGraphQL(dette.debut, DATE_MM_YYYY),
    fin: dateToGraphQL(dette.fin, DATE_MM_YYYY),
  }
}

export const toPretFormUI = (pret: any, personneId: string, newId?: string): PretFormUI => {
  const id = newId || pret.id
  const { __typename: _, paliers, ...pretData } = pret
  return {
    ...pretData,
    id,
    personneId,
    type: EncoursType.PRET,
    debut: dateFromGraphQL(pret.debut, DATE_MM_YYYY),
    fin: dateFromGraphQL(pret.fin, DATE_MM_YYYY),
    paliers: (paliers ?? []).map(({ __typename: __, ...palier }) => palier),
  }
}

export const toPretsFormUI = (emprunteur: any): PretFormUI[] => (emprunteur?.prets || []).map(pret => toPretFormUI(pret, emprunteur?.id))

export const toPretGraphQL = (pret: PretFormUI) : Pret => {
  const { titre, type, personneId, regime, sourceTaux, sourcePreavis, sourceIra, sourceFin, paliers, ...pretData } = pret
  const paliersGraphQL = paliers.filter(({ montantEcheance }) => montantEcheance || montantEcheance === 0)
  return {
    ...pretData,
    paliers: paliersGraphQL,
    sourceTaux: sourceTaux ? PretSource[sourceTaux] : sourceTaux,
    sourcePreavis: sourcePreavis ? PretSource[sourcePreavis] : sourcePreavis,
    sourceIra: sourceIra ? PretSource[sourceIra] : sourceIra,
    sourceFin: sourceFin ? PretSource[sourceFin] : sourceFin,
    regime: regime ? Regime[regime] : undefined,
    debut: dateToGraphQL(pret.debut, DATE_MM_YYYY),
    fin: dateToGraphQL(pret.fin, DATE_MM_YYYY),
  }
}

