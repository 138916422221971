import React from 'react'
import cn from 'classnames'
import { string, node } from 'prop-types'

import styles from './Header.module.css'

Header.propTypes = {
  className: string,
  children: node,
}

Header.defaultProps = {
  className: '',
  children: null,
}

export function Header({ children, className }) {
  return (
    <header className={cn(styles.header, className)}>
      {children}
    </header>
  )
}
