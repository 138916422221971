import React, { useEffect, useContext, createRef, useState } from 'react'
import cn from 'classnames'
import { string, oneOf, arrayOf, shape, func } from 'prop-types'
import { isEmpty, map } from 'lodash'

import { ErrorMessage, Input, ToggleButton, AlertMessage } from '@mmb/ui-components'
import { ADMINISTRATEUR, COLLABORATEUR, EMAIL_REGEXP, PORTAIL_CGP, SUPER_ADMIN } from '@core/common'
import { useForm, useErrorReducer, CREATION_IMPOSSIBLE, EMAIL_EXISTANT } from '@core/ui'
import { modalContext, FormHeader, Select, Tooltip, WithPortals } from '@core/partenaire-ui'

import { useUtilisateurContext } from '@core/partenaire-common'
import { useAgenceOptions, useCabinetOptions } from './Form.hooks'
import { useUpdatePersonne } from './UpdatePersonne.hooks'
import { usePersonneExist } from './ExistePersonne.hooks'
import { useAddPersonne } from './CreatePersonne.hooks'
import { useWritableRoles } from './useRoles.hooks'

import { Modal } from '../Modal'
import styles from './Form.module.css'

Form.propTypes = {
  title: string,
  submitLabel: string,
  initialValues: shape({
    email: string.isRequired,
    role: oneOf([COLLABORATEUR, ADMINISTRATEUR, SUPER_ADMIN]),
    cabinets: arrayOf(string.isRequired),
    agences: arrayOf(string.isRequired),
  }),
  personneId: string,
  info: string,
  onClose: func,
}

Form.defaultProps = {
  title: '',
  submitLabel: '',
  initialValues: {
    email: '',
    role: COLLABORATEUR,
    cabinets: [],
    agences: [],
  },
  personneId: null,
  info: '',
  onClose: () => {},
}

export function Form({ title, info, submitLabel, initialValues, personneId, onClose }) {
  const [rolesInitDone, setRoleInitDone] = useState(false)
  const [roleChanged, setRoleChanged] = useState(false)
  const { cabinets: currentUserCabinets, loading: currentUserCabinetsLoading } = useUtilisateurContext()
  const { form, setFormFieldFromEvent, setFormField } = useForm(initialValues)
  const { writableRoles } = useWritableRoles(initialValues.cabinets)
  const { cabinetOptions, onChangeCabinet, cabinetToOption, loading: cabinetsLoading } = useCabinetOptions(setFormField)
  const { agenceOptions, nomToAgenceOption, onChangeSelectedOptions, loading: agencesLoading } = useAgenceOptions(setFormField)
  const updatePersonne = useUpdatePersonne()
  const [getPersonneExist, { loading, personneExist }] = usePersonneExist()
  const [addPersonne, personne] = useAddPersonne()
  const [error, setError] = useErrorReducer()
  const { setOpen } = useContext(modalContext)
  const emailRef = createRef()

  useEffect(() => {
    if (loading || cabinetsLoading || !form.email) return

    if (personneExist) {
      setError(EMAIL_EXISTANT)
      emailRef.current.focus()
      return
    }

    if (personne) {
      setError(CREATION_IMPOSSIBLE)
      emailRef.current.focus()
      return
    }

    if (!personneId) {
      onClose()
      setOpen(false)
      const agenceIds = form.agences.map(agence => agenceOptions.find(option => option.label === agence).value)
      const cabinetsIds = form.cabinets
      addPersonne(form.email, agenceIds, form.role, cabinetsIds)
    }
  }, [loading, cabinetsLoading])

  // Une fois qu'on a les roles sur lesquels on peut écrire, on ne garde que ceux la dans la sélection
  useEffect(() => {
    if (!rolesInitDone && writableRoles && !agencesLoading) {
      // Pour un utilisateur qui n'a qu'un seule cabinet, c'est celui la qui est défini.
      // Pour une création, on crée donc la personne sur ce cabinet et sur une update, on modifie sur ce cabinet
      if (isEmpty(agenceOptions) && currentUserCabinets.length === 1) {
        setFormField('cabinets', currentUserCabinets[0].id)
      } else {
        const filteredInitialValues = initialValues.cabinets.filter(cabinet => currentUserCabinets
          .map(currentCabinet => currentCabinet.id)
          .includes(cabinet))
        setFormField('cabinets', filteredInitialValues)
      }
      setRoleInitDone(true)
    }
  }, [cabinetsLoading, currentUserCabinetsLoading, agencesLoading])

  const onValidate = formValidate => {
    if (initialValues.email) {
      onClose()
      setOpen(false)
      const agenceIds = form.agences.map(agence => agenceOptions.find(option => option.label === agence).value)
      // on a soit des agences, soit des cabinets
      const cabinetsIds = agenceIds.length === 0 ? form.cabinets : []
      return updatePersonne(personneId, formValidate.role, agenceIds, cabinetsIds)
    }
    return getPersonneExist(formValidate.email)
  }

  const onChangeRole = event => {
    setRoleChanged(true)
    setFormFieldFromEvent(event)
  }

  const header = title ? (
    <FormHeader info={info}>{title}</FormHeader>
  ) : null

  return (
    <Modal
      header={header}
      onValidate={() => onValidate(form)}
      submitLabel={submitLabel}
      onClose={onClose}
    >
      {error && (
        <ErrorMessage error={error} className={styles.form__error} />
      )}

      <Input
        type="email"
        name="email"
        label="Email"
        className={styles.form__row}
        labelClassName={styles.form__label}
        fieldClassName={styles.form__inputField}
        onChange={setFormFieldFromEvent}
        disabled={Boolean(personneId)}
        required
        value={form.email}
        ref={emailRef}
        // eslint-disable-next-line max-len
        pattern={EMAIL_REGEXP}
        title="Veuillez renseigner un email valide"
      />

      <WithPortals portals={[PORTAIL_CGP]}>
        {(cabinetOptions.length > 1) &&
        (<Select
          options={cabinetOptions}
          onChange={onChangeCabinet}
          value={form.cabinets.map(cabinetToOption)}
          defaultValue={form.cabinets.map(cabinetToOption)}
          className={cn(styles.form__row, styles.form__select)}
          labelClassName={styles.form__label}
          fieldClassName={styles.form__selectField}
          name="cabinets"
          label="Cabinet(s)"
          isMulti
          required
        />)}
      </WithPortals>

      {!isEmpty(agenceOptions) &&
        (<Select
          options={agenceOptions}
          onChange={onChangeSelectedOptions}
          value={form.agences.map(nomToAgenceOption)}
          className={cn(styles.form__row, styles.form__select)}
          labelClassName={styles.form__label}
          fieldClassName={styles.form__selectField}
          name="agences"
          label="Agence(s)"
          isMulti
          defaultValue={form.agences.map(nomToAgenceOption)}
          required
        />
        )}

      {writableRoles.length > 1 && (
        <>
          <div className={styles.form__row}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className={styles.form__label}>
              Rôle
            </label>
            <div className={styles.form__toggleButton}>
              {map(writableRoles, role => (
                <ToggleButton
                  {...role}
                  name="role"
                  key={role.value}
                  onChange={onChangeRole}
                  checked={form.role === role.value}
                />
              ))}
            </div>
            <Tooltip
              title="Information sur les rôles"
              content={(
                <>
                  <p className={styles.form__paragraphe}>
                    Un administrateur a en charge la gestion des collaborateurs, c'est-à-dire qu'il peut créer un compte collaborateur et le supprimer.
                  </p>
                  <p className={styles.form__paragraphe}>Ces fonctionnalités ne sont pas disponibles pour un collaborateur.</p>
                </>
          )}
            />
          </div>
          {personneId && roleChanged && (
          <AlertMessage className={styles.form__alert}>
            Le changement de rôle s'effectuera sur l'ensemble des cabinets.
          </AlertMessage>
          )}
        </>
      )}
    </Modal>
  )
}
