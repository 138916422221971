export const TOOLTIP_INFORMATIONS = [
  {
    type: 'BIC_BNC',
    title: 'BIC/BNC',
    content: 'Saisir 1/12 de la moyenne des sommes déclarées sur les 3 derniers avis d\'imposition (en cas de baisse de revenus, retenir la dernière année).',
  },
  {
    type: 'LOYER_AVEC_SCI',
    title: 'LOYER_AVEC_SCI',
    // eslint-disable-next-line max-len
    content: 'Saisir 100% des revenus fonciers (pondération automatique). Ils doivent être présents sur les relevés de comptes et déclarés sur le dernier avis d\'imposition.',
  },
  {
    type: 'LOYER_SANS_SCI',
    title: 'LOYER_SANS_SCI',
    // eslint-disable-next-line max-len
    content: 'Saisir 100% des revenus fonciers (pondération automatique). Ils doivent être présents sur les relevés de comptes et déclarés sur le dernier avis d\'imposition.',
  },
  {
    type: 'PENSION_ALIMENTAIRE',
    title: 'Pension alimentaire',
    // eslint-disable-next-line max-len
    content: 'Elle doit figurer sur les relevés de comptes et être fiscalement déclarée. Prise en compte de tous les enfants bénéficiaires quel que soit leur âge.',
  },
  {
    type: 'PENSION_D_INVALIDITE',
    title: 'Pension d\'invalidité',
    content: 'Saisir 100% du montant de la pension déclaré sur le dernier avis d\'imposition ou sur le bulletin de pension (pondération automatique).',
  },
]

export const TOOLTIP_BAISSE_REVENU = {
  type: 'BAISSE_REVENU',
  title: 'Revenu de retraite',
  content: `Si le client a plus de 55 ans, le futur revenu à saisir est le montant indiqué sur l’estimation de retraite à l’âge légal de départ. 
    Nous n’appliquons plus l’abattement des 30%`,
}
