/* eslint-disable react/jsx-indent */
import React from 'react'
import { bool, node } from 'prop-types'
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom'
import { Redirect } from '@core/routing'

import { useUtilisateurContext } from '@core/partenaire-common'
import { Configuration, Logout, SignUp, UnauthenticatedLayout, ConnexionRoute } from '@core/partenaire-frontend'
import { IzanamiProvider, Enabled, Feature, Pending, Disabled } from '@core/ui'
import { Spinner } from '@mmb/ui-components'
import { UsersRoute } from '@core/partenaire-users'
import { ProfilRoute } from '@core/partenaire-profil'
import { DossiersRoute } from '@partenaire/frontend-dossiers'
import { OutilsRoute } from '@core/frontend-outils'
import { SaisieRoute } from '@partenaire/frontend-saisie'
import { Contact } from '@partenaire/frontend-contact'
import { Accueil } from '@partenaire/frontend-accueil'

import { AuthenticatedRoute } from '../AuthenticatedRoute'
import { PagesRouter } from './PagesRouter'
import Maintenance from '../../pages/maintenance'
import { features } from '../../../features'

Router.propTypes = {
  loading: bool,
}

Router.defaultProps = {
  loading: false,
}

function ProtectedUsersRoute({ ...props }) {
  const { role } = useUtilisateurContext()

  if (role !== 'ADMINISTRATEUR' && role !== 'SUPER_ADMIN') return <RedirectToApp />

  return <UsersRoute nomenclature="PARTENAIRE" {...props} />
}

IzanamiRootProvider.propTypes = {
  element: node.isRequired,
}


function IzanamiRootProvider({ element }) {
  return (
    <IzanamiProvider featuresFallback={features} onFailureMaxRetry={process.env.NODE_ENV === 'production' ? 3 : 0}>
      <Configuration>
        <Feature name="maintenance">
          <Disabled>
            {element}
          </Disabled>
          <Pending>
            <Spinner />
          </Pending>
          <Enabled>
            <Maintenance />
          </Enabled>
        </Feature>
      </Configuration>
    </IzanamiProvider>
  )
}


export function Router({ loading }) {
  if (loading) {
    return (
      <UnauthenticatedLayout>
        <Spinner primary />
      </UnauthenticatedLayout>
    )
  }

  const routes = createRoutesFromElements(
    <Route element={<IzanamiRootProvider element={<Outlet />} />}>
      <Route path="/app/dossiers/*" element={<AuthenticatedRoute component={DossiersRoute} />} />
      <Route path="/app/dossiers/saisie/*" element={<AuthenticatedRoute component={SaisieRoute} />} />
      <Route path="/app/outils/*" element={<AuthenticatedRoute component={OutilsRoute} />} />
      <Route path="/app/utilisateurs/*" element={<AuthenticatedRoute component={ProtectedUsersRoute} />} />
      <Route path="/app/mon-profil/*" element={<AuthenticatedRoute component={ProfilRoute} />} />
      <Route path="/app/contact" element={<AuthenticatedRoute component={Contact} />} />
      <Route path="/app/accueil" element={<AuthenticatedRoute component={Accueil} />} />
      <Route path="/app/connexion/*" element={<ConnexionRoute />} />
      <Route path="/app/creation-de-votre-compte/*" element={<SignUp />} />
      <Route path="/app/deconnexion" element={<Logout />} />
      <Route path="/app" element={<RedirectToApp />} />
      <Route path="*" element={<PagesRouter />} />
    </Route>,
  )
  const router = createBrowserRouter(routes)
  return <RouterProvider router={router} />
}

function RedirectToApp() {
  return <Redirect to="/app/accueil" noThrow />
}
