import { gql } from '@core/graphql'

export const GET_SIMULATION_DOSSIER = gql`
  query getSimulationDossier($id: ID!) {
    partenaire_getSimulation(id: $id) {
      statut
      errors {
        horsNorme {
          field
          args {
            min
            taux
            max
          }
        }
        nonValide {
          products
          errors {
            field
            args {
              min
              max
              taux
            }
          }
        }
        errorSaisie {
          field
          args {
            min
            max
            taux
          }
        }
      }
      produit {
        nom
        gamme
        regime
        montantFinancement
        montantApport
        montantOperation
        dureeFinancement
        taux
        seuilImmo
        echeance
        variation
        variationWithAssurance
        detail {
          key
          value
        }
        paliers {
          echeance
          variation
          duree
          debut
          fin
          resteAVivre
          variationWithAssurance
        }
      }
      assurances {
        statut
        nom
        montantEcheance
        prenom
        role
        type
        couverture
        quotite
      }
      diagnostic {
        hasPriority
        taeg
        ratioHypo
        tauxUsure
        expertiseImmo
        endettementAvant
        endettementApres
        resteAVivre
      }
    }
}
`
