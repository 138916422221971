import React from 'react'
import { func, string } from 'prop-types'
import cn from 'classnames'

import { Plus } from '@mmb/ui-components'

import styles from './AddChild.module.css'

AddChild.propTypes = {
  className: string,
  onClick: func,
}

AddChild.defaultProps = {
  className: '',
  onClick: () => {},
}

export function AddChild({ className, onClick, ...props }) {
  return (
    <div className={cn(styles.addChild, className)} onClick={onClick} aria-label="Ajouter un enfant">
      <div className={styles.addChild__icon}>
        <Plus {...props} />
      </div>
    </div>
  )
}
