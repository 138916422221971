import React, { FunctionComponent } from 'react'
import { Details } from '@mmb/ui-components'
import { DocumentListe } from '../liste'
import styles from './Groupes.module.css'
import { useDocuments } from '../OutilsDocuments.hook'
import { GroupeUI } from '../../../model/OutilsUI'

interface GroupeProps {
  groupe: GroupeUI
  idRubrique: string
}

export const Groupe: FunctionComponent<GroupeProps> = ({ idRubrique, groupe }) => {
  const { loading, documents } = useDocuments(false, idRubrique, groupe.id)
  return (
    <Details
      open
      className={styles.sections__detail}
      summaryClassName={styles.sections__summary}
      title={groupe.nom}
      key={groupe.id}
    >
      {!loading && <DocumentListe documents={documents} />}
    </Details>
  )
}
