export enum DocumentCategorie {
  DEMANDE_DE_PRET = 'DEMANDE_DE_PRET',
  EN_COURS = 'EN_COURS',
  IDENTITE = 'IDENTITE',
  PATRIMOINE_IMMOBILIER = 'PATRIMOINE_IMMOBILIER',
  REVENUS = 'REVENUS',
  ASSURANCE = 'ASSURANCE',
  COMPTES_BANCAIRES = 'COMPTES_BANCAIRES',
  AUTRE = 'AUTRE',
}

export interface CategoriePieceUI {
  value: DocumentCategorie
  label: string
  icone: any
  contour?: boolean
  popoverItems: string[]
}
