import React from 'react'
import { func, string, shape, number } from 'prop-types'

import { Button, Cross } from '@mmb/ui-components'

import styles from './ActiviteHeader.module.css'

ActiviteHeader.propTypes = {
  index: number,
  removeActivite: func,
  suffixeLabel: string,
  activite: shape({
    id: string,
    type: string,
    typeContrat: string,
    catSocio: string,
    profession: string,
    debut: string,
  }),
}

ActiviteHeader.defaultProps = {
  index: 0,
  activite: {
    id: '',
    type: '',
    typeContrat: '',
    catSocio: '',
    profession: '',
    debut: '',
  },
  removeActivite: () => {},
  suffixeLabel: '',
}

export function ActiviteHeader({ index, activite, removeActivite, suffixeLabel }) {
  return (
    <div className={styles.activiteHeader__title}>
      <Button
        type="button"
        className={styles.activiteHeader__removeButton}
        aria-label={`Supprimer l'activité ${suffixeLabel}`}
        onClick={() => removeActivite(activite, index)}
      >
        <Cross className={styles.activiteHeader__icon} />
      </Button>
    </div>
  )
}
