import { ActiviteType, ActiviteContrat, ActiviteCategSocioProf } from '../model/activite/ActiviteFormUI'

export const ACTIVITE_LABEL_MAP = new Map<ActiviteType | undefined, string>([
  [ActiviteType.SALARIE_DU_PRIVE, 'Salarié du privé'],
  [ActiviteType.SALARIE_DU_PUBLIC, 'Salarié du public'],
  [ActiviteType.RETRAITE, 'Retraité'],
  [ActiviteType.NON_SALARIE, 'Travailleur non salarié'],
  [ActiviteType.RENTIER, 'Rentier'],
  [ActiviteType.SANS_ACTIVITE_PROFESSIONNELLE, 'Sans activité'],
])

export const ACTIVITES_OPTIONS = [...ACTIVITE_LABEL_MAP.entries()].map(([key, value]) => ({ label: value, value: key }))


export const CATEGORIES_ACTIVITES_LABEL_MAP = new Map<string | undefined, string>([
  ['PRINCIPALE', 'Principale'],
  ['SECONDAIRE', 'Secondaire'],
])

export const CATEGORIES_ACTIVITES_OPTIONS = [...CATEGORIES_ACTIVITES_LABEL_MAP.entries()].map(([key, value]) => ({ label: value, value: key }))

export const ACTIVITE_CONTRAT_LABEL_MAP = new Map<ActiviteContrat | undefined, string>([
  [ActiviteContrat[''], 'Aucun'],
  [ActiviteContrat.CDI, 'CDI'],
  [ActiviteContrat.CDD, 'CDD'],
  [ActiviteContrat.INTERIMAIRE, 'Interimaire'],
  [ActiviteContrat.INTERMITTENT, 'Intermittent'],
  [ActiviteContrat.CESU, 'CESU'],
  [ActiviteContrat.TITULAIRE_FONCTION_PUBLIQUE, 'Titulaire de la fonction publique'],
  [ActiviteContrat.STAGIAIRE_FONCTION_PUBLIQUE, 'Stagiaire de la fonction publique'],
  [ActiviteContrat.CONTRACTUEL_FONCTION_PUBLIQUE, 'Contractuel de la fonction publique'],
  [ActiviteContrat.APPRENTISSAGE, 'Apprentissage'],
  [ActiviteContrat.AIDE, 'Aide'],
  [ActiviteContrat.STAGE, 'Stage'],
])

export const TYPE_CONTRAT_OPTIONS = [...ACTIVITE_CONTRAT_LABEL_MAP.entries()].map(([key, value]) => ({ label: value, value: key }))

export const ACTIVITE_CAT_SOCIO_PROF_LABEL_MAP = new Map<ActiviteCategSocioProf | undefined, string>([
  [ActiviteCategSocioProf[''], ''],
  [ActiviteCategSocioProf['11_AGRICULTEURS_SUR_PETITE_EXPLOITATION'], 'Agriculteur sur petite exploitation'],
  [ActiviteCategSocioProf['12_AGRICULTEURS_SUR_MOYENNE_EXPLOITATION'], 'Agriculteur sur moyenne exploitation'],
  [ActiviteCategSocioProf['13_AGRICULTEURS_SUR_GRANDE_EXPLOITATION'], 'Agriculteur sur grande exploitation'],
  [ActiviteCategSocioProf['21_ARTISANS'], 'Artisans'],
  [ActiviteCategSocioProf['22_COMMERCANTS_ET_ASSIMILES'], 'Commercant et assimilés'],
  [ActiviteCategSocioProf['23_CHEFS_D_ENTREPRISE_DE_10_SALARIES_OU_PLUS'], 'Chef d\'entreprise de 10+ salariés'],
  [ActiviteCategSocioProf['31_PROFESSIONS_LIBERALES'], 'Professions libérales'],
  [ActiviteCategSocioProf['33_CADRES_DE_LA_FONCTION_PUBLIQUE'], 'Cadres de la fonction publique'],
  [ActiviteCategSocioProf['34_PROFESSEURS_PROFESSIONS_SCIENTIFIQUES'], 'Professeurs professions scientifiques'],
  [ActiviteCategSocioProf['35_PROFESSIONS_DE_L_INFORMATION_DES_ARTS_ET_DES_SPECTACLES'], 'Professions de l\'info des arts et spectacles'],
  [ActiviteCategSocioProf['37_CADRES_ADMINISTRATIFS_ET_COMMERCIAUX_D_ENTREPRISE'], 'Cadres administratifs et commerciaux d\'entreprise'],
  [ActiviteCategSocioProf['38_INGENIEURS_ET_CADRES_TECHNIQUES_D_ENTREPRISE'], 'Ingénieurs et cadres techniques d\'entreprise'],
  [ActiviteCategSocioProf['42_PROFESSEURS_DES_ECOLES_INSTITUTEURS_ET_ASSIMILES'], 'Professeurs des écoles instituteurs et assimilés'],
  [ActiviteCategSocioProf['43_PROFESSIONS_INTERMEDIAIRES_DE_LA_SANTE_ET_DU_TRAVAIL_SOCIAL'], 'Professions intermédiaires de la santé et du travail social'],
  [ActiviteCategSocioProf['44_CLERGE_RELIGIEUX'], 'Clergé religieux'],
  [ActiviteCategSocioProf['45_PROFESSIONS_INTERMEDIAIRES_ADMINISTRATIVES_DE_LA_FONCTION_PUBLIQUE'],
    'Professions intermédiaires administratives de la fonction publique'],
  [ActiviteCategSocioProf['46_PROFESSIONS_INTERMEDIAIRES_ADMINISTRATIVES_ET_COMMERCIALES_DES_ENTREPRISES'],
    'Professions intermédiaires administratives et commerciales des entreprises'],
  [ActiviteCategSocioProf['47_TECHNICIENS'], 'Techniciens'],
  [ActiviteCategSocioProf['48_CONTREMAITRES_AGENTS_DE_MAITRISE'], 'Contremaitres agents de maitrise'],
  [ActiviteCategSocioProf['52_EMPLOYES_CIVILS_ET_AGENTS_DE_SERVICE_DE_LA_FONCTION_PUBLIQUE'], 'Employés civils et agents de service de la fonction publique'],
  [ActiviteCategSocioProf['53_POLICIERS_ET_MILITAIRES'], 'Policiers et militaires'],
  [ActiviteCategSocioProf['54_EMPLOYES_ADMINISTRATIFS_D_ENTREPRISE'], 'Employés administratifs d\'entreprise'],
  [ActiviteCategSocioProf['55_EMPLOYES_DE_COMMERCE'], 'Employés de commerce'],
  [ActiviteCategSocioProf['56_PERSONNELS_DES_SERVICES_DIRECTS_AUX_PARTICULIERS'], 'Personnels des services directs aux particuliers'],
  [ActiviteCategSocioProf['57_AGENTS_DE_SURVEILLANCE'], 'Agents de surveillance'],
  [ActiviteCategSocioProf['58_COMMERCANTS_AMBULANTS_VRP'], 'Commercants ambulants VRP'],
  [ActiviteCategSocioProf['59_PERSONNEL_BAR_HOTEL_RESTAURANT'], 'Personnel bar hotel restaurant'],
  [ActiviteCategSocioProf['62_OUVRIERS_QUALIFIES_DE_TYPE_INDUSTRIEL'], 'Ouvriers qualifiés de types industriel'],
  [ActiviteCategSocioProf['63_OUVRIERS_QUALIFIES_DE_TYPE_ARTISANAL'], 'Ouvriers qualifiés de type artisanal'],
  [ActiviteCategSocioProf['64_CHAUFFEURS'], 'Chauffeurs'],
  [ActiviteCategSocioProf['65_OUVRIERS_QUALIFIES_DE_LA_MANUTENTION_DU_MAGASINAGE_ET_DU_TRANSPORT'], 'Ouvriers qualifiés de la manutention et du transport'],
  [ActiviteCategSocioProf['67_OUVRIERS_NON_QUALIFIES_DE_TYPE_INDUSTRIEL'], 'Ouvriers non  qualifiés de types industriel'],
  [ActiviteCategSocioProf['68_OUVRIERS_NON_QUALIFIES_DE_TYPE_ARTISANAL'], 'Ouvriers non qualifiés de types artisanal'],
  [ActiviteCategSocioProf['69_OUVRIERS_AGRICOLES'], 'Ouvriers agricoles'],
  [ActiviteCategSocioProf['70_TRANSPORTEURS_ET_TAXIS'], 'Transporteurs et taxis'],
  [ActiviteCategSocioProf['71_ANCIENS_AGRICULTEURS_EXPLOITANTS'], 'Anciens agriculteurs exploitants'],
  [ActiviteCategSocioProf['72_ANCIENS_ARTISANS_COMMERÇANTS_CHEFS_D_ENTREPRISE'], 'Anciens artisans commerçants chefs d\'entreprise'],
  [ActiviteCategSocioProf['74_ANCIENS_CADRES'], 'Anciens cadres'],
  [ActiviteCategSocioProf['75_ANCIENNES_PROFESSIONS_INTERMEDIAIRES'], 'Anciennes professions intermédiaires'],
  [ActiviteCategSocioProf['77_ANCIENS_EMPLOYES'], 'Anciens employés'],
  [ActiviteCategSocioProf['78_ANCIENS_OUVRIERS'], 'Anciens ouvriers'],
  [ActiviteCategSocioProf['81_CHOMEURS_N_AYANT_JAMAIS_TRAVAILLE'], 'Chômeurs n\'ayant jamais travaillé'],
  [ActiviteCategSocioProf['83_MILITAIRES_DU_CONTINGENT'], 'Militaires du contingent'],
  [ActiviteCategSocioProf['84_ELEVES_ETUDIANTS'], 'Elèves / Etudiants'],
  [ActiviteCategSocioProf['85_PERSONNES_DIVERSES_SANS_ACTIVITE_PROFESSIONNELLE_DE_MOINS_DE_60_ANS_SAUF_RETRAITES'],
    'Sans activité pro de moins de 60ans hors retraite'],
  [ActiviteCategSocioProf['86_PERSONNES_DIVERSES_SANS_ACTIVITE_PROFESSIONNELLE_DE_60_ANS_ET_PLUS_SAUF_RETRAITES'], 'Sans activité pro de 60ans+ hors retraite'],
])

export const CATEGORIES_SOCIO_OPTIONS = [...ACTIVITE_CAT_SOCIO_PROF_LABEL_MAP.entries()].map(([key, value]) => ({ label: value, value: key }))

