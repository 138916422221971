import React from 'react'
import cn from 'classnames'
import { func, node, string } from 'prop-types'

import styles from './Statut.module.css'

Statut.propTypes = {
  children: node,
  className: string,
  icon: func,
  label: string,
}

Statut.defaultProps = {
  children: null,
  className: '',
  icon: null,
  label: '',
}

export function Statut({ children, icon: Icon, className, label }) {
  return (
    <div
      className={cn(styles.statut, className)}
      aria-label={label}
    >
      {Icon && <Icon className={styles.statut__icon} value={50} />}
      {children}
    </div>
  )
}
