/* eslint-disable react/require-default-props */
import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import { Cross, Switch, Input } from '@mmb/ui-components'
import { Select } from '@core/partenaire-ui'
import { WarningPopIn, InputMontant } from '@partenaire/frontend-ui'

import styles from './Dette.module.css'

import { clearEmpty, parseMontant, hasErrorForName as hasError } from '../../../utils'
import { DettePlaceholder } from './DettePlaceholder/DettePlaceholder'
import { ErrorMessage, SaisieErrorMessages } from '../../SaisieErrorMessages'
import { DetteFormUI, getSelectedNatureDette, NATURE_DETTE_OPTIONS, ValidationFieldError } from '../../../model'
import { DATE_MM_YYYY } from '../../../model/format'

interface DetteProps {
  loading?: boolean
  index: number
  dette: DetteFormUI
  removeDette: (detteFormUI: DetteFormUI, index: number) => void
  setDette: (detteFormUI: DetteFormUI) => void
  setRepris: (repris: boolean) => void
  errors?: ValidationFieldError[]
  errorMessages?: ErrorMessage[]
  showErrors: boolean
}

export const Dette: FunctionComponent<DetteProps> = ({
  index, dette, loading = false, removeDette, setDette, setRepris, errors = [], errorMessages = [], showErrors = false }) => {
  const numero = index + 1
  const hasErrorForName = name => hasError(name, errors, showErrors)

  if (loading) return <DettePlaceholder titre={dette.titre || ''} numero={index} />

  return (
    <div aria-label={`Rachat de la dette n° ${numero}`}>
      <div className={styles.dette__titre}>
        {dette.titre}
        <div className={styles.dette__titreDroit}>
          <Switch
            name={`rachatdette_${numero}`}
            label="A racheter"
            checked={Boolean(dette?.repris)}
            onClick={() => setRepris(!dette?.repris)}
            leftLabel
          />
          <WarningPopIn
            onConfirm={() => removeDette(dette, index)}
            label={`Suppression de la dette n° ${numero}`}
            title="Suppression d'une dette"
            warningLine="Êtes-vous sûr de vouloir supprimer l'encours ?"
            warningLineBold="Cette action est définitive."
          >
            <Cross className={styles.dette__cross} />
          </WarningPopIn>
        </div>
      </div>
      <div className={styles.dette__contour}>
        <div className={styles.dette__ligne}>
          <div className={styles.dette__left}>
            <Select
              name={`nature.dette.${numero}`}
              options={NATURE_DETTE_OPTIONS}
              label="Nature de la dette"
              onChange={({ value }) => setDette({ ...dette, nature: value })}
              className={styles.dette__nature}
              labelClassName={classNames(styles.dette__label, styles.dette__labelNature)}
              value={getSelectedNatureDette(dette?.nature)}
              hasError={hasErrorForName('nature')}
            />
            <Input
              name={`encours.dettes.${index}.beneficiaire`}
              label="Bénéficiaire"
              aria-label={`Bénéficiaire de la dette n° ${numero}`}
              onChange={({ target }) => setDette({ ...dette, beneficiaire: target.value })}
              className={styles.dette__nom}
              labelClassName={styles.dette__label}
              value={clearEmpty(dette?.beneficiaire)}
              hasError={hasErrorForName('beneficiaire')}
            />
            <div className={styles.dette__ligne}>
              <Input
                name={`encours.dettes.${index}.debut`}
                label="Début"
                aria-label={`Date de début de la dette n° ${numero}`}
                onChange={({ target }) => setDette({ ...dette, debut: target.value })}
                className={styles.dette__inputBas}
                labelClassName={styles.dette__label}
                value={clearEmpty(dette?.debut)}
                type="text"
                placeholder={`${DATE_MM_YYYY.toLowerCase()}`}
                hasError={hasErrorForName('debut')}
              />
              <Input
                name={`encours.dettes.${index}.fin`}
                label="Fin (optionnel)"
                aria-label={`Date de fin de la dette n° ${numero}`}
                onChange={({ target }) => setDette({ ...dette, fin: target.value })}
                className={styles.dette__inputBas}
                labelClassName={styles.dette__label}
                value={clearEmpty(dette?.fin)}
                type="text"
                placeholder={`${DATE_MM_YYYY.toLowerCase()}`}
                hasError={hasErrorForName('fin')}
              />
            </div>
          </div>

          <div className={styles.dette__right}>
            <div className={styles.dette__ligne}>
              <InputMontant
                name={`encours.dettes.${index}.crd`}
                label="CRD total"
                aria-label={`CRD total de la dette n° ${numero}`}
                onChange={({ target }) => setDette({ ...dette, montantCrd: parseMontant(target.value) })}
                className={styles.dette__input}
                labelClassName={styles.dette__label}
                value={clearEmpty(dette?.montantCrd)}
                type="number"
                hasError={hasErrorForName('montantCrd')}
                unit="€"
                unitClassName={styles.dette__unite}
              />
            </div>
            <div className={styles.dette__ligne}>
              <InputMontant
                name={`encours.dettes.${index}.capitalInit`}
                label="Capital initial (optionnel)"
                aria-label={`Capital initial de la dette n° ${numero}`}
                onChange={({ target }) => setDette({ ...dette, montantCapitalInitial: parseMontant(target.value) })}
                className={styles.dette__input}
                labelClassName={styles.dette__label}
                value={clearEmpty(dette?.montantCapitalInitial)}
                type="number"
                hasError={hasErrorForName('montantCapitalInitial')}
                unit="€"
                unitClassName={styles.dette__unite}
              />
            </div>
            <div className={styles.dette__ligne}>
              <InputMontant
                name={`encours.dettes.${index}.palier`}
                label="Echéance"
                aria-label={`Echéance de la dette n° ${numero}`}
                onChange={({ target }) => setDette({ ...dette, montantEcheance: parseMontant(target.value) })}
                className={styles.dette__inputBasRight}
                labelClassName={styles.dette__label}
                value={clearEmpty(dette?.montantEcheance)}
                type="number"
                hasError={hasErrorForName('montantEcheance')}
                unit="€"
                unitClassName={styles.dette__unite}
              />
            </div>
          </div>
        </div>
        <SaisieErrorMessages errors={errorMessages} />
      </div>
    </div>
  )
}
