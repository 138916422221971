import React, { FunctionComponent } from 'react'

interface DossierInfoFormatProps {
  children: any
  className: string
}
/**
 * Force l'affichage d'un contenu vide si la data ne renvoie rien
 */
export const DossierInfoFormat: FunctionComponent<DossierInfoFormatProps> = ({ children, className }) => {
  const dataToReturn = children ?? ' - '
  return (
    <p className={className}>{dataToReturn} </p>
  )
}
