import { ADMINISTRATEUR, ATTACHE_COMMERCIAL, COLLABORATEUR, SUPER_ADMIN, SUPPORT_COMMERCIAL } from '@core/common'

export const ROLES = [
  {
    label: 'Administrateur',
    value: ADMINISTRATEUR,
  },
  {
    label: 'Collaborateur',
    value: COLLABORATEUR,
  },
  {
    label: 'Super Administrateur',
    value: SUPER_ADMIN,
  },
  {
    label: 'Attaché commercial',
    value: ATTACHE_COMMERCIAL,
  },
  {
    label: 'Support commercial',
    value: SUPPORT_COMMERCIAL,
  },
]
