import React, { FunctionComponent } from 'react'

import { InputMontant } from '@partenaire/frontend-ui'

import styles from './BudgetTotal.module.css'

interface BudgetTotalProps {
  type: 'revenus' | 'charges'
  total: number
  withAllocationFamiliales?: number
}

export const BudgetTotal : FunctionComponent<BudgetTotalProps> = ({ type, total, withAllocationFamiliales }) => {
  return (
    <div className={styles.budgetTotal}>
      {type === 'revenus' &&
        (
        <div className={styles.totalRevenus}>
          <p className={styles.totalRevenus__label}>Allocations familiales</p>
          <InputMontant
            label="Allocations familiales du foyer"
            name="allocations.total"
            className={styles.totalRevenus__input}
            readOnly
            hideLabel
            value={withAllocationFamiliales}
          />
        </div>
        )}
      <div className={styles.totalRevenus}>
        <p className={styles.totalRevenus__label}>Total des {type} du foyer</p>
        <InputMontant
          label={`Total des ${type} du foyer`}
          name={`${type}.total`}
          className={styles.totalRevenus__input}
          readOnly
          hideLabel
          value={total}
        />
      </div>
    </div>
  )
}

BudgetTotal.defaultProps = {
  withAllocationFamiliales: 0,
}
