import React from 'react'
import cn from 'classnames'
import { node, string, oneOf } from 'prop-types'

import { GAMMES } from '@partenaire/frontend-common'

import { Facet } from '../Facet'

import styles from './Gamme.module.css'

Gamme.propTypes = {
  children: node,
  className: string,
  type: oneOf([GAMMES.SECURED.value, GAMMES.UNSECURED.value]),
}

Gamme.defaultProps = {
  children: null,
  className: '',
  type: undefined,
}

export function Gamme({ children, className, type }) {
  const isHypo = type === GAMMES.SECURED.value
  const isConso = type === GAMMES.UNSECURED.value

  const styleGamme = cn(
    styles.gamme,
    {
      [styles.gammeFacetHypo]: isHypo,
      [styles.gammeFacetConso]: isConso,
    },

    className,
  )

  return (
    <Facet className={styleGamme}>{children}</Facet>
  )
}
