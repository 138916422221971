import { CATEGORIES_PIECE } from '../../utils'
import { DocumentCategorie } from './CategorieUI.model'

export enum PieceStatutUI {
  VALIDE = 'VALIDE',
  MANQUANT = 'MANQUANT'
}

export interface PieceUI {
  categorie: DocumentCategorie
  dateValidation: string
  libelle: string
  finalite: string
  statut: PieceStatutUI
}
export interface PiecesPropsUI {
  pieces: PieceUI[]
}

export class PiecesUI {
  private readonly pieces: PieceUI[]

  private static readonly sortedCategories: DocumentCategorie[] = CATEGORIES_PIECE.map(categorie => categorie.value)

  constructor(pieces: PieceUI[]) {
    this.pieces = PiecesUI.sort(pieces)
  }

  public getCategoriesByStatut(statut: PieceStatutUI): DocumentCategorie[] {
    return this.getPiecesByStatut(statut).map(pieceUI => pieceUI.categorie)
  }

  private static getSortedPieces(pieces: PieceUI[], categorie: DocumentCategorie): PieceUI[] {
    return pieces.filter(rawDocument => rawDocument.categorie === categorie)
  }

  private getPiecesByStatut(statut: PieceStatutUI): PieceUI[] {
    return this.pieces.filter(piece => piece.statut === statut)
  }

  public getPiecesValide(): PieceUI[] {
    return this.getPiecesByStatut(PieceStatutUI.VALIDE)
  }

  public getPiecesManquanteByCategorie(categorie: DocumentCategorie): PieceUI[] {
    return this.getPiecesByStatut(PieceStatutUI.MANQUANT).filter(piece => piece.categorie === categorie)
  }

  public hasPiecesManquantes(): boolean {
    return this.getPiecesByStatut(PieceStatutUI.MANQUANT).length !== 0
  }

  private static sort(pieces: PieceUI[]): PieceUI[] {
    return PiecesUI.sortedCategories
      .map(categorie => PiecesUI.getSortedPieces(pieces, categorie))
      .flatMap(p => p)
      .filter(d => d)
      .map(d => d!!)
  }
}
