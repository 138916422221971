import { uniq } from 'lodash'
import { isResidenceValid } from './codePostalValidation'
import { buildValidationError } from '../../model'

const VALID_STATUTS_ADRESSE = [
  'PROPRIETAIRE',
  'LOCATAIRE',
  'HEBERGE_FAMILLE',
  'HEBERGE_EMPLOYEUR',
  'HEBERGE_TIERS',
  'AUTRE',
]

export const VALIDATION_ERRORS_MESSAGES = {
  MISSING_STATUT: {
    title: 'Information requise',
    message: 'Veuillez renseigner le statut du lieu de résidence du/des client(s).',
  },
  MISSING_ADRESSE: {
    title: 'Information requise',
    message: 'Veuillez renseigner la résidence du/des client(s).',
  },
  INVALID_DEPARTEMENT: {
    title: 'Adresse domicile',
    message: 'Le département de la résidence principale ne nous permet pas d\'intervenir.',
  },
}

function getAdresseErrors(error) {
  return VALIDATION_ERRORS_MESSAGES[error.message] || undefined
}

export function validateAdresse({ numero, voie, codePostal, ville, statut }, gamme, hasCoEmprunteur?) {
  const errors: any[] = []

  if (!voie) {
    errors.push(buildValidationError('voie', 'MISSING_ADRESSE'))
  }

  if (!codePostal && !ville) {
    errors.push(buildValidationError('codePostalVille', 'MISSING_ADRESSE'))
  }

  if (!hasCoEmprunteur) {
    // le statut de l'adresse du co-emprunteur n'est pas une donnée saisie
    if (!statut) {
      errors.push(buildValidationError('statut', 'MISSING_STATUT'))
    } else if (!VALID_STATUTS_ADRESSE.includes(statut)) {
      errors.push(buildValidationError('statut', 'WRONG_VALUE'))
    }
  }

  if (codePostal && !isResidenceValid(codePostal, gamme)) {
    errors.push(buildValidationError('codePostalVille', 'INVALID_DEPARTEMENT'))
  }

  if (!!numero && numero < 0) {
    errors.push(buildValidationError('numero', 'NEGATIVE_VALUE'))
  }

  return errors
}

export function validateAdresses({ emprunteurAdresse, coEmprunteurAdresse, hasCoEmprunteur, gamme }) {
  const emprunteurErrors = validateAdresse(emprunteurAdresse, gamme)
  const coEmprunteurErrors = hasCoEmprunteur ? validateAdresse(coEmprunteurAdresse, gamme, hasCoEmprunteur) : []
  const globalErrors = uniq([...emprunteurErrors, ...coEmprunteurErrors].map(getAdresseErrors).filter(error => error))
  const hasErrors = emprunteurErrors.length + coEmprunteurErrors.length + globalErrors.length > 0
  return {
    globalErrors,
    emprunteurErrors,
    coEmprunteurErrors,
    hasErrors,
  }
}
