import { format } from 'date-fns'
import { checkWarningsPret, validatePret } from './validatePret'
import { validateDette } from './validateDette'
import { ValidationFieldError } from '../ValidationFieldError'
import { EncoursUI } from './EncoursUI'
import { EncoursFormUI } from './EncoursFormUI'
import { NaturePret, PretFormUI } from './PretFormUI'
import { DetteFormUI, NatureDette } from './DetteFormUI'
import { DATE_MM_YYYY } from '../format'
import { ENCOURS_WARNING_MESSAGES } from '../../components/Encours/Errors/EncoursErrorMessage'
import { ValidationErrors } from '../ValidationErrors'
import { ErrorMessage } from '../../components/SaisieErrorMessages'

const currentDate = format(new Date(), DATE_MM_YYYY)

const validateEncoursFormUI = (encoursFormUI: EncoursFormUI, gamme: string, encoursUI: EncoursUI) => EncoursUI.isPret(encoursFormUI) ?
  validatePret(encoursFormUI as PretFormUI, gamme, currentDate, encoursUI) : validateDette(encoursFormUI as DetteFormUI, gamme, currentDate)

export interface EncoursValidationErrors extends ValidationErrors {
  encoursErrors: ValidationFieldError[][]
  positionFirstError: number
}

export function validateEncours({ encoursUI, gamme }: { encoursUI: EncoursUI, gamme: string }) : EncoursValidationErrors {
  const globalErrors: ValidationFieldError[] = []
  const avecGarantie = gamme === 'SECURED'

  const pretsRepris = encoursUI.getPretsRepris()
  const dettesReprises = encoursUI.getDettesRepris()
  const detteFiscaleOuPrivee = dettesReprises.some(dette => (
    !!dette?.nature && ['DETTE_FISCALE', 'DETTE_PRIVEE'].includes(dette?.nature?.toString())
  ))

  if (!avecGarantie && !encoursUI.list.length) {
    globalErrors.push({ field: 'encours', message: 'ENCOURS_REQUIRED' })
  }

  if (!pretsRepris.length && dettesReprises.length === 1 && detteFiscaleOuPrivee) {
    globalErrors.push({ field: 'encours', message: 'SINGLE_RACHAT_UNAUTHORIZED_FAMILLE_FISCALE' })
  }

  if (avecGarantie) {
    if (pretsRepris.length === 1 && dettesReprises.length === 0 && pretsRepris[0].nature === NaturePret.DECOUVERT) {
      globalErrors.push({ field: 'encours', message: 'SINGLE_RACHAT_UNAUTHORIZED_GENERIC' })
    }
    if (pretsRepris.length === 0
      && dettesReprises.length === 1
      && dettesReprises[0].nature
      && [NatureDette.DETTE_HUISSIER,
        NatureDette.DETTE_SOCIETE_DE_RECOUVREMENT,
        NatureDette.RETARD_DE_CHARGES_DE_COPRO,
        NatureDette.RETARD_DE_LOYER,
      ].includes(dettesReprises[0].nature)) {
      globalErrors.push({ field: 'encours', message: 'SINGLE_RACHAT_UNAUTHORIZED_GENERIC' })
    }
  }

  const encoursErrors = encoursUI.getEncoursWithDisplayablePosition().map(encoursFormUI => validateEncoursFormUI(encoursFormUI, gamme, encoursUI))
  const hasErrors = globalErrors.length + encoursErrors.flatMap(p => p).length > 0
  const positionFirstError = hasErrors ? encoursErrors.findIndex(errors => errors.length > 0) : 0
  return {
    globalErrors,
    encoursErrors,
    hasErrors,
    positionFirstError: positionFirstError === -1 ? 0 : positionFirstError,
  }
}

const checkWarningsEncoursFormUI = (encoursFormUI: EncoursFormUI) => EncoursUI.isPret(encoursFormUI) ? checkWarningsPret(encoursFormUI as PretFormUI) : []

const getWarningMessages =
  (encoursWarnings: ValidationFieldError[]) : ErrorMessage[] => encoursWarnings
    .map(({ message }) => ENCOURS_WARNING_MESSAGES[message || '']).filter(e => e)

export interface EncoursWarnings {
  warnings: ValidationFieldError[][]
  warningMessages: ErrorMessage[][]
  positionFirstWarning: number
}

export function getEncoursWarnings({ encoursUI }: { encoursUI: EncoursUI }) : EncoursWarnings {
  const warnings = encoursUI.getEncoursWithDisplayablePosition().map(checkWarningsEncoursFormUI)
  const warningMessages = warnings.map(getWarningMessages)
  const positionFirstWarning = warnings.findIndex(warning => warning.length > 0)

  return {
    warnings,
    warningMessages,
    positionFirstWarning: positionFirstWarning === -1 ? 0 : positionFirstWarning,
  }
}
