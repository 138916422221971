import React, { createContext, useContext, useState, useEffect } from 'react'
import { node } from 'prop-types'

export const PrintContext = createContext({})

export function usePrint() {
  const context = useContext<any>(PrintContext)

  if (context === undefined) {
    throw new Error('usePrint must be used within a PrintProvider')
  }
  return context
}

PrintProvider.propTypes = {
  children: node.isRequired,
}
let timeoutId

export function PrintProvider({ children }) {
  const [documentToPrint, setDocumentToPrint] = useState(children)
  const [onPrint, setOnPrint] = useState(false)
  const printScreen = () => {
    setOnPrint(true)
    timeoutId = setTimeout(() => window.print(), 500)
  }


  const onAfterPrint = () => {
    setOnPrint(false)
    setDocumentToPrint(children)
    clearTimeout(timeoutId)
  }

  useEffect(() => {
    window.addEventListener('afterprint', onAfterPrint)

    return () => {
      window.removeEventListener('afterprint', onAfterPrint)
    }
  }, [])

  return (
    <PrintContext.Provider
      value={[
        {
          printScreen,
        },
        onPrint,
      ]}
    >
      {documentToPrint}
    </PrintContext.Provider>
  )
}
