/**
 * Fichier de "fallback" : défini quelles fonctionnalités du portail My Access
 * Pro (DC) seront actives ou non dans le cas où le serveur Izanami est
 * inaccessible.
 */
export const features = {
  partenaire: {
    sit: {
      maintenance: { active: true },
      meteo: { active: true },
      'contenu-bandeau-info': { active: false },
      'transmission-dossier': { active: true },
    },
    uat: {
      maintenance: { active: false },
      meteo: { active: true },
      'contenu-bandeau-info': { active: false },
      'transmission-dossier': { active: true },
    },
    prod: {
      maintenance: { active: false },
      meteo: { active: true },
      'contenu-bandeau-info': { active: false },
      'transmission-dossier': { active: true },
    },
  },
}
