/**
 * Télécharge le contenu d'une URL dans un nouvel onglet.
 * @param urlOrBackendPath URL ou path du backend à récupérer
 */
export const download = urlOrBackendPath => {
  const url = urlOrBackendPath.startsWith('/') ? `${global.GATSBY_API_URL}${urlOrBackendPath}` : urlOrBackendPath
  const downloadLink: HTMLAnchorElement = document.createElement('a')
  downloadLink.href = url
  downloadLink.target = '_blank'
  // l'en-tête 'referer' ne contient que l'origine, de la même façon que les requêtes effectuée par fetch de @core/common-frontend
  downloadLink.referrerPolicy = 'origin'
  downloadLink.click()
}
