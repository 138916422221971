import React from 'react'
import { Route, Routes } from 'react-router'
import { Redirect } from '@core/routing'

import ProtectionDesDonnees from '../../pages/protection-des-donnees'
import NotFoundPage from '../../pages/404'
import ProtectionDesDonneesUnauthPage from '../../pages/protection-des-donnees-unauth'
import ConditionsGenerales from '../../pages/conditions-generales'
import UnavailableServicePage from '../../pages/service-indisponible'

export const PagesRouter = () => {
  return (
    <Routes>
      <Route path="/conditions-generales/" element={<ConditionsGenerales />} />
      <Route path="/protection-des-donnees-unauth/" element={<ProtectionDesDonneesUnauthPage />} />
      <Route path="/protection-des-donnees/" element={<ProtectionDesDonnees />} />
      <Route path="/service-indisponible" element={<UnavailableServicePage />} />
      <Route path="/" element={<Redirect to="/app/accueil" noThrow />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}
