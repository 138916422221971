/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react'
import { Select, Tooltip } from '@core/partenaire-ui'
import { InputMontant, SelectCommunes, WarningPopIn } from '@partenaire/frontend-ui'
import classnames from 'classnames'
import { formatVille } from '@partenaire/frontend-common'
import { Input, ToggleButton, Cross, Switch, Button, Document } from '@mmb/ui-components'
import { concatString } from '@core/common'
import styles from './Bien.module.css'
import { clearEmpty, parseMontant, hasErrorForName as hasError } from '../../../utils'
import { BienPlaceholder } from '../BienPlaceholder'
import { PatrimoineUI, ValidationFieldError, REGIME_PROPRIETE_OPTIONS, NATURE_BIEN_OPTIONS, USAGE_BIEN_OPTIONS, RegimePropriete } from '../../../model'
import { useBien } from './Bien.hooks'

interface BienProps {
  numero: number
  patrimoine: PatrimoineUI
  removePatrimoine: (patrimoineToDelete: PatrimoineUI) => void
  setPatrimoine: (patrimoineUI: PatrimoineUI) => void
  setIsGarantie: (repris: boolean) => void
  copyAdresse: () => void
  adresseIsCopiable?: boolean
  loading?: boolean
  errors?: ValidationFieldError[]
  showErrors: boolean
}

export const Bien: FunctionComponent<BienProps> = ({
  numero,
  patrimoine,
  setPatrimoine,
  removePatrimoine,
  setIsGarantie,
  copyAdresse,
  adresseIsCopiable,
  showErrors,
  loading = false,
  errors = [],
}) => {
  const getRegimeByValue = (searchValue?: RegimePropriete) => REGIME_PROPRIETE_OPTIONS.find(({ value }) => value === searchValue)
  const hasErrorForName = name => hasError(name, errors, showErrors)
  const {
    setCodePostalVille,
    citiesOptions,
    setBien,
    canSelectCodeInsee,
    codeInseeOptions,
  } = useBien({ setPatrimoine, errors, showErrors, patrimoine })
  return (
    <>
      <header className={styles.bien__header}>
        <h4 className={styles.bien__title}>{`Bien immobilier ${numero}`}</h4>
        <div className={styles.bien__titleRight}>
          <Switch
            name={`isgarantie_${numero}`}
            label="Garantie"
            checked={Boolean(patrimoine?.bien?.isGarantie)}
            onClick={() => setIsGarantie(!patrimoine?.bien?.isGarantie)}
            leftLabel
          />
          <WarningPopIn
            label={`Suppression du bien n° ${numero}`}
            title="Suppression d'un bien"
            warningLine="Êtes-vous sûr de vouloir supprimer le bien ?"
            warningLineBold="Cette action est définitive."
            onConfirm={() => removePatrimoine(patrimoine)}
          >
            <Cross className={styles.bien__icon} />
          </WarningPopIn>
        </div>
      </header>
      <div className={styles.bien__content}>
        <div className={styles.bien__labels}>
          <div className={styles.bien__titleTooltipWrapper}>
            <p className={classnames(styles.bien__label, styles.bien__labelToggles)}>Usage du bien</p>
            <Tooltip
              wrapperClassName={styles.bien__tooltip}
              title="Usage du bien"
              content={(
                <p className={styles.bien__tooltipText}>
                  <span className={styles.bien__tooltipTextBold}>Mixte</span> : Le bien immobilier sert de local professionnel et d'habitation <br />
                  à l'emprunteur. La surface professionnelle doit être inférieure à 30 % de la <br />
                  surface habitable totale du bien OU une RP avec une partie locative avec un accès individuel (une <br />
                  chambre louée dans le bien immobilier n’est pas un bien mixte). <br />
                  <span className={styles.bien__tooltipTextBold}>Locatif</span> : Pour les biens locatifs, veuillez saisir la valeur actuelle du <br />
                  bien à 100 %. Un abattement automatique de 10 % sera déduit pour la simulation.
                </p>
                )}
            />
          </div>
          <div className={styles.bien__titleTooltipWrapper}>
            <p className={classnames(styles.bien__label, styles.bien__labelToggles)}>Nature du bien</p>
            <Tooltip
              wrapperClassName={styles.bien__tooltip}
              title="Nature du bien"
              content={(
                <p className={styles.bien__tooltipText}>
                  <span className={styles.bien__tooltipTextBold}>Immeuble</span> : L'immeuble ou les lots de l'immeuble à prendre en garantie <br />
                  doivent appartenir uniquement au client, il ne doit pas y avoir de règlement de copropriété.
                </p>)}
            />
          </div>
          <div className={styles.bien__titleTooltipWrapper}>
            <p className={styles.bien__label}>Nature de la propriété</p>
            <Tooltip
              wrapperClassName={styles.bien__tooltip}
              title="Nature de la propriété"
              content={(
                <p className={styles.bien__tooltipText}>
                  En cas d'indivision (parents/enfants majeurs) issue d'une acquisition, <br />
                  d'une succession ou d'une donation, le client doit avoir une partie en nue-propriété.
                </p>)}
            />
          </div>
          <p className={styles.bien__labelButton} />
          <p className={styles.bien__label}>Complément</p>
          <p className={styles.bien__label}>Numéro</p>
          <p className={styles.bien__label}>Voie</p>
          <p className={styles.bien__label}>Lieu-dit</p>
          <p className={styles.bien__label}>Code postal ou ville</p>
          <p className={styles.bien__label}>Code insee</p>
          <p className={styles.bien__label}>Prix d'acquisition</p>
          <p className={styles.bien__label}>Valeur du bien</p>
        </div>
        {loading ? <BienPlaceholder /> :
          (
            <div className={styles.bien__form}>
              <div className={styles.bien__toggles}>
                <div className={styles.bien__radios} aria-label={`Usage du bien n° ${numero}`}>
                  {USAGE_BIEN_OPTIONS.map(({ label, value }, index) => {
                    const isChecked = patrimoine?.bien?.usage === value
                    return (
                      <ToggleButton
                        label={label}
                        name={`patrimoine.biens.${numero}.usage.${index}`}
                        hasError={hasErrorForName('usage')}
                        aria-invalid={hasErrorForName('usage')}
                        className={classnames(styles.bien__option, {
                          [styles.bien__optionChecked]: isChecked,
                        })}
                        labelClassName={styles.bien__labelOptions}
                        onChange={() => setBien({ usage: value })}
                        checked={isChecked}
                        value={value}
                        key={`${index}-${value}`}
                        aria-label={`Usage Bien - ${numero} - ${label}`}
                      />
                    )
                  })}
                </div>
              </div>
              <div className={styles.bien__toggles}>
                <div className={styles.bien__radios} aria-label={`Nature du bien n° ${numero}`}>
                  {NATURE_BIEN_OPTIONS.map(({ label, value }, index) => {
                    const isChecked = patrimoine?.bien?.nature === value
                    return (
                      <ToggleButton
                        label={label}
                        name={`patrimoine.biens.${numero}.nature.${index}`}
                        className={classnames(styles.bien__option, {
                          [styles.bien__optionChecked]: isChecked,
                        })}
                        labelClassName={styles.bien__labelOption}
                        hasError={hasErrorForName('nature')}
                        aria-invalid={hasErrorForName('nature')}
                        onChange={() => setBien({ nature: value })}
                        checked={isChecked}
                        value={value}
                        key={`${index}-${value}`}
                        aria-label={`Nature Bien - ${numero} - ${label}`}
                      />
                    )
                  })}
                </div>
              </div>
              <Select
                className={styles.bien__inputSelect}
                name={`nature.propriete.${numero}`}
                options={REGIME_PROPRIETE_OPTIONS}
                label="Nature de la propriété"
                placeholder="Nature de la propriété"
                aria-invalid={hasErrorForName('regimePropriete')}
                hasError={hasErrorForName('regimePropriete')}
                onChange={({ value }) => setPatrimoine({ ...patrimoine, regimePropriete: value })}
                value={getRegimeByValue(patrimoine.regimePropriete)}
                hideLabel
              />
              <Button
                type="button"
                className={styles.bien__copyButton}
                aria-label="Copier adresse"
                onClick={copyAdresse}
                outline
                disabled={!adresseIsCopiable}
                size="small"
                leftIcon={Document}
                iconClassName={styles.bien__copyIcon}
              >
                Copier l'adresse de l'Emprunteur 1
              </Button>
              <Input
                className={styles.bien__input}
                name={`patrimoine.biens.${numero}.complément`}
                placeholder="Complément"
                label="Complément"
                aria-label={`Complément du bien n° ${numero}`}
                onChange={({ target }) => setBien({ complement: target.value })}
                value={clearEmpty(patrimoine?.bien?.complement)}
                hideLabel
              />
              <Input
                className={styles.bien__input}
                name={`patrimoine.biens.${numero}.numero`}
                placeholder="Numéro"
                label="Numéro"
                aria-label={`Numéro du bien n° ${numero}`}
                onChange={({ target }) => setBien({ numero: target.value })}
                aria-invalid={hasErrorForName('numero')}
                hasError={hasErrorForName('numero')}
                value={clearEmpty(patrimoine?.bien?.numero)}
                hideLabel
              />
              <Input
                className={styles.bien__input}
                name={`patrimoine.biens.${numero}.voie`}
                placeholder="Voie"
                label="Voie"
                aria-invalid={hasErrorForName('voie')}
                hasError={hasErrorForName('voie')}
                aria-label={`Voie du bien n° ${numero}`}
                onChange={({ target }) => setBien({ voie: target.value })}
                value={clearEmpty(patrimoine?.bien?.voie)}
                hideLabel
              />
              <Input
                className={styles.bien__input}
                name={`patrimoine.biens.${numero}.lieuDit`}
                placeholder="Lieu-dit"
                label="Lieu-dit"
                aria-label={`Lieu-dit du bien n° ${numero}`}
                onChange={({ target }) => setBien({ lieuDit: target.value })}
                value={clearEmpty(patrimoine?.bien?.lieuDit)}
                hideLabel
              />
              <SelectCommunes
                setCodePostalVille={setCodePostalVille}
                citiesOptions={citiesOptions}
                className={styles.bien__inputSelect}
                label={`Code postal ou ville du bien n° ${numero}`}
                name={`patrimoine.biens.${numero}.codePostalVille`}
                onChange={({ value }) => {
                  const data = JSON.parse(value)
                  setBien({
                    codePostalVille: concatString(data.codePostal, data.nom)(' '),
                    codeInsee: data.codeInsee,
                  })
                }}
                aria-invalid={hasErrorForName('codePostalVille')}
                hasError={hasErrorForName('codePostalVille')}
                placeholder="Code postal ou ville"
                value={formatVille(patrimoine?.bien?.codePostalVille)}
                hideLabel
                hideDropdown
              />
              <Select
                className={styles.bien__input}
                name={`patrimoine.biens.${numero}.codeInsee`}
                placeholder="Code insee"
                label={`Code insee du bien n° ${numero}`}
                aria-invalid={hasErrorForName('codeInsee')}
                hasError={hasErrorForName('codeInsee')}
                value={clearEmpty({
                  label: patrimoine?.bien?.codeInsee,
                  value: patrimoine?.bien?.codeInsee,
                })}
                hideLabel
                disabled={!canSelectCodeInsee}
                options={codeInseeOptions}
                onChange={({ value }) => setBien({ codeInsee: value })}
              />

              <InputMontant
                name={`patrimoine.biens.${numero}.prixInitial`}
                aria-label={`Prix d'acquisition du bien n° ${numero}`}
                label="Prix d'acquisition"
                onChange={({ target }) => setBien({ prixInitial: parseMontant(target.value) })}
                className={styles.bien__inputMontant}
                unitClassName={styles.bien__inputMontant__unit}
                aria-invalid={hasErrorForName('prixInitial')}
                hasError={hasErrorForName('prixInitial')}
                placeholder="Prix d'acquisition"
                value={clearEmpty(patrimoine?.bien?.prixInitial)}
                hideLabel
                unit="€"
              />
              <InputMontant
                name={`patrimoine.biens.${numero}.valeur`}
                aria-label={`Valeur du bien n° ${numero}`}
                label="Valeur du bien"
                onChange={({ target }) => setBien({ valeur: parseMontant(target.value) })}
                className={styles.bien__inputMontant}
                unitClassName={styles.bien__inputMontant__unit}
                placeholder="Valeur du bien"
                aria-invalid={hasErrorForName('valeurDuBien')}
                hasError={hasErrorForName('valeurDuBien')}
                value={clearEmpty(patrimoine?.bien?.valeur)}
                hideLabel
                unit="€"
              />
            </div>)}
      </div>
    </>
  )
}
