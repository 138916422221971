import { compareDesc } from 'date-fns'
import { DossierStatut } from '@partenaire/common'
import { SupportStateUI } from '../SupportStateUI'
import { DocumentsUI } from './DocumentsUI.model'
import { TransfertUI } from './TransfertUI.model'

export interface TranfertUICallback {
  refechTransferts(dossierId: string): Promise<TransfertUI[]>
}

export class TransfertsUI extends SupportStateUI {
  constructor(
    private dossierId: string,
    public readonly transfertsUI: TransfertUI[],
    private readonly callback: TranfertUICallback,
    private statut: DossierStatut | undefined,
  ) {
    super()
    this.transfertsUI.sort((a: TransfertUI, b: TransfertUI) => compareDesc(a.props.date, b.props.date))
  }

  public async reload(): Promise<void> {
    const reloadedTransfertsUI = await this.callback.refechTransferts(this.dossierId)
    this.transfertsUI.splice(0, this.transfertsUI.length)
    this.transfertsUI.push(...reloadedTransfertsUI)
    this.refreshState()
  }

  public isEnvoiComplementaire(): boolean {
    return !(this.statut === DossierStatut.SAISIE || this.statut === DossierStatut.TELETRANSMIS)
  }


  public getTransferts(): TransfertUI[] {
    return this.transfertsUI
  }

  private getDossierId(): string|undefined {
    return this.transfertsUI.find(p => p)?.props.dossierId
  }

  public getDownloadDocumentURL(backendUrl: string, documentId: string): string {
    return DocumentsUI.getDownloadDocumentURL(backendUrl, this.getDossierId()!, documentId)
  }

  protected clone() {
    return new TransfertsUI(this.dossierId, this.transfertsUI, this.callback, this.statut)
  }
}
