/* eslint-disable react/require-default-props */
import React, { FunctionComponent } from 'react'
import { ModalTrigger } from '@core/partenaire-ui'
import { WarningConfirmationModal } from '../WarningConfirmationModal/WarningConfirmationModal'

import styles from './WarningPopIn.module.css'

export declare interface WarningPopInProps {
  onConfirm?: () => void
  label?: string
  title?: string
  warningLine?: string
  warningLineBold?: string
  children?: React.ReactNode
  loading?: boolean
  confirmText?: string
  loadingText?: string
  cancelText?: string
}

export const WarningPopIn: FunctionComponent<WarningPopInProps> =
({
  children,
  onConfirm = () => {},
  label = '',
  title = '',
  warningLine = '',
  warningLineBold = '',
  loading = false,
  confirmText = 'Supprimer',
  loadingText = 'Suppression en cours',
  cancelText = 'Annuler',
}) => {
  return (
    <ModalTrigger
      renderButton={(_: any, setModalOpen: (_: boolean) => void) => (
        <div
          className={styles.warningPopIn__removeButton}
          aria-label={label}
          onClick={e => { e.preventDefault(); setModalOpen(true) }}
        >
          {children}
        </div>
      )}
    >
      <WarningConfirmationModal
        loading={loading}
        title={title}
        warningLine={warningLine}
        warningLineBold={warningLineBold}
        onConfirm={() => onConfirm()}
        confirmText={confirmText}
        loadingText={loadingText}
        cancelText={cancelText}
      />
    </ModalTrigger>)
}
