import React from 'react'
import { func } from 'prop-types'

import { Delete } from '@mmb/ui-components'

import styles from '../MoreActions.module.css'

DeleteButton.propTypes = {
  setModalOpen: func.isRequired,
}

export function DeleteButton({ setModalOpen }) {
  const buttonDelete = <Delete />
  return (
    <button
      aria-label="Supprimer"
      className={styles.moreActions__button}
      onClick={() => setModalOpen(true)}
    >
      {buttonDelete}
    </button>
  )
}
