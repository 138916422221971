import { gql, useQueryWithError } from '@core/graphql'
import { ADMINISTRATEUR, SUPER_ADMIN } from '@core/common'
import { useUtilisateurContext } from '@core/partenaire-common'
import { shouldHandleAgences } from '../../utils/const'

const GET_AGENCES = gql`
query getAgences {
  partenaire_agences {
    id
    nom
  }
}
`

const ALL_AGENCES_OPTION = {
  value: '',
  label: 'Toutes les agences',
}

const toAgenceOption = agence => ({ value: agence.id, label: agence.nom })

export function useAgenceOptions(setFormField) {
  const { data, loading } = useQueryWithError(GET_AGENCES, { notifyOnNetworkStatusChange: true, skip: !shouldHandleAgences() })
  // eslint-disable-next-line camelcase
  const availableAgences = data?.partenaire_agences || []
  const agenceOptions = availableAgences.length > 0 ? availableAgences.map(toAgenceOption).concat(ALL_AGENCES_OPTION) : []
  const nomToAgenceOption = agenceNom => ({ value: agenceOptions.find(option => option.label === agenceNom).value, label: agenceNom })
  const onChangeSelectedOptions = selectedAgenceOptions => {
    const hasSelectedAllAgences = selectedAgenceOptions?.find(agence => agence.value === '')
    if (hasSelectedAllAgences) {
      setFormField('agences', availableAgences.map(agence => agence.nom))
    } else {
      setFormField('agences', selectedAgenceOptions?.map(option => option.label) || [])
    }
  }
  return { agenceOptions, nomToAgenceOption, onChangeSelectedOptions, loading }
}

export function useCabinetOptions(setFormField) {
  const { cabinets, loading } = useUtilisateurContext()
  // la liste des cabinets proposés = les cabinets de travail de l'utilisateur courant sur lesquels il est admin ou superadmin
  const cabinetOptions = cabinets
    .filter(cabinet => cabinet.role === ADMINISTRATEUR || cabinet.role === SUPER_ADMIN)
    .map(({ id, libelle }) => ({ value: id, label: libelle }))
    .sort(({ label: a }, { label: b }) => a.toLowerCase().localeCompare(b.toLowerCase()))
  const onChangeCabinet = selectedCabinetOptions => {
    setFormField('cabinets', selectedCabinetOptions?.map(cabinetOption => cabinetOption.value) || [])
  }
  const cabinetToOption = id => cabinetOptions.find(({ value }) => value === id)
  return { cabinetOptions, onChangeCabinet, cabinetToOption, loading }
}
