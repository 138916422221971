const isTestEnv = process.env.NODE_ENV === 'test'

const getAppId = () => {
  let appId = '#___gatsby'
  if (isTestEnv) {
    appId = document.createElement('div')
  }
  return appId
}

export const APP_ELEMENT_ID = getAppId()
