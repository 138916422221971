/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { useUtilisateurContext } from '@core/partenaire-common'
import { Cabinet } from './Cabinet'
import styles from './SelectionCabinetForm.module.css'

/**
 * Contenu de la modale de sélection des cabinets
 */
export function SelectionCabinetForm() {
  const { cabinets, loading } = useUtilisateurContext()

  if (loading) {
    return null
  }

  return (
    <div className={styles.selection_pm_form}>
      <h1>Choix du cabinet</h1>
      <p>Veuillez sélectionner le cabinet pour lequel vous souhaitez faire une acquisition</p>
      {cabinets && cabinets.map(cabinet => <Cabinet key={cabinet.id} cabinet={cabinet} />)}
    </div>
  )
}
