import { gql, useMutation } from '@core/graphql'

const ACTIVATE_ACCOUNT = gql`
  mutation activateAccount($token: String!) {
    partenaire_activateAccount(token: $token)
  }
`
export function useActivateAccount() {
  return useMutation(ACTIVATE_ACCOUNT)
}
