import React, { useContext, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { Input, MagnifyingGlass } from '@mmb/ui-components'

import { searchContext } from '@core/partenaire-common'
import { SelectFilter, Separator } from '@core/partenaire-ui'

import styles from './Filter.module.css'

const statutsOptions = [
  { value: 'EN_ATTENTE', label: 'En attente' },
  { value: 'EN_COURS', label: 'En cours' },
  { value: 'CONFIRME', label: 'Confirmé' },
]

export function Filter() {
  const { search: [search, setSearch], statuts: [, setStatuts] } = useContext(searchContext)
  const [value, setValue] = useState(search)
  const [debounceValue] = useDebounce(value, 500)

  useEffect(() => {
    setSearch(debounceValue)
  }, [debounceValue])

  const inputOnChange = e => {
    setValue(e.target.value)
  }

  return (
    <>
      <Input
        name="userfilter"
        className={styles.filters__input}
        labelClassName={styles.filters__label}
        fieldClassName={styles.filters__field}
        value={value}
        label="Rechercher un utilisateur"
        onChange={inputOnChange}
        placeholder="Nom, email.."
      >
        <MagnifyingGlass className={styles.filters__loupe} />
      </Input>
      <Separator className={styles.filter__separator} />
      <div className={styles.filterContainer}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={styles.labelFilter} htmlFor="statutsSelect">Statut</label>
        <SelectFilter
          name="statutsSelect"
          options={statutsOptions}
          onSelect={setStatuts}
          placeholder="Tous les statuts"
        />
      </div>
    </>
  )
}
