import { DATE_DD_MM_YYYY_FR } from '@core/common'
import { isEmpty } from 'lodash'
import { dateFromGraphQL, dateToGraphQL } from '../../../utils'

export const toGraphQL = ({ __typename: _, ...identiteData }: any) => {
  if (isEmpty(identiteData)) {
    return {}
  }
  const { __typename: __, ...naissanceData } = identiteData?.naissance || {}
  const date = dateToGraphQL(naissanceData?.date, DATE_DD_MM_YYYY_FR)
  return {
    ...identiteData,
    naissance: {
      ...naissanceData,
      ...date && { date },
    },
  }
}

export const toModelUI = identiteGrapQL => ({
  ...identiteGrapQL,
  naissance: {
    ...identiteGrapQL.naissance,
    date: dateFromGraphQL(identiteGrapQL?.naissance?.date, DATE_DD_MM_YYYY_FR),
  },
})
