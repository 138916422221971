import React from 'react'
import { bool, func, string } from 'prop-types'
import { Button, Arrow } from '@mmb/ui-components'
import { useNavigate } from '@core/routing'
import classNames from 'classnames'

import styles from './FormActions.module.css'

FormActions.propTypes = {
  cancelAction: func,
  cancelText: string,
  submitText: string,
  disabledSubmit: bool,
}

FormActions.defaultProps = {
  cancelAction: undefined,
  cancelText: '',
  submitText: '',
  disabledSubmit: false,
}

export function FormActions({ cancelAction, cancelText, submitText, disabledSubmit }) {
  const navigate = useNavigate()
  const doCancelAction = cancelAction || (() => navigate('/mon-espace/mon-profil'))
  return (
    <div className={styles.actions__buttons}>
      <Button
        type="button"
        outline
        onClick={doCancelAction}
        className={classNames(styles.actions__button, styles.actions__cancel)}
      >
        {cancelText}
      </Button>
      <Button
        type="submit"
        rightIcon={Arrow}
        className={styles.actions__button}
        disabled={disabledSubmit}
      >
        {submitText}
      </Button>
    </div>
  )
}
