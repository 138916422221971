import React from 'react'
import { node } from 'prop-types'
import { MyAccessPro } from '@mmb/ui-components'
import { UtilisateurContextProvider, PortailPartenaireProvider, useFetch } from '@core/partenaire-common'
import { GlobalErrorBoundary, HeadProvider } from '@core/ui'
import { ApolloProvider, buildGraphqlClient as coreBuildGraphqlClient } from '@core/graphql'
import { Head } from '../Head/Head'
import { buildGraphqlClient } from '../../graphql'

const defaultGraphqlClient = coreBuildGraphqlClient()

WithApolloProvider.propTypes = {
  children: node.isRequired,
}

function WithApolloProvider({ children }) {
  const fetch = useFetch()
  const client = buildGraphqlClient(fetch)
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

Root.propTypes = {
  element: node.isRequired,
}

export function Root({ element }) {
  return (
    <GlobalErrorBoundary>
      <HeadProvider head={Head}>
        <PortailPartenaireProvider iconHeader={<MyAccessPro />} title="Portail partenaire" name="My Access Pro">
          <WithApolloProvider>
            <UtilisateurContextProvider graphqlClient={defaultGraphqlClient}>
              {element}
            </UtilisateurContextProvider>
          </WithApolloProvider>
        </PortailPartenaireProvider>
      </HeadProvider>
    </GlobalErrorBoundary>
  )
}
