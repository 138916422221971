/* eslint-disable max-len */
import React from 'react'
import { DATE_MM_YYYY } from '../../../model/format'
import { ValidationFieldError } from '../../../model/ValidationFieldError'
import { getBasculeHypothecaireLink } from '../../../utils/gamme'
import { ErrorMessage } from '../../SaisieErrorMessages'

export const ENCOURS_WARNING_MESSAGES = {
  THE_END_IS_NIGH: {
    message: (<>Le CRD de ce prêt ne sera pas inclus dans la simulation si la <strong>date de fin</strong> est inférieure à 90 jours</>),
  },
}

const ENCOURS_ERROR_MESSAGES = ({
  changeGamme,
  isUpdatingGamme,
}) => ({
  debut: {
    DATE_FORMAT_INVALID: {
      title: 'Date de début',
      SECURED: `Veuillez renseigner une date au format ${DATE_MM_YYYY.toLowerCase()}`,
      UNSECURED: `Veuillez renseigner une date au format ${DATE_MM_YYYY.toLowerCase()}`,
    },
    TOO_RECENT: {
      title: 'Montage',
      SECURED: 'Le prêt de regroupement de crédits hypothécaire doit avoir plus d\'un an.',
      UNSECURED: 'Le prêt de regroupement de crédits conso doit avoir plus d\'un an.',
    },
    AFTER_TODAY: {
      title: 'Date de début',
      SECURED: 'Veuillez renseigner une date de début inférieure à la date du jour.',
      UNSECURED: 'Veuillez renseigner une date de début inférieure à la date du jour.',
    },
  },
  fin: {
    DATE_FORMAT_INVALID: {
      title: 'Date de fin',
      SECURED: `Veuillez renseigner une date au format ${DATE_MM_YYYY.toLowerCase()}`,
      UNSECURED: `Veuillez renseigner une date au format ${DATE_MM_YYYY.toLowerCase()}`,
    },
    DATE_END_INVALID: {
      title: 'Date de fin',
      SECURED: 'Veuillez renseigner une date de fin supérieure à la date du jour.',
      UNSECURED: 'Veuillez renseigner une date de fin supérieure à la date du jour.',
    },
  },
  nature: {
    CANT_KEEP: {
      title: 'Montage',
      SECURED: 'Des prêts ou dettes non rachetées ne sont pas autorisées. Merci de revoir votre montage. ',
      UNSECURED: 'Des prêts ou dettes non rachetées ne sont pas autorisées. Merci de revoir votre montage. ',
    },
    CANT_KEEP_UNSECURED_CONSO: {
      title: 'Montage',
      UNSECURED: 'Le client ne peut pas conserver un prêt de regroupement de crédits conso. Merci de revoir le plan de financement.',
    },
    SECURED_NATURE_SOULTE_INVALID: {
      title: 'Montage',
      SECURED: 'Une soulte immobilière ne peut pas être conservée. Merci de revoir le montage.',
    },
    SECURED_ONLY: {
      title: 'Montage',
      UNSECURED: (
        <>
          Les soultes immobilières, les dettes fiscales hors année en cours, huissiers et professionnelles ainsi que les retards de loyer et de charges de co-propriété sont exclus en sans garantie.
          {getBasculeHypothecaireLink(changeGamme, isUpdatingGamme, 'Merci de basculer en hypothécaire.')}
        </>),
    },
    NO_UNSECURED_RESTRUCTURATION_HYPOTHECAIRE: {
      title: 'Montage',
      UNSECURED: (
        <>
          Le client a un prêt de regroupement de crédits hypothécaire en cours,
          {getBasculeHypothecaireLink(changeGamme, isUpdatingGamme, 'merci de basculer en hypothécaire.')}
        </>),
    },
  },
  montantIra: {
    TAUX_ZERO: {
      title: 'Prêt taux zéro',
      SECURED: 'L\'IRA doit être égale à 0.',
      UNSECURED: 'L\'IRA doit être égale à 0.',
    },
    WRONG_NATURE: {
      title: 'IRA',
      SECURED: 'Le découvert et le crédit renouvelable ne sont pas soumis à des IRA.',
      UNSECURED: 'Le découvert et le crédit renouvelable ne sont pas soumis à des IRA.',
    },
  },
  taux: {
    WRONG_TAUX: {
      title: 'Taux',
      SECURED: 'Le taux doit être compris entre 0 et 100 %.',
      UNSECURED: 'Le taux doit être compris entre 0 et 100 %.',
    },
    TAUX_ZERO: {
      title: 'Prêt taux zéro',
      SECURED: 'Le taux doit être égal à 0.',
      UNSECURED: 'Le taux doit être égal à 0.',
    },
  },
  finalite: {
    WRONG_NATURE: {
      title: 'Approche investisseur',
      SECURED: 'La nature du  prêt ne permet pas de sélectionner une approche investisseur. Merci de la décocher pour poursuivre votre saisie.',
      UNSECURED: 'La nature du  prêt ne permet pas de sélectionner une approche investisseur. Merci de la décocher pour poursuivre votre saisie.',
    },
    WRONG_RACHAT: {
      title: 'Approche investisseur',
      SECURED: 'Le rachat du  prêt ne permet pas de sélectionner une approche investisseur. Merci de la décocher pour poursuivre votre saisie.',
      UNSECURED: 'Le rachat du  prêt ne permet pas de sélectionner une approche investisseur. Merci de la décocher pour poursuivre votre saisie.',
    },
    NO_INVEST: {
      title: 'Approche investisseur',
      SECURED: 'Elle n\'est pas autorisée pour le produit My New Tréso',
    },
  },
  preavis: {
    WRONG_NATURE: {
      title: 'Préavis',
      SECURED: 'Le découvert et le crédit renouvelable ne sont pas soumis à un préavis.',
      UNSECURED: 'Le découvert et le crédit renouvelable ne sont pas soumis à un préavis.',
    },
  },
  encours: {
    ENCOURS_REQUIRED: {
      title: 'Information requise',
      UNSECURED: 'Veuillez renseigner a minima un prêt ou une dette.',
    },
    SINGLE_RACHAT_UNAUTHORIZED_FAMILLE_FISCALE: {
      title: 'Montage',
      SECURED: `Le rachat seul d'une dette privée ou familiale ou d'une dette fiscale n'est pas autorisé. 
        Merci de revoir le plan de financement en mettant le montant dans la trésorerie pour basculer en My New Tréso.`,
      UNSECURED: `Le rachat seul d'une dette privée ou familiale ou d'une dette fiscale n'est pas autorisé. 
        Merci de revoir le plan de financement en mettant le montant dans la trésorerie pour basculer en My New Tréso.`,
    },
    SINGLE_RACHAT_UNAUTHORIZED_GENERIC: {
      title: 'Montage',
      SECURED: 'Le rachat seul de ce prêt ou dette n’est pas autorisé. Merci de revoir votre montage.',
    },
  },
})

export class EncoursErrorMessage {
  constructor(public readonly message: any, public readonly title: string) {}

  public static getMessage(error: ValidationFieldError,
    props : {
      gamme: string
      changeGamme: () => void
      isUpdatingGamme: boolean
  }): ErrorMessage|null {
    if (!error.message) {
      return null
    }
    const message = ENCOURS_ERROR_MESSAGES(props)[error.field]?.[error.message]?.[props.gamme]
    const title = ENCOURS_ERROR_MESSAGES(props)[error.field]?.[error.message]?.title
    return message ? new EncoursErrorMessage(message, title) : null
  }
}
