import React, { useContext } from 'react'
// eslint-disable-next-line import/no-relative-packages
import { NATURE_PRET_MAP } from '../../../../../dossiers/src/model/DetailPlanFinancement.model'
import styles from './ToPrintDocument.module.css'
import { FormStateContext } from '../../Contexts/FormStateContext'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { EncoursType } from '../../../model'
import { SOURCE_PRET_MAP } from '../../../model/encours/PretFormUI'

/**
 * Contenu de dossier à imprimer ou à télécharger
 */

let counterDette = 0

function Dette(encours) {
  counterDette += 1
  return (
    <>
      <div className={styles.printDocument__subtitle}>
        <div>
          <span className={styles.printDocument__cell}>Dette {counterDette}</span>
          <span className={styles.printDocument__column__inside__right}> {encours.repris ? 'A racheter' : 'A conserver'}</span>
          <hr />
        </div>
      </div>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Nature de la dette</p>
          <p className={styles.printDocument__cell}>Bénéficiaire</p>
          <p className={styles.printDocument__cell}>Date de début</p>
          <p className={styles.printDocument__cell}>Date de fin</p>
          <p className={styles.printDocument__cell}>CRD total (€)</p>
          <p className={styles.printDocument__cell}>Capital initial (€)</p>
          <p className={styles.printDocument__cell}>Référence</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__cell}>{NATURE_PRET_MAP.get(encours.nature)}</p>
            <p className={styles.printDocument__cell}>{encours.nomPreteur}</p>
            <p className={styles.printDocument__cell}>{encours.debut}</p>
            <p className={styles.printDocument__cell}>{encours.fin}</p>
            <p className={styles.printDocument__cell}>{encours.montantCrd}</p>
            <p className={styles.printDocument__cell}>{encours.montantCapitalInitial}</p>
            <p className={styles.printDocument__cell}>{encours.reference}</p>
          </div>
        </div>
      </div>
    </>
  )
}

function Pret(encours) {
  counterDette = 0
  return (
    <>
      <div className={styles.printDocument__subtitle}>
        <div>
          <span className={styles.printDocument__cell}>{encours.titre}</span>
          <span className={styles.printDocument__column__inside__right}> {encours.repris ? 'A racheter' : 'A conserver'}</span>
          <hr />
        </div>
      </div>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Nature du prêt</p>
          <p className={styles.printDocument__cell}>Nom du prêteur</p>
          <p className={styles.printDocument__cell}>Régime</p>
          <p className={styles.printDocument__cell}>Date de début</p>
          <p className={styles.printDocument__cell}>Date de fin</p>
          <p className={styles.printDocument__cell}>Source fin</p>
          <p className={styles.printDocument__cell}>Référence</p>
          <p className={styles.printDocument__cell}>CRD total (€)</p>
          <p className={styles.printDocument__cell}>Capital initial (€)</p>
          <p className={styles.printDocument__cell}>IRA (€)</p>
          <p className={styles.printDocument__cell}>Source IRA</p>
          <p className={styles.printDocument__cell}>Taux (%)</p>
          <p className={styles.printDocument__cell}>Source Taux</p>
          <p className={styles.printDocument__cell}>{encours.paliers[0]?.montantEcheance ? 'Echéance (€)' : ''}</p>
          <p className={styles.printDocument__cell}>{encours.preavis ? "Présence d'un un préavis (jours)" : ''}</p>
          <p className={styles.printDocument__cell}>{encours.finalite ? 'Approche investisseur' : ''}</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__cell}>{NATURE_PRET_MAP.get(encours.nature)}</p>
            <p className={styles.printDocument__cell}>{encours.nomPreteur}</p>
            <p className={styles.printDocument__cell}>{encours.regime}</p>
            <p className={styles.printDocument__cell}>{encours.debut}</p>
            <p className={styles.printDocument__cell}>{encours.fin}</p>
            <p className={styles.printDocument__cell}>{SOURCE_PRET_MAP.get(encours.sourceFin)}</p>
            <p className={styles.printDocument__cell}>{encours.reference}</p>
            <p className={styles.printDocument__cell}>{encours.montantCrd}</p>
            <p className={styles.printDocument__cell}>{encours.montantCapitalInitial}</p>
            <p className={styles.printDocument__cell}>{encours.montantIra}</p>
            <p className={styles.printDocument__cell}>{SOURCE_PRET_MAP.get(encours.sourceIra)}</p>
            <p className={styles.printDocument__cell}>{encours.taux}</p>
            <p className={styles.printDocument__cell}>{SOURCE_PRET_MAP.get(encours.sourceTaux)}</p>
            <p className={styles.printDocument__cell}>{encours.paliers[0]?.montantEcheance ? encours.paliers[0]?.montantEcheance : ''}</p>
            <p className={styles.printDocument__cell}>{encours.preavis ?? ''}</p>
            <p className={styles.printDocument__cell}>{encours.finalite ? 'OUI' : ''}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export function ToPrintEncours() {
  const formState = useContext(FormStateContext)
  const { value: encoursValue } = formState.getEntryFormState(EntryFormStateName.EN_COURS)
  const encours = encoursValue.encoursUI

  const encoursTotalARacheter = encours.list.map(enc => ((enc.repris && enc.montantCrd) ?
    enc.montantCrd + (enc.montantIra ?? 0) : 0)).reduce((total, courant) => total + courant, 0)

  const encoursEcheancesARacheter = encours.list.map(enc => (
    enc.repris ? enc.paliers?.map(palier => (palier.montantEcheance ? palier.montantEcheance : 0),
    )?.reduce((total, courant) => total + courant, 0) : 0) ?? 0,
  ).reduce((total, courant) => total + courant, 0)

  const encoursTotalAConserver = encours.list.map(enc => (!enc.repris && enc.montantCrd ? enc.montantCrd : 0)).reduce((total, courant) => total + courant, 0)

  const encoursEcheancesAConserver = encours.list.map(enc => (
    !enc.repris ? enc.paliers?.map(palier => (palier.montantEcheance ? palier.montantEcheance : 0),
    ).reduce((total, courant) => total + courant, 0) : 0),
  ).reduce((total, courant) => total + courant, 0)

  return (
    <div>
      <h1 className={styles.printDocument__headband}>
        <div className={styles.printDocument__headband__text}>
          ENCOURS
        </div>
      </h1>
      {encours.list.map(enc => enc.type === EncoursType.PRET ? Pret(enc) : Dette(enc))}

      <div className={styles.printDocument__subtitle}>
        <h1>Encours à racheter</h1>
        <hr />
      </div>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Total CRD (€)</p>
          <p className={styles.printDocument__cell}>Total échéances (€)</p>

        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__cell}> {encoursTotalARacheter} </p>
            <p className={styles.printDocument__cell}> {encoursEcheancesARacheter} </p>

          </div>
        </div>
      </div>
      <div className={styles.printDocument__subtitle}>
        <h1>Encours à conserver</h1>
        <hr />
      </div>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Total CRD (€)</p>
          <p className={styles.printDocument__cell}>Total échéances (€)</p>

        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__cell}> {encoursTotalAConserver} </p>
            <p className={styles.printDocument__cell}> {encoursEcheancesAConserver} </p>
          </div>
        </div>
      </div>
    </div>
  )
}
