import { download } from '@core/common-frontend'

export const useDocumentDownload = (idDocument: string, contentType: string) => {
  const url = `${(window as any).GATSBY_API_URL}/outils/documents/${idDocument}?portail=${(window as any).GATSBY_PORTAL}`
  const downloadOrStream = (setOpen: (open: boolean) => void): void => {
    if (contentType === 'video/mp4') {
      setOpen(true)
    } else {
      download(url)
    }
  }
  return {
    downloadOrStream,
    url,
  }
}
