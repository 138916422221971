import React from 'react'
import { func, node, shape, string } from 'prop-types'
import classNames from 'classnames'
import { Arrow, ButtonLink, Card, Layout } from '@mmb/ui-components'
import { Link } from '@core/routing'

import styles from './ErrorPage.module.css'

ErrorPage.propTypes = {
  children: node,
  firstButton: shape({
    label: string.isRequired,
    action: func.isRequired,
  }),
  secondButton: shape({
    label: string.isRequired,
    action: func.isRequired,
  }),
}

ErrorPage.defaultProps = {
  children: undefined,
  firstButton: {
    label: 'Retour à l\'accueil',
    url: '/',
  },
  secondButton: {
    label: 'Contactez-nous',
    url: '/contact/',
  },
}

export function ErrorPage({ children, firstButton, secondButton }) {
  return (
    <Layout>
      <Layout.Header label="Un problème est survenu" />
      <Layout.Body>
        <Card className={styles.errorpage__card}>
          {children}
          <div className={styles.errorpage__buttons}>
            <ButtonLink
              to={firstButton.url}
              linkComponent={Link}
              rightIcon={Arrow}
              className={classNames(styles.errorpage__button, styles.errorpage__firstbutton)}
            >
              {firstButton.label}
            </ButtonLink>
            <ButtonLink
              outline
              to={secondButton.url}
              linkComponent={Link}
              className={styles.errorpage__button}
            >
              {secondButton.label}
            </ButtonLink>
          </div>
        </Card>
      </Layout.Body>
    </Layout>
  )
}
