import { useEffect, useState } from 'react'
import { AssuranceFormUI, AssuranceUI } from '../../model'

export const useAssuranceUI = (hasCoEmprunteur: boolean) => {
  const [assuranceUI, setAssuranceUI] = useState(new AssuranceUI(new AssuranceFormUI(), new AssuranceFormUI(), hasCoEmprunteur))
  useEffect(() => {
    setAssuranceUI(assuranceUI.withUpdateState(setAssuranceUI))
  }, [assuranceUI])

  useEffect(() => {
    setAssuranceUI(new AssuranceUI(assuranceUI.emprunteurAssurance, assuranceUI.coEmprunteurAssurance, hasCoEmprunteur))
  }, [hasCoEmprunteur])
  return assuranceUI
}
