import { DOSSIER_STATUTS_LIBELLES, Gamme } from '@partenaire/common'

export interface StatutStep {
  statut: string
  dateModif: string
}

export type HistoriqueStatut = StatutStep[]

export function formatHistorique(historique: { statut: string, dateModif: string }[], gamme: Gamme) : HistoriqueStatut {
  if (!historique || historique.length < 1) return []
  return historique.filter(({ statut }) => {
    const labelElem = DOSSIER_STATUTS_LIBELLES
      .find(({ value, isSecured }) => value === statut && (isSecured === undefined || isSecured === (gamme === Gamme.SECURED)))
    if (labelElem) return true
    return false
  })
}
