import React, { useContext } from 'react'
import styles from './ToPrintDocument.module.css'
import { FormStateContext } from '../../Contexts/FormStateContext'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { DossierInfoFormat } from './DossierInfoFormat'

/**
 * Contenu de dossier à imprimer ou à télécharger
 */

function getChildrenUnder18(enfants) {
  return (enfants.filter(enfant => enfant.age < 18)).length
}

function IdentityCoEmprunteur(coEmprunteur) {
  return (
    <div className={styles.printDocument__column__answer}>
      {/* column 3 */}
      <p className={styles.printDocument__header}> Emprunteur 2</p>
      <p className={styles.printDocument__cell}>{coEmprunteur.civilite}</p>
      <p className={styles.printDocument__cell}>{coEmprunteur.nom}</p>
      <DossierInfoFormat className={styles.printDocument__cell}>{coEmprunteur.naissance?.nom}</DossierInfoFormat>
      <p className={styles.printDocument__cell}>{coEmprunteur.prenom}</p>
      <p className={styles.printDocument__cell}>{coEmprunteur.naissance?.date}</p>
      <p className={styles.printDocument__cell}>{coEmprunteur.naissance?.ville}</p>
      <p className={styles.printDocument__cell}>{coEmprunteur.naissance?.departement}</p>
      <p className={styles.printDocument__cell}>{coEmprunteur.nationalite}</p>
    </div>
  )
}

function ContactCoEmprunteur({ adresse, communications }: any) {
  const coEmprunteurAdresse = adresse.coEmprunteurAdresse
  const coEmprunteurCommunications = communications.coEmprunteurCommunications

  return (
    <div className={styles.printDocument__column__answer}>
      {/* column 3 */}
      <p className={styles.printDocument__header}> Emprunteur 2</p>
      <DossierInfoFormat className={styles.printDocument__cell}>{coEmprunteurAdresse.complement}</DossierInfoFormat>
      <DossierInfoFormat className={styles.printDocument__cell}>{coEmprunteurAdresse.numero}</DossierInfoFormat>
      <DossierInfoFormat className={styles.printDocument__cell}>{coEmprunteurAdresse.voie}</DossierInfoFormat>
      <DossierInfoFormat className={styles.printDocument__cell}>{coEmprunteurAdresse.lieuDit}</DossierInfoFormat>
      <p className={styles.printDocument__cell}>{coEmprunteurAdresse.codePostal} {coEmprunteurAdresse.ville}</p>
      <DossierInfoFormat className={styles.printDocument__cell}>{coEmprunteurAdresse.statut}</DossierInfoFormat>
      <DossierInfoFormat className={styles.printDocument__cell}>{coEmprunteurCommunications.mobile?.value}</DossierInfoFormat>
      <DossierInfoFormat className={styles.printDocument__cell}>{coEmprunteurCommunications.domicile?.value}</DossierInfoFormat>
      <DossierInfoFormat className={styles.printDocument__cell}>{coEmprunteurCommunications.email?.value}</DossierInfoFormat>
    </div>
  )
}
export function ToPrintSituationPersonnelle() {
  const formState = useContext(FormStateContext)
  const { value: identite } = formState.getEntryFormState(EntryFormStateName.IDENTITE)
  const emprunteur = identite.emprunteur
  const coEmprunteur = identite.coEmprunteur

  const { value: situationFamiliale } = formState.getEntryFormState(EntryFormStateName.SITUATION_FAMILIALE)
  const famille = situationFamiliale.situationFamiliale

  const { value: adresse } = formState.getEntryFormState(EntryFormStateName.ADRESSE)
  const { value: communications } = formState.getEntryFormState(EntryFormStateName.COMMUNICATIONS)

  return (
    <div>
      <h1 className={styles.printDocument__headband}>
        <div className={styles.printDocument__headband__text}>
          SITUATION PERSONNELLE
        </div>
      </h1>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles__withEmprunteur}>
          <p className={styles.printDocument__cell}>Civilité</p>
          <p className={styles.printDocument__cell}>Nom</p>
          <p className={styles.printDocument__cell}>Nom de naissance</p>
          <p className={styles.printDocument__cell}>Prénom</p>
          <p className={styles.printDocument__cell}>Date de naissance</p>
          <p className={styles.printDocument__cell}>Ville de naissance</p>
          <p className={styles.printDocument__cell}>Département de naissance</p>
          <p className={styles.printDocument__cell}>Nationalité</p>
          <p className={styles.printDocument__cell}>Nombre d'enfants</p>
          <div>
            {famille.enfants.lenght !== 0 ? famille.enfants.map(enfant => (<p>Enfant {enfant.order}</p>)) : ''}
          </div>
          <p>Situation familiale</p>
          <p>{famille.typeOfInstance ? 'Type d instance' : ''}</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__header}> Emprunteur 1</p>
            <p className={styles.printDocument__cell}>{emprunteur.civilite} </p>
            <p className={styles.printDocument__cell}>{emprunteur.nom} </p>
            <DossierInfoFormat className={styles.printDocument__cell}>{emprunteur.naissance.nom}</DossierInfoFormat>
            <p className={styles.printDocument__cell}>{emprunteur.prenom}</p>
            <p className={styles.printDocument__cell}>{emprunteur.naissance.date}</p>
            <p className={styles.printDocument__cell}>{emprunteur.naissance.ville}</p>
            <p className={styles.printDocument__cell}>{emprunteur.naissance.departement} </p>
            <p className={styles.printDocument__cell}>{emprunteur.nationalite}</p>
            <p className={styles.printDocument__cell}>
              <span> {famille.enfants.length} </span>
              <span className={styles.printDocument__column__inside}> Dont à charge </span>
              <span> {famille.enfants.length !== 0 ? getChildrenUnder18(famille.enfants) : 0}
              </span>
            </p>
            <div>
              {famille.enfants.lenght !== 0 ? famille.enfants.map(enfant => (<p>{enfant.age} ans</p>)) : ''}

            </div>
            <p className={styles.printDocument__cell}>{famille.situation}</p>
            <p className={styles.printDocument__cell}>{famille.typeOfInstance ?? ''}</p>
          </div>
        </div>
        {identite.hasCoEmprunteur ? <IdentityCoEmprunteur {...coEmprunteur} /> : ''}
      </div>
      <div className={styles.printDocument__subtitle}>
        <h1>Informations de contact</h1>
        <hr />
      </div>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles__withEmprunteur}>
          <p className={styles.printDocument__cell}>Complement</p>
          <p className={styles.printDocument__cell}>N°</p>
          <p className={styles.printDocument__cell}>Voie</p>
          <p className={styles.printDocument__cell}>Lieu-dit</p>
          <p className={styles.printDocument__cell}>Code postal ou ville</p>
          <p className={styles.printDocument__cell}>Statut lieu de résidence</p>
          <p className={styles.printDocument__cell}>Téléphone portable</p>
          <p className={styles.printDocument__cell}>Téléphone fixe</p>
          <p className={styles.printDocument__cell}>Email</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__header}> Emprunteur 1</p>
            <DossierInfoFormat className={styles.printDocument__cell}> {adresse.emprunteurAdresse.complement}</DossierInfoFormat>
            <DossierInfoFormat className={styles.printDocument__cell}>{adresse.emprunteurAdresse.numero} </DossierInfoFormat>
            <DossierInfoFormat className={styles.printDocument__cell}>{adresse.emprunteurAdresse.voie}</DossierInfoFormat>
            <DossierInfoFormat className={styles.printDocument__cell}>{adresse.emprunteurAdresse.lieuDit}</DossierInfoFormat>
            <p className={styles.printDocument__cell}> {adresse.emprunteurAdresse.codePostal} {adresse.emprunteurAdresse.ville}</p>
            <DossierInfoFormat className={styles.printDocument__cell}>{adresse.emprunteurAdresse.statut}</DossierInfoFormat>
            <DossierInfoFormat className={styles.printDocument__cell}>{communications.emprunteurCommunications.mobile?.value}</DossierInfoFormat>
            <DossierInfoFormat className={styles.printDocument__cell}>{communications.emprunteurCommunications.domicile?.value}</DossierInfoFormat>
            <DossierInfoFormat className={styles.printDocument__cell}>{communications.emprunteurCommunications.email?.value}</DossierInfoFormat>
          </div>
        </div>
        {identite.hasCoEmprunteur ? <ContactCoEmprunteur adresse={adresse} communications={communications} /> : ''}
      </div>
    </div>
  )
}
