export enum AmorcageAdhesionErreur {
    AGENCE_NON_AUTORISEE = 'AGENCE_NON_AUTORISEE',
    UTILISATEUR_DESACTIVEE = 'UTILISATEUR_DESACTIVEE',
    DOUBLON_PARCOURS_ASSURANCE = 'DOUBLON_PARCOURS_ASSURANCE',
    NATIONALITE_NON_AUTORISEE = 'NATIONALITE_NON_AUTORISEE',
    PAYS_NAISSANCE_NON_AUTORISE = 'PAYS_NAISSANCE_NON_AUTORISE',
    FORMAT_DONNEES_INVALIDE = 'FORMAT_DONNEES_INVALIDE',
    FORMAT_EMAIL_INVALIDE = 'FORMAT_EMAIL_INVALIDE',
    ERREUR_TECHNIQUE = 'ERREUR_TECHNIQUE'
}
