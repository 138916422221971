import React, { useContext, useEffect, useState } from 'react'

import { searchContext } from '@core/partenaire-common'
import { Select } from '@core/partenaire-ui'

import { SORT_BY_OPTIONS } from '../../utils/const'

import styles from './Sort.module.css'

export function Sort() {
  const { sort: [sort, setSort] } = useContext(searchContext)
  const initialValue = SORT_BY_OPTIONS.find(o => o.value === sort)
  const [value, setValue] = useState(initialValue)

  useEffect(() => setSort(value.value), [value])

  return (
    <Select
      options={SORT_BY_OPTIONS}
      onChange={setValue}
      name="sortUsers"
      label="Trier par"
      value={value}
      className={styles.sort}
    />
  )
}
