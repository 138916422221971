import { isEmpty } from 'lodash'

const VALIDATION_ERROR_MESSAGES = {
  ERROR_FORM: {
    title: 'Erreur dans le formulaire',
    message: 'Veuillez remplir l\'ensemble des champs obligatoires',
  },
  MISSING_CAPTCHA: {
    title: 'Captcha',
    message: 'Veuillez cocher le captcha pour valider le formulaire',
  },
  MOT_DE_PASSE_INCORRECT: {
    title: 'Mot de passe incorrect',
    message: 'Les mots de passe saisis sont différents. Veuillez vérifier les données et réessayer.',
  },
}

function buildValidationError(field, message) {
  return { message, field }
}

const validateForm = values => {
  const errors: any[] = []
  if (isEmpty(values?.civilite)) errors.push(buildValidationError('civilite', 'MISSING_VALUE'))
  if (isEmpty(values?.nom)) errors.push(buildValidationError('nom', 'MISSING_VALUE'))
  if (isEmpty(values?.prenom)) errors.push(buildValidationError('prenom', 'MISSING_VALUE'))
  if (isEmpty(values?.password)) errors.push(buildValidationError('password', 'MISSING_VALUE'))
  if (isEmpty(values?.confirmPassword)) errors.push(buildValidationError('confirmPassword', 'MISSING_VALUE'))
  if (isEmpty(values?.captcha)) errors.push(buildValidationError('captcha', 'MISSING_CAPTCHA'))
  if (values.password !== values.confirmPassword) {
    errors.push(buildValidationError('password', 'MOT_DE_PASSE_INCORRECT'))
    errors.push(buildValidationError('confirmPassword', 'MOT_DE_PASSE_INCORRECT'))
  }
  if (errors.length) errors.push(buildValidationError('form', 'ERROR_FORM'))
  return errors
}

export const validateSignUp = values => {
  const formErrors = validateForm(values)
  const hasErrors = formErrors.length > 0
  return {
    globalErrors: formErrors.map(error => VALIDATION_ERROR_MESSAGES[error.message]).filter(error => error),
    signUpErrors: formErrors,
    hasErrors,
  }
}

export const hasErrorForName = (name, errors) => {
  if (!Array.isArray(errors)) return false
  const findError = value => errors.find(({ field }) => value === field)
  if (Array.isArray(name)) {
    return Boolean(name.some(findError))
  }
  return Boolean(findError(name))
}
