import React from 'react'
import classNames from 'classnames'
import { node, string } from 'prop-types'

import styles from './VerticalSteps.module.css'

VerticalSteps.propTypes = {
  children: node.isRequired,
  className: string,
}

VerticalSteps.defaultProps = {
  className: '',
}

export function VerticalSteps({ className, children, ...props }) {
  return (
    <div
      {...props}
      className={classNames(
        styles.verticalSteps,
        className,
      )}
    >
      {children}
    </div>
  )
}
