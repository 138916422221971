import React, { useState } from 'react'
import { node, string, oneOf, bool } from 'prop-types'
import classnames from 'classnames'

import { HelpSmall, Popover, Alert } from '@mmb/ui-components'

import styles from './Tooltip.module.css'

Tooltip.propTypes = {
  content: node.isRequired,
  title: string.isRequired,
  position: oneOf(['top', 'bottom', 'left', 'right']),
  wrapperClassName: string,
  className: string,
  arrowClassName: string,
  warning: bool,
}

Tooltip.defaultProps = {
  position: 'top',
  wrapperClassName: '',
  className: '',
  arrowClassName: '',
  warning: false,
}

export function Tooltip({ content, title, position, wrapperClassName, className, arrowClassName, warning, ...props }) {
  const [isPopoverOpen, setPopoverOpen] = useState(false)

  return (
    <Popover
      title={title}
      open={isPopoverOpen}
      wrapperClassName={classnames(styles.tooltip__wrapper, wrapperClassName)}
      className={classnames(styles.tooltip__popover, className)}
      arrowClassName={classnames(styles.tooltip__popover, arrowClassName)}
      onClick={() => setPopoverOpen(!isPopoverOpen)}
      position={position}
      content={(
        <div
          className={styles.tooltip__content}
          onClick={() => setPopoverOpen(false)}
        >
          {content}
        </div>
      )}
      {...props}
    >
      {warning ?
        <Alert className={styles.tooltip__warningIcon} aria-label={`Infobulle warning ${title}`} />
        :
        <HelpSmall className={styles.tooltip__icon} aria-label={`Infobulle ${title}`} />}
    </Popover>
  )
}
