import { isAfter } from 'date-fns'
import { isEmpty } from 'lodash'
import { isValidDate, isValidDateMinAge, parseDate } from '@core/common'
import { DATE_MM_YYYY } from '../format'
import { ValidationFieldError } from '../ValidationFieldError'
import { ALL_NATURE_DETTE, SECURED_NATURE_DETTE } from './const'
import { DetteFormUI, NatureDette } from './DetteFormUI'

const unAuthorizedNaturesToKeepInSecured = [
  NatureDette.DETTE_FISCALE,
  NatureDette.DETTE_HUISSIER,
  NatureDette.DETTE_PRIVEE,
  NatureDette.DETTE_SOCIETE_DE_RECOUVREMENT,
  NatureDette.RETARD_DE_CHARGES_DE_COPRO,
  NatureDette.RETARD_DE_LOYER,
  NatureDette.DETTE_PRIVEE,
]

const isSecuredException = (nature: NatureDette, dateDebut: string | undefined, isRepris: boolean) => (nature === NatureDette.DETTE_FISCALE
  && !!dateDebut
  && !isValidDateMinAge(dateDebut, 1, undefined)
  && isRepris)

export function validateDette(
  { nature, beneficiaire, debut, fin, montantCrd, repris, montantCapitalInitial, montantEcheance }: DetteFormUI,
  gamme: string,
  currentdate: string,
) {
  const avecGarantie = gamme === 'SECURED'
  const errors: ValidationFieldError[] = []
  const dateDebut = parseDate(debut, DATE_MM_YYYY)
  const dateDebutString = dateDebut?.toLocaleDateString()

  if (!nature) {
    errors.push({ field: 'nature', message: 'MISSING_VALUE' })
  }

  if (nature) {
    if (!ALL_NATURE_DETTE.includes(nature)) {
      errors.push({ field: 'nature', message: 'MISSING_VALUE' })
    }

    if (!avecGarantie && SECURED_NATURE_DETTE.includes(nature) && !isSecuredException(nature, dateDebutString, repris)) {
      errors.push({ field: 'nature', message: 'SECURED_ONLY' })
    }
  }

  if (isEmpty(beneficiaire)) {
    errors.push({ field: 'beneficiaire', message: 'MISSING_VALUE' })
  }

  if (debut && !isValidDate(debut, DATE_MM_YYYY)) {
    errors.push({ field: 'debut', message: 'DATE_FORMAT_INVALID' })
  }

  if (!montantCrd || montantCrd === 0) {
    errors.push({ field: 'montantCrd', message: 'MISSING_VALUE' })
  }

  if (!!montantCrd && montantCrd < 0) {
    errors.push({ field: 'montantCrd', message: 'NEGATIVE_VALUE' })
  }
  if (!!montantCapitalInitial && montantCapitalInitial < 0) {
    errors.push({ field: 'montantCapitalInitial', message: 'NEGATIVE_VALUE' })
  }
  if (!!montantEcheance && montantEcheance < 0) {
    errors.push({ field: 'montantEcheance', message: 'NEGATIVE_VALUE' })
  }

  if (dateDebut && isAfter(dateDebut, new Date())) {
    errors.push({ field: 'debut', message: 'AFTER_TODAY' })
  }

  if (avecGarantie && !repris && nature && unAuthorizedNaturesToKeepInSecured.includes(nature)) {
    errors.push({ field: 'nature', message: 'CANT_KEEP' })
  }

  if (avecGarantie && !repris && nature === NatureDette.SOULTE) {
    errors.push({ field: 'nature', message: 'SECURED_NATURE_SOULTE_INVALID' })
  }

  if (!avecGarantie && repris && nature === NatureDette.DETTE_FISCALE && debut && isValidDateMinAge(dateDebutString, 1, undefined)) {
    errors.push({ field: 'debut', message: 'TOO_OLD' })
  }

  if (!avecGarantie && repris && !debut) {
    errors.push({ field: 'debut', message: 'MISSING_VALUE' })
  }

  if (fin) {
    if (!isValidDate(fin, DATE_MM_YYYY)) {
      errors.push({ field: 'fin', message: 'DATE_FORMAT_INVALID' })
    } else if (!isAfter(parseDate(fin, DATE_MM_YYYY)!, parseDate(currentdate, DATE_MM_YYYY)!)) {
      errors.push({ field: 'fin', message: 'DATE_END_INVALID' })
    }
  }
  return errors
}
