import { useEffect } from 'react'
import { node, string } from 'prop-types'
import { GraphQLErrorEmitter, UNAUTHENTICATED, FORBIDDEN } from '@core/graphql'
import { useLocation, useNavigate } from '@core/routing'

GraphQLErrorsHandler.propTypes = {
  children: node,
  path: string,
}

GraphQLErrorsHandler.defaultProps = {
  children: null,
  path: '/mon-espace',
}

export function GraphQLErrorsHandler({ children, path }) {
  const navigate = useNavigate()
  // note : on ne peut pas utiliser useNavigate() ici car nous ne sommes pas au sein d'un router @reach/router
  const location = useLocation()
  useEffect(() => {
    function redirectToDeconnexionOnce() {
      const deconnexionPath = `${path}/deconnexion`
      const connextionPath = `${path}/connexion`
      const pages = [connextionPath, deconnexionPath]

      if (!pages.includes(location.pathname)) {
        navigate(deconnexionPath, { replace: true })
      }
    }

    function redirectTo404NotFoundOnce() {
      const NotFoundPath = '/404'

      if (NotFoundPath !== location.pathname) {
        navigate(NotFoundPath)
      }
    }

    GraphQLErrorEmitter.on(UNAUTHENTICATED, redirectToDeconnexionOnce)
    GraphQLErrorEmitter.on(FORBIDDEN, redirectTo404NotFoundOnce)

    return function cleanup() {
      GraphQLErrorEmitter.removeListener(UNAUTHENTICATED, redirectToDeconnexionOnce)
      GraphQLErrorEmitter.removeListener(FORBIDDEN, redirectTo404NotFoundOnce)
    }
  }, [])

  return children
}
