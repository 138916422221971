import React, { FunctionComponent } from 'react'
import cn from 'classnames'

import { Montant } from '@mmb/ui-components'

import styles from '../DetailPlanFinancement.module.css'
import { MainValues } from './MainValues'
import { PlanFinancementProps } from '../../../model/DetailPlanFinancement.model'

export const WithoutPalier: FunctionComponent<PlanFinancementProps> = ({ planFinancement }) => {
  const defaultValue = '-'
  const { mensualite, mensualiteAvecAssurance } = planFinancement
  return (
    <div className={styles.planFinancement}>
      <MainValues planFinancement={planFinancement} />
      <div className={cn(styles.planFinancement__cell, styles.planFinancement__echeances)}>
        <div className={styles.planFinancement__head}>
          Échéance
          <div className={styles.planFinancement__assurance}>Hors assurance</div>
        </div>
        <div
          className={cn({
            [styles.planFinancement__value]: mensualite,
            [styles.planFinancement__mensualite]: mensualite,
          })}
        >
          {mensualite ? (
            <>
              <Montant value={mensualite} />/mois
            </>
          ) : defaultValue}
        </div>
      </div>

      <div className={cn(styles.planFinancement__cell, styles.planFinancement__echeances)}>
        <div className={styles.planFinancement__head}>
          Échéance
          <div className={styles.planFinancement__assurance}>Avec assurance</div>
        </div>
        <div
          className={cn({
            [styles.planFinancement__value]: mensualiteAvecAssurance,
            [styles.planFinancement__mensualite]: mensualiteAvecAssurance,
          })}
        >
          {mensualiteAvecAssurance ? (
            <>
              <Montant value={mensualiteAvecAssurance} />/mois
            </>
          ) : defaultValue}
        </div>
      </div>
    </div>
  )
}
