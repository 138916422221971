import { useEffect } from 'react'
import { useHandleCallbackFromAzureAd, toast } from '@core/ui'
import { useLocation } from '@core/routing'
import { useUtilisateurContext } from '@core/partenaire-common'
import { AzureADFlowType, AzureADHook, HandleCallbackFromAzureAdHook } from './AzureADInterface'

/**
 * Hook pour gérer le callback AzureAD à l'issue d'un flow
 */
export function useAzureADCallback(): AzureADHook {
  const { loggedIn, refresh } = useUtilisateurContext()
  const location = useLocation()
  const { success, error, previousUrl, flowType } = useHandleCallbackFromAzureAd((location as any)?.search) as HandleCallbackFromAzureAdHook
  useEffect(() => {
    if (success) {
      refresh()
    }
  }, [success])
  if (loggedIn && success) {
    if (flowType === AzureADFlowType.RESET_PASSWORD) {
      toast({
        message: 'Votre mot de passe a bien été modifié.',
        type: 'SUCCESS',
      })
    }
    return { navigateToUrl: previousUrl || '/app' }
  }
  if (error) {
    switch (error) {
      case 'SIGNIN_WANTED':
      case 'WRONG_PARAMS':
        return { navigateToUrl: '/app/connexion' }
      default:
        return { technicalIssue: true }
    }
  }
  return {}
}
