/* eslint-disable camelcase */
import { get, isEmpty } from 'lodash'

import { gql, useQuery } from '@core/graphql'
import { DossierStatut } from '@partenaire/common'


function getHasAssurance(data) {
  const assurances = get(data, 'partenaire_assurances')
  return !isEmpty(assurances)
}

const SHOW_CONTRAT_STATUS = [
  DossierStatut.EDITE_EN_ATTENTE_ACCEPTATION,
  DossierStatut.ACCEPTATION_CONFORME,
  DossierStatut.ACCEPTATION_NON_CONFORME,
  DossierStatut.DEBLOQUE,
  DossierStatut.ENTREE_EN_CA,
]

export function formatContrat({ partenaire_dossierTransmis }) {
  return {
    shouldShowContrat: Boolean(
      partenaire_dossierTransmis?.historique?.map(({ statut }) => statut)?.some(statut => SHOW_CONTRAT_STATUS.includes(statut))),
    hasProduit: Boolean(partenaire_dossierTransmis?.nomProduit),
  }
}

export function isCompletudeDossier(historique?: { statut: DossierStatut }[]) : boolean {
  if (!historique) return false

  const validCompletudeStatus = [
    DossierStatut.PRIS_EN_CHARGE,
    DossierStatut.EN_ENQUETE,
    DossierStatut.AVIS_FAVORABLE_SOUS_RESERVE,
    DossierStatut.ACCORDE,
    DossierStatut.EDITE_EN_ATTENTE_ACCEPTATION,
    DossierStatut.ACCEPTATION_NON_CONFORME,
    DossierStatut.ACCEPTATION_CONFORME,
    DossierStatut.REFUSE,
    DossierStatut.SANS_SUITE,
  ]

  const statuts = historique.filter(({ statut }) => validCompletudeStatus.includes(statut))
  return ((statuts || []).length > 0) ?? false
}

export const GET_PANEL_INFORMATION = gql`
query getPanelInformation($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    id
    nomProduit
    historique {
      statut
      dateModif
    }
    dateModification
    gamme
    emprunteurs {
      id
      identite {
        nom
        prenom
      }
    }
    isPriority
  }
  partenaire_assurances(id: $id) {
    assuranceEmprunteur {
      id
    }
    assuranceCoEmprunteur {
      id
    }
  }
}
`

export function usePanel(id) {
  const { data, loading } = useQuery(GET_PANEL_INFORMATION, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    variables: { id },
  })

  if (loading) return { loading }
  const { hasProduit, shouldShowContrat } = formatContrat(data)

  const replaceReference = link => link.replace(':id', id.toString())

  return {
    links: buildPanelLinks(
      isCompletudeDossier(data?.partenaire_dossierTransmis?.historique),
      getHasAssurance(data),
      hasProduit,
      shouldShowContrat),
    loading,
    replaceReference,
  }
}

function buildPanelLinks(isCompletude: boolean, hasAssurance: boolean, hasProduit: boolean, shouldShowContrat: boolean) {
  const links = [
    {
      label: 'Détail dossier',
      linkPattern: '/app/dossiers/:id',
    },
    {
      label: isCompletude ? 'Complétude dossier' : 'Envoi des pièces',
      linkPattern: '/app/dossiers/:id/pieces',
    },
    {
      label: 'Historique des envois',
      linkPattern: '/app/dossiers/:id/pieces/historique',
    },
  ]

  if (hasAssurance && hasProduit) {
    links.push({
      label: 'Assurance',
      linkPattern: '/app/dossiers/:id/assurance',
    })
  }

  if (shouldShowContrat) {
    links.push({
      label: 'Contrat',
      linkPattern: '/app/dossiers/:id/contrat',
    })
  }
  return links
}
