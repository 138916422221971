import { useEffect, useState } from 'react'

import { gql, useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../Contexts/SaisieContext'
import { useHandleSubmit, useValidation } from '../FormState/form.state.hooks'
import { EntryFormStateName } from '../FormState/EntryFormState'
import { ErrorMessage } from '../SaisieErrorMessages'
import { useGamme } from '../Gamme'
import { getSituationBancaireErrorByField } from '../../utils/ValidationErrors/situationBancaireErrors'

export const GET_SITUATION_BANCAIRE_DOSSIER = gql`
query getSituationBancaire($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    situationBancaire {
      nbComptes
      nbImpayeCtTotal
      nbImpayeImmoTotal
    }
  }
}
`

export const UPDATE_SITUATION_BANCAIRE_DOSSIER = gql`
  mutation updateSituationBancaire($id: ID!, $situationBancaire: partenaire_SituationBancaireInput!) {
    partenaire_updateSituationBancaire(
      id: $id
      situationBancaire: $situationBancaire
    ) {
      nbComptes
      nbImpayeCtTotal
      nbImpayeImmoTotal
    }
  }
`

export function useSituationBancaire() {
  const navigate = useNavigate()
  const { saisieUI: { props: { dossierId, gamme } } } = useSaisie()
  const [situationBancaire, setSituationBancaire] = useState<any>(undefined)
  const useGammeProps = useGamme()
  const [loadingSituationBancaire, setLoadingSituationBancaire] = useState(true)
  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.SITUATION_BANCAIRE)

  const { data, loading: queryLoading }
    = useQueryRedirectError(GET_SITUATION_BANCAIRE_DOSSIER, navigate, { fetchPolicy: 'network-only', variables: { id: dossierId } })
  const [
    updateSituationBancaireByDossierId,
    { loading: mutationLoading, error: mutationError },
  ] = useMutation(UPDATE_SITUATION_BANCAIRE_DOSSIER, {
    refetchQueries: [{ query: GET_SITUATION_BANCAIRE_DOSSIER, variables: { id: dossierId } }],
    awaitRefetchQueries: true,
  })
  const loading = loadingSituationBancaire || mutationLoading

  const { errors, showErrors } = useValidation(EntryFormStateName.SITUATION_BANCAIRE, loading, { situationBancaire }, { gamme })
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([])

  useEffect(() => {
    setErrorMessages((errors?.globalErrors || []).map(error => getSituationBancaireErrorByField(error, useGammeProps)))
  }, [errors])

  useEffect(() => {
    if (!queryLoading) {
      const situationBancaireData = data?.partenaire_dossierEnCours?.situationBancaire
      if (!situationBancaireData) return
      setSituationBancaire(formatSituationBancaireFrom(situationBancaireData))
      setLoadingSituationBancaire(false)
    }
  }, [data, queryLoading])

  function changeSituationBancaire(newSituationBancaire) {
    setSituationBancaire(currentSituationBancaire => ({ ...currentSituationBancaire, ...newSituationBancaire }))
  }

  const updateSituationBancaire = async () => {
    await updateSituationBancaireByDossierId({
      variables: {
        id: dossierId,
        situationBancaire: formatSituationBancaireToGraphql(situationBancaire),
      },
    })
  }

  const handleSubmit = async event => doHandleSubmit(updateSituationBancaire, event)

  return {
    situationBancaire,
    handleSubmit,
    setNumberOfComptes: newNbComptes => changeSituationBancaire({ numberOfComptes: newNbComptes < 0 ? 0 : newNbComptes }),
    setNumberOfRejetsConso: newRejetsConso => changeSituationBancaire({ numberOfRejetsConso: newRejetsConso < 0 ? 0 : newRejetsConso }),
    setNumberOfRejetsImmo: newRejetsImmo => changeSituationBancaire({ numberOfRejetsImmo: newRejetsImmo < 0 ? 0 : newRejetsImmo }),
    gamme,
    loading,
    error: mutationError,
    errors: errors?.globalErrors,
    errorMessages,
    showErrors,
  }
}

function formatSituationBancaireFrom(situationBancaireData) {
  return {
    numberOfComptes: situationBancaireData.nbComptes,
    numberOfRejetsConso: situationBancaireData.nbImpayeCtTotal,
    numberOfRejetsImmo: situationBancaireData.nbImpayeImmoTotal,
  }
}

function formatSituationBancaireToGraphql(situationBancaireData) {
  return {
    nbComptes: Number(situationBancaireData.numberOfComptes || 0),
    nbImpayeCtTotal: Number(situationBancaireData.numberOfRejetsConso || 0),
    nbImpayeImmoTotal: Number(situationBancaireData.numberOfRejetsImmo || 0),
  }
}
