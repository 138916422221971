import React from 'react'
import cn from 'classnames'
import { node, string } from 'prop-types'

import styles from './Facet.module.css'

Facet.propTypes = {
  children: node,
  className: string,
}

Facet.defaultProps = {
  children: null,
  className: '',
}

export function Facet({ children, className }) {
  return (
    <div
      className={cn(styles.facet, className)}
    >
      {children}
    </div>
  )
}
