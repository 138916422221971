import { useEffect, useState } from 'react'
import { gql, useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../../Contexts'
import { useHandleSubmit } from '../../FormState/form.state.hooks'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { toGraphQL, toModelUI } from './mappers'
import { IdentiteUI } from '../../../model/identite/IdentiteUI'

export const GET_IDENTITE_DOSSIER = gql`
query getIdentiteDossier($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    emprunteurs {
      id
      identite {
        civilite
        nom
        prenom
        nationalite
        naissance {
          nom
          date
          ville
          departement
        }
      }
    }
  }
}
`

export const UPDATE_IDENTITE = gql`
  mutation updateIdentite($dossierId: ID!, $personneId: ID!, $identite: partenaire_IdentiteInput) {
    partenaire_updateIdentite(id: $dossierId, personneId: $personneId identite: $identite) {
      id
      identite {
        civilite
        nom
        prenom
        nationalite
        naissance {
          nom
          date
          ville
          departement
        }
      }
    }
  }
`

export function useIdentite() {
  const navigate = useNavigate()
  const { saisieUI: { props: { dossierId, hasCoEmprunteur, emprunteurId, coEmprunteurId } } } = useSaisie()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emprunteur, setEmprunteur] = useState({})
  const [coEmprunteur, setCoEmprunteur] = useState({})
  const [loadingIdentiteUI, setLoadingIdentiteUI] = useState(true)
  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.IDENTITE)

  const { data, loading: queryLoading } = useQueryRedirectError(
    GET_IDENTITE_DOSSIER,
    navigate,
    { fetchPolicy: 'network-only', variables: { id: dossierId } },
  )
  const [updateIdentiteByDossierId, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_IDENTITE)

  useEffect(() => {
    if (!queryLoading) {
      // eslint-disable-next-line camelcase
      const [emprunteurData, coEmprunteurData] = data?.partenaire_dossierEnCours?.emprunteurs || []

      if (emprunteurData) {
        const identite = toModelUI(emprunteurData?.identite || {})
        setEmprunteur({ ...identite, personneId: emprunteurData?.id })
      }

      if (coEmprunteurData) {
        const identite = toModelUI(coEmprunteurData?.identite || {})
        setCoEmprunteur({ ...identite, personneId: coEmprunteurData?.id })
      }
      setLoadingIdentiteUI(false)
    }
  }, [data, queryLoading])

  const updateIdentite = async (personneId, { personneId: __, ...identite }: any) => updateIdentiteByDossierId({
    variables: {
      dossierId,
      personneId,
      identite: toGraphQL(identite),
    },
  })

  const updateEmprunteursIdentite = async () => {
    await updateIdentite(emprunteurId, emprunteur)

    if (hasCoEmprunteur) {
      await updateIdentite(coEmprunteurId, coEmprunteur)
    }
  }

  const handleSubmit = async event => doHandleSubmit(updateEmprunteursIdentite, event)

  const updateEmprunteur = (identite: IdentiteUI, updateOnEmprunteur: boolean = true): void => {
    if (updateOnEmprunteur) {
      setEmprunteur({ ...emprunteur, ...identite })
    } else {
      setCoEmprunteur({ ...coEmprunteur, ...identite })
    }
  }

  return {
    loading: loadingIdentiteUI || mutationLoading,
    error: mutationError,
    emprunteur,
    coEmprunteur,
    handleSubmit,
    updateEmprunteur,
  }
}
