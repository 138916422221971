import React, { useRef } from 'react'
import { string } from 'prop-types'
import { nth } from 'lodash'

import { Button, Plus, Card } from '@mmb/ui-components'
import { FormHeader, Separator } from '@core/partenaire-ui'

import { Pret } from './Pret'
import { Dette } from './Dette'
import { useEnCours, useEncoursErrors } from './Encours.hook'
import { EncoursTotal } from './EncoursTotal'

import { useDelayedFormBlur } from '../../utils'

import styles from './Encours.module.css'
import { EncoursFormUI, DetteFormUI, EncoursUI, PretFormUI, buildDefaultPret, buildDefaultDette } from '../../model'
import { EntryFormStateName } from '../FormState/EntryFormState'
import { SaisieErrorMessages } from '../SaisieErrorMessages'

Encours.propTypes = {
  className: string,
}

Encours.defaultProps = {
  className: '',
}

export function Encours({ className }) {
  const { loading, encoursUI, handleSubmit, removeEncours, canBeInApprocheInvestisseur } = useEnCours()
  const { showErrors,
    encoursValidationErrors,
    errorMessages,
    creanceErrorMessages,
    encoursWarnings,
  } = useEncoursErrors({ loading, encoursUI })

  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)
  const getKey = (encoursFormUI: EncoursFormUI) => encoursFormUI.id || String(encoursFormUI.position!)

  const addPret = () => encoursUI.add(buildDefaultPret())
  const addDette = () => encoursUI.add(buildDefaultDette())
  return (
    <div id={`${EntryFormStateName.EN_COURS}`}>
      <Card className={className}>
        <FormHeader className={styles.encours__title}>
          Encours
        </FormHeader>
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          data-testid="encours-form"
          onBlur={onBlur}
          onFocus={onFocus}
        >
          {encoursUI.getEncoursWithDisplayablePosition().map((encours, index) => (
            <div key={encours.position}>
              {index === encoursValidationErrors.positionFirstError && <div id={`${EntryFormStateName.EN_COURS}${index}`} />}
              {EncoursUI.isPret(encours) ?
                (<Pret
                  index={index}
                  pret={encours as PretFormUI}
                  key={`pret_${getKey(encours)}`}
                  setPret={newPret => encoursUI.update(index, newPret)}
                  removePret={removeEncours}
                  setRepris={repris => encoursUI.updateRepris(index, repris)}
                  loading={loading}
                  warningMessages={nth(encoursWarnings?.warningMessages, index) || []}
                  errors={nth(encoursValidationErrors.encoursErrors, index)}
                  errorMessages={nth(creanceErrorMessages, index)}
                  showErrors={showErrors}
                  canBeInApprocheInvestisseur={canBeInApprocheInvestisseur(encours as PretFormUI)}
                />) :
                (<Dette
                  index={index}
                  dette={encours as DetteFormUI}
                  key={`dette_${getKey(encours)}`}
                  setDette={newDette => encoursUI.update(index, newDette)}
                  removeDette={removeEncours}
                  setRepris={repris => encoursUI.updateRepris(index, repris)}
                  loading={loading}
                  errors={nth(encoursValidationErrors.encoursErrors, index)}
                  errorMessages={nth(creanceErrorMessages, index)}
                  showErrors={showErrors}
                />
                )}
            </div>),
          )}
        </form>
        {encoursUI.list.length > 0 && <Separator className={styles.encours__separator} />}
        <div className={styles.encours}>
          <Button className={styles.encours__addButton} aria-label="Ajouter un prêt" onClick={addPret} outline disabled={loading}>
            <Plus className={styles.encours__addIcon} />
            Ajouter un prêt
          </Button>
          <Button className={styles.encours__addButton} aria-label="Ajouter une dette" onClick={addDette} outline disabled={loading}>
            <Plus className={styles.encours__addIcon} />
            Ajouter une dette
          </Button>
        </div>
        <EncoursTotal encoursUI={encoursUI} />
        {showErrors && (<SaisieErrorMessages
          errors={errorMessages}
        />)}
      </Card>
    </div>
  )
}
