import { useState, useEffect } from 'react'
import { useNavigate } from '@core/routing'
import { useLazyQuery, gql } from '@core/graphql'
import { useSaisie } from '../Contexts'

const GET_STATUT_TRANSMISSION_DOSSIER = gql`
  query getStatutTransmissionDossier($id: ID!) {
    partenaire_getStatutTransmission(id: $id) {
      statut
    }
}`

export function useTransmissionHandler() {
  const { saisieUI: { props: { dossierId } } } = useSaisie()
  const [getStatutTransmission, { data, loading, error }] = useLazyQuery(
    GET_STATUT_TRANSMISSION_DOSSIER, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    })
  const navigate = useNavigate()
  const [isEnAttente, setIsEnAttente] = useState(false)
  const [isTransmis, setIsTransmis] = useState(false)
  const [hasTransmissionFailed, setHasTransmissionFailed] = useState(false)
  useEffect(() => {
    checkTransmission()
  }, [dossierId])

  useEffect(() => {
    if (error) throw error
  }, [error])

  useEffect(() => {
    if (isTransmis) {
      navigate(`/app/dossiers/${dossierId}/pieces`)
    }
  }, [isTransmis, data])

  async function checkTransmission() {
    if (!dossierId) return
    const { data: queryData } = await getStatutTransmission({ variables: { id: dossierId } })
      .then(response => {
        if (!response.data) {
          navigate('/service-indisponible')
        }
        return response
      })

    setIsEnAttente(queryData?.partenaire_getStatutTransmission?.statut === 'EN_ATTENTE')
    setIsTransmis(queryData?.partenaire_getStatutTransmission?.statut === 'TRANSMIS')
    setHasTransmissionFailed(queryData?.partenaire_getStatutTransmission?.statut === 'EN_ERREUR')
  }


  return {
    isEnAttente,
    isTransmis,
    hasTransmissionFailed,
    checkTransmission,
    loading,
    error,
    statut: data?.partenaire_getStatutTransmission?.statut,
  }
}
