import { PositionableFormUI } from '../support/PositionableFormUI'

export enum TypeRevenu {
  SALAIRE = 'SALAIRE',
  RETRAITE = 'RETRAITE',
  BIC_BNC = 'BIC_BNC',
  LOYER_AVEC_SCI = 'LOYER_AVEC_SCI',
  LOYER_SANS_SCI = 'LOYER_SANS_SCI',
  INDEMNITES_POLE_EMPLOI = 'INDEMNITES_POLE_EMPLOI',
  AUTRE = 'AUTRE',
  ALLOCATIONS_LOGEMENT = 'ALLOCATIONS_LOGEMENT',
  ALLOCATIONS_FAMILIALES = 'ALLOCATIONS_FAMILIALES',
  ALLOCATIONS_PAJE = 'ALLOCATIONS_PAJE',
  ALLOCATIONS_ADULTES_HANDICAPES_AAH = 'ALLOCATIONS_ADULTES_HANDICAPES_AAH',
  ALLOCATIONS_RSA = 'ALLOCATIONS_RSA',
  ALLOCATIONS_AUTRE = 'ALLOCATIONS_AUTRE',
  PENSION_ALIMENTAIRE = 'PENSION_ALIMENTAIRE',
  PENSION_DE_REVERSION = 'PENSION_DE_REVERSION',
  PRESTATION_COMPENSATOIRE = 'PRESTATION_COMPENSATOIRE',
  PENSION_D_INVALIDITE = 'PENSION_D_INVALIDITE',
  RENTE_ACCIDENT_DE_TRAVAIL = 'RENTE_ACCIDENT_DE_TRAVAIL',
  PENSION_AUTRE = 'PENSION_AUTRE',
}

export interface RevenuFormUI extends PositionableFormUI {
  id?: string
  debut?: string
  montant?: number
  type?: TypeRevenu
  fin?: string | null
}


export const getDefaultRevenuFormUI = (): RevenuFormUI => ({ montant: 0 })
