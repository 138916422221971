import React from 'react'
import { string } from 'prop-types'

import { Card } from '@mmb/ui-components'
import { FormHeader, Separator } from '@core/partenaire-ui'

import { Identite } from './Identite'
import { SituationFamiliale } from './SituationFamiliale'
import { Adresse } from './Adresse'
import { Communications } from './Communications'

import { ColumnName } from '../ColumnName'

import styles from './SituationPersonnelle.module.css'
import { EntryFormStateName } from '../FormState/EntryFormState'

SituationPersonnelle.propTypes = {
  className: string,
}

SituationPersonnelle.defaultProps = {
  className: '',
}

export function SituationPersonnelle({ className }) {
  return (
    <div id={`${EntryFormStateName.IDENTITE}`}>
      <Card className={className}>
        <FormHeader>Situation personnelle</FormHeader>
        <ColumnName />
        <>
          <Identite />

          <div id={`${EntryFormStateName.SITUATION_FAMILIALE}`} />
          <Separator className={styles.situationPersonnelle__separator} />
          <SituationFamiliale />

          <div id={`${EntryFormStateName.ADRESSE}`} />
          <Separator className={styles.situationPersonnelle__separator} />
          <Adresse />

          <div id={`${EntryFormStateName.COMMUNICATIONS}`} />
          <Separator />
          <Communications />
        </>
      </Card>
    </div>
  )
}
