import React from 'react'

import { TextRow } from '@mmb/ui-components'

import styles from './IdentitePlaceholder.module.css'

export function IdentitePlaceholder() {
  return (
    <div className={styles.identitePlaceholder}>
      <div className={styles.identitePlaceholder__toggles} aria-label="civilites.placeholder">
        <TextRow width="12rem" className={styles.identitePlaceholder__toggle} />
        <TextRow width="12rem" className={styles.identitePlaceholder__toggle} />
      </div>
      <span aria-label="nom.placeholder">
        <TextRow width="35rem" className={styles.identitePlaceholder__row} />
      </span>
      <span aria-label="naissance.nom.placeholder">
        <TextRow width="35rem" className={styles.identitePlaceholder__row} />
      </span>
      <span aria-label="prenom.placeholder">
        <TextRow width="35rem" className={styles.identitePlaceholder__row} />
      </span>
      <span aria-label="naissance.date.placeholder">
        <TextRow width="35rem" className={styles.identitePlaceholder__row} />
      </span>
      <span aria-label="naissance.ville.placeholder">
        <TextRow width="35rem" className={styles.identitePlaceholder__row} />
      </span>
      <span aria-label="naissance.departement.placeholder">
        <TextRow width="35rem" className={styles.identitePlaceholder__row} />
      </span>
      <span aria-label="nationalite.placeholder">
        <TextRow width="35rem" className={styles.identitePlaceholder__row} />
      </span>
    </div>
  )
}
