import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'

import { RectShape, Download } from '@mmb/ui-components'
import { ModalTrigger } from '@core/partenaire-ui'
import { formatCondenseDate } from '@core/common'

import styles from './DocumentPreview.module.css'
import { useDocumentDownload } from '../../liste/DocumentLink/DocumentLink.hook'
import { DocumentUI } from '../../../../model/OutilsUI'
import { DocumentIcon } from '../../DocumentIcon'

let globalId = 0

interface DocumentPreviewProps {
  document: DocumentUI
}

export const DocumentPreview: FunctionComponent<DocumentPreviewProps> = ({ document: { id, nom, dateCreation, contentType } }) => {
  const { url, downloadOrStream } = useDocumentDownload(id, contentType)
  const uniqueId = `document_preview_${++globalId}`
  return (
    <ModalTrigger
      renderButton={(_, setOpen) => (
        <Link to="#" onClick={() => downloadOrStream(setOpen)} title={nom} aria-labelledby={uniqueId} className={styles.documentPreview}>
          <div className={styles.documentPreview__iconContainer}>
            <DocumentIcon contentType={contentType} className={styles.documentPreview__icon} />
          </div>
          <p className={styles.documentPreview__informations}>
            <strong id={uniqueId} className={styles.documentPreview__filename}>{nom}</strong>
            <span>{formatCondenseDate(dateCreation)}</span>
          </p>
          <div className={styles.documentPreview__overlay}>
            <div className={styles.documentPreview__backdrop} />
            <div className={styles.documentPreview__mainAction}>
              <Download aria-hidden className={styles.documentPreview__download} />
            </div>
          </div>
        </Link>)}
    >
      <ReactPlayer playing url={url} controls />
    </ModalTrigger>
  )
}
export const DocumentPreviewPlaceholder = () => (<RectShape height="16rem" width="21rem" />)
