import React from 'react'
import { string } from 'prop-types'

import { Montant, Priority, TelephoneSimple, Mail } from '@mmb/ui-components'
import { Facet, Gamme } from '@partenaire/frontend-ui'

import { useDossierInformations } from './Information.hooks'

import styles from './Information.module.css'

Information.propTypes = {
  id: string.isRequired,
  className: string,
}

Information.defaultProps = {
  className: '',
}

export function Information({ id, className }) {
  const { error, loading, emprunteur, coEmprunteur, agence, gamme, montant, dateModif, statut, isPriority, reference } = useDossierInformations(id)
  if (loading || error) return null

  return (
    <div className={className}>
      <div className={styles.information__personnes}>
        <h3 className={styles.information__personne}>
          {emprunteur?.fullName}
        </h3>
        {(emprunteur?.telephone) && (
        <>
          <TelephoneSimple className={styles.information__personne__icon} />
          <span className={styles.information__personne__communication}> {emprunteur?.telephone} </span>
        </>
        )}
        {(emprunteur?.email) && (
        <>
          <Mail className={styles.information__personne__icon} />
          <span className={styles.information__personne__communication}> {emprunteur?.email} </span>
        </>
        )}
        {coEmprunteur && (
        <>
          <h3 className={styles.information__personne__title}>
            {coEmprunteur?.fullName}
          </h3>
          {(coEmprunteur?.telephone) && (
          <>
            <TelephoneSimple className={styles.information__personne__icon} />
            <span className={styles.information__personne__communication}> {coEmprunteur?.telephone} </span>
          </>
          )}
          {(coEmprunteur.email) && (
          <>
            <Mail className={styles.information__personne__icon} />
            <span className={styles.information__personne__communication}> {coEmprunteur?.email} </span>
          </>
          )}
        </>
        )}
      </div>
      <div className={styles.information__reference}>Réf: {reference}</div>
      {montant && (
        <Montant
          decimal
          separator
          value={montant}
          className={styles.information__montant}
          wholeClassName={styles.information__whole}
          separatorClassName={styles.information__separator}
          decimalClassName={styles.information__decimal}
        />)}
      {statut && (
        <div className={styles.information__statut} data-testid="statut">
          {statut}
        </div>
      )}
      {dateModif && (
        <div className={styles.information__date}>{dateModif}</div>
      )}
      {gamme?.value && gamme.label &&
      (
      <Gamme type={gamme.value} className={styles.information__gamme}>
        {gamme.label}
      </Gamme>)}
      <Facet className={styles.information__agence}>{agence}</Facet>
      {isPriority && (
        <div className={styles.information__priority}><Priority className={styles.information__icon} /> Priority</div>
      )}
    </div>
  )
}
