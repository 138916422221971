import React from 'react'
import { node, string } from 'prop-types'

import { AccordionItem } from '@mmb/ui-components'

import { Title } from '../Title'

import styles from './Item.module.css'

Item.propTypes = {
  children: node,
  title: string,
}

Item.defaultProps = {
  children: null,
  title: '',
}

export function Item({ children, title }) {
  return (
    <AccordionItem
      title={<Title>{title}</Title>}
      hideIcon
      disableMargin
      iconClassName={styles.item__chevron}
    >
      {children}
    </AccordionItem>
  )
}
