import React from 'react'
import { string } from 'prop-types'
import { find } from 'lodash'
import classNames from 'classnames'

import { STATUTS } from '../../utils/const'

import styles from './List.module.css'

CellStatut.propTypes = {
  statut: string.isRequired,
}

export function CellStatut({ statut }) {
  const statutFormatted = find(STATUTS, { value: statut })?.label
  return (
    <span
      className={classNames(styles.list__cell, { [styles.list__cellHighlightStatut]: statut !== 'CONFIRME' })}
      title={statutFormatted}
      role="cell"
    >
      {statutFormatted}
    </span>
  )
}
