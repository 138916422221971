import React from 'react'

import { TextRow } from '@mmb/ui-components'

import { ASSURANCE_OPTIONS } from '../../../model'

import styles from './AssurancePlaceholder.module.css'

export function AssurancePlaceholder() {
  return (
    <div className={styles.assurancePlaceholder}>
      <div className={styles.assurancePlaceholder__toggles} aria-label="type.placeholder">
        {ASSURANCE_OPTIONS.map(({ value }) => (<TextRow
          width="20rem"
          height="4.6rem"
          key={`type.${value}`}
          className={styles.assurancePlaceholder__toggle}
        />))}
      </div>
      <span aria-label="formule.placeholder">
        <TextRow width="35rem" className={styles.assurancePlaceholder__row} />
      </span>
      <span aria-label="quotite.placeholder">
        <TextRow width="10rem" className={styles.assurancePlaceholder__row} />
      </span>
    </div>
  )
}
