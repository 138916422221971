import { ValidationFieldError } from '../model'

const removeTypename = (key, value) => key === '__typename' ? undefined : value
export const parseMutationInput = variables => JSON.parse(JSON.stringify(variables), removeTypename)

export const noop = () => {}

export const hasErrorForName = (name: string | string[], errors: ValidationFieldError[], showErrors = true) => {
  if (!showErrors) {
    return false
  }
  if (!Array.isArray(errors)) return false
  const findError = value => errors.find(({ field }) => value === field)
  if (Array.isArray(name)) {
    return Boolean(name.some(findError))
  }
  return Boolean(findError(name))
}

export const clearEmpty = value => {
  if (value === 0) return 0
  if (value === false) return false
  return value || ''
}

export const limit = (value: number, limite: number) => (value > limite ? limite : value)

