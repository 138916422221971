import { FormEvent, useContext, useEffect, useState } from 'react'
import { FormStateContext } from '../Contexts/FormStateContext'
import { EntryFormStateName, findValidatorByFormStateName, findWarningCheckerByFormStateName } from './index'
import { FormState, FormStateProps } from './FormState'

export const useGetEntityFormState = (name: EntryFormStateName) => {
  const formState = useContext(FormStateContext)
  const entryFormState = formState.getEntryFormState(name)
  return {
    hasErrors: !!entryFormState?.hasErrors,
    showErrors: entryFormState?.showErrors,
    entryFormState,
  }
}

interface Validation {
  errors: any
  warnings: any
  showErrors: boolean
}

export const useValidation = (name: EntryFormStateName, loading: boolean, value: any, requiredContextValues?: any) : Validation => {
  const formState = useContext(FormStateContext)
  const validator = findValidatorByFormStateName(name)
  const warningChecker = findWarningCheckerByFormStateName(name)
  const validate = () => value && value.validate ? value.validate() : validator!({ ...value, ...requiredContextValues })
  const errors = loading ? { globalErrors: [] } : validate()
  useEffect(() => formState.updateEntries(name, value, errors, loading), [JSON.stringify(value), JSON.stringify(errors), loading])
  const warnings = !loading ? warningChecker({ ...value, ...requiredContextValues }) : undefined
  const entryFormState = formState.getEntryFormState(name)
  return {
    errors,
    warnings,
    showErrors: formState.props.showErrors || !!entryFormState?.showErrors,
  }
}

const doHandleSubmit =
  (formState: FormState, entryFormStateName: EntryFormStateName, hasErrors: boolean, setSubmitted: (submitted: boolean) => void,
  ) => async (handleSubmit: () => Promise<void>, event?: FormEvent, ignoreErrors: boolean = false) => {
    const entryFormState = { ...formState.getEntryFormState(entryFormStateName), submitting: true, showErrors: true }
    formState.updateEntry(entryFormState)
    if (event) {
      event.preventDefault()
    }
    if (!hasErrors || ignoreErrors) {
      await handleSubmit().finally(() => setSubmitted(true))
    }
  }

export const useHandleSubmit = (entryFormStateName: EntryFormStateName) => {
  const formState = useContext(FormStateContext)
  const [submitted, setSubmitted] = useState(false)
  const { hasErrors } = useGetEntityFormState(entryFormStateName)
  useEffect(() => {
    if (submitted) {
      formState.update({ ...formState.props, submitted } as FormStateProps)
      setSubmitted(false)
    }
  }, [submitted])
  return { doHandleSubmit: doHandleSubmit(formState, entryFormStateName, hasErrors, setSubmitted) }
}
