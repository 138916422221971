import React from 'react'
import classnames from 'classnames'
import { number, string } from 'prop-types'

import { TextRow, Cross } from '@mmb/ui-components'

import styles from './PretPlaceholder.module.css'


PretPlaceholder.propTypes = {
  titre: string.isRequired,
  numero: number.isRequired,
}

export function PretPlaceholder({ titre, numero }) {
  return (
    <div aria-label={`Rachat du prêt n° ${numero}`}>
      <div className={styles.pretPlaceholder__titre}>
        {`${titre}`}
        <div className={styles.pretPlaceholder__titreDroit}>
          <button
            className={styles.pretPlaceholder__removeButton}
            type="button"
            aria-label={`Suppression du prêt n° ${numero}`}
          >
            <Cross className={styles.pretPlaceholder__cross} />
          </button>
        </div>
      </div>
      <div className={styles.pretPlaceholder__contour}>
        <div className={styles.pretPlaceholder__upperBlocks}>
          <div className={styles.pretPlaceholder__left}>
            <div className={classnames(styles.pretPlaceholder__wrapper, styles.pretPlaceholder__oneThird)}>
              <div className={styles.pretPlaceholder__label}>Nature du prêt</div>
              <TextRow width="100%" className={styles.pretPlaceholder__input} />
            </div>
            <div className={styles.pretPlaceholder__ligne}>
              <div className={classnames(styles.pretPlaceholder__wrapper, styles.pretPlaceholder__twoThird)}>
                <div className={styles.pretPlaceholder__label}>Nom du prêteur</div>
                <TextRow width="100%" className={styles.pretPlaceholder__input} />
              </div>
              <div className={classnames(styles.pretPlaceholder__wrapper, styles.pretPlaceholder__oneThird)}>
                <div className={styles.pretPlaceholder__label}>Régime</div>
                <TextRow width="100%" className={styles.pretPlaceholder__input} />
              </div>
            </div>
            <div className={classnames(styles.pretPlaceholder__ligne, styles.pretPlaceholder__lastLine)}>
              <div className={styles.pretPlaceholder__wrapperDate}>
                <div className={styles.pretPlaceholder__label}>Début</div>
                <TextRow width="20rem" className={styles.pretPlaceholder__input} />
              </div>
              <div className={styles.pretPlaceholder__wrapper}>
                <div className={styles.pretPlaceholder__label}>Fin</div>
                <TextRow width="20rem" className={styles.pretPlaceholder__input} />
              </div>
            </div>
            <div className={classnames(styles.pretPlaceholder__wrapper, styles.pretPlaceholder__oneThird)}>
              <div className={styles.pretPlaceholder__label}>Référence</div>
              <TextRow width="100%" className={styles.pretPlaceholder__input} />
            </div>
          </div>
          <div className={styles.pretPlaceholder__right}>
            <div className={styles.pretPlaceholder__ligne}>
              <div className={styles.pretPlaceholder__wrapper}>
                <div className={styles.pretPlaceholder__label}>CRD total</div>
                <TextRow width="100%" className={styles.pretPlaceholder__input} />
              </div>
            </div>
            <div className={styles.pretPlaceholder__ligne}>
              <div className={styles.pretPlaceholder__wrapper}>
                <div className={styles.pretPlaceholder__label}>IRA (optionnel)</div>
                <TextRow width="100%" className={styles.pretPlaceholder__input} />
              </div>
            </div>
            <div className={styles.pretPlaceholder__ligne}>
              <div className={styles.pretPlaceholder__wrapper}>
                <div className={styles.pretPlaceholder__label}>Taux (optionnel)</div>
                <TextRow width="100%" className={styles.pretPlaceholder__input} />
              </div>
            </div>
            <div className={styles.pretPlaceholder__ligne}>
              <div className={styles.pretPlaceholder__wrapper}>
                <div className={styles.pretPlaceholder__label}>Echéance</div>
                <TextRow width="100%" className={styles.pretPlaceholder__input} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.pretPlaceholder__ligneBas}>
          <TextRow width="100%" className={styles.pretPlaceholder__input} />
        </div>
        <div className={styles.pretPlaceholder__ligneBas}>
          <TextRow width="100%" className={styles.pretPlaceholder__input} />
        </div>
      </div>
    </div>
  )
}
