import { isBrowser } from '@core/common-frontend'
import { array, node } from 'prop-types'

WithPortals.propTypes = {
  children: node,
  portals: array.isRequired,
}

WithPortals.defaultProps = {
  children: undefined,
}

export function WithPortals({ portals, children }) {
  return portals.indexOf(isBrowser && window.GATSBY_PORTAL) !== -1 ? children : null
}
