import React from 'react'
import { bool, node } from 'prop-types'

import { Placeholder, TextRow } from '@mmb/ui-components'

import styles from './BaisseRevenuPlaceholder.module.css'


BaisseRevenuPlaceholder.propTypes = {
  children: node.isRequired,
  loading: bool,
  hasReducedRevenueEmprunteur: bool,
  hasCoEmprunteur: bool,
  hasReducedRevenueCoEmprunteur: bool,
}

BaisseRevenuPlaceholder.defaultProps = {
  loading: false,
  hasReducedRevenueEmprunteur: false,
  hasCoEmprunteur: false,
  hasReducedRevenueCoEmprunteur: false,
}

export function BaisseRevenuPlaceholder({ children, loading, hasReducedRevenueEmprunteur, hasCoEmprunteur, hasReducedRevenueCoEmprunteur }) {
  return (
    <Placeholder
      loading={loading}
      customPlaceholder={(
        <div aria-label="Chargement en cours" className={styles.revenusFormPlaceholder}>
          <div className={styles.baisse__button}>
            <div className={styles.baisse__labelCheck}>{`Baisse de revenu à prévoir
            ex : départ à la retraite`}
            </div>
            {hasCoEmprunteur && (
            <div className={styles.baisse__labelCheck}>{`Baisse de revenu à prévoir
            ex : départ à la retraite`}
            </div>
            )}
          </div>
          <div className={styles.baisse__listsLabels}>
            {(hasReducedRevenueEmprunteur || hasReducedRevenueCoEmprunteur) && (
              <div className={styles.baisse__baisseLabels}>
                <div className={styles.baisse__baisseLabels__grid}>
                  <p className={styles.baisse__baisseLabels__label}>Montant prévisionnel</p>
                  <p className={styles.baisse__baisseLabels__label}>Date de début</p>
                </div>
              </div>
            )}
            {hasReducedRevenueEmprunteur && (
              <div>
                <TextRow className={styles.baisseRevenue__form__dynamicInput} />
                <TextRow className={styles.baisseRevenue__form__input} />
              </div>
            )}
            {hasCoEmprunteur && hasReducedRevenueCoEmprunteur && (
              <div>
                <TextRow className={styles.baisseRevenue__form__dynamicInput} />
                <TextRow className={styles.baisseRevenue__form__input} />
              </div>
            )}
          </div>
        </div>
      )}
    >
      {children}
    </Placeholder>
  )
}
