import React from 'react'
import { isEmpty } from 'lodash'
import { ChangeGammeProps } from '../../components/Gamme'
import { SimulationFieldHorsNorme, SimulationFieldDimensionInvalide } from '../../model'
import { ERROR_MESSAGES, GENERIC_ERROR_MESSAGE, NON_VALIDITE_DIMENSIONS, SAISIE_ERROR_MESSAGE } from '../errorMessage'
import { getBasculeHypothecaireLink } from '../gamme'

const getBascule = ({ changeGamme, isUpdatingGamme }) => getBasculeHypothecaireLink(changeGamme, isUpdatingGamme, 'ou de basculer en hypothécaire.')

const getMessage = (
  messages: any,
  code: string,
  args: { min?: String, max?: String, taux?: String },
  gammeProps: ChangeGammeProps,
  defaultErrorMessage: { libelle: String } = { libelle: '' }): any => {
  return messages(args, gammeProps)[code] || defaultErrorMessage
}

export const getSimulationHorsNormeMessage = (error: SimulationFieldHorsNorme, gammeProps: ChangeGammeProps) => {
  const message = getMessage(ERROR_MESSAGES, error.field, error.args, gammeProps, GENERIC_ERROR_MESSAGE)
  if (!message.hasBascule) {
    return message.libelle
  }
  return <>{message.libelle} {getBascule(gammeProps)}</>
}

export const getSaisieErrorMessage = (error: string) => SAISIE_ERROR_MESSAGE[error]

export const getErrorsToShow = (errors: SimulationFieldDimensionInvalide[]) : SimulationFieldDimensionInvalide[] => {
  if (isEmpty(errors)) {
    return []
  }
  const props = errors[0]?.args || { max: 0 }
  const keys = Object.keys(NON_VALIDITE_DIMENSIONS(props))
  return errors.filter(error => keys.includes(error.field))
}

export const getSimulationInvalidMessage = (error: SimulationFieldDimensionInvalide, gammeProps: ChangeGammeProps) => {
  const args = error.args || { max: undefined }
  const message = getMessage(NON_VALIDITE_DIMENSIONS, error.field, args, gammeProps)
  if (!message.hasBascule) {
    return message.libelle
  }
  return <>{message.libelle} {getBascule(gammeProps)}</>
}
