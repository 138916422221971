
import React, { useRef } from 'react'
import { string, number, func, arrayOf, shape, bool } from 'prop-types'

import { FormHeader, Tooltip } from '@core/partenaire-ui'
import { Card, Input } from '@mmb/ui-components'

import { useDelayedFormBlur, clearEmpty, hasErrorForName as hasError } from '../../utils'

import { useSituationBancaire } from './SituationBancaire.hooks'

import styles from './SituationBancaire.module.css'
import { SituationBancairePlaceholder } from './SituationBancairePlaceholder'
import { EntryFormStateName } from '../FormState/EntryFormState'
import { SaisieErrorMessages } from '../SaisieErrorMessages'

SituationBancaire.propTypes = {
  className: string,
}

SituationBancaire.defaultProps = {
  className: '',
}

export function SituationBancaire({ className }) {
  const {
    handleSubmit,
    situationBancaire,
    setNumberOfComptes,
    setNumberOfRejetsConso,
    setNumberOfRejetsImmo,
    loading,
    showErrors,
    errorMessages,
    errors,
  } = useSituationBancaire()
  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)
  return (
    <div id={`${EntryFormStateName.SITUATION_BANCAIRE}`}>
      <Card className={className}>
        <FormHeader>Analyse des comptes</FormHeader>
        <form
          data-testid="situation-bancaire-form"
          ref={formRef}
          onSubmit={handleSubmit}
          className={styles.situationBancaire}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          <div className={styles.situationBancaire__labels}>
            <div className={styles.situationBancaire__titleTooltipWrapper}>
              <p className={styles.situationBancaire__label}>Nombre de comptes courants</p>
              <Tooltip
                wrapperClassName={styles.situationBancaire__tooltip}
                title="Nombre de comptes courants"
                content={(
                  <p className={styles.situationBancaire__tooltipText}>
                    Saisir uniquement le nombre de comptes bancaires hors comptes épargne.
                  </p>)}
              />
            </div>
            <div className={styles.situationBancaire__titleTooltipWrapper}>
              <p className={styles.situationBancaire__label}>Nombre de rejets
                <span className={styles.situationBancaire__sublabel}>régularisés ou non</span>
              </p>
              <Tooltip
                wrapperClassName={styles.situationBancaire__tooltip}
                title="Nombre de rejets"
                content={(
                  <p className={styles.situationBancaire__tooltipText}>
                    Une échéance prélevée en plusieurs fois sur le même mois (fractionnement sans majoration) et les reports <br />
                    de mensualité justifiés ne sont pas des rejets.
                  </p>)}

              />
            </div>
          </div>
          {loading ? <SituationBancairePlaceholder /> : (<SituationBancaireForm
            numberOfComptes={situationBancaire?.numberOfComptes}
            numberOfRejetsConso={situationBancaire?.numberOfRejetsConso}
            numberOfRejetsImmo={situationBancaire?.numberOfRejetsImmo}
            setNumberOfComptes={setNumberOfComptes}
            setNumberOfRejetsConso={setNumberOfRejetsConso}
            setNumberOfRejetsImmo={setNumberOfRejetsImmo}
            errors={errors}
            showErrors={showErrors}
          />)}
        </form>
        {showErrors && (<SaisieErrorMessages
          errors={errorMessages}
        />)}
      </Card>
    </div>
  )
}


SituationBancaireForm.propTypes = {
  numberOfComptes: number,
  numberOfRejetsConso: number,
  numberOfRejetsImmo: number,
  setNumberOfComptes: func,
  setNumberOfRejetsConso: func,
  setNumberOfRejetsImmo: func,
  errors: arrayOf(shape({
    field: string,
    value: string,
  })),
  showErrors: bool,
}

SituationBancaireForm.defaultProps = {
  numberOfComptes: 0,
  numberOfRejetsConso: 0,
  numberOfRejetsImmo: 0,
  setNumberOfComptes: () => {},
  setNumberOfRejetsConso: () => {},
  setNumberOfRejetsImmo: () => {},
  errors: [],
  showErrors: false,
}
function SituationBancaireForm({
  numberOfComptes,
  numberOfRejetsConso,
  numberOfRejetsImmo,
  setNumberOfComptes,
  setNumberOfRejetsConso,
  setNumberOfRejetsImmo,
  errors,
  showErrors,
}) {
  const hasErrorForName = name => hasError(name, errors, showErrors)
  return (
    <div className={styles.situationBancaire__form}>
      <Input
        className={styles.situationBancaire__comptesCourants}
        type="number"
        min="0"
        precision="0"
        name="situationBancaire.numberOfComptes"
        label="Nombre de comptes courants"
        hasError={hasErrorForName('nbComptes')}
        hideLabel
        value={clearEmpty(numberOfComptes)}
        onChange={({ target }) => setNumberOfComptes(target.value)}
      />
      <div className={styles.situationBancaire__rejets}>
        <div>
          <p className={styles.situationBancaire__rejetsLabel}>Prêt conso</p>
          <Input
            className={styles.situationBancaire__rejetsInput}
            type="number"
            min="0"
            precision="0"
            name="situationBancaire.numberOfRejetsConso"
            label="Prêt conso"
            hideLabel
            hasError={hasErrorForName('nbImpayeCtTotal')}
            value={clearEmpty(numberOfRejetsConso)}
            onChange={({ target }) => setNumberOfRejetsConso(target.value)}
          />
        </div>
        <div>
          <p className={styles.situationBancaire__rejetsLabel}>Prêt immo/RAC hypothécaire</p>
          <Input
            className={styles.situationBancaire__rejetsInput}
            type="number"
            min="0"
            precision="0"
            name="situationBancaire.numberOfRejetsImmo"
            hasError={hasErrorForName('nbImpayeImmoTotal')}
            label="Prêt immobilier"
            hideLabel
            value={clearEmpty(numberOfRejetsImmo)}
            onChange={({ target }) => setNumberOfRejetsImmo(target.value)}
          />
        </div>
      </div>
    </div>
  )
}
