import React from 'react'
import styles from './ToPrintDocument.module.css'
import { Diagnostic } from '../../Simulation/Diagnostic'
import { ToPrintSimulationProduit } from './ToPrintSimulationProduit'
import { Header } from '../../Simulation/Header'

/**
 * Contenu de dossier à imprimer ou à télécharger
 */


export function ToPrintSimulation() {
  return (
    <>
      <Header />
      <div className={styles.printDocument__simulation_content}>
        <Diagnostic />
        <ToPrintSimulationProduit />
      </div>
    </>
  )
}
