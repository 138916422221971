import React from 'react'
import { bool, node } from 'prop-types'
import classnames from 'classnames'

import { Placeholder, TextRow } from '@mmb/ui-components'

import styles from './CommunicationsPlaceholder.module.css'

CommunicationsPlaceholder.propTypes = {
  children: node.isRequired,
  hasCoEmprunteur: bool.isRequired,
  loading: bool,
}

CommunicationsPlaceholder.defaultProps = {
  loading: false,
}

export function CommunicationsPlaceholder({ children, hasCoEmprunteur, loading }) {
  return (
    <Placeholder
      loading={loading}
      customPlaceholder={(
        <div className={styles.communicationsPlaceholder} aria-label="Chargement en cours" tabIndex={-1}>
          <div className={classnames(styles.communicationsPlaceholder__block, styles.communicationsPlaceholder__blockFirst)}>
            <TextRow width="34rem" className={styles.communicationsPlaceholder__row} />
            <TextRow width="34rem" className={styles.communicationsPlaceholder__row} />
            <TextRow width="34rem" className={styles.communicationsPlaceholder__row} />
          </div>
          {hasCoEmprunteur && (
            <div className={classnames(styles.communicationsPlaceholder__block, styles.communicationsPlaceholder__blockSecond)}>
              <TextRow width="34rem" className={styles.communicationsPlaceholder__row} />
              <TextRow width="34rem" className={styles.communicationsPlaceholder__row} />
              <TextRow width="34rem" className={styles.communicationsPlaceholder__row} />
            </div>
          )}
        </div>
      )}
    >
      {children}
    </Placeholder>
  )
}
