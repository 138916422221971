import { gql, useQuery, DO_NOT_CHECK_CACHE } from '@core/graphql'
import { Gamme } from '@partenaire/common'

export const GET_DETAIL_PRET = gql`
query getDetailPrets($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    id
    gamme
    propositionCredit {
      planFinancement {
        mandatApporteur
        tresorerie
        fraisNotaire
        fraisDossier
        montantApport
        montantOperation
        montantARacheter
      }
    }
  }
}
`

export function useDetailPret(id) {
  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_DETAIL_PRET, {
    ...DO_NOT_CHECK_CACHE,
    errorPolicy: 'all',
    variables: { id },
  })

  if (error) return { error }
  if (loading) return { loading }
  if (!data.partenaire_dossierTransmis?.propositionCredit) return {}
  const { planFinancement } = data.partenaire_dossierTransmis.propositionCredit

  const { mandatApporteur, tresorerie, fraisNotaire, montantApport, montantOperation, fraisDossier, montantARacheter, ...plan } = planFinancement
  const { gamme } = data.partenaire_dossierTransmis

  const detailFinancementItems = [
    {
      label: 'Trésorerie',
      value: tresorerie,
    },
    {
      label: 'Honoraires intermédiaires de crédit',
      value: mandatApporteur,
    },
    {
      label: 'Frais de dossier bancaire',
      value: fraisDossier,
    },
    ...(gamme === Gamme.SECURED ? [{
      label: 'Evaluation des frais d\'acte',
      value: fraisNotaire,
    }] : []),
    {
      label: 'Montant des prêts à racheter',
      value: montantARacheter,
    },
    ...(gamme === Gamme.SECURED ? [{
      label: 'Apport personnel',
      value: montantApport,
    }, {
      label: 'Montant de l\'opération',
      value: montantOperation,
    }] : []),
  ]
  return {
    ...plan,
    detailFinancementItems,
  }
}

