import React from 'react'
import { node, string, bool } from 'prop-types'
import classNames from 'classnames'
import { Spinner } from '@mmb/ui-components'

import styles from './RoundButton.module.css'


RoundButton.propTypes = {
  children: node.isRequired,
  className: string,
  outline: bool,
  asText: bool,
  loading: bool,
}

RoundButton.defaultProps = {
  className: '',
  outline: false,
  asText: false,
  loading: false,
}

export function RoundButton({
  children,
  className,
  outline,
  asText,
  loading,
  ...props
}) {
  return (
    <button
      {...props}
      className={classNames(
        styles.button,
        className,
        { [styles.buttonOutline]: outline },
      )}
    >
      {loading ? (
        <Spinner size="2rem" className={classNames(styles.button__spinner)} svgClassName={styles.button__spinnerCircle} />
      ) : children}
    </button>
  )
}
