import { DOSSIER_STATUTS_LIBELLES, DossierStatut, Gamme } from '@partenaire/common'

export const getDossierRedirectPath = (id: string, statut?: DossierStatut, hasAcces?: boolean) : string => {
  if (statut === DossierStatut.SAISIE) {
    return hasAcces ? `/app/dossiers/saisie/${id}` : '/app/dossiers'
  }
  return `/app/dossiers/${id}`
}

export const isDossierStatusComplete = (statut? : DossierStatut) => {
  return statut !== DossierStatut.SAISIE && statut !== DossierStatut.TELETRANSMIS
}

export function getStatutLabel(gamme: Gamme, searchValue?: DossierStatut, isSignatureElectronique?: boolean) : string | undefined {
  const etapesWithGammeValue = DOSSIER_STATUTS_LIBELLES.filter(({
    value,
    isSecured }) => (value === searchValue) && (isSecured === undefined || isSecured === (gamme === Gamme.SECURED))) ?? {}
  if (etapesWithGammeValue.length <= 1) {
    return etapesWithGammeValue[0]?.label
  }

  return etapesWithGammeValue.find(({ hasSignatureElectronique }) => hasSignatureElectronique === isSignatureElectronique)?.label
}
