import React from 'react'
import { Navigate } from 'react-router'
import { any } from 'prop-types'
import { Redirection } from '../Redirection'
import { useAzureADCallback } from '../../hooks/AzureAD/useAzureADCallback.hook'

Callback.propTypes = {
  wrapInUnauthenticatedLayout: any,
}

Callback.defaultProps = {
  wrapInUnauthenticatedLayout: undefined,
}

export function Callback({ wrapInUnauthenticatedLayout }) {
  const { navigateToUrl, technicalIssue } = useAzureADCallback()
  if (navigateToUrl) {
    return <Navigate to={navigateToUrl} />
  }

  return <Redirection technicalIssue={technicalIssue} wrapper={wrapInUnauthenticatedLayout} />
}
