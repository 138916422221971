import React from 'react'
import { oneOf } from 'prop-types'
import { Card, NotFound, NotEligible, NoProduct } from '@mmb/ui-components'
import { PARTENAIRE_CONTACT_PHONE, PARTENAIRE_CONTACT_EMAIL, PARTENAIRE_LINK_TO_MENTIONS_LEGALES } from '@core/common-frontend'


import styles from './SimulationCard.module.css'
import { SIMULATION_CARDS_STATUS } from '../const'

const CARD_STATE = {
  INTERNAL_ERROR: {
    title: "La simulation n'est pas disponible",
    message: (
      <>
        <p className={styles.simulationCard__message}>
          Un dysfonctionnement technique empêche l'affichage de la simulation.
        </p>
        <p className={styles.simulationCard__message}>
          Veuillez réessayer ultérieurement.
        </p>
      </>
    ),
    icon: <NotFound className={styles.simulationCard__icon} />,
  },
  NON_ELIGIBLE: {
    title: 'Client non éligible',
    message: (
      <>
        <p className={styles.simulationCard__message}>
          Pour cette demande de financement, nous vous informons que le demandeur
          (prospect ou client) bénéficie du droit de contester cette décision,
          d’exprimer son point de vue et de solliciter un entretien aux fins de réexamen
          de son dossier sans prise de décision entièrement automatisée.
          A cette fin, nos équipes sont joignables au
          <strong> {PARTENAIRE_CONTACT_PHONE} </strong>
          ou à l’adresse suivante :
          <strong> {PARTENAIRE_CONTACT_EMAIL} </strong>
        </p>

        <p className={styles.simulationCard__message}>
          Nous vous rappelons qu’il vous appartient de leur communiquer la notice
          d’information sur la protection des données personnelles de My Money Bank
          (accessible à l’adresse :
          <a href={PARTENAIRE_LINK_TO_MENTIONS_LEGALES} target="_blank" rel="noreferrer">
            <strong> {PARTENAIRE_LINK_TO_MENTIONS_LEGALES}</strong>
          </a>
          ), avant de nous communiquer leurs données.
        </p>
      </>
    ),
    icon: <NotEligible className={styles.simulationCard__icon} />,
  },
  NON_VALIDE: {
    title: 'Aucun résultat disponible',
    message: (
      <p className={styles.simulationCard__message}>
        My Money Bank ne peut pas faire de proposition. <br />
        Merci de revoir le plan de financement ou de contacter votre
        commercial.e.
      </p>
    ),
    icon: <NoProduct className={styles.simulationCard__icon} />,
  },
}

SimulationCard.propTypes = {
  state: oneOf(SIMULATION_CARDS_STATUS).isRequired,
}

export function SimulationCard({ state }) {
  const cardState = CARD_STATE[state]
  return (
    <Card className={styles.simulationCard}>
      <h3 className={styles.SimulationCard__title}>{cardState.title}</h3>
      {cardState.icon}
      {cardState.message}
    </Card>
  )
}
