import { gql, useMutation } from '@core/graphql'

import { toast } from '@core/ui'

import { USE_SEARCH_PERSONNES_QUERY } from '../List/List.hooks'

const UPDATE_PERSONNE_ROLE = gql`
  mutation ($idPersonne: String!, $role: partenaire_RoleNom!, $agences: [String], $cabinets: [String]) {
    partenaire_updatePersonneRole(idPersonne: $idPersonne, role: $role, agences: $agences, cabinets: $cabinets)
  }
`

export const useUpdatePersonne = () => {
  const [mutation] = useMutation(UPDATE_PERSONNE_ROLE, {
    refetchQueries: [USE_SEARCH_PERSONNES_QUERY],
    awaitRefetchQueries: true,
    onCompleted: () => toast({ message: 'Un utilisateur a été modifié.', type: 'SUCCESS' }),
    onError: () => toast({ message: 'La modification a échouée.', type: 'ERROR' }),
  })
  return (idPersonne, role, agences, cabinets) => mutation({ variables: { idPersonne, role, agences, cabinets } })
}
