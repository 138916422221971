import React from 'react'
import { func, string } from 'prop-types'

import { ModalTrigger } from '@core/partenaire-ui'

import { Delete } from '../../Delete'
import { DeleteButton } from './deleteButton'

DeleteModal.propTypes = {
  onClose: func.isRequired,
  email: string.isRequired,
  id: string.isRequired,
}

export function DeleteModal({ onClose, email, id }) {
  return (
    <ModalTrigger
      renderButton={(_, setModalOpen) => (
        <DeleteButton setModalOpen={setModalOpen} />
      )}
      onClose={onClose}
    >
      <Delete email={email} personneId={id} onClose={onClose} />
    </ModalTrigger>
  )
}
