import React from 'react'
import { func, object } from 'prop-types'
import classNames from 'classnames'
import { CloseCross, Spinner, Loader, Retry } from '@mmb/ui-components'

import styles from './FileFish.module.css'

FileFish.propTypes = {
  file: object.isRequired,
  onDelete: func,
}

FileFish.defaultProps = {
  onDelete: null,
}

export function FileFish({ file, onDelete }) {
  const showFileSize = number => number > 0
  const isError = Boolean(file.error)
  const showRetryIcon = isError && file.canRetry
  const isUploaded = !file.loading && !isError
  const showThumbnail = isUploaded && file.thumbnail
  const showDocIcon = isUploaded && !file.thumbnail

  const formatFileSize = number => {
    if (number < 1024) {
      return `${number} octets`
    }

    if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} Ko`
    }

    return `${(number / 1048576).toFixed(1)} Mo`
  }

  return (
    <div
      title={file.name}
      className={classNames(
        styles.fileFish,
        { [styles.fileFish__fileLoading]: file.loading },
        { [styles.fileFish__fileThumbnail]: showThumbnail },
        { [styles.fileFish__fileError]: file.error || file.canRetry },
      )}
    >
      {showThumbnail && (
        <div className={styles.fileFish__thumbnailWrapper}>
          <img className={styles.fileFish__thumbnail} src={file.thumbnail} alt={file.name} />
          <div className={styles.fileFish__thumbnailOverlay} />
        </div>
      )}
      <div className={styles.fileFish__status}>
        {file.loading && (
          <div className={styles.fileFish__loading}>
            {file.progress ? (
              <>
                <Loader className={styles.fileFish__spinner} value={file.progress} colorVar="white" />
                {file.progress.toFixed(0)}%
              </>
            ) : (
              <Spinner className={styles.fileFish__spinner} colorVar="white" />
            )}
          </div>
        )}
        {showDocIcon && (
          <div className={styles.fileFish__done}>
            <svg height="32" viewBox="0 0 112.01 143.8">
              <path
                d="M7,0A7,7,0,0,0,0,7V136.83a7,7,0,0,0,7,7H105a7,7,0,0,0,7-7V32L80,0Zm99.28,138H5.77V5.77H73.55V31.49a7,7,
              0,0,0,7,7h25.72ZM79.33,32.69V7.46l25.23,25.23Z"
              />
            </svg>
          </div>
        )}
        {!file.loading && file.error && (
          <div className={styles.fileFish__error}>
            <div className={styles.fileFish__errorName}>
              {file.error}
            </div>
            <div className={styles.fileFish__errorTip}>
              {file.errorTip}
            </div>
          </div>
        )}
        {showRetryIcon && (
          <div onClick={file.retry}>
            <Retry className={styles.fileFish__retry} />
          </div>
        )}
      </div>
      <div className={styles.fileFish__fileInfos}>
        <div className={styles.fileFish__fileName}>
          {file.name}
        </div>
        {showFileSize(file.size) && (
          <div className={styles.fileFish__fileSize}>
            {formatFileSize(file.size)}
          </div>
        )}
      </div>
      {!file.loading && (file.forceCanDelete || !file.error) && onDelete && (
        <div className={styles.fileFish__delete} onClick={() => onDelete(file)}>
          <CloseCross className={styles.fileFish__deleteIcon} />
        </div>
      )}
    </div>
  )
}
