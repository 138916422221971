import React from 'react'
import { node, string } from 'prop-types'
import { useIzanamiFeatureByName } from '../useIzanamiFeature.hooks'

export function Enabled({ children }) { return children }
export function Disabled({ children }) { return children }
export function Pending({ children }) { return children }

const isSameComponentType = (component, componentType) => component?.type?.name === componentType?.name

const getIfPendingComponent = component => isSameComponentType(component, Pending) ? component : null
const getIfEnabledComponent = component => isSameComponentType(component, Enabled) ? component : null
const getIfDisabledComponent = component => isSameComponentType(component, Disabled) ? component : null

Feature.propTypes = {
  children: node.isRequired,
  name: string.isRequired,
}

export function Feature({ children, name }) {
  const { active, firstFetchPending } = useIzanamiFeatureByName(name)

  if (firstFetchPending) {
    const pendingChild = React.Children.map(children, getIfPendingComponent).find(Boolean)

    if (pendingChild) {
      return pendingChild
    }
  }

  if (active) {
    const activeChild = React.Children.map(children, getIfEnabledComponent).find(Boolean)

    return activeChild || React.Children.toArray(children)[0] || null
  }

  return React.Children.map(children, getIfDisabledComponent).find(Boolean) || null
}
