/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { useParams } from '@core/routing'
import classnames from 'classnames'
import { FixedSizeList } from 'react-window'

import { ButtonLink, DocPdf, Download, InformationMessage } from '@mmb/ui-components'

import { DetailLayout } from '../Detail/DetailLayout'
import { useContrat } from './Contrat.hooks'

import styles from './Contrat.module.css'


const formatText = text => text && text.replace('’', '\'')

export function Contrat() {
  const { id } = useParams()
  const { documents, dateEnvoi, totalCount, loading, gamme, isSignatureManuscrite } = useContrat(id!)

  const getDownloadPath = (documentId: string) => `${(window as any).GATSBY_API_URL}/contrat/${id}/${documentId}`
  const downloadAllPath = `${(window as any).GATSBY_API_URL}/contrat/${id}`
  const isDcu = gamme === 'UNSECURED'

  if (loading) return null

  return (
    <DetailLayout className={styles.contrat} id={id!} title="Contrat" right={dateEnvoi && `Envoyé le ${dateEnvoi}`}>
      {isDcu && isSignatureManuscrite && (
        <InformationMessage className={styles.contrat__information}>
          <span className={styles.contrat__informationBold}>Gagnez du temps ! </span>
          En sans garantie, imprimez l'offre <span className={styles.contrat__informationBold}>recto-verso </span>
          et faites-la signer par votre client. Dans tous les cas, l'offre est envoyée par courrier.
          Vous pourrez guider votre client avec le <span className={styles.contrat__informationBold}>mode d'emploi </span>
          téléchargeable ci-dessous.
        </InformationMessage>
      )}
      {totalCount > 0 && dateEnvoi && (
        <div role="table" aria-label="Contrats" className={styles.contrat__list}>
          <div role="rowgroup">
            <div role="row" className={styles.contrat__header}>
              <span role="columnheader" />
              <span role="columnheader">Nom du document</span>
              <span role="columnheader" />
            </div>
          </div>
          <div role="rowgroup">
            <FixedSizeList height={totalCount * 50} itemCount={totalCount} itemSize={50}>
              {({ index, style }) => {
                const { libelle, id: documentId } = documents[index]
                return (
                  <div style={style}>
                    <a
                      role="button"
                      aria-label="Pret"
                      className={classnames(styles.contrat__line, { [styles.contrat__lineStriped]: index % 2 !== 0 })}
                      href={getDownloadPath(documentId)}
                      referrerPolicy="origin"
                      download
                    >
                      <DocPdf className={styles.contrat__pdfIcon} /> {formatText(libelle)}
                    </a>
                  </div>
                )
              }}
            </FixedSizeList>
          </div>
          <div className={styles.contrat__form}>
            <ButtonLink
              outline
              className={styles.contrat__button}
              referrerPolicy="origin"
              href={downloadAllPath}
              leftIcon={({ ...props }) => <Download className={styles.contrat__downloadIcon} {...props} />}
              linkComponent={({ ...props }) => <a {...props} />}
              download
            >
              Tout télécharger
            </ButtonLink>
          </div>
        </div>
      )}
    </DetailLayout>
  )
}
