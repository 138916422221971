import { useEffect, useState } from 'react'
import queryString from 'query-string'

import { gql, useMutation } from '@core/graphql'
import { useLocation } from '@core/routing'
import { EMAIL_INTROUVABLE, CREATION_COMPTE_IMPOSSIBLE, useForm, useErrorReducer } from '@core/ui'

import { validateSignUp } from '../../utils/signUpValidationErros'

/* eslint-disable max-len */
const CREATE_ACCOUNT = gql`
  mutation createAccount($email: String!, $nom: String!, $prenom: String!, $civilite: String!, $password: String!) {
    partenaire_createAccount(email: $email, nom: $nom, prenom: $prenom, civilite: $civilite, password: $password)
  }
`

const useValidation = (loading: boolean, value: any) => (
  { errors: loading ? { signUpErrors: [], hasErrors: false, globalErrors: [] } : validateSignUp(value) }
)

export function useCreateAccount() {
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [createAccount, { loading }] = useMutation(CREATE_ACCOUNT)
  const [showErrors, setShowErrors] = useState(false)
  const [callbackError, setCallbackError] = useErrorReducer()
  const [sent, setSent] = useState(false)
  const [captcha, setCaptcha] = useState('')
  const { form, setFormFieldFromEvent, setFormField } = useForm({ nom: '', prenom: '', password: '', confirmPassword: '', civilite: '' })
  const { errors } = useValidation(loading, { ...form, captcha })

  const getParsingValues = parseValues => {
    setEmail(parseValues?.email?.toString() || '')
    return !!parseValues?.email
  }

  useEffect(() => {
    const parsValues = queryString.parse(location.search)
    if (!getParsingValues(parsValues)) {
      setShowErrors(true)
      setCallbackError(EMAIL_INTROUVABLE)
    }
  }, [location.search])

  useEffect(() => {
    const { prenom, nom, civilite } = queryString.parse(location.search)
    if (prenom) {
      setFormField('prenom', prenom)
    }
    if (nom) {
      setFormField('nom', nom)
    }
    if (civilite) {
      setFormField('civilite', civilite)
    }
  }, [location.search])

  const submitSignup = async event => {
    event.preventDefault()
    setShowErrors(true)
    if (errors?.hasErrors) return
    const variables = { email, civilite: form.civilite, nom: form.nom, prenom: form.prenom, password: form.password }
    try {
      await createAccount({ variables })
      setSent(true)
      setShowErrors(false)
    } catch (e) {
      setCallbackError(CREATION_COMPTE_IMPOSSIBLE)
    }
  }

  const { signUpErrors = [], globalErrors = [] } = errors
  const allErrors = [
    callbackError,
    ...globalErrors,
  ].filter(f => f)

  return {
    submitSignup,
    email,
    showErrors,
    sent,
    setCaptcha,
    errors: allErrors,
    signUpErrors,
    setFormFieldFromEvent,
    form,
  }
}
