import React from 'react'

import { ProtectionDonnees, UnauthenticatedLayout } from '@core/partenaire-frontend'

function ProtectionDesDonneesUnauth() {
  return (
    <UnauthenticatedLayout>
      <ProtectionDonnees />
    </UnauthenticatedLayout>
  )
}

export default ProtectionDesDonneesUnauth
