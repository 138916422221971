import React from 'react'
import { NotFound as NotFoundIcon } from '@mmb/ui-components'

import styles from './NotFound.module.css'

export function NotFound() {
  return (
    <div className={styles.notfound}>
      <h3>Désolé, mais la page est introuvable</h3>
      <NotFoundIcon className={styles.notfound__icon} />
      <p>
        Nous avons pourtant cherché partout... <br />
        La page n'existe pas ou l'URL est incorrect.
      </p>
    </div>
  )
}
