import React from 'react'
import { node, string } from 'prop-types'
import cn from 'classnames'

import styles from './Telephone.module.css'

Telephone.propTypes = {
  children: node.isRequired,
  className: string,
}

Telephone.defaultProps = {
  className: '',
}

export function Telephone({ children, className }) {
  return (
    <span className={cn(styles.telephone, className)}>
      {children}
    </span>
  )
}
