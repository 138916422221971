import React, { createContext } from 'react'
import { element, node, string } from 'prop-types'

import { MyAccessPro } from '@mmb/ui-components'

export const portailPartenaireContext = createContext({ title: 'Portail partenaire', iconHeader: <MyAccessPro />, name: 'My Access Pro' })

PortailPartenaireProvider.propTypes = {
  iconHeader: element.isRequired,
  title: string.isRequired,
  name: string.isRequired,
  children: node.isRequired,
}

export function PortailPartenaireProvider({ iconHeader, title, name, children }) {
  return (
    <portailPartenaireContext.Provider value={{ iconHeader, title, name }}>
      {children}
    </portailPartenaireContext.Provider>
  )
}
