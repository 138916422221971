import { NatureCharge } from '@partenaire/common'

export interface ChargeOptionUI {
  label:string
  value: string
}

export const NATURE_CHARGE_LABEL_MAP = new Map<NatureCharge | undefined, string>([
  [NatureCharge.LOYER_ENFANT, 'Loyer enfant'],
  [NatureCharge.LOYER_RP, 'Loyer résidence principale'],
  [NatureCharge.LOYER_AUTRE_QUE_RP, 'Loyer hors résidence principale'],
  [NatureCharge.LOYER_FICTIF, 'Loyer fictif'],
  [NatureCharge.VIREMENT_RECURRENT_VERS_UN_TIERS, 'Virement récurrent vers un tiers'],
  [NatureCharge.PENSION_ALIMENTAIRE, 'Pension alimentaire'],
  [NatureCharge.PRESTATION_COMPENSATOIRE, 'Prestation compensatoire'],
  [NatureCharge.LOCATION_LONGUE_DUREE, 'LLD (Location Longue Durée)'],
  [NatureCharge.IMPOTS_SUR_LE_REVENU, 'Impots sur le revenu'],
  [NatureCharge.PRET_IMMOBILIER, 'Prêt immobilier'],
  [NatureCharge.PRET_AUTOMOBILE, 'Prêt automobile'],
  [NatureCharge.PRET_PERSONNEL, 'Prêt personnel'],
  [NatureCharge.CREDIT_RENOUVELABLE, 'Credit renouvelable'],
  [NatureCharge.AUTRE_CREDIT, 'Autre credit'],
  [NatureCharge.AUTRE, 'Autre'],
])

export const CHARGE_OPTIONS = [{
  label: 'Loyer enfant',
  value: 'LOYER_ENFANT',
},
{
  label: 'Loyer résidence principale',
  value: 'LOYER_RP',
},
{
  label: 'Loyer hors résidence principale',
  value: 'LOYER_AUTRE_QUE_RP',
},
{
  label: 'Loyer fictif',
  value: 'LOYER_FICTIF',
},
{
  label: 'Virement récurrent vers un tiers',
  value: 'VIREMENT_RECURRENT_VERS_UN_TIERS',
},
{
  label: 'Pension alimentaire',
  value: 'PENSION_ALIMENTAIRE',
},
{
  label: 'Prestation compensatoire',
  value: 'PRESTATION_COMPENSATOIRE',
},
{
  label: 'LLD (Location Longue Durée)',
  value: 'LOCATION_LONGUE_DUREE',
},
{
  label: 'Impots sur le revenu',
  value: 'IMPOTS_SUR_LE_REVENU',
},
{
  label: 'Prêt immobilier',
  value: 'PRET_IMMOBILIER',
},
{
  label: 'Prêt automobile',
  value: 'PRET_AUTOMOBILE',
},
{
  label: 'Prêt personnel',
  value: 'PRET_PERSONNEL',
},
{
  label: 'Credit renouvelable',
  value: 'CREDIT_RENOUVELABLE',
},
{
  label: 'Autre credit',
  value: 'AUTRE_CREDIT',
},
{
  label: 'Autre',
  value: 'AUTRE',
},
] as ChargeOptionUI[]
