import React from 'react'

import { Checkbox } from '@mmb/ui-components'
import { Tooltip } from '@core/partenaire-ui'

import styles from './BaisseRevenu.module.css'
import { BaisseRevenuForm } from './BaisseRevenuForm'
import { useRevenus } from '../../RevenusContext'
import { TOOLTIP_BAISSE_REVENU } from '../../tooltip'

interface BaisseRevenuProps {
    errors
    showErrors: boolean
}

export function BaisseRevenu({ errors, showErrors }: BaisseRevenuProps) {
  const { revenusUI, handleToogleBaisseRevenu } = useRevenus()
  return (
    <>
      <div className={styles.baisse__button}>
        <Checkbox
          label={`Baisse de revenu à prévoir
            ex : départ à la retraite`}
          name="baisse de salaire emprunteur"
          aria-label="baisse de salaire emprunteur"
          className={styles.baisse__labelCheck}
          checked={revenusUI?.emprunteurRevenus.hasBaisseRevenu()}
          onChange={async () => { await handleToogleBaisseRevenu(true) }}
        />
        {revenusUI?.hasCoEmprunteur && (
        <Checkbox
          label={`Baisse de revenu à prévoir
            ex : départ à la retraite`}
          name="baisse de salaire co-emprunteur"
          aria-label="baisse de salaire co-emprunteur"
          className={styles.baisse__labelCheck}
          checked={revenusUI?.coEmprunteurRevenus.hasBaisseRevenu()}
          onChange={async () => { await handleToogleBaisseRevenu(false) }}
        />
        )}
      </div>
      <div className={styles.baisse__listsLabels}>
        {(revenusUI?.emprunteurRevenus.hasBaisseRevenu() || revenusUI?.coEmprunteurRevenus.hasBaisseRevenu()) && (
        <div className={styles.baisse__baisseLabels}>
          <div className={styles.baisse__baisseLabels__grid}>
            <div className={styles.baisse__baisseLabels__label}>
              Montant prévisionnel
              <Tooltip
                wrapperClassName={styles.revenusForm__tooltip}
                title={TOOLTIP_BAISSE_REVENU?.title}
                content={(<p>{TOOLTIP_BAISSE_REVENU?.content}</p>)}
              />
            </div>
            <p className={styles.baisse__baisseLabels__label}>Date de début</p>
          </div>
        </div>
        )}
        {revenusUI?.emprunteurRevenus.hasBaisseRevenu() ? (
          <BaisseRevenuForm
            type="emprunteur"
            showErrors
            errors={errors?.baisseRevenuEmprunteurErrors}
            baisseRevenu={revenusUI.emprunteurRevenus.getBaisseRevenu()!}
            update={(value: any) => revenusUI.updateBaisseRevenu(value, true)}
          />
        ) : <div />}
        {revenusUI?.hasCoEmprunteur && revenusUI.coEmprunteurRevenus.hasBaisseRevenu() ? (
          <BaisseRevenuForm
            type="co-emprunteur"
            showErrors={showErrors}
            errors={errors?.baisseRevenuCoEmprunteurErrors}
            baisseRevenu={revenusUI.coEmprunteurRevenus.getBaisseRevenu()!}
            update={(value: any) => revenusUI.updateBaisseRevenu(value, false)}
          />
        ) : <div />}
      </div>
    </>
  )
}
