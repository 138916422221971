import { useEffect, useState } from 'react'
import { useCommunesOptions, toCommunesQuery } from '@partenaire/frontend-ui'
import { Commune, suggestionsCommune } from '@partenaire/frontend-common'
import { BienUI, PatrimoineUI, ValidationFieldError } from '../../../model'
import { hasErrorForName as hasError } from '../../../utils'

interface BienHooksProps {
    setPatrimoine: (patrimoineUI: PatrimoineUI) => void
    errors: ValidationFieldError[]
    showErrors: boolean
    patrimoine: PatrimoineUI
}

type Option = { label: string, value: string }

const toInseeOption = (communes: Commune) : Option => ({
  label: `Insee ${communes.codeInsee} - ${communes.nom}, ${communes.codePostal}`,
  value: communes.codeInsee,
})

export function useBien({ setPatrimoine, errors = [], showErrors, patrimoine } : BienHooksProps) {
  const [codeInseeOptions, setCodeInseeOptions] = useState<Option[]>([])
  const [loadInseeOptionTimer, setLoadInseeOptionTimer] = useState<any>()

  const formatCommune = ({ nom, codePostal, codeInsee }) => ({
    label: `${codePostal} ${nom}`,
    value: JSON.stringify({ codePostal, codeInsee, nom }),
  })
  const { setCodePostalVille, citiesOptions } = useCommunesOptions(formatCommune)
  const setBien = (bien: BienUI) => setPatrimoine({ ...patrimoine, bien: { ...patrimoine.bien, ...bien } })
  const hasErrorForName = name => hasError(name, errors, showErrors)
  const canSelectCodeInsee = Boolean(!hasErrorForName('codePostalVille') && patrimoine?.bien?.codePostalVille && codeInseeOptions?.length !== 0)

  const updateInseeOptions = async (codePostalVille: string) => {
    const query = toCommunesQuery(codePostalVille) ?? {}
    const options = await suggestionsCommune(query).then(communes => communes.map(toInseeOption))
    setCodeInseeOptions(options)
    if (options.length === 1) {
      setBien({ codeInsee: options[0].value })
    }
  }

  useEffect(() => {
    setCodeInseeOptions([])
    if (loadInseeOptionTimer) {
      clearTimeout(loadInseeOptionTimer)
    }
    const codePostalVille = patrimoine?.bien?.codePostalVille
    if (codePostalVille) {
      setLoadInseeOptionTimer(setTimeout(() => updateInseeOptions(codePostalVille), 500))
    }
  }, [patrimoine?.bien?.codePostalVille])

  return {
    setCodePostalVille,
    setBien,
    citiesOptions,
    canSelectCodeInsee,
    codeInseeOptions,
  }
}
