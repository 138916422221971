import React from 'react'
import { string } from 'prop-types'

import { Card } from '@mmb/ui-components'
import { FormHeader } from '@core/partenaire-ui'

import { ColumnName } from '../ColumnName'
import { Activites } from './Activites/Activites/Activites'
import { EntryFormStateName } from '../FormState/EntryFormState'

SituationProfessionnelle.propTypes = {
  className: string,
}

SituationProfessionnelle.defaultProps = {
  className: '',
}

export function SituationProfessionnelle({ className }) {
  return (
    <div id={`${EntryFormStateName.ACTIVITE}`}>
      <Card className={className}>
        <FormHeader>Situation professionnelle</FormHeader>
        <ColumnName />
        <Activites />
      </Card>
    </div>
  )
}
