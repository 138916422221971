import React, { useEffect, useState } from 'react'
import { func, node, number, object } from 'prop-types'
import { fetch, isBrowser } from '@core/common-frontend'
import { IzanamiFeatureContextProvider, EMPTY_OBJECT } from './IzanamiFeatureContext'

IzanamiProvider.propTypes = {
  failureFetchDelay: number,
  children: node.isRequired,
  features: object,
  featuresFallback: object,
  onFailureMaxRetry: number,
  onFailureMaxRetryExceeded: func,
}

IzanamiProvider.defaultProps = {
  featuresFallback: EMPTY_OBJECT,
  features: EMPTY_OBJECT,
  failureFetchDelay: 3000,
  onFailureMaxRetry: 3,
  onFailureMaxRetryExceeded: () => {},
}

const getVariableFront = variable => isBrowser ? window[variable] : undefined

export function IzanamiProvider({
  features,
  children,
  featuresFallback,
  failureFetchDelay,
  onFailureMaxRetry,
  onFailureMaxRetryExceeded }) {
  const [serverDatas, setServerDatas] = useState(undefined)
  const firstFetchPending = serverDatas === undefined

  useEffect(() => {
    const fetchFrom = `${getVariableFront('GATSBY_API_URL')}/api/izanami`
    if (!getVariableFront('GATSBY_API_URL')) {
      return
    }
    let nbRetry = 0
    let timeoutId

    const doFetch = async () => {
      try {
        const fetchResult = await fetch(fetchFrom, { method: 'GET', credentials: 'omit' })
        if (fetchResult.ok) {
          setServerDatas(await fetchResult.json())
        } else {
          throw new Error(`GET ${fetchFrom} => ${fetchResult.status}`)
        }
      } catch (e) {
        nbRetry++
        if (nbRetry > onFailureMaxRetry) {
          console.error('Impossible d\'appeler le serveur Izanami', e)
          onFailureMaxRetryExceeded(e)
          setServerDatas({ error: e })
        } else {
          console.warn(`Erreur lors de l'appel du serveur Izanami, re-essai n°${nbRetry}`, e)
          timeoutId = setTimeout(doFetch, failureFetchDelay)
        }
      }
    }

    timeoutId = setTimeout(doFetch, 0)
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId)
  }, [getVariableFront('GATSBY_API_URL'), failureFetchDelay, onFailureMaxRetry])

  return (
    <IzanamiFeatureContextProvider
      serverDatas={serverDatas}
      features={features}
      featuresFallback={featuresFallback}
      firstFetchPending={firstFetchPending}
    >
      {children}
    </IzanamiFeatureContextProvider>
  )
}
