import { NatureCharge } from '@partenaire/common'
import { ErrorMessage } from '../../components/SaisieErrorMessages'
import { ChargeFormUI, ChargesUI, HAS_LOYER_FICTIF, ValidationErrors, ValidationFieldError, buildValidationError } from '../../model'

const CHARGES_THAT_SHOULD_BE_PRET : NatureCharge[] = [
  NatureCharge.AUTRE_CREDIT,
  NatureCharge.CREDIT_RENOUVELABLE,
  NatureCharge.PRET_AUTOMOBILE,
  NatureCharge.PRET_IMMOBILIER,
  NatureCharge.PRET_PERSONNEL,
]

export function validateCharge(charge: ChargeFormUI) {
  const errors: any[] = []
  if (!charge?.nature) {
    errors.push(buildValidationError('nature', 'WRONG_VALUE'))
  }
  if (charge?.nature && CHARGES_THAT_SHOULD_BE_PRET.includes(charge.nature)) {
    errors.push(buildValidationError('nature', 'SHOULD_BE_PRET'))
  }
  if (!charge?.montant || String(charge?.montant) === '0') {
    errors.push(buildValidationError('montant', 'WRONG_VALUE'))
  }
  if (!!charge?.montant && charge?.montant < 0) {
    errors.push(buildValidationError('montant', 'NEGATIVE_VALUE'))
  }
  if (HAS_LOYER_FICTIF(charge.nature) && charge?.montant !== 500) {
    errors.push(buildValidationError('montant', 'FICTIF_WRONG_VALUE'))
  }
  return errors
}

export interface ChargesValidationErrors extends ValidationErrors {
  emprunteurErrors: ValidationFieldError[][]
  coEmprunteurErrors: ValidationFieldError[][]
}

export function validateCharges({ chargesUI }: { chargesUI: ChargesUI }) : ChargesValidationErrors {
  const emprunteurErrors = chargesUI.emprunteursCharges.list.map(validateCharge)
  const coEmprunteurErrors = chargesUI.coEmprunteursCharges.list.map(validateCharge)
  const globalErrors = chargesUI.emprunteursCharges.list.length + chargesUI.coEmprunteursCharges.list.length === 0 ?
    [] :
    [...(emprunteurErrors?.flatMap(p => p) || []), ...(coEmprunteurErrors?.flatMap(p => p) || [])]

  const hasErrors = (
    (globalErrors.length || 0) +
    (emprunteurErrors.flatMap(p => p)?.length || 0) +
    (coEmprunteurErrors?.flatMap(p => p)?.length || 0)
  ) > 0
  return { hasErrors, globalErrors, emprunteurErrors, coEmprunteurErrors }
}

const CHARGE_ERROR_MESSAGES = {
  WRONG_VALUE: {
    nature: { title: 'nature', message: 'La nature des charges ajoutées doit être renseignée.' },
    montant: { title: 'montant', message: 'Le montant des charges ajoutées doit être renseigné.' },
  },
  FICTIF_WRONG_VALUE: {
    montant: { title: 'charges', message: 'Le loyer fictif doit être renseigné pour un montant de 500 €' },
  },
  SHOULD_BE_PRET: {
    nature: { title: 'nature', message: 'Pour cette nature de charge, merci de bien ajouter le prêt concerné dans la partie encours' },
  },
}

export function getChargeErrors(error) : ErrorMessage | null {
  return CHARGE_ERROR_MESSAGES[error.message]?.[error.field] || undefined
}
