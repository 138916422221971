import React, { useState } from 'react'

import { Card, Section } from '@mmb/ui-components'

import styles from './RatioHypothecaire.module.css'

import { Formulaire } from './Formulaire'
import { Resultat } from './Resultat'

export function RatioHypothecaire() {
  const [showResult, setShowResult] = useState()
  const [selectedZoneGeo, setSelectedZoneGeo] = useState({
    codePostal: '',
    codeInsee: '',
    cpVille: '',
  })

  return (
    <Card className={styles.ratioHypothecaire__card}>
      <Section title="Tester un ratio hypothécaire" className={styles.ratioHypothecaire__section}>

        {!showResult && (
          <Formulaire form={selectedZoneGeo} setForm={setSelectedZoneGeo} showResult={showResult} setShowResult={setShowResult} />
        )}

        {showResult && (
          <Resultat form={selectedZoneGeo} setForm={setSelectedZoneGeo} setShowResult={setShowResult} />
        )}

      </Section>
    </Card>
  )
}
