export interface TooltipUI {
  nature: string
  content: string
}

export const TOOLTIP_INFORMATIONS = [
  {
    nature: 'AUTRE',
    content: 'Charge déduite des revenus en endettement avant dans les calculs de la simulation ',
  },
  {
    nature: 'IMPOTS_SUR_LE_REVENU',
    content: 'Charge non retenue dans les calculs de la simulation en endettement avant et après',
  },
  {
    nature: 'PRET_IMMOBILIER',
    content: 'Charge non retenue dans les calculs de la simulation en endettement avant et après',
  },
  {
    nature: 'PRET_AUTOMOBILE',
    content: 'Charge non retenue dans les calculs de la simulation en endettement avant et après',
  },
  {
    nature: 'PRET_PERSONNEL',
    content: 'Charge non retenue dans les calculs de la simulation en endettement avant et après',
  },
  {
    nature: 'CREDIT_RENOUVELABLE',
    content: 'Charge non retenue dans les calculs de la simulation en endettement avant et après',
  },
  {
    nature: 'AUTRE_CREDIT',
    content: 'Charge non retenue dans les calculs de la simulation en endettement avant et après',
  },
  {
    nature: 'VIREMENT_RECURRENT_VERS_UN_TIERS',
    // eslint-disable-next-line max-len
    content: 'Charge non retenue (elle doit être récurrente et expliquée/justifiée dans la lettre de présentation)',
  },
  {
    nature: 'LOYER_ENFANT',
    // eslint-disable-next-line max-len
    content: 'Charge non retenue (elle doit être récurrente et expliquée/justifiée dans la lettre de présentation)',
  },
  {
    nature: 'LOYER_RP',
    content: 'Charge retenue',
  },
  {
    nature: 'LOYER_AUTRE_QUE_RP',
    content: 'Charge déduite des revenus en endettement avant et après dans les calculs de la simulation',
  },
  {
    nature: 'LOYER_FICTIF',
    content: 'Loyer fictif à retenir en déduction des revenus en endettement après à hauteur de 500 € si Client hébergé à titre gratuit par famille, ' +
    'concubin ou tiers (hors employeur)',
  },
  {
    nature: 'PENSION_ALIMENTAIRE',
    content: 'Charge déduite des revenus en endettement avant et après dans les calculs de la simulation',
  },
  {
    nature: 'PRESTATION_COMPENSATOIRE',
    content: 'Charge déduite des revenus en endettement avant et après dans les calculs de la simulation',
  },
  {
    nature: 'LOCATION_LONGUE_DUREE',
    content: 'Charge déduite des revenus en endettement avant et après dans les calculs de la simulation',
  },
] as TooltipUI[]
