import { PatrimoineUI, BienUI, NatureBien, UsageBien, RegimePropriete } from '../../model'

interface EmprunteurGraphQL {
  id: string
  patrimoines: [PatrimoineGraphQL]
}

interface PatrimoineGraphQL {
  __typename: string
  id: string
  regimePropriete: RegimePropriete
  taux: string
  position: number
  bien: BienGraphQL
}

interface BienGraphQL {
  __typename: string
  id: string
  isGarantie: boolean
  nature: NatureBien
  usage: UsageBien
  complement: string
  numero: string
  voie: string
  lieuDit: string
  codePostalVille: string
  prixInitial: number
  valeur: number
}


export const toPatrimoineUIList = (personneData : EmprunteurGraphQL) : PatrimoineUI[] => {
  if (!personneData) return []
  const personneId = personneData?.id
  return (personneData?.patrimoines || []).map(patrimoine => (toPatrimoineUI(patrimoine, personneId)))
}

export const toPatrimoineUI = ({ __typename, ...patrimoine }: PatrimoineGraphQL, personneId: string) : PatrimoineUI => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let patrimoineUI: PatrimoineUI = {
    ...patrimoine,
    personneId,
  }
  if (patrimoine.bien) {
    const { __typename: _, ...bien } = patrimoine.bien
    patrimoineUI = { ...patrimoineUI, bien }
  }
  return patrimoineUI
}

export const toBienGraphQL = (bien: BienUI = {}) => {
  return ({
    ...bien,
    prixInitial: Number(bien?.prixInitial),
    valeur: Number(bien?.valeur),
  })
}

export const toPatrimoineGraphQL = (patrimoineUI: PatrimoineUI) => {
  const { personneId, ...patrimoine } = patrimoineUI
  return {
    ...patrimoine,
    bien: toBienGraphQL(patrimoine.bien),
  }
}
