import { DocumentNode, OperationVariables, TypedDocumentNode } from '@apollo/client/core'
import { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types'
import { useQuery } from './hooks'
import { navigateOnError } from './navigateOnError'

export function useQueryRedirectError<TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  navigate: (path:string) => void,
  options?: QueryHookOptions<TData, TVariables>,
  path?: string): QueryResult<TData, TVariables> {
  return useQuery<TData, TVariables>(query, { ...options, onError: navigateOnError(navigate, path) })
}
