import React from 'react'

import { TextRow } from '@mmb/ui-components'

import styles from './SituationFamilialePlaceholder.module.css'

export function SituationFamilialePlaceholder() {
  return (
    <>
      <TextRow id="situation-familiale-placeholder-1" width="10rem" height="4.3rem" className={styles.situationFamilialePlaceholder} />
      <TextRow id="situation-familiale-placeholder-2" width="10rem" height="4.3rem" className={styles.situationFamilialePlaceholder} />
      <TextRow id="situation-familiale-placeholder-3" width="10rem" height="4.3rem" className={styles.situationFamilialePlaceholder} />
      <TextRow id="situation-familiale-placeholder-4" width="10rem" height="4.3rem" className={styles.situationFamilialePlaceholder} />
      <TextRow id="situation-familiale-placeholder-5" width="10rem" height="4.3rem" className={styles.situationFamilialePlaceholder} />
      <TextRow id="situation-familiale-placeholder-6" width="10rem" height="4.3rem" className={styles.situationFamilialePlaceholder} />
    </>
  )
}

