import { FieldFunctionOptions, FieldMergeFunction, FieldPolicy } from '@apollo/client'
import { FieldReadFunction } from '@apollo/client/cache/inmemory/policies'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { DocumentNode } from 'graphql'
import { isEqual } from 'lodash/lang'
import { Counters, PagedResult, PageRequest } from '@core/common'
import { GraphqlPaginationRequestArguments, GraphqlPaginationResultHook } from '@core/common-frontend'
import { useQueryWithError } from './hooks'

/**
 * La structure de la liste paginée dans le cache Apollo.
 * C'est la structure d'une page de résultat, et d'un attribut additionnel `firstPageArgs` contenant les arguments de récupération de la première page.
 * La page de résultat en elle-même contient l'intégralité éléments des pages précédentes, et la valeur `pageNumber` indique le nombre de pages récupérées.
 */
export type ListInCache = PagedResult & { firstPageArgs: GraphqlPaginationRequestArguments }

function toFirstPageArgs(args: GraphqlPaginationRequestArguments | null): GraphqlPaginationRequestArguments | null {
  if (!args) {
    return null
  }
  return { ...args, pageRequest: { ...args.pageRequest, pageNumber: 1 } }
}

function isSameList(options: FieldFunctionOptions, existingListInCache?: ListInCache): boolean {
  return existingListInCache // il y a déjà des données dans le cache
    // et il s'agit du cache de la même liste
    && isEqual(existingListInCache.firstPageArgs, toFirstPageArgs((options as FieldFunctionOptions<GraphqlPaginationRequestArguments>).args))
}

/**
 * Renvoie le résultat de la requête graphql depuis les données en cache.
 * @param existing données en cache
 * @param options options de la requête graphql
 * @return une page de résultat contenant l'intégralité éléments des pages précédentes, la valeur `pageNumber` indiquant le nombre de pages récupérées,
 * ou undefined si les données en cache ne correspondent pas à la requête
 */
const paginationRead: FieldReadFunction<ListInCache, PagedResult> = (existing, options) => {
  if (isSameList(options as FieldFunctionOptions<GraphqlPaginationRequestArguments>, existing)) {
    const result = { ...existing } as ListInCache
    // @ts-ignore
    delete result.firstPageArgs
    return result
  }
  return undefined
}

/**
 * Concatène une page de résultat aux données en cache pour cette liste.
 * @param existing données en cache
 * @param incoming les données récupérées du backend graphql
 * @param options options de la requête graphql
 */
const paginationMerge: FieldMergeFunction<ListInCache, PagedResult> = (existing, incoming, options) => {
  let result: ListInCache
  if (isSameList(options as FieldFunctionOptions<GraphqlPaginationRequestArguments>, existing)) {
    // ajout de la page de résultat au cache
    result = { ...existing, elements: existing!.elements?.slice(0) || [] } as ListInCache
    const { pageNumber, pageSize } = options.args!.pageRequest
    const offset = (pageNumber - 1) * pageSize
    for (let i = 0; i < incoming.elements.length; i++) {
      result.elements[offset + i] = incoming.elements[i]
    }
    result.pageNumber = incoming.pageNumber
  } else {
    // création du cache
    result = { ...incoming, firstPageArgs: toFirstPageArgs((options as FieldFunctionOptions<GraphqlPaginationRequestArguments>).args)! }
  }
  return result
}

/**
 * Field policy pour des listes paginées.
 */
export const paginationFieldPolicy: FieldPolicy<ListInCache, PagedResult, PagedResult> = {
  read: paginationRead,
  keyArgs: false,
  merge: paginationMerge,
}

/**
 * Hook React pour exécuter une requête paginée.
 * @param query la requête à exécuter
 * @param queryName nom de la requête
 * @param options options de requête
 */
export function usePaginationQuery<A extends GraphqlPaginationRequestArguments = GraphqlPaginationRequestArguments, T = any, C extends Counters = Counters>(
  query: DocumentNode,
  queryName: string,
  options: QueryHookOptions<any, A>,
): GraphqlPaginationResultHook<T> {
  // vérification des paramètres d'appel
  if (!options.variables) {
    throw new Error('Missing variables')
  }
  const firstPageRequest: PageRequest = options.variables.pageRequest
  if (firstPageRequest.pageNumber !== 1) {
    throw new Error('La première page doit être demandée')
  }
  if (firstPageRequest.pageSize <= 0) {
    throw new Error('La taille des pages demandées doit être strictement positive')
  }
  // ajout de notifyOnNetworkStatusChange afin de provoquer le render React à chaque fois que des éléments sont récupérés
  const queryOptions: QueryHookOptions<any, A> = { ...options }
  queryOptions.notifyOnNetworkStatusChange = true
  // appel graphql pour la première page
  const { loading, data, fetchMore } = useQueryWithError(query, queryOptions)
  const result: PagedResult<T, C> | undefined = data?.[queryName]
  return {
    loading,
    elements: result?.elements,
    counters: result?.counters,
    fetchMore: fetchMore ? () => {
      const pageRequest: PageRequest = {
        ...firstPageRequest,
        pageNumber: result ? result.pageNumber + 1 : 1,
      }
      const variables: any = { ...queryOptions.variables, pageRequest }
      // appel graphql pour la page suivante
      return fetchMore({ ...queryOptions, variables }).then(() => undefined)
    } : undefined,
  }
}
