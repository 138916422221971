import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import { DocPdf, Document, ExcelFile, TextFile, ImageFile, VideoFile } from '@mmb/ui-components'

import styles from './OutilsDocuments.module.css'

interface DocumentIconProps {
  contentType?: string
  className?: string
}

const spreadsheetTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.spreadsheet',
]
const textTypes = ['application/vnd.oasis.opendocument.text', 'application/msword']

export const DocumentIcon: FunctionComponent<DocumentIconProps> = ({ contentType: contentString, className }) => {
  const contentType: string = contentString || ''

  if (contentType === 'application/pdf') {
    return (<DocPdf aria-hidden className={classNames(className, styles.documentIcon__big)} />)
  }

  if (spreadsheetTypes.includes(contentType)) {
    return (<ExcelFile aria-hidden className={className} />)
  }

  if (textTypes.includes(contentType) || (contentType).startsWith('text/')) {
    return (<TextFile aria-hidden className={className} />)
  }

  if ((contentType).startsWith('image/')) {
    return (<ImageFile aria-hidden className={className} />)
  }

  if ((contentType).startsWith('video/')) {
    return (<VideoFile aria-hidden className={className} />)
  }

  return (<Document aria-hidden className={classNames(className, styles.documentIcon__big)} />)
}

DocumentIcon.defaultProps = {
  contentType: 'application/pdf',
  className: '',
}
