import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

import styles from './AssuranceGroupeDetail.module.css'

import stylesDetail from './AssuranceDetail.module.css'

import { AssuranceUI } from '../../../../model/assurance/AssuranceUI'

export const AssuranceGroupeDetail: FunctionComponent<{assuranceUI: AssuranceUI}> = ({ assuranceUI }) => (
  <div data-testid="assurance-groupe-detail">
    <div className={styles.assuranceGroupeDetail}>
      <div aria-label="Garanties couvertures" className={classnames(styles.assuranceGroupeDetail__cell, styles.assuranceGroupeDetail__formule)}>
        <div className={styles.assuranceGroupeDetail__titre}>Garanties couvertures</div>
        <div className={styles.assuranceGroupeDetail__donnee}>{assuranceUI.props.formule}</div>
      </div>
      <div aria-label="Quotité" className={styles.assuranceGroupeDetail__cell}>
        <div className={styles.assuranceGroupeDetail__titre}>Quotité</div>
        <div className={styles.assuranceGroupeDetail__donnee}>{assuranceUI.props.quotite}</div>
      </div>
    </div>
    <div className={stylesDetail.assuranceDetail__table}>
      <div className={stylesDetail.assuranceDetail__blue}>
        <div aria-label="Statut" className={stylesDetail.assuranceDetail__row}>
          <span>Statut</span>
          <span className={stylesDetail.assuranceDetail__content}>{assuranceUI.getLibelleStatut()}</span>
        </div>
      </div>
      {assuranceUI.props.assuranceDetailDCSPropsUI?.libelleDetailStatut && (
        <div className={stylesDetail.assuranceDetail__blue}>
          <div aria-label="Détail Statut" className={stylesDetail.assuranceDetail__row}>
            <span>Détail Statut</span>
            <span className={stylesDetail.assuranceDetail__content}>{assuranceUI.props.assuranceDetailDCSPropsUI?.libelleDetailStatut}</span>
          </div>
        </div>
      )}
      <div aria-label="Date de statut" className={classnames(stylesDetail.assuranceDetail__white, stylesDetail.assuranceDetail__row)}>
        <span>Date de statut</span>
        <span className={stylesDetail.assuranceDetail__content}>{assuranceUI.getDateStatut()}</span>
      </div>
      {assuranceUI.hasReferenceExterne() && (
        <>
          <div aria-label="Numéro de l'adhesion" className={classnames(stylesDetail.assuranceDetail__blue, stylesDetail.assuranceDetail__row)}>
            <span>N° de l'adhésion</span>
            <span className={stylesDetail.assuranceDetail__content}>{assuranceUI.props.assuranceDetailDCSPropsUI?.numeroAdhesion}</span>
          </div>
          <div aria-label="Taux standard" className={classnames(stylesDetail.assuranceDetail__white, stylesDetail.assuranceDetail__row)}>
            <span>Taux standard (annuel)</span>
            <span className={stylesDetail.assuranceDetail__content}>{assuranceUI.props.tauxStandard}</span>
          </div>
        </>
      )}
      <div aria-label="Taux final" className={classnames(stylesDetail.assuranceDetail__blue, stylesDetail.assuranceDetail__row)}>
        <span>Taux final (annuel)</span>
        <span className={stylesDetail.assuranceDetail__content}>{assuranceUI.props.tauxFinal}</span>
      </div>
      <div aria-label="Prime mensuelle" className={classnames(stylesDetail.assuranceDetail__white, stylesDetail.assuranceDetail__row)}>
        <span>Prime mensuelle</span>

        <span className={stylesDetail.assuranceDetail__content}>{assuranceUI.props.cotisation}</span>
      </div>
      {assuranceUI.hasReferenceExterne() && (
        <div className={styles.assuranceGroupeDetail__parentLink}>
          <a
            className={styles.assuranceGroupeDetail__link}
            href={assuranceUI.props.assuranceDetailDCSPropsUI?.webcreditorUrl}
            rel="noopener noreferrer"
            target="_blank"
          >
            Accès Webcréditor
          </a>
        </div>
      )}
    </div>
  </div>
)
