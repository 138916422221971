import React, { useRef } from 'react'

import { Checkbox } from '@mmb/ui-components'

import { useDelayedFormBlur } from '../../../utils'
import { useSaisie } from '../../Contexts/SaisieContext'
import { AdresseForm } from './AdresseForm'
import { AdresseFormPlaceholder } from './AdresseFormPlaceholder'
import { useAdresse } from './Adresse.hooks'

import styles from './Adresse.module.css'
import { SaisieErrorMessages } from '../../SaisieErrorMessages'

export function Adresse() {
  const { saisieUI: { props: { hasCoEmprunteur } } } = useSaisie()
  const {
    loading,
    emprunteurAdresse,
    coEmprunteurAdresse,
    sameAdresseAsEmprunteur,
    emprunteurErrors,
    coEmprunteurErrors,
    errorMessages,
    showErrors,
    handleSubmit,
    changeEmprunteurAdresse,
    changeCoEmprunteurAdresse,
    setSameAdresseAsEmprunteur,
  } = useAdresse()
  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)

  const suffixes = {
    emprunteur: {
      label: 'de l\'emprunteur',
      name: 'emprunteur',
    },
    coEmprunteur: {
      label: 'du co-emprunteur',
      name: 'coEmprunteur',
    },
  }
  return (
    <>
      <div className={styles.adresse__info}>
        <strong className={styles.adresse__bold}>Renseignez les informations de contact</strong> au plus juste afin de garantir une
        <strong className={styles.adresse__bold}> bonne correspondance</strong> avec le client pendant toute la durée de vie du crédit.
      </div>
      <form
        className={styles.adresse}
        data-testid="adresse-form"
        ref={formRef}
        onSubmit={handleSubmit}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <div className={styles.adresse__labels}>
          <p className={styles.adresse__label}>Complement</p>
          <p className={styles.adresse__label}>N°</p>
          <p className={styles.adresse__label}>Voie</p>
          <p className={styles.adresse__label}>Lieu-dit</p>
          <p className={styles.adresse__label}>Code postal ou ville</p>
          <p className={styles.adresse__label}>Statut lieu de résidence</p>
        </div>
        <div>
          <AdresseFormPlaceholder loading={loading}>
            <AdresseForm
              suffixes={suffixes.emprunteur}
              adresse={emprunteurAdresse}
              setAdresse={changeEmprunteurAdresse}
              errors={emprunteurErrors}
              showErrors={showErrors}
            />
          </AdresseFormPlaceholder>
        </div>
        {hasCoEmprunteur && (
        <div>
          <AdresseFormPlaceholder loading={loading} isCoEmprunteur>
            <AdresseForm
              suffixes={suffixes.coEmprunteur}
              adresse={coEmprunteurAdresse}
              setAdresse={changeCoEmprunteurAdresse}
              disabled={sameAdresseAsEmprunteur}
              errors={coEmprunteurErrors}
              showErrors={showErrors}
              isCoEmprunteur
            />
          </AdresseFormPlaceholder>
          <Checkbox
            className={styles.adresse__checkbox}
            label="L'adresse du co-emprunteur est identique"
            name="coEmprunteur.same-adresse"
            checked={sameAdresseAsEmprunteur}
            onChange={() => setSameAdresseAsEmprunteur(!sameAdresseAsEmprunteur)}
          />
        </div>
        )}
      </form>
      {showErrors && (<SaisieErrorMessages
        data-testid="adresse__errors"
        errors={errorMessages}
      />)}
    </>
  )
}
