import React from 'react'
import { string, node, bool, elementType } from 'prop-types'
import { useHasPermission } from './hasPermission.hook'

Can.propTypes = {
  scope: string.isRequired,
  allCabinets: bool,
  component: elementType,
  not: bool,
  children: node,
}

Can.defaultProps = {
  allCabinets: false,
  component: undefined,
  not: false,
  children: undefined,
}

export function Can({ scope, allCabinets, not, component: Component, children, ...props }) {
  const hasPermission = useHasPermission(scope, allCabinets)
  if ((hasPermission && !not) || (!hasPermission && not)) {
    return Component ? <Component {...props} /> : children
  }
  return null
}
