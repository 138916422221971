import React from 'react'
import { node, string } from 'prop-types'
import cn from 'classnames'

import styles from './Title.module.css'

Title.propTypes = {
  children: node.isRequired,
  className: string,
}

Title.defaultProps = {
  className: '',
}

export function Title({ children, className }) {
  return (
    <span className={cn(styles.title, className)}>
      {children}
    </span>
  )
}
