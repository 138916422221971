import { useEffect, useState } from 'react'
import { sendJson } from '@core/common-frontend'

const DEFAULT_GENERATE_STATE = { azureUrl: undefined, error: undefined }

/**
 * Hook permettant de générer une url vers un flux Azure AD
 * @param flowType
 * @param callbackPath
 * @param previousUrl
 * @param skip
 * @returns {{azureUrl: string, error: Error}}
 */
export function useGenerateUrlToAzureAd({ flowType, callbackPath, previousUrl, skip = false }) {
  const [state, setState] = useState(DEFAULT_GENERATE_STATE)

  useEffect(() => {
    let cancelled = false
    if (!skip) {
      setState(DEFAULT_GENERATE_STATE)

      generateAzureAdLoginUrl(callbackPath, previousUrl, flowType)
        .then(azureUrl => {
          if (!cancelled) { setState({ azureUrl, error: undefined }) }
        })
        .catch(error => {
          if (!cancelled) { setState({ azureUrl: undefined, error }) }
        })
    }

    return () => { cancelled = true }
  }, [skip, callbackPath, previousUrl, flowType])

  return state
}

async function generateAzureAdLoginUrl(callbackPath, afterLogginUrl, flowType) {
  const callbackUrl = new URL(callbackPath, `${window.GATSBY_SITE_URL}`)
  const response = await sendJson('/azuread/login', { callbackUrl, afterLogginUrl, flowType })
  if (response.ok) {
    const { url } = await response.json()
    return url
  }
  throw new Error(`Impossible de construire l'url vers le flux '${flowType}'`)
}
