import React from 'react'

import { Card } from '@mmb/ui-components'
import { ProtectionDonnees } from '@core/ui'
import { smartProfileTrackWithParams } from '@core/analytics'
import { getConsent } from '@core/partenaire-ui'
import { Identite } from '../Identite'

import styles from './Profil.module.css'

export function Profil() {
  smartProfileTrackWithParams('&_title=Profil', getConsent())

  return (
    <div className={styles.profil}>
      <div aria-label="Mon profil">
        <h2 className={styles.profil__titre}>Mon profil</h2>

        <Identite />
        <Card>
          <ProtectionDonnees className={styles.profil__protectionDonnees} />
        </Card>
      </div>
    </div>
  )
}
