/* eslint-disable react/jsx-filename-extension */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { object, node } from 'prop-types'
import { ADMINISTRATEUR, COLLABORATEUR, SUPER_ADMIN } from '@core/common'
import { fetchWithRefresh } from '@core/common-frontend'
import { gql, useQueryWithError } from '@core/graphql'
import { CabinetTravail, UtilisateurContext } from './UtilisateurContext'

interface GenericHook<T> {
  loading: boolean
  result?: T
}

interface RefetchableHook<T> extends GenericHook<T> {
  refetch: () => Promise<any>
}

interface SkippableHook<T> extends GenericHook<T> {
  setSkip: (skip: boolean) => void
}

/**
 * Récupère l'email de la personne actuellement connectée.
 * Cette fonction peut être utilisée pour vérifier la validité de la session utilisateur.
 */
const fetchHasActiveSession: () => Promise<boolean> =
  () => fetchWithRefresh('/partenaire/session')
    .then(res => res.text())
    .then(text => text === 'OK')
    .catch(() => Promise.resolve(false))

function useHasActiveSession(): RefetchableHook<boolean> {
  const [hook, setHook] = useState({ loading: true } as GenericHook<boolean>)
  useEffect(() => {
    if (hook.loading) {
      fetchHasActiveSession().then(result => setHook({ loading: false, result }))
    }
  }, [hook.loading])
  return { ...hook, refetch: async () => setHook({ loading: true }) }
}

interface CurrentPersonne {
  email: string
  nom: string
  prenom: string
  cabinets: CabinetTravail[]
}

const PARTENAIRE_CURRENT_PERSONNE_QUERY = gql`
query {
  partenaire_currentPersonne {
    email
    nom
    prenom
    cabinets {
      id
      role
      permissionScopes
      libelle
    }
  }
}`

/**
 * @param initialSkip : la valeur initiale d'un state react mis à jour avec setSkip et utilisée pour exécuter ou non la requête graphQL
 */
function useGetCurrentUtilisateur(graphqlClient: any, initialSkip: boolean): SkippableHook<CurrentPersonne> {
  const [skip, setSkip] = useState(initialSkip)
  const { loading: loadingGetCurrentUtilisateur, data } =
    useQueryWithError(PARTENAIRE_CURRENT_PERSONNE_QUERY, { fetchPolicy: 'network-only', client: graphqlClient, skip })
  const loading = loadingGetCurrentUtilisateur || (!loadingGetCurrentUtilisateur && !data?.partenaire_currentPersonne && !initialSkip)
  return { loading, result: data?.partenaire_currentPersonne, setSkip }
}

function getMostPermissiveRole(cabinets: CabinetTravail[]): string {
  const roles = cabinets.map(({ role }) => role)
  if (roles.includes(SUPER_ADMIN)) {
    return SUPER_ADMIN
  }
  if (roles.includes(ADMINISTRATEUR)) {
    return ADMINISTRATEUR
  }
  return COLLABORATEUR
}

function useGetUtilisateurContext(graphqlClient: any): UtilisateurContext {
  // state React pour gérer le cabinet de travail
  const [idCabinetTravail, setIdCabinetTravailState] = useState<string | undefined>()
  // récupération de l'état de la session utilisateur
  const { loading: loadingHasActiveSession, result: hasActiveSession, refetch: refresh } = useHasActiveSession()
  const shouldSkipGetCurrentUtilisateur = !hasActiveSession
  // récupération de l'utilisateur courant
  const { loading: loadingGetCurrentUtilisateur, result: currentUtilisateur, setSkip: setSkipGetCurrentUtilisateur } =
    useGetCurrentUtilisateur(graphqlClient, shouldSkipGetCurrentUtilisateur)
  // chargement de l'utilisateur courant en fonction de l'état de la session utilisateur
  const loading = loadingHasActiveSession || loadingGetCurrentUtilisateur

  useEffect(() => setSkipGetCurrentUtilisateur(shouldSkipGetCurrentUtilisateur), [loadingHasActiveSession])
  // choix automatique du cabinet de travail si il n'y a qu'une seule possibilité, ou déselection du cabinet si plus d'utilisateur courant
  useEffect(() => {
    if (!idCabinetTravail && currentUtilisateur && currentUtilisateur.cabinets.length === 1) {
      setIdCabinetTravailState(currentUtilisateur.cabinets[0].id)
    }
    if (idCabinetTravail && !currentUtilisateur) {
      setIdCabinetTravailState(undefined)
    }
  }, [idCabinetTravail, currentUtilisateur])
  if (loading) {
    return { loading: true, refresh, setIdCabinetTravail: () => undefined }
  }
  if (!hasActiveSession || !currentUtilisateur) {
    return { loading: false, loggedIn: false, error: !currentUtilisateur, refresh, setIdCabinetTravail: () => undefined }
  }
  const setIdCabinetTravail = (newIdCabinet: string) => {
    if (!currentUtilisateur.cabinets!.map(({ id }) => id).includes(newIdCabinet)) {
      throw new Error('Identifiant de cabinet incorrect')
    }
    setIdCabinetTravailState(newIdCabinet)
  }
  return {
    loading: false,
    loggedIn: true,
    refresh,
    idCabinetTravail,
    setIdCabinetTravail,
    role: getMostPermissiveRole(currentUtilisateur.cabinets!),
    ...currentUtilisateur,
  }
}

const utilisateurContext = createContext<UtilisateurContext>({ loading: true, refresh: () => Promise.resolve(), setIdCabinetTravail: () => undefined })

export function useUtilisateurContext(): UtilisateurContext {
  return useContext(utilisateurContext)
}

UtilisateurContextProvider.propTypes = {
  graphqlClient: object.isRequired,
  children: node.isRequired,
}

export function UtilisateurContextProvider({ graphqlClient, children }) {
  const context = useGetUtilisateurContext(graphqlClient)
  return (
    <utilisateurContext.Provider value={context}>
      {children}
    </utilisateurContext.Provider>
  )
}

MockUtilisateurContextProvider.propTypes = {
  value: object,
  children: node.isRequired,
}

MockUtilisateurContextProvider.defaultProps = {
  value: { loading: false, loggedIn: false, refresh: () => undefined },
}

export function MockUtilisateurContextProvider({ value, children }) {
  return (
    <utilisateurContext.Provider value={value}>
      {children}
    </utilisateurContext.Provider>
  )
}
