/* eslint-disable react/no-array-index-key */
import React from 'react'
import { node, number } from 'prop-types'

Patrimoine.propTypes = {
  children: node,
  numero: number.isRequired,
}

Patrimoine.defaultProps = {
  children: null,
}

export function Patrimoine({ numero, children }) {
  return (
    <div aria-label={`Garantie du patrimoine n° ${numero}`}>
      {children}
    </div>
  )
}
