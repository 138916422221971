import { gql } from '@core/graphql'

const GET_DOCUMENTS = gql`
query getLiasseDocuments($id: partenaire_UUID!) {
  partenaire_getLiasseDocuments(id: $id) {
    id
    filename
    contentType
    dateCreation
    nature
    categorie
  }
}`

const GET_LIASSE = gql`
query getLiasseInfo($id: partenaire_UUID!) {
  partenaire_getLiasseInfo(id: $id) {
    commentaire
    dateCreation
  }
}`

const GET_PIECES_VALIDE = gql`
query getPieces($id: partenaire_UUID!) {
  partenaire_getPieces(id: $id) {
    categorie
    dateValidation
    libelle
    finalite
    statut
  }
}
`

const SET_COMMENTAIRE = gql`
mutation setCommentaire($id: partenaire_UUID!, $commentaire: String) {
  partenaire_setCommentaire(id: $id, commentaire: $commentaire)
}`

const GET_DOSSIER_ID = gql`
query getDossierIdStatut($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    id
    statut
  }
}
`

const GET_TRANSFERTS = gql`
query getTransferts($id: partenaire_UUID!) {
  partenaire_getTransferts(id: $id) {
    id
    documents {
      id
      filename
      contentType
      dateCreation
      nature
      categorie
    }
    date
    commentaire
    statut
  }
}
`
const TRANSFERT_LIASSE = gql`
  mutation transfert($id: partenaire_UUID!) {
    partenaire_transfert(id: $id)
  }
`

const DELETE_DOCUMENT = gql`
mutation partenaire_removeDocument($id: partenaire_UUID!, $documentId: partenaire_UUID!) {
  partenaire_removeDocument(id: $id, documentId: $documentId)
}`


export const LiasseQuery = {
  GET_DOCUMENTS,
  GET_LIASSE,
  GET_PIECES_VALIDE,
  GET_TRANSFERTS,
  DELETE_DOCUMENT,
  GET_DOSSIER_ID,
  TRANSFERT_LIASSE,
  SET_COMMENTAIRE,
}
