import React from 'react'
import { func, shape, string } from 'prop-types'

import styles from './FilePreview.module.css'

export const fileType = shape({
  file: shape({
    name: string.isRequired,
  }).isRequired,
  url: string.isRequired,
})

FilePreview.propTypes = {
  onFileRemove: func.isRequired,
  file: fileType.isRequired,
}

export function FilePreview({ onFileRemove, file }) {
  return (
    <li className={styles.thumbnail}>
      <button
        className={styles.thumbnail__button}
        onClick={e => {
          e.preventDefault()
          onFileRemove(file)
        }}
        aria-label="Suppression du fichier"
      >
        <figure className={styles.thumbnail__fig}>
          <img
            className={styles.thumbnail__img}
            src={file.url}
            alt={file.file.name}
          />
          <figcaption className={styles.thumbnail__caption}>{file.file.name}</figcaption>
        </figure>
      </button>
    </li>
  )
}
