/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react'

import { Tooltip } from '@core/partenaire-ui'
import { IdentiteForm } from './IdentiteForm'

import { useSaisie } from '../../Contexts/'
import { useIdentite } from './Identite.hooks'
import { useDelayedFormBlur } from '../../../utils'


import styles from './Identite.module.css'
import { useValidation } from '../../FormState/form.state.hooks'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { SaisieErrorMessages } from '../../SaisieErrorMessages'

export function Identite() {
  const { saisieUI: { props: { hasCoEmprunteur, gamme } } } = useSaisie()
  const {
    loading,
    emprunteur,
    coEmprunteur,
    updateEmprunteur,
    handleSubmit,
  } = useIdentite()
  const { errors, showErrors } = useValidation(EntryFormStateName.IDENTITE, loading, { emprunteur, coEmprunteur, hasCoEmprunteur, gamme })
  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)

  const suffixes = {
    emprunteur: {
      label: 'de l\'emprunteur',
      name: 'emprunteur',
    },
    coEmprunteur: {
      label: 'du co-emprunteur',
      name: 'coEmprunteur',
    },
  }
  return (
    <>
      <form
        data-testid="identite-form"
        ref={formRef}
        onSubmit={handleSubmit}
        className={styles.identite}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <div className={styles.identite__labels}>
          <div className={styles.identite__label}>Civilité</div>
          <div className={styles.identite__label}>
            <p>Nom</p>
            <Tooltip
              wrapperClassName={styles.identite__tooltip}
              title="Information sur le nom"
              content={(<p>Nom d’usage de la personne utilisé dans la vie courante potentiellement distinct de son nom de naissance.</p>)}
            />
          </div>
          <div className={styles.identite__label}>Nom de naissance</div>
          <div className={styles.identite__label}>Prénom</div>
          <div className={styles.identite__label}>Date de naissance</div>
          <div className={styles.identite__label}>Ville de naissance</div>
          <div className={styles.identite__label}>Département de naissance</div>
          <div className={styles.identite__label}>Nationalité</div>
        </div>

        <IdentiteForm
          suffixes={suffixes.emprunteur}
          loading={loading}
          identite={emprunteur}
          setIdentite={newEmprunteur => { updateEmprunteur(newEmprunteur, true) }}
          errors={errors.emprunteurErrors}
          showErrors={showErrors}
        />
        {hasCoEmprunteur && (
        <IdentiteForm
          suffixes={suffixes.coEmprunteur}
          loading={loading}
          identite={coEmprunteur}
          setIdentite={newCoEmprunteur => updateEmprunteur(newCoEmprunteur, false)}
          errors={errors.coEmprunteurErrors}
          showErrors={showErrors}
        />
        )}
      </form>
      {showErrors && (<SaisieErrorMessages
        data-testid="identite__errors"
        errors={errors.globalErrors}
      />)}
    </>
  )
}
