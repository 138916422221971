export enum UsageBien {
    PRINCIPAL = 'PRINCIPAL',
    SECONDAIRE = 'SECONDAIRE',
    LOCATIF = 'LOCATIF',
    MIXTE = 'MIXTE',
    COMMERCIAL = 'COMMERCIAL',
}

export const USAGE_BIEN_OPTIONS = [{
  label: 'Principal',
  value: UsageBien.PRINCIPAL,
}, {
  label: 'Secondaire',
  value: UsageBien.SECONDAIRE,
}, {
  label: 'Locatif',
  value: UsageBien.LOCATIF,
}, {
  label: 'Mixte',
  value: UsageBien.MIXTE,
}]
