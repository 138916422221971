import { Component } from 'react'
import { node, func } from 'prop-types'

/**
 * Gestionnaire d'erreur qui va afficher un composant de 'fallback' en cas d'erreur
 * Ce gestionnaire est prévu pour être positionné sur une page ou un composant spécifique afin de proposer un affichage "en mode sans-échec"
*/
export class FallbackErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.props.onComponentDidCatch(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }
}

FallbackErrorBoundary.propTypes = {
  children: node.isRequired,
  fallback: node,
  onComponentDidCatch: func,
}

FallbackErrorBoundary.defaultProps = {
  onComponentDidCatch: () => {},
  fallback: null,
}
