import { gql } from '@core/graphql'

export const GET_DETTES_DOSSIER = gql`
query getDettesDossier($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    emprunteurs {
      id
      dettes(dossierId: $id) {
        id
        repris
        position
        nature
        beneficiaire
        debut
        fin
        montantCrd
        montantEcheance
        montantCapitalInitial
      }
    }
  }
}
`

export const UPDATE_DETTE_DOSSIER = gql`
mutation updateDetteDossier($id: ID!, $personneId: ID!, $dette: partenaire_DetteInput!) {
  partenaire_updateDette(id: $id, personneId: $personneId, dette: $dette) {
    id
    repris
    position
    nature
    beneficiaire
    debut
    fin
    montantCrd
    montantEcheance
    montantCapitalInitial
  }
}
`

export const DELETE_DETTE_DOSSIER = gql`
mutation deleteDetteDossier($id: ID!, $personneId: ID!, $detteId: ID!) {
  partenaire_deleteDette(id: $id, personneId: $personneId, detteId: $detteId) {
    id
  }
}
`

export const CREATE_DETTE_DOSSIER = gql`
mutation createDetteDossier($id: ID!, $personneId: ID!, $dette: partenaire_DetteInput!) {
  partenaire_createDette(id: $id, personneId: $personneId, dette: $dette) {
    id
    repris
    position
    nature
    beneficiaire
    debut
    fin
    montantCrd
    montantEcheance
    montantCapitalInitial
  }
}
`
