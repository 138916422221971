import { gql, useMutation } from '@core/graphql'
import { useEffect, useState } from 'react'
import { DetteFormUI, EncoursUI, EncoursFormUI, PretFormUI } from '../../model'
import { useSaisie } from '../Contexts'
import { EntryFormStateName } from '../FormState/EntryFormState'
import { useHandleSubmit, useValidation } from '../FormState/form.state.hooks'
import { useDettes } from './Dette'
import { usePrets } from './Pret'
import { PRET_IMMOBILIER } from '../../model/encours/const'
import { ErrorMessage } from '../SaisieErrorMessages'
import { EncoursErrorMessage } from './Errors'
import { useGamme } from '../Gamme'
import { EncoursValidationErrors, EncoursWarnings } from '../../model/encours/validateEncours'

const UPDATE_DEMANDE_AJOUT_CREANCES_DOSSIER = gql`
mutation updateCreancesAReprendreDossier($id: ID!, $creances: partenaire_CreancesInput!) {
  partenaire_updateCreancesAReprendre(id: $id, creances: $creances) {
    id
  }
}
`


export function useEncoursErrors({ loading, encoursUI }) : {
  encoursValidationErrors: EncoursValidationErrors
  encoursWarnings?: EncoursWarnings
  errorMessages: ErrorMessage[]
  creanceErrorMessages: ErrorMessage[][]
  showErrors: boolean
} {
  const useGammeProps = useGamme()
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([])
  const [creanceErrorMessages, setCreanceErrorMessages] = useState<ErrorMessage[][]>([])

  const { errors: encoursValidationErrors, warnings: encoursWarnings, showErrors } : { errors, warnings: EncoursWarnings, showErrors: boolean }
    = useValidation(EntryFormStateName.EN_COURS, loading, { encoursUI, gamme: useGammeProps.gamme })

  useEffect(() => {
    setErrorMessages((encoursValidationErrors?.globalErrors || []).map(error => EncoursErrorMessage.getMessage(error, useGammeProps)))
    setCreanceErrorMessages(
      (encoursValidationErrors?.encoursErrors || [])
        .map(encoursErrors => (encoursErrors || [])
          .map(error => EncoursErrorMessage.getMessage(error, useGammeProps)),
        ),
    )
  }, [encoursValidationErrors])

  return {
    showErrors,
    encoursValidationErrors,
    encoursWarnings,
    errorMessages,
    creanceErrorMessages,
  }
}

export function useEnCours() {
  const { saisieUI: { props: { dossierId, gamme } } } = useSaisie()
  const [updateDemandeWithCreances, { loading: updateDemandesLoading }] = useMutation(UPDATE_DEMANDE_AJOUT_CREANCES_DOSSIER, { refetchQueries: [] })
  const { prets, loading: loadingPrets, handleUpdatePret, handleRemovePret } = usePrets()
  const { dettes, loading: loadingDettes, handleUpdateDette, handleRemoveDette } = useDettes()
  const [encoursUI, setEncoursUI] = useState<EncoursUI>(new EncoursUI([], true))
  const [loadingEncoursUI, setLoadingEncoursUI] = useState(true)

  useEffect(() => { encoursUI.withUpdateState(setEncoursUI) }, [encoursUI])
  useEffect(() => encoursUI.addAll(prets), [prets])
  useEffect(() => encoursUI.addAll(dettes), [dettes])
  useEffect(() => {
    if (!loadingPrets && !loadingDettes) {
      encoursUI.refreshState(new EncoursUI(encoursUI.list))
      setLoadingEncoursUI(false)
    }
  }, [loadingPrets, loadingDettes])


  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.EN_COURS)

  const repriseCreances = async () => {
    const creances = encoursUI.getCreancesAReprendre()
    await updateDemandeWithCreances({ variables: { id: dossierId, creances } })
  }

  const handleEncoursFormUI = (encoursFormUI: EncoursFormUI): Promise<EncoursFormUI> => EncoursUI.isPret(encoursFormUI) ?
    handleUpdatePret(encoursFormUI as PretFormUI) : handleUpdateDette(encoursFormUI as DetteFormUI)

  const updateEncours = async () => {
    const updatedEncoursFormUI: EncoursFormUI[] = await Promise.all(encoursUI.list.map(handleEncoursFormUI))
    updatedEncoursFormUI.forEach((encoursFormUI, index) => encoursUI.update(index, encoursFormUI))
    await repriseCreances()
    encoursUI.refreshState()
  }

  const handleSubmit = async event => doHandleSubmit(updateEncours, event)

  const doRemoveEncours = async (encoursFormUI: EncoursFormUI, index: number) => {
    const creances = encoursUI.getCreancesAReprendre()
    const creancesWithoutEncours = {
      prets: creances.prets.filter(({ creanceId }) => creanceId !== encoursFormUI.id),
      dettes: creances.dettes.filter(({ creanceId }) => creanceId !== encoursFormUI.id),
    }
    await updateDemandeWithCreances({ variables: { id: dossierId, creances: creancesWithoutEncours } })
    encoursUI.delete(index)
    return EncoursUI.isPret(encoursFormUI) ? handleRemovePret(encoursFormUI as PretFormUI) : handleRemoveDette(encoursFormUI as DetteFormUI)
  }

  const removeEncours = (encoursFormUI: EncoursFormUI, index: number) => doHandleSubmit(async () => doRemoveEncours(encoursFormUI, index), undefined, true)

  const canBeInApprocheInvestisseur = (pret: PretFormUI) : boolean => !(
    (pret.nature && !PRET_IMMOBILIER.includes(pret.nature)) || pret.repris || (gamme === 'SECURED' && !encoursUI.hasPretsRepris())
  )

  return {
    loading: updateDemandesLoading || loadingEncoursUI,
    queryLoading: loadingEncoursUI,
    encoursUI,
    handleSubmit,
    removeEncours,
    canBeInApprocheInvestisseur,
  }
}
