import { ROLES, useUtilisateurContext } from '@core/partenaire-common'
import { capitalize } from '@core/common'

export function useIdentite() {
  const { loading, nom, prenom, email, idCabinetTravail, cabinets } = useUtilisateurContext()
  const roleLabel = idCabinetTravail && cabinets
    .filter(c => c.id === idCabinetTravail)
    .map(c => c.role)
    .map(role => ROLES.find(r => r.value === role))
    .map(role => role.label)
    .find(p => p)
  return {
    loading,
    nomComplet: nom && prenom ? `${capitalize(prenom)} ${nom.toUpperCase()}` : '',
    identifiant: email,
    role: roleLabel,
  }
}
