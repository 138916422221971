import { DocumentCategorie } from './CategorieUI.model'

export interface DocumentPropsUI {
  id: string
  filename: string
  contentType: string
  dateCreation: string
  nature?: string
  categorie: DocumentCategorie
}

export class DocumentUI {
  constructor(public readonly props: DocumentPropsUI) {
  }
}
