import React from 'react'
import { node, oneOfType, string } from 'prop-types'
import cn from 'classnames'

import { Separator } from '@core/partenaire-ui'

import { DetailPanel } from '../DetailPanel'

import styles from './DetailLayout.module.css'

DetailLayout.propTypes = {
  children: node,
  id: string.isRequired,
  title: string,
  right: oneOfType([string, node]),
  className: string,
}

DetailLayout.defaultProps = {
  children: null,
  title: '',
  right: '',
  className: '',
}

export function DetailLayout({ children, id, title, right, className }) {
  return (
    <div className={styles.detailLayout}>
      <DetailPanel id={id} />

      <div className={cn(styles.detailLayout__body, className)}>
        <div className={styles.detailLayout__bandeau}>
          <div className={styles.detailLayout__bandeauIsLeft}>
            <h2 className={styles.detailLayout__title} id="dossiers_table_description">{title}</h2>
          </div>
          <div className={styles.detailLayout__bandeauIsRight}>
            {right}
          </div>
        </div>

        <Separator className={styles.detailLayout__separator} />

        {children}

      </div>
    </div>
  )
}
