import { addMonths, format } from 'date-fns'
import { DATE_MM_YYYY } from '../format'
import { TypeRevenu } from './RevenuFormUI'

export interface BaisseRevenuUI {
  id?: string
  debut?: string
  montant?: number
  type?: TypeRevenu
}

const getDebut = (isSecured: boolean): string => format(addMonths(new Date(), isSecured ? 15 : 13), DATE_MM_YYYY)

export const buildDefaultRevenu = (isSecured: boolean): BaisseRevenuUI => ({
  debut: getDebut(isSecured),
  montant: 0,
  type: TypeRevenu.RETRAITE,
})
