import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { Flow, AzureADFlowType } from '@core/partenaire-frontend'
import { NavigationPath } from '@core/partenaire-common'
import { Profil } from './components/Profil'

function ModifierMotDePasse() {
  return (
    <Flow flowType={AzureADFlowType.RESET_PASSWORD} defaultCompletionUrl={NavigationPath.MON_PROFIL} />
  )
}

export function ProfilRoute() {
  return (
    <Routes>
      <Route path="/" element={<Profil />} />
      <Route path="modifier-mon-mot-de-passe" element={<ModifierMotDePasse />} />
    </Routes>
  )
}
