import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import { ToggleButton } from '@mmb/ui-components'
import { Select } from '@core/partenaire-ui'
import { InputMontant } from '@partenaire/frontend-ui'

import { hasErrorForName as hasError, limit, parseMontant } from '../../../utils'
import { AssurancePlaceholder } from '../AssurancePlaceholder'

import styles from './AssuranceForm.module.css'
import { AssuranceFormUI, AssuranceType, LabelUI, ValidationFieldError, ASSURANCE_FORMULES_OPTIONS, ASSURANCE_OPTIONS } from '../../../model'
import { AssuranceFormPropsUI } from '../../../model/assurance/AssuranceFormUI'

interface AssuranceFormProps {
  assuranceFormUI: AssuranceFormUI
  suffixes: LabelUI
  errors: ValidationFieldError[]
  showErrors: boolean
  loading: boolean
  updateAssuranceFormUI: (assuranceFormProps: AssuranceFormPropsUI) => void
}

export const AssuranceForm: FunctionComponent<AssuranceFormProps> = ({ suffixes, assuranceFormUI, updateAssuranceFormUI, errors, showErrors, loading }) => {
  const hasErrorForName = name => hasError(name, errors, showErrors)
  if (loading) {
    return <AssurancePlaceholder />
  }
  return (
    <div className={styles.assuranceForm}>
      <div className={styles.assuranceForm__options}>
        {ASSURANCE_OPTIONS.map(({ label, value }) => {
          return (
            <ToggleButton
              value={value}
              label={label}
              name={`${suffixes.name}.assurance.type.${value}`}
              key={`${suffixes.name}.assurance.type.${value}`}
              className={classNames(styles.assuranceForm__option, {
                [styles.assuranceForm__optionChecked]: assuranceFormUI.hasType(value),
              })}
              labelClassName={styles.assuranceForm__label}
              onChange={() => updateAssuranceFormUI({ type: value as AssuranceType })}
              checked={assuranceFormUI.hasType(value)}
              aria-label={`Choix d'assurance ${suffixes.label} - ${label}`}
              aria-invalid={hasErrorForName('type')}
              hasError={hasErrorForName('type')}
            />
          )
        })}
      </div>
      {assuranceFormUI.hasAssurance() && (
        <>
          <Select
            options={ASSURANCE_FORMULES_OPTIONS}
            name={`${suffixes.name}.formule`}
            label={`Garanties couvertures ${suffixes.label}`}
            onChange={({ value }) => updateAssuranceFormUI({ formule: value })}
            hideLabel
            value={assuranceFormUI.getSelectedFormule()}
            hasError={hasErrorForName('formule')}
          />
          <div className={styles.assuranceForm__quotite}>
            <InputMontant
              name={`${suffixes.name}.quotite`}
              unit="%"
              min="0"
              max="100"
              label={`Quotité d'assurance ${suffixes.label}`}
              className={styles.assuranceForm__quotiteInput}
              hideLabel
              onChange={({ target }) => updateAssuranceFormUI({ quotite: limit(parseMontant(target.value), 100) })}
              value={assuranceFormUI.getQuotite()}
              hasError={hasErrorForName('quotite')}
            />
          </div>
        </>
      )}
    </div>
  )
}
