import { EntryFormStateName as EntryName } from '../FormState/EntryFormState'
import { FormState } from '../FormState/FormState'
import { FiligraneStepUI, FiligraneStepName } from './FiligraneStepUI'

const ALL_STEPS = [
  new FiligraneStepUI(FiligraneStepName.SITUATION_PERSONNELLE,
    [EntryName.IDENTITE, EntryName.SITUATION_FAMILIALE, EntryName.ADRESSE, EntryName.COMMUNICATIONS]),
  new FiligraneStepUI(FiligraneStepName.SITUATION_PROFESSIONNELLE, [EntryName.ACTIVITE]),
  new FiligraneStepUI(FiligraneStepName.SITUATION_FINANCIERE, [EntryName.REVENU, EntryName.CHARGE]),
  new FiligraneStepUI(FiligraneStepName.COMPTES, [EntryName.SITUATION_BANCAIRE]),
  new FiligraneStepUI(FiligraneStepName.ENCOURS, [EntryName.EN_COURS]),
  new FiligraneStepUI(FiligraneStepName.PATRIMOINE, [EntryName.PATRIMOINE]),
  new FiligraneStepUI(FiligraneStepName.ASSURANCE, [EntryName.ASSURANCE]),
  new FiligraneStepUI(FiligraneStepName.MONTAGE, [EntryName.MONTAGE]),
] as FiligraneStepUI[]


export class FiligraneUI {
  public readonly steps: FiligraneStepUI[]

  constructor(private formState: FormState, private gamme: string = 'UNSECURED', public apporteur?: string | null) {
    this.steps = this.compute()
  }

  private compute(): FiligraneStepUI[] {
    const UNSECURED_CARDS = ALL_STEPS.filter(step => step.name !== FiligraneStepName.PATRIMOINE)
    const SECURED_CARDS = ALL_STEPS.filter(step => step.name !== FiligraneStepName.ASSURANCE)
    const stepsByGamme = this.gamme === 'UNSECURED' ? UNSECURED_CARDS : SECURED_CARDS
    const entryFormStateCount = stepsByGamme.map(p => p.entriesFormState).flatMap(p => p).length
    return stepsByGamme.map(step => step.computeErrors(this.formState, entryFormStateCount))
  }
}
