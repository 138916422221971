import React from 'react'
import { useNavigate } from '@core/routing'

import { Button, Arrow } from '@mmb/ui-components'
import { Identite as CoreIdentite } from '@core/ui'

import { useIdentite } from './Identite.hooks'

import styles from './Identite.module.css'

export function Identite() {
  const navigate = useNavigate()
  const { loading, nomComplet, identifiant, role } = useIdentite()

  if (loading) return null

  return (
    <CoreIdentite
      nomComplet={nomComplet}
      identifiant={identifiant}
      role={role}
    >
      <Button
        className={styles.identite__button}
        rightIcon={Arrow}
        onClick={() => navigate('/app/mon-profil/modifier-mon-mot-de-passe')}
      >
        Modifier mon mot de passe
      </Button>
    </CoreIdentite>
  )
}
