import React, { FunctionComponent } from 'react'

import { ErrorMessage } from '@partenaire/frontend-ui'

import styles from './AssuranceIneligibility.module.css'

export const AssuranceIneligibility: FunctionComponent = () => (
  <div className={styles.assurance__ineligibility}>
    <ErrorMessage>
      L'emprunteur n'est pas éligible à l'Assurance groupe.
    </ErrorMessage>
    <div className={styles.assurance__ineligibilityExplanation}>
      <p>
        Au regard des âges limites de couverture des garanties,
        de l'âge de l'emprunteur et de la durée initiale du prêt demandé, aucune de nos options ne correspond.
      </p>
      <p>L'emprunteur a cependant le choix entre : </p>
      <ul className={styles.assurance__list}>
        <li className={styles.assurance__dash}>
          une <strong>souscription auprès de l'assureur de son choix. </strong>
          Le contrat d'assurance vous sera demandé.
        </li>
        <li className={styles.assurance__dash}>
          <strong>sans assurance.</strong> Une lettre de renonciation doit être rédigée par écrit, datée et signée par l'emprunteur.
        </li>
      </ul>
      <p>
        Les documents cités seront à déposer dans l'encart "Assurance" sur la page "Envoi des pièces".
      </p>
    </div>
  </div>
)
