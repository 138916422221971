import { func, object } from 'prop-types'
import React from 'react'
import { TickEtapes } from '@mmb/ui-components'
import cn from 'classnames'
import { useUtilisateurContext } from '@core/partenaire-common'
import styles from './CabinetHeader.module.css'


CabinetHeader.propTypes = {
  cabinet: object.isRequired,
  onClick: func.isRequired,
}

/**
 * Composant représantant une ligne de sélection des cabinets
 */
export function CabinetHeader({ cabinet, onClick }) {
  const { setIdCabinetTravail, idCabinetTravail } = useUtilisateurContext()
  const isSelected = idCabinetTravail === cabinet.id

  const handleClick = () => {
    setIdCabinetTravail(cabinet.id)
    onClick()
  }

  return (
    <button className={cn(styles.pm, { [styles.pm__selected]: isSelected })} onClick={handleClick}>
      <div className={styles.pm__checkmark}>
        {isSelected && <TickEtapes inline />}
      </div>
      <div className={styles.pm__raison_sociale}>
        {cabinet.libelle}
      </div>
    </button>
  )
}
