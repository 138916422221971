import React from 'react'
import cn from 'classnames'
import { string, node } from 'prop-types'

import { Bullet } from '@mmb/ui-components'

import styles from './FormHeader.module.css'

FormHeader.propTypes = {
  className: string,
  children: node,
  info: string,
}

FormHeader.defaultProps = {
  className: '',
  children: null,
  info: '',
}

export function FormHeader({ children, className, info }) {
  return (
    <header className={className}>
      <h3 className={cn(styles.formHeader__title, { [styles.formHeader__hasInfo]: info })}>
        <Bullet className={styles.formHeader__bullet} />
        {children}
      </h3>
      {info && <p className={styles.formHeader__info}>{info}</p>}
    </header>
  )
}
