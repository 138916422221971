import { uniqBy } from 'lodash'
import { ValidationFieldError, ValidationErrors, buildValidationError } from '../../model'
import { ErrorMessage } from '../../components/SaisieErrorMessages'

const titles = {
  mobile: 'Coordonnées téléphoniques',
  domicile: 'Coordonnées téléphoniques',
  email: 'Email',
}

function getMessageErrorByField(field, message) {
  if (message === 'WRONG_VALUE' && (field === 'mobile' || field === 'domicile')) return 'Le numéro doit être composé de 10 chiffres.'
  if (message === 'WRONG_VALUE' && field === 'email') return 'Veuillez saisir une adresse email valide.'
  if (message === 'ONE_REQUIRED' && (field === 'mobile' || field === 'domicile')) {
    return 'Indiquez au moins un numéro de téléphone. Le numéro de portable est fortement conseillé.'
  }
  return ''
}

export function getCommunicationErrors(error) {
  return {
    title: titles[error.field],
    message: getMessageErrorByField(error.field, error.message),
  }
}

const validatePhone = (type, { value }) => {
  const isValidPhone = new RegExp(/^([0-9]{10})$/)
  if (value && !isValidPhone.test(value)) return buildValidationError(type, 'WRONG_VALUE')
  return null
}

const validateEmail = ({ value }) => {
  const isValidEmail = new RegExp(/^.+@[^"\\-].+$/)
  if (!isValidEmail.test(value)) return buildValidationError('email', 'WRONG_VALUE')
  return null
}

const validatePersonneCommunications = communications => {
  const errors: any[] = []


  if (communications.mobile) {
    errors.push(validatePhone('mobile', communications.mobile))
  }
  if (communications.domicile) {
    errors.push(validatePhone('domicile', communications.domicile))
  }
  if (communications.email) {
    errors.push(validateEmail(communications.email))
  }

  return errors.filter(error => error)
}

export interface CommunicationsValidationErrors extends ValidationErrors {
  emprunteurErrors: ValidationFieldError[]
  coEmprunteurErrors: ValidationFieldError[]
  errorMessages: ErrorMessage[]
}

export function validateCommunications({ emprunteurCommunications, coEmprunteurCommunications, hasCoEmprunteur }:
  { emprunteurCommunications: any, coEmprunteurCommunications?: any, hasCoEmprunteur?: boolean }) : CommunicationsValidationErrors {
  const emprunteurErrors = validatePersonneCommunications(emprunteurCommunications)
  const coEmprunteurErrors = hasCoEmprunteur ? validatePersonneCommunications(coEmprunteurCommunications) : []

  const filledPhones = [emprunteurCommunications?.mobile, emprunteurCommunications?.domicile,
    coEmprunteurCommunications?.mobile, coEmprunteurCommunications?.domicile]
    .filter(phone => !!phone?.value)

  if (filledPhones.length === 0) {
    emprunteurErrors.push(buildValidationError('mobile', 'ONE_REQUIRED'))
    emprunteurErrors.push(buildValidationError('domicile', 'ONE_REQUIRED'))
    if (hasCoEmprunteur) {
      coEmprunteurErrors.push(buildValidationError('mobile', 'ONE_REQUIRED'))
      coEmprunteurErrors.push(buildValidationError('domicile', 'ONE_REQUIRED'))
    }
  }
  const errorMessages = uniqBy([...emprunteurErrors, ...coEmprunteurErrors].map(getCommunicationErrors), 'message')
  const hasErrors = errorMessages.length + emprunteurErrors.length + coEmprunteurErrors.length > 0
  return {
    globalErrors: [],
    errorMessages,
    hasErrors,
    emprunteurErrors,
    coEmprunteurErrors,
  }
}
