import React from 'react'
import { Arrow, Button, Checkbox, SwitchArrows } from '@mmb/ui-components'
import { ModalTrigger } from '@core/partenaire-ui'
import { WarningPopIn } from '@partenaire/frontend-ui'

import { PARTENAIRE_LINK_TO_MENTIONS_LEGALES } from '@core/common-frontend'
import { useProduitSimulation } from './Produit.hooks'
import { Header } from './Header'
import { Body } from './Body'
import { ValidationDossierModal } from '../ValidationDossierModal'

import styles from './Produit.module.css'
import { DownloadButton } from '../../Buttons'
import { useGamme } from '../../Gamme'
import { useSaisie } from '../../Contexts'

export function Produit() {
  const { loading, isTransmissionAllowed, isTransmissionEnabled, checked, hasPermissionTransmissionWrite, setChecked, validateDossier } = useProduitSimulation()
  const { changeGamme, isUpdatingGamme } = useGamme()
  const { saisieUI } = useSaisie()

  return (
    <div className={styles.produit}>
      <Header />
      <Body />
      {hasPermissionTransmissionWrite && (
        <>
          <Checkbox
            className={styles.produit__body__checkbox}
            name="certifie-before-validate-product"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <div className={styles.produit__body__checkbox__bloc}>
            <span className={styles.produit__body__checkbox__label} onClick={() => setChecked(!checked)}>
              Je certifie que les données transmises ont bien fait l’objet d’une étude préalable
              et avoir communiqué au demandeur (prospect ou client) la notice d’information sur la
              protection des données personnelles de My Money Bank (accessible à l’adresse :
            </span>
            <a href={PARTENAIRE_LINK_TO_MENTIONS_LEGALES} target="_blank" rel="noreferrer">
              {PARTENAIRE_LINK_TO_MENTIONS_LEGALES}
            </a>
            <span className={styles.produit__body__checkbox__label} onClick={() => setChecked(!checked)}>), avant de nous communiquer leurs données. </span>

          </div>
        </>
      )}
      {checked && !isTransmissionEnabled && (
        <p>
          La transmission est temporairement indisponible.
        </p>)}
      <p>
        Cette simulation n’a aucune valeur contractuelle. Seule une offre de
        financement régulièrement émise par le prêteur pourrait l’engager, après
        étude approfondie de la situation du client. Les montants sont indiqués
        hors assurance emprunteur facultative pour la gamme My Simply One.
      </p>
      <div className={styles.produit__foot}>
        <DownloadButton />
        <WarningPopIn
          onConfirm={() => changeGamme()}
          label="Changement de gamme"
          title="Changement de gamme"
          warningLine={`Souhaitez-vous basculer votre dossier en ${saisieUI.isSecured() ? 'sans garantie' : 'hypothécaire'} ?`}
          warningLineBold="Merci de confirmer."
          loading={isUpdatingGamme}
          confirmText="Confirmer"
          loadingText="Changement en cours"
        >
          <Button
            iconClassName={styles.simulation__switchButton}
            leftIcon={SwitchArrows}
            loading={loading}
            outline
          >
            {`Basculer en ${saisieUI.isSecured() ? 'sans garantie' : 'hypothécaire'}`}
          </Button>
        </WarningPopIn>
        <ModalTrigger
          renderButton={(_, setModalOpen) => (
            <Button
              disabled={!isTransmissionAllowed}
              rightIcon={Arrow}
              className={styles.simulation__button}
              onClick={() => setModalOpen(true)}
            >
              Valider cette simulation
            </Button>
          )}
        >
          <ValidationDossierModal
            validateDossier={validateDossier}
            loading={loading}
          />
        </ModalTrigger>
      </div>
    </div>
  )
}
