import { gql, useQuery, DO_NOT_CHECK_CACHE } from '@core/graphql'
import { useEffect, useState } from 'react'
import { Dossier, Palier, PlanFinancement } from '../../model/DetailPlanFinancement.model'

export const GET_PLAN_FINANCEMENT = gql`
query getPlanFinancement($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    id
    propositionCredit {
      planFinancement {
        dureeEnMois
        taux
        mensualite
        mensualiteAvecAssurance
        taeg
        usure
        listPalier {
          dureeEnMois
          echeance
        }
      }
    }
  }
}
`

const formatUsure = usure => usure ? Number(Number.parseFloat(usure).toFixed(2)) : 0

export function usePlanFinancement(id) {
  const {
    data = {} as Dossier,
    loading,
  } = useQuery(GET_PLAN_FINANCEMENT, {
    ...DO_NOT_CHECK_CACHE,
    errorPolicy: 'all',
    variables: { id },
  })

  const [planFinancement, setPlanFinancement] = useState<PlanFinancement>()
  const [hasPalier, setHasPalier] = useState<boolean>()

  useEffect(() => {
    const planFinancementData = data?.partenaire_dossierTransmis?.propositionCredit?.planFinancement
    if (planFinancementData) {
      const formatedPlanFinancement = {
        duree: planFinancementData.dureeEnMois,
        taux: planFinancementData.taux,
        mensualite: planFinancementData.mensualite,
        mensualiteAvecAssurance: planFinancementData?.mensualiteAvecAssurance,
        taeg: planFinancementData.taeg,
        usure: formatUsure(planFinancementData?.usure),
        listPalier: planFinancementData.listPalier,
      } as PlanFinancement
      setPlanFinancement(formatedPlanFinancement)
      const listePalier = planFinancementData?.listPalier as Array<Palier>
      setHasPalier(listePalier?.length >= 2)
    }
  }, [data])

  return {
    loading,
    planFinancement,
    hasPalier,
  }
}
