import React from 'react'
import { string, shape, func, bool, arrayOf } from 'prop-types'

import { Input } from '@mmb/ui-components'
import { Select } from '@core/partenaire-ui'
import { SelectCommunes, useCommunesOptions } from '@partenaire/frontend-ui'
import { hasErrorForName as hasError, clearEmpty } from '../../../../utils/utils'

import { STATUT_ADRESSE_OPTIONS } from '../../../../../src/utils/'

import styles from './AdresseForm.module.css'

AdresseForm.propTypes = {
  suffixes: shape({
    label: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  adresse: shape({
    complement: string,
    numero: string,
    voie: string,
    lieuDit: string,
    codePostalVille: string,
    statut: string,
  }).isRequired,
  setAdresse: func,
  disabled: bool,
  isCoEmprunteur: bool,
  errors: arrayOf(
    shape({
      field: string.isRequired,
      message: string,
    }),
  ),
  showErrors: bool,
}

AdresseForm.defaultProps = {
  disabled: false,
  isCoEmprunteur: false,
  setAdresse: () => {},
  errors: [],
  showErrors: false,
}

export function AdresseForm({ suffixes, errors, showErrors, adresse, setAdresse, disabled, isCoEmprunteur }) {
  const formatCommune = ({ nom, codePostal }) => { return { label: `${codePostal} ${nom}`, value: JSON.stringify({ codePostal, ville: nom }) } }
  const { setCodePostalVille, citiesOptions } = useCommunesOptions(formatCommune)
  const getAdresseStatutByValue = searchValue => STATUT_ADRESSE_OPTIONS.find(({ value }) => value === searchValue)
  const hasErrorForName = name => hasError(name, errors, showErrors)
  const formatVille = codePostalVille => {
    if (codePostalVille) {
      const codePostal = codePostalVille.codePostal || ''
      const ville = codePostalVille.ville || ''
      const formatedValue = codePostal || ville ? JSON.stringify(codePostalVille) : ''
      return { label: `${codePostal} ${ville}`, value: formatedValue }
    }
    return { label: '', value: '' }
  }

  const inputCommune = input => {
    const { codePostal = '', ville = '' } = input?.value ? JSON.parse(input?.value) : {}
    setAdresse({ codePostal, ville })
  }

  return (
    <div className={styles.adresseForm}>
      <Input
        aria-invalid={hasErrorForName('complement')}
        hasError={hasErrorForName('complement')}
        disabled={disabled}
        className={styles.adresseForm__input}
        name={`${suffixes.name}.complement`}
        label={`Complement ${suffixes.label}`}
        hideLabel
        onChange={({ target }) => setAdresse({ complement: target.value })}
        value={clearEmpty(adresse?.complement)}
      />
      <Input
        aria-invalid={hasErrorForName('numero')}
        hasError={hasErrorForName('numero')}
        disabled={disabled}
        className={styles.adresseForm__input}
        name={`${suffixes.name}.numero`}
        label={`Numéro ${suffixes.label}`}
        hideLabel
        onChange={({ target }) => setAdresse({ numero: target.value })}
        value={clearEmpty(adresse?.numero)}
      />
      <Input
        aria-invalid={hasErrorForName('voie')}
        hasError={hasErrorForName('voie')}
        disabled={disabled}
        className={styles.adresseForm__input}
        name={`${suffixes.name}.voie`}
        label={`Voie ${suffixes.label}`}
        hideLabel
        onChange={({ target }) => setAdresse({ voie: target.value })}
        value={clearEmpty(adresse?.voie)}
      />
      <Input
        aria-invalid={hasErrorForName('lieuDit')}
        hasError={hasErrorForName('lieuDit')}
        disabled={disabled}
        className={styles.adresseForm__input}
        name={`${suffixes.name}.lieuDit`}
        label={`Lieu-dit ${suffixes.label}`}
        hideLabel
        onChange={({ target }) => setAdresse({ lieuDit: target.value })}
        value={clearEmpty(adresse?.lieuDit)}
      />
      <SelectCommunes
        setCodePostalVille={setCodePostalVille}
        citiesOptions={citiesOptions}
        aria-invalid={hasErrorForName('codePostalVille')}
        hasError={hasErrorForName('codePostalVille')}
        disabled={disabled}
        className={styles.adresseForm__select}
        name={`${suffixes.name}.codePostalVille`}
        label={`Code postal ou ville ${suffixes.label}`}
        hideLabel
        hideDropdown
        onChange={input => inputCommune(input)}
        value={formatVille({ codePostal: adresse?.codePostal, ville: adresse?.ville })}
      />
      {!isCoEmprunteur && (
      <Select
        aria-invalid={hasErrorForName('statut')}
        hasError={hasErrorForName('statut')}
        disabled={disabled}
        className={styles.adresseForm__select}
        name={`${suffixes.name}.statut`}
        label={`Statut lieu de résidence ${suffixes.label}`}
        hideLabel
        onChange={({ value }) => { setAdresse({ statut: value }) }}
        value={getAdresseStatutByValue(adresse?.statut)}
        options={STATUT_ADRESSE_OPTIONS}
      />)}
    </div>
  )
}
