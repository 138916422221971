import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import styles from './List.module.css'

const NON_RENSEIGNE = 'Non renseigné'

CellHiglightingEmptyValue.propTypes = {
  value: string,
}

CellHiglightingEmptyValue.defaultProps = {
  value: undefined,
}

export function CellHiglightingEmptyValue({ value }) {
  return (
    <span
      className={classNames(styles.list__cell, { [styles.list__cellHighlight]: !value })}
      title={value || NON_RENSEIGNE}
      role="cell"
    >
      {value || NON_RENSEIGNE}
    </span>
  )
}
