import React, { useRef } from 'react'
import { string } from 'prop-types'
import { isEmpty, nth } from 'lodash'

import { Button, Plus, Card } from '@mmb/ui-components'
import { FormHeader, Separator } from '@core/partenaire-ui'

import { useDelayedFormBlur } from '../../utils'
import { Patrimoine } from './Patrimoine'
import { usePatrimoines } from './Patrimoines.hook'

import styles from './Patrimoines.module.css'
import { Bien } from './Bien'
import { SaisieErrorMessages } from '../SaisieErrorMessages'
import { EntryFormStateName } from '../FormState/EntryFormState'

Patrimoines.propTypes = {
  className: string,
}

Patrimoines.defaultProps = {
  className: '',
}

export function Patrimoines({ className }) {
  const {
    loading,
    patrimoinesUI,
    showErrors,
    errors,
    biensErrorMessages,
    errorMessages,
    isAdresseCopiable,
    addPatrimoine,
    removePatrimoine,
    handleSubmit,
    copyAdresse,
  } = usePatrimoines()
  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)

  return (
    <div id={`${EntryFormStateName.PATRIMOINE}`}>
      <Card className={className}>
        <FormHeader className={styles.patrimoines__title}>Patrimoine</FormHeader>
        <h4 className={styles.patrimoines__subTitle}>Saisissez le ou les biens à prendre en garantie et le ou les autre(s) biens si besoin</h4>
        <form
          data-testid="patrimoine-form"
          ref={formRef}
          onSubmit={handleSubmit}
          onBlur={onBlur}
          onFocus={onFocus}
        >
          {patrimoinesUI.getSortedList()?.map((patrimoine, index) => {
            const numero = index + 1
            return (
              <Patrimoine key={`patrimoine_${patrimoine.position}`} numero={numero}>
                <Bien
                  numero={numero}
                  patrimoine={patrimoine}
                  setPatrimoine={newPatrimoine => patrimoinesUI.update(index, newPatrimoine)}
                  loading={loading}
                  removePatrimoine={removePatrimoine(index)}
                  errors={nth(errors.bienErrors, index)}
                  showErrors={!!showErrors}
                  setIsGarantie={(isGarantie: boolean) => patrimoinesUI.updateIsGarantie(index, isGarantie)}
                  copyAdresse={copyAdresse(index)}
                  adresseIsCopiable={isAdresseCopiable}
                />
                <SaisieErrorMessages
                  errors={(nth(biensErrorMessages, index) || [])}
                />
              </Patrimoine>
            )
          })}
          {showErrors && <SaisieErrorMessages errors={errorMessages} />}
        </form>
        {isEmpty(patrimoinesUI.list) && <Separator className={styles.patrimoines__separator} />}
        <div className={styles.patrimoines}>
          <Button
            className={styles.patrimoines__addButton}
            aria-label="Ajouter un bien"
            onClick={addPatrimoine}
            disabled={loading}
            outline
          >
            <Plus className={styles.patrimoines__addIcon} />
            Ajouter un bien
          </Button>
        </div>
      </Card>
    </div>
  )
}
