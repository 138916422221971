import React, { useState, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { searchContext } from '@core/partenaire-common'
import { ColumnName } from '@partenaire/common'

import { Dossiers } from './components/Dossiers'
import { Detail } from './components/Detail'
import { HistoriquePieces, Pieces } from './components/Pieces'
import { Contrat } from './components/Contrat'
import { Assurances } from './components/Assurances'

export function DossiersRoute({ ...props }) {
  const searchState = useState('')
  const statutState = useState('')
  const sortState = useState(ColumnName.DATE_STATUT)
  const agencesState = useState([])
  const selectedAgencesState = useState([])
  const totalCountState = useState()
  const selectedGammeState = useState()
  const isDescendingOrderState = useState(true)
  const searchValue = useMemo(() => ({
    search: searchState,
    statut: statutState,
    sort: sortState,
    agences: agencesState,
    selectedAgences: selectedAgencesState,
    totalCount: totalCountState,
    selectedGamme: selectedGammeState,
    isDescendingOrder: isDescendingOrderState,
  }), [searchState, sortState, agencesState, totalCountState, selectedGammeState, isDescendingOrderState, selectedAgencesState, statutState])

  return (
    <searchContext.Provider value={searchValue}>
      <Routes {...props}>
        <Route path="/" element={<Dossiers />} />
        <Route path="/:id" element={<Detail />} />
        <Route path="/:id/pieces" element={<Pieces />} />
        <Route path="/:id/pieces/historique" element={<HistoriquePieces />} />
        <Route path="/:id/contrat" element={<Contrat />} />
        <Route path="/:id/assurance" element={<Assurances />} />
      </Routes>
    </searchContext.Provider>
  )
}
