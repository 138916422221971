import React from 'react'

import { TextRow } from '@mmb/ui-components'

import styles from './MontagePlaceholder.module.css'

export function MontagePlaceholder() {
  return (
    <div className={styles.montagePlaceholder}>
      <div className={styles.montagePlaceholder__block} aria-label="duree.placeholder">
        <p>Durée</p>
        <TextRow width="15rem" className={styles.montagePlaceholder__row} />
      </div>
      <div className={styles.montagePlaceholder__block} aria-label="fraisMandat.placeholder">
        <p>Frais de mandat</p>
        <TextRow width="15rem" className={styles.montagePlaceholder__row} />
      </div>
      <div className={styles.montagePlaceholder__block} aria-label="tresories.placeholder">
        <p>Besoin de trésorerie</p>
        <div className={styles.montagePlaceholder__inputs}>
          <div className={styles.montagePlaceholder__input} aria-label="confort.placeholder">
            <p>Confort</p>
            <TextRow width="15rem" className={styles.montagePlaceholder__row} />
          </div>
          <div className={styles.montagePlaceholder__input} aria-label="travaux.placeholder">
            <p>Travaux</p>
            <TextRow width="15rem" className={styles.montagePlaceholder__row} />
          </div>
          <div className={styles.montagePlaceholder__input} aria-label="autre.placeholder">
            <p>Autre</p>
            <TextRow width="15rem" className={styles.montagePlaceholder__row} />
          </div>
        </div>
      </div>
    </div>
  )
}
