import React, { FunctionComponent } from 'react'

import { DocumentUI, TransfertUI } from '../../../../model'
import { HistoriqueCommentaire } from './HistoriqueCommentaire'
import { HistoriqueTitle } from './HistoriqueTitle'
import { HistoriqueDocument } from './HistoriqueDocument'

import styles from '../HistoriquePieces.module.css'

interface HistoriqueTransfertProps {
  transfert: TransfertUI
  getDownloadDocumentURL: Function
  idx: number
}

export const HistoriqueTransfert: FunctionComponent<HistoriqueTransfertProps> = ({ transfert, getDownloadDocumentURL, idx }) => (
  <div className={styles.historique__transfert} data-testid={`histo transfert ${idx}`}>
    <HistoriqueTitle date={transfert.getDisplayableDate()} />
    <HistoriqueCommentaire commentaire={transfert?.props?.commentaire} />
    {transfert?.props?.documentsUI?.documents?.map((document: DocumentUI, index: number) => (
      <HistoriqueDocument document={document} idx={index} getDownloadDocumentURL={getDownloadDocumentURL} key={`Historique Document ${index + 1}`} />
    ))}
  </div>
)
