/* eslint-disable react/require-default-props */
import React, { FunctionComponent } from 'react'
import { Cross } from '@mmb/ui-components'

import { InputMontant } from '@partenaire/frontend-ui'
import { Select, Separator, Tooltip } from '@core/partenaire-ui'
import { REVENUS_OPTIONS } from '@core/common'

import styles from './RevenuForm.module.css'
import { TOOLTIP_INFORMATIONS } from '../../tooltip'
import { clearEmpty, hasErrorForName as hasError, parseMontant } from '../../../../../utils'
import { LabelUI, RevenuFormUI, ValidationFieldError } from '../../../../../model'

interface RevenuFormProps {
  suffixes: LabelUI
  revenu: RevenuFormUI
  setRevenu: (revenu: RevenuFormUI, index: number) => void
  removeRevenu: (revenu: RevenuFormUI, index: number) => void
  index: number
  errors?: ValidationFieldError[]
  showErrors: boolean
}

export const RevenuForm: FunctionComponent<RevenuFormProps> = ({ suffixes, revenu, setRevenu, removeRevenu, index, errors = [], showErrors }) => {
  const findRevenu = typeToFind => REVENUS_OPTIONS
    .find(({ value: type }) => type === typeToFind)
  const hasErrorForName = name => hasError(name, errors, showErrors)
  const foundRevenu = findRevenu(revenu?.type) || null
  const numero = index + 1
  const tooltip = TOOLTIP_INFORMATIONS.find(({ type }) => type === revenu?.type)
  return (
    <div className={styles.revenuForm__container} aria-label={`Revenu ${suffixes.label} numero ${numero}`}>
      <div className={styles.revenuForm__selectContainer}>
        <Select
          className={styles.revenuForm__select}
          options={REVENUS_OPTIONS}
          hasError={hasErrorForName('nature')}
          hideLabel
          placeholder="Type de revenu"
          name={`${revenu?.type}.revenus.type`}
          label={`Type de revenu ${foundRevenu?.label || 'non défini '} ${suffixes.label}`}
          onChange={({ value }) => setRevenu({ ...revenu, type: value }, index)}
          value={foundRevenu}
        />
        {tooltip && (<Tooltip
          wrapperClassName={styles.revenusForm__tooltip}
          title={tooltip?.title}
          content={(<p>{tooltip?.content}</p>)}
        />)}

        <button
          aria-label={`Suppression du revenu n° ${numero}`}
          className={styles.revenuForm__buttonCross}
          type="button"
          onClick={e => {
            e.preventDefault()
            removeRevenu(revenu, index)
          }}
        >
          <Cross />
        </button>
      </div>
      <InputMontant
        className={styles.revenuForm__dynamicInput}
        name={`${revenu?.type}.revenus.value`}
        hasError={hasErrorForName('montant')}
        label={`Montant du revenu ${revenu?.type} ${suffixes.label}`}
        hideLabel
        onChange={({ target }) => setRevenu({ ...revenu, montant: parseMontant(target.value) }, index)}
        value={clearEmpty(revenu?.montant)}
      />
      <Separator />
    </div>)
}

