export enum TypeAmortissement {
  LINEAIRE = 'LINEAIRE',
  PALIER = 'PALIER',
}

export interface Demande {
  dureeFinancement?: number
  fraisMandat?: number
  apport?: number
  id?: string
  tresorerie: {
    autre?: number
    confort?: number
    travaux?: number
  }
  typeAmortissement?: TypeAmortissement
}
