/**
 * Permissions utilisables avec les composants gérant le contrôle d'accès selon le rôle.
 * Certaines 'Query' et 'Mutation' GraphQL sont soumises à des autorisations (permissions),
 * exprimées avec la librairie Casbin (https://casbin.org/).
 */
export enum PermissionScope {
  DOSSIER_READ = 'dossier:read',
  DOSSIER_READ_ALL = 'dossier:read_all',
  DOSSIER_WRITE = 'dossier:write',
  GESTIONNAIRES_READ = 'gestionnaires:read',
  PILOTAGE_READ = 'pilotage:read',
  PILOTAGE_ADVANCED_FILTERS = 'pilotage-advanced-filters:read',
  UTILISATEURS_READ = 'utilisateurs:read',
  UTILISATEURS_WRITE = 'utilisateurs:write',
  UTILISATEURS_NOTIFY = 'utilisateurs:notify',
  UTILISATEURS_ACT_AS_SUPER_ADMIN_ON_OTHER_CABINETS = 'utilisateurs:act-as-super-admin-on-other-cabinets',
  TRANSFERT_ENCOURS = 'transfert-encours:write',
  PILOTAGE_READ_SECRET = 'pilotage:read-secret',
  TRANSMISSION_WRITE = 'transmission:write',
  ENVOI_PIECES_WRITE = 'envoi-pieces:write',
  PARCOURS_ASSURANCE_WRITE = 'parcours-assurance:write'
}
