import React, { useState } from 'react'
import Pluralize from 'react-pluralize'
import { isNil } from 'lodash'
import { oneOf } from 'prop-types'

import { Can } from '@core/partenaire-common'
import { getConsent, ModalTrigger, Panel, Separator } from '@core/partenaire-ui'
import { Button, Plus } from '@mmb/ui-components'
import { smartProfileTrackWithParams } from '@core/analytics'

import { Form } from '../Form'
import { Sort } from '../Sort'
import { Filter } from '../Filter'
import { List } from '../List'

import styles from './Users.module.css'

const buttonCreate = (
  <>
    <Plus className={styles.users__plus} />
    Créer un utilisateur
  </>
)

Users.propTypes = {
  nomenclature: oneOf(['PARTENAIRE', 'PORTAIL_CGP']).isRequired,
}

export function Users({ nomenclature }) {
  smartProfileTrackWithParams('&_title=Utilisateurs', getConsent())

  const [totalCount, setTotalCount] = useState(0)

  const numberOfUsers = !isNil(totalCount) && `(${totalCount})`

  return (
    <div className={styles.users}>
      <Panel>
        <Filter />
      </Panel>
      <div className={styles.users__body}>
        <div className={styles.users__bandeau}>
          <div className={styles.users__bandeauIsLeft}>
            <h2 id="utilisateurs_table_description">
              <Pluralize singular="Utilisateur" zero="Utilisateur" count={totalCount} showCount={false} /> {numberOfUsers}
            </h2>
            <Can scope="utilisateurs:write" allCabinets>
              <ModalTrigger
                className={styles.users__modal}
                renderButton={(_, setOpen) => (
                  <Button
                    className={styles.users__button}
                    onClick={() => setOpen(true)}
                  >
                    {buttonCreate}
                  </Button>
                )}
              >
                <Form
                  title="Création d'un utilisateur"
                  info="L'utilisateur recevra un Email afin d'activer son compte."
                  submitLabel="Créer"
                />
              </ModalTrigger>
            </Can>
          </div>

          <Sort />
        </div>

        <Separator className={styles.users__separator} />

        <List updateTotalCount={setTotalCount} nomenclature={nomenclature} />
      </div>
    </div>
  )
}
