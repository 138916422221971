import React from 'react'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import { shape, string } from 'prop-types'

import { GAMMES } from '@partenaire/frontend-common'

import styles from './Gamme.module.css'


Gamme.propTypes = {
  gamme: shape({
    label: string,
    value: string,
  }).isRequired,
  delais: shape({
    min: string,
    max: string,
  }),
}

Gamme.defaultProps = {
  delais: {},
}

export function Gamme({ gamme, delais: { min, max } }) {
  const isHypo = gamme.value === GAMMES.SECURED.value
  const isConso = gamme.value === GAMMES.UNSECURED.value
  const fetchDayLabel = () => {
    if (isEmpty(min) && isEmpty(max)) {
      return ''
    }
    return max === '1' ? 'Jour' : 'Jours'
  }
  const fetchDayString = () => (isEmpty(min) || isEmpty(max) ? min || max || '-' : `${min} - ${max}`)
  return (
    <div className={styles.gamme}>
      <div aria-label={`Délais de prise en charge pour la gamme ${gamme.label}`} className={styles.gamme__duration}>
        {fetchDayString()}
      </div>
      <div className={styles.gamme__dayLabel}>{fetchDayLabel()}</div>
      <div
        className={cn(
          styles.gamme__label,
          {
            [styles.gammeHypo]: isHypo,
            [styles.gammeConso]: isConso,
          },
        )}
      >
        {gamme.label}
      </div>
    </div>
  )
}
