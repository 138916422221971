import React from 'react'

import { TextRow } from '@mmb/ui-components'

import styles from './NombreEnfantsPlaceholder.module.css'

export function NombreEnfantsPlaceholder() {
  return (
    <div className={styles.nombreEnfantsPlaceholder}>
      <div className={styles.nombreEnfantsPlaceholder__inputs}>
        <p className={styles.nombreEnfantsPlaceholder__label}>Nombre d'enfants</p>
        <TextRow width="6.5rem" height="4.5rem" />
        <p className={styles.nombreEnfantsPlaceholder__label}>
          Dont à charge
          <span className={styles.nombreEnfantsPlaceholder__aCharge}> de moins de 18 ans</span>
        </p>
        <TextRow width="6.5rem" height="4.5rem" />
      </div>
      <div className={styles.nombreEnfantsPlaceholder__ages}>
        <TextRow width="180px" height="100%" className={styles.nombreEnfantsPlaceholder__row} />
        <TextRow width="180px" height="100%" className={styles.nombreEnfantsPlaceholder__row} />
        <TextRow width="180px" height="100%" className={styles.nombreEnfantsPlaceholder__row} />
      </div>
    </div>
  )
}

