import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'

import { ModalTrigger } from '@core/partenaire-ui'
import { formatCondenseDate } from '@core/common'

import styles from '../DocumentsListe.module.css'
import { useDocumentDownload } from './DocumentLink.hook'
import { DocumentUI } from '../../../../model/OutilsUI'
import { DocumentIcon } from '../../DocumentIcon'

let globalId = 0

interface DocumentLinkProps {
  document: DocumentUI
}

export const DocumentLink: FunctionComponent<DocumentLinkProps> = ({ document: { id, nom, dateCreation, contentType } }) => {
  const { url, downloadOrStream } = useDocumentDownload(id, contentType)
  const uniqueId = `document_link_${++globalId}`
  return (
    <ModalTrigger
      renderButton={(_, setOpen) => (
        <li title={nom} aria-labelledby={uniqueId} className={styles.outils__documents__line}>
          <Link to="#" onClick={() => downloadOrStream(setOpen)} className={styles.outils__documents__link}>
            <div aria-hidden>
              <DocumentIcon contentType={contentType} className={styles.outils__documents__link_icone} />
            </div>
            <div className={styles.outils__documents__link_nom}>{nom}</div>
            {dateCreation && <div>Mis à disposition le {formatCondenseDate(dateCreation)}</div>}
          </Link>
        </li>
      )}
    >
      <ReactPlayer playing url={url} controls />
    </ModalTrigger>
  )
}
