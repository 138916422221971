import { WarningPopIn } from '@partenaire/frontend-ui'
import React from 'react'
import style from './gamme.module.css'

export const getBasculeHypothecaireLink = (changeGamme: () => void, isUpdatingGamme: boolean, text: string) => (
  <WarningPopIn
    onConfirm={changeGamme}
    label="Changement de gamme"
    title="Changement de gamme"
    warningLine="Souhaitez-vous basculer votre dossier en hypothécaire ?"
    warningLineBold="Merci de confirmer."
    loading={isUpdatingGamme}
    confirmText="Confirmer"
    loadingText="Changement en cours"
  >
    <a className={style.gamme__link}>{` ${text}`}</a>
  </WarningPopIn>)
