import React from 'react'
import { Navigate } from 'react-router'
import { any, string } from 'prop-types'
import { Redirection } from '../Redirection'
import { useAzureADFlow } from '../../hooks/AzureAD/useAzureADFlow.hook'

Flow.propTypes = {
  flowType: string.isRequired,
  defaultCompletionUrl: string.isRequired,
  wrapInUnauthenticatedLayout: any,
}

Flow.defaultProps = {
  wrapInUnauthenticatedLayout: undefined,
}

export function Flow({ flowType, defaultCompletionUrl, wrapInUnauthenticatedLayout }) {
  const { navigateToUrl, redirectToUrl, invalidToken, technicalIssue } = useAzureADFlow(flowType, defaultCompletionUrl)
  if (navigateToUrl) {
    return <Navigate to={navigateToUrl} />
  }

  if (redirectToUrl) {
    window.location.assign(redirectToUrl)
    return null
  }

  return <Redirection invalidToken={invalidToken} technicalIssue={technicalIssue} wrapper={wrapInUnauthenticatedLayout} />
}
