import { DATE_DD_MM_YYYY_FR } from '@core/common'
import { parseISO } from 'date-fns'
import { TransfertUI, DocumentsUI, PieceUI, DocumentUI, PieceStatutUI } from '../../model'
import { DocumentCategorie } from '../../model/transfert/CategorieUI.model'
import { dateFromGraphQL } from '../../utils/date'

export const toDocumentUI = (documentFromQuery: any): DocumentUI => (new DocumentUI({
  id: documentFromQuery.id,
  filename: documentFromQuery.filename,
  contentType: documentFromQuery.contentType,
  dateCreation: documentFromQuery.dateCreation,
  nature: documentFromQuery.nature,
  categorie: documentFromQuery.categorie as DocumentCategorie,
}))

export const toTransfertUI = (transfertGraphQL: any, dossierId: string): TransfertUI => new TransfertUI({
  id: transfertGraphQL.id,
  dossierId,
  date: parseISO(transfertGraphQL.date),
  commentaire: transfertGraphQL.commentaire,
  statut: transfertGraphQL.statut,
  documentsUI: new DocumentsUI(transfertGraphQL.documents.map(toDocumentUI)),
})


export const toPieceUI = (pieceGraphQL: any): PieceUI => ({
  categorie: pieceGraphQL.categorie as DocumentCategorie,
  dateValidation: dateFromGraphQL(pieceGraphQL.dateValidation, DATE_DD_MM_YYYY_FR),
  libelle: pieceGraphQL.libelle as string,
  statut: pieceGraphQL.statut as PieceStatutUI,
  finalite: pieceGraphQL.finalite as string,
})
