import { FetchPolicy } from '@apollo/client'

export { gql, ApolloProvider, useApolloClient } from '@apollo/client'
export { MockedProvider } from '@apollo/client/testing'
export * from './client'
export * from './hooks'
export * from './pagination'
export * from './useQueryRedirectError'
export * from './useMutationRedirectError'

/**
 * Désactive la vérification du cache par le client Apollo lors des requêtes graphql
 * effectuées par les hooks useQuery / useLazyQuery / useQueryWithError / usePaginationQuery
 * @see https://www.apollographql.com/docs/react/data/queries/#setting-a-fetch-policy
 */
export const DO_NOT_CHECK_CACHE = { fetchPolicy: 'network-only' as FetchPolicy }
