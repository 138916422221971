import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { differenceInDays } from 'date-fns'

import { DossierResume, DossierStatut, Gamme, SignatureType } from '@partenaire/common'
import { Link } from '@core/ui'
import { Delete, Montant, Priority, ESignature } from '@mmb/ui-components'
import { Tooltip } from '@core/partenaire-ui'
import { WarningPopIn } from '@partenaire/frontend-ui'
import { formatCondenseDate } from '@core/common'
import { getDossierRedirectPath, getStatutLabel, isDossierStatusComplete } from '@partenaire/frontend-common'

import styles from './ListItem.module.css'
import { useDossiers } from '../List.hooks'


interface ListItemProps {
  dossier: DossierResume
  index: number
  deleteDossier: (id: string) => void
  deleteLoading: boolean
}

export const ListItem: FunctionComponent<ListItemProps> = ({ dossier, index, deleteDossier, deleteLoading }) => {
  const { id, gamme, reference, dateStatut, montantFinance, isPriority, statut, emprunteurs, signatureType } = dossier
  const daysSinceCreation = dateStatut ? differenceInDays(new Date(), new Date(dateStatut)) : undefined
  const daysBeforeDeletion = daysSinceCreation !== undefined ? 61 - daysSinceCreation : false
  const isSoonDeleted = daysSinceCreation ? statut === DossierStatut.SAISIE && daysSinceCreation >= 45 : false

  const { hasPermissionDossierWrite } = useDossiers()

  const { nom, prenom } = emprunteurs[0]?.identite ?? {}
  const path = getDossierRedirectPath(id, statut, hasPermissionDossierWrite)

  const statutLabel = gamme && statut ? getStatutLabel(gamme, statut) : ''

  return (
    <div
      role="row"
      aria-label={`Dossier ${nom} ${prenom}`}
      className={classnames(styles.listItem__line, styles.listItem__gamme, {
        [styles.listItem__lineStriped]: index % 2 !== 0,
        [styles.listItem__gammeHypo]: gamme === Gamme.SECURED,
      })}
    >
      <Link
        to={path}
        role="cell"
        aria-label="emprunteur"
        className={classnames(styles.listItem__cell, styles.listItem__name)}
      >{nom?.toUpperCase() || ''}
      </Link>
      <Link
        to={path}
        role="cell"
        aria-label="prenom"
        className={classnames(styles.listItem__cell, styles.listItem__name)}
      >{prenom}
      </Link>
      <Link
        to={path}
        role="cell"
        aria-label="reference"
        className={classnames(styles.listItem__cell, styles.listItem__reference)}
      >{reference}
      </Link>
      <Link
        to={path}
        role="cell"
        aria-label="montant emprunté"
        className={classnames(styles.listItem__bold, styles.listItem__montant, styles.listItem__cell)}
      >
        {(montantFinance && !Number.isNaN(montantFinance)) && (<Montant value={montantFinance} withoutDecimal wholeClassName={styles.listItem__whole} />)}
      </Link>
      <Link
        to={path}
        role="cell"
        aria-label="statut"
        className={classnames(styles.listItem__bold, styles.listItem__cell, {
          [styles.listItem__statutComplete]: isDossierStatusComplete(statut),
          [styles.listItem__statutIncomplete]: !isDossierStatusComplete(statut),
        })}
      >
        {statutLabel}
      </Link>
      <Link
        to={path}
        role="cell"
        aria-label="date dernier statut"
        className={classnames(styles.listItem__cell, styles.listItem__date)}
      >
        {formatCondenseDate(dateStatut)}
      </Link>
      <Link
        to={path}
        role="cell"
        aria-label="priority"
        className={classnames(styles.listItem__cell, styles.listItem__signature)}
      >
        {signatureType === SignatureType.ELECTRONIQUE && <ESignature className={styles.listItem__priority__icon} />}
      </Link>
      <Link to={path} role="cell" aria-label="priority" className={styles.listItem__cell}>
        {isPriority && <Priority className={styles.listItem__priority__icon} />}
      </Link>
      <div className={classnames(styles.listItem__cell, styles.listItem__delete)}>
        {isSoonDeleted && (
        <Tooltip
          warning
          wrapperClassName={styles.listItem__tooltip}
          title="suppression à venir"
          content={`Votre dossier sera supprimé dans ${daysBeforeDeletion} jours si vous n'avez pas validé de simulation`}
        />)}
      </div>
      <div role="cell" aria-label="delete" className={classnames(styles.listItem__cell, styles.listItem__delete)}>
        {statut === DossierStatut.SAISIE && hasPermissionDossierWrite && (
        <WarningPopIn
          loading={deleteLoading}
          title="Suppression d’un dossier"
          warningLine="Vous êtes sur le point de supprimer un dossier."
          warningLineBold="Cette action est définitive."
          onConfirm={() => deleteDossier(id)}
        >
          <Delete
            aria-label="Supprimer Dossier"
            className={styles.listItem__delete__icon}
          />
        </WarningPopIn>)}
      </div>
    </div>
  )
}
