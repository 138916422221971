import React from 'react'
import { string } from 'prop-types'

import { Section } from '@mmb/ui-components'

import { useDetailAssurance } from './DetailAssurance.hooks'

import styles from './DetailAssurance.module.css'
import { PersonneAssurance } from '../../Assurances/Assurance/PersonneAssurance'

DetailAssurance.propTypes = {
  id: string.isRequired,
}

export function DetailAssurance({ id }) {
  const { loading, assuranceEmprunteurUI, assuranceCoEmprunteurUI } = useDetailAssurance(id)
  return loading || !assuranceEmprunteurUI ? null : (
    <Section
      title="Assurance"
      className={styles.detailAssurance}
    >
      <div className={styles.detailAssurance__content} aria-label="Assurance">
        {assuranceEmprunteurUI && <PersonneAssurance assuranceUI={assuranceEmprunteurUI} className={styles.detailAssurance__personne} />}
        {assuranceCoEmprunteurUI && <PersonneAssurance assuranceUI={assuranceCoEmprunteurUI} className={styles.detailAssurance__personne} />}
      </div>
    </Section>
  )
}
