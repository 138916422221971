import React from 'react'
import { Link } from '@core/ui'
import { func } from 'prop-types'
import styles from '../Layout.module.css'


MonProfil.propTypes = {
  setOpen: func.isRequired,
}

/**
 * Action d'accès à la page de profil
 * @param setOpen fonction gérant l'ouverture de la liste déroulante qui contiendra l'action
 * @returns
 */
export function MonProfil({ setOpen, ...props }) {
  return (
    <Link
      to="/app/mon-profil"
      onClick={() => setOpen(false)}
      className={styles.layout__link}
      {...props}
    >
      Accéder à mon profil
    </Link>
  )
}

Deconnexion.propTypes = {
  setOpen: func.isRequired,
}

/**
 * Action de déconnexion
 * @param setOpen fonction gérant l'ouverture de la liste déroulante qui contiendra l'action
 * @returns
 */
export function Deconnexion({ setOpen, ...props }) {
  return (
    <Link
      to="/app/deconnexion"
      onClick={() => setOpen(false)}
      className={styles.layout__link}
      {...props}
    >
      Déconnexion
    </Link>
  )
}
