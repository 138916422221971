import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { func, string } from 'prop-types'
import { useDebounce } from 'use-debounce'
import { Input, MagnifyingGlass } from '@mmb/ui-components'

import styles from './InputSearch.module.css'

InputSearch.propTypes = {
  onDebouncedChange: func,
  onChange: func,
  className: string,
  labelClassName: string,
  fieldClassName: string,
  iconClassName: string,
}

InputSearch.defaultProps = {
  onDebouncedChange: () => null,
  onChange: () => null,
  className: '',
  labelClassName: '',
  fieldClassName: '',
  iconClassName: '',
}

export function InputSearch({ onDebouncedChange, onChange, className, labelClassName, fieldClassName, iconClassName, ...props }) {
  const [value, setValue] = useState('')

  const [debounceValue] = useDebounce(value, 500)

  useEffect(() => {
    onDebouncedChange(debounceValue)
  }, [debounceValue])

  const inputOnChange = e => {
    onChange(e.target.value)
    setValue(e.target.value)
  }

  return (
    <Input
      value={value}
      className={cn(styles.inputSearch__input, className)}
      labelClassName={cn(styles.inputSearch__label, labelClassName)}
      fieldClassName={cn(styles.inputSearch__field, fieldClassName)}
      onChange={inputOnChange}
      {...props}
    >
      <MagnifyingGlass className={cn(styles.inputSearch__icon, iconClassName)} />
    </Input>
  )
}
