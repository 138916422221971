import React from 'react'

import { Button, Card, InformationMessage, Arrow } from '@mmb/ui-components'

import { useSaisie } from '../Contexts/SaisieContext'
import { EmprunteursButtons, GammeButtons } from '../Buttons'
import { SelectAgency } from './SelectAgency'
import { usePreSaisie } from './PreSaisie.hook'

import styles from './PreSaisie.module.css'
import { SaisiePropsUI } from '../../model'

export function PreSaisie() {
  const { saisieUI, updateSaisie } = useSaisie()
  const { props: { gamme, hasCoEmprunteur, agence } } = saisieUI

  const { loading, hasErrorAgence, setAgence, createDossier } = usePreSaisie()
  return (
    <>
      <Card className={styles.preSaisie__card}>
        <h3 className={styles.preSaisie__subtitle}>Vous souhaitez créer un dossier pour</h3>
        <EmprunteursButtons
          hasCoEmprunteur={hasCoEmprunteur}
          setHasCoEmprunteur={newHasCoEmprunteur => updateSaisie({ hasCoEmprunteur: newHasCoEmprunteur } as SaisiePropsUI)}
        />
        <p>et</p>
        <GammeButtons gamme={gamme} setGamme={newGamme => { updateSaisie({ gamme: newGamme } as SaisiePropsUI) }} />
        <SelectAgency
          agence={agence}
          setAgence={setAgence}
          hasError={hasErrorAgence}
        />
        <InformationMessage className={styles.preSaisie__message} contentClassName={styles.preSaisie__content}>
          Assurez-vous d'avoir sélectionné correctement l'agence associée à ce dossier
          et que votre client est propriétaire d'un bien immobilier à usage d'habitation en nom propre.
        </InformationMessage>
      </Card>

      <Button
        type="button"
        className={styles.preSaisie__button}
        rightIcon={Arrow}
        loading={loading}
        onClick={createDossier}
        disabled={loading}
      >
        Commencer
      </Button>
    </>
  )
}
