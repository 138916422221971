import React, { FunctionComponent, useMemo } from 'react'

import { InputMontant } from '@partenaire/frontend-ui'

import styles from './EncoursTotal.module.css'
import { EncoursUI } from '../../../model'

interface EncoursTotalProps {
  encoursUI: EncoursUI
}

export const EncoursTotal: FunctionComponent<EncoursTotalProps> = ({ encoursUI }) => {
  const total = useMemo(() => encoursUI.calculateTotalEncours(), [encoursUI])

  return (
    <div className={styles.encoursTotal__cadre}>
      <p className={styles.encoursTotal__titre}>Encours à racheter</p>
      <div className={styles.encoursTotal__totaux}>
        <InputMontant
          name="encours.totalCrdRepris"
          label="Total CRD"
          aria-label="Total CRD - Encours à racheter"
          className={styles.encoursTotal__input}
          fieldClassName={styles.encoursTotal__field}
          labelClassName={styles.encoursTotal__label}
          readOnly
          value={total.totalCrdRepris}
          type="number"
          unit="€"
        />
      </div>
      <div className={styles.encoursTotal__totaux}>
        <InputMontant
          name="encours.totalEcheancesReprises"
          label="Total échéances"
          aria-label="Total échéances - Encours à racheter"
          className={styles.encoursTotal__input}
          fieldClassName={styles.encoursTotal__field}
          labelClassName={styles.encoursTotal__label}
          readOnly
          value={total.totalEcheancesReprises}
          type="number"
          unit="€"
        />
      </div>
      <p className={styles.encoursTotal__titre}>Encours à conserver</p>
      <div className={styles.encoursTotal__totaux}>
        <InputMontant
          name="encours.totalCrdConserves"
          label="Total CRD"
          aria-label="Total CRD - Encours à conserver"
          className={styles.encoursTotal__input}
          fieldClassName={styles.encoursTotal__field}
          labelClassName={styles.encoursTotal__label}
          readOnly
          value={total.totalCrdConserves}
          type="number"
          unit="€"
        />
      </div>
      <div className={styles.encoursTotal__totaux}>
        <InputMontant
          name="encours.totalEcheancesConserves"
          label="Total échéances"
          aria-label="Total échéances - Encours à conserver"
          className={styles.encoursTotal__input}
          fieldClassName={styles.encoursTotal__field}
          labelClassName={styles.encoursTotal__label}
          readOnly
          value={total.totalEcheancesConserves}
          type="number"
          unit="€"
        />
      </div>
    </div>)
}
