import { CATEGORIES_PIECE } from '../../utils'
import { CategoriePieceUI, DocumentCategorie } from './CategorieUI.model'
import { DocumentUI } from './DocumentUI.model'

export interface DocumentsAndCategoriePieceUI {
  categoriePiece: CategoriePieceUI
  documents: DocumentUI[]
}

export class DocumentsUI {
  public readonly documents: DocumentUI[]

  private static readonly sortedCategories: DocumentCategorie[] = CATEGORIES_PIECE.map(categorie => categorie.value)

  constructor(documents: DocumentUI[]) {
    this.documents = DocumentsUI.sort(documents)
  }

  public findDocumentByCategorie(documentCategorie: DocumentCategorie): DocumentUI[] {
    return this.documents.filter(({ props: { categorie } }) => categorie === documentCategorie)
  }

  public static getDownloadDocumentURL(backendUrl: string, dossierId: string, documentId: string) {
    return `${backendUrl}/partenaire/dossiers/${dossierId}/documents/${documentId}`
  }

  public static sort(documents: DocumentUI[]): DocumentUI[] {
    return DocumentsUI.sortedCategories
      .map(categorie => documents.filter(rawDocument => rawDocument.props.categorie === categorie))
      .flatMap(p => p)
      .filter(d => d)
      .map(d => d!!)
  }

  public static getCategorieDisplayable(documentCategorie: DocumentCategorie): CategoriePieceUI {
    return CATEGORIES_PIECE.find(categorieDisplayable => categorieDisplayable.value === documentCategorie)!
  }
}
