import React, { useEffect } from 'react'
import { func } from 'prop-types'
import { Spinner } from '@mmb/ui-components'
import { useCaptcha } from './Captcha.hooks'

Captcha.propTypes = {
  onValidation: func.isRequired,
}

export function Captcha({ onValidation }) {
  const captchaLoaded = useCaptcha()

  useEffect(() => {
    if (captchaLoaded) {
      window.grecaptcha.render('captcha', {
        sitekey: window.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY,
        callback: onValidation,
      })
    }
  }, [captchaLoaded])

  return captchaLoaded ? <div id="captcha" /> : <Spinner secondary />
}
