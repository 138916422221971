import React from 'react'
import { string, object, bool } from 'prop-types'
import { concatString } from '@core/common'
import classnames from 'classnames'

import { Section } from '@mmb/ui-components'

import styles from './DetailGestionDePret.module.css'

DetailGestionDePret.propTypes = {
  gamme: string,
  notaire: object,
  jourPrelevement: string,
  dateDeblocage: string,
  isAnticipe: bool,
}

DetailGestionDePret.defaultProps = {
  gamme: 'SECURED',
  notaire: undefined,
  jourPrelevement: undefined,
  dateDeblocage: undefined,
  isAnticipe: undefined,
}

export function DetailGestionDePret({ gamme, jourPrelevement, notaire, dateDeblocage, isAnticipe }) {
  const nomNotaire = notaire && concatString(notaire.prenom, notaire.nom)(' ')
  const adresse = notaire?.adresse
  const adresseNotaire = adresse && concatString(adresse.numero, adresse.voie, adresse.complement)(' ')
  const villeNotaire = adresse && concatString(adresse.codePostal, adresse.ville)(' ')
  const deblocage = isAnticipe ? `Déblocage anticipé à partir du ${dateDeblocage}` : `Déblocage à partir du ${dateDeblocage}`
  const showDeblocage = gamme === 'UNSECURED' && dateDeblocage
  return (
    <div className={styles.detailGestionDePret}>
      <Section
        title="Date de prélèvement"
        className={styles.detailGestionDePret__section}
      >
        <div
          className={classnames(styles.detailGestionDePret__content,
            jourPrelevement ? styles.detailGestionDePret__bold : styles.detailGestionDePret__italic,
            styles.detailGestionDePret__center)}
          aria-label="Jour de prélèvement"
        >
          {jourPrelevement ? `Le ${jourPrelevement} de chaque mois` : 'Non communiqué'}
        </div>
      </Section>
      {gamme === 'SECURED' && (
        <Section
          title="Notaire réalisateur"
          className={styles.detailGestionDePret__section}
        >
          <div className={classnames(styles.detailGestionDePret__content, styles.detailGestionDePret__notaire)} aria-label="Notaire">
            <span
              className={classnames(styles.detailGestionDePret__block,
                nomNotaire && styles.detailGestionDePret__padl,
                nomNotaire ? styles.detailGestionDePret__bold : styles.detailGestionDePret__italic)}
            >
              {nomNotaire ?? 'Non communiqué'}
            </span>
            <span className={classnames(styles.detailGestionDePret__block, styles.detailGestionDePret__padl)}>{nomNotaire && adresseNotaire}</span>
            <span className={classnames(styles.detailGestionDePret__block, styles.detailGestionDePret__padl)}>{nomNotaire && villeNotaire}</span>
          </div>
        </Section>
      )}
      {showDeblocage && (
        <Section
          title="Déblocage"
          className={styles.detailGestionDePret__section}
        >
          <div className={classnames(styles.detailGestionDePret__content, styles.detailGestionDePret__notaire)} aria-label="Date de déblocage">
            <span
              className={classnames(styles.detailGestionDePret__block, styles.detailGestionDePret__padl, styles.detailGestionDePret__bold)}
            >
              {deblocage}
            </span>
          </div>
        </Section>
      )}
    </div>
  )
}
