import React from 'react'
import { number } from 'prop-types'
import cn from 'classnames'

import styles from './Taeg.module.css'

Taeg.propTypes = {
  value: number.isRequired,
  compare: number.isRequired,
}

export function Taeg({ value, compare }) {
  const isNegative = value > compare

  return (
    <div
      className={cn(
        { [styles.taeg]: !isNegative },
        { [styles.taeg__negative]: isNegative },
      )}
    >
      {Number.parseFloat(value).toFixed(2)} %
    </div>
  )
}
