import React, { useContext } from 'react'
import { string, func } from 'prop-types'

import { modalContext } from '@core/partenaire-ui'
import { Warning } from '@mmb/ui-components'

import { Modal } from '../Modal'

import { useDisablePersonne } from './Delete.hooks'

import styles from './Delete.module.css'

Delete.propTypes = {
  email: string.isRequired,
  personneId: string.isRequired,
  onClose: func,
}

Delete.defaultProps = {
  onClose: () => {},
}

export function Delete({ email, personneId, onClose }) {
  const { setOpen } = useContext(modalContext)
  const disablePersonne = useDisablePersonne()

  const addressEmail = <span className={styles.delete__strong}>{email}</span>

  return (
    <Modal
      header={(
        <header className={styles.delete__header}>
          <h3 className={styles.delete__title}>
            Suppression d'un utilisateur
          </h3>
          <Warning className={styles.delete__iconWarning} />
        </header>
      )}
      onValidate={() => {
        onClose()
        setOpen(false)
        disablePersonne(personneId)
      }}
      submitLabel="Supprimer"
      onClose={onClose}
    >
      <div className={styles.delete__content}>
        <p>L'utilisateur n'aura plus accès à son portail.<br />Son portefeuille de dossier restera accessible en son nom.</p>
        <p>Êtes-vous sûr de vouloir supprimer l'utilisateur {addressEmail}&nbsp;?</p>
      </div>
    </Modal>
  )
}
