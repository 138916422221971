import React from 'react'
import { bool, node, string } from 'prop-types'
import {
  Chevron,
  Shield,
  Section,
  PostMortem,
  MyMoneyBank,
} from '@mmb/ui-components'
import { PORTAIL_CGP } from '@core/common'
import { Link } from '@core/routing'

import styles from './ProtectionDonnees.module.css'

ProtectionDonnees.propTypes = {
  className: string,
  noText: bool,
  children: node,
}

ProtectionDonnees.defaultProps = {
  className: '',
  noText: false,
  children: null,
}

const ShieldAndChevron = () => (
  <>
    <Shield inline className={styles.protectionDonnees__shield} />
    En savoir plus
    <Chevron inline className={styles.protectionDonnees__chevron} />
  </>
)

export function ProtectionDonnees({ className, noText, children }) {
  const currentPortal = window.GATSBY_PORTAL
  return (
    <Section className={className} title="Protection de mes données personnelles">
      {!noText && (
        <p className={styles.protectionDonnees__text}>
          Vous disposez d'un droit d'accès, d'interrogation, de rectification et d'effacement des informations
          vous concernant, de limitation du traitement, d'un droit à la portabilité des données, ainsi que d'un
          droit d'opposition pour motif légitime et d'un droit d'opposition à la prospection commerciale. Vous
          disposez également d'un droit de formuler des directives spécifiques et générales concernant la
          conservation, l'effacement et la communication de vos données <PostMortem />. La communication de directives
          spécifiques <PostMortem /> et l'exercice des droits s'effectuent auprès du département Consommateurs de <MyMoneyBank />,
          API24D2, 1, rue du Château de l'Eraudière 44311 Nantes Cedex 3,
          accompagné d'une copie de votre pièce d'identité.
        </p>
      )}

      {children}

      <hr className={styles.protectionDonnees__separator} />
      {currentPortal === PORTAIL_CGP ? (
        <Link
          className={styles.protectionDonnees__link}
          to="/protection-des-donnees-unauth/"
        >
          Politique de protection de mes données personnelles
          <ShieldAndChevron />
        </Link>
      ) : (
        <Link to="/protection-des-donnees/" className={styles.protectionDonnees__link}>
          <ShieldAndChevron />
        </Link>
      )}
    </Section>
  )
}
