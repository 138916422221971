/* eslint-disable react/require-default-props */
import React, { useState, useEffect, FunctionComponent } from 'react'
import classnames from 'classnames'

import { Cross, Switch, Input, Checkbox } from '@mmb/ui-components'
import { Select, Tooltip } from '@core/partenaire-ui'
import { WarningPopIn, InputMontant } from '@partenaire/frontend-ui'

import styles from './Pret.module.css'

import { Source } from './Source'
import { clearEmpty, parseMontant, limit, hasErrorForName as hasError } from '../../../utils'
import { PretPlaceholder } from './PretPlaceholder/PretPlaceholder'
import { ErrorMessage, SaisieErrorMessages } from '../../SaisieErrorMessages'
import { PretFormUI, ValidationFieldError, getSelectedNaturePret, getSelectedRegimePret, NATURE_PRET_OPTIONS, REGIMES_PRET } from '../../../model'
import { DATE_MM_YYYY } from '../../../model/format'
import { Finalite, getDefaultRegime } from '../../../model/encours/PretFormUI'
import { SaisieWarningMessages } from '../../SaisieWarningMessages'

interface PretProps {
  index: number
  pret: PretFormUI
  removePret: (pretToDelete: PretFormUI, index: number) => void
  setPret: (pretFormUI: PretFormUI) => void
  setRepris: (repris: boolean) => void
  canBeInApprocheInvestisseur?: boolean
  loading?: boolean
  errors?: ValidationFieldError[]
  warningMessages?: ErrorMessage[]
  errorMessages?: ErrorMessage[]
  showErrors: boolean
}

export const Pret: FunctionComponent<PretProps> = ({
  index, pret, loading = false, removePret, setPret, setRepris, errors = [], errorMessages = [], warningMessages = [], showErrors, canBeInApprocheInvestisseur,
}) => {
  const numero = index + 1
  const titre = `Prêt ${numero}`
  const { paliers = [] } = pret
  const [firstPalier] = paliers
  const [palierPrincipale, setPalierPincipale] = useState(firstPalier)
  const hasErrorForName = name => hasError(name, errors, showErrors)
  useEffect(() => {
    function fillPretWithPalier() {
      const filteredPalier = palierPrincipale?.montantEcheance == null ? [] : [palierPrincipale]
      setPret({ ...pret, paliers: filteredPalier })
    }
    fillPretWithPalier()
  }, [palierPrincipale])

  if (loading) return (<PretPlaceholder titre={titre} numero={numero} />)

  const labelTaux = (
    <div className={styles.pret__labelTooltip}>
      Taux (optionnel)
      <Tooltip
        title="Information sur le taux"
        content={(<p>Il s'agit du taux d'intérêt indiqué dans l'offre de prêt hors frais et hors assurance éventuelle.</p>)}
      />
    </div>)

  return (
    <div aria-label={`Rachat du prêt n° ${numero}`}>
      <div className={styles.pret__titre}>
        {titre}
        <div className={styles.pret__titreDroit}>
          <Switch
            name={`rachatpret_${numero}`}
            label="A racheter"
            checked={Boolean(pret?.repris)}
            onClick={() => setRepris(!pret?.repris)}
            leftLabel
          />
          <WarningPopIn
            onConfirm={() => removePret(pret, index)}
            label={`Suppression du prêt n° ${numero}`}
            title="Suppression d'un prêt"
            warningLine="Êtes-vous sûr de vouloir supprimer l'encours ?"
            warningLineBold="Cette action est définitive."
          >
            <Cross className={styles.pret__cross} />
          </WarningPopIn>
        </div>
      </div>
      <div className={styles.pret__contour}>
        <div className={styles.pret__upperBlocks}>
          <div className={styles.pret__left}>
            <Select
              name={`nature.pret.${numero}`}
              options={NATURE_PRET_OPTIONS}
              label="Nature du prêt"
              onChange={({ value }) => setPret({ ...pret, nature: value, regime: getDefaultRegime(value) })}
              className={styles.pret__nature}
              labelClassName={classnames(styles.pret__label, styles.pret__labelNature)}
              value={getSelectedNaturePret(pret?.nature)}
              aria-invalid={hasErrorForName('nature')}
              hasError={hasErrorForName('nature')}
            />
            <div className={styles.pret__ligne}>
              <Input
                name={`encours.prets.${index}.nomPreteur`}
                label="Nom du prêteur"
                aria-label={`Nom du prêteur du prêt n° ${numero}`}
                onChange={({ target }) => setPret({ ...pret, nomPreteur: target.value })}
                className={styles.pret__nom}
                labelClassName={styles.pret__label}
                value={clearEmpty(pret?.nomPreteur)}
                aria-invalid={hasErrorForName('nomPreteur')}
                hasError={hasErrorForName('nomPreteur')}
              />
              <Select
                name={`Régime du prêt n° ${numero}`}
                options={REGIMES_PRET}
                label="Régime"
                onChange={({ value }) => setPret({ ...pret, regime: value })}
                className={styles.pret__nature}
                labelClassName={classnames(styles.pret__label, styles.pret__labelNature)}
                value={getSelectedRegimePret(pret?.regime)}
                hasError={hasErrorForName('regime')}
              />
            </div>
            <div className={styles.pret__ligne}>
              <Input
                name={`encours.prets.${index}.debut`}
                label="Début"
                aria-label={`Date de début du prêt n° ${numero}`}
                onChange={({ target }) => setPret({ ...pret, debut: target.value })}
                className={styles.pret__inputBas}
                labelClassName={styles.pret__label}
                pattern="[0-9]{4}-[0-9]{2}"
                type="text"
                placeholder={`${DATE_MM_YYYY.toLowerCase()}`}
                value={clearEmpty(pret?.debut)}
                aria-invalid={hasErrorForName('debut')}
                hasError={hasErrorForName('debut')}
              />
              <div className={styles.pret__fin}>
                <Input
                  name={`encours.prets.${index}.fin`}
                  label="Fin"
                  aria-label={`Date de fin du prêt n° ${numero}`}
                  onChange={({ target }) => setPret({ ...pret, fin: target.value })}
                  className={styles.pret__inputBasCenter}
                  labelClassName={styles.pret__label}
                  pattern="[0-9]{4}-[0-9]{2}"
                  type="text"
                  placeholder={`${DATE_MM_YYYY.toLowerCase()}`}
                  value={clearEmpty(pret?.fin)}
                  aria-invalid={hasErrorForName('fin')}
                  hasError={hasErrorForName('fin')}
                />
                <Source
                  source={pret?.sourceFin}
                  className={styles.pret__sourceGauche}
                  setSource={source => setPret({ ...pret, sourceFin: source })}
                  hasError={hasErrorForName('sourceFin')}
                  suffixes={{ label: 'date de fin', name: `sourceFin.pret_${index}` }}
                  aria-label={`Nature de l'information date de fin du prêt ${numero}`}
                  label={`Date de fin du prêt ${numero}`}
                />
              </div>
            </div>
            <Input
              name={`encours.prets.${index}.reference`}
              label="Référence"
              aria-label={`Référence du prêt n° ${numero}`}
              onChange={({ target }) => setPret({ ...pret, reference: target.value })}
              className={styles.pret__ref}
              labelClassName={styles.pret__label}
              value={clearEmpty(pret?.reference)}
              aria-invalid={hasErrorForName('reference')}
              hasError={hasErrorForName('reference')}
            />
            <div className={styles.pret__ligneBas}>
              <Checkbox
                label="Présence d'un préavis"
                name={`Présence d'un préavis pour prêt n° ${numero}`}
                className={styles.pret__labelCheck}
                checked={Boolean(pret?.preavis)}
                onChange={() => setPret({ ...pret, preavis: pret?.preavis ? undefined : 1 })}
              />
              {Boolean(pret?.preavis) && (
                <>
                  <Input
                    name={`encours.prets.${index}.preavis.duree`}
                    label="Durée en jours"
                    aria-label={`Durée du préavis du prêt n° ${numero}`}
                    onChange={({ target }) => setPret({ ...pret, preavis: Number(target.value) })}
                    className={styles.pret__debutPalier}
                    labelClassName={styles.pret__labelHorizontal}
                    placeholder="nombre de jours"
                    type="number"
                    value={clearEmpty(pret?.preavis)}
                    disabled={!pret?.preavis}
                    aria-invalid={hasErrorForName('preavis')}
                    hasError={hasErrorForName('preavis')}
                    min="0"
                  />
                  <Source
                    className={styles.pret__sourceBottom}
                    source={pret?.sourcePreavis}
                    setSource={source => setPret({ ...pret, sourcePreavis: source })}
                    hasError={hasErrorForName('sourcePreavis')}
                    aria-label={`Nature de l'information préavis du prêt ${numero}`}
                    label={`Préavis du prêt ${numero}`}
                    suffixes={{ label: 'préavis', name: `sourcePreavis.pret_${index}` }}
                  />
                </>)}
            </div>
            <div className={styles.pret__ligneBas}>
              <Checkbox
                label="Approche investisseur"
                name={`Approche investisseur pour prêt n° ${numero}`}
                className={classnames(styles.pret__labelCheck, { [styles.pret__labelGrey]: !canBeInApprocheInvestisseur })}
                checked={Boolean(pret?.finalite)}
                onChange={() => setPret({ ...pret, finalite: pret?.finalite ? undefined : Finalite.INVESTISSEMENT_LOCATIF })}
                aria-invalid={hasErrorForName('finalite')}
              />
              <Tooltip
                title="Information sur l'approche investisseur"
                content={(<p>Ce prêt doit porter sur un bien locatif pour appliquer le calcul du bilan foncier.</p>)}
                wrapperClassName={styles.pret__label__tooltip}
              />
            </div>
          </div>
          <div className={styles.pret__right}>
            <div className={styles.pret__ligne}>
              <InputMontant
                name={`encours.prets.${index}.crd`}
                label="CRD total"
                aria-label={`CRD total du prêt n° ${numero}`}
                onChange={({ target }) => setPret({ ...pret, montantCrd: parseMontant(target.value) })}
                className={styles.pret__input}
                labelClassName={styles.pret__label}
                value={clearEmpty(pret?.montantCrd)}
                type="number"
                aria-invalid={hasErrorForName('montantCrd')}
                hasError={hasErrorForName('montantCrd')}
                unit="€"
                unitClassName={styles.pret__unite}
              />
            </div>
            <div className={styles.pret__ligne}>
              <InputMontant
                name={`encours.prets.${index}.montantCapitalInitial`}
                label="Capital initial (optionnel)"
                aria-label={`Capital initial du prêt n° ${numero}`}
                onChange={({ target }) => setPret({ ...pret, montantCapitalInitial: parseMontant(target.value) })}
                className={styles.pret__input}
                labelClassName={styles.pret__label}
                value={clearEmpty(pret?.montantCapitalInitial)}
                type="number"
                aria-invalid={hasErrorForName('montantCapitalInitial')}
                hasError={hasErrorForName('montantCapitalInitial')}
                unit="€"
                unitClassName={styles.pret__unite}
              />
            </div>
            <div className={styles.pret__ligne}>
              <InputMontant
                name={`encours.prets.${index}.montantIra`}
                label="IRA (optionnel)"
                aria-label={`IRA du prêt n° ${numero}`}
                onChange={({ target }) => setPret({ ...pret, montantIra: parseMontant(target.value) })}
                className={styles.pret__input}
                labelClassName={styles.pret__label}
                value={clearEmpty(pret?.montantIra)}
                type="number"
                aria-invalid={hasErrorForName('montantIra')}
                hasError={hasErrorForName('montantIra')}
                unit="€"
                unitClassName={styles.pret__unite}
              />
              <Source
                className={styles.pret__sourceDroit}
                source={pret?.sourceIra}
                setSource={source => setPret({ ...pret, sourceIra: source })}
                hasError={hasErrorForName('sourceIra')}
                aria-label={`Nature de l'information IRA du prêt ${numero}`}
                label={`IRA du prêt ${numero}`}
                suffixes={{ label: 'ira', name: `sourceIra.pret_${index}` }}
              />
            </div>
            <div className={styles.pret__ligne}>
              <InputMontant
                name={`encours.prets.${index}.taux`}
                label={labelTaux}
                aria-label={`Taux du prêt n° ${numero}`}
                onChange={({ target }) => setPret({ ...pret, taux: limit(parseMontant(target.value), 100) })}
                className={styles.pret__input}
                labelClassName={styles.pret__label}
                value={clearEmpty(pret?.taux)}
                type="number"
                max={100}
                min={0}
                aria-invalid={hasErrorForName('taux')}
                hasError={hasErrorForName('taux')}
                unit="%"
                unitClassName={styles.pret__unite}
              />
              <Source
                className={styles.pret__sourceDroit}
                source={pret?.sourceTaux}
                setSource={source => setPret({ ...pret, sourceTaux: source })}
                hasError={hasErrorForName('sourceTaux')}
                aria-label={`Nature de l'information taux du prêt ${numero}`}
                label={`Taux du prêt ${numero}`}
                suffixes={{ label: 'taux', name: `sourceTaux.pret_${index}` }}
              />
            </div>
            <div className={styles.pret__ligne}>
              <InputMontant
                name={`encours.prets.${index}.palier1`}
                label="Echéance"
                aria-label={`Echéance du prêt n° ${numero}`}
                onChange={({ target }) => setPalierPincipale({ ...palierPrincipale, montantEcheance: parseMontant(target.value) })}
                className={styles.pret__input}
                labelClassName={styles.pret__label}
                value={clearEmpty(palierPrincipale?.montantEcheance)}
                type="number"
                aria-invalid={hasErrorForName('montantEcheance')}
                hasError={hasErrorForName('montantEcheance')}
                unit="€"
                unitClassName={styles.pret__unite}
              />
            </div>
          </div>
        </div>
        <SaisieWarningMessages className={styles.pret__warnings} warnings={warningMessages} />
        <SaisieErrorMessages errors={errorMessages} />
      </div>
    </div>
  )
}

