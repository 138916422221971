import { useEffect, useState } from 'react'
import { gql, useApolloClient } from '@core/graphql'
import { get } from 'lodash'
import { useDebounce } from 'use-debounce'

export const GET_ADRESSES = gql`
 query GET_ADRESSE($query: String!, $type: String) {
   referentiel_suggestionAdresse(query: $query, type: $type) {
     id
     label
     name
     housenumber
     street
     postcode
     city
   }
 }
`

export function useAdresses(type) {
  const [street, setStreet] = useState('')
  const [adresses, setAdresses] = useState([])
  const [debouncedStreet] = useDebounce(street, 500)
  const client = useApolloClient()

  useEffect(() => {
    if (debouncedStreet && debouncedStreet.length > 5) {
      client.query({
        query: GET_ADRESSES,
        variables: { query: debouncedStreet, type: type || '' },
      })
        .then(res => {
          const adressesMap = get(res, 'data.referentiel_suggestionAdresse', [])
          setAdresses(adressesMap)
        })
    }
  }, [debouncedStreet])

  return {
    setStreet,
    adresses,
  }
}
