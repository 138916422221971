import React from 'react'
import { Card, Section } from '@mmb/ui-components'
import { useIzanamiFeatureByName, useIzanamiConfigurationByName } from '@core/ui'
import { GAMMES } from '@partenaire/frontend-common'
import { MeteoIndisponible } from './MeteoIndisponible'
import { Gamme } from './Gamme'

import styles from './DelaisPriseCharge.module.css'

export function DelaisPriseCharge() {
  const { configuration } = useIzanamiConfigurationByName('contenu-meteo')
  const { active } = useIzanamiFeatureByName('meteo')
  const titleDate = active && configuration ? `au ${configuration.date}` : ''
  const title = `Délais de prise en charge ${titleDate}`

  const renderGammes = (subtitles, { DCS, DCU }) => (
    <>
      <div className={styles.delais__gammes}>
        {subtitles.map((subtitle, num) => (
          <h3 className={styles.delais__sectionTitle}>
            {num === 0 && 'Dossiers '} {subtitle}
          </h3>
        ))}
      </div>
      <div className={styles.delais__gammes}>
        <Gamme gamme={GAMMES.SECURED} delais={DCS} />
        <Gamme gamme={GAMMES.UNSECURED} delais={DCU} />
      </div>
    </>
  )

  return (
    <Card className={styles.delais__card}>
      <Section title={title} titleClassName={styles.delais__sectionTitle}>
        {active && configuration ? (
          <>
            {renderGammes(['Standards'], configuration.standard)}
            {renderGammes(['LCC', 'Priority'], configuration.priority)}
          </>) :
          <MeteoIndisponible />}
      </Section>
    </Card>
  )
}

