export const EXPERTISES = [{
  value: 'EXPERTISE_IMMOBILIERE',
  label: 'Expertise immobilière',
}, {
  value: 'EXPERTISE_MAC',
  label: 'Estimation MAC',
}, {
  value: 'EXPERTISE_NOTAIRE',
  label: 'Estimation notaire',
}, {
  value: 'AVIS_DE_VALEUR',
  label: 'Avis de valeur',
}, {
  value: 'CONTRE_EXPERTISE',
  label: 'Contre-expertise',
}]

export const CATEGORIES = {
  DEMANDE_DE_PRET: 'Demande de prêt',
  EN_COURS: 'Encours',
  IDENTITE: 'Identité',
  PATRIMOINE_IMMOBILIER: 'Patrimoine',
  REVENUS: 'Revenus',
  ASSURANCE: 'Assurance',
  COMPTES_BANCAIRES: 'Comptes bancaires',
  AUTRE: 'Autre',
}
