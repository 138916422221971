import React from 'react'
import { func, string } from 'prop-types'
import cn from 'classnames'
import { isFunction } from 'lodash'

import { Cross, Input } from '@mmb/ui-components'

import styles from './InputAge.module.css'

InputAge.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  numero: string,
  className: string,
  labelClassName: string,
  inputClassName: string,
  handleRemove: func,
}

InputAge.defaultProps = {
  numero: '',
  className: '',
  labelClassName: '',
  inputClassName: '',
  handleRemove: null,
}

export function InputAge({ name, onChange, numero, className, labelClassName, inputClassName, handleRemove, ...props }) {
  const label = `Enfant ${numero}`
  return (
    <div className={cn(styles.inputAge, className)}>
      {isFunction(handleRemove) && (
        <button
          aria-label={`Retirer l'enfant ${numero} de la liste`}
          className={styles.inputAge__buttonCross}
          type="button"
          onClick={evt => {
            evt.preventDefault()
            evt.stopPropagation()
            handleRemove(evt)
          }}
        >
          <Cross />
        </button>
      )}
      <label
        htmlFor={name}
        className={cn(styles.inputAge__label, labelClassName)}
      >
        {label}
      </label>
      <div className={styles.inputAge__row}>
        <Input
          {...props}
          type="number"
          min="0"
          step="1"
          className={cn(styles.inputAge__input, inputClassName)}
          labelClassName={cn(styles.inputAge__label, labelClassName)}
          name={name}
          label={label}
          hideLabel
          onChange={onChange}
          placeholder="Âge"
        />
        an(s)
      </div>
    </div>
  )
}
