import React from 'react'
import classnames from 'classnames'
import { shape, string, func, oneOfType, node } from 'prop-types'
import { ErrorMessage } from '@partenaire/frontend-ui'

import styles from './SaisieErrorMessage.module.css'

SaisieErrorMessage.propTypes = {
  error: shape({
    title: string,
    message: oneOfType([string, node]),
    link: shape({
      action: func,
      message: string,
    }),
  }).isRequired,
  className: string,
}

SaisieErrorMessage.defaultProps = {
  className: '',
}

export function SaisieErrorMessage({ error, className }) {
  return (
    <ErrorMessage className={classnames(styles.saisieErrorMessage__error, className)}>
      <strong className={styles.saisieErrorMessage__title}>
        {error?.title}
      </strong>
      <span className={styles.saisieErrorMessage__message}>
        {error?.message}
      </span>
      <a onClick={error?.link?.action} className={styles.saisieErrorMessage__link}>{error?.link?.message}</a>
    </ErrorMessage>
  )
}
