import React, { FunctionComponent } from 'react'

import { Family } from '@mmb/ui-components'

import styles from './AssuranceNotDeclarable.module.css'

export const AssuranceNotDeclarable: FunctionComponent = () => (
  <div className={styles.assuranceNotDeclarable}>
    <h2 className={styles.assuranceNotDeclarable_title}>L'adhésion n'est pas encore disponible</h2>
    <Family className={styles.assuranceNotDeclarable_icon} />
    <span className={styles.assuranceNotDeclarable_text}>L'adhésion à l'assurance groupe sera accessible à l'accord du dossier.</span>
  </div>
)
