import { SignatureType } from './offre'

export enum DossierStatut {
    SAISIE = 'SAISIE',
    TELETRANSMIS = 'TELETRANSMIS',
    ENVOYE_EN_BANQUE = 'ENVOYE_EN_BANQUE',
    ARRIVE = 'ARRIVE',
    PREFILTRAGE = 'PREFILTRAGE',
    NON_RECEVABLE = 'NON_RECEVABLE',
    REFUSE_PREFILTRAGE = 'REFUSE_PREFILTRAGE',
    SANS_SUITE_PREFILTRAGE = 'SANS_SUITE_PREFILTRAGE',
    A_PRENDRE_EN_CHARGE = 'A_PRENDRE_EN_CHARGE',
    PRIS_EN_CHARGE = 'PRIS_EN_CHARGE',
    EN_ENQUETE = 'EN_ENQUETE',
    AVIS_FAVORABLE_SOUS_RESERVE ='AVIS_FAVORABLE_SOUS_RESERVE',
    ACCORDE = 'ACCORDE',
    REFUSE = 'REFUSE',
    SANS_SUITE = 'SANS_SUITE',
    OFFRES_EDITEES = 'OFFRES_EDITEES',
    EDITE_EN_ATTENTE_ACCEPTATION = 'EDITE_EN_ATTENTE_ACCEPTATION',
    ACCEPTATION_CONFORME = 'ACCEPTATION_CONFORME',
    ACCEPTATION_NON_CONFORME = 'ACCEPTATION_NON_CONFORME',
    DEBLOQUE = 'DEBLOQUE',
    ENTREE_EN_CA = 'ENTREE_EN_CA'
}

export enum Role {
    EMPRUNTEUR = 'EMPRUNTEUR',
    COEMPRUNTEUR = 'COEMPRUNTEUR',
}

export enum Gamme {
    SECURED = 'SECURED',
    UNSECURED = 'UNSECURED',
}

export enum CommunicationType {
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
  DOMICILE = 'DOMICILE',
  AUTRE = 'AUTRE'
}

export interface Communication {
  id?: string
  value?: string
  type?: CommunicationType
}

export interface Emprunteur {
    id?: string
    identite: {
        nom?: string
        prenom?: string
        businessId?: string
    }
    communications?: Communication[]
    role: Role
}

export interface DossierResume {
    id: string
    reference?: string
    nomProduit?: string
    emprunteurs: Emprunteur[]
    montantFinance?: number
    statut?: DossierStatut
    dateStatut?: string
    gamme?: Gamme
    agence?: string
    isPriority?: boolean
    signatureType?: SignatureType
}

export interface DossiersPage {
    pageSize: number
    totalPages: number
    pageNumber: number
    counters: {
        total: number
    }
    elements: DossierResume[]
}

export const DOSSIER_STATUTS_LIBELLES: {
    label: string
    hasSignatureElectronique?: boolean
    isSecured?: boolean
    value: string
  }[] = [
    {
      label: 'En cours de saisie',
      value: DossierStatut.SAISIE,
    }, {
      label: 'En cours de transmission',
      value: DossierStatut.TELETRANSMIS,
    }, {
      label: 'Réceptionné',
      value: DossierStatut.ARRIVE,
    }, {
      label: 'Préfiltrage',
      value: DossierStatut.PREFILTRAGE,
    }, {
      label: 'Non Recevable',
      value: DossierStatut.NON_RECEVABLE,
    }, {
      label: 'Refusé',
      value: DossierStatut.REFUSE_PREFILTRAGE,
    }, {
      label: 'Sans suite',
      value: DossierStatut.SANS_SUITE_PREFILTRAGE,
    }, {
      label: 'A prendre en charge',
      value: DossierStatut.A_PRENDRE_EN_CHARGE,
    }, {
      label: 'Pris en charge',
      value: DossierStatut.PRIS_EN_CHARGE,
    }, {
      label: 'Attente compléments',
      value: DossierStatut.EN_ENQUETE,
    }, {
      label: 'Avis favorable sous réserve',
      value: DossierStatut.AVIS_FAVORABLE_SOUS_RESERVE,
    }, {
      label: 'Accordé',
      value: DossierStatut.ACCORDE,
    }, {
      label: 'Refusé',
      value: DossierStatut.REFUSE,
    }, {
      label: 'Sans suite',
      value: DossierStatut.SANS_SUITE,
    }, {
      label: 'Offre en cours',
      value: DossierStatut.OFFRES_EDITEES,
    }, {
      label: 'Offre envoyée',
      value: DossierStatut.EDITE_EN_ATTENTE_ACCEPTATION,
    }, {
      label: 'Offre envoyée en version papier',
      value: DossierStatut.EDITE_EN_ATTENTE_ACCEPTATION,
      hasSignatureElectronique: false,
    }, {
      label: 'Offre envoyée en version electronique',
      value: DossierStatut.EDITE_EN_ATTENTE_ACCEPTATION,
      hasSignatureElectronique: true,
    }, {
      label: 'Actes envoyés au notaire',
      isSecured: true,
      value: DossierStatut.ACCEPTATION_CONFORME,
    }, {
      label: 'Offre reçue conforme',
      isSecured: false,
      value: DossierStatut.ACCEPTATION_CONFORME,
    }, {
      label: 'Offre reçue non conforme',
      value: DossierStatut.ACCEPTATION_NON_CONFORME,
    }, {
      label: 'Fonds débloqués',
      value: DossierStatut.DEBLOQUE,
    }, {
      label: 'Entrée en CA',
      value: DossierStatut.ENTREE_EN_CA,
    }, {
      label: 'Statut inconnu',
      value: 'AUTRE',
    }]

interface DossierOption {
  label: string
  value: DossierStatut | ''
}

export const DOSSIER_STATUTS_OPTIONS: DossierOption[] = [{
  label: 'Tous les statuts',
  value: '',
}, {
  label: 'En cours de saisie',
  value: DossierStatut.SAISIE,
}, {
  label: 'En cours de transmission',
  value: DossierStatut.TELETRANSMIS,
}, {
  label: 'Réceptionné',
  value: DossierStatut.ARRIVE,
}, {
  label: 'Préfiltrage',
  value: DossierStatut.PREFILTRAGE,
}, {
  label: 'Non Recevable',
  value: DossierStatut.NON_RECEVABLE,
}, {
  label: 'Refusé',
  value: DossierStatut.REFUSE_PREFILTRAGE,
}, {
  label: 'Sans suite',
  value: DossierStatut.SANS_SUITE_PREFILTRAGE,
}, {
  label: 'A prendre en charge',
  value: DossierStatut.A_PRENDRE_EN_CHARGE,
}, {
  label: 'Pris en charge',
  value: DossierStatut.PRIS_EN_CHARGE,
}, {
  label: 'Attente compléments',
  value: DossierStatut.EN_ENQUETE,
}, {
  label: 'Avis favorable sous réserve',
  value: DossierStatut.AVIS_FAVORABLE_SOUS_RESERVE,
}, {
  label: 'Accordé',
  value: DossierStatut.ACCORDE,
}, {
  label: 'Refusé',
  value: DossierStatut.REFUSE,
}, {
  label: 'Sans suite',
  value: DossierStatut.SANS_SUITE,
}, {
  label: 'Offre en cours',
  value: DossierStatut.OFFRES_EDITEES,
}, {
  label: 'Offre envoyée',
  value: DossierStatut.EDITE_EN_ATTENTE_ACCEPTATION,
}, {
  label: 'Offre reçue conforme / Actes envoyés au notaire',
  value: DossierStatut.ACCEPTATION_CONFORME,
}, {
  label: 'Offre reçue non conforme',
  value: DossierStatut.ACCEPTATION_NON_CONFORME,
}, {
  label: 'Fonds débloqués',
  value: DossierStatut.DEBLOQUE,
}, {
  label: 'Entrée en CA',
  value: DossierStatut.ENTREE_EN_CA,
}]


export const getDossierStatutOption = (statut: DossierStatut | '') : DossierOption => DOSSIER_STATUTS_OPTIONS.find(({ value }) => value === statut)!

export enum ColumnName {
  REFERENCE = 'REFERENCE',
  NOM = 'NOM',
  PRENOM = 'PRENOM',
  MONTANT = 'MONTANT',
  STATUT = 'STATUT',
  DATE_STATUT = 'DATE_STATUT',
  PRIORITY = 'PRIORITY'
}
