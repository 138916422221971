import {
  validateActivites,
  validateCharges,
  validateCommunications,
  validateDemande,
  validatePatrimoines,
  validateAssurances,
  validateRevenus,
  validateSituationBancaire,
  validateSituationFamiliale,
} from '../../utils'
import { validateIdentites } from '../../utils/ValidationErrors/identiteValidationErrors'
import { validateAdresses } from '../../utils/ValidationErrors/adresseValidationErrors'
import { getEncoursWarnings, validateEncours } from '../../model/encours/validateEncours'
import { ValidationErrors } from '../../model'
import { EntryFormState, EntryFormStateName } from './EntryFormState'
import { getAssuranceWarnings } from '../../utils/ValidationErrors/assuranceValidationErrors'

type validateFunction = (...args: any[]) => ValidationErrors

const VALIDATORS = new Map<EntryFormStateName, validateFunction>([
  [EntryFormStateName.ACTIVITE, validateActivites],
  [EntryFormStateName.CHARGE, validateCharges],
  [EntryFormStateName.SITUATION_FAMILIALE, validateSituationFamiliale],
  [EntryFormStateName.REVENU, validateRevenus],
  [EntryFormStateName.IDENTITE, validateIdentites],
  [EntryFormStateName.COMMUNICATIONS, validateCommunications],
  [EntryFormStateName.ADRESSE, validateAdresses],
  [EntryFormStateName.PATRIMOINE, validatePatrimoines],
  [EntryFormStateName.EN_COURS, validateEncours],
  [EntryFormStateName.MONTAGE, validateDemande],
  [EntryFormStateName.SITUATION_BANCAIRE, validateSituationBancaire],
  [EntryFormStateName.ASSURANCE, validateAssurances],
])

const WARNING_CHECKERS = new Map<EntryFormStateName, any>([
  [EntryFormStateName.EN_COURS, getEncoursWarnings],
  [EntryFormStateName.ASSURANCE, getAssuranceWarnings],
])

export const findValidatorByFormStateName = (name: EntryFormStateName) => VALIDATORS.get(name)
export const findWarningCheckerByFormStateName = (name: EntryFormStateName) => WARNING_CHECKERS.has(name) ? WARNING_CHECKERS.get(name) : () => ({})
export { EntryFormState, EntryFormStateName }
