import React, { FunctionComponent } from 'react'

import styles from '../HistoriquePieces.module.css'

interface HistoriqueTitleProps {
  date: string
}

export const HistoriqueTitle: FunctionComponent<HistoriqueTitleProps> = ({ date }) => (
  <div aria-label="historique transfert title" className={styles.historique__title}>
    <span> Envoyé Le {date} </span>
  </div>
)
