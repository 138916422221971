import { PositionUI } from '../support/PositionUI'
import { ActiviteFormUI } from './ActiviteFormUI'

export class ActiviteListUI extends PositionUI<ActiviteFormUI> {
  constructor(public readonly personneId?: string, public readonly list: ActiviteFormUI[] = []) {
    super(list)
  }

  public clone() {
    return new ActiviteListUI(this.personneId, [...this.list])
  }

  public getActivitesPrincipales() {
    return this.list.filter(({ isPrincipale }) => isPrincipale)
  }
}
