import React, { createContext, useMemo } from 'react'
import { bool, node, object, shape } from 'prop-types'
import { mergeWith } from 'lodash'

export const EMPTY_OBJECT = {}
export const DEFAULT_VALUES = { features: EMPTY_OBJECT, configurations: EMPTY_OBJECT, firstFetchPending: false }
export const IzanamiFeatureContext = createContext(DEFAULT_VALUES)

IzanamiFeatureContextProvider.propTypes = {
  children: node.isRequired,
  features: object,
  featuresFallback: object,
  firstFetchPending: bool,
  serverDatas: shape({ features: object }),
}

IzanamiFeatureContextProvider.defaultProps = {
  features: EMPTY_OBJECT,
  featuresFallback: EMPTY_OBJECT,
  serverDatas: EMPTY_OBJECT,
  firstFetchPending: false,
}

export function IzanamiFeatureContextProvider({ children, features: propsFeatures, featuresFallback, firstFetchPending, serverDatas }) {
  const features = serverDatas.features || propsFeatures
  const configurations = serverDatas.configurations

  const contextState = useMemo(() => (
    {
      features: buildMergedFeatures(featuresFallback, features),
      configurations,
      firstFetchPending,
    }), [featuresFallback, features, configurations, firstFetchPending])

  return (
    <IzanamiFeatureContext.Provider value={contextState}>
      {children}
    </IzanamiFeatureContext.Provider>
  )
}


function buildMergedFeatures(fallBackFeatures, apiFeatures) {
  return mergeWith({}, fallBackFeatures, apiFeatures, arrayConcatStrategy)
}

function arrayConcatStrategy(objValue, srcValue) {
  if (Array.isArray(objValue) && Array.isArray(srcValue)) {
    return [...srcValue, ...objValue]
  }
  return undefined
}
