import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import { Montant, HourglassEmpty, Section } from '@mmb/ui-components'

import { useExpertise, useExpertiseInformation } from './DetailExpertise.hooks'
import { DetailExpertisePlaceholder } from './DetailExpertisePlaceholder'

import styles from './DetailExpertise.module.css'

DetailExpertise.propTypes = {
  id: string.isRequired,
}

export function DetailExpertise({ id }) {
  const { loading, type, dateLancement, estimation, ratio } = useExpertise(id)
  const { loading: loadingInformation, isExpertiseEditable } = useExpertiseInformation(id)

  if (loading || loadingInformation) return <DetailExpertisePlaceholder />
  if (!type) return null

  return (
    <Section
      title="Valeur immobilière"
      className={styles.detailExpertise}
    >
      {isExpertiseEditable && (
      <div>Cette estimation est susceptible d'être modifiée</div>)}
      <div className={styles.detailExpertise__content} aria-label="Expertise immobilière">
        <div className={styles.detailExpertise__cell} aria-label="type">
          <div className={styles.detailExpertise__head}>Type</div>
          <div className={styles.detailExpertise__value}>{type}</div>
        </div>

        <div className={styles.detailExpertise__cell} aria-label="date de lancement">
          <div className={styles.detailExpertise__head}>Date de lancement</div>
          <div className={styles.detailExpertise__value}>{dateLancement}</div>
        </div>

        <div className={styles.detailExpertise__cell} aria-label="montant estimé">
          <div className={styles.detailExpertise__head}>Montant estimé</div>
          <div className={classNames({ [styles.detailExpertise__hourglassContainer]: !estimation })}>
            {!estimation ? (
              <div className={styles.detailExpertise__status}>
                <HourglassEmpty className={styles.detailExpertise__hourglass} />
                En attente
              </div>
            ) :
              (<Montant
                value={estimation}
                className={styles.detailExpertise__montant}
                wholeClassName={styles.detailExpertise__whole}
                separatorClassName={styles.detailExpertise__separator}
                decimalClassName={styles.detailExpertise__decimal}
              />)}
          </div>
        </div>

        <div className={styles.detailExpertise__cell} aria-label="RH">
          <div className={styles.detailExpertise__head}>
            <div data-testid="RH title 1">RH</div>
            <div data-testid="RH title 2">{ratio.maxValue}</div>
          </div>
          <div className={classNames(styles.detailExpertise__value, { [styles.detailExpertise__error]: ratio.isExceedingMaxValue })}>
            {ratio.value}
          </div>
        </div>
      </div>
    </Section>
  )
}
