import React, { useContext } from 'react'
import { string, node, bool } from 'prop-types'
import cn from 'classnames'

import { HalfBullet } from '@mmb/ui-components'

import { menuContext } from '../Menu'

import styles from './MenuLink.module.css'

MenuLink.propTypes = {
  to: string.isRequired,
  children: node.isRequired,
  className: string,
  isActive: bool,
}

MenuLink.defaultProps = {
  className: '',
  isActive: false,
}

export function MenuLink({ to, children, className, isActive }) {
  const { linkComponent: LinkComponent } = useContext(menuContext)
  return (
    <li className={cn(styles.menu__entry, className)}>
      <LinkComponent to={to} className={cn(styles.menu__link, { [styles.menu__entryActive]: isActive })}>
        {children}
        <HalfBullet className={styles.menu__icon} />
      </LinkComponent>
    </li>
  )
}
