import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@core/graphql'
import { FetchFunction, useFetch, useHasPermission, useUtilisateurMultiPartenaire } from '@core/partenaire-common'
import { PermissionScope } from '@core/common'
import { DossierStatut } from '@partenaire/common'
import { DocumentsUI, LiasseUI, TransfertsUI, LiasseUIProps, LiasseUICallback, DocumentUI, PiecesUI, TransfertUI } from '../../model/'
import { toDocumentUI, toPieceUI, toTransfertUI } from './mappers'
import { LiasseQuery } from './Liasse.query'
import { DocumentCategorie } from '../../model/transfert/CategorieUI.model'

const doUploadDocument = (fetch: FetchFunction, dossierId: string) => (file: File, categorie: DocumentCategorie): Promise<Response> => {
  const url = `/partenaire/dossiers/${dossierId}/documents`
  const body = new FormData()
  body.append('file', file)
  body.append('categorie', categorie.toString())
  const options = { method: 'POST', body }
  return fetch(url, options)
}

export const useGetDossierInfo = () => {
  const { id } = useParams()!
  const [dossierId, setDossierId] = useState<string>()
  const [statut, setStatut] = useState<DossierStatut>()
  const { data, loading } = useQuery(LiasseQuery.GET_DOSSIER_ID, { fetchPolicy: 'no-cache', variables: { id } })
  useEffect(() => {
    if (!loading && !dossierId) {
      setDossierId(data?.partenaire_dossierTransmis?.id)
      setStatut(data?.partenaire_dossierTransmis?.statut)
    }
  }, [loading])
  return { dossierId, id: id as string, loading, statut }
}

const useDossierTransfertLiasse = (dossierId?: string) => {
  const [doTransfertLiasse] = useMutation(LiasseQuery.TRANSFERT_LIASSE)
  const dossierTransfertLiasse = async () => doTransfertLiasse({ variables: { id: dossierId } })
  return dossierTransfertLiasse
}

const useDocuments = (dossierId?: string) => {
  const [documentsUI, setDocumentsUI] = useState<DocumentsUI>()
  const { data, loading: loadingGetDocuments, refetch } = useQuery(
    LiasseQuery.GET_DOCUMENTS,
    { fetchPolicy: 'network-only', skip: !dossierId, variables: { id: dossierId } })
  const [loading, setLoading] = useState(true)
  const fetch = useFetch()
  const uploadDocument = doUploadDocument(fetch, dossierId!)
  const [deleteDocumentByDossierId] = useMutation(LiasseQuery.DELETE_DOCUMENT)
  const refetchDocuments = async (_dossierId: string): Promise<DocumentUI[]> => refetch({ id: _dossierId })
    .then(response => (response.data?.partenaire_getLiasseDocuments || []).map(toDocumentUI))

  const deleteDocument = async (documentId: string): Promise<void> => {
    await deleteDocumentByDossierId({
      variables: {
        id: dossierId,
        documentId,
      },
    })
  }
  useEffect(() => {
    if (!loadingGetDocuments && dossierId) {
      setDocumentsUI(new DocumentsUI((data?.partenaire_getLiasseDocuments || []).map(toDocumentUI)))
      setLoading(false)
    }
  }, [loadingGetDocuments])
  return { documentsUI, uploadDocument, loading, refetchDocuments, deleteDocument }
}

const useCommentaire = () => {
  const [setCommentaireByDossierId] = useMutation(LiasseQuery.SET_COMMENTAIRE)
  const updateCommentaire = async (dossierId, commentaire: string): Promise<void> => {
    await setCommentaireByDossierId({
      variables: {
        id: dossierId,
        commentaire,
      },
    })
  }
  return { updateCommentaire }
}

const useTransfertLiasse = (dossierId?: string) => {
  const { updateCommentaire } = useCommentaire()
  const dossierTransfertLiasse = useDossierTransfertLiasse(dossierId)
  return { dossierTransfertLiasse, updateCommentaire }
}

const useGetLiasseInfo = (dossierId?: string) => {
  const { data, loading, refetch: refetchDocuments } = useQuery(
    LiasseQuery.GET_LIASSE, { fetchPolicy: 'network-only', skip: !dossierId, variables: { id: dossierId } })
  const { dateCreation, commentaire } = data?.partenaire_getLiasseInfo || {}
  return { dateCreation, commentaire, refetchDocuments, loading }
}

const useGetPieces = (dossierId?: string) => {
  const { data, loading } = useQuery(
    LiasseQuery.GET_PIECES_VALIDE, { fetchPolicy: 'network-only', skip: !dossierId, variables: { id: dossierId } })
  const [loadingPiecesValide, setLoadingPiecesValide] = useState(true)
  const [piecesUI, setPiecesUI] = useState<PiecesUI>()
  useEffect(() => {
    if (!loading && dossierId) {
      setPiecesUI(new PiecesUI((data?.partenaire_getPieces || []).map(toPieceUI)))
      setLoadingPiecesValide(false)
    }
  }, [loading])
  return { piecesUI, loading: loadingPiecesValide }
}

export const useGetTransferts = () => {
  useUtilisateurMultiPartenaire()
  const { dossierId, id, loading: loadingGetDossier, statut } = useGetDossierInfo()
  const [loading, setLoading] = useState(true)
  const { data, loading: loadingGetTransferts, refetch } = useQuery(LiasseQuery.GET_TRANSFERTS,
    { fetchPolicy: 'network-only', skip: !dossierId, variables: { id: dossierId } })

  const hasPermissionEnvoiPieces = useHasPermission(PermissionScope.ENVOI_PIECES_WRITE)
  const [transfertsUI, setTransfertsUI] = useState<TransfertsUI>()
  const refechTransferts = async (_dossierId: string): Promise<TransfertUI[]> => refetch({ id: _dossierId })
    .then(response => (response.data?.partenaire_getTransferts || []).map(t => toTransfertUI(t, _dossierId)))

  const loadingQuery = loadingGetDossier || loadingGetTransferts
  useEffect(() => { transfertsUI?.withUpdateState(setTransfertsUI) }, [transfertsUI])
  useEffect(() => {
    if (!loadingQuery && !transfertsUI && dossierId) {
      const listTransfertUI = (data?.partenaire_getTransferts || []).map(t => toTransfertUI(t, dossierId))
      setTransfertsUI(new TransfertsUI(dossierId, listTransfertUI, { refechTransferts }, statut))
      setLoading(false)
    }
  }, [loadingQuery])
  return { id, transfertsUI, loading, hasPermissionEnvoiPieces }
}

export const useLiasse = () => {
  const navigate = useNavigate()
  const [liasseUI, setLiasseUI] = useState<LiasseUI>()
  const [loading, setLoading] = useState(true)
  const { dossierId, id, loading: loadingGetDossierInfo, statut: dossierStatut } = useGetDossierInfo()
  const { dateCreation, commentaire, loading: loadingGetLiasse } = useGetLiasseInfo(dossierId)
  const { documentsUI, uploadDocument, deleteDocument, refetchDocuments, loading: loadingGetDocuments } = useDocuments(dossierId)
  const { dossierTransfertLiasse, updateCommentaire } = useTransfertLiasse(dossierId)
  const { piecesUI, loading: loadingGetPiecesValide } = useGetPieces(dossierId)
  const loadingQuery = loadingGetDossierInfo || loadingGetLiasse || loadingGetDocuments || loadingGetPiecesValide
  useEffect(() => {
    if (!loadingQuery && !liasseUI && dossierId) {
      const props: LiasseUIProps = { dossierId, id, dateCreation, commentaire, documentsUI, piecesUI, dossierStatut }
      const callback: LiasseUICallback = {
        uploadDocument,
        deleteDocument,
        refetchDocuments,
        updateCommentaire,
        dossierTransfertLiasse,
      }
      setLiasseUI(new LiasseUI(props, callback, navigate))
      setLoading(false)
    }
  }, [loadingQuery])
  useEffect(() => { liasseUI?.withUpdateState(setLiasseUI) }, [liasseUI])
  return { loading, liasseUI }
}
