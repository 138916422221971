export class EncoursTotal {
  constructor(
    public readonly totalCrdRepris: number,
    public readonly totalEcheancesReprises: number,
    public readonly totalCrdConserves: number,
    public readonly totalEcheancesConserves: number) {}

  public static defaultEncoursTotal(): EncoursTotal {
    return new EncoursTotal(0, 0, 0, 0)
  }
}
