import { cloneDeep, get, isFunction, set } from 'lodash'

export function changeField(form, setForm, name, value) {
  const cloneForm = cloneDeep(form)
  set(cloneForm, name, value)
  if (isFunction(setForm)) {
    setForm(cloneForm)
    return value
  }
  return null
}

export function changeFloatInput({ target }, form, setForm) {
  const field = target.getAttribute('name')
  const value = parseFloat(target.value)
  changeField(form, setForm, field, value)
}

export function changeInput({ target }, form, setForm) {
  const field = target.getAttribute('name')
  const value = target.value
  changeField(form, setForm, field, value)
}

export const addEntity = (form, setForm, name) => {
  const entities = get(form, name, [])
  entities.push({})
  changeField(form, setForm, name, entities)
}

export const removeEntity = (form, setForm, name, index) => {
  const entities = get(form, name, [])
  entities.splice(index, 1)
  changeField(form, setForm, name, entities)
}
