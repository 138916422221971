import { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'

export const useDelayedBlur = fn => {
  if (!fn || (typeof (fn) !== 'function')) {
    throw new Error('Missing or invalid argument passed it should  be of Function')
  }
  const [isFormFocused, setFormFocused] = useState(false)
  const [debounceFormFocus] = useDebounce(isFormFocused, 500)

  const [isFormBlured, setFormBlured] = useState(false)

  useEffect(() => {
    if (!debounceFormFocus && isFormBlured) {
      fn()
    }
  }, [debounceFormFocus, isFormBlured])

  return {
    onBlur: () => {
      setFormFocused(false)
      setFormBlured(true)
    },
    onFocus: () => {
      setFormFocused(true)
      setFormBlured(false)
    },
  }
}

export const useDelayedFormBlur = formRef => {
  const result = useDelayedBlur(
    () => formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true })),
  )
  return result
}
