import { object } from 'prop-types'
import React from 'react'
import { Chevron } from '@mmb/ui-components'
import { useUtilisateurContext } from '@core/partenaire-common'
import styles from './Cabinet.module.css'


Cabinet.propTypes = {
  cabinet: object.isRequired,
}

/**
 * Composant représentant une ligne de sélection des cabinets
 */
export function Cabinet({ cabinet }) {
  const { setIdCabinetTravail } = useUtilisateurContext()
  return (
    <button className={styles.pm} onClick={() => setIdCabinetTravail(cabinet.id)}>
      <div>{cabinet.libelle}</div>
      <Chevron inline className={styles.pm__chevron} />
    </button>
  )
}
