import React from 'react'

import { NotFound } from '../NotFound'
import { UnauthenticatedLayout } from '../UnauthenticatedLayout'

export function Page404() {
  return (
    <UnauthenticatedLayout>
      <NotFound />
    </UnauthenticatedLayout>
  )
}
