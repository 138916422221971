import React from 'react'

import { Card } from '@mmb/ui-components'
import { Layout } from '@partenaire/frontend-ui'

import styles from './conditions-generales.module.css'

/* eslint-disable max-len */
function ConditionsGenerales() {
  return (
    <Layout className={styles.conditionsGenerales}>
      <h2 className={styles.conditionsGenerales__title}>Conditions Générales</h2>
      <Card>
        <span role="img" aria-label="Page en construction">🚧</span>
      </Card>
    </Layout>
  )
}

export default ConditionsGenerales
