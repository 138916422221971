import React from 'react'
import { Modal } from '../Modal'
import { modalContext } from '../../contexts/modalContext'
import { SelectionCabinetForm } from './SelectionCabinetForm'
import { useSelectionCabinetModale } from './SelectionCabinetModale.hook'

/**
 * Modale de sélection du cabinet pour le contexte
 */
export function SelectionCabinetModale() {
  const { isOpen, setOpen, onClose } = useSelectionCabinetModale()
  return (
    <modalContext.Provider value={{ isOpen, setOpen }}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <SelectionCabinetForm />
      </Modal>
    </modalContext.Provider>
  )
}
