import React from 'react'
import { ScrollList, SortableColumnHeader } from '@core/partenaire-ui'
import { ColumnName } from '@partenaire/common'

import { Column, useDossiers } from './List.hooks'
import { ListItem } from './ListItem'

import styles from './List.module.css'
import { SortArrow } from './SortArrow'


export function List() {
  const {
    loading,
    dossiers,
    deleteDossier,
    deleteLoading,
    hasNextPage,
    totalCount,
    fetchMore,
    listRef,
    sort,
    isDescendingOrder,
    toggleSort,
  } = useDossiers()

  const columns : Column[] = [{
    name: ColumnName.NOM,
    label: 'Nom',
  }, {
    name: ColumnName.PRENOM,
    label: 'Prénom',
  }, {
    name: ColumnName.REFERENCE,
    label: 'Référence',
  }, {
    name: ColumnName.MONTANT,
    label: 'Montant emprunté',
  }, {
    name: ColumnName.STATUT,
    label: 'Statut',
  }, {
    name: ColumnName.DATE_STATUT,
    label: 'Depuis le',
  }, {
    name: ColumnName.PRIORITY,
  }]

  const headers = (
    <div role="row" className={styles.list__header}>
      {columns.map(column => (
        <SortableColumnHeader
          key={column.name}
          title={column.label ?? ''}
          titlePosition="left"
          sorted={sort === column.name}
          descendingOrder={isDescendingOrder}
          onClick={column.label ? toggleSort(column.name) : undefined}
          chevron={SortArrow}
          chevronStyle={styles.list__sortArrow}
        />
      ))}
    </div>)

  const content = dossiers?.map((dossier, index) => (
    <ListItem
      key={dossier.id}
      dossier={dossier}
      index={index}
      deleteDossier={deleteDossier}
      deleteLoading={deleteLoading}
    />
  ))

  return (
    <ScrollList
      id="listeDossiers"
      rowClassName={styles.list__line}
      listref={listRef}
      hasNextPage={hasNextPage || loading}
      totalCount={totalCount}
      loadMore={fetchMore}
      customScrollbar
      header={headers}
      ariaLabel="Prospects"
    >
      {content}
    </ScrollList>
  )
}
