import { useState, useEffect, useCallback } from 'react'
import { useDebounce } from 'use-debounce'
import { suggestionsCommune } from '@partenaire/frontend-common'

const communeFormat = ({ nom, codePostal, codeInsee }) => ({ label: `${codePostal} ${nom}`, value: codePostal, codeInsee })


function useGetCommunes(codePostalVille: string) {
  const [communes, setCommunes] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>()

  const getCommunes = useCallback(
    async () => {
      setLoading(true)
      try {
        const codePostalOrVille = toCommunesQuery(codePostalVille)
        if (codePostalOrVille) {
          const communesData = await suggestionsCommune(codePostalOrVille)
          setCommunes(communesData)
          setError(undefined)
          return { communes: communesData }
        }
        setCommunes(undefined)
        setError(undefined)
        return {}
      } catch (err) {
        const newError = { message: err }
        setError(newError)
        return { error: newError }
      } finally {
        setLoading(false)
      }
    }, [codePostalVille])
  return { getCommunes, communes, loading, error }
}

export function toCommunesQuery(codePostalVille?: string) : { ville?: string, codePostal?: string } | undefined {
  const currentValue = (codePostalVille || '').trim()

  if (currentValue && currentValue.length > 2) {
    const [code] = currentValue.split(' ')
    const hasOnlyDigit = (/\d+/g).test(currentValue)
    const isCodePostal = code.length === 5 && hasOnlyDigit
    return {
      ville: !isCodePostal ? currentValue : undefined,
      codePostal: isCodePostal ? code : undefined,
    }
  }
  return undefined
}

export function useCommunesOptions(valueFormat: any = communeFormat) {
  const [codePostalVille, setCodePostalVille] = useState('')
  const [citiesOptions, setCitiesOptions] = useState<any>()
  const [debounceValue] = useDebounce(codePostalVille, 500)
  const { getCommunes, communes, loading } = useGetCommunes(debounceValue)

  useEffect(() => {
    getCommunes()
  }, [debounceValue])

  useEffect(() => {
    if (communes) {
      const options = communes.map(({ nom, codePostal, codeInsee }) => valueFormat({ nom, codePostal, codeInsee }))
      setCitiesOptions(options)
    } else {
      setCitiesOptions(undefined)
    }
  }, [JSON.stringify(communes)])


  return {
    setCodePostalVille,
    citiesOptions,
    loading,
  }
}
