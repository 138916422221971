import { useContext, useEffect, useRef } from 'react'
import { gql, usePaginationQuery, DO_NOT_CHECK_CACHE } from '@core/graphql'
import { searchContext } from '@core/partenaire-common'

export const USE_SEARCH_PERSONNES_QUERY = 'getPersonnes'
export const SEARCH_PERSONNES_QUERY_NAME = 'partenaire_searchPersonnes'
const SEARCH_PERSONNES_QUERY = gql`
query ${USE_SEARCH_PERSONNES_QUERY}($search: String, $statuts: [partenaire_StatutPersonne!], $pageRequest: partenaire_PageRequest!) {
  ${SEARCH_PERSONNES_QUERY_NAME}(search: $search, statuts: $statuts, pageRequest: $pageRequest) {
    pageNumber
    pageSize
    counters {
      total
    }
    elements {
      id
      email
      nom
      prenom
      cabinets {
        id
        role
        libelle
      }
      agences
      statut
    }
  }
}
`

const useSearchPersonnes = (sortBy, search, statuts) => {
  const pageRequest = { pageNumber: 1, pageSize: 20, sortBy }
  return usePaginationQuery(
    SEARCH_PERSONNES_QUERY,
    SEARCH_PERSONNES_QUERY_NAME,
    { variables: { search, statuts, pageRequest }, ...DO_NOT_CHECK_CACHE })
}

export function usePersonnes(updateTotalCount) {
  // récupération du filtre textuel de la recherche, et du tri demandé
  const { search: [search], statuts: [statuts], sort: [sort] } = useContext(searchContext)

  const { loading, elements, counters, fetchMore } = useSearchPersonnes(sort, search, statuts)
  const listRef = useRef(null)
  const criteriasChangedRef = useRef(false)

  useEffect(() => {
    criteriasChangedRef.current = true
  }, [sort, search, statuts])

  useEffect(() => {
    if (!loading) {
      updateTotalCount(counters.total)
    }
    if (listRef.current && !loading && criteriasChangedRef.current) {
      listRef.current.resetloadMoreItemsCache(true)
      listRef.current.scrollTo(0)
      criteriasChangedRef.current = false
    }
  }, [loading])

  const hasNextPage = Boolean(elements && counters && elements.length < counters.total)
  return { displayedPersonnes: elements || [], counters, fetchMore, listRef, hasNextPage, sort }
}
