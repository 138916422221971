import React from 'react'
import classnames from 'classnames'
import { TextRow } from '@mmb/ui-components'

import styles from './DossierItemPlaceholder.module.css'

export function DossierItemPlaceholder() {
  return (
    <div role="row" className={classnames(styles.dossierItemPlaceholder__line, styles.dossierItemPlaceholder__gamme)}>
      <span role="cell" aria-label="nom">
        <TextRow width="12rem" />
      </span>
      <span role="cell" aria-label="prenom">
        <TextRow width="12rem" />
      </span>
      <span role="cell" aria-label="montant emprunté">
        <TextRow width="12rem" />
      </span>
      <span role="cell" aria-label="statut">
        <TextRow width="12rem" />
      </span>
      <span role="cell" aria-label="date dernier statut">
        <TextRow width="12rem" />
      </span>
    </div>
  )
}
