import { DATE_DD_MM_YYYY_FR, getAgeFromBirthDate, isValidDate, NATIONALITES_OPTIONS, reformatDate } from '@core/common'
import { isEmpty } from 'lodash'
import { DATE_YYYY_MM_DD } from '../../model/format'
import { buildValidationError } from '../../model'

export const VALIDATION_ERRORS_MESSAGES = {
  DATE_UNDER_18_YEARS_OLD: {
    title: 'Date de naissance',
    message: 'Votre client doit avoir plus de 18 ans.',
  },
  DATE_OVER_EQUAL_76_YEARS_OLD: {
    title: 'Montage',
    message: 'La durée minimale du produit n\'est pas respectée.',
  },
  DATE_OVER_EQUAL_77_YEARS_OLD: {
    title: 'Montage',
    message: 'La durée minimale du produit n\'est pas respectée.',
  },
  MISSING_VALUE: {
    title: 'Information requise',
    message: 'Veuillez renseigner la situation personnelle du/des client(s).',
  },
  DATE_FORMAT_INVALID: {
    title: 'Date de naissance',
    message: `Veuillez renseigner une date au format ${DATE_DD_MM_YYYY_FR.toLowerCase()}`,
  },
}

const validateDate = (date, field, avecGarantie) => {
  if (isEmpty(date)) {
    return buildValidationError(field, 'MISSING_VALUE')
  }
  if (!isValidDate(date, DATE_DD_MM_YYYY_FR)) {
    return buildValidationError(field, 'DATE_FORMAT_INVALID')
  }
  const age = getAgeFromBirthDate(reformatDate(date, DATE_DD_MM_YYYY_FR, DATE_YYYY_MM_DD))
  if (age < 18) {
    return buildValidationError(field, 'DATE_UNDER_18_YEARS_OLD')
  }
  if (avecGarantie && age >= 76) {
    return buildValidationError(field, 'DATE_OVER_EQUAL_76_YEARS_OLD')
  }
  if (!avecGarantie && age >= 77) {
    return buildValidationError(field, 'DATE_OVER_EQUAL_77_YEARS_OLD')
  }
  return null
}

export const validateIdentite = (identite, gamme?) => {
  // naissance.nom is optionnal field
  const errors: any[] = []
  const civilites = ['MR', 'MME']
  if (!civilites.includes(identite?.civilite)) {
    errors.push(buildValidationError('civilite', 'MISSING_VALUE'))
  }

  if (isEmpty(identite?.nom)) {
    errors.push(buildValidationError('nom', 'MISSING_VALUE'))
  }

  if (isEmpty(identite?.prenom)) {
    errors.push(buildValidationError('prenom', 'MISSING_VALUE'))
  }

  if (identite?.civilite === 'MME' && isEmpty(identite?.naissance?.nom)) {
    errors.push(buildValidationError('naissance.nom', 'MISSING_VALUE'))
  }

  if (identite?.nom?.match?.(/\d/)) {
    errors.push(buildValidationError('nom', 'WRONG_VALUE'))
  }

  if (identite?.prenom?.match?.(/\d/)) {
    errors.push(buildValidationError('prenom', 'WRONG_VALUE'))
  }

  if (identite?.naissance?.nom?.match?.(/\d/)) {
    errors.push(buildValidationError('naissance.nom', 'WRONG_VALUE'))
  }

  if (identite?.naissance?.ville?.match?.(/\d/)) {
    errors.push(buildValidationError('naissance.ville', 'WRONG_VALUE'))
  }

  // rule on nationalite ?
  if (isEmpty(identite?.nationalite)) {
    errors.push(buildValidationError('nationalite', 'MISSING_VALUE'))
  } else if (!isValidNationalite(identite?.nationalite)) {
    errors.push(buildValidationError('nationalite', 'WRONG_VALUE'))
  }

  // rules on birth date
  const dateError = validateDate(identite?.naissance?.date, 'naissance.date', gamme === 'SECURED')
  switch (dateError?.message) {
    case 'MISSING_VALUE':
    case 'DATE_FORMAT_INVALID':
      errors.push(dateError)
      break
    default: if (dateError) errors.push(dateError)
  }

  if (isEmpty(identite?.naissance?.ville)) errors.push(buildValidationError('naissance.ville', 'MISSING_VALUE'))
  if (isEmpty(identite?.naissance?.departement)) errors.push(buildValidationError('naissance.departement', 'MISSING_VALUE'))
  return errors
}

export const validateIdentites = ({ emprunteur, coEmprunteur, hasCoEmprunteur, gamme }) => {
  const emprunteurErrors = validateIdentite(emprunteur, gamme)
  const coEmprunteurErrors = hasCoEmprunteur ? validateIdentite(coEmprunteur, gamme) : []
  const hasErrors = emprunteurErrors.length + coEmprunteurErrors.length > 0
  return {
    globalErrors: [...emprunteurErrors, ...coEmprunteurErrors].map(error => VALIDATION_ERRORS_MESSAGES[error?.message]).filter(error => error),
    emprunteurErrors,
    coEmprunteurErrors,
    hasErrors,
  }
}

function isValidNationalite(nationalite) {
  const hasNationalite = NATIONALITES_OPTIONS.find(option => option.value === nationalite)
  return Boolean(hasNationalite)
}
