import React, { FunctionComponent } from 'react'
import { Placeholder } from '@mmb/ui-components'
import { RubriqueLinkPlaceholder, RubriqueLink } from './RubriqueLink'
import { RubriqueUI } from '../../../model/OutilsUI'

const customPlaceholder = (
  <>
    <RubriqueLinkPlaceholder />
    <RubriqueLinkPlaceholder />
    <RubriqueLinkPlaceholder />
  </>)

interface RubriquesProps {
  rubriques?: RubriqueUI[]
  loading: boolean
}

export const Rubriques: FunctionComponent<RubriquesProps> = ({ rubriques, loading }) => (
  <Placeholder loading={loading} customPlaceholder={customPlaceholder}>
    {rubriques?.map(rubrique => <RubriqueLink rubrique={rubrique} key={rubrique.id} />)}
  </Placeholder>
)

Rubriques.defaultProps = {
  rubriques: [],
}
