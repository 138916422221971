import { fetch as originalFetch } from './fetch'

/** Nom de la clé contenant le refresh token dans le local storage du navigateur */
const LOCAL_STORAGE_KEY = 'refreshToken'

/**
 * Fetch utilisant la mécanique de rafraichissement de token d'authent
 * @param {string} urlOrBackendPath l'url du backend
 * @param {*} options les options de l'appel
 * @param fetch la fonction fetch à utiliser
 * @returns
 */
export const fetchWithRefresh = async (urlOrBackendPath, options = {}, fetch = originalFetch) => {
  const response = await fetch(urlOrBackendPath, options)
  if (!response.ok && response.status === 401) {
    return refreshTokenAndRefetch(urlOrBackendPath, options, fetch)
  }
  return response
}

/**
 * fonction permettant de demander le rafraichissement du token d'authent et exécute à nouveau l'appel originel à la suite
 * @param {string} urlOrBackendPath l'appel ayant causé une demande de refresh
 * @param {*} options les options de l'appel
 * @param fetch la fonction fetch à utiliser
 * @returns un nouvel appel à la demande originelle
 */
export const refreshTokenAndRefetch = async (urlOrBackendPath, options, fetch = originalFetch) => {
  await refreshToken(fetch)
  return fetch(urlOrBackendPath, options)
}

export const removeRefreshToken = () => localStorage.removeItem(LOCAL_STORAGE_KEY)

export const refreshToken = async (fetch = originalFetch) => {
  const storedRefreshToken = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (!storedRefreshToken) {
    return
  }

  const optionsRefresh = {
    method: 'POST',
    body: JSON.stringify({ refreshToken: storedRefreshToken }),
    headers: { 'Content-Type': 'application/json' },
  }
  const responseRefresh = await fetch(`${window.GATSBY_API_URL}/azuread/refresh`, optionsRefresh)
  if (!responseRefresh.ok) {
    removeRefreshToken()
  }
}
