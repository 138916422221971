import React from 'react'
import classnames from 'classnames'

import { Card, formatNumberCurrency } from '@mmb/ui-components'
import { any } from 'prop-types'

import styles from './Body.module.css'

BodyPalierUnsecured.propTypes = {
  variationWithAssurance: any.isRequired,
}
export function BodyPalierUnsecured({ variationWithAssurance }) {
  return (
    <Card
      className={classnames(
        styles.simulation__produit__body__container,
        styles.simlutation__produit__body__space_right,
        styles.simulation__produit__headerContainer,
      )}
    >
      <span> Variation <br /> Avec assurance </span>
      <span
        aria-label="Variation du financement proposé par la simulation avec assurance"
        className={classnames(
          styles.simulation__produit__palier__content,
          styles.simulation__produit__palier__color,
        )}
      >
        {`${variationWithAssurance && formatNumberCurrency({ number: variationWithAssurance })}/mois`}
      </span>
    </Card>
  )
}
