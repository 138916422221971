import React from 'react'
import { bool, array, number, node, func, string } from 'prop-types'
import InfiniteLoading from 'react-simple-infinite-loading'

import styles from './ScrollList.module.css'

ScrollList.propTypes = {
  id: string,
  children: array.isRequired,
  rowClassName: string.isRequired,
  header: node,
  listRef: array,
  hasNextPage: bool,
  totalCount: number,
  loadMore: func,
  customScrollbar: bool,
  ariaLabel: string,
}

ScrollList.defaultProps = {
  id: undefined,
  header: null,
  listRef: undefined,
  hasNextPage: false,
  totalCount: undefined,
  loadMore: undefined,
  customScrollbar: false,
  ariaLabel: '',
}

export function ScrollList({ id, children, header, listRef, hasNextPage, totalCount, loadMore, customScrollbar, rowClassName, ariaLabel }) {
  return (
    <div
      id={id}
      role="table"
      aria-label={`${ariaLabel}s`}
      className={styles.scrollList}
    >
      <div role="rowgroup">
        {header}
      </div>
      <div role="rowgroup" className={styles.scrollList__body}>
        <InfiniteLoading
          ref={listRef}
          hasMoreItems={hasNextPage}
          itemHeight={50}
          itemCount={totalCount}
          loadMoreItems={loadMore}
          customScrollbar={customScrollbar}
          placeholder={hasNextPage && (
            <div
              role="row"
              aria-label={ariaLabel}
              className={rowClassName}
            >
              <span role="cell">Loading...</span>
            </div>
          )}
        >
          {children}
        </InfiniteLoading>
      </div>
    </div>
  )
}
