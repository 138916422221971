import React from 'react'
import { ChangeGammeProps } from '../../components/Gamme'
import { getBasculeHypothecaireLink } from '../gamme'

const MONTAGE_ERROR_MESSAGES = ({ changeGamme, isUpdatingGamme }) => ({
  dureeFinancement: {
    OUT_OF_BOUNDS: {
      title: 'Durée',
      SECURED: 'La durée demandée doit être comprise entre 120 et 300 mois.',
      UNSECURED: 'La durée demandée doit être comprise entre 60 et 180 mois.',
    },
  },
  typeAmortissement: {
    WITHOUT_PALIER_WITH_BAISSE_DE_REVENU: {
      title: 'Type d\'amortissement',
      message:
      'La sélection d’un prêt linéaire au niveau du montage n’est pas compatible avec une baisse de revenu. ' +
      'Merci de modifier votre saisie dans la situation financière.',
    },
    WITH_PALIER_WITHOUT_BAISSE_DE_REVENU: {
      title: 'Type d\'amortissement',
      message:
      'La sélection d’un prêt à palier au niveau du montage n’est pas compatible avec une absence de baisse de revenu. ' +
      'Merci de modifier votre saisie dans la situation financière.',
    },
  },
  apport: {
    UNSECURED: {
      title: 'Apport personnel',
      message:
      'L’apport personnel n’est pas autorisé sur la gamme Sans Garantie, merci de revoir votre plan de financement.',
    },
    SECURED_NEW_TRESO: {
      title: 'Apport personnel',
      message:
      'L’apport personnel n’est pas autorisé pour le produit My New Tréso, merci de revoir votre plan de financement.',
    },
  },
  tresorerie: {
    UNDER_LIMITS: {
      title: 'Trésorerie',
      message: 'Le montant de la trésorerie doit être supérieur ou égal à 1000 €. Merci de revoir le plan de financement.',
    },
    OVER_LIMITS_UNSECURED: {
      title: 'Trésorerie',
      message: (
        <>
          La trésorerie demandée doit être inférieure à 50 000 € et plafonnée à 70 % du montant du prêt. Merci de revoir le plan de financement ou de
          {getBasculeHypothecaireLink(changeGamme, isUpdatingGamme, 'basculer en hypothécaire.')}
        </>),
    },
  },
  travaux: {
    OVER_LIMITS_MY_NEW_TRESO: {
      title: 'Montage',
      message: 'La trésorerie "Travaux" ne doit pas dépasser 75 000 € en My New Tréso.',
    },
    OVER_LIMITS_MSO: {
      title: 'Montage',
      message: 'La trésorerie "Travaux" ne doit pas dépasser 75 000 €.',
    },
  },
})

export function getMontageError(error, gammeProps: ChangeGammeProps) {
  const { field, message } = error
  const messages = MONTAGE_ERROR_MESSAGES(gammeProps)
  return {
    title: messages[field]?.[message]?.title,
    message: messages[field]?.[message]?.message || messages[field]?.[message]?.[gammeProps.gamme],
  }
}
