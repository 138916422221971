import React from 'react'

import { TextRow } from '@mmb/ui-components'

import styles from './BienPlaceholder.module.css'

export function BienPlaceholder() {
  return (
    <div className={styles.bienPlaceholder}>
      <div className={styles.bienPlaceholder__toggles} aria-label="usage.placeholder">
        <TextRow width="12rem" height="3.5rem" className={styles.bienPlaceholder__toggle} />
        <TextRow width="12rem" height="3.5rem" className={styles.bienPlaceholder__toggle} />
        <TextRow width="12rem" height="3.5rem" className={styles.bienPlaceholder__toggle} />
        <TextRow width="12rem" height="3.5rem" className={styles.bienPlaceholder__toggle} />
      </div>
      <div className={styles.bienPlaceholder__toggles} aria-label="natureBien.placeholder">
        <TextRow width="12rem" height="3.5rem" className={styles.bienPlaceholder__toggle} />
        <TextRow width="12rem" height="3.5rem" className={styles.bienPlaceholder__toggle} />
        <TextRow width="12rem" height="3.5rem" className={styles.bienPlaceholder__toggle} />
        <TextRow width="12rem" height="3.5rem" className={styles.bienPlaceholder__toggle} />
      </div>
      <span aria-label="naturePropriete.placeholder">
        <TextRow width="33.5rem" height="3.5rem" className={styles.bienPlaceholder__row} />
      </span>
      <span aria-label="complement.placeholder">
        <TextRow width="33.5rem" height="3.5rem" className={styles.bienPlaceholder__row} />
      </span>
      <span aria-label="numero.placeholder">
        <TextRow width="33.5rem" height="3.5rem" className={styles.bienPlaceholder__row} />
      </span>
      <span aria-label="voie.placeholder">
        <TextRow width="33.5rem" height="3.5rem" className={styles.bienPlaceholder__row} />
      </span>
      <span aria-label="lieuDit.placeholder">
        <TextRow width="33.5rem" height="3.5rem" className={styles.bienPlaceholder__row} />
      </span>
      <span aria-label="codPostal.placeholder">
        <TextRow width="33.5rem" height="3.5rem" className={styles.bienPlaceholder__row} />
      </span>
      <span aria-label="date.placeholder">
        <TextRow width="33.5rem" height="3.5rem" className={styles.bienPlaceholder__row} />
      </span>
      <span aria-label="prix.placeholder">
        <TextRow width="33.5rem" height="3.5rem" className={styles.bienPlaceholder__row} />
      </span>
      <span aria-label="valeur.placeholder">
        <TextRow width="33.5rem" height="3.5rem" className={styles.bienPlaceholder__row} />
      </span>
    </div>
  )
}
