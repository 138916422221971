import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import { Cross } from '@mmb/ui-components'

import styles from './GammeButton.module.css'

interface GammeButtonProps {
  children?: any
  gammeClasseName?: string
  className?: string
  isClicked?: boolean
  testId?: string
  onClose: () => void
  onClick: () => void
}

export const GammeButton: FunctionComponent<GammeButtonProps> = ({ children, className, gammeClasseName, isClicked, onClose, onClick, testId }) => {
  const styleGamme = classNames(
    styles.gammeButton,
    {
      [styles.gammeButtonSelected]: isClicked,
    },
    className,
    gammeClasseName,
  )

  return isClicked ? (
    <div
      className={styleGamme}
    >
      {children}
      <button
        className={styles.gammeButtonClose}
        onClick={onClose}
      >
        <Cross data-testid={`closing-cross-${testId}`} className={styles.gammeButtonCrossIcon} />
      </button>
    </div>
  ) : (
    <button
      className={styleGamme}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

GammeButton.defaultProps = {
  className: '',
  children: undefined,
  isClicked: false,
  gammeClasseName: '',
  testId: undefined,
}
