import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { uniq, uniqBy } from 'lodash'

import { SaisieErrorMessage } from '../SaisieErrorMessage'

import styles from './SaisieErrorMessages.module.css'

import { VALIDATION_ERRORS_MESSAGES as IdentiteValidationErrors } from '../../utils/ValidationErrors/identiteValidationErrors'
import { VALIDATION_ERRORS_MESSAGES as SituationFamilialeValidationErrors } from '../../utils/ValidationErrors/situationFamilialeValidationErrors'

interface ErrorMessageProps {
  errors: ErrorMessage[]
  className?: string
}

export interface ErrorMessage {
  message: any
  title: string
}


export const buildValidationError = ({ error }: { error?: any} = {}) : ErrorMessage|null => {
  if (!error || !error?.message) {
    throw new Error('No Error were provided to build validation error')
  }
  if (Object.keys(IdentiteValidationErrors).includes(error?.message)) {
    return IdentiteValidationErrors[error?.message]
  }

  if (Object.keys(SituationFamilialeValidationErrors).includes(error?.message)) {
    return SituationFamilialeValidationErrors[error?.message]
  }

  return null
}

export const SaisieErrorMessages: FunctionComponent<ErrorMessageProps> = ({ errors = [], className = '', ...props }) => {
  return (
    <div {...props} className={classnames(styles.saisieErrorMessages, className)}>
      {uniqBy(uniq(
        errors
          .filter(error => error?.message)),
      'message').map(error => (
        <SaisieErrorMessage className={styles.saisieErrorMessages__error} key={String(error!.message)} error={error!} />
      ))}
    </div>)
}

SaisieErrorMessages.defaultProps = {
  className: '',
}
