import React, { useContext } from 'react'
import cn from 'classnames'
import { node, string } from 'prop-types'

import { Link } from '@core/ui'
import { portailPartenaireContext } from '@core/partenaire-common'

import styles from './TopBar.module.css'

TopBar.propTypes = {
  children: node,
  className: string,
}

TopBar.defaultProps = {
  children: null,
  className: '',
}

export function TopBar({ children, className }) {
  const { iconHeader, title } = useContext(portailPartenaireContext)
  return (
    <div className={cn(styles.topbar, className)}>
      <div className={styles.topbar__left}>
        <Link to="/" aria-label="Portail logo">
          <div className={styles.topbar__logo}>
            {iconHeader}
          </div>
        </Link>
        <div className={styles.topbar__title}>{title}</div>
      </div>
      <div className={styles.topbar__links}>{children}</div>
    </div>
  )
}

