import React from 'react'

import { getBasculeHypothecaireLink } from '../gamme'
import { ErrorMessage } from '../../components/SaisieErrorMessages'
import { ValidationFieldError, buildValidationError } from '../../model'
import { ChangeGammeProps } from '../../components/Gamme'

export function getSituationBancaireErrorByField(
  { field, message }: ValidationFieldError,
  { isUpdatingGamme, changeGamme }: ChangeGammeProps)
    : ErrorMessage|null {
  if (field === 'nbImpayeImmoTotal' && message === 'WRONG_VALUE') {
    return {
      title: 'Prêt immobilier',
      message: (
        <>
          Le nombre d'impayés sur le(s) prêt(s) immobilier doit être inférieur au nombre autorisé.
          {getBasculeHypothecaireLink(changeGamme, isUpdatingGamme, 'Merci de basculer en hypothécaire.')}
        </>),
    }
  }
  if (field === 'nbImpayeCtTotal' && message === 'WRONG_VALUE') {
    return {
      title: 'Prêt conso',
      // eslint-disable-next-line max-len
      message: (
        <>
          Le nombre d'impayés sur les prêts conso est supérieur au nombre autorisé.
          {getBasculeHypothecaireLink(changeGamme, isUpdatingGamme, 'Merci de basculer en hypothécaire.')}
        </>),
    }
  }
  if (field === 'nbComptes' && message === 'WRONG_VALUE') {
    return {
      title: 'Nombre de comptes courants',
      message: 'Le nombre de comptes courants doit être supérieur à 0.',
    }
  }
  return null
}

export function validateSituationBancaire({ situationBancaire = {} as any, gamme }) {
  const numberOfComptes = situationBancaire?.numberOfComptes
  const numberOfRejetsConso = situationBancaire?.numberOfRejetsConso
  const numberOfRejetsImmo = situationBancaire?.numberOfRejetsImmo
  const globalErrors: any[] = []
  if (gamme === 'UNSECURED' && numberOfRejetsConso > 1) globalErrors.push(buildValidationError('nbImpayeCtTotal', 'WRONG_VALUE'))
  if (numberOfRejetsConso < 0) globalErrors.push(buildValidationError('nbImpayeCtTotal', 'NEGATIVE_VALUE'))
  if (gamme === 'UNSECURED' && numberOfRejetsImmo > 0) globalErrors.push(buildValidationError('nbImpayeImmoTotal', 'WRONG_VALUE'))
  if (numberOfRejetsImmo < 0) globalErrors.push(buildValidationError('nbImpayeImmoTotal', 'NEGATIVE_VALUE'))
  if (numberOfComptes < 1) globalErrors.push(buildValidationError('nbComptes', 'WRONG_VALUE'))
  return {
    globalErrors,
    hasErrors: globalErrors.length > 0,
  }
}
