import { OptionFamilialeUI } from './OptionFamilialeUI'
import { SITUATION_MATRIMONIALE_OPTIONS, SituationMatrimoniale } from './SituationMatrimoniale'


export class SituationFamilialeUI {
  constructor(public readonly hasCoEmprunteur: boolean,
    public readonly situation: string) {}

  public getOptionsFamiliales() : Array<OptionFamilialeUI> {
    const options = this.hasCoEmprunteur ? SITUATION_MATRIMONIALE_OPTIONS.couple : SITUATION_MATRIMONIALE_OPTIONS.single
    return options.map(option => new OptionFamilialeUI(option, this.hasCoEmprunteur))
  }

  public isSeparationDeBiens() : boolean {
    return this.situation === SituationMatrimoniale.MARIE && !this.hasCoEmprunteur
  }

  public isMarried() : boolean {
    return this.situation === SituationMatrimoniale.MARIE
  }

  public hasRegimeOptions(isLoading : boolean) : boolean {
    return !isLoading && this.hasCoEmprunteur && this.situation === SituationMatrimoniale.MARIE
  }

  public isDivorcing(isLoading : boolean) : boolean {
    return !isLoading && this.situation === SituationMatrimoniale.INSTANCE_DE_DIVORCE
  }
}
