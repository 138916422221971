import React, { useMemo } from 'react'

import { useSimulation } from '../../Contexts'
import { BannerCard } from '../BannerCard'
import { HorsNormeCardErrors } from './CardErrors/HorsNormeCardErrors'
import { NonValideCardErrors } from './CardErrors/NonValideCardErrors'
import { SimulationStatus } from '../const'
import { SaisieCardErrors } from './CardErrors/SaisieCardErrors'

export function Header() {
  const [{ status, simulationErrors }] = useSimulation()

  const getTitle = hasError => {
    return hasError ?
      'Votre simulation présente des critères hors-normes.'
      :
      'Votre dossier est pré-validé.'
  }

  const isHorsNorme = status === SimulationStatus.HORS_NORME
  const isErrorSaisie = status === SimulationStatus.SAISIE_ERROR
  const isNonValide = status === SimulationStatus.NON_VALIDE

  const hasError = useMemo(() => !!simulationErrors.horsNorme || !!simulationErrors.nonValide || !!simulationErrors.errorSaisie, [simulationErrors])
  const title = useMemo(() => getTitle(hasError),
    [hasError])

  return (
    <BannerCard title={title} hasError={hasError}>
      {isHorsNorme && <HorsNormeCardErrors errors={simulationErrors.horsNorme} />}
      {isNonValide && <NonValideCardErrors {...simulationErrors.nonValide} />}
      {isErrorSaisie && <SaisieCardErrors error={simulationErrors.errorSaisie?.[0]} />}
    </BannerCard>
  )
}
