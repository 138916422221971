import React, { Context, createContext, useEffect, useState } from 'react'
import { bool, node } from 'prop-types'
import { FormState } from '../FormState/FormState'

export const FormStateContext: Context<FormState> = createContext(undefined as any as FormState)

FormStateProvider.propTypes = {
  children: node.isRequired,
  initialShowErrors: bool,
}

FormStateProvider.defaultProps = {
  initialShowErrors: true,
}

export function FormStateProvider({ initialShowErrors, children }) {
  const [formState, setFormState] = useState<FormState>()
  useEffect(() => {
    if (!formState) {
      setFormState(new FormState({ entries: [], showErrors: initialShowErrors, dirtySinceLastSubmit: true, submitted: false }).withUpdateState(setFormState))
    }
  }, [initialShowErrors])
  return formState ? (
    <FormStateContext.Provider value={formState}>
      {children}
    </FormStateContext.Provider>
  ) : <></>
}
