import { useEffect, useState } from 'react'
import { Consentement } from './const'

export function getConsent() {
  return document.cookie === Consentement.TRUE
}

export function useConsentCookie() {
  const [consentingValue, setConsentingValue] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    setConsentingValue(document.cookie
      .split(';')
      .find(item => item.includes(Consentement.FALSE) || item.includes(Consentement.TRUE))
      ?.includes(Consentement.TRUE))
  }, [document.cookie])

  function setConsent(newConsentingValue: boolean) {
    document.cookie = `consentement=${newConsentingValue};samesite=strict;path=/`
  }

  return {
    consentingValue,
    setConsent,
  }
}
