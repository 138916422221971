import 'regenerator-runtime/runtime'
import { ApolloClient, ApolloLink, HttpLink, from, InMemoryCache } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { getOr } from 'lodash/fp'
import { fetch as coreCommonFrontendFetch, isBrowser } from '@core/common-frontend'
import EventEmitter from 'eventemitter3'
import { buildFetchWithRefresh } from './fetchWithRefresh'


const uri = (isBrowser && window.GATSBY_GRAPHQL_URL) || process.env.GATSBY_GRAPHQL_URL
const batchHttpLink = (useRefreshToken, fetch) => new BatchHttpLink(
  { uri, headers: { batch: 'true' }, fetch: useRefreshToken ? buildFetchWithRefresh(fetch) : fetch })
const httpLink = (useRefreshToken, fetch) => new HttpLink({ uri, fetch: useRefreshToken ? buildFetchWithRefresh(fetch) : fetch })

export const UNAUTHENTICATED = 'UNAUTHENTICATED'
export const FORBIDDEN = 'FORBIDDEN'
export const GraphQLErrorEmitter = new EventEmitter()

const getCode = getOr('', 'extensions.code')
const hasUnauthenticatedError = graphQLErrors => (graphQLErrors || []).map(getCode).some(code => code === UNAUTHENTICATED)
const hasForbiddenError = graphQLErrors => (graphQLErrors || []).map(getCode).some(code => code === FORBIDDEN)
export const errorHandledByErrorEmitter = graphQLErrors => hasUnauthenticatedError(graphQLErrors) || hasForbiddenError(graphQLErrors)

const errorLink = onError(({ graphQLErrors }) => {
  if (hasUnauthenticatedError(graphQLErrors)) {
    GraphQLErrorEmitter.emit(UNAUTHENTICATED)
  }

  if (hasForbiddenError(graphQLErrors)) {
    GraphQLErrorEmitter.emit(FORBIDDEN)
  }
})

export const buildGraphqlClient = (cacheConfiguration, useRefreshToken = false, fetch = coreCommonFrontendFetch) => new ApolloClient({
  link: from([
    errorLink,
    ApolloLink.split(
      operation => operation.getContext().noBatch === true,
      httpLink(useRefreshToken, fetch),
      batchHttpLink(useRefreshToken, fetch),
    ),
  ]),
  cache: new InMemoryCache(cacheConfiguration),
})
