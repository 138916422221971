import React from 'react'
import styles from '../../Simulation/Produit/Produit.module.css'
import { Header } from '../../Simulation/Produit/Header'
import { Body } from '../../Simulation/Produit/Body'

export function ToPrintSimulationProduit() {
  return (
    <div className={styles.produit}>
      <Header />
      <Body />
    </div>
  )
}
