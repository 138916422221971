import React, { useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Consentement } from '@core/partenaire-ui/src/components/SelectionCookies/const'

import { Card, MyMoneyBank, PostMortem, Switch } from '@mmb/ui-components'

import { Link } from 'react-router-dom'
import styles from './ProtectionDonnees.module.css'

/* eslint-disable max-len */
export function ProtectionDonnees() {
  const [isConstented, setIsConsented] = useState(document.cookie === Consentement.TRUE)

  function setConsent(value) {
    setIsConsented(value)
    setConsentCookie(value)
  }

  function setConsentCookie(newConsentingValue) {
    document.cookie = `consentement=${newConsentingValue};samesite=strict;path=/`
  }

  const textCredit = (
    <>
      <p>
        <MyMoneyBank /> (le Prêteur), en tant que responsable de traitement, met en œuvre des traitements de données personnelles ayant pour finalité
        l'octroi et le pilotage de crédit (notamment au moyen de scores de crédit et de traitement de modélisation), l'évaluation, la gestion et la
        consolidation du risque, la prospection et l'animation commerciale, ainsi que la gestion de la relation client de manière générale (actions
        commerciales, gestion des réclamations, mesure de la satisfaction, …), les études statistiques et la fiabilité des données, la sécurité et la
        prévention des impayés et de la fraude, le recouvrement, le contentieux et la gestion des provisions, la lutte contre le blanchiment de capitaux
        et le financement du terrorisme, ainsi que le respect de ses obligations légales et réglementaires. Les bases juridiques de ces traitements sont
        soit les relations contractuelles et les mesures précontractuelles existantes entre vous et le Prêteur, soit les obligations légales et
        réglementaires s'imposant au Prêteur, soit votre consentement, soit l'intérêt légitime du Prêteur (gestion, suivi et contrôle de l'activité,
        connaissance client, développement de l'activité).
      </p>
      <p>
        Les informations qui sont identifiées comme obligatoires dans les formulaires sont nécessaires pour les finalités décrites dans chaque
        formulaire. En leur absence, les demandes ou opérations relatives à ces traitements ne pourront pas être traitées ou réalisées.
      </p>
      <ol className={styles.protectionDonnees__liste}>
        <li>
          Certaines des informations collectées sont utilisées pour réaliser un score de crédit, mis en œuvre dans le cadre de l'étude de votre demande.
          Ce score a pour objectif de mesurer le risque statistique de défaut de remboursement et d'apporter une aide à la sélection des demandes de
          crédit. Ce score est susceptible d'aboutir à une décision automatique de refus de votre demande. En cas de refus par le Prêteur, vous disposez
          du droit de solliciter un entretien avec une personne habilitée à procéder à un réexamen de votre dossier en vous adressant au département
          Consommateur (dont l'adresse figure au point 7) ci-après), et au cours duquel vous pourrez présenter vos observations concernant votre situation
          financière personnelle.
        </li>
        <li>
          Si vous êtes déjà client de <MyMoneyBank />, vous êtes informé que les données vous concernant en possession de <MyMoneyBank /> (informations
          relatives à l'identité, la situation personnelle, économique, financière et bancaire) peuvent être utilisées pour le traitement de la demande de
          crédit.
        </li>
        <li>
          Ces informations sont destinées au personnel habilité du Prêteur, tenu au secret bancaire. Le cas échéant, les informations liées à la
          présente offre pourront être communiquées au(x) partenaire(x) commercial(aux) (apporteur(s) d'affaires) du Prêteur, informé(s) de l'accord ou du
          refus du crédit sans communication du motif du refus. Si vous avez adhéré à l'une des polices d'assurance proposées par le Prêteur, les
          informations vous concernant pourront être transmises à la ou les compagnies d'assurance afin de permettre la gestion de votre (vos) contrat(s)
          d'assurance. La compagnie d'assurance informera en retour le Prêteur du statut de vos éventuels sinistres. Les informations vous concernant
          pourront également être transmises dans le cadre d'opérations spécifiques listées à l'article L511-33 du Code Monétaire et Financier telles que
          les opérations de cession.
        </li>
        <li>
          Les informations (identité, adresse, situation personnelle, professionnelle et financière, informations relatives au crédit) sont également
          susceptibles d'être communiquées à des sous-traitants situés en Inde aux fins de gestion de la comptabilité et de la réalisation de reportings.
          Les transferts d'information à destination de l'Inde sont encadrés par une convention de flux transfrontières conclue entre <MyMoneyBank /> et les
          sous-traitants concernés. Vous pouvez obtenir communication des garanties prises en vous adressant au département Consommateurs dont l'adresse
          figure au point 7) ci-après.
        </li>
        <li>
          Les informations vous concernant sont conservées pour des durées variables en fonction de la finalité de leur collecte. Sous réserve de
          dispositions plus contraignantes, les données sont conservées : pour la gestion des contrats, pour la lutte contre le blanchiment de capitaux et
          le financement du terrorisme, jusqu'à la fin de la relation contractuelle augmentée de la durée des prescriptions légales; pour la gestion de la
          prospection, pendant 3 ans soit à compter du dernier contact du prospect soit à compter de la fin du contrat, le cas échéant; pour la gestion de
          la comptabilité, pendant 10 ans à compter de la clôture de l'exercice comptable ; en cas de refus d'octroi de crédit, les informations sont
          supprimées (ou anonymisées) dans les 6 mois à compter de la demande.
        </li>
        <li>
          En outre, dans le cadre d'une procédure d'octroi de crédit, le Prêteur, en tant qu'établissement de crédits est tenu de consulter le FICP. En
          cas d'incident de paiement caractérisé tel que défini à l'arrêté du 26 octobre 2010 relatif au FICP, le Prêteur est tenu de demander
          l'inscription d'informations vous concernant dans ce fichier.
        </li>
        <li>
          Vous disposez d'un droit d'accès, d'interrogation, de rectification et d'effacement des informations vous concernant, de limitation du
          traitement, d'un droit à la portabilité des données, ainsi que d'un droit d'opposition pour motif légitime et d'un droit d'opposition à la
          prospection commerciale. Vous disposez également d'un droit de formuler des directives spécifiques et générales concernant la conservation,
          l'effacement et la communication de vos données <PostMortem />. En ce qui concerne les directives générales, elles devront être adressées à un
          tiers qui sera désigné par Décret.<br />
          La communication de directives spécifiques <PostMortem /> et l'exercice des droits s'effectuent auprès du département Consommateurs de <MyMoneyBank />,
          API24D2, 1, rue du Château de l'Eraudière 44311 Nantes Cedex 3, accompagné d'une copie de votre pièce d'identité.<br />
          Enfin, vous avez le droit d'introduire une réclamation auprès de la Cnil.<br />
        </li>
        <li>
          En outre, si vous ne souhaitez pas faire l'objet d'un démarchage par voie téléphonique, vous avez la possibilité de demander gratuitement
          l'inscription de votre numéro de téléphone sur la liste d'opposition au démarchage téléphonique auprès de l'organisme OPPOSETEL par le biais du
          lien suivant : <a href="http://www.bloctel.gouv.fr" target="_blank" rel="noopener noreferrer">www.bloctel.gouv.fr</a>. A noter que si vous y avez expressément consenti, vous pourrez
          recevoir des propositions commerciales par voie électronique (Email, sms, …). Vous pourrez vous opposer à leur réception, en suivant les
          indications portées sur le message de prospection commerciale (Email, sms, …).<br />
        </li>
      </ol>
      <p className={styles.protectionDonnees__coordonnees}>
        <strong>Coordonnées du Responsable du traitement :</strong> <MyMoneyBank />, Tour Europlaza, 20 Avenue André-Prothin 92063 Paris La Défense Cedex
        <br />
        <strong>Coordonnées du Délégué à la Protection des Données : Email : </strong>
        <a href="mailto:dpd@mymoneybank.com">dpd@mymoneybank.com</a> ; <b className={styles.protectionDonnees__voiePostale}>voie postale : </b>
        Délégué à la Protection des Données, <MyMoneyBank />, Tour Europlaza, 20
        Avenue André-Prothin 92063 Paris La Défense Cedex.
      </p>
      <p className={styles.protectionDonnees__cookies}>
        <strong>A propos des cookies sur ce site :</strong>
      </p>
      <p>A ce titre, nous souhaitons vous informer de manière claire et transparente sur notre politique en matière de dépôt et de lecture des cookies depuis notre site myaccesspro.fr (ci-après, le "Site") vers votre terminal* de connexion.</p>
      <ul>
        <li>le terminal désigne l’équipement matériel (ordinateur, tablette, smartphone…) que vous utilisez pour consulter ou voir s’afficher un site, une application, un contenu publicitaire etc. </li>
      </ul>
      <p>
        <ol>
          <li>
            <strong> Qu'est-ce qu'un cookie ?</strong>
          </li>
          <p> Les cookies sont de petits fichiers qui sont placés et stockés sur le disque dur de votre appareil, lorsque vous visitez un site internet. Ces cookies permettent à un site Internet de vous identifier, de signaler votre passage sur telle ou telle page et de vous apporter ainsi une qualité de navigation supérieure, sécuriser votre connexion ou éventuellement adapter le contenu d’une page à vos centres d’intérêt.</p>
          <p>Les informations stockées par les cookies, ont une durée de validité limitée, et portent notamment sur les pages visitées, les publicités sur lesquelles vous avez pu cliquer lors de votre navigation, le type de navigateur que vous utilisez, votre adresse Internet Protocole (IP), les informations que vous avez saisies sur un site afin de vous éviter de les saisir à nouveau.</p>
          <p><strong>Différents types de cookies existent :</strong></p>
          <ul>
            <li>des cookies de session qui disparaissent dès que vous quittez le Site ; </li>
            <li>des cookies permanents qui demeurent sur votre équipement terminal jusqu’à expiration de leur durée de vie ou jusqu’à ce que vous les supprimiez à l’aide des fonctionnalités de votre navigateur.</li>
          </ul>
          <p>D’autres types de traceurs que les cookies peuvent aussi être utilisés, tels que les pixels invisibles, également appelés web bugs ou balises web. Ceux-ci se présentent sous forme d’images graphiques minuscules et peuvent être placés sur le Site ou dans un courriel.</p>
          <p> Dans un souci de clarté, ces technologies seront désignées sous le terme « cookies » dans la présente Politique. </p>
          <li>
            <strong> Quels types de cookies utilisons-nous sur ce site ? </strong>
            <p> Les cookies utilisés sur notre site sont de différentes catégories.</p>
            <p> 2.1. Les cookies strictement nécessaires (obligatoires).</p>
            <p> Ces cookies permettent au site de fonctionner de manière optimale. Ils vous permettent d'utiliser les principales fonctionnalités de notre site. Vous pouvez vous y opposer et les supprimer en utilisant les paramètres de votre navigateur (cf. paragraphe 4.2), cependant vous ne pourrez pas utiliser notre site normalement et votre expérience utilisateur risque d’être dégradée.</p>
            <p> 2.2. Les cookies de mesures d’audience (soumis à votre consentement).</p>
            <p> Ces cookies nous permettent de reconnaître et mesurer le nombre de visiteurs sur notre site mais également d’analyser la manière dont il est utilisé pour améliorer son fonctionnement. Les cookies statistiques aident les propriétaires du Site web, par la collecte et la communication d'informations, à comprendre comment les visiteurs interagissent avec les sites web.</p>
          </li>
          <ul>
            <li>
              <p className={styles.protectionDonnees__consent_title}> <strong> SmartProfile </strong> <a href="https://smartp.com/en/politique-confidentialite/" target="_blank" rel="noopener noreferrer"> (voir le site officiel)</a>
                <Switch
                  className={styles.protectionDonnees__consent}
                  name={`isConsented${isConstented}`}
                  label={`${isConstented ? 'Accepté' : 'Refusé'}`}
                  checked={(isConstented)}
                  onClick={() => setConsent(!isConstented)}
                />
              </p>
            </li>
          </ul>
          <li>
            <strong> Liste des cookies déposés par nous et nos partenaires.</strong>
            <p> 3.1 Liste des cookies que nous déposons :</p>
            <table>
              <thead>
                <tr>
                  <th scope="col">Type de cookie</th>
                  <th scope="col">Finalité</th>
                  <th scope="col">Nom du cookie</th>
                  <th scope="col">Durée de conservation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Mesure d'audience- technique <br />(exempté de consentement)</th>
                  <td>Cookie de type first party, déposé quel que soit le consentement pour permettre à la solution de mesure d'audience d'identifier de manière aléatoire et anonyme le visiteur
                    Si vous souhaitez désactiver le cookie de mesure d'audience exempté de consentement de la société Net Solution Partner cliquez sur ce <span />
                    <Link
                      to="https://www.smartp.com/index.cfm?fuseaction=c.dspHome&subaction=optout"
                      target="blank"
                    >
                      lien
                    </Link>
                  </td>
                  <td>sp-i</td>
                  <td>13 mois</td>
                </tr>
              </tbody>
            </table>
          </li>

          <p> 3.2 Liste des cookies déposés par nos partenaires :</p>
          <table>
            <thead>
              <tr>
                <th scope="col">Nom du partenaire et politique de confidentialité</th>
                <th scope="col">Finalité</th>
                <th scope="col">Nom du cookie</th>
                <th scope="col">Durée de conservation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Mesure d'audience - technique (exempté de consentement)
                  <br />Net Solution Partner
                  <br />
                  <span />
                  <Link
                    to="https://smartp.com/politique-confidentialite/"
                    target="blank"
                  >
                    Politique de confidentialité
                  </Link>

                </th>
                <td>Permet la gestion du load balancer effectué par l'hébergeur Claranet qui opère l'infrastructure de SmartProfile</td>
                <td>BIGipServer~Clara_Mut~pl_nspnsp.pw_tcp-80</td>
                <td>Durée de la session</td>
              </tr>
            </tbody>
          </table>
          <li>
            <p>
              Nous vous informons que, si vous acceptez le dépôt du cookie de ciblage marketing, des données personnelles seront transmises et traitées par Google dans des pays situés hors de l'Union Européenne (ci-après, « UE ») :
              <ul>
                <li>
                  <Link
                    to="https://www.google.com/about/datacenters/locations/"
                    target="blank"
                  >
                    lien vers la localisation des centres de données Google.
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://business.safety.google/adssubprocessors/"
                    target="blank"
                  >
                    lien vers la localisation des sous-traitants ultérieurs de Google.
                  </Link>
                </li>
              </ul>
              Certains de ces pays situés hors UE ne sont pas reconnus comme adéquats au regard du droit de l’UE. Afin de sécuriser ces transferts, Google les encadre notamment par la conclusion de clauses contractuelles types adoptées par la Commission européenne.
              Les données personnelles collectées via le cookie de ciblage marketing sont également traitées par My Money Bank, afin d’assurer le suivi de ses conversions, en tant que responsable du traitement, pour une durée de 30 jours.
              Ces données sont traitées sur la base de votre consentement, que vous pouvez retirer à tout moment en refusant le cookie de ciblage marketing via notre module cookie, en cliquant sur l'icône « cookie » située en bas à gauche de votre écran.
              Pour en savoir plus sur le traitement de vos données personnelles et l’exercice de vos droits, veuillez consulter notre politique « Informatique et libertés » en cliquant ici.
              Les cookies utilisés sur ce Site sont déposés par nous ou nos partenaires

            </p>
            <strong> Comment exercer vos choix en matière de cookies ?</strong>
            <p> Vous pouvez gérer à tout moment l’utilisation des cookies selon les modalités suivantes : </p>
            <ul>
              <li> directement sur notre Site, via le module prévu à cet effet ;</li>
              <li> à partir des paramètres votre de navigateur internet.</li>
            </ul>
            <p>4.1 Gestion des cookies sur notre Site</p>
            <p> Un module de gestion des cookies s’affiche en permanence sur notre site, vous pouvez exercer votre choix en cliquant sur l'icône « cookie » située en bas à gauche de votre écran</p>
            <p> Votre choix est conservé pour une durée de 6 mois.</p>
            <p> 4.2 Gestion des cookies à partir de votre navigateur internet </p>
            <p>Si vous ne souhaitez pas que des cookies soient enregistrés sur votre terminal de connexion, vous pouvez à tout moment :</p>
            <ul> <li> refuser le dépôt de tout type de cookie sur votre terminal de connexion via les paramètres de votre navigateur internet. Pour votre information, en refusant tout ou partie des cookies, votre expérience sur le site peut être dégradée et vous pourriez ne pas pouvoir accéder à certaines fonctionnalités.</li></ul>
            <p>Pour la gestion des cookies et de vos choix, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos choix en matière de cookies.</p>
            <p>A titre d’exemples :</p>
            <ul>
              <li>
                <Link
                  to="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d"
                  target="blank"
                >
                  pour Internet Explorer™
                </Link>
              </li>
              <li>
                <Link
                  to="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
                  target="blank"
                >
                  pour Safari™
                </Link>
              </li>
              <li>
                <Link
                  to="https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647"
                  target="blank"
                >
                  pour Chrome™
                </Link>
              </li>
              <li>
                <Link
                  to="https://support.mozilla.org/fr/users/auth?next=%2Ffr%2Fkb%2Fnew%3Ftitle%3DActiver%2Bet%2Bd%25C3%25A9sactiver%2Bles%2Bcookies%2Bque%2Bles%2Bsites%2BInternet%2Butilisent%2Bpour%2Bconna%25C3%25AEtre%2Bvos%2Bpr%25C3%25A9f%25C3%25A9rences%26redirectslug%3DActiver%2Bet%2Bd%25C3%25A9sactiver%2Bles%2Bcookies%26redirectlocale%3Dfr"
                  target="blank"
                >
                  pour Firefox™
                </Link>
              </li>
              <li>
                <Link
                  to="https://help.opera.com/en/latest/web-preferences/#cookies"
                  target="blank"
                >
                  pour Opera™
                </Link>
              </li>
            </ul>
            <p>Pour en savoir plus sur la manière de paramétrer vos logiciels de navigation, nous vous invitons à <span />
              <Link
                to="https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur"
                target="blank"
              >
                consulter le site de la Commission nationale de l’informatique et des libertés
              </Link>
            </p>
          </li>
          <li>
            <strong> Vos données personnelles :</strong>
            <p> <strong>Coordonnées du Responsable du traitement : </strong> My Money Bank, Tour Europlaza, 20 Avenue André-Prothin 92063 Paris La Défense Cedex</p>
            <p> <strong>Coordonnées du Délégué à la Protection des Données : e-mail : </strong> <span />
              <Link
                to="mailto:dpd@mymoneybank.com"
                target="blank"
              >
                dpd@mymoneybank.com;
              </Link>
              voie postale : My Money Bank, Délégué à la Protection des Données, 1 Rue du Château de l'Eraudière, 44300 Nantes
            </p>
          </li>
        </ol>
      </p>
    </>
  )

  return (
    <div className={styles.protectionDonnees}>
      <h2 className={styles.protectionDonnees__title}>Protection de mes données personnelles</h2>
      <Card>
        {textCredit}
      </Card>
    </div>
  )
}
