import React, { FunctionComponent } from 'react'
import cn from 'classnames'
import { Message, Alert } from '@mmb/ui-components'

import styles from './WarningMessage.module.css'

interface WarningMessageProps {
  children?: React.ReactNode
  className?: string
  contentClassName?: string
}

export const WarningMessage: FunctionComponent<WarningMessageProps> = ({ children, className, contentClassName, ...props }) => (
  <Message
    type="warning"
    icon={Alert}
    iconClassName={styles.message__icon}
    role="alert"
    contentClassName={cn(styles.message, contentClassName)}
    className={className}
    {...props}
  >
    {children}
  </Message>
)

WarningMessage.defaultProps = {
  children: null,
  className: '',
  contentClassName: '',
}
