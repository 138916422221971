export const GAMMES = {
  SECURED: {
    label: 'Hypothécaire',
    value: 'hypo',
  },
  UNSECURED: {
    label: 'Sans garantie',
    value: 'conso',
  },
}
