import { gql } from '@core/graphql'

export const GET_PATRIMOINE_DOSSIER = gql`
query getPatrimoine($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    emprunteurs {
      id
      patrimoines(dossierId: $id) {
        id
        regimePropriete
        taux
        position
        bien {
          id
          isGarantie
          nature
          usage
          complement
          numero
          voie
          lieuDit
          codePostalVille
          codeInsee
          prixInitial
          valeur
        }
      }
    }
  }
}
`


export const UPDATE_PATRIMOINE_DOSSIER = gql`
mutation updatePatrimoineDossier($id: ID!, $personneId: ID!, $patrimoine: partenaire_PatrimoineInput!) {
  partenaire_updatePatrimoine(id: $id, personneId: $personneId, patrimoine: $patrimoine) {
    id
    regimePropriete
    taux
    position
    bien {
      id
      isGarantie
      nature
      usage
      complement
      numero
      voie
      lieuDit
      codePostalVille
      codeInsee
      prixInitial
      valeur
    }
  }
}
`

export const CREATE_PATRIMOINE_DOSSIER = gql`
mutation createPatrimoineDossier($id: ID!, $personneId: ID!, $patrimoine: partenaire_PatrimoineInput!) {
  partenaire_createPatrimoine(id: $id, personneId: $personneId, patrimoine: $patrimoine) {
    id
    regimePropriete
    taux
    position
    bien {
      id
      isGarantie
      nature
      usage
      complement
      numero
      voie
      lieuDit
      codePostalVille
      codeInsee
      prixInitial
      valeur
    }
  }
}
`

export const UPDATE_DEMANDE_GARANTIE_BIENS = gql`
mutation updateGarantieBiens($id: ID!, $biens: [partenaire_BienInput]!) {
  partenaire_updateGarantieBiens(id: $id, biens: $biens) {
    id
  }
}
`

export const DELETE_PATRIMOINE_DOSSIER = gql`
mutation deletePatrimoineDossier($id: ID!, $personneId: ID!, $patrimoineId: ID!) {
  partenaire_deletePatrimoine(id: $id, personneId: $personneId, patrimoineId: $patrimoineId)
}
`
