import { useState, useEffect } from 'react'
import { gql, useQueryWithError } from '@core/graphql'
import { DossierStatut } from '@partenaire/common'
import { AssuranceSecuredUI } from '../../../../model'
import { AssuranceUI } from '../../../../model/assurance/AssuranceUI'


const GET_ASSURANCE_DOSSIER = gql`
query getAssurance_dossier($id: String!) {
    partenaire_dossierTransmis(id: $id) {
        historique {
          statut
          dateModif
        }
    }
}
`

const DELCARABLE_STATUTS : DossierStatut[] = [
  DossierStatut.ACCORDE,
  DossierStatut.REFUSE,
  DossierStatut.SANS_SUITE,
  DossierStatut.OFFRES_EDITEES,
  DossierStatut.EDITE_EN_ATTENTE_ACCEPTATION,
  DossierStatut.ACCEPTATION_CONFORME,
  DossierStatut.ACCEPTATION_NON_CONFORME,
  DossierStatut.DEBLOQUE,
  DossierStatut.ENTREE_EN_CA,
]

const checkAssuranceDeclarable = historique => historique?.some(({ statut }) => DELCARABLE_STATUTS.includes(statut))

export function useAssuranceEligibility(assuranceUI: AssuranceUI) {
  const { idDossier, type, situationAdhesion, assuranceDetailDCSPropsUI } = assuranceUI.props || {}
  const { statut, numeroAdhesion } = assuranceDetailDCSPropsUI || {}
  const [isAssuranceDeclarable, setIsAssuranceDeclarable] = useState(false)
  const { data = {}, loading } = useQueryWithError(GET_ASSURANCE_DOSSIER, { variables: { id: idDossier } })

  useEffect(() => {
    if (!loading && data.partenaire_dossierTransmis) {
      const { historique = [] } = data.partenaire_dossierTransmis
      setIsAssuranceDeclarable(checkAssuranceDeclarable(historique))
    }
  }, [data])

  if (loading) {
    return { loading }
  }
  const assuranceSecuredUI = new AssuranceSecuredUI(
    isAssuranceDeclarable,
    type,
    statut,
    situationAdhesion,
    numeroAdhesion,
    assuranceUI.props.isDossierSecured,
    assuranceUI.hasValidAge())
  return { assuranceSecuredUI }
}
