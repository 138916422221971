import { v4 as uuidv4 } from 'uuid'
import { merge } from 'lodash'
import { HEADER_CORRELATION_ID } from '@core/common'

/**
 * Instrumente la méthode fetch() du navigateur avec les éléments suivants :
 * - possibilité d'utiliser un path (commence par /) à la place d'une URL. Dans ce cas, la requête est envoyée sur le backend
 * défini dans la variable d'env GATSBY_API_URL
 * - ajout d'un éventuel cookie de session
 * - ajout d'un en-tête X-Correlation-ID
 * - ajout de l'en-tête Referer via la policy 'origin' : cela force l'envoi de l'en-tête Referer dans tous les cas, et cela permet au backend
 * de détecter correctement le portail appelant dans le cas où l'en-tête 'Origin' n'est pas envoyé par le front, ce qui peut arriver quand la page
 * est en train d'être chargée
 *
 * @param {string} urlOrBackendPath URL ou path du backend à récupérer
 * @param {*} requestOptions options
 * @returns promesse sur la réponse
 * @see https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
 */
export const fetch = async (urlOrBackendPath, requestOptions = {}) => {
  const urlToFetch = urlOrBackendPath.startsWith('/') ? `${window.GATSBY_API_URL}${urlOrBackendPath}` : urlOrBackendPath
  const headers = {}
  headers[HEADER_CORRELATION_ID] = uuidv4()
  const enhancedOptions = merge({
    headers,
    credentials: 'include',
    referrerPolicy: 'origin',
  }, requestOptions)
  return global.fetch(urlToFetch, enhancedOptions)
}


/**
 * Effectue un appel POST sur le serveur backend en transmettant (et recevant) les données au format json.
 * Note: La méthode `fetch` instrumentée est utilisée (@see #fetch)
 * @param {string} urlOrBackendPath URL ou path du backend à appeler
 * @param {*} body le corps de la requête
 * @param fetchToUse la fonction fetch à utiliser
 * @returns promesse sur la réponse
 */
export const sendJson = (urlOrBackendPath, body, fetchToUse = fetch) => fetchToUse(
  urlOrBackendPath,
  {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  },
)
