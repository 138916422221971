import React from 'react'
import { Panel } from '@core/partenaire-ui'
import { string, arrayOf, shape, bool } from 'prop-types'
import styles from './OutilsPanel.module.css'
import { Rubriques } from './Rubriques'

export const OutilsPanel = ({ rubriques, loading }) => (
  <Panel className={styles.outils_panel}>
    <nav className={styles.outils_panel__categories}>
      <Rubriques rubriques={rubriques} loading={loading} />
    </nav>
  </Panel>
)

OutilsPanel.propTypes = {
  rubriques: arrayOf(shape({ id: string, label: string })).isRequired,
  loading: bool.isRequired,
}
