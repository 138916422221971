import { gql, useQuery, DO_NOT_CHECK_CACHE } from '@core/graphql'
import { DossierStatut, Offre, Role, SignatureType, OffreEmprunteurs } from '@partenaire/common'
import { HistoriqueStatut, formatHistorique } from '../../../utils/historique'

export const GET_HISTORIQUE = gql`
query getHistorique($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    id
    historique {
      statut
      dateModif
    }
    emprunteurs {
      id
      role
      identite {
        nom
        prenom
      }
    }
    offres {
      offreEmprunteur {
        signatureType
        statut
        dateCreation
      }
      offreCoemprunteur {
        signatureType
        statut
        dateCreation
      }
    }
  }
}`

interface FormatedEmprunteur {
  id: string
  role: Role
  nom: string
  prenom: string
  offre: Offre
}

const formatEmprunteur = ({ identite, ...emprunteur }) : FormatedEmprunteur => ({
  ...identite,
  ...emprunteur,
})

export function useDossierHistory(id, gamme) {
  const {
    data = {},
    loading,
  } = useQuery(GET_HISTORIQUE, {
    ...DO_NOT_CHECK_CACHE,
    errorPolicy: 'all',
    variables: { id },
  })
  const dossierTransmis = data?.partenaire_dossierTransmis

  const historique: HistoriqueStatut = loading ? [] : formatHistorique(dossierTransmis?.historique, gamme)
  const offres: OffreEmprunteurs = loading ? {} : dossierTransmis?.offres
  const emprunteurs: FormatedEmprunteur[] = loading ? [] : dossierTransmis?.emprunteurs.map(formatEmprunteur) || []
  const hasOneSignatureElectronique
    = loading ? false : [offres?.offreEmprunteur, offres?.offreCoemprunteur].some(offre => offre?.signatureType === SignatureType.ELECTRONIQUE)
  const hasOneSignatureManuelle
    = loading ? false : [offres?.offreEmprunteur, offres?.offreCoemprunteur].some(offre => offre?.signatureType === SignatureType.MANUSCRITE)

  const hasSignatureElectronique = (!hasOneSignatureElectronique && !hasOneSignatureManuelle) ? undefined : hasOneSignatureElectronique

  return {
    loading,
    shouldShowDetail: statut => statut === DossierStatut.EDITE_EN_ATTENTE_ACCEPTATION,
    shouldShowEmprunteur: (offre?: Offre) => offre?.signatureType === SignatureType.ELECTRONIQUE,
    offres,
    historique,
    emprunteurs,
    hasSignatureElectronique,
  }
}
