import React, { useContext } from 'react'
import classNames from 'classnames'
import { node, string } from 'prop-types'

import { Link } from '@core/ui'
import { portailPartenaireContext } from '@core/partenaire-common'


import styles from './UnauthenticatedLayout.module.css'

UnauthenticatedLayout.propTypes = {
  children: node,
  className: string,
}

UnauthenticatedLayout.defaultProps = {
  children: null,
  className: '',
}

export function UnauthenticatedLayout({ children, className }) {
  const { iconHeader } = useContext(portailPartenaireContext)
  return (
    <div>
      <header className={styles.unauthenticatedLayout__header}>
        <Link to="/" aria-label="Portail logo">
          {iconHeader}
        </Link>
      </header>
      <div className={classNames(styles.unauthenticatedLayout__body, className)}>
        {children}
      </div>
    </div>
  )
}
