import { ADMINISTRATEUR, COLLABORATEUR, PermissionScope, SUPER_ADMIN } from '@core/common'
import { ROLES, useHasPermission, useUtilisateurContext } from '@core/partenaire-common'

const computeWritableRoles = role => {
  switch (role) {
    case SUPER_ADMIN: return [ADMINISTRATEUR, COLLABORATEUR]
    case ADMINISTRATEUR: return [COLLABORATEUR]
    default: return []
  }
}

/**
 *
 * Récupère le rôle le plus fort parmi une liste de roles
 */
const computeStrongestRole = (hasPermissionActAsSuperAdminOnOtherCabinets, roleList) => {
  return roleList.reduce((acc, role) => {
    if (hasPermissionActAsSuperAdminOnOtherCabinets || role === SUPER_ADMIN || acc === SUPER_ADMIN) {
      return SUPER_ADMIN
    }
    if (role === ADMINISTRATEUR || acc === ADMINISTRATEUR) {
      return ADMINISTRATEUR
    }
    return COLLABORATEUR
  })
}

/**
 * Calcule les rôles que l'utilisateur courant peut définir sur une personne.
 * @param {string[]} idsCabinet ids des cabinets de travail de la personne à modifier
 * @return {{writableRoles:{value:string,label:string}[]}} liste des rôles que l'utilisateur courant peut définir
 */
export const useWritableRoles = idsCabinet => {
  const { cabinets } = useUtilisateurContext()
  const hasPermissionActAsSuperAdminOnOtherCabinets = useHasPermission(PermissionScope.UTILISATEURS_ACT_AS_SUPER_ADMIN_ON_OTHER_CABINETS)
  const currentPersonneStrongestRole = computeStrongestRole(hasPermissionActAsSuperAdminOnOtherCabinets, cabinets.map(cabinet => cabinet.role))
  const writableRoles = computeWritableRoles(currentPersonneStrongestRole)
  return {
    writableRoles: writableRoles.map(role => ROLES.find(({ value }) => role === value)),
  }
}
