export enum SimulationStatus {
    VALIDE = 'VALIDE',
    HORS_NORME = 'HORS_NORME',
    NON_VALIDE = 'NON_VALIDE',
    SAISIE_ERROR = 'SAISIE_ERROR',
    NON_ELIGIBLE = 'NON_ELIGIBLE',
    INTERNAL_ERROR = 'INTERNAL_ERROR',
}

export const VALIDE_SIMULATION_STATUS = [SimulationStatus.VALIDE, SimulationStatus.HORS_NORME]
export const NON_VALIDE_SIMULATION_STATUS = [SimulationStatus.NON_VALIDE, SimulationStatus.SAISIE_ERROR]
export const SIMULATION_CARDS_STATUS = [SimulationStatus.NON_VALIDE, SimulationStatus.INTERNAL_ERROR, SimulationStatus.NON_ELIGIBLE]
