import { EntryFormState, EntryFormStateName } from '../FormState/EntryFormState'
import { FormState } from '../FormState/FormState'

export enum FiligraneStepName {
  SITUATION_PERSONNELLE = 'Situation personnelle',
  SITUATION_PROFESSIONNELLE = 'Situation professionnelle',
  SITUATION_FINANCIERE = 'Situation financière',
  COMPTES = 'Analyse des comptes',
  ENCOURS = 'Encours',
  PATRIMOINE = 'Patrimoine',
  ASSURANCE = 'Assurance',
  MONTAGE = 'Montage'
}

export class FiligraneStepUI {
  constructor(
      public readonly name: FiligraneStepName,
      public readonly entriesFormState: EntryFormStateName[],
      private stepShowErrors?: boolean,
      private stepShowSuccess?: boolean,
      private anchorProps?: string,
      private loading?: boolean) {}

  public computeErrors(formState: FormState, entryFormStateCount: number): FiligraneStepUI {
    this.loading = formState.isLoading() || formState.props.entries.length < entryFormStateCount
    const stepHasErrors = !!this.reduce(formState, entryFormState => entryFormState.hasErrors)
    const stepShowErrors = !!this.reduce(formState, entryFormState => entryFormState.showErrors)
    this.stepShowErrors = (formState.props.showErrors || stepShowErrors) && stepHasErrors && !this.loading
    this.stepShowSuccess = !stepHasErrors && !this.loading
    this.anchorProps = this.computeAnchor(formState)
    return this
  }

  public showErrors(): boolean {
    return !!this.stepShowErrors
  }

  public getState(): string {
    let state
    if (this.stepShowSuccess) {
      state = 'success'
    } else if (this.stepShowErrors) {
      state = 'error'
    } else {
      state = 'notStarted'
    }
    return state
  }

  public getLabel(): string {
    return this.name
  }

  public getAnchor(): string|undefined {
    return this.anchorProps
  }

  private computeAnchor(formState: FormState): string | undefined {
    const selector = this.stepShowErrors ? entryFormState => entryFormState.showErrors && entryFormState.hasErrors : entryFormState => entryFormState
    const entryFormState = this.findEntryFormState(formState).find(selector) || this.findEntryFormState(formState).find(e => !!e)
    return `#${entryFormState?.name || ''}${entryFormState?.positionFirstError || ''}`
  }

  private reduce<T>(formState: FormState, fn: (entryFormState: EntryFormState) => T): T | undefined {
    return this.findEntryFormState(formState).map(fn).find(p => p)
  }

  private findEntryFormState(formState: FormState): EntryFormState[] {
    return this.entriesFormState
      .map(entryFormStateName => formState.getEntryFormState(entryFormStateName))
      .filter(p => p)
  }
}
