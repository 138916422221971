/* eslint-disable react/no-array-index-key */
import React, { FunctionComponent } from 'react'
import { isEmpty } from 'lodash'
import { SimulationFieldDimensionInvalide } from '../../../../model'
import { useGamme } from '../../../Gamme'
import { getSimulationInvalidMessage, getErrorsToShow, DEFAULT_NON_VALIDE_ERROR_MESSAGE } from '../../../../utils'

interface NonValideCardErrorsProps {
  products: string
  errors: SimulationFieldDimensionInvalide[]
}

export const NonValideCardErrors: FunctionComponent<NonValideCardErrorsProps> = ({ products, errors }) => {
  const gammeProps = useGamme()
  const errorsToShow = getErrorsToShow(errors)
  return (
    !isEmpty(errors) ? (
      <>
        <span>Pour le(s) produit(s) {products}, la simulation ne propose pas de solution car :</span>
        <ul>
          {errorsToShow.map((error, index) => (<li key={index}>{getSimulationInvalidMessage(error, gammeProps)}</li>))}
        </ul>
      </>
    ) : (
      <div key="default">{DEFAULT_NON_VALIDE_ERROR_MESSAGE}</div>
    )
  )
}
