import { PositionUI } from '../support/PositionUI'
import { PatrimoineUI } from './PatrimoineUI'
import { RegimePropriete } from './RegimePropriete'

const usageToHide = ['COMMERCIAL']
const natureToHide = ['HOTEL_PARTICULIER', 'TERRAIN', 'LOCAL']
export class PatrimoinesUI extends PositionUI<PatrimoineUI> {
  private loading: boolean

  constructor(list: PatrimoineUI[], loading: boolean = false) {
    const filteredList = list.filter(({ bien }) => !natureToHide.includes(bien?.nature || '') && !usageToHide.includes(bien?.usage || '')) || []
    super(filteredList)
    this.loading = loading
  }

  protected clone(): PatrimoinesUI {
    return new PatrimoinesUI([...this.list]).withUpdateState(this.setState).withLoading(this.loading)
  }

  public updateIsGarantie(index: number, isGarantie: boolean) {
    this.update(index, { ...this.list[index], bien: { ...this.list[index].bien, isGarantie } })
  }

  private withLoading(loading: boolean): PatrimoinesUI {
    this.loading = loading
    return this
  }

  public getByGarantie(hasGarantie: boolean): PatrimoineUI[] {
    return this.getSortedList().filter(patrimoine => patrimoine?.bien?.isGarantie === hasGarantie)
  }

  public getByRegime(regime: RegimePropriete): PatrimoineUI[] {
    return this.getSortedList().filter(patrimoine => patrimoine.regimePropriete === regime)
  }
}
