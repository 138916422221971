import React from 'react'

import { SituationPersonnelle } from '../SituationPersonnelle'
import { SituationProfessionnelle } from '../SituationProfessionnelle'
import { SituationBancaire } from '../SituationBancaire'
import { SituationFinanciere } from '../SituationFinanciere'
import { Encours } from '../Encours'
import { Patrimoines } from '../Patrimoines'
import { Assurance } from '../Assurance'
import { Montage } from '../Montage'
import { Simulation } from '../Simulation'

import { useSaisie, SimulationProvider } from '../Contexts'
import { useForm } from './Form.hook'

import styles from './Form.module.css'
import { RevenusProvider } from '../SituationFinanciere/Revenus'

export function Form() {
  const { saisieUI } = useSaisie()
  const { props: { dossierId } } = saisieUI
  const { loading } = useForm()

  if (loading || !dossierId) return null

  return (
    <SimulationProvider>
      <RevenusProvider>
        <SituationPersonnelle className={styles.form__card} />
        <SituationProfessionnelle className={styles.form__card} />
        <SituationFinanciere className={styles.form__card} />
        <SituationBancaire className={styles.form__card} />
        <Encours className={styles.form__card} />
        {saisieUI.isSecured() && <Patrimoines className={styles.form__card} />}
        {!saisieUI.isSecured() && <Assurance className={styles.form__card} />}
        <Montage className={styles.form__card} />
      </RevenusProvider>
      <Simulation />
    </SimulationProvider>
  )
}
