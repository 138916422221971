import React from 'react'
import classnames from 'classnames'

import { Card, formatNumberCurrency } from '@mmb/ui-components'
import { bool, node, object } from 'prop-types'

import styles from './Body.module.css'
import { BodyPalierUnsecured } from './BodyPalierUnsecured'

BodyPalier.propTypes = {
  product: object.isRequired,
  children: node,
  hasSecured: bool,
}

BodyPalier.defaultProps = {
  children: null,
  hasSecured: false,
}

export function BodyPalier({ children, product, hasSecured }) {
  const getTitleComplet = (title : string) => (<span> {title} {!hasSecured && (<><br /> Hors assurance </>)} </span>)

  return (
    <div className={styles.simulation__produit__body__cards}>
      {children}
      <Card
        className={classnames(
          styles.simulation__produit__body__container,
          styles.simulation__produit__headerContainer,
        )}
      >
        {getTitleComplet('Échéance')}
        <span
          aria-label="Échéance du financement proposé par la simulation"
          className={classnames(
            styles.simulation__produit__palier__content,
            styles.simulation__produit__palier__color,
          )}
        >
          {`${product.echeance && formatNumberCurrency({ number: product.echeance })}/mois`}
        </span>
      </Card>
      <Card
        className={classnames(
          styles.simulation__produit__body__container,
          styles.simlutation__produit__body__space_right,
          styles.simulation__produit__headerContainer,
        )}
      >
        {getTitleComplet('Variation')}
        <span
          aria-label="Variation du financement proposé par la simulation"
          className={classnames(
            styles.simulation__produit__palier__content,
            styles.simulation__produit__palier__color,
          )}
        >
          {`${product.variation && formatNumberCurrency({ number: product.variation })}/mois`}
        </span>
      </Card>
      {!hasSecured && (<BodyPalierUnsecured variationWithAssurance={product.variationWithAssurance} />)}
    </div>
  )
}
