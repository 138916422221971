import React from 'react'
import { string, shape, func, arrayOf, bool } from 'prop-types'

import { Input } from '@mmb/ui-components'
import styles from './CommunicationsForm.module.css'
import { hasErrorForName as hasError, clearEmpty } from '../../../../utils'

CommunicationsForm.propTypes = {
  suffixes: shape({
    label: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  communications: shape({
    mobile: shape({
      id: string,
      value: string,
    }),
    domicile: shape({
      id: string,
      value: string,
    }),
    email: shape({
      id: string,
      value: string,
    }),
  }).isRequired,
  setCommunications: func,
  errors: arrayOf(
    shape({
      field: string.isRequired,
      message: string,
    }),
  ),
  showErrors: bool,
}

CommunicationsForm.defaultProps = {
  setCommunications: () => {},
  errors: [],
  showErrors: false,
}

export function CommunicationsForm({ suffixes, errors, showErrors, communications: { mobile, domicile, email }, setCommunications }) {
  const hasErrorForName = (name: string) => hasError(name, errors, showErrors)
  return (
    <div className={styles.communicationsForm}>
      <Input
        aria-invalid={hasErrorForName('mobile')}
        hasError={hasErrorForName('mobile')}
        className={styles.communicationsForm__input}
        type="tel"
        pattern="^[0-9]{10}$"
        name={`${suffixes.name}.mobile`}
        label={`Téléphone portable ${suffixes.label}`}
        hideLabel
        onChange={({ target }) => setCommunications({ mobile: { ...mobile, value: target.value } })}
        value={clearEmpty(mobile?.value)}
      />
      <Input
        aria-invalid={hasErrorForName('domicile')}
        hasError={hasErrorForName('domicile')}
        type="tel"
        pattern="^[0-9]{10}$"
        className={styles.communicationsForm__input}
        name={`${suffixes.name}.domicile`}
        label={`Téléphone fixe ${suffixes.label}`}
        hideLabel
        onChange={({ target }) => setCommunications({ domicile: { ...domicile, value: target.value } })}
        value={clearEmpty(domicile?.value)}
      />
      <Input
        aria-invalid={hasErrorForName('email')}
        hasError={hasErrorForName('email')}
        className={styles.communicationsForm__input}
        name={`${suffixes.name}.email`}
        label={`Email ${suffixes.label}`}
        hideLabel
        onChange={({ target }) => setCommunications({ email: { ...email, value: target.value } })}
        type="email"
        value={clearEmpty(email?.value)}
      />
    </div>
  )
}
