import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useGenerateUrlToAzureAd } from '@core/ui'
import { useLocation } from '@core/routing'
import { NavigationPath, useUtilisateurContext } from '@core/partenaire-common'

import { useActivateAccount as useActivateAccountCorePartenaire } from '../useActivateAccount.hook'
import { AzureADFlowType, AzureADHook } from './AzureADInterface'
import { callMutation } from '../../utils/callMutation'

const useActivateAccount: () => (token: string) => Promise<void> = () => {
  const [activateAccountMutation] = useActivateAccountCorePartenaire()
  return token => callMutation(activateAccountMutation, { token }).then(() => undefined)
}

function getTokenFromLocation(location): string | undefined {
  const values = queryString.parse(location.search)?.token
  if (!values) {
    return undefined
  }
  return Array.isArray(values) ? values[0] : values
}

/**
 * Hook pour gérer un flow AzureAD
 * @param flowType type de flow à utiliser
 * @param defaultCompletionUrl l'URL par défaut sur laquelle rediriger après complétion du flow
 */
export function useAzureADFlow(flowType: AzureADFlowType, defaultCompletionUrl: string = NavigationPath.HOME): AzureADHook {
  const location = useLocation()
  const { loading, loggedIn: authenticated } = useUtilisateurContext()
  const skipFlow: boolean = flowType === AzureADFlowType.SIGNIN && Boolean(authenticated)

  // gestion du token d'activation, pour le flow SIGNIN : celui-ci est potentiellement présent dans l'URL
  // si l'utilisateur a cliqué sur le lien du mail d'activation reçu lors du parcours de première connexion
  const token: string | undefined = (skipFlow || flowType === AzureADFlowType.RESET_PASSWORD) ? undefined : getTokenFromLocation(location)
  const activateAccount = useActivateAccount()
  const [invalidToken, setInvalidToken] = useState(undefined as boolean | undefined)
  const [activationError, setActivationError] = useState(undefined as boolean | undefined)
  const [shouldActivate, setShouldActivate] = useState(true as boolean | undefined)
  useEffect(() => {
    if (loading) {
      return
    }
    (async function doActivateAccount() {
      if (token && shouldActivate) {
        try {
          await activateAccount(token)
          setShouldActivate(false)
        } catch (err) {
          // @ts-ignore
          if (err.message === 'Invalid Token') {
            setInvalidToken(true)
          } else {
            setActivationError(true)
          }
        }
      } else {
        setShouldActivate(false)
      }
    }())
  }, [loading, token])

  // gestion de la demande de l'URL de redirection AzureAD
  const completionUrl: string = ((location as any)?.state)?.previousUrl ?? defaultCompletionUrl
  const skip = loading || skipFlow || shouldActivate
  const { azureUrl, error } = useGenerateUrlToAzureAd({
    flowType,
    callbackPath: '/app/connexion/callback',
    previousUrl: completionUrl,
    skip,
  })
  return {
    navigateToUrl: skipFlow ? completionUrl : undefined,
    redirectToUrl: azureUrl,
    invalidToken,
    technicalIssue: activationError ?? Boolean(error) ? true : undefined,
  }
}
