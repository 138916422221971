import React from 'react'
import { string, PropTypes } from 'prop-types'
import { Accordion, AccordionItem, Montant, Card, Section } from '@mmb/ui-components'
import { Separator } from '@core/partenaire-ui'
import { Gamme } from '@partenaire/common'
import { Prets } from '../../Prets'

import { DetailPlanFinancement } from '../../DetailPlanFinancement'
import { DetailFinancement } from '../DetailFinancement'
import { DetailExpertise } from '../DetailExpertise'
import styles from './PlanFinancement.module.css'

PlanFinancement.propTypes = {
  id: string.isRequired,
  montant: PropTypes.number.isRequired,
  gamme: PropTypes.string.isRequired,
  nomProduit: string,
}

PlanFinancement.defaultProps = {
  nomProduit: 'Produit Inconnu',
}

export function PlanFinancement({ id, montant, nomProduit, gamme }) {
  return (
    <Section title="Plan de financement">
      <Card className={styles.planFinancement__card}>
        <div className={styles.planFinancement__presentation}>
          {nomProduit && (<div className={styles.planFinancement__nom}>{nomProduit}</div>)}
          {montant &&
          (
            <Montant
              value={montant}
              wholeClassName={styles.planFinancement__whole}
              separatorClassName={styles.planFinancement__separator}
              decimalClassName={styles.planFinancement__decimal}
            />
          )}
        </div>
      </Card>

      <DetailPlanFinancement id={id} />

      <Card className={styles.planFinancement__card}>
        <Accordion allowZeroExpanded>
          <AccordionItem
            title={<h3 className={styles.planFinancement__title}>Détail</h3>}
            className={styles.planFinancement__accordion}
            hideIcon
            disableMargin
          >
            <DetailFinancement id={id} />
            <Separator className={styles.planFinancement__separator} />
            <Prets id={id} />
          </AccordionItem>
        </Accordion>
      </Card>

      {gamme === Gamme.SECURED && <DetailExpertise id={id} />}
    </Section>
  )
}
