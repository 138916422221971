import React, { useState } from 'react'
import cn from 'classnames'
import { array, string } from 'prop-types'

import { HelpSmall, Popover } from '@mmb/ui-components'

import styles from './PopoverCategorie.module.css'

PopoverCategorie.propTypes = {
  label: string.isRequired,
  items: array.isRequired,
}

export function PopoverCategorie({ label, items }) {
  const [openPopover, setOpenPopover] = useState(false)

  return (
    <Popover
      position="top"
      title={`Aide pour les pièces de la catégorie ${label}`}
      open={openPopover}
      wrapperClassName={styles.popoverCategorie__wrapperPopover}
      className={cn(styles.popoverCategorie__popover, styles.popoverCategorie__popoverBorder)}
      arrowClassName={styles.popoverCategorie__popoverBorder}
      content={(
        <>
          {items.map(item => (
            <div
              key={item}
              className={styles.popoverCategorie__popoverItem}
            >
              - {item}
            </div>
          ))}
        </>
      )}
    >
      <HelpSmall
        className={styles.popoverCategorie__popoverIconHelp}
        onClick={() => setOpenPopover(!openPopover)}
      />
    </Popover>
  )
}
