import React from 'react'
import classNames from 'classnames'
import { string, node, bool } from 'prop-types'
import { Layout, HalfCircle } from '@mmb/ui-components'

import styles from './PageBanner.module.css'

PageBanner.propTypes = {
  children: node,
  className: string,
  showLogo: bool,
}

PageBanner.defaultProps = {
  children: '',
  className: '',
  showLogo: true,
}

export function PageBanner({ className, children, showLogo }) {
  return (
    <Layout.Banner className={classNames(styles.pageBanner, className)}>
      <div className={styles.pageBanner__wrapper}>
        {showLogo && <HalfCircle className={styles.pageBanner__logo} />}
        <div className={styles.pageBanner__content}>
          {children}
        </div>
      </div>
    </Layout.Banner>
  )
}

