import React from 'react'
import { string, shape, arrayOf } from 'prop-types'
import cn from 'classnames'
import { FixedSizeList } from 'react-window'

import styles from './ListPiecesNecessaires.module.css'

ListPiecesManquantes.propTypes = {
  pieces: arrayOf(shape({
    categorie: string,
    libelle: string.isRequired,
    finalite: string.isRequired,
  })).isRequired,
}

export function ListPiecesManquantes({ pieces }) {
  return (
    <FixedSizeList
      className={styles.listPiecesNecessaires}
      height={pieces.length * 50}
      itemCount={pieces.length}
      itemSize={50}
    >
      {({ index, style }) => {
        const { libelle, finalite } = pieces[index]
        return (
          <div
            aria-label={`Pièce nécessaire ${index + 1}`}
            style={style}
            className={cn(styles.listPiecesNecessaires__line, {
              [styles.listPiecesNecessaires__lineStriped]: index % 2 === 0,
            })}
          >
            <span>{libelle}</span>
            <span className={styles.listPiecesNecessaires__lineStatut}>{finalite}</span>
          </div>
        )
      }}
    </FixedSizeList>
  )
}
