import React, { useEffect, useState, createContext, useContext, FormEvent, useMemo } from 'react'
import { node } from 'prop-types'
import { gql, useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../../../Contexts'
import { useGetEntityFormState, useHandleSubmit, useValidation } from '../../../FormState/form.state.hooks'
import { EntryFormStateName } from '../../../FormState/EntryFormState'
import { HandleStoreRevenuFormUI, RevenuFormUI, RevenuListUI, RevenusUI } from '../../../../model'
import { toRevenuFormUI, toRevenuGraphQL, toRevenuListUI } from './mappers'
import { ErrorMessage } from '../../../SaisieErrorMessages'
import { getRevenuErrorMessage } from '../../../../utils'
import { TypeAmortissement } from '../../../../model/demande/Demande'

export const GET_REVENUS_DOSSIER = gql`
query getRevenusDossier($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    emprunteurs {
        id
        revenus(dossierId: $id) {
          id
          type
          montant
          debut
          position
        }
    }
  }
}
`
export const CREATE_REVENU_DOSSIER = gql`
mutation createRevenu($id: ID!, $personneId: ID!, $revenu: partenaire_RevenuInput!) {
  partenaire_createRevenu(id: $id, personneId: $personneId, revenu: $revenu) {
    id
    type
    montant
    debut
    position
  }
}
`

export const UPDATE_REVENU_DOSSIER = gql`
mutation updateRevenu($id: ID!, $personneId: ID!, $revenu: partenaire_RevenuInput!) {
  partenaire_updateRevenu(id: $id, personneId: $personneId, revenu: $revenu) {
    id
    type
    montant
    debut
    fin
    position
  }
}
`

export const DELETE_REVENU_DOSSIER = gql`
mutation deleteRevenu($id: ID!, $personneId: ID!, $revenu: partenaire_RevenuInput!) {
  partenaire_deleteRevenu(id: $id, personneId: $personneId, revenu: $revenu) {
    id
  }
}
`

export interface RevenusContextUI {
  loading: boolean
  queryLoading: boolean
  error: any
  revenusUI: RevenusUI
  errors: any
  errorMessages: ErrorMessage[]
  showErrors: boolean
  handleSubmit: (event: FormEvent) => void
  handleRemoveRevenu: (index: number, removeOnEmprunteur: boolean) => void
  handleToogleBaisseRevenu: (toogleOnEmprunteur: boolean) => void
}

export const RevenusContext = createContext({} as RevenusContextUI)

export function useRevenus() {
  const context = useContext<RevenusContextUI>(RevenusContext)
  if (context === {} as unknown as RevenusContextUI) {
    throw new Error('useRevenus must be used within a RevenusProvider')
  }
  return context
}

RevenusProvider.propTypes = {
  children: node.isRequired,
}

export function RevenusProvider({ children }) {
  const navigate = useNavigate()
  const { saisieUI } = useSaisie()
  const { props: { dossierId, emprunteurId, coEmprunteurId, hasCoEmprunteur, gamme } }
    = saisieUI as { props: { dossierId, emprunteurId: string, coEmprunteurId: string, hasCoEmprunteur, gamme } }
  const [revenusUI, setRevenusUI] = useState<RevenusUI>(new RevenusUI(new RevenuListUI(), new RevenuListUI()))
  const [loadingRevenusUI, setLoadingRevenusUI] = useState(true)
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([])
  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.REVENU)
  const { entryFormState } = useGetEntityFormState(EntryFormStateName.MONTAGE)
  const { entryFormState: situationFamilialeFormState } = useGetEntityFormState(EntryFormStateName.SITUATION_FAMILIALE)
  const nombreEnfantsACharge = (situationFamilialeFormState?.value?.situationFamiliale?.enfants || []).filter(enfant => enfant.age < 18).length || 0

  useEffect(() => { revenusUI.withUpdateState(setRevenusUI).withHandleStoreRevenu(handleStoreRevenuFormUI) }, [revenusUI])
  useEffect(() => {
    if (!loadingRevenusUI) {
      revenusUI.setNombreEnfantsACharge(nombreEnfantsACharge)
    }
  }, [loadingRevenusUI, nombreEnfantsACharge])

  const { data, loading: queryLoading } = useQueryRedirectError(GET_REVENUS_DOSSIER, navigate, { fetchPolicy: 'network-only', variables: { id: dossierId } })

  const [createRevenu, { loading: createLoading, error: createError }] = useMutation(CREATE_REVENU_DOSSIER, {
    refetchQueries: [{ query: GET_REVENUS_DOSSIER, variables: { id: dossierId } }],
  })

  const [updateRevenu, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_REVENU_DOSSIER, {
    refetchQueries: [{ query: GET_REVENUS_DOSSIER, variables: { id: dossierId } }],
  })

  const [deleteRevenu, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_REVENU_DOSSIER, {
    refetchQueries: [{ query: GET_REVENUS_DOSSIER, variables: { id: dossierId } }],
  })

  const hasPalier = () => {
    const typeAmortissement = entryFormState?.value?.demande?.typeAmortissement || ''
    return typeAmortissement === TypeAmortissement.PALIER
  }

  useEffect(() => revenusUI.updatePalier(hasPalier()), [entryFormState?.value?.demande?.typeAmortissement])
  useEffect(() => {
    if (!queryLoading) {
      const [emprunteur, coEmprunteur] = data?.partenaire_dossierEnCours?.emprunteurs || []
      const revenusListEmprunteur = toRevenuListUI(emprunteurId, emprunteur?.revenus)
      const revenusListCoEmprunteur = toRevenuListUI(coEmprunteurId, coEmprunteur?.revenus)
      const newRevenusUI = new RevenusUI(revenusListEmprunteur, revenusListCoEmprunteur, hasCoEmprunteur, false, nombreEnfantsACharge)
      newRevenusUI.updatePalier(hasPalier())
      setRevenusUI(newRevenusUI.withUpdateState(setRevenusUI))
      setLoadingRevenusUI(false)
    }
  }, [data, queryLoading])

  const { errors, showErrors } = useValidation(EntryFormStateName.REVENU, queryLoading, { revenusUI }, { isSecured: saisieUI.isSecured() })

  useEffect(() => {
    setErrorMessages((errors.globalErrors || []).map(getRevenuErrorMessage).filter(e => e))
  }, [errors])

  const handleStoreRevenuFormUI: HandleStoreRevenuFormUI = async (personneId: string, revenu: RevenuFormUI): Promise<RevenuFormUI> => {
    const formattedRevenu = toRevenuGraphQL(revenu)
    return revenu.id ?
      updateRevenu({ variables: { id: dossierId, personneId, revenu: formattedRevenu } }).then(() => revenu) :
      createRevenu({ variables: { id: dossierId, personneId, revenu: formattedRevenu } })
        .then(createdRevenu => toRevenuFormUI(createdRevenu.data?.partenaire_createRevenu, personneId))
  }

  const removeRevenu = async (index: number, removeOnEmprunteur: boolean = true) => {
    const personneId = removeOnEmprunteur ? emprunteurId : coEmprunteurId
    const id = revenusUI.delete(index, removeOnEmprunteur)
    if (id) {
      await deleteRevenu({ variables: { id: dossierId, personneId, revenu: { id } } })
    }
  }
  const storeRevenu = async () => {
    await revenusUI.store()
    revenusUI.refreshState()
  }

  const handleToogleBaisseRevenu = async (toogleOnEmprunteur: boolean = true) => {
    const idRevenuToDelete = revenusUI.toogleBaisseRevenu(gamme === 'SECURED', toogleOnEmprunteur)
    const personneId = toogleOnEmprunteur ? emprunteurId : coEmprunteurId
    if (idRevenuToDelete) {
      await deleteRevenu({ variables: { id: dossierId, personneId, revenu: { id: idRevenuToDelete } } })
      await revenusUI.store()
    }
  }

  const handleSubmit = async (event?: FormEvent) => {
    if (revenusUI.hasBaisseRevenu()) {
      event?.preventDefault()
      return storeRevenu()
    }
    return doHandleSubmit(storeRevenu, event)
  }
  const handleRemoveRevenu =
    async (index: number, removeOnEmprunteur: boolean = true) => doHandleSubmit(() => removeRevenu(index, removeOnEmprunteur), undefined, true)

  const loading = loadingRevenusUI || updateLoading || createLoading || deleteLoading
  const error = updateError || createError || deleteError
  const value = useMemo(() => ({
    loading,
    queryLoading: loadingRevenusUI,
    error,
    errors,
    errorMessages,
    showErrors,
    revenusUI,
    handleSubmit,
    handleRemoveRevenu,
    handleToogleBaisseRevenu,
  }), [loading,
    loadingRevenusUI,
    error,
    errors,
    errorMessages,
    showErrors,
    revenusUI,
    handleSubmit,
    handleRemoveRevenu,
    handleToogleBaisseRevenu])
  return (
    <RevenusContext.Provider value={value}>
      {children}
    </RevenusContext.Provider>
  )
}
