import React from 'react'
import cn from 'classnames'
import { string } from 'prop-types'

import { Button, Plus } from '@mmb/ui-components'

import styles from './AddButton.module.css'

AddButton.propTypes = {
  label: string,
  className: string,
}

AddButton.defaultProps = {
  label: '',
  className: '',
}

export function AddButton({ className, label, ...props }) {
  return (
    <Button
      className={cn(styles.addButton, className)}
      aria-label={label}
      type="button"
      outline
      {...props}
    >
      <Plus className={styles.addButton__icon} />
      {label}
    </Button>
  )
}
