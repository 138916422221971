import React, { useEffect, useState } from 'react'
import { node } from 'prop-types'

export const HeadProvider = ({ children, head: Head }) => {
  const [hasVariables, setVariables] = useState(false)
  const [reload, setReload] = useState(false)
  useEffect(() => {
    if (!hasVariables) {
      setTimeout(() => {
        fetch('/env.gatsby.js').then(__ => {
          if (window.GATSBY_API_URL) {
            setVariables(true)
          } else {
            setReload(!reload)
          }
        })
      }, 100)
    }
  }, [hasVariables, reload])
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return hasVariables ? <>{children}</> : <Head />
}

HeadProvider.propTypes = {
  children: node.isRequired,
  head: node.isRequired,

}
