import React from 'react'
import { default as ReactSelect, createFilter } from 'react-select'
import { bool, func, string, shape, arrayOf, oneOfType, oneOf, array } from 'prop-types'
import classnames from 'classnames'

import styles from './Select.module.css'

Select.propTypes = {
  className: string,
  labelClassName: string,
  fieldClassName: string,
  label: string.isRequired,
  options: arrayOf(shape({
    value: string,
    label: string,
  })),
  onChange: func,
  name: string,
  defaultValue: arrayOf(shape({
    value: string,
    label: string,
  })),
  placeholder: string,
  isMulti: bool,
  required: bool,
  disabled: bool,
  value: oneOfType([
    string,
    array,
    shape({
      value: string,
      label: string,
    }),
  ]),
  hideLabel: bool,
  hideEmptyMenu: bool,
  hideDropdown: bool,
  hasError: bool,
  filterConfig: shape({
    ignoreCase: bool,
    ignoreAccents: bool,
    matchFrom: oneOf(['start', 'any']),
    stringify: func,
    trim: bool,
  }),
}

Select.defaultProps = {
  className: '',
  labelClassName: '',
  fieldClassName: '',
  options: [],
  onChange: () => {},
  name: 'tableFilter',
  defaultValue: null,
  placeholder: '',
  isMulti: false,
  required: false,
  disabled: false,
  value: undefined,
  hideLabel: false,
  hideEmptyMenu: false,
  hideDropdown: false,
  hasError: false,
  filterConfig: {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.label} ${option.value}`,
    trim: true,
  },
}

const styleOption = (isFocused, isSelected) => {
  const colorSecondary = isFocused || isSelected

  return {
    color: colorSecondary ? '#00a9e0' : '#13294B',
    fontSize: '1.4rem',
    minHeight: '4.8rem',
    borderRadius: 0,
    paddingLeft: '1rem',
    background: '#fff',
  }
}

const getStyleControl = (base, { isFocused, isSelected, isMulti }) => {
  const control = isMulti ? {
    paddingLeft: 0,
    height: 'auto',
    minHeight: '4.5rem', // default height on react-select
  } : {}

  return {
    ...base,
    ...styleOption(isFocused, isSelected),
    ...control,
  }
}

const getStyleOption = (base, { isFocused, isSelected, data, options }) => {
  const [firstOption] = options

  return {
    ...base,
    ...styleOption(isFocused, isSelected),
    // first option doesn't have border-top
    borderTop: data.value === firstOption.value ? 0 : '.1rem solid #ccc',
  }
}

const getComponents = ({ isMulti, hideDropdown, options, hideEmptyMenu }) => {
  const indicatorComponent = !isMulti ? {
    IndicatorSeparator: null,
  } : {}
  const dropdownComponent = hideDropdown ? {
    DropdownIndicator: null,
  } : {}
  const menuComponent = hideEmptyMenu && options.length === 0 ? {
    Menu: () => null,
  } : {}
  return {
    ...indicatorComponent,
    ...dropdownComponent,
    ...menuComponent,
  }
}

export function Select({ className, labelClassName, fieldClassName, label, hideLabel, name, required, disabled, hasError, filterConfig, ...props }) {
  const overrideComponents = getComponents(props)
  const enableRequired = !disabled && required

  let selectRef = null
  const setSelectRef = ref => {
    if (enableRequired) {
      selectRef = ref
    }
  }

  return (
    <div className={className}>
      {!hideLabel && (
        <label
          htmlFor={name}
          className={classnames(styles.select__label, labelClassName)}
        >
          {label}
        </label>
      )}
      <ReactSelect
        {...props}
        aria-label={label}
        name={name}
        inputId={name}
        className={classnames(styles.select__field, fieldClassName, { [styles.select__invalid]: hasError })}
        styles={{
          control: getStyleControl,
          option: getStyleOption,
          indicatorsContainer: base => ({
            ...base,
            border: 0,
          }),
          dropdownIndicator: base => ({
            ...base,
            color: '#13294b',
          }),
        }}
        components={overrideComponents}
        ref={setSelectRef}
        isDisabled={disabled}
        filterOption={createFilter(filterConfig)}
      />
      {enableRequired && (
        <input
          tabIndex={-1}
          autoComplete="off"
          className={styles.select__hidden}
          value={props.value || ''}
          onFocus={() => selectRef.focus()}
          required={required}
          onChange={() => {}}
        />
      )}
    </div>
  )
}
