
import React from 'react'
import { Unavailable } from '@mmb/ui-components'

import styles from './MeteoIndisponible.module.css'

export const MeteoIndisponible = () => (
  <div className={styles.meteoIndisponible}>
    <Unavailable className={styles.meteoIndisponible__icon} />
    <div className={styles.meteoIndisponible__text}>
      Les données ne sont pas accessibles pour le moment.
    </div>
  </div>
)
