import React from 'react'
import classNames from 'classnames'
import { toast as toastify } from 'react-toastify'
import { func } from 'prop-types'
import 'react-toastify/dist/ReactToastify.css'
import { Cross, CheckCircle, Error } from '@mmb/ui-components'

import styles from './Toast.module.css'

const CloseButton = ({ closeToast }) => (<Cross className={styles.toast__cross} onClick={closeToast} />)
CloseButton.propTypes = {
  closeToast: func.isRequired,
}

export function toast({ message, type, ...options }) {
  const defaultOptions = {
    hideProgressBar: true,
    draggable: false,
    closeButton: CloseButton,
    className: classNames(
      styles.toast,
      {
        [styles.toastSuccess]: type === 'SUCCESS',
        [styles.toastError]: type === 'ERROR',
      },
    ),
  }

  toastify((
    <div className={styles.toast__body}>
      {type === 'SUCCESS' ? (
        <CheckCircle className={styles.toast__icon} />
      ) : (
        <Error className={classNames(styles.toast__icon, styles.toast__iconError)} />
      )}
      {message}
    </div>
  ), {
    ...defaultOptions,
    ...options,
  })
}
