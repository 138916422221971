import { gql, useLazyQuery, DO_NOT_CHECK_CACHE } from '@core/graphql'

const GET_PERSONNE_EXISTE = gql`
query getPersonneExist($email: String!) {
  partenaire_existsPersonne(email: $email)
}
`

export function usePersonneExist() {
  const [getPersonneExist, { data = {}, loading, called }] = useLazyQuery(GET_PERSONNE_EXISTE, DO_NOT_CHECK_CACHE)

  if (loading) return [getPersonneExist, { loading, called }]

  return [
    email => getPersonneExist({
      variables: { email },
    }), {
      personneExist: data.partenaire_existsPersonne,
      loading,
    },
  ]
}
