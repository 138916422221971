import { gql, useMutation } from '@core/graphql'

import { toast } from '@core/ui'

import { USE_SEARCH_PERSONNES_QUERY } from '../List/List.hooks'

const ADD_PERSONNE = gql`
  mutation ($email: String!, $role: partenaire_RoleNom!, $agenceIds: [String], $cabinetIds: [String]) {
    partenaire_addPersonne(email: $email, role: $role, agences: $agenceIds, cabinets: $cabinetIds)
  }
`
export const useAddPersonne = () => {
  const [mutation, { personne }] = useMutation(ADD_PERSONNE, {
    refetchQueries: [USE_SEARCH_PERSONNES_QUERY],
    awaitRefetchQueries: true,
    onCompleted: () => toast({ message: 'Un nouvel utilisateur a été créé.', type: 'SUCCESS' }),
  })
  return [
    (email, agenceIds, role, cabinetIds) => mutation({
      variables: {
        email,
        agenceIds,
        role,
        cabinetIds,
      },
    }),
    personne,
  ]
}
