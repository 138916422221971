import React, { useRef } from 'react'
import classnames from 'classnames'

import { useSaisie } from '../../Contexts/SaisieContext'
import { CommunicationsForm } from './CommunicationsForm'
import { CommunicationsPlaceholder } from './CommunicationsPlaceholder'
import { useCommunications } from './Communications.hooks'

import styles from './Communications.module.css'
import { CommunicationsValidationErrors, useDelayedFormBlur } from '../../../utils'
import { useValidation } from '../../FormState/form.state.hooks'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { SaisieErrorMessages } from '../../SaisieErrorMessages'

const suffixes = {
  emprunteur: {
    label: 'de l\'emprunteur',
    name: 'emprunteur',
  },
  coEmprunteur: {
    label: 'du co-emprunteur',
    name: 'coEmprunteur',
  },
}

export function Communications() {
  const { saisieUI: { props: { hasCoEmprunteur } } } = useSaisie() as { saisieUI: { props: { hasCoEmprunteur: boolean } } }
  const {
    emprunteurCommunications,
    coEmprunteurCommunications,
    changeEmprunteurCommunications,
    changeCoEmprunteurCommunications,
    handleSubmit,
    loading,
  } = useCommunications()
  const { errors, showErrors } : { errors: CommunicationsValidationErrors, showErrors: boolean }
    = useValidation(EntryFormStateName.COMMUNICATIONS, loading, { emprunteurCommunications, coEmprunteurCommunications, hasCoEmprunteur })
  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)

  return (
    <>
      <div className={styles.communications__info}>
        <strong className={styles.communications__bold}>Le numéro de portable et l'adresse email de chaque emprunteur </strong>
        sont fortement conseillés pour la
        <strong className={styles.communications__bold}> signature électronique.</strong>
      </div>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        data-testid="communications-form"
        className={classnames(
          styles.communications,
          { [styles.communicationsLoading]: loading },
        )}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <div className={styles.communications__labels}>
          <p className={styles.communications__label}>Téléphone portable</p>
          <p className={styles.communications__label}>Téléphone fixe</p>
          <p className={styles.communications__label}>Email</p>
        </div>
        <CommunicationsPlaceholder loading={loading} hasCoEmprunteur={hasCoEmprunteur}>
          <CommunicationsForm
            suffixes={suffixes.emprunteur}
            communications={emprunteurCommunications}
            errors={errors.emprunteurErrors}
            showErrors={showErrors}
            setCommunications={changeEmprunteurCommunications}
          />
          {hasCoEmprunteur && (
            <CommunicationsForm
              suffixes={suffixes.coEmprunteur}
              communications={coEmprunteurCommunications}
              errors={errors.coEmprunteurErrors}
              showErrors={showErrors}
              setCommunications={changeCoEmprunteurCommunications}
            />
          )}
        </CommunicationsPlaceholder>
      </form>
      {showErrors && (<SaisieErrorMessages
        data-testid="comunication__errors"
        errors={errors.errorMessages}
      />)}
    </>
  )
}
