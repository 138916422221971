import React from 'react'

import { bool, func, node, string } from 'prop-types'
import { Warning } from '@mmb/ui-components'

import { Modal } from '@core/partenaire-ui'
import { ModalCard } from '../ModalCard'

import styles from './Confirmation.module.css'

Confirmation.propTypes = {
  isOpen: bool,
  onClose: func.isRequired,
  onValidate: func.isRequired,
  title: string.isRequired,
  children: node.isRequired,
  label: string,
  submitLabel: string,
  icone: func,
}

Confirmation.defaultProps = {
  icone: Warning,
  isOpen: false,
  label: '',
  submitLabel: 'Confirmer',
}

export function Confirmation({ icone: Icone, isOpen, onClose, onValidate, title, children, label, submitLabel }) {
  return (
    <Modal
      isOpen={isOpen}
      label={label}
    >
      <ModalCard
        header={(
          <header className={styles.confirmation__header}>
            <h3 className={styles.confirmation__title}>
              {title}
            </h3>
            <Icone className={styles.confirmation__iconWarning} />
          </header>
        )}
        onValidate={onValidate}
        onClose={onClose}
        submitLabel={submitLabel}
        hideArrow
      >
        {children}
      </ModalCard>
    </Modal>
  )
}
