import React, { useState } from 'react'
import { string, oneOf } from 'prop-types'

import { searchContext } from '@core/partenaire-common'

import { Users } from './components/Users'

UsersRoute.propTypes = {
  className: string,
  nomenclature: oneOf(['PARTENAIRE', 'PORTAIL_CGP']).isRequired,
}

UsersRoute.defaultProps = {
  className: undefined,
}

export function UsersRoute({ className, nomenclature }) {
  const searchState = useState('')
  const statutsState = useState()
  const sortState = useState('nom')
  const searchValue = {
    search: searchState,
    statuts: statutsState,
    sort: sortState,
  }
  return (
    <searchContext.Provider value={searchValue}>
      <Users path="/" className={className} nomenclature={nomenclature} />
    </searchContext.Provider>
  )
}
