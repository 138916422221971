import React from 'react'

import { EmptyDoc, Card } from '@mmb/ui-components'

import styles from './ListDossiersEmpty.module.css'

export function ListDossiersEmpty() {
  return (
    <Card className={styles.listDossiersEmpty}>
      <EmptyDoc className={styles.listDossiersEmpty__icon} />
      <p>
        Bientôt, vous retrouverez ici vos derniers dossiers modifiés.
      </p>
    </Card>
  )
}
