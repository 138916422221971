import { useState, useEffect } from 'react'

export function useCaptcha() {
  const [captchaLoaded, setCaptchaLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.setAttribute('data-id', 'recaptcha')
    script.async = true
    script.defer = true
    script.src = global.GATSBY_GOOGLE_RECAPTCHA_API_URL || 'https://www.google.com/recaptcha/api.js?onload=onloadCaptchaCallback&render=explicit&hl=fr'
    document.body.appendChild(script)

    window.onloadCaptchaCallback = () => {
      setCaptchaLoaded(true)
    }

    // eslint-disable-next-line consistent-return
    return () => {
      document.body.removeChild(script)
      delete window.onloadCaptchaCallback
    }
  }, [])
  return captchaLoaded
}
