import React, { FunctionComponent } from 'react'

import { useAssuranceEligibility } from './AssuranceSecured.hooks'
import { AssuranceType } from '../AssuranceType'
import { AssuranceGroupeDetail, SansAssuranceDetail } from '../AssuranceDetail'
import { AssuranceParcours } from '../AssuranceParcours/AssuranceParcours'
import { AssuranceIneligibility } from '../AssuranceIneligibility'
import { AssuranceNotDeclarable } from '../AssuranceNotDeclarable'
import { PersonneAssurance } from '../PersonneAssurance'

import styles from '../Assurance.module.css'
import { AssuranceUI } from '../../../../model/assurance/AssuranceUI'
import { AwaitingAssurance } from '../AssuranceParcours/AwaitingAssurance'
import { AssuranceWithoutType } from '../AssuranceWithoutType'

export const AssuranceSecured: FunctionComponent<{ assuranceUI: AssuranceUI, isAwaiting: boolean}> = ({ assuranceUI, isAwaiting }) => {
  const { loading, assuranceSecuredUI } = useAssuranceEligibility(assuranceUI)
  const { idDossier, idPersonne } = assuranceUI.props

  if (loading) return null

  return assuranceUI?.isAmorcageEnCours() || isAwaiting ? <AwaitingAssurance /> : (
    <>
      {assuranceSecuredUI.shouldShowPersonne() && <PersonneAssurance assuranceUI={assuranceUI} className={styles.assurance__personne} hideStatus />}
      {assuranceSecuredUI.shouldShowAssuranceNotDeclarable() && <AssuranceNotDeclarable />}
      {assuranceSecuredUI.shouldShowIneligible() && <AssuranceIneligibility />}
      {assuranceSecuredUI.shouldShowParcoursStartable() && <AssuranceParcours idDossier={idDossier} idPersonne={idPersonne} assuranceUI={assuranceUI} />}
      {assuranceSecuredUI.shouldShowAssuranceType() && <AssuranceType libelle={assuranceUI.getLibelleType()} organisme={assuranceUI.props.organisme} />}
      {assuranceSecuredUI.shouldShowAssuranceDCS() && <AssuranceGroupeDetail assuranceUI={assuranceUI} />}
      {assuranceSecuredUI.shouldShowSansAssurance() && <SansAssuranceDetail assuranceUI={assuranceUI} />}
      {assuranceSecuredUI.shouldShowNoAssuranceInformation() && <AssuranceWithoutType assuranceUI={assuranceUI} />}
    </>
  )
}
