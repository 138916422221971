import React, { useState } from 'react'
import { bool, func, string } from 'prop-types'
import classNames from 'classnames'

import { Plus } from '@mmb/ui-components'

import styles from './FileDock.module.css'

FileDock.propTypes = {
  name: string.isRequired,
  onUpload: func.isRequired,
  multiple: bool,
  showFormat: bool,
  className: string,
}

FileDock.defaultProps = {
  multiple: false,
  showFormat: true,
  className: '',
}

let globalId = 0

export function FileDock({ name, onUpload, multiple, className, showFormat }) {
  globalId++

  const inputRef = React.createRef()
  const [isHover, setHover] = useState(false)

  const handleDrop = event => {
    dropShield(event)
    setHover(false)
    if (event && event.dataTransfer) {
      onUpload(event.dataTransfer.files)
    }
  }

  const handleChange = () => {
    if (inputRef && inputRef.current) {
      onUpload(inputRef.current.files)
      inputRef.current.value = null
    }
  }

  const onDragOver = event => {
    dropShield(event)
    if (!isHover) setHover(true)
  }

  const onDragLeave = event => {
    dropShield(event)
    setHover(false)
  }

  return (
    <div
      className={classNames(
        styles.fileDock,
        { [styles.fileDockWithFormat]: showFormat },
        { [styles.fileDockHover]: isHover },
        className,
      )}
      onDrag={dropShield}
      onDragStart={dropShield}
      onDragEnd={dropShield}
      onDragOver={onDragOver}
      onDragEnter={dropShield}
      onDragLeave={onDragLeave}
      onDrop={handleDrop}
    >
      <label
        htmlFor={`file${globalId}`}
        className={styles.fileDock__label}
      >
        <input
          className={styles.fileDock__input}
          type="file"
          name={`${name}[]`}
          id={`file${globalId}`}
          multiple={multiple}
          onChange={handleChange}
          ref={inputRef}
          accept=".jpg,.jpeg,.tiff,.jfif,.bmp,.pdf,.png"
        />
        {showFormat && (
          <div className={styles.fileDock__format}>
            Glisser et déposer un fichier <br />
            (.pdf, .jpg ou .png 25 Mo max.)
          </div>
        )}
        <div>
          <div
            className={classNames(
              styles.fileDock__button,
              { [styles.fileDock__buttonWithFormat]: showFormat },
            )}
          >
            <Plus className={styles.fileDock__icon} />
            Ajouter
          </div>
        </div>
      </label>
    </div>
  )
}

function dropShield(event) {
  event.preventDefault()
  event.stopPropagation()
}
