import { gql, useQueryWithError, DO_NOT_CHECK_CACHE } from '@core/graphql'
import { formatCondenseDate } from '@core/common'

import { EXPERTISES } from '../../../utils/const'

export const GET_DOSSIER_STATUT = gql`
query getHistorique($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    id
    historique {
      statut
    }
  }
}`

export const GET_EXPERTISE = gql`
query getExpertise($id: String!) {
  partenaire_dossierTransmis(id: $id) {
    id
    propositionCredit {
      expertise {
        type
        dateLancement
        estimation
        ratioHypo
        ratioHypoMax
      }
    }
  }
}`

export function useExpertise(id) {
  const {
    data = {},
    loading,
  } = useQueryWithError(GET_EXPERTISE, {
    ...DO_NOT_CHECK_CACHE,
    errorPolicy: 'all',
    variables: { id },
  })

  if (loading) return { loading }

  // eslint-disable-next-line camelcase
  const expertise = data?.partenaire_dossierTransmis?.propositionCredit?.expertise
  if (!expertise || !expertise?.type) return {}

  const { type, dateLancement, estimation, ratioHypo, ratioHypoMax } = expertise

  if (type === 'EXPERTISE_MAC' && dateLancement) {
    return {
      type: formatType('EXPERTISE_IMMOBILIERE'),
      dateLancement: formatDate(dateLancement),
      ratio: {
        value: '-',
        maxValue: formatRatioMax(ratioHypoMax),
        isExceedingMaxValue: false,
      },
      estimation: null,
    }
  }

  return {
    type: formatType(type),
    dateLancement: formatDate(dateLancement),
    estimation,
    ratio: {
      value: formatRatio(ratioHypo),
      maxValue: formatRatioMax(ratioHypoMax),
      isExceedingMaxValue: ratioHypo > ratioHypoMax,
    },
  }
}

export function useExpertiseInformation(id) {
  const {
    data = {},
    loading,
  } = useQueryWithError(GET_DOSSIER_STATUT, {
    notifyOnNetworkStatusChange: true,
    variables: { id },
  })

  if (loading) return { loading }
  // eslint-disable-next-line camelcase
  const historique = data?.partenaire_dossierTransmis?.historique || []
  const allowedStatus = ['SANS_SUITE', 'REFUSE', 'ACCORDE']
  // if historique contains any of the allowedStatus, isExpertiseEditable = false
  const isExpertiseEditable = !historique
    .map(({ statut }) => statut)
    .some(statut => allowedStatus.includes(statut))
  return { isExpertiseEditable }
}

function formatType(type) {
  if (!type) return ''
  return EXPERTISES.find(({ value }) => value === type).label
}

function formatRatioMax(ratioMax) {
  if (!ratioMax) return '(-)'
  return `(${Math.round(ratioMax)} %)`
}

function formatRatio(ratio) {
  if (!ratio) return '-'
  return `${Math.round(ratio)} %`
}

function formatDate(date) {
  if (!date) return '-'
  return formatCondenseDate(date)
}
