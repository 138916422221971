import cn from 'classnames'
import React from 'react'
import { string } from 'prop-types'

import { FixedSizeList } from 'react-window'

import { TickEtapes } from '@mmb/ui-components'
import { usePrets } from './Prets.hooks'
import styles from './Prets.module.css'

Prets.propTypes = {
  id: string.isRequired,
}

export function Prets({ id }) {
  const { loading, prets } = usePrets(id)
  if (loading) return null
  return (
    <div
      role="table"
      // eslint-disable-next-line react/no-unknown-property
      described-by="prets_table_description"
      aria-label="Prets"
      className={styles.prets__list}
    >
      <div role="rowgroup">
        <div role="row" className={cn(styles.prets__line, styles.prets__header)}>
          <span role="columnheader" className={styles.prets__center}>Repris</span>
          <span role="columnheader">Nature du prêt</span>
          <span role="columnheader">Organisme</span>
          <span role="columnheader" className={styles.prets__right}>Crd avec IRA</span>
          <span role="columnheader" className={styles.prets__right}>Taux</span>
          <span role="columnheader" className={styles.prets__right}>Echéance</span>
        </div>
      </div>
      {prets && (
        <div role="rowgroup">
          <FixedSizeList
            height={prets.length * 40}
            itemCount={prets.length}
            itemSize={40}
          >
            {({ index, style }) => {
              const { cdcAvecIra, taux, echeance, repris, nature, nomPreteur } = prets[index]
              return (
                <div
                  role="row"
                  aria-label="Pret"
                  style={style}
                  className={cn(styles.prets__line, {
                    [styles.prets__lineStriped]: index % 2 !== 0,
                  })}
                >
                  <span role="cell" className={styles.prets__center}>
                    {repris && (
                    <TickEtapes
                      data-testid={`pret_${index}_repris`}
                      className={styles.prets__tickEtapes}
                    />
                    )}
                  </span>
                  <span role="cell" className={styles.prets__bold}>{nature}</span>
                  <span role="cell" className={styles.prets__uppercase}>{nomPreteur}</span>
                  <span role="cell" className={styles.prets__right}>
                    {cdcAvecIra}
                  </span>
                  <span role="cell" className={styles.prets__right}>
                    {taux}
                  </span>
                  <span role="cell" className={styles.prets__right}>
                    {echeance}
                  </span>
                </div>
              )
            }}
          </FixedSizeList>
        </div>
      )}
    </div>
  )
}
