import React from 'react'
import { func, shape, string, number, arrayOf, bool } from 'prop-types'
import { Select, Separator, Tooltip } from '@core/partenaire-ui'
import { InputMontant } from '@partenaire/frontend-ui'

import { Cross } from '@mmb/ui-components'

import styles from './ChargeInput.module.css'

import { clearEmpty, hasErrorForName as hasError, parseMontant } from '../../../../../utils'
import { ChargeFormUI, CHARGE_OPTIONS } from '../../../../../model'

ChargeInput.propTypes = {
  suffixes: shape({
    label: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  charge: shape({
    id: string,
    montant: number,
    nature: string,
  }).isRequired,
  removeCharge: func,
  setCharge: func,
  index: number,
  errors: arrayOf(
    shape({
      field: string,
      message: string,
    }),
  ),
  showErrors: bool,
}

ChargeInput.defaultProps = {
  removeCharge: () => {},
  setCharge: () => {},
  index: 0,
  errors: [],
  showErrors: false,
}

export function ChargeInput({ suffixes, charge, removeCharge, setCharge, index, errors, showErrors }) {
  const chargeFormUI = ChargeFormUI.buildChargeFormUI(charge)
  const hasErrorForName = (name: string) => hasError(name, errors, showErrors)
  const foundCharge = chargeFormUI.getChargeOption()
  const numero = index + 1
  const tooltip = chargeFormUI.getTooltip()
  return (
    <div className={styles.chargeInput__container} aria-label={`Charge ${suffixes.label} numero ${numero}`}>
      <div className={styles.chargeInput__selectContainer}>
        <Select
          className={styles.chargeInput__select}
          options={CHARGE_OPTIONS}
          hasError={hasErrorForName('nature')}
          hideLabel
          placeholder="Nature de charge"
          name={`${numero}.charges.nature`}
          label={`Type de charge ${foundCharge?.label || 'non défini '} ${suffixes.label}`}
          onChange={({ value }) => setCharge(ChargeFormUI.buildChargeFormUI({ ...charge, nature: value }), index)}
          value={foundCharge}
        />
        {tooltip && (<Tooltip
          wrapperClassName={styles.chargeInput__tooltip}
          title={chargeFormUI?.nature}
          content={(<p>{tooltip?.content}</p>)}
        />)}

        <button
          aria-label={`Suppression de la charge n° ${numero}`}
          type="button"
          className={styles.chargeInput__buttonCross}
          onClick={e => {
            e.preventDefault()
            removeCharge(chargeFormUI, index)
          }}
        >
          <Cross />
        </button>
      </div>
      <InputMontant
        className={styles.chargeInput__dynamicInput}
        name={`${numero}.charges.value`}
        hasError={hasErrorForName('montant')}
        label={`Montant de la charge ${numero} ${suffixes.label}`}
        hideLabel
        onChange={({ target }) => setCharge(ChargeFormUI.buildChargeFormUI({ ...charge, montant: parseMontant(target.value) }), index)}
        value={clearEmpty(chargeFormUI?.montant)}
      />
      <Separator />
    </div>
  )
}
