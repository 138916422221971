import React from 'react'
import { number, string } from 'prop-types'

import { TextRow, Cross } from '@mmb/ui-components'

import styles from './DettePlaceholder.module.css'


DettePlaceholder.propTypes = {
  titre: string.isRequired,
  numero: number.isRequired,
}

export function DettePlaceholder({ titre, numero }) {
  return (
    <div aria-label={`Rachat de la dette n° ${numero}`}>
      <div className={styles.dettePlaceholder__titre}>
        {`${titre}`}
        <div className={styles.dettePlaceholder__titreDroit}>
          <button
            className={styles.dettePlaceholder__removeButton}
            type="button"
            aria-label={`Suppression de la dette n° ${numero}`}
          >
            <Cross className={styles.dettePlaceholder__cross} />
          </button>
        </div>
      </div>
      <div className={styles.dettePlaceholder__contour}>
        <div className={styles.dettePlaceholder__ligne}>
          <div className={styles.dettePlaceholder__left}>
            <div className={styles.dettePlaceholder__wrapper}>
              <div className={styles.dettePlaceholder__label}>Nature de la dette</div>
              <TextRow width="100%" className={styles.dettePlaceholder__input} />
            </div>
            <div className={styles.dettePlaceholder__wrapper}>
              <div className={styles.dettePlaceholder__label}>Bénéficiaire</div>
              <TextRow width="100%" className={styles.dettePlaceholder__input} />
            </div>
            <div className={styles.dettePlaceholder__ligne}>
              <div className={styles.dettePlaceholder__wrapperDate}>
                <div className={styles.dettePlaceholder__label}>Début</div>
                <TextRow width="20rem" className={styles.dettePlaceholder__inputBas} />
              </div>
              <div className={styles.dettePlaceholder__wrapperDate}>
                <div className={styles.dettePlaceholder__label}>Fin</div>
                <TextRow width="20rem" className={styles.dettePlaceholder__inputBas} />
              </div>
            </div>
          </div>

          <div className={styles.dettePlaceholder__right}>
            <div className={styles.dettePlaceholder__ligne}>
              <div className={styles.dettePlaceholder__wrapper}>
                <div className={styles.dettePlaceholder__label}>Capital restant dû total</div>
                <TextRow width="100%" className={styles.dettePlaceholder__input} />
              </div>
            </div>
            <div className={styles.dettePlaceholder__ligne}>
              <div className={styles.dettePlaceholder__wrapper}>
                <div className={styles.dettePlaceholder__label}>Capital initial (optionnel)</div>
                <TextRow width="100%" className={styles.dettePlaceholder__input} />
              </div>
            </div>
            <div className={styles.dettePlaceholder__ligne}>
              <div className={styles.dettePlaceholder__wrapper}>
                <div className={styles.dettePlaceholder__label}>Echéance</div>
                <TextRow width="100%" className={styles.dettePlaceholder__input} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
