import React from 'react'
import { Button, Card, Hourglass, Retry, NotFound } from '@mmb/ui-components'

import { useTransmissionHandler } from './TransmissionHandler.hooks'

import styles from './TransmissionHandler.module.css'

const TRANSMISSION_MESSAGES = {
  EN_ATTENTE: {
    icon: props => <Hourglass {...props} />,
    title: 'Transfert en cours',
    content: [
      'Votre simulation est en cours de transfert.',
      'Cette action peut prendre quelques minutes.',
      'Nous vous invitons à revenir plus tard ou à cliquer sur “actualiser”.',
    ],
    showRetryButton: true,
  },
  EN_ERREUR: {
    icon: NotFound,
    title: 'Echec du transfert',
    content: [
      'Le transfert de votre simulation n’a pas abouti.',
      'Cette action peut prendre quelques minutes.',
      'Merci de contacter la Cellule Commerciale au 02 51 89 59 20 ou votre Responsable Commercial.e.',
    ],
    showRetryButton: false,
  },
}

export function TransmissionHandler() {
  const { checkTransmission, statut, loading } = useTransmissionHandler()
  const message = TRANSMISSION_MESSAGES[statut]
  if (loading) return null

  return (
    <div className={styles.transmissionHandler}>
      <Card className={styles.transmissionHandler__card}>
        <h2 className={styles.transmissionHandler__title}>{message?.title}</h2>
        {message?.icon && (<message.icon className={styles.transmissionHandler__icon} />)}
        <div className={styles.transmissionHandler__content}>
          {message?.content.map((line, index) => (
          // eslint-disable-next-line react/no-array-index-key
            <p key={`transmission-line-${index}`} className={styles.transmissionHandler__content__line}>{line}</p>
          ))}
        </div>
        {message?.showRetryButton && (
        <Button
          leftIcon={Retry}
          onClick={checkTransmission}
          iconClassName={styles.transmissionHandler__button__icon}
          className={styles.transmissionHandler__button}
        >
          Actualiser
        </Button>
        )}
      </Card>
    </div>
  )
}
