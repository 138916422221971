import { merge } from 'lodash'
import { HEADER_MAP_ID_CABINET_TRAVAIL } from '@core/common'
import { fetch } from '@core/common-frontend'
import { useUtilisateurContext } from '../contexts'

// eslint-disable-next-line no-undef
export type FetchFunction = (urlOrBackendPath: string, options?: RequestInit) => Promise<Response>

/**
 * @return fonction fetch pour laquelle l'id du cabinet de travail du CGP est ajouté à la requête
 */
export function useFetch(): FetchFunction {
  const { idCabinetTravail } = useUtilisateurContext()
  return (urlOrBackendPath, options = {}) => {
    const headers = {}
    if (idCabinetTravail) {
      headers[HEADER_MAP_ID_CABINET_TRAVAIL] = idCabinetTravail
    }
    return fetch(urlOrBackendPath, merge({ headers }, options))
  }
}
