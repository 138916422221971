/* eslint-disable jsx-a11y/label-has-associated-control */
import { func } from 'prop-types'
import React from 'react'
import { useUtilisateurContext } from '@core/partenaire-common'
import { Separator } from '../Separator'
import { CabinetHeader } from './CabinetHeader'
import styles from './SelectionCabinetHeader.module.css'


SelectionCabinetHeader.propTypes = {
  setOpen: func.isRequired,
}

/**
 * Composant affichant une liste de Cabinet pour en permettre la sélection pour le contexte
 */
export function SelectionCabinetHeader({ setOpen, ...props }) {
  const { loading, cabinets } = useUtilisateurContext()
  if (loading || cabinets.length < 2) {
    return null
  }

  return (
    <div {...props}>
      <div className={styles.selection_pm_header}>
        {(cabinets && cabinets.length > 1) && cabinets.map(cabinet => {
          return <CabinetHeader key={cabinet.id} cabinet={cabinet} onClick={() => setOpen(false)} />
        })}
      </div>
      <Separator />
    </div>
  )
}
