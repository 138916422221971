import React from 'react'

import { Card, Section, Accordion } from '@mmb/ui-components'
import { Separator, getConsent } from '@core/partenaire-ui'
import { smartProfileTrackWithParams } from '@core/analytics'

import { PARTENAIRE_CONTACT_PHONE, PARTENAIRE_CONTACT_EMAIL } from '@core/common-frontend'
import { Item } from '../Item'
import { Telephone } from '../Telephone'


import styles from './Contact.module.css'

export function Contact() {
  smartProfileTrackWithParams('&_title=Contact', getConsent())

  return (
    <div className={styles.contact}>
      <h2 className={styles.contact__title}>Nous contacter</h2>

      <Card>
        <Section title="Vos Services My Money Bank">
          <Accordion allowZeroExpanded>
            <Item title="La Cellule Commerciale">
              <p className={styles.contact__informations}>
                <Telephone>{PARTENAIRE_CONTACT_PHONE}</Telephone>
                {PARTENAIRE_CONTACT_EMAIL}
              </p>
            </Item>

            <Separator />

            <Item title="L'Équipe Préfiltrage">
              <p className={styles.contact__informations}>
                <Telephone>02 40 20 91 46</Telephone>
                prefiltrage@mymoneybank.com
              </p>
            </Item>

            <Separator />

            <Item title="L'Équipe Analyse">
              <p className={styles.contact__informations}>
                <span className={styles.contact__dossier}>
                  Dossier Hypothécaire :
                  <Telephone>02 40 20 91 15</Telephone>
                </span>
                Dossier Sans Garantie :
                <Telephone>02 40 20 91 74</Telephone>
              </p>
            </Item>

            <Separator />

            <Item title="L'Équipe Réalisation et Assurance Groupe">
              <p className={styles.contact__informations}>
                <Telephone>02 51 89 56 95</Telephone>
                realisation@mymoneybank.com
              </p>
            </Item>

          </Accordion>
        </Section>
      </Card>

      <Card>
        <Section title="Vos Services Techniques">
          <Accordion allowZeroExpanded>
            <Item title="Hotline My Access Pro">
              <p className={styles.contact__informations}>
                <Telephone>02 51 89 56 98</Telephone>
                myaccessproDC@mymoneybank.com
              </p>
            </Item>

            <Separator />

            <Item title="Hotline AXA/WebCreditor">
              <p className={styles.contact__informations}>
                <Telephone>01 57 32 47 14</Telephone>
                clp.fr.assistancewebcreditor@partners.axa
              </p>
            </Item>
          </Accordion>
        </Section>
      </Card>
    </div>
  )
}
