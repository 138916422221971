import { CreanceUI } from './CreanceUI'
import { DetteFormUI, detteToCreanceUI } from './DetteFormUI'
import { EncoursFormUI, EncoursType, isRepris } from './EncoursFormUI'
import { EncoursTotal } from './EncoursTotal'
import { PretFormUI, prettoCreanceUI } from './PretFormUI'
import { PositionUI } from '../support/PositionUI'
import { Creance } from './Creance'

export class EncoursUI extends PositionUI<EncoursFormUI> {
  private loading: boolean

  constructor(list: EncoursFormUI[], loading: boolean = false) {
    super(list)
    this.loading = loading
  }

  protected clone(): EncoursUI {
    return new EncoursUI([...this.list]).withUpdateState(this.setState).withLoading(this.loading)
  }

  public updateRepris(index: number, repris: boolean) {
    this.update(index, { ...this.list[index], repris })
  }

  public calculateTotalEncours(): EncoursTotal {
    const toCreanceUI = (encoursFormUI: EncoursFormUI) => encoursFormUI.type === EncoursType.PRET ?
      prettoCreanceUI(encoursFormUI as PretFormUI) : detteToCreanceUI(encoursFormUI as DetteFormUI)

    return EncoursUI.computeTotals(this.list.map(toCreanceUI))
  }

  public getPrets(): PretFormUI[] {
    return this.list.filter(encoursFormUI => encoursFormUI.type === EncoursType.PRET) as PretFormUI[]
  }

  public getDettes(): DetteFormUI[] {
    return this.list.filter(encoursFormUI => encoursFormUI.type !== EncoursType.PRET) as DetteFormUI[]
  }

  public getPretsRepris(): PretFormUI[] {
    return this.getPrets().filter(isRepris)
  }

  public getDettesRepris(): DetteFormUI[] {
    return this.getDettes().filter(isRepris)
  }

  public hasPretsRepris(): boolean {
    return this.getPretsRepris().length > 0
  }

  public hasDettesRepris(): boolean {
    return this.getDettesRepris().length > 0
  }

  public getEncoursWithDisplayablePosition(): EncoursFormUI[] {
    let detteCount = 1
    let pretCount = 1
    return this.getSortedList().map(encoursFormUI => {
      const titre = EncoursUI.isPret(encoursFormUI) ? `Pret ${pretCount++}` : `Dette ${detteCount++}`
      return { ...encoursFormUI, titre }
    })
  }

  public getCreancesAReprendre(): { prets: Creance[], dettes: Creance[] } {
    const creances = this.list.filter(encoursFormUI => encoursFormUI.id && encoursFormUI.repris)
    return {
      prets: creances.filter(encoursFormUI => EncoursUI.isPret(encoursFormUI)).map(({ id, personneId }) => ({ creanceId: id, personneId })),
      dettes: creances.filter(encoursFormUI => !EncoursUI.isPret(encoursFormUI)).map(({ id, personneId }) => ({ creanceId: id, personneId })),
    }
  }

  private withLoading(loading: boolean): EncoursUI {
    this.loading = loading
    return this
  }

  public static isPret(encoursFormUI: EncoursFormUI) {
    return encoursFormUI.type === EncoursType.PRET
  }

  private static computeTotals(creances: CreanceUI[]): EncoursTotal {
    return creances.reduce((acc, { capitalRestantDu, echeance, indemnitesDeRemboursementAnticipe, repris }) => {
      if (repris) {
        const totalCrdRepris = capitalRestantDu + indemnitesDeRemboursementAnticipe + acc.totalCrdRepris
        const totalEcheancesReprises = echeance + acc.totalEcheancesReprises
        return { ...acc, totalCrdRepris, totalEcheancesReprises }
      }

      const totalCrdConserves = capitalRestantDu + acc.totalCrdConserves
      const totalEcheancesConserves = echeance + acc.totalEcheancesConserves
      return { ...acc, totalCrdConserves, totalEcheancesConserves }
    }, EncoursTotal.defaultEncoursTotal())
  }
}
