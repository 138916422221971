import { PermissionScope } from '@core/common'

import { useHasPermission, useUtilisateurMultiPartenaire } from '@core/partenaire-common'

export function useAccueil() {
  useUtilisateurMultiPartenaire()

  const hasPermissionDossierWrite = useHasPermission(
    PermissionScope.DOSSIER_WRITE,
  )

  return hasPermissionDossierWrite
}
