import React from 'react'
import { string, any, bool } from 'prop-types'

import { Produit } from '../Produit'
import { SimulationCard } from '../SimulationCard'
import { Header } from '../Header'
import { Diagnostic } from '../Diagnostic'

import styles from './SimulationContent.module.css'
import { NON_VALIDE_SIMULATION_STATUS, SimulationStatus, VALIDE_SIMULATION_STATUS } from '../const'

SimulationContent.propTypes = {
  error: any,
  loading: bool.isRequired,
  status: string,
}

SimulationContent.defaultProps = {
  error: undefined,
  status: '',
}

export function SimulationContent({ status, error, loading }) {
  if (!loading && error) {
    return <SimulationCard state={SimulationStatus.INTERNAL_ERROR} />
  }

  if (!loading && status === SimulationStatus.NON_ELIGIBLE) {
    return <SimulationCard state={SimulationStatus.NON_ELIGIBLE} />
  }

  if (!loading && NON_VALIDE_SIMULATION_STATUS.includes(status)) {
    return (
      <>
        <Header />
        <SimulationCard state={SimulationStatus.NON_VALIDE} />
      </>
    )
  }

  if (!loading && VALIDE_SIMULATION_STATUS.includes(status)) {
    return (
      <>
        <Header />
        <div className={styles.simulation_content}>
          <Diagnostic />
          <Produit />
        </div>
      </>
    )
  }

  return null
}
