import React, { FunctionComponent } from 'react'
import { number } from 'prop-types'
import { RectShape } from '@mmb/ui-components'

import styles from './Groupes.module.css'
import { GroupeUI } from '../../../model/OutilsUI'
import { Groupe } from './Groupe'

interface GroupesProps {
  groupes: GroupeUI[]
  idRubrique: string
}

export const Groupes: FunctionComponent<GroupesProps> = ({ groupes, idRubrique }) => (
  <>
    {groupes.map((groupe, idx) => (
      <Groupe groupe={groupe} idRubrique={idRubrique} key={`${idx + 1}`} />
    ))}
  </>
)

export const GroupesPlaceholder = ({ nbElements }) => (
  <>
    {Array.from(Array(nbElements)).map((e, idx) => <RectShape height="9rem" className={styles.sections__detail} key={`${idx + nbElements}`} />)}
  </>
)

GroupesPlaceholder.propTypes = {
  nbElements: number.isRequired,
}
