import React, { useContext } from 'react'
import { bool, func, string } from 'prop-types'
import classNames from 'classnames'

import { Button, Card, Cross, Warning } from '@mmb/ui-components'

import { modalContext } from '@core/partenaire-ui'
import styles from './WarningConfirmationModal.module.css'

WarningConfirmationModal.propTypes = {
  title: string,
  warningLine: string,
  warningLineBold: string,
  onConfirm: func,
  loading: bool,
  confirmText: string,
  loadingText: string,
  cancelText: string,
}

WarningConfirmationModal.defaultProps = {
  onConfirm: () => {},
  loading: false,
  title: '',
  warningLine: '',
  warningLineBold: '',
  confirmText: '',
  loadingText: '',
  cancelText: '',
}

export function WarningConfirmationModal({ title, warningLine, warningLineBold, onConfirm, loading, confirmText, loadingText, cancelText }) {
  const { setOpen } = useContext(modalContext)

  const onDelete = async () => {
    if (loading) return
    await onConfirm()
    setOpen(false)
  }

  return (
    <Card className={styles.deleteConfirmationModal__card}>
      <header className={styles.deleteConfirmationModal__header}>
        <h3 className={styles.deleteConfirmationModal__title}>
          {title}
        </h3>
        <Warning className={styles.deleteConfirmationModal__iconWarning} />
      </header>

      <button
        onClick={() => setOpen(false)}
        aria-label="Fermer"
        className={styles.deleteConfirmationModal__close}
      >
        <Cross />
      </button>

      <div className={styles.deleteConfirmationModal__form}>
        {warningLine && <p className={styles.deleteConfirmationModal__warningLine}>{warningLine}</p>}
        {warningLineBold && (
        <p
          className={classNames(styles.deleteConfirmationModal__warningLine,
            styles.deleteConfirmationModal__strong)}
        >{warningLineBold}
        </p>)}

        <div className={styles.deleteConfirmationModal__buttons}>
          <Button
            type="button"
            outline
            className={styles.deleteConfirmationModal__cancel}
            onClick={() => setOpen(false)}
          >
            {cancelText}
          </Button>
          <Button
            type="button"
            onClick={onDelete}
            loading={loading}
            disabled={loading}
          >
            {loading ? loadingText : confirmText}
          </Button>
        </div>
      </div>
    </Card>
  )
}
