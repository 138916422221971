import React from 'react'
import { Page404 } from '@core/partenaire-frontend'
import { isBrowser } from '@core/common-frontend'
import { Head } from '../components/Head'


// Pourquoi ne rendre la page 404 que sur le browser ?
// C'est pour gérer le cas où l'utilisateur arrive directement dans l'espace SPA (ou qu'il rafraichit son navigateur).
// Comme cet "ensemble de pages" n'existe pas sous la forme de fichiers html, la navigateur se prend une erreur 404 en appelant le serveur et arrive donc ici.
// Le flux de rendu est alors le suivant: rendu du contenu HTML (SSR) => chargement du javascript => hydratation et rendu des composants React
// Le fait de rendre la page 404 uniquement sur le navigateur permet de "désactiver" le rendu SSR et éviter ainsi un affichage de la page 404 le temps que
// le javascript se charge et que le router Gatsby prenne la décision de:
// - soit afficher l'espace SPA (selon la configuration du plugin 'gatsby-plugin-create-client-paths')
// - soit rester sur cette page et donc afficher le composant 404
// @see https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-484741119 pour l'issue qui présente cette méthode

// Note 1: L'affichage de la page 404 doit aussi être géré dans le <Router /> SPA (pour toutes les routes inconnues dans cet espace)
// Note 2: On pourrait gérer ce comportement côté server en configurant le frontal pour servir la page d'index dès qu'on est dans l'espace SPA:
// https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/#understanding-client-only-routes

export default function NotFoundPage() {
  return (
    <>
      <Head />
      {isBrowser && <Page404 />}
    </>
  )
}
