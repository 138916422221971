import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { ToggleButton } from '@mmb/ui-components'

import styles from './Source.module.css'
import { LabelUI } from '../../../../model'
import { NATURE_PRET_INFOS } from '../../../../model/encours/const'

interface SourceProps {
  suffixes: LabelUI
  setSource: (value: any) => void
  label: string
  className: string
  source: string
  hasError: boolean
}

export const Source: FunctionComponent<SourceProps> = ({ source, suffixes, setSource, hasError, label, className, ...props }) => {
  return (
    <div
      className={classnames(styles.source, className)}
      aria-invalid={hasError}
      {...props}
    >
      {NATURE_PRET_INFOS.map(sourceOption => {
        const isChecked = sourceOption.value === source
        return (
          <abbr
            title={sourceOption.tooltip}
            key={`source.${sourceOption.name}.${suffixes.name}`}
          >
            <ToggleButton
              name={`${suffixes.name}.source.${sourceOption.name}`}
              className={classnames(styles.source__option, {
                [styles.source__optionsChecked]: isChecked,
              })}
              labelClassName={styles.source__label}
              onChange={() => setSource(sourceOption.value)}
              checked={sourceOption.value === source}
              value={sourceOption.value}
              label={sourceOption.label}
              aria-label={`${label} - ${sourceOption.label}`}
            />
          </abbr>
        )
      })}
    </div>
  )
}
