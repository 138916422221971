export * from './date'
export * from './extranet-prospect'
export * from './partenaire'
export * from './validation'
export { capitalize, concatString } from './string'
export { NATIONALITES_OPTIONS } from './nationalites'
export * from './portals'
export * from './emails'

// Même Regexp que celle utilisée par le flow AzureAD B2C (entre 12 et 64 caractères,
// au moins 3 parmi 4 : Majuscule, minuscule, symbole, numérique)
export const PASSWORD_REGEXP = '^(?:' +
  '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)' +
  '|' +
  '(?=.*[a-z])(?=.*[A-Z])(?=.*[\\-@#$%^&*_!+=\\[\\]\\{\\}\\|\\\\:\',.?\\/`~"\\(\\);])' +
  '|' +
  '(?=.*[a-z])(?=.*\\d)(?=.*[\\-@#$%^&*_!+=\\[\\]\\{\\}\\|\\\\:\',.?\\/`~"\\(\\);])' +
  '|' +
  '(?=.*[A-Z])(?=.*\\d)(?=.*[\\-@#$%^&*_!+=\\[\\]\\{\\}\\|\\\\:\',.?\\/`~"\\(\\);])' +
  ')' +
  '[A-Za-z0-9\\-@#$%^&*_!+=\\[\\]\\{\\}\\|\\\\:\',.?\\/`~"\\(\\); ]{12,64}' +
  '$'
