import React, { FunctionComponent } from 'react'

import { DocumentPreview, DocumentPreviewPlaceholder } from './DocumentPreview'

import styles from './Favoris.module.css'
import { DocumentUI } from '../../../model/OutilsUI'

interface FavorisProps {
  documents?: DocumentUI[]
}

export const Favoris: FunctionComponent<FavorisProps> = ({ documents }) => (
  <div className={styles.favoris}>
    {documents?.map(document => (<DocumentPreview document={document} key={document.id} />))}
  </div>
)

export const FavorisPlaceholder = () => (
  <div className={styles.favoris}>
    <DocumentPreviewPlaceholder />
    <DocumentPreviewPlaceholder />
    <DocumentPreviewPlaceholder />
  </div>
)

Favoris.defaultProps = {
  documents: [],
}
