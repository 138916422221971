import React from 'react'
import { useMatch } from 'react-router-dom'
import { shape, string } from 'prop-types'

import { MenuLink } from '@core/partenaire-ui'

LayoutMenuLink.propTypes = {
  link: shape({ path: string, name: string }).isRequired,
}

export function LayoutMenuLink({ link: { path, name } }) {
  return (
    <MenuLink isActive={!!useMatch(`${path}/*`)} to={path}>{name}</MenuLink>
  )
}
