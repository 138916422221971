import { gql, useQueryWithError } from '@core/graphql'

export const GET_AGENCES = gql`
query getAgences {
  partenaire_agences {
    id
    nom
    referenceExterne
  }
}
`

export const toAgenceOption = ({ id, nom, referenceExterne }) => ({
  value: id,
  label: nom,
  codeApr: referenceExterne,
})

export function useAgenceOptions(setAgence) {
  const { data, loading } = useQueryWithError(GET_AGENCES, { notifyOnNetworkStatusChange: true })
  // eslint-disable-next-line camelcase
  const availableAgences = data?.partenaire_agences || []
  const agenceOptions = availableAgences.length > 0 ? availableAgences.map(toAgenceOption) : []
  const onChangeSelectedOptions = selectedAgenceOptions => {
    setAgence(selectedAgenceOptions || {})
  }
  return { agenceOptions, loading, onChangeSelectedOptions }
}
