import { OptionUI } from './OptionUI'

export enum SituationMatrimoniale {
  CELIBATAIRE = 'CELIBATAIRE',
  VEUF = 'VEUF',
  DIVORCE = 'DIVORCE',
  INSTANCE_DE_DIVORCE = 'INSTANCE_DE_DIVORCE',
  MARIE = 'MARIE',
  PACSE = 'PACSE',
  EN_CONCUBINAGE = 'EN_CONCUBINAGE',
}

export const SITUATION_MATRIMONIALE_OPTIONS = {
  single: [
    new OptionUI('Célibataire', SituationMatrimoniale.CELIBATAIRE),
    new OptionUI('Veuf', SituationMatrimoniale.VEUF),
    new OptionUI('Divorcé', SituationMatrimoniale.DIVORCE),
    new OptionUI('Instance de divorce', SituationMatrimoniale.INSTANCE_DE_DIVORCE),
    new OptionUI('Marié en séparation de biens', SituationMatrimoniale.MARIE)],
  couple: [
    new OptionUI('Pacsé', SituationMatrimoniale.PACSE),
    new OptionUI('Marié', SituationMatrimoniale.MARIE),
    new OptionUI('Concubin', SituationMatrimoniale.EN_CONCUBINAGE)],
}
