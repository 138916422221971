import { useContext, useEffect, useState } from 'react'
import { capitalize } from '@core/common'

import { useSaisie } from '../Contexts'
import { FiligraneUI } from './FiligraneUI'
import { FormStateContext } from '../Contexts/FormStateContext'
import { EntryFormStateName } from '../FormState/EntryFormState'
import { useGetEntityFormState } from '../FormState/form.state.hooks'

export const formatEmprunteurName = identite => {
  if (!identite) return ''
  const { nom, prenom } = identite
  if (!nom && !prenom) return 'Non renseigné'
  return `${capitalize(prenom) || ''} ${nom?.toUpperCase() || ''}`
}

export function useEmprunteursCommunications(hasCoEmprunteur) {
  const [emprunteurEmail, setEmprunteurEmail] = useState('')
  const [coEmprunteurEmail, setCoEmprunteurEmail] = useState('')
  const [emprunteurMobile, setEmprunteurMobile] = useState('')
  const [coEmprunteurMobile, setComprunteurMobile] = useState('')
  const [emprunteurDomicile, setEmprunteurDomicile] = useState('')
  const [coEmprunteurDomicile, setCoEmprunteurDomicile] = useState('')
  const { entryFormState } = useGetEntityFormState(EntryFormStateName.COMMUNICATIONS)

  useEffect(() => {
    if (entryFormState) {
      setEmprunteurEmail(entryFormState?.rawValue?.emprunteurCommunications?.email?.value)
      setEmprunteurMobile(entryFormState?.rawValue?.emprunteurCommunications?.mobile?.value)
      setEmprunteurDomicile(entryFormState?.rawValue?.emprunteurCommunications?.domicile?.value)
      if (hasCoEmprunteur) {
        setCoEmprunteurEmail(entryFormState?.rawValue?.coEmprunteurCommunications?.email?.value)
        setComprunteurMobile(entryFormState?.rawValue?.coEmprunteurCommunications?.mobile?.value)
        setCoEmprunteurDomicile(entryFormState?.rawValue?.coEmprunteurCommunications?.domicile?.value)
      }
    }
  }, [entryFormState?.rawValue?.coEmprunteurCommunications?.email?.value,
    entryFormState?.rawValue?.coEmprunteurCommunications?.mobile?.value,
    entryFormState?.rawValue?.coEmprunteurCommunications?.domicile?.value,
    entryFormState?.rawValue?.emprunteurCommunications?.email?.value,
    entryFormState?.rawValue?.emprunteurCommunications?.mobile?.value,
    entryFormState?.rawValue?.emprunteurCommunications?.domicile?.value,
  ])

  return { emprunteurEmail, emprunteurMobile, emprunteurDomicile, coEmprunteurEmail, coEmprunteurMobile, coEmprunteurDomicile }
}

export function useEmprunteursNames() {
  const { saisieUI: { props: { hasCoEmprunteur } } } = useSaisie()
  const [emprunteurName, setEmprunteurName] = useState('')
  const [coEmprunteurName, setCoEmprunteurName] = useState('')
  const { entryFormState } = useGetEntityFormState(EntryFormStateName.IDENTITE)

  useEffect(() => {
    if (entryFormState) {
      setEmprunteurName(formatEmprunteurName({ nom: entryFormState?.value?.emprunteur?.nom, prenom: entryFormState?.value?.emprunteur?.prenom }))
      if (hasCoEmprunteur) {
        setCoEmprunteurName(formatEmprunteurName({ nom: entryFormState?.value?.coEmprunteur?.nom, prenom: entryFormState?.value?.coEmprunteur?.prenom }))
      }
    }
  }, [entryFormState?.value?.coEmprunteur?.nom,
    entryFormState?.value?.coEmprunteur?.prenom,
    entryFormState?.value?.emprunteur?.nom,
    entryFormState?.value?.emprunteur?.prenom])
  return { emprunteurName, coEmprunteurName }
}

export function useFiligrane(): FiligraneUI {
  const formState = useContext(FormStateContext)
  const { saisieUI } = useSaisie()

  return new FiligraneUI(formState, saisieUI.props.gamme, saisieUI.props.apporteur)
}
