import React, { FunctionComponent } from 'react'
import { Link, useParams } from '@core/routing'
import classNames from 'classnames'
import { TextRow } from '@mmb/ui-components'
import styles from './RubriqueLink.module.css'
import { RubriqueUI } from '../../../../model/OutilsUI'

interface RubriqueLinkProps {
  rubrique: RubriqueUI
}

export const RubriqueLink: FunctionComponent<RubriqueLinkProps> = ({ rubrique }) => {
  const { rubriqueId } = useParams()
  return (
    <Link
      className={classNames(styles.rubrique, { [styles.activeRubrique]: rubriqueId === rubrique.id })}
      to={`/app/outils/${rubrique.id}`}
    >
      {rubrique.nom}
    </Link>
  )
}

export const RubriqueLinkPlaceholder = () => (
  <div className={styles.rubrique}>
    <TextRow />
  </div>
)
