/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react'

import { AddButton } from '@partenaire/frontend-ui'
import { nth } from 'lodash'
import { RevenuForm } from './RevenuForm'
import { useRevenus } from '../RevenusContext'

import styles from './Revenus.module.css'
import { useDelayedFormBlur } from '../../../../utils'
import { RevenusFormPlaceholder } from './RevenusFormPlaceholder'
import { BaisseRevenuPlaceholder } from './BaisseRevenuPlaceholder'
import { BaisseRevenu } from './BaisseRevenu'
import { BudgetTotal } from '../../BudgetTotal'
import { LabelUI, RevenusUI } from '../../../../model'
import { SaisieErrorMessages } from '../../../SaisieErrorMessages'

const suffixesEmprunteur: LabelUI = { label: 'de l\'emprunteur', name: 'emprunteur' }
const suffixesCoEmprunteur: LabelUI = { label: 'du co-emprunteur', name: 'coEmprunteur' }

export function Revenus() {
  const { loading, errors, errorMessages, showErrors, handleSubmit, handleRemoveRevenu, revenusUI } = useRevenus()
  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)

  return (
    <>
      <form
        data-testid="revenu-form"
        ref={formRef}
        onSubmit={handleSubmit}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <div className={styles.revenus}>
          <div className={styles.revenus__lists}>
            {revenusUI.emprunteurRevenus.list.map((revenu, index) => (
              RevenusUI.isListable(revenu) && (
              <RevenusFormPlaceholder key={`revenu_emp_placeholder_${index}`} loading={loading}>
                <RevenuForm
                  key={`revenu_emp_${index}`}
                  suffixes={suffixesEmprunteur}
                  revenu={revenu}
                  setRevenu={newRevenu => revenusUI.update(index, newRevenu, true)}
                  removeRevenu={(_, idx) => handleRemoveRevenu(idx, true)}
                  index={index}
                  errors={nth(errors.emprunteurErrors, index)}
                  showErrors={showErrors}
                />
              </RevenusFormPlaceholder>)))}
          </div>
          {revenusUI.hasCoEmprunteur && (
          <div className={styles.revenus__lists}>
            {revenusUI.coEmprunteurRevenus.list.map((revenu, index) => (
              RevenusUI.isListable(revenu) && (
              <RevenusFormPlaceholder key={`revenu_coEmp_placeholder_${index}`} loading={loading}>
                <RevenuForm
                  key={`revenu_coEmp_${index}`}
                  suffixes={suffixesCoEmprunteur}
                  revenu={revenu}
                  index={index}
                  setRevenu={newRevenu => revenusUI.update(index, newRevenu, false)}
                  removeRevenu={(_, idx) => handleRemoveRevenu!(idx, false)}
                  errors={nth(errors.coEmprunteurErrors, index)}
                  showErrors={showErrors}
                />
              </RevenusFormPlaceholder>)))}
          </div>
          )}
        </div>
        <div className={styles.revenus__button}>
          <AddButton
            label="Ajouter un revenu"
            aria-label="Ajouter un revenu pour emprunteur"
            className={styles.revenuForm__button}
            disabled={loading}
            onClick={() => revenusUI.add(true)}
          />
          {revenusUI.hasCoEmprunteur && (
          <AddButton
            label="Ajouter un revenu"
            aria-label="Ajouter un revenu pour co-emprunteur"
            className={styles.revenuForm__button}
            disabled={loading}
            onClick={() => revenusUI.add(false)}
          />)}
        </div>
        <BaisseRevenuPlaceholder
          loading={loading}
          hasReducedRevenueEmprunteur={revenusUI.emprunteurRevenus.hasBaisseRevenu()}
          hasCoEmprunteur={revenusUI.hasCoEmprunteur}
          hasReducedRevenueCoEmprunteur={revenusUI.coEmprunteurRevenus.hasBaisseRevenu()}
        >
          <BaisseRevenu errors={errors} showErrors={showErrors} />
        </BaisseRevenuPlaceholder>
      </form>

      {showErrors && (<SaisieErrorMessages
        errors={errorMessages}
      />)}
      <BudgetTotal
        type="revenus"
        total={revenusUI.getTotalRevenus()}
        withAllocationFamiliales={revenusUI.getMontantAllocationFamiliales()}
      />
    </>
  )
}
