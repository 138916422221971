import React from 'react'
import { useIzanamiFeatureByName, useIzanamiConfigurationByName, BandeauInfo } from '@core/ui'
import { Maintenance as HeadlessMaintenance } from '@core/partenaire-frontend'

import { Head } from '../components/Head'
import styles from './maintenance.module.css'

const Maintenance = () => {
  const { configuration } = useIzanamiConfigurationByName('contenu-bandeau-info')
  const { active, firstFetchPending } = useIzanamiFeatureByName('bandeau-info')
  const displayBandeau = active && !firstFetchPending
  return (
    <>
      {displayBandeau && <BandeauInfo className={styles.layout__bandeau} message={configuration?.contenu} />}
      <Head />
      <HeadlessMaintenance />
    </>
  )
}

export default Maintenance

