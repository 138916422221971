import { AssuranceFormPropsUI, AssuranceFormUI } from './AssuranceFormUI'
import { LabelUI } from '../LabelUI'
import { SupportStateUI } from '../support'

export class AssuranceUI extends SupportStateUI {
  public static readonly SUFFIXE_EMPRUNTEUR = new LabelUI('de l’emprunteur', 'emprunteur')

  public static readonly SUFFIXE_COEMPRUNTEUR = new LabelUI('du co-emprunteur', 'coEmprunteur')

  constructor(
    public readonly emprunteurAssurance: AssuranceFormUI,
    public readonly coEmprunteurAssurance: AssuranceFormUI,
    public readonly hasCoEmprunteur: boolean) {
    super()
  }

  public shouldDisplayGarantie(): boolean {
    return this.emprunteurAssurance.hasAssurance() ||
     (this.hasCoEmprunteur && this.coEmprunteurAssurance.hasAssurance())
  }

  public update(emprunteurAssurance: AssuranceFormUI, coEmprunteurAssurance: AssuranceFormUI): void {
    this.emprunteurAssurance.update(emprunteurAssurance.props)
    this.coEmprunteurAssurance.update(coEmprunteurAssurance.props)
    this.refreshState()
  }

  public updateAssuranceFormUI(assurance: AssuranceFormPropsUI, updateOnEmprunteur: boolean): void {
    if (updateOnEmprunteur) {
      this.emprunteurAssurance.update(assurance)
    } else {
      this.coEmprunteurAssurance.update(assurance)
    }
    this.refreshState()
  }

  public updateIfDifferentDateAssuranceFormUI(assurance: AssuranceFormPropsUI, updateOnEmprunteur: boolean): void {
    if (updateOnEmprunteur) {
      this.emprunteurAssurance.updateIfDifferentDate(assurance)
    } else {
      this.coEmprunteurAssurance.updateIfDifferentDate(assurance)
    }
    this.refreshState()
  }

  public updateValidActivityTypeFormUI(assurance: AssuranceFormPropsUI, updateOnEmprunteur: boolean): void {
    if (updateOnEmprunteur) {
      this.emprunteurAssurance.updateValidActivityType(assurance)
    } else {
      this.coEmprunteurAssurance.updateValidActivityType(assurance)
    }
    this.refreshState()
  }

  protected clone() {
    return new AssuranceUI(this.emprunteurAssurance, this.coEmprunteurAssurance, this.hasCoEmprunteur).withUpdateState(this.setState)
  }

  private static toValidableProps(props: AssuranceFormPropsUI) : AssuranceFormPropsUI {
    return {
      type: props.type,
      quotite: props.quotite,
      formule: props.formule,
    } || {}
  }

  public getAdditionalValues() {
    return {
      emprunteur: {
        ...this.emprunteurAssurance.props,
      },
      coEmprunteur: {
        ...this.coEmprunteurAssurance.props,
      },
      hasCoEmprunteur: this.hasCoEmprunteur,
    }
  }
}
