const getDefaultHeaders = () => ({
  'Content-Type': 'application/json',
})

export interface Commune {
  codePostal: string
  nom: string
  codeInsee: string
}

const fetchURL = async (url: string, searchParams?: URLSearchParams) : Promise<any[]> => {
  const searchString = searchParams ? `?${searchParams.toString()}` : ''
  const queryParams = {
    method: 'GET',
    headers: { ...getDefaultHeaders() },
  }
  const response = await fetch(`${url}${searchString}`, queryParams)
  if (response.ok) {
    return response.status !== 204 ? response.json() : []
  }
  const error = { status: response.status, message: await response.text() }
  throw error
}

const getCommunes = async (query: { codePostal?: string, nom?: string }) : Promise<Commune[]> => {
  // on cherche les arrondissements uniquement pour les villes qui en disposent
  const COMMUNES_AVEC_ARRONDISSEMENTS = ['PARIS', 'MARSEILLE', 'LYON']
  const url = `${(window as any).GATSBY_API_COMMUNES}/communes`

  const communes = await fetchURL(url, new URLSearchParams(query))

  const arrondissementsSearch = communes?.map(async commune => {
    if (!COMMUNES_AVEC_ARRONDISSEMENTS.includes(String(commune.nom).toUpperCase())) {
      return commune
    }
    const params = new URLSearchParams({ nom: commune.nom, type: 'arrondissement-municipal', fields: 'nom,code,codesPostaux' })
    const arrondissements = await fetchURL(url, params)
    if (!arrondissements || arrondissements.length === 0) {
      return commune
    }
    return arrondissements
  })
  const res = await Promise.all(arrondissementsSearch)
  return res.flat().flatMap(({ codesPostaux, nom, code }) => codesPostaux.map(codePostal => ({
    codePostal,
    nom,
    codeInsee: code,
  })))
}

export const suggestionsCommune = async (query: { codePostal?: string, ville?: string }) : Promise<Commune[]> => {
  if (query.codePostal) {
    return getCommunes({ codePostal: query.codePostal })
  }
  return getCommunes({ nom: query.ville })
}
