import { useEffect, useState } from 'react'
import { useQueryRedirectError, useMutation } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../../Contexts'
import { DetteFormUI } from '../../../model'
import { CREATE_DETTE_DOSSIER, DELETE_DETTE_DOSSIER, GET_DETTES_DOSSIER, UPDATE_DETTE_DOSSIER } from './graphql-queries'
import { toDetteFormUI, toDetteGraphQL, toDettesFormUI } from '../mappers'

export function useDettes() {
  const navigate = useNavigate()
  const { saisieUI: { props: { dossierId, emprunteurId } } } = useSaisie() as { saisieUI: { props: { dossierId: string, emprunteurId: string } } }
  const [dettes, setDettes] = useState<DetteFormUI[]>([])
  const [loadingDettesUI, setLoadingDettesUI] = useState(true)
  const { data, loading: queryLoading } = useQueryRedirectError(GET_DETTES_DOSSIER, navigate, { fetchPolicy: 'network-only', variables: { id: dossierId } })
  const [createDette, { loading: createLoading }] = useMutation(CREATE_DETTE_DOSSIER)
  const [updateDette, { loading: updateLoading }] = useMutation(UPDATE_DETTE_DOSSIER, {
    refetchQueries: [{ query: GET_DETTES_DOSSIER, variables: { id: dossierId } }],
  })
  const [deleteDette, { loading: deleteLoading }] = useMutation(DELETE_DETTE_DOSSIER, {
    refetchQueries: [{ query: GET_DETTES_DOSSIER, variables: { id: dossierId } }],
  })

  useEffect(() => {
    if (!queryLoading) {
      const [emprunteur, coEmprunteur] = data?.partenaire_dossierEnCours?.emprunteurs || []
      setDettes([...toDettesFormUI(emprunteur), ...toDettesFormUI(coEmprunteur)])
      setLoadingDettesUI(false)
    }
  }, [queryLoading])

  async function handleUpdateDette(detteFormUI: DetteFormUI): Promise<DetteFormUI> {
    const detteGraphQL = toDetteGraphQL(detteFormUI)
    return detteGraphQL?.id ?
      updateDette({ variables: { id: dossierId, personneId: detteFormUI.personneId, dette: detteGraphQL } })
        .then(() => detteFormUI) :
      createDette({ variables: { id: dossierId, personneId: emprunteurId, dette: detteGraphQL } })
        .then(createdDette => toDetteFormUI(detteFormUI, emprunteurId, createdDette.data?.partenaire_createDette.id))
  }

  const handleRemoveDette = async (detteToDelete: DetteFormUI) => {
    if (detteToDelete?.id) {
      await deleteDette({ variables: { id: dossierId, personneId: detteToDelete.personneId, detteId: detteToDelete.id } })
    }
  }

  return {
    loading: loadingDettesUI || createLoading || updateLoading || deleteLoading,
    dettes,
    handleUpdateDette,
    handleRemoveDette,
  }
}
