import React from 'react'
import { string, func, shape, bool } from 'prop-types'
import classnames from 'classnames'

import { TextRow } from '@mmb/ui-components'
import { Select } from '@core/partenaire-ui'

import { useAgenceOptions } from './SelectAgency.hooks'

import styles from './SelectAgency.module.css'

SelectAgency.propTypes = {
  agence: shape({
    id: string,
    nom: string,
  }),
  setAgence: func,
  hasError: bool,
  className: string,
}

SelectAgency.defaultProps = {
  agence: null,
  setAgence: () => {},
  hasError: false,
  className: '',
}

export function SelectAgency({ agence, hasError, setAgence, className }) {
  const { agenceOptions, loading, onChangeSelectedOptions } = useAgenceOptions(setAgence)

  return (
    <div className={classnames(styles.selectAgency, className)}>
      <span className={styles.selectAgency__label}>associé à l'agence</span>
      {loading ?
        (<TextRow width="35rem" className={styles.selectAgency__placeholder} />) :
        (<Select
          htmlFor="agency.select"
          hasError={hasError}
          options={agenceOptions}
          onChange={onChangeSelectedOptions}
          value={agence}
          className={styles.selectAgency__row}
          fieldClassName={styles.selectAgency__field}
          label="associé à l'agence"
          name="agence"
          hideLabel
          defaultValue={[agence]}
          required
        />)}
    </div>
  )
}
