import { OptionUI } from '../OptionUI'

export class SourcePretOptionUI extends OptionUI {
  public readonly name: string

  public readonly tooltip: string

  constructor(label: string, value: string, name: string, tooltip: string) {
    super(label, value)
    this.name = name
    this.tooltip = tooltip
  }
}
