import { CreanceUI } from './CreanceUI'
import { EncoursFormUI, EncoursType } from './EncoursFormUI'

export enum NatureDette {
  DETTE_FISCALE = 'DETTE_FISCALE',
  DETTE_PRIVEE = 'DETTE_PRIVEE',
  DETTE_HUISSIER = 'DETTE_HUISSIER',
  RETARD_DE_LOYER = 'RETARD_DE_LOYER',
  RETARD_DE_CHARGES_DE_COPRO = 'RETARD_DE_CHARGES_DE_COPRO',
  DETTE_SOCIETE_DE_RECOUVREMENT = 'DETTE_SOCIETE_DE_RECOUVREMENT',
  SOULTE = 'SOULTE',
}

export interface DetteFormUI extends EncoursFormUI {
  montantCapitalInitial: number
  montantEcheance: number
  montantCrd: number
  beneficiaire?: string
  nature?: NatureDette
  debut?: string
  fin?: string
}

export const buildDefaultDette = (): DetteFormUI => ({
  type: EncoursType.DETTE,
  repris: true,
  montantCapitalInitial: 0,
  montantCrd: 0,
  montantEcheance: 0,
})

export const detteToCreanceUI = (dette: DetteFormUI): CreanceUI => ({
  repris: dette.repris,
  capitalRestantDu: Number(dette.montantCrd || 0),
  echeance: Number(dette.montantEcheance || 0),
  indemnitesDeRemboursementAnticipe: 0,
})
