function getCodePostal(codePostal: string): number {
  return Number(codePostal?.slice(0, 5))
}

function isCodePostalValid(department: number): boolean {
  return (department >= 1_000 && department < 96_000) || (Math.floor(department / 100) === 974)
}

export function isResidenceValid(codePostal: string, gamme: string): boolean {
  const codePostalNumber = getCodePostal(codePostal)
  const isValid = codePostalNumber >= 1_000 && codePostalNumber < 96_000
  const isValidSecured = gamme === 'SECURED' && codePostalNumber >= 97_100 && codePostalNumber < 97_500
  return isValid || isValidSecured
}

export function isBienValid(codePostal: string): boolean {
  const department = getCodePostal(codePostal)
  return isCodePostalValid(department) && (Math.floor(department / 1_000) !== 20)
}
