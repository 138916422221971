/* eslint-disable no-restricted-imports */
import { useQuery } from '@apollo/client'
import { errorHandledByErrorEmitter } from './client'

export { useQuery, useMutation, useLazyQuery } from '@apollo/client'

/**
 * Wrapper around the react-apollo-hook useQuery that throw errors (in the main loop of the hook so during the rendering!),
 * except for UnauthenticatedError and ForbiddenError. It means that with these exceptions the data part of the result will be empty
 * while isLoading will be false. Caller need to protect against those cases.
 * @param query
 * @param options
 * @returns QueryHookResult<TData, TVariables> from which we removed the errors attribute
 */
export function useQueryWithError(query, options) {
  const { error, ...result } = useQuery(query, options)
  if (error && !errorHandledByErrorEmitter(error.graphQLErrors)) {
    throw error
  }

  return result
}
