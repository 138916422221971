import { useEffect } from 'react'
import { useUtilisateurContext } from './UtilisateurContextProvider'

export function useUtilisateurMultiPartenaire() {
  const { loading, idCabinetTravail, setIdCabinetTravail, cabinets } = useUtilisateurContext()

  useEffect(() => {
    if (!loading && !idCabinetTravail && cabinets && cabinets.length > 1) {
      setIdCabinetTravail(cabinets[0].id)
    }
  }, [idCabinetTravail, loading])
}
