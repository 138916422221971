import React from 'react'
import { node, bool, shape, string, number } from 'prop-types'

import { TextRow, Placeholder } from '@mmb/ui-components'

import styles from './ActivitePlaceholder.module.css'

import { ActiviteHeader } from '../ActiviteForm/ActiviteHeader'
import { CATEGORIES_ACTIVITES_OPTIONS } from '../../../../utils'


ActivitePlaceholder.propTypes = {
  children: node.isRequired,
  loading: bool,
  index: number,
  suffixes: shape({
    label: string.isRequired,
    name: string.isRequired,
  }).isRequired,

}

ActivitePlaceholder.defaultProps = {
  loading: false,
  index: 0,
}

export function ActivitePlaceholder({ children, loading, suffixes, index }) {
  return (
    <Placeholder
      loading={loading}
      customPlaceholder={(
        <div className={styles.activitePlaceholderContainer}>
          <ActiviteHeader suffixeLabel={suffixes.label} />
          <div className={styles.activitePlaceholder}>
            <div className={styles.activitePlaceholder__toggles} aria-label={`${suffixes.name}.activite.isPrincipale`}>
              {CATEGORIES_ACTIVITES_OPTIONS.map(categorie => (
                <TextRow width="12rem" className={styles.activitePlaceholder__toggle} key={`${index}-${suffixes.name}-${categorie?.value}`} />
              ))}
            </div>
            <span aria-label={`${suffixes.name}.activite.type`}>
              <TextRow width="35rem" className={styles.activitePlaceholder__row} />
            </span>
            <span aria-label={`${suffixes.name}.activite.categorieSocioPro`}>
              <TextRow width="35rem" className={styles.activitePlaceholder__row} />
            </span>
            <span aria-label={`${suffixes.name}.activite.profession`}>
              <TextRow width="35rem" className={styles.activitePlaceholder__row} />
            </span>
            <span aria-label={`${suffixes.name}.activite.type.contrat`}>
              <TextRow width="35rem" className={styles.activitePlaceholder__row} />
            </span>
            <span aria-label={`${suffixes.name}.activite.debut`}>
              <TextRow width="35rem" className={styles.activitePlaceholder__row} />
            </span>
          </div>
        </div>
      )}
    >
      {children}
    </Placeholder>
  )
}
