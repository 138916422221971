import { useReducer } from 'react'

const errorInitialState = undefined

export const VERIFICATION_IMPOSSIBLE = 'VERIFICATION_IMPOSSIBLE'
export const CONNEXION_IMPOSSIBLE = 'CONNEXION_IMPOSSIBLE'
export const RESET_ERROR = 'RESET_ERROR'
export const CREATION_IMPOSSIBLE = 'CREATION_IMPOSSIBLE'
export const MOT_DE_PASSE_INCORRECT = 'MOT_DE_PASSE_INCORRECT'
export const MOT_DE_PASSE_IDENTIQUE = 'MOT_DE_PASSE_IDENTIQUE'
export const BAD_CREDENTIALS = 'BAD_CREDENTIALS'
export const EMAIL_NON_CONFIRME = 'EMAIL_NON_CONFIRME'
export const EMAIL_INTROUVABLE = 'EMAIL_INTROUVABLE'
export const EMAIL_EXISTANT = 'EMAIL_EXISTANT'
export const LOCKED_DOCUMENT = 'LOCKED_DOCUMENT'
export const LOCKED_RECEPTION = 'LOCKED_RECEPTION'
export const CREATION_COMPTE_IMPOSSIBLE = 'CREATION_COMPTE_IMPOSSIBLE'

export function useErrorReducer() {
  return useReducer(errorReducer, errorInitialState)
}

function errorReducer(state, action) {
  const errors = new Map([
    [CREATION_IMPOSSIBLE, {
      title: 'Création impossible',
      message: 'Le lien de confirmation de l\'Email n\'est plus valide. Pour des raisons de sécurité, veuillez recommencer.',
    }],
    [BAD_CREDENTIALS, {
      title: 'Connexion impossible',
      message: 'Votre Email ou mot de passe actuel est incorrect. Veuillez vérifier les données et réessayer.',
    }],
    [EMAIL_NON_CONFIRME, {
      title: 'Email non confirmé.',
      message: `Pour activer et protéger votre compte, connectez-vous en cliquant sur le bouton
      "J'active mon Espace Personnel" dans l'Email de confirmation.`,
    }],
    [VERIFICATION_IMPOSSIBLE, {
      title: 'Vérification impossible',
      message: 'Votre mot de passe actuel est incorrect. Veuillez vérifier les données et réessayer.',
    }],
    [CREATION_COMPTE_IMPOSSIBLE, {
      title: 'Création impossible',
      message: 'Veuillez vérifier les données et réessayer.',
    }],
    [CONNEXION_IMPOSSIBLE, {
      title: 'Connexion impossible',
      message: 'Le service est momentanément indisponible, veuillez nous excuser pour la gêne occasionnée.',
    }],
    [MOT_DE_PASSE_INCORRECT, {
      title: 'Mot de passe incorrect',
      message: 'Les mots de passe saisis sont différents. Veuillez vérifier les données et réessayer.',
    }],
    [MOT_DE_PASSE_IDENTIQUE, {
      title: 'Mot de passe incorrect',
      message: 'Mot de passe déjà utilisé. Veuillez indiquer un mot de passe différent.',
    }],
    [EMAIL_INTROUVABLE, {
      title: 'Email introuvable',
      message: 'Votre Email est introuvable. Veuillez cliquer sur le lien que vous avez reçu par Email.',
    }],
    [EMAIL_EXISTANT, {
      title: 'Création impossible',
      message: 'Cet email est déjà utilisé.',
    }],
    [LOCKED_DOCUMENT, {
      title: 'Documents verrouillés',
      message: 'Veuillez valider la lecture des documents dans lˈordre dˈaffichage pour déverrouiller les autres documents.',
    }],
    [LOCKED_RECEPTION, {
      title: 'Réception de lˈoffre',
      message: 'Veuillez lire lˈensemble des documents avant de valider la réception de lˈoffre.',
    }],
    [RESET_ERROR, errorInitialState],
  ])

  return errors.get(action) || state
}
