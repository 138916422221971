import React, { createContext, useContext, useState, useEffect } from 'react'
import { node } from 'prop-types'

import { useParams } from 'react-router-dom'
import { SaisieUI, SaisiePropsUI } from '../../model'


export const SaisieContext = createContext({} as SaisieContextUI)

export function useSaisie() {
  const context = useContext<SaisieContextUI>(SaisieContext)
  if (Object.entries(context).length === 0) {
    throw new Error('useSaisie must be used within a SaisieProvider')
  }
  return context
}

SaisieProvider.propTypes = {
  children: node.isRequired,
}

interface SaisieContextUI {
  saisieUI : SaisieUI
  updateSaisie: (props: SaisiePropsUI) => void
}

export function SaisieProvider({ children }) {
  const [saisieUI, setSaisieUI] = useState(SaisieUI.default())
  const updateSaisie = props => setSaisieUI(saisieUI.update(props))

  useEffect(() => { saisieUI.withUpdateState(setSaisieUI) }, [saisieUI])

  const { dossierId } = useParams()
  useEffect(() => {
    if (dossierId) {
      updateSaisie(SaisieUI.defaultProps())
    }
  }, [dossierId])

  return (
    <SaisieContext.Provider value={{ saisieUI, updateSaisie }}>
      {children}
    </SaisieContext.Provider>
  )
}
