import { OffreAndAssuranceStatut } from '@partenaire/common'

const statusLabelOf = new Map<OffreAndAssuranceStatut, string>([
  [OffreAndAssuranceStatut.OFFRE_NON_SIGNEE, 'Offre disponible'],
  [OffreAndAssuranceStatut.OFFRE_ACCESSIBLE, 'Offre disponible'],
  [OffreAndAssuranceStatut.OFFRE_ACCEDEE, 'Offre disponible'],
  [OffreAndAssuranceStatut.OFFRE_RECEPTIONNEE, 'Offre réceptionnée'],
  [OffreAndAssuranceStatut.SIGNATURE_ACCESSIBLE, 'Délai de réflexion terminé'],
  [OffreAndAssuranceStatut.SIGNATURE_ACCEDEE, 'Délai de réflexion terminé'],
  [OffreAndAssuranceStatut.OFFRE_SIGNE, 'Offre signée'],
  [OffreAndAssuranceStatut.OFFRE_DEMANDEE_MANUSCRITE, 'Demande d’offre manuscrite'],
  [OffreAndAssuranceStatut.CHOIX_ASSURANCE_TRAITE, 'Choix assurance Traité'],
  [OffreAndAssuranceStatut.OFFRE_SIGNEE_ASSURANCE_NON_SIGNEE, 'Offre signée et Assurance non signée'],
  [OffreAndAssuranceStatut.OFFRE_ET_ASSURANCE_SIGNEE, 'Offre signée et Assurance signée'],
  [OffreAndAssuranceStatut.OFFRE_SIGNEE_ASSURANCE_ACCESSIBLE, 'Offre signée et Assurance accessible'],
  [OffreAndAssuranceStatut.OFFRE_INVALIDEE, 'Offre invalidée'],
])

export const isWarning = (statut: OffreAndAssuranceStatut) => statut === OffreAndAssuranceStatut.OFFRE_SIGNEE_ASSURANCE_NON_SIGNEE

export const getStatusLabel = (statut: OffreAndAssuranceStatut) => {
  return statusLabelOf.get(statut) ?? 'Statut inconnu'
}
