import React, { useEffect } from 'react'
import { useNavigate } from '@core/routing'
import { useApolloClient } from '@core/graphql'
import { fetch, removeRefreshToken } from '@core/common-frontend'
import { Spinner } from '@mmb/ui-components'
import { useUtilisateurContext } from '@core/partenaire-common'
import { UnauthenticatedLayout } from '../UnauthenticatedLayout'

export function Logout() {
  removeRefreshToken()
  const { loading, refresh } = useUtilisateurContext()
  const client = useApolloClient()
  const navigate = useNavigate()
  useEffect(() => {
    if (!loading) {
      fetch('/deconnexion')
        .then(response => response.json()
          .then(res => res.logoutURL)
          .then(logoutURL => logoutURL && window.location.assign(logoutURL)))
        .finally(() => {
          client.stop()
          client.clearStore()
          refresh()
        })
    } else {
      navigate('/app/connexion')
    }
  }, [loading])

  return (
    <UnauthenticatedLayout>
      <Spinner primary />
    </UnauthenticatedLayout>
  )
}
