import { SupportStateUI } from '../support'
import { DEFAULT_ACTIVITE } from './ActiviteFormUI'
import { ActiviteListUI } from './ActiviteListUI'

export class ActivitesUI extends SupportStateUI {
  constructor(
    public readonly emprunteursActivites: ActiviteListUI,
    public readonly coEmprunteursActivites: ActiviteListUI,
    public readonly hasCoEmprunteur: boolean = false,
  ) {
    super()
  }

  protected clone() {
    return new ActivitesUI(this.emprunteursActivites.clone(), this.coEmprunteursActivites.clone(), this.hasCoEmprunteur)
  }

  public delete(index: number, deleteOnEmprunteur: boolean): string|undefined {
    const id = this.selectActiviteListUI(deleteOnEmprunteur).list[index]?.id
    this.selectActiviteListUI(deleteOnEmprunteur).delete(index)
    this.refreshState()
    return id
  }

  public add(addOnEmprunteur: boolean) {
    this.selectActiviteListUI(addOnEmprunteur).add(DEFAULT_ACTIVITE)
    this.refreshState()
  }

  public update(index: number, activite: any, updateOnEmprunteur: boolean) {
    this.selectActiviteListUI(updateOnEmprunteur).update(index, activite)
    this.refreshState()
  }

  private selectActiviteListUI(selectOnEmprunteur: boolean): ActiviteListUI {
    return selectOnEmprunteur ? this.emprunteursActivites : this.coEmprunteursActivites
  }

  public static defaultActivitesUI(hasCoEmprunteur: boolean): ActivitesUI {
    return new ActivitesUI(new ActiviteListUI(), new ActiviteListUI(), hasCoEmprunteur)
  }
}
