export enum EntryFormStateName {
  ACTIVITE = 'activites',
  CHARGE = 'charges',
  SITUATION_FAMILIALE = 'situationFamiliale',
  REVENU = 'revenus',
  IDENTITE = 'identite',
  COMMUNICATIONS = 'communications',
  ADRESSE = 'adresse',
  PATRIMOINE = 'patrimoine',
  EN_COURS = 'encours',
  ASSURANCE = 'assurance',
  MONTAGE = 'montage',
  SITUATION_BANCAIRE = 'comptes',
}

export interface EntryFormState {
  name: EntryFormStateName
  value: any
  rawValue: any
  errors: any[]
  showErrors?: boolean
  hasErrors?: boolean
  positionFirstError?: number
  loading: boolean
  submitting: boolean
}
