import { gql, useMutation } from '@core/graphql'
import { useSaisie } from '../Contexts'

const UPDATE_DEMANDE_GAMME = gql`
mutation updateGamme($id: ID!, $isSecured: Boolean!) {
  partenaire_updateGamme(id: $id, isSecured: $isSecured)
}
`
export interface ChangeGammeProps {
  gamme: string
  changeGamme: () => void
  isUpdatingGamme: boolean
}

export function useGamme() : ChangeGammeProps {
  const { saisieUI } = useSaisie()
  const [updateGamme, { loading: isUpdatingGamme }] = useMutation(UPDATE_DEMANDE_GAMME)
  const { props: { dossierId, gamme } } = saisieUI

  const changeGamme = () => {
    const updatedIsSecured = gamme !== 'SECURED'
    updateGamme({ variables: { id: dossierId, isSecured: updatedIsSecured } })
      .then(() => window.location.reload())
  }

  return {
    gamme,
    changeGamme,
    isUpdatingGamme,
  }
}
