import { PositionableFormUI } from '../support/PositionableFormUI'

export enum EncoursType {
  PRET,
  DETTE
}

export interface EncoursFormUI extends PositionableFormUI {
  id?: string
  personneId?: string
  titre?: string
  repris: boolean
  type: EncoursType
}

export const isRepris = (encours: EncoursFormUI): boolean => encours.repris
