import React, { FunctionComponent } from 'react'
import cn from 'classnames'
import { DocumentIcon } from '@core/frontend-outils'

import { DocumentUI } from '../../../../model'
import { getDisplayableCategorie } from '../../../../utils'
import styles from '../HistoriquePieces.module.css'

interface HistoriqueDocumentProps {
  document: DocumentUI
  getDownloadDocumentURL: Function
  idx: number
}

export const HistoriqueDocument: FunctionComponent<HistoriqueDocumentProps> = ({ document, getDownloadDocumentURL, idx }) => (
  <a
    className={cn(styles.historique__line, { [styles.historique__lineStriped]: idx % 2 === 0 })}
    href={getDownloadDocumentURL(document?.props?.id)}
    target="_blank"
    rel="noreferrer"
    data-testid={`histo document ${idx}`}
    key={`Document ${idx + 1}`}
  >
    <DocumentIcon contentType={document?.props?.contentType} className={styles.historique__documentIcon} />
    <div className={styles.historique__documentFilename}>{document.props.filename}</div>
    <div className={styles.historique__documentCategorie}>{getDisplayableCategorie(document?.props?.categorie)}</div>
  </a>
)
