import React from 'react'
import { string } from 'prop-types'

import { getConsent, Panel } from '@core/partenaire-ui'
import { smartProfileTrackWithParams } from '@core/analytics'

import { Information } from './Information'
import { usePanel } from './DetailPanel.hooks'
import { DetailPanelLink } from './DetailPanelLink'

import styles from './DetailPanel.module.css'

DetailPanel.propTypes = {
  id: string.isRequired,
}

export function DetailPanel({ id }) {
  smartProfileTrackWithParams('&_title=Detail dossier', getConsent())

  const { loading, links, replaceReference } = usePanel(id)
  return (
    <Panel
      className={styles.detailPanel}
      aria-label="menu de la page détail"
    >
      {loading || !links ? null :
        (
          <div>
            <Information className={styles.detailPanel__informations} id={id} />
            {links.map(({ linkPattern, label }) => (
              <DetailPanelLink key={label} linkPattern={linkPattern} label={label} replaceReference={replaceReference} />
            ))}
          </div>
        )}
    </Panel>
  )
}
