import React, { useContext } from 'react'
import styles from './ToPrintDocument.module.css'
import { FormStateContext } from '../../Contexts/FormStateContext'
import { EntryFormStateName } from '../../FormState/EntryFormState'

/**
 * Contenu de dossier à imprimer ou à télécharger
 */


export function ToPrintMontage() {
  const formState = useContext(FormStateContext)
  const { value: montageValue } = formState.getEntryFormState(EntryFormStateName.MONTAGE)
  const montage = montageValue.demande
  return (
    <div>
      <h1 className={styles.printDocument__headband}>
        <div className={styles.printDocument__headband__text}>
          MONTAGE
        </div>
      </h1>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Durée (mois)</p>
          <p className={styles.printDocument__cell}>Frais de mandat (€)</p>
          <p className={styles.printDocument__cell}>Apport personnel (€)</p>
          <p className={styles.printDocument__cell}>Type d'amortissement</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__header} />
            <p className={styles.printDocument__cell}>{montage.dureeFinancement} </p>
            <p className={styles.printDocument__cell}>{montage.fraisMandat} </p>
            <p className={styles.printDocument__cell}>{montage.apport ?? 0} </p>
            <p className={styles.printDocument__cell}>{montage.typeAmortissement} </p>
          </div>
        </div>
      </div>
      <div className={styles.printDocument__subtitle}>
        <h1>Besoin de trésorerie</h1>
        <hr />
      </div>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Confort (€)</p>
          <p className={styles.printDocument__cell}>Travaux (€)</p>
          <p className={styles.printDocument__cell}>Autre (€)</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__header} />
            <p className={styles.printDocument__cell}>{montage.tresorerie.confort} </p>
            <p className={styles.printDocument__cell}>{montage.tresorerie.travaux} </p>
            <p className={styles.printDocument__cell}>{montage.tresorerie.autre} </p>
          </div>
        </div>
      </div>
    </div>
  )
}
