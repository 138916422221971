import { useState, useMemo } from 'react'
import { gql, useMutation } from '@core/graphql'
import { toast, useIzanamiFeatureByName } from '@core/ui'
import { TransmissionStatut, TransmissionInvalidDetail } from '@partenaire/common'
import { PermissionScope } from '@core/common'
import { useHasPermission, useUtilisateurMultiPartenaire } from '@core/partenaire-common'
import { useSaisie, useSimulation } from '../../Contexts'
import { hasErrorForName } from '../../../utils'
import { SimulationStatus } from '../const'

const GENERIC_TRANSMISSION_ERROR = 'Transmission impossible. Veuillez contacter la cellule commerciale'

const TRANSMISSION_ERROR_MAP = new Map<TransmissionInvalidDetail, string>([
  [TransmissionInvalidDetail.DOSSIER_INVALIDE, GENERIC_TRANSMISSION_ERROR],
  [TransmissionInvalidDetail.SITUATION_MARITALE_INVALIDE, 'Transmission impossible. La situation familiale est incorrecte, veuillez la ressaisir'],
])

const getTransmissionErrorText = (errorCode: TransmissionInvalidDetail) : string => {
  return TRANSMISSION_ERROR_MAP.get(errorCode) ?? GENERIC_TRANSMISSION_ERROR
}

const TRANSMISSION_DOSSIER = gql`
  mutation transmissionDossier($id: ID!) {
    partenaire_transmission(id: $id) {
      statut
      invalidDetails
    }
}`

const isCheckedAndValideOrUsure = (check, statusSimulation, errors, gamme, isTransmissionEnabled, hasPermissionTransmissionWrite) => {
  if (!check || !isTransmissionEnabled || !hasPermissionTransmissionWrite) return false
  if (statusSimulation === SimulationStatus.VALIDE) return true
  if (gamme === 'UNSECURED') return false
  return errors?.horsNorme.length === 1 && hasErrorForName('TAUX_USURE', errors?.horsNorme)
}

function useProduitSimulation() {
  useUtilisateurMultiPartenaire()
  const { saisieUI: { props: { dossierId } } } = useSaisie()
  const [{ status, simulationErrors, product }, { getSimulation }] = useSimulation()
  const { active: isTransmissionEnabled } = useIzanamiFeatureByName('transmission-dossier')
  // checked is status of checkbox to confirm before validating simulation
  const [checked, setChecked] = useState(false)
  const hasPermissionTransmissionWrite = useHasPermission(PermissionScope.TRANSMISSION_WRITE)
  const isTransmissionAllowed = useMemo(
    () => isCheckedAndValideOrUsure(checked, status, simulationErrors, product?.gamme, isTransmissionEnabled, hasPermissionTransmissionWrite),
    [checked, status, simulationErrors])
  const [transmissionByDossierId, { loading, error }] = useMutation(TRANSMISSION_DOSSIER)
  const sucessToast = { type: 'SUCCESS', message: 'Votre dossier est en cours de création.' }
  const errorToast = { type: 'ERROR', message: 'Le dossier n’a pas pu être créé, veuillez recommencer.' }
  const simulationInvalidToast = { ...errorToast, message: 'Votre dossier n’a pas pu être créé, la simulation est non conforme' }
  async function refetchOnInvalidSimulation() {
    setChecked(false)
    await getSimulation()
  }

  async function validateDossier() {
    try {
      const response = await transmissionByDossierId({ variables: { id: dossierId } })
      const transmisionResult = response?.data?.partenaire_transmission
      if (transmisionResult?.statut === TransmissionStatut.DOSSIER_INVALIDE) {
        transmisionResult?.invalidDetails
          ?.forEach((detail: TransmissionInvalidDetail) => toast({ type: 'ERROR', message: getTransmissionErrorText(detail) }))
      } else {
        toast(sucessToast)
        window.location.reload()
      }
    } catch (err: any) {
      const hadInvalidSimulation = err?.message === 'TRAITEMENT_TRANSMISSION_SIMULATION_ERROR'
      toast(hadInvalidSimulation ? simulationInvalidToast : errorToast)
      if (hadInvalidSimulation) {
        await refetchOnInvalidSimulation()
      }
    }
  }

  return {
    validateDossier,
    isTransmissionAllowed,
    isTransmissionEnabled,
    loading,
    error,
    checked,
    hasPermissionTransmissionWrite,
    setChecked,
  }
}

export { isCheckedAndValideOrUsure, useProduitSimulation }

