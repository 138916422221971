import React from 'react'
import classnames from 'classnames'

import styles from './ActivitesLabels.module.css'

export function ActivitesLabels() {
  return (
    <div className={styles.activitesLabels}>
      <div className={styles.activitesLabels__grid}>
        <p className={classnames(styles.activitesLabels__label, styles.activitesLabels__label__principal)}>Activité</p>
        <p className={styles.activitesLabels__label}>Type d'activité</p>
        <p className={styles.activitesLabels__label}>Catégorie socio-professionnelle</p>
        <p className={styles.activitesLabels__label}>Profession</p>
        <p className={styles.activitesLabels__label}>Type de contrat</p>
        <p className={styles.activitesLabels__label}>Depuis le</p>
      </div>
    </div>
  )
}
