import { gql, useQueryWithError } from '@core/graphql'
import { RatiosHypothecaires } from '@partenaire/common'

export const GET_RATIOS_HYPOTHECAIRES = gql`
query getRatiosHypothecaires($codePostal: String!, $codeInsee: String!){
  partenaire_ratiosHypothecaires(codePostal: $codePostal, codeInsee: $codeInsee) {
    ratioHypothecaireLS2
    ratioHypothecaireLCC
    ratioMyNewTreso
    isEligible
  }
}
`
export function useRatiosHypothecaires(codePostal: string, codeInsee: string): { loading: boolean, ratiosHypothecaires?: RatiosHypothecaires } {
  const {
    data = {},
    loading,
  } = useQueryWithError(GET_RATIOS_HYPOTHECAIRES, {
    variables: { codePostal, codeInsee },
  })

  return { loading, ratiosHypothecaires: data?.partenaire_ratiosHypothecaires }
}
