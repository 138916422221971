import React, { useMemo } from 'react'
import { bool, node, string } from 'prop-types'
import { Cross, TickEtapes, Card } from '@mmb/ui-components'

import classnames from 'classnames'

import styles from './BannerCard.module.css'

BannerCard.propTypes = {
  title: string,
  hasError: bool,
  children: node,
  className: string,
}

BannerCard.defaultProps = {
  title: '',
  hasError: false,
  children: null,
  className: '',
}

export function BannerCard({ title, children, hasError, className }) {
  const Icon = useMemo(() => hasError
    ? (<Cross className={styles.header__iconError} />)
    : (<TickEtapes />),
  [hasError])
  return (
    <div aria-label={title} className={classnames(className, styles.bannerCard)}>
      <div className={classnames(styles.bannerCard__header, { [styles.bannerCard__header__error]: hasError })}>
        {Icon}
        <span>{title}</span>
      </div>
      {children && (
        <Card className={styles.bannerCard__content}>
          {children}
        </Card>
      )}
    </div>
  )
}
