
import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

import { Button, Download as DownloadIcon } from '@mmb/ui-components'
import { ToPrintDocument } from './ToPrintDocument'

export function DownloadButton() {
  const componentRef = useRef(null)

  const [isDisable, setIsDisable] = useState(true)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  useEffect(() => {
    if (!isDisable) {
      handlePrint()
      setIsDisable(true)
    }
  }, [isDisable])

  const startPrinting = () => {
    setIsDisable(false)
  }

  return (
    <>
      <Button leftIcon={DownloadIcon} onClick={startPrinting} outline> Télécharger le dossier </Button>
      <div ref={componentRef}>
        {!isDisable && <ToPrintDocument />}
      </div>
    </>
  )
}
