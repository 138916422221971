import React from 'react'
import { bool, node } from 'prop-types'

import { Placeholder, TextRow } from '@mmb/ui-components'

import styles from './ChargesFormPlaceholder.module.css'


ChargesFormPlaceholder.propTypes = {
  children: node.isRequired,
  loading: bool,
}

ChargesFormPlaceholder.defaultProps = {
  loading: false,
}

export function ChargesFormPlaceholder({ children, loading }) {
  return (
    <Placeholder
      loading={loading}
      customPlaceholder={(
        <div aria-label="Chargement en cours" className={styles.chargesFormPlaceholder}>
          <TextRow width="70%" className={styles.chargesFormPlaceholder__row} />
          <TextRow width="90%" className={styles.chargesFormPlaceholder__row} />
        </div>
      )}
    >
      {children}
    </Placeholder>
  )
}
