import { useEffect, useState } from 'react'
import { useQueryRedirectError, useMutation } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../../Contexts'
import { PretFormUI } from '../../../model'
import { CREATE_PRET_DOSSIER, DELETE_PRET_DOSSIER, GET_PRETS_DOSSIER, UPDATE_PRET_DOSSIER } from './graphql-queries'
import { toPretFormUI, toPretGraphQL, toPretsFormUI } from '../mappers'

export function usePrets() {
  const navigate = useNavigate()
  const { saisieUI: { props: { dossierId, emprunteurId } } } = useSaisie() as { saisieUI: { props: { dossierId: string, emprunteurId: string } } }
  const [prets, setPrets] = useState<PretFormUI[]>([])
  const [loadingPretsUI, setLoadingPretsUI] = useState(true)
  const { data, loading: queryLoading } = useQueryRedirectError(GET_PRETS_DOSSIER, navigate, { fetchPolicy: 'network-only', variables: { id: dossierId } })
  const [createPret, { loading: createLoading }] = useMutation(CREATE_PRET_DOSSIER)
  const [updatePret, { loading: updateLoading }] = useMutation(UPDATE_PRET_DOSSIER, {
    refetchQueries: [{ query: GET_PRETS_DOSSIER, variables: { id: dossierId } }],
  })
  const [deletePret, { loading: deleteLoading }] = useMutation(DELETE_PRET_DOSSIER, {
    refetchQueries: [{ query: GET_PRETS_DOSSIER, variables: { id: dossierId } }],
  })
  useEffect(() => {
    if (!queryLoading) {
      const [emprunteur, coEmprunteur] = data?.partenaire_dossierEnCours?.emprunteurs || []
      setPrets([...toPretsFormUI(emprunteur), ...toPretsFormUI(coEmprunteur)])
      setLoadingPretsUI(false)
    }
  }, [queryLoading])

  async function handleUpdatePret(pretFormUI: PretFormUI): Promise<PretFormUI> {
    const pretGraphQL = toPretGraphQL(pretFormUI)
    return pretGraphQL?.id ?
      updatePret({ variables: { id: dossierId, personneId: pretFormUI.personneId, pret: pretGraphQL } })
        .then(() => pretFormUI) :
      createPret({ variables: { id: dossierId, personneId: emprunteurId, pret: pretGraphQL } })
        .then(createdPret => toPretFormUI(createdPret.data?.partenaire_createPret, emprunteurId, createdPret.data?.partenaire_createPret.id))
  }

  const handleRemovePret = async (pretToDelete: PretFormUI) => {
    if (pretToDelete?.id) {
      await deletePret({ variables: { id: dossierId, personneId: pretToDelete.personneId, pretId: pretToDelete?.id } })
    }
  }

  return {
    loading: loadingPretsUI || createLoading || updateLoading || deleteLoading,
    prets,
    handleUpdatePret,
    handleRemovePret,
  }
}
