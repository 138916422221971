import { useState, useEffect } from 'react'

import { gql, useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../../Contexts'
import { useHandleSubmit } from '../../FormState/form.state.hooks'
import { EntryFormStateName } from '../../FormState/EntryFormState'

export const GET_COMMUNICATIONS_DOSSIER = gql`
query getCommunication($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    emprunteurs {
      id
      communications(dossierId: $id) {
        id
        value
        type
      }
    }
  }
}
`

export const CREATE_COMMUNICATION_DOSSIER = gql`
mutation createCommunication($id: ID!, $personneId: ID!, $communication: partenaire_CommunicationInput!) {
  partenaire_createCommunication(id: $id, personneId: $personneId, communication: $communication) {
    id
    value
    type
  }
}
`

export const UPDATE_COMMUNICATION_DOSSIER = gql`
mutation updateCommunication($id: ID!, $personneId: ID!, $communication: partenaire_CommunicationInput!) {
  partenaire_updateCommunication(id: $id, personneId: $personneId, communication: $communication) {
    id
    value
    type
  }
}
`

export const DELETE_COMMUNICATION_DOSSIER = gql`
mutation deleteCommunication($id: ID!, $personneId: ID!, $communication: partenaire_CommunicationInput!) {
  partenaire_deleteCommunication(id: $id, personneId: $personneId, communication: $communication){
    id,
    type,
  }
}
`

export function useCommunications() {
  const navigate = useNavigate()
  const { saisieUI: { props: { dossierId, emprunteurId, coEmprunteurId, hasCoEmprunteur } } } = useSaisie()
  const [emprunteurCommunications, setEmprunteurCommunications] = useState<any>({})
  const [coEmprunteurCommunications, setCoEmprunteurCommunications] = useState<any>({})
  const [loadingCommunicationsUI, setLoadingCommunicationsUI] = useState(true)
  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.COMMUNICATIONS)


  const { data, loading: queryLoading } = useQueryRedirectError(
    GET_COMMUNICATIONS_DOSSIER,
    navigate,
    { fetchPolicy: 'network-only', variables: { id: dossierId } },
  )

  /* Emprunteur Mutations */
  const [
    createCommunication,
    { loading: createLoading, error: createError },
  ] = useMutation(CREATE_COMMUNICATION_DOSSIER)

  const [
    deleteCommunication,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation(DELETE_COMMUNICATION_DOSSIER)

  const [
    updateCommunication,
    { loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_COMMUNICATION_DOSSIER)

  useEffect(() => {
    if (!queryLoading) {
      // eslint-disable-next-line camelcase
      const [emprunteurData, coEmprunteurData] = data?.partenaire_dossierEnCours?.emprunteurs || []

      if (emprunteurData?.communications) {
        setEmprunteurCommunications(formatCommunicationsFromGraphqlQuery(emprunteurData?.communications || []))
      }

      if (coEmprunteurData?.communications) {
        setCoEmprunteurCommunications(formatCommunicationsFromGraphqlQuery(coEmprunteurData?.communications || []))
      }
      setLoadingCommunicationsUI(false)
    }
  }, [data, queryLoading])


  const handleSubmit = async event => doHandleSubmit(updateEmprunteursCommunications, event)

  const handleCommunication = (personneId, communication) => {
    if (communication.id && communication?.value) {
      return updateCommunication({ variables: { id: dossierId, personneId, communication } })
    }

    if (!communication.id && communication?.value) {
      return createCommunication({ variables: { id: dossierId, personneId, communication } })
    }

    if (communication.id && !communication?.value) {
      return deleteCommunication({ variables: { id: dossierId, personneId, communication } })
    }
    return Promise.resolve()
  }

  const updateEmprunteurCommunication = async (id, communications) => {
    const formattedCommunications = formatCommunicationToGraphql(communications)
    const handledCommunications = await Promise.all(formattedCommunications.map((communication: any) => handleCommunication(id, communication)))
    const updatedCommunications = formatCommunicationFromGraphqlMutation(handledCommunications)
    return { communications: updatedCommunications }
  }

  const updateEmprunteursCommunications = async () => {
    if (!emprunteurId) return

    const { communications: updatedEmprunteurCommunications }
      = await updateEmprunteurCommunication(emprunteurId, emprunteurCommunications)
    setEmprunteurCommunications(updatedEmprunteurCommunications)

    if (coEmprunteurId && hasCoEmprunteur) {
      const { communications: updatedCoEmprunteurCommunications }
        = await updateEmprunteurCommunication(coEmprunteurId, coEmprunteurCommunications)
      setCoEmprunteurCommunications(updatedCoEmprunteurCommunications)
    }
  }

  return {
    loading: loadingCommunicationsUI || updateLoading || createLoading || deleteLoading,
    error: updateError || createError || deleteError,
    emprunteurCommunications,
    coEmprunteurCommunications,
    handleSubmit,
    changeEmprunteurCommunications: newState => setEmprunteurCommunications(currentState => ({ ...currentState, ...newState })),
    changeCoEmprunteurCommunications: newState => setCoEmprunteurCommunications(currentState => ({ ...currentState, ...newState })),
  }
}

function formatCommunicationFromGraphql({ __typename, type, ...communication }: any = {}) {
  return { [type.toLowerCase()]: communication }
}

function formatCommunicationFromGraphqlMutation(communications) {
  return communications.reduce((accumulateur, { data }) => {
    const [key] = Object.keys(data)
    return { ...accumulateur, ...formatCommunicationFromGraphql(data[key]) }
  }, {})
}

function formatCommunicationsFromGraphqlQuery(communications = []) {
  return communications.reduce(
    (accumulateur, communication) => ({ ...accumulateur, ...formatCommunicationFromGraphql(communication) }),
    {})
}

function formatCommunicationToGraphql(communications = {}) {
  return Object.keys(communications).reduce(
    (accumulateur: any, communicationKeys) => {
      const { errors: _, ...communication } = communications[communicationKeys]
      return [...accumulateur, { ...communication, type: communicationKeys.toUpperCase() }]
    },
    [])
}
