import { buildValidationError } from '../../model'

const titles = {
  nbEnfants: 'Nombre d\'enfants',
}

function getMessageErrorByField(field, message) {
  if (message === 'WRONG_VALUE') {
    if (field === 'nbEnfants') return 'le nombre d\'enfants maximum est de 15.'
  }
  return ''
}

function validateAgeEnfant(enfant, globalErrors) {
  if (enfant.age < 0) {
    globalErrors.push(buildValidationError(`enfantAge_${enfant.order}`, 'NEGATIVE_VALUE'))
  }
}

export function buildSituationFamilialeErrors(error) {
  return {
    title: titles[error?.field],
    message: getMessageErrorByField(error?.field, error?.message),
  }
}

export function validateSituationFamiliale({ situationFamiliale, gamme, hasCoEmprunteur }) {
  const globalErrors: any[] = []

  if (gamme === 'UNSECURED' && situationFamiliale?.enfants?.length > 15) globalErrors.push(buildValidationError('nbEnfants', 'NBR_CHILDREN_EXCEEDED'))
  if (!situationFamiliale?.situation) { globalErrors.push(buildValidationError('type', 'MISSING_VALUE')) }
  if (hasCoEmprunteur
    && situationFamiliale?.situation === 'MARIE'
    && !situationFamiliale?.regimeMatrimonial) {
    globalErrors.push(buildValidationError('regimeMatrimonial', 'MISSING_VALUE'))
  }

  if (situationFamiliale.enfants && situationFamiliale.enfants.length) {
    situationFamiliale.enfants.map(enfant => validateAgeEnfant(enfant, globalErrors))
  }

  if (situationFamiliale?.situation === 'INSTANCE_DE_DIVORCE') {
    if (gamme === 'UNSECURED') globalErrors.push(buildValidationError('type', 'UNSECURED_DIVORCE_CONSENT'))
    if (gamme === 'SECURED') {
      if (!situationFamiliale?.typeOfInstance) {
        globalErrors.push(buildValidationError('typeInstance.PAR_NON_CONCILIATION', 'MISSING_VALUE'))
        globalErrors.push(buildValidationError('typeInstance.PAR_CONSENTEMENT_MUTUEL', 'MISSING_VALUE'))
      }
      if (situationFamiliale?.typeOfInstance === 'PAR_NON_CONCILIATION') {
        globalErrors.push(buildValidationError('typeInstance.PAR_NON_CONCILIATION', 'DIVORCE_NON_CONSENT'))
      }
    }
  }

  return {
    hasErrors: globalErrors.length > 0,
    globalErrors,
  }
}
