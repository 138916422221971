/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react'

import { AddButton } from '@partenaire/frontend-ui'

import { nth } from 'lodash'
import { useDelayedFormBlur } from '../../../../utils'

import styles from './Charges.module.css'

import { useCharges } from '../ChargeContext'
import { ChargeInput } from './ChargeInput'
import { ChargesFormPlaceholder } from './ChargesFormPlaceholder'
import { BudgetTotal } from '../../BudgetTotal'
import { SaisieErrorMessages } from '../../../SaisieErrorMessages'

const suffixes = {
  emprunteur: {
    label: 'de l\'emprunteur',
    name: 'emprunteur',
  },
  coEmprunteur: {
    label: 'du co-emprunteur',
    name: 'coEmprunteur',
  },
}

export function Charges() {
  const { loading, chargesUI, errorMessages, showErrors, errors, handleSubmit, handleRemoveCharge } = useCharges()
  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)
  return (
    <>
      <form
        data-testid="charge-form"
        ref={formRef}
        onSubmit={handleSubmit}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <div className={styles.charges}>
          <div className={styles.charges_item}>
            <div className={styles.charges__lists}>
              {chargesUI.emprunteursCharges.displayableCharge().map((charge, index) => (
                <ChargesFormPlaceholder
                  key={`charge_emp_placeholder_${index}`}
                  loading={loading}
                >
                  <ChargeInput
                    key={`charge_emp_${index}`}
                    suffixes={suffixes.emprunteur}
                    charge={charge as any}
                    setCharge={newCharge => chargesUI.update(index, newCharge, true)}
                    removeCharge={(_, idx) => handleRemoveCharge(idx, true)}
                    index={index}
                    errors={nth(errors.emprunteurErrors, index)}
                    showErrors={showErrors}
                  />
                </ChargesFormPlaceholder>),
              )}
            </div>
          </div>
          {chargesUI.hasCoEmprunteur && (
            <div className={styles.charges__item}>
              <div className={styles.charges__lists}>
                {chargesUI.coEmprunteursCharges.displayableCharge().map((charge, index) => (
                  <ChargesFormPlaceholder
                    key={`charge_coEmp_placeholder_${index}`}
                    loading={loading}
                  >
                    <ChargeInput
                      key={`charge_coEmp_${index}`}
                      suffixes={suffixes.coEmprunteur}
                      charge={charge as any}
                      setCharge={newCharge => chargesUI.update(index, newCharge, false)}
                      removeCharge={(_, idx) => handleRemoveCharge(idx, false)}
                      index={index}
                      errors={nth(errors.coEmprunteurErrors, index)}
                      showErrors={showErrors}
                    />
                  </ChargesFormPlaceholder>),
                )}
              </div>
            </div>
          )}
        </div>
        <div className={styles.charges__button}>
          <AddButton
            label="Ajouter une charge"
            aria-label={`Ajouter une charge pour ${suffixes.emprunteur.name}`}
            className={styles.chargesForm__button}
            disabled={loading}
            onClick={() => chargesUI.add(true)}
          />
          {chargesUI.hasCoEmprunteur && (
            <AddButton
              label="Ajouter une charge"
              aria-label={`Ajouter une charge pour ${suffixes.coEmprunteur.name}`}
              className={styles.chargesForm__button}
              disabled={loading}
              onClick={() => chargesUI.add(false)}
            />
          )}
        </div>
      </form>
      {showErrors && (<SaisieErrorMessages
        errors={errorMessages}
      />)}
      <BudgetTotal type="charges" total={chargesUI.getTotalCharges()} />
    </>
  )
}
