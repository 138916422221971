import { format } from 'date-fns'
import { DocumentsUI } from './DocumentsUI.model'
import { TransfertStatutUI } from './TransfertStatutUI.model'

export interface TransfertUIProps {
  id: string
  dossierId: string
  date: Date
  commentaire: string
  statut: TransfertStatutUI
  documentsUI: DocumentsUI
}

const DATE_TIME_FORMAT = 'dd/MM/yyyy à HH:mm:ss'
export class TransfertUI {
  constructor(public readonly props: TransfertUIProps) {
  }

  public getDisplayableDate(): string {
    return format(this.props.date, DATE_TIME_FORMAT)
  }
}
