import React from 'react'

import { InputMontant } from '@partenaire/frontend-ui'
import { Input } from '@mmb/ui-components'

import styles from './BaisseRevenuForm.module.css'
import { clearEmpty, hasErrorForName as hasError, parseMontant } from '../../../../../../utils'
import { BaisseRevenuUI } from '../../../../../../model/revenus/BaisseRevenuUI'
import { DATE_MM_YYYY } from '../../../../../../model/format'

interface BaisseRevenuFormProps {
  type: string
  errors
  showErrors: boolean
  baisseRevenu: BaisseRevenuUI
  update: (value: any) => void
}

export function BaisseRevenuForm({ type, errors, showErrors, baisseRevenu, update }: BaisseRevenuFormProps) {
  const hasErrorForName = name => hasError(name, errors, showErrors)
  return (
    <div aria-label="Baisse revenu">
      <InputMontant
        className={styles.baisseRevenue__form__dynamicInput}
        name={`baisse.revenu.${type}.montant`}
        hasError={hasErrorForName('montant')}
        aria-invalid={hasErrorForName('montant')}
        label={`Montant prévisionnel ${type}`}
        onChange={({ target }) => update({ montant: parseMontant(target.value) })}
        value={clearEmpty(baisseRevenu.montant)}
      />
      <Input
        aria-invalid={hasErrorForName('debut')}
        hasError={hasErrorForName('debut')}
        className={styles.baisseRevenue__form__input}
        name={`baisse.revenu.${type}.debut`}
        onChange={({ target }) => update({ debut: target.value })}
        label={`Date début ${type}`}
        hideLabel
        type="text"
        placeholder={`${DATE_MM_YYYY.toLowerCase()}`}
        value={baisseRevenu.debut}
      />
    </div>
  )
}
