import React from 'react'
import classnames from 'classnames'
import { bool, string, func } from 'prop-types'

import { ToggleButton } from '@mmb/ui-components'

import { GAMMES_OPTIONS } from '../../../utils'

import styles from './GammeButtons.module.css'


GammeButtons.propTypes = {
  small: bool,
  gamme: string,
  setGamme: func,
  disabled: bool,
}

GammeButtons.defaultProps = {
  small: false,
  gamme: 'SECURED',
  setGamme: () => {},
  disabled: false,
}

export function GammeButtons({ small, gamme, setGamme, disabled }) {
  return (
    <div className={classnames(styles.gammeButtons__radios, { [styles.gammeButtons__radiosDisabled]: disabled })}>
      {GAMMES_OPTIONS.map(({ label, value, arialLabel }) => {
        const isChecked = value === gamme
        return (
          <ToggleButton
            label={label}
            value={value}
            name="gamme"
            aria-label={`${arialLabel}`}
            className={classnames(styles.gammeButtons__option, {
              [styles.gammeButtons__gammesSmall]: small,
              [styles.gammeButtons__gammesConso]: isChecked && value === 'UNSECURED',
              [styles.gammeButtons__gammesHypo]: isChecked && value === 'SECURED',
              [styles.gammeButtons__optionSmallNotChecked]: small && !isChecked,
            })}
            labelClassName={classnames(
              styles.gammeButtons__label, {
                [styles.gammeButtons__labelGammesSmall]: small,
                [styles.gammeButtons__labelSmallNotChecked]: small && !isChecked,
              })}
            onChange={() => setGamme(value)}
            checked={isChecked}
            key={value}
          />
        )
      })}
    </div>
  )
}
