import React, { Component } from 'react'
import { func, node } from 'prop-types'
import { ErrorPage, UnavailableService } from '@mmb/ui-components'

/**
 * Gestionnaire d'erreur destiné à être positionné au niveau le plus haut de l'arbre React.
 * Si ce gestionnaire est activé, cela signifie qu'aucun autre gestionnaire n'a pû gérer l'erreur et qu'il faut considérer l'application comme étant corrompue.
 * De ce fait, ce gestionnaire affiche une page <b>complètement statique</b> ne faisant intervenir que la librairie React (pas de router)
 */
export class GlobalErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.props.onComponentDidCatch(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      const firstButton = { label: 'Retour à l\'accueil', action: () => window.location.assign('/') }
      const secondButton = { label: 'Contactez-nous', action: () => window.location.assign('/contact') }

      return (
        <ErrorPage firstButton={firstButton} secondButton={secondButton}>
          <UnavailableService isPage />
        </ErrorPage>
      )
    }

    return this.props.children
  }
}

GlobalErrorBoundary.propTypes = {
  children: node.isRequired,
  onComponentDidCatch: func,
}

GlobalErrorBoundary.defaultProps = {
  onComponentDidCatch: () => {},
}

