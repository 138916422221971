import React from 'react'
import { Maintenance as MaintenanceIcon, Card } from '@mmb/ui-components'
import { UnauthenticatedLayout } from '../UnauthenticatedLayout'

import styles from './maintenance.module.css'

export const Maintenance = () => (
  <UnauthenticatedLayout>
    <Card className={styles.maintenance__card}>
      <h2>
        Votre Portail Partenaire est<br />
        actuellement en maintenance
      </h2>
      <MaintenanceIcon className={styles.maintenance__icon} />
      <p>
        Nos équipes font le maximum pour qu'il soit accessible au plus vite.<br />
        Merci de votre compréhension.
      </p>
    </Card>
  </UnauthenticatedLayout>
)
