export enum AssuranceType {
    SANS_ASSURANCE = 'SANS_ASSURANCE',
    ASSURANCE_GROUP = 'ASSURANCE_GROUP'
  }

export enum AssuranceOption {
    DC_PTIA_ITT_PE = 'DC_PTIA_ITT_PE',
    DC_PTIA_ITT = 'DC_PTIA_ITT',
  }

export const ASSURANCE_OPTIONS = [{
  label: 'Assurance Groupe',
  value: AssuranceType.ASSURANCE_GROUP,
}, {
  label: 'Sans assurance',
  value: AssuranceType.SANS_ASSURANCE,
}]

export const ASSURANCE_FORMULES_OPTIONS = [{
  label: 'Décès, PTIA, ITT, perte d\'emploi',
  value: AssuranceOption.DC_PTIA_ITT_PE,
}, {
  label: 'Décès, PTIA, ITT',
  value: AssuranceOption.DC_PTIA_ITT,
}]

