import React, { FunctionComponent } from 'react'

import { AssuranceType } from '../AssuranceType'
import { AssuranceGroupeDetail } from '../AssuranceDetail'
import { PersonneAssurance } from '../PersonneAssurance'

import styles from '../Assurance.module.css'

import { AssuranceTypeUI, AssuranceUI } from '../../../../model/assurance/AssuranceUI'

export const AssuranceUnsecured: FunctionComponent<{ assuranceUI: AssuranceUI}>
= ({ assuranceUI }) => assuranceUI.props.type === AssuranceTypeUI.DELEGATION ? null : (
  <>
    <PersonneAssurance assuranceUI={assuranceUI} className={styles.assurance__personne} hideStatus />
    {assuranceUI && (
      <>
        <AssuranceType libelle={assuranceUI.getLibelleType()} organisme={assuranceUI.props.organisme} />
        <AssuranceGroupeDetail assuranceUI={assuranceUI} />
      </>
    )}
  </>
)
