import { gql } from '@core/graphql'

export const GET_GROUPES = gql`
query getGroupes($idRubrique: String!) {
  outils_groupes(idRubrique:$idRubrique) {
    id
    nom
  }
}
`
export const GET_DOCUMENTS = gql`
query getDocuments($idRubrique: String!, $idGroupe: String) {
  outils_documents(idRubrique:$idRubrique, idGroupe:$idGroupe) {
    id
    nom
    rubriqueId
    groupeId
    dateCreation
    dateUpdate
    contentType
  }
}
`

export const GET_DOCUMENTS_FAVORIS = gql`
query getDocumentsFavoris($idRubrique: String!) {
  outils_documentsFavoris(idRubrique:$idRubrique) {
    id
    nom
    rubriqueId
    groupeId
    dateCreation
    dateUpdate
    contentType
  }
}
`
