import React from 'react'
import { string, func, shape, arrayOf } from 'prop-types'
import { isEmpty } from 'lodash'
import classNames from 'classnames'

import { Select } from '@core/partenaire-ui'

import styles from './SelectCommunes.module.css'

SelectCommunes.propTypes = {
  className: string,
  setCodePostalVille: func,
  citiesOptions: arrayOf(shape({
    label: string,
    name: string,
    codeInsee: string,
  })),

}

SelectCommunes.defaultProps = {
  className: '',
  setCodePostalVille: () => {},
  citiesOptions: [],
}

export function SelectCommunes({ className, setCodePostalVille, citiesOptions, ...props }) {
  const handleInputChange = input => setCodePostalVille(input)

  const noOptionsMessage = ({ inputValue }) => {
    if (!inputValue) return null
    if (!citiesOptions) return 'Saisir un code postal ou une ville'
    if (isEmpty(citiesOptions)) return 'Aucune ville trouvée'
    return null
  }

  return (
    <Select
      options={citiesOptions}
      noOptionsMessage={noOptionsMessage}
      onInputChange={handleInputChange}
      className={classNames(styles.selectCommunes, className)}
      placeholder="Saisir un code postal ou une ville"
      fieldClassName={styles.selectCommunes__text}
      {...props}
    />
  )
}
