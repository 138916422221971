import React from 'react'
import { Input, Card, formatNumberCurrency } from '@mmb/ui-components'
import { HorsNormeCodeEnum } from '@partenaire/common'

import { useSimulation } from '../../Contexts'
import { hasErrorForName } from '../../../utils'

import styles from './Diagnostic.module.css'

const EXPERTISE = {
  sans_expertise: 'Sans expertise',
  expertise_obligatoire: 'Avec expertise',
  NA: 'N/A',
}


export function Diagnostic() {
  const [{ product, diagnostic, simulationErrors }] = useSimulation()
  const paliers = product?.paliers || []
  return (
    <Card className={styles.diagnostic}>
      <h3 className={styles.diagnostic__title}>Diagnostic</h3>
      <Input
        className={styles.diagnostic__titles}
        name="priority"
        label="Priority"
        aria-label="Priority"
        readOnly
        value={diagnostic.hasPriority ? 'Éligible' : 'Non éligible'}
        type="text"
        fieldClassName={styles.diagnostic__input}
      />
      <Input
        className={styles.diagnostic__titles}
        name="taeg"
        hasError={hasErrorForName(HorsNormeCodeEnum.TAUX_USURE, simulationErrors?.horsNorme)}
        label={`TAEG (${diagnostic?.tauxUsure} %)`}
        aria-label="TAEG"
        readOnly
        value={`${diagnostic.taeg && diagnostic.taeg.toFixed(2)} %`}
        type="text"
        fieldClassName={styles.diagnostic__input}
      />
      {product.gamme === 'SECURED' && (
        <>
          <Input
            name="ratio_hypothecaire"
            hasError={hasErrorForName(HorsNormeCodeEnum.RATIO_HYPOTHECAIRE_MONTANT_PRET, simulationErrors?.horsNorme)}
            label="Ratio hypothécaire"
            aria-label="Ratio hypothécaire"
            readOnly
            value={`${diagnostic.ratioHypo && diagnostic.ratioHypo.toFixed(2)} %`}
            type="text"
            fieldClassName={styles.diagnostic__input}
          />
          <Input
            name="expertise_immo"
            label="Expertise immo"
            aria-label="Expertise immo"
            readOnly
            value={EXPERTISE[diagnostic?.expertiseImmo]}
            disabled
            type="text"
            fieldClassName={styles.diagnostic__input}
          />
        </>
      )}
      <Input
        className={styles.diagnostic__titles}
        name="endettement_avant"
        label="Endettement avant"
        aria-label="Endettement avant"
        readOnly
        value={`${diagnostic.endettementAvant && diagnostic.endettementAvant.toFixed(2)} %`}
        type="text"
        fieldClassName={styles.diagnostic__input}
      />
      <Input
        className={styles.diagnostic__titles}
        name="endetemment_apres"
        hasError={hasErrorForName(HorsNormeCodeEnum.ENDETTEMENT_APRES, simulationErrors?.horsNorme)}
        label="Endettement après"
        aria-label="Endettement après"
        readOnly
        value={`${diagnostic.endettementApres && diagnostic.endettementApres.toFixed(2)} %`}
        type="text"
        fieldClassName={styles.diagnostic__input}
      />
      {paliers.length < 2 ? (
        <Input
          className={styles.diagnostic__titles}
          name="reste_a_vivre"
          hasError={hasErrorForName(HorsNormeCodeEnum.RESTE_A_VIVRE_APRES, simulationErrors?.horsNorme)}
          label="Reste à vivre"
          aria-label="Reste à vivre"
          readOnly
          value={`${diagnostic.resteAVivre && formatNumberCurrency({ number: diagnostic.resteAVivre })}/mois`}
          type="text"
          fieldClassName={styles.diagnostic__input}
        />
      ) : paliers.map(({ resteAVivre }, index) => {
        return (
          <Input
            className={styles.diagnostic__titles}
            name={`reste_a_vivre${index}`}
            label={`Reste à vivre - Palier ${index + 1}`}
            aria-label={`Reste à vivre - Palier ${index + 1}`}
            readOnly
            value={`${resteAVivre && formatNumberCurrency({ number: resteAVivre })}/mois`}
            type="text"
            fieldClassName={styles.diagnostic__input}
          />
        )
      })}
    </Card>
  )
}
