import { DATE_DD_MM_YYYY_FR } from '@core/common'
import { isAfter } from 'date-fns'
import { RevenuFormUI, RevenuListUI, TypeRevenu } from '../../../../model'
import { DATE_MM_YYYY } from '../../../../model/format'
import { dateFromGraphQL, dateToGraphQL } from '../../../../utils'

const removeTypeName = ({ __typename, ...data }) => data

const basicToRevenuFormUI = (revenuGraphQL): RevenuFormUI|undefined => revenuGraphQL ? {
  ...revenuGraphQL,
  montant: revenuGraphQL.montant,
  debut: revenuGraphQL.debut ? dateFromGraphQL(revenuGraphQL.debut, DATE_MM_YYYY) : undefined,
  fin: revenuGraphQL.fin ? dateFromGraphQL(revenuGraphQL.fin, DATE_DD_MM_YYYY_FR) : null,
} : undefined

const toRevenusFormUI = revenusFromGraphQL => revenusFromGraphQL.map(basicToRevenuFormUI)

export const toRevenuListUI = (personneId: string, revenusGraphQLRaw: any[]): RevenuListUI => {
  const revenusGraphQL = (revenusGraphQLRaw || []).map(removeTypeName)
  const baisseRevenu = revenusGraphQL.find(revenu => revenu?.debut && isAfter(new Date(revenu.debut), new Date()) && revenu.type === TypeRevenu.RETRAITE)
  const revenus = baisseRevenu ? revenusGraphQL.filter(revenu => revenu.id !== baisseRevenu?.id) : revenusGraphQL
  return new RevenuListUI(personneId, revenusGraphQL ? toRevenusFormUI(revenus) : [], basicToRevenuFormUI(baisseRevenu))
}

export const toRevenuFormUI = (revenuGraphQL: any, personneId: string, newId?: number): RevenuFormUI => {
  const id = newId || revenuGraphQL.id
  return { ...basicToRevenuFormUI(removeTypeName(revenuGraphQL)), personneId, id } as RevenuFormUI
}

export const toRevenuGraphQL = (revenu: any) => ({
  ...revenu,
  debut: dateToGraphQL(revenu.debut, DATE_MM_YYYY),
  fin: dateToGraphQL(revenu.fin, DATE_DD_MM_YYYY_FR),
  montant: Number(revenu?.montant),
  type: revenu?.type,
})
