import React from 'react'
import { Card, Hourglass, Button } from '@mmb/ui-components'

import styles from './AwaitingAssurance.module.css'

export function AwaitingAssurance() {
  return (
    <Card className={styles.awaitingAssurance__card}>
      <h2 className={styles.awaitingAssurance__title}>Création en cours</h2>
      <Hourglass className={styles.awaitingAssurance__icon} />
      <div className={styles.awaitingAssurance__content}>
        <p>Votre parcours assurance est en cours de lancement.</p>
        <p>Cette action peut prendre quelques minutes.</p>
        <Button
          iconClassName={styles.awaitingAssurance__button__icon}
          className={styles.awaitingAssurance__button}
          loading
        >
          Lancement en cours...
        </Button>
      </div>
    </Card>
  )
}
