import { head } from 'lodash'
import { CreanceUI } from './CreanceUI'
import { EncoursFormUI, EncoursType } from './EncoursFormUI'
import { PalierUI } from './PalierUI'

export enum NaturePret {
  PRET_IMMOBILIER_HYPOTHECAIRE = 'PRET_IMMOBILIER_HYPOTHECAIRE',
  PRET_IMMOBILIER_NON_HYPOTHECAIRE = 'PRET_IMMOBILIER_NON_HYPOTHECAIRE',
  PRET_TAUX_ZERO_HYPOTHECAIRE = 'PRET_TAUX_ZERO_HYPOTHECAIRE',
  PRET_TAUX_ZERO_NON_HYPOTHECAIRE = 'PRET_TAUX_ZERO_NON_HYPOTHECAIRE',
  PRET_DE_RESTRUCTURATION_HYPOTHECAIRE = 'PRET_DE_RESTRUCTURATION_HYPOTHECAIRE',
  PRET_DE_RESTRUCTURATION_NON_HYPOTHECAIRE = 'PRET_DE_RESTRUCTURATION_NON_HYPOTHECAIRE',
  LOCATION_AVEC_OPTION_D_ACHAT = 'LOCATION_AVEC_OPTION_D_ACHAT',
  PRET_EMPLOYEUR = 'PRET_EMPLOYEUR',
  PRET_PATRONAL = 'PRET_PATRONAL',
  PRET_PERSONNEL_NON_HYPOTHECAIRE = 'PRET_PERSONNEL_NON_HYPOTHECAIRE',
  PRET_PERSONNEL_HYPOTHECAIRE = 'PRET_PERSONNEL_HYPOTHECAIRE',
  PRET_TRAVAUX_NON_HYPOTHECAIRE = 'PRET_TRAVAUX_NON_HYPOTHECAIRE',
  PRET_TRAVAUX_HYPOTHECAIRE = 'PRET_TRAVAUX_HYPOTHECAIRE',
  PRET_VOITURE = 'PRET_VOITURE',
  CREDIT_RENOUVELABLE = 'CREDIT_RENOUVELABLE',
  DECOUVERT = 'DECOUVERT',
}

const NaturesImmo = [
  NaturePret.PRET_IMMOBILIER_HYPOTHECAIRE,
  NaturePret.PRET_IMMOBILIER_NON_HYPOTHECAIRE,
  NaturePret.PRET_TAUX_ZERO_HYPOTHECAIRE,
  NaturePret.PRET_TAUX_ZERO_NON_HYPOTHECAIRE,
  NaturePret.PRET_DE_RESTRUCTURATION_HYPOTHECAIRE,
]

const NaturesConso = [
  NaturePret.PRET_DE_RESTRUCTURATION_NON_HYPOTHECAIRE,
  NaturePret.LOCATION_AVEC_OPTION_D_ACHAT,
  NaturePret.CREDIT_RENOUVELABLE,
  NaturePret.DECOUVERT,
  NaturePret.PRET_VOITURE,
  NaturePret.PRET_EMPLOYEUR,
  NaturePret.PRET_PATRONAL,
  NaturePret.PRET_PERSONNEL_NON_HYPOTHECAIRE,
  NaturePret.PRET_PERSONNEL_HYPOTHECAIRE,
  NaturePret.PRET_TRAVAUX_NON_HYPOTHECAIRE,
  NaturePret.PRET_TRAVAUX_HYPOTHECAIRE,
]

export enum RegimePret {
  CONSO = 'CONSO',
  IMMO = 'IMMO',
}

export enum SourcePret {
  SUR_JUSTIFICATIF = 'SUR_JUSTIFICATIF',
  SUR_DECLARATIF = 'SUR_DECLARATIF',
  NON_COMMUNIQUE = 'NON_COMMUNIQUE',
}

export const SOURCE_PRET_MAP = new Map<SourcePret | undefined, string>([
  [SourcePret.NON_COMMUNIQUE, 'Non communiqué'],
  [SourcePret.SUR_DECLARATIF, 'Sur déclaratif'],
  [SourcePret.SUR_JUSTIFICATIF, 'Sur justificatif'],
])

export enum Finalite {
  INVESTISSEMENT_LOCATIF = 'INVESTISSEMENT_LOCATIF'
}

export interface PretFormUI extends EncoursFormUI {
  sourceTaux: SourcePret
  sourcePreavis: SourcePret
  sourceIra: SourcePret
  sourceFin: SourcePret
  paliers: PalierUI[]
  nature?: NaturePret
  nomPreteur?: string
  reference?: string
  debut?: string
  fin?: string
  taux?: number
  regime?: RegimePret
  montantCrd?: number
  montantIra?: number
  montantCapitalInitial?: number
  bienFinance?: string
  preavis?: number
  finalite?: Finalite
}

export const buildDefaultPret = (): PretFormUI => ({
  type: EncoursType.PRET,
  repris: true,
  sourceTaux: SourcePret.NON_COMMUNIQUE,
  sourcePreavis: SourcePret.NON_COMMUNIQUE,
  sourceFin: SourcePret.NON_COMMUNIQUE,
  sourceIra: SourcePret.NON_COMMUNIQUE,
  paliers: [],
})

export const prettoCreanceUI = (pret: PretFormUI): CreanceUI => ({
  repris: pret.repris,
  capitalRestantDu: Number(pret.montantCrd || 0),
  echeance: Number(head<any>(pret.paliers)?.montantEcheance || 0),
  indemnitesDeRemboursementAnticipe: Number((pret.montantIra || 0)),
})

export const getDefaultRegime = (nature: NaturePret): RegimePret | undefined => {
  if (NaturesImmo.includes(nature)) {
    return RegimePret.IMMO
  }

  if (NaturesConso.includes(nature)) {
    return RegimePret.CONSO
  }

  return undefined
}
