import React from 'react'
import { string, func } from 'prop-types'
import { Input } from '@mmb/ui-components'
import { get, has } from 'lodash'
import { flow, isNil, mapKeys, omitBy, pick } from 'lodash/fp'
import Downshift from 'downshift'
import classNames from 'classnames'
import { useAdresses } from './adresse.hook'

import styles from './StreetAutoComplete.module.css'

export const getNewAdresse = flow(
  pick(['name', 'postcode', 'city', 'housenumber', 'street']),
  omitBy(isNil),
  mapKeys(key => {
    if (key === 'street') return 'streetName'
    if (key === 'name') return 'street'
    if (key === 'postcode') return 'postalCode'
    return key
  }),
)


StreetAutoComplete.propTypes = {
  className: string,
  onChange: func.isRequired,
  value: string,
  type: string,
}

StreetAutoComplete.defaultProps = {
  className: '',
  value: '',
  type: '',
}

export function StreetAutoComplete({ className, onChange, value, type }) {
  const { setStreet, adresses } = useAdresses(type)

  return (
    <Downshift
      onStateChange={changes => {
        if (has(changes, 'selectedItem')) {
          const newAdresse = getNewAdresse(changes.selectedItem)
          onChange(newAdresse)
        } else if (has(changes, 'inputValue')) {
          onChange({ street: changes.inputValue })
        }
      }}
      itemToString={item => get(item, 'name', item)}
      onInputValueChange={inputValue => setStreet(inputValue)}
      initialInputValue={value}
      selectedItem={value}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem,
      }) => (
        <div className={styles.autocomplete}>
          <Input
            {...getInputProps()}
            label="N° et voie"
            name="street"
            className={className}
            required
            starry
          />
          <ul
            {...getMenuProps()}
            className={classNames(styles.autocomplete__list, {
              [styles.autocompleteHidden]: !isOpen,
            })}
          >
            {isOpen
              ? adresses.map((adresse, index) => (
                <li
                  {...getItemProps({
                    key: adresse.id,
                    index,
                    item: adresse,
                  })}
                  className={classNames(styles.autocomplete__item, {
                    [styles.autocomplete__itemHighlighted]: highlightedIndex === index,
                    [styles.autocomplete__itemSelected]: selectedItem === adresse,
                  })}
                >
                  {adresse.label}
                </li>
              ))
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  )
}

