import React from 'react'
import cn from 'classnames'
import { string } from 'prop-types'

import styles from './Separator.module.css'

Separator.propTypes = {
  className: string,
}

Separator.defaultProps = {
  className: '',
}

export function Separator({ className }) {
  return <hr className={cn(styles.separator, className)} />
}
