import React from 'react'
import { object } from 'prop-types'

import { ASSURANCE_OPTIONS, ASSURANCE_FORMULES_OPTIONS } from '../../../../model'

import styles from './Body.module.css'

BodyAssurance.propTypes = {
  assurance: object.isRequired,
}
export function BodyAssurance({ assurance }) {
  return (
    <div className={styles.simultaion__assurances__body__row}>
      {!assurance || !assurance.statut || assurance.statut === 'null' ? (
        <div className={styles.simultaion__assurances__body__row__header}>
          <span>Assurance</span>
          <span>Pas de co-emprunteur</span>
        </div>
      ) : (
        <>
          <div className={styles.simultaion__assurances__body__row__header}>
            <span>Assurance</span>
            <span>{assurance?.nom} {assurance?.prenom}</span>
          </div>
          <span className={styles.simultaion__assurances__body__row__header}>
            {assurance?.montantEcheance && `${assurance?.montantEcheance.toFixed(2)} €/mois`}
          </span>
          <div className={styles.simultaion__assurances__body__row__footer}>
            <span>{assurance?.type && ASSURANCE_OPTIONS.find(option => option.value === assurance?.type)?.label}</span>
            <span>
              {assurance?.couverture && assurance.quotite &&
                `${ASSURANCE_FORMULES_OPTIONS.find(option => option.value === assurance?.couverture)?.label} - 
                ${assurance.quotite}%`}
            </span>
          </div>
        </>
      )}
    </div>
  )
}
