import React from 'react'

import { node, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Panel.module.css'

Panel.propTypes = {
  className: string,
  children: node,
}

Panel.defaultProps = {
  className: '',
  children: null,
}

export function Panel({ children, className, ...props }) {
  return (
    <div className={classNames(styles.panel, className)} {...props}>
      {children}
    </div>
  )
}
