import { useState } from 'react'

export function useForm(formInitialState) {
  const [form, setForm] = useState(formInitialState)

  const setFormField = (field, value) => {
    setForm(current => ({
      ...current,
      [field]: value,
    }))
  }

  const setFormFieldFromEvent = ({ target }) => {
    const field = target.getAttribute('name')
    const value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value
    setFormField(field, value)
  }

  return {
    form,
    setForm,
    setFormField,
    setFormFieldFromEvent,
  }
}
