import React, { FunctionComponent } from 'react'

import { PinnedAction } from './PinnedAction'

import styles from './ActionBoard.module.css'

interface ActionBoardProps {
  actions: {
    title: string
    label: string
    action: () => void
  }[]
}

export const ActionBoard: FunctionComponent<ActionBoardProps> = ({ actions }) => {
  return (
    <div
      className={styles.actionBoard}
    >
      {actions.map(action => (
        <PinnedAction
          key={action.title}
          title={action.title}
          label={action.label}
          action={action.action}
        />
      ),
      )}
    </div>
  )
}
