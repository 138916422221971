import React from 'react'
import cn from 'classnames'
import { node, string } from 'prop-types'
import { Message, CloseCross } from '@mmb/ui-components'

import styles from './ErrorMessage.module.css'

ErrorMessage.propTypes = {
  children: node,
  className: string,
}

ErrorMessage.defaultProps = {
  children: null,
  className: '',
}

export function ErrorMessage({ children, className, ...props }) {
  return (
    <Message
      type="danger"
      icon={CloseCross}
      iconClassName={styles.message__icon}
      role="alert"
      contentClassName={cn(styles.message, className)}
      {...props}
    >
      {children}
    </Message>
  )
}
