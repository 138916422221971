import { PositionableFormUI } from '../support/PositionableFormUI'

export enum ActiviteType {
  SALARIE_DU_PRIVE = 'SALARIE_DU_PRIVE',
  SALARIE_DU_PUBLIC = 'SALARIE_DU_PUBLIC',
  RENTIER = 'RENTIER',
  SANS_ACTIVITE_PROFESSIONNELLE = 'SANS_ACTIVITE_PROFESSIONNELLE',
  RETRAITE = 'RETRAITE',
  NON_SALARIE = 'NON_SALARIE',
}

export enum ActiviteContrat {
  CDI = 'CDI',
  CDD = 'CDD',
  INTERIMAIRE = 'INTERIMAIRE',
  INTERMITTENT = 'INTERMITTENT',
  CESU = 'CESU',
  TITULAIRE_FONCTION_PUBLIQUE = 'TITULAIRE_FONCTION_PUBLIQUE',
  STAGIAIRE_FONCTION_PUBLIQUE = 'STAGIAIRE_FONCTION_PUBLIQUE',
  CONTRACTUEL_FONCTION_PUBLIQUE = 'CONTRACTUEL_FONCTION_PUBLIQUE',
  APPRENTISSAGE = 'APPRENTISSAGE',
  AIDE = 'AIDE',
  STAGE = 'STAGE'
}

export enum ActiviteCategSocioProf {
  '11_AGRICULTEURS_SUR_PETITE_EXPLOITATION' = '11_AGRICULTEURS_SUR_PETITE_EXPLOITATION',
  '12_AGRICULTEURS_SUR_MOYENNE_EXPLOITATION' = '12_AGRICULTEURS_SUR_MOYENNE_EXPLOITATION',
  '13_AGRICULTEURS_SUR_GRANDE_EXPLOITATION' = '13_AGRICULTEURS_SUR_GRANDE_EXPLOITATION',
  '21_ARTISANS' = '21_ARTISANS',
  '22_COMMERCANTS_ET_ASSIMILES' = '22_COMMERCANTS_ET_ASSIMILES',
  '23_CHEFS_D_ENTREPRISE_DE_10_SALARIES_OU_PLUS' = '23_CHEFS_D_ENTREPRISE_DE_10_SALARIES_OU_PLUS',
  '31_PROFESSIONS_LIBERALES' = '31_PROFESSIONS_LIBERALES',
  '33_CADRES_DE_LA_FONCTION_PUBLIQUE' = '33_CADRES_DE_LA_FONCTION_PUBLIQUE',
  '34_PROFESSEURS_PROFESSIONS_SCIENTIFIQUES' = '34_PROFESSEURS_PROFESSIONS_SCIENTIFIQUES',
  '35_PROFESSIONS_DE_L_INFORMATION_DES_ARTS_ET_DES_SPECTACLES' = '35_PROFESSIONS_DE_L_INFORMATION_DES_ARTS_ET_DES_SPECTACLES',
  '37_CADRES_ADMINISTRATIFS_ET_COMMERCIAUX_D_ENTREPRISE' = '37_CADRES_ADMINISTRATIFS_ET_COMMERCIAUX_D_ENTREPRISE',
  '38_INGENIEURS_ET_CADRES_TECHNIQUES_D_ENTREPRISE' = '38_INGENIEURS_ET_CADRES_TECHNIQUES_D_ENTREPRISE',
  '42_PROFESSEURS_DES_ECOLES_INSTITUTEURS_ET_ASSIMILES' = '42_PROFESSEURS_DES_ECOLES_INSTITUTEURS_ET_ASSIMILES',
  '43_PROFESSIONS_INTERMEDIAIRES_DE_LA_SANTE_ET_DU_TRAVAIL_SOCIAL' = '43_PROFESSIONS_INTERMEDIAIRES_DE_LA_SANTE_ET_DU_TRAVAIL_SOCIAL',
  '44_CLERGE_RELIGIEUX' = '44_CLERGE_RELIGIEUX',
  '45_PROFESSIONS_INTERMEDIAIRES_ADMINISTRATIVES_DE_LA_FONCTION_PUBLIQUE' = '45_PROFESSIONS_INTERMEDIAIRES_ADMINISTRATIVES_DE_LA_FONCTION_PUBLIQUE',
  '46_PROFESSIONS_INTERMEDIAIRES_ADMINISTRATIVES_ET_COMMERCIALES_DES_ENTREPRISES' =
  '46_PROFESSIONS_INTERMEDIAIRES_ADMINISTRATIVES_ET_COMMERCIALES_DES_ENTREPRISES',
  '47_TECHNICIENS' = '47_TECHNICIENS',
  '48_CONTREMAITRES_AGENTS_DE_MAITRISE' = '48_CONTREMAITRES_AGENTS_DE_MAITRISE',
  '52_EMPLOYES_CIVILS_ET_AGENTS_DE_SERVICE_DE_LA_FONCTION_PUBLIQUE' = '52_EMPLOYES_CIVILS_ET_AGENTS_DE_SERVICE_DE_LA_FONCTION_PUBLIQUE',
  '53_POLICIERS_ET_MILITAIRES' = '53_POLICIERS_ET_MILITAIRES',
  '54_EMPLOYES_ADMINISTRATIFS_D_ENTREPRISE' = '54_EMPLOYES_ADMINISTRATIFS_D_ENTREPRISE',
  '55_EMPLOYES_DE_COMMERCE' = '55_EMPLOYES_DE_COMMERCE',
  '56_PERSONNELS_DES_SERVICES_DIRECTS_AUX_PARTICULIERS' = '56_PERSONNELS_DES_SERVICES_DIRECTS_AUX_PARTICULIERS',
  '57_AGENTS_DE_SURVEILLANCE' = '57_AGENTS_DE_SURVEILLANCE',
  '58_COMMERCANTS_AMBULANTS_VRP' = '58_COMMERCANTS_AMBULANTS_VRP',
  '59_PERSONNEL_BAR_HOTEL_RESTAURANT' = '59_PERSONNEL_BAR_HOTEL_RESTAURANT',
  '62_OUVRIERS_QUALIFIES_DE_TYPE_INDUSTRIEL' = '62_OUVRIERS_QUALIFIES_DE_TYPE_INDUSTRIEL',
  '63_OUVRIERS_QUALIFIES_DE_TYPE_ARTISANAL' = '63_OUVRIERS_QUALIFIES_DE_TYPE_ARTISANAL',
  '64_CHAUFFEURS' = '64_CHAUFFEURS',
  '65_OUVRIERS_QUALIFIES_DE_LA_MANUTENTION_DU_MAGASINAGE_ET_DU_TRANSPORT' = '65_OUVRIERS_QUALIFIES_DE_LA_MANUTENTION_DU_MAGASINAGE_ET_DU_TRANSPORT',
  '67_OUVRIERS_NON_QUALIFIES_DE_TYPE_INDUSTRIEL' = '67_OUVRIERS_NON_QUALIFIES_DE_TYPE_INDUSTRIEL',
  '68_OUVRIERS_NON_QUALIFIES_DE_TYPE_ARTISANAL' = '68_OUVRIERS_NON_QUALIFIES_DE_TYPE_ARTISANAL',
  '69_OUVRIERS_AGRICOLES' = '69_OUVRIERS_AGRICOLES',
  '70_TRANSPORTEURS_ET_TAXIS' = '70_TRANSPORTEURS_ET_TAXIS',
  '71_ANCIENS_AGRICULTEURS_EXPLOITANTS' = '71_ANCIENS_AGRICULTEURS_EXPLOITANTS',
  '72_ANCIENS_ARTISANS_COMMERÇANTS_CHEFS_D_ENTREPRISE' = '72_ANCIENS_ARTISANS_COMMERÇANTS_CHEFS_D_ENTREPRISE',
  '74_ANCIENS_CADRES' = '74_ANCIENS_CADRES',
  '75_ANCIENNES_PROFESSIONS_INTERMEDIAIRES' = '75_ANCIENNES_PROFESSIONS_INTERMEDIAIRES',
  '77_ANCIENS_EMPLOYES' = '77_ANCIENS_EMPLOYES',
  '78_ANCIENS_OUVRIERS' = '78_ANCIENS_OUVRIERS',
  '81_CHOMEURS_N_AYANT_JAMAIS_TRAVAILLE' = '81_CHOMEURS_N_AYANT_JAMAIS_TRAVAILLE',
  '83_MILITAIRES_DU_CONTINGENT' = '83_MILITAIRES_DU_CONTINGENT',
  '84_ELEVES_ETUDIANTS' = '84_ELEVES_ETUDIANTS',
  '85_PERSONNES_DIVERSES_SANS_ACTIVITE_PROFESSIONNELLE_DE_MOINS_DE_60_ANS_SAUF_RETRAITES' =
  '85_PERSONNES_DIVERSES_SANS_ACTIVITE_PROFESSIONNELLE_DE_MOINS_DE_60_ANS_SAUF_RETRAITES',
  '86_PERSONNES_DIVERSES_SANS_ACTIVITE_PROFESSIONNELLE_DE_60_ANS_ET_PLUS_SAUF_RETRAITES' =
  '86_PERSONNES_DIVERSES_SANS_ACTIVITE_PROFESSIONNELLE_DE_60_ANS_ET_PLUS_SAUF_RETRAITES',
}

export const DEFAULT_ACTIVITE: ActiviteFormUI = {
  id: '',
  type: undefined,
  catSocio: '',
  profession: '',
  typeContrat: '',
  debut: '',
  isPrincipale: true,
}

export interface ActiviteFormUI extends PositionableFormUI {
  id?: string
  type?: ActiviteType
  typeContrat?: string
  catSocio?: string
  isPrincipale?: boolean
  profession?: string
  debut?: string
}
