import { gql, useMutation } from '@core/graphql'
import { PermissionScope } from '@core/common'
import { useHasPermission, useUtilisateurMultiPartenaire } from '@core/partenaire-common'
import { AssuranceUI } from '../../../../model/assurance/AssuranceUI'

export const CREATE_ASSURANCE = gql`
mutation createAssurance($id: String!, $idPersonne: String!) {
  partenaire_createAssurance(id: $id, idPersonne: $idPersonne)
}`

export function useAssuranceParcours({ id, idPersonne, assuranceUI } : { id: string, idPersonne: string, assuranceUI: AssuranceUI }) {
  useUtilisateurMultiPartenaire()
  const [createAssurance, { loading }] = useMutation(CREATE_ASSURANCE, {
    variables: { id, idPersonne },
    onCompleted: () => { assuranceUI.props.refetch() },
  })
  const hasPermissionParcoursAssurance = useHasPermission(PermissionScope.PARCOURS_ASSURANCE_WRITE)
  return { createAssurance, loading, hasPermissionParcoursAssurance }
}
