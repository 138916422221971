import { OptionUI } from '../OptionUI'
import { NatureDette } from './DetteFormUI'

export const NATURE_DETTE_OPTIONS: OptionUI[] = [
  new OptionUI('Dette fiscale', NatureDette.DETTE_FISCALE),
  new OptionUI('Dette privée', NatureDette.DETTE_PRIVEE),
  new OptionUI('Dette huissier', NatureDette.DETTE_HUISSIER),
  new OptionUI('Retard de loyer', NatureDette.RETARD_DE_LOYER),
  new OptionUI('Retard de charge de copropriété', NatureDette.RETARD_DE_CHARGES_DE_COPRO),
  new OptionUI('Dette société de recouvrement', NatureDette.DETTE_SOCIETE_DE_RECOUVREMENT),
  new OptionUI('Soulte', NatureDette.SOULTE),
]

export const getSelectedNatureDette = (natureValue?: NatureDette) => NATURE_DETTE_OPTIONS.find(({ value }) => value === natureValue) || ''
