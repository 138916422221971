import { SupportStateUI } from './support'

export interface SaisiePropsUI {
  hasCoEmprunteur: boolean
  gamme: string
  hasPretImmoWithPalier: boolean
  dossierId?: string | null
  demandeId?: string | null
  emprunteurId?: string | null
  coEmprunteurId?: string | null
  agence?: any
  apporteur?: string | null
}

export class SaisieUI extends SupportStateUI {
  constructor(public readonly props: SaisiePropsUI) {
    super()
  }

  protected clone() : SaisieUI {
    return new SaisieUI({ ...this.props })
  }

  public update(props: SaisiePropsUI) : SaisieUI {
    return new SaisieUI({ ...this.props, ...props })
  }

  public isSecured() : boolean {
    return this.props.gamme === 'SECURED'
  }

  public isDossierLoaded() : boolean { return !!this.props?.dossierId }

  public static defaultProps() : SaisiePropsUI {
    return {
      hasCoEmprunteur: true,
      gamme: 'SECURED',
      hasPretImmoWithPalier: false,
      dossierId: null,
      demandeId: null,
      emprunteurId: null,
      coEmprunteurId: null,
      agence: null,
      apporteur: null,
    }
  }

  public static default() : SaisieUI {
    return new SaisieUI(SaisieUI.defaultProps())
  }
}
