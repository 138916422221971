import { AmorcageAdhesionErreur } from '@partenaire/common'
import { differenceInCalendarYears, parse } from 'date-fns'

export enum SituationAdhesionUI {
  NON_DEMARREE = 'NON_DEMARREE', EN_COURS = 'EN_COURS', CLOTUREE = 'CLOTUREE'
}

export enum AssuranceTypeUI {
  ASSURANCE_GROUP_DCU = 'ASSURANCE_GROUP_DCU',
  ASSURANCE_GROUP_DCS = 'ASSURANCE_GROUP_DCS',
  DELEGATION = 'DELEGATION',
  SANS_ASSURANCE = 'SANS_ASSURANCE'
}

const mapSituationAdhesionLabel = new Map<SituationAdhesionUI, string>([
  [SituationAdhesionUI.CLOTUREE, 'Traité'],
  [SituationAdhesionUI.EN_COURS, 'En cours'],
  [SituationAdhesionUI.NON_DEMARREE, 'En attente'],
])

const mapTypeAssuranceLibelle = new Map<AssuranceTypeUI, string>([
  [AssuranceTypeUI.ASSURANCE_GROUP_DCS, 'Assurance Groupe'],
  [AssuranceTypeUI.ASSURANCE_GROUP_DCU, 'Assurance Groupe'],
  [AssuranceTypeUI.SANS_ASSURANCE, 'Sans assurance'],
  [AssuranceTypeUI.DELEGATION, 'Délégation extérieure'],
])

const AGE_LIMIT = 75


export class AssuranceUI {
  constructor(public readonly props: AssurancePropsUI) {
  }

  public isAssuranceGroupDCU(): boolean {
    return this.props.type === AssuranceTypeUI.ASSURANCE_GROUP_DCU
  }

  public hasType(): boolean {
    return !!this.props.type
  }

  public isValid(): boolean {
    return this.props.situationAdhesion === SituationAdhesionUI.CLOTUREE
  }

  public showDateSituationAdhesion(): boolean {
    return this.isValid()
  }

  public getLibelleSituationAdhesion(): string {
    return mapSituationAdhesionLabel.get(this.props.situationAdhesion) ?? 'Aucune information'
  }

  public hasReferenceExterne(): boolean {
    return this.props.type === AssuranceTypeUI.ASSURANCE_GROUP_DCS && !!this.props.assuranceDetailDCSPropsUI?.numeroAdhesion
  }

  public getLibelleStatut(): string {
    let libelleStatut
    if (this.props.type === AssuranceTypeUI.ASSURANCE_GROUP_DCU) {
      libelleStatut = this.props.assuranceDetailDCUPropsUI?.libelleStatut
    } else if (this.props.type === AssuranceTypeUI.SANS_ASSURANCE) {
      libelleStatut = this.props.sansAssuranceUI?.typeRefus
    } else {
      libelleStatut = this.props.assuranceDetailDCSPropsUI?.libelleStatut
    }
    return libelleStatut || ''
  }

  public getDateStatut(): string {
    let dateStatut
    if (this.props.type === AssuranceTypeUI.ASSURANCE_GROUP_DCU) {
      dateStatut = this.props.assuranceDetailDCUPropsUI?.dateStatut
    } else if (this.props.type === AssuranceTypeUI.SANS_ASSURANCE) {
      dateStatut = this.props.sansAssuranceUI?.dateRefus
    } else {
      dateStatut = this.props.assuranceDetailDCSPropsUI?.dateStatut
    }
    return dateStatut || ''
  }

  public getLibelleType(): string {
    return mapTypeAssuranceLibelle.get(this.props.type) || 'Type d\'assurance non communiqué'
  }

  public hasValidAge(): boolean {
    return differenceInCalendarYears(new Date(), parse(this.props.dateNaissance, 'dd/MM/yyyy', new Date())) < AGE_LIMIT
  }

  public shouldStopRefetching(): boolean {
    return this.props.amorcageAdhesion?.statut !== AmorcageAdhesionStatutUI.EN_COURS
  }

  public getErrorMessage(): string | undefined {
    const error : AmorcageAdhesionErreur | undefined = this?.props?.amorcageAdhesion?.erreur
    return error ? mapErrorAssuranceGroupDCSLibelle.get(error) : undefined
  }

  public isAmorcageEnCours(): boolean {
    return this.props?.amorcageAdhesion?.statut === AmorcageAdhesionStatutUI.EN_COURS
  }
}

export interface AssurancePropsUI {
  id: string
  idDossier: string
  idPersonne: string
  nom: string
  dateNaissance: string
  type: AssuranceTypeUI
  organisme?: string
  situationAdhesion: SituationAdhesionUI
  dateSituationAdhesion: string
  quotite?: string
  formule?: string
  cotisation?: string
  tauxStandard?: string
  tauxFinal?: string
  amorcageAdhesion?: AmorcageAdhesionUI
  assuranceDetailDCUPropsUI?: AssuranceDetailDCUPropsUI
  assuranceDetailDCSPropsUI?: AssuranceDetailDCSPropsUI
  sansAssuranceUI?: SansAssuranceUI
  isDossierSecured?: boolean
  refetch: () => void
}

export interface AssuranceDetailDCUPropsUI {
  situation: string
  dateValidation: string
  statut: string
  libelleStatut: string
  dateStatut: string
}

export enum AmorcageAdhesionStatutUI {
  AMORCABLE = 'AMORCABLE',
  EN_COURS = 'EN_COURS',
  AMORCEE = 'AMORCEE',
  NON_APPLICABLE = 'NON_APPLICABLE'
}

export interface AmorcageAdhesionUI {
  statut: AmorcageAdhesionStatutUI
  erreur?: AmorcageAdhesionErreur
}

const mapErrorAssuranceGroupDCSLibelle = new Map <AmorcageAdhesionErreur, string>([
  [AmorcageAdhesionErreur.AGENCE_NON_AUTORISEE, 'Agence non autorisée'],
  [AmorcageAdhesionErreur.UTILISATEUR_DESACTIVEE, 'Utilisateur désactivé'],
  [AmorcageAdhesionErreur.DOUBLON_PARCOURS_ASSURANCE, 'Dossier déjà existant, demander l\'annulation du premier dossier'],
  [AmorcageAdhesionErreur.NATIONALITE_NON_AUTORISEE, 'Nationalité non autorisée'],
  [AmorcageAdhesionErreur.PAYS_NAISSANCE_NON_AUTORISE, 'Pays de naissance non autorisé'],
  [AmorcageAdhesionErreur.FORMAT_DONNEES_INVALIDE, 'Format invalide (ex: un n° dans le nom)'],
  [AmorcageAdhesionErreur.FORMAT_EMAIL_INVALIDE, 'Format de mail invalide'],
  [AmorcageAdhesionErreur.ERREUR_TECHNIQUE, 'L\'ouverture du parcours assurance a échoué.'],
])

export enum StatutAssuranceGroupDCS {
  SIMULATION = 'SIMULATION',
  FORMALITES_ADHESION = 'FORMALITES_ADHESION',
  FORMALITES_MEDICALES = 'FORMALITES_MEDICALES',
  DECISION = 'DECISION',
  FINALISATION = 'FINALISATION',
  SANS_SUITE = 'SANS_SUITE',
}

export interface AssuranceDetailDCSPropsUI {
  numeroAdhesion?: string
  webcreditorUrl: string
  statut?: StatutAssuranceGroupDCS
  libelleStatut: string
  dateStatut: string
  libelleDetailStatut: string
  detailStatut: string
}

export interface SansAssuranceUI {
  dateRefus: string
  typeRefus?: string
}
