import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { Saisie } from './components/Saisie'
import { SaisieProvider, PrintProvider } from './components/Contexts'
import { FormStateProvider } from './components/Contexts/FormStateContext'

export function SaisieRoute({ ...props }) {
  return (
    <FormStateProvider>
      <SaisieProvider>
        <PrintProvider>
          <Routes {...props}>
            <Route path="/" element={<Saisie />} />
            <Route path="/:dossierId" element={<Saisie />} />
          </Routes>
        </PrintProvider>
      </SaisieProvider>
    </FormStateProvider>
  )
}
