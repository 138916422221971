import { FormEvent, useEffect, useState } from 'react'
import { gql, useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'


import { useSaisie } from '../Contexts'
import { useGetEntityFormState, useHandleSubmit, useValidation } from '../FormState/form.state.hooks'
import { toDemandeParsed } from '../../utils/ValidationErrors/demandeValidation'
import { EntryFormStateName } from '../FormState/EntryFormState'
import { useRevenus } from '../SituationFinanciere/Revenus'
import { Demande, TypeAmortissement } from '../../model/demande/Demande'
import { ErrorMessage } from '../SaisieErrorMessages'
import { getMontageError } from '../../utils'
import { useGamme } from '../Gamme'

export const GET_MONTAGE_DOSSIER = gql`
query getMontageDossier($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    demande {
      id
      dureeFinancement
      fraisMandat
      apport
      typeAmortissement
      tresorerie {
        confort
        travaux
        autre
      }
    }
  }
}
`

export const UPDATE_MONTAGE_DOSSIER = gql`
mutation updateMontageDossier($id: ID!, $demandeId: ID!, $demande: partenaire_DemandeInput) {
  partenaire_updateMontage(id: $id, demandeId: $demandeId, demande: $demande) {
    id
    dureeFinancement
    fraisMandat
    apport
    typeAmortissement
    tresorerie {
      confort
      travaux
      autre
    }
  }
}
`
export const MONTAGE_PALIERS = [{
  label: 'Linéaire',
  value: TypeAmortissement.LINEAIRE,
}, {
  label: 'A palier',
  value: TypeAmortissement.PALIER,
}]

const defaultDemande: Demande = {
  dureeFinancement: undefined,
  typeAmortissement: TypeAmortissement.LINEAIRE,
  fraisMandat: undefined,
  apport: undefined,
  tresorerie: {
    autre: undefined,
    confort: undefined,
    travaux: undefined,
  },
}

export function useMontage() {
  const navigate = useNavigate()
  const { saisieUI } = useSaisie()
  const { props: { dossierId, demandeId, hasPretImmoWithPalier } } = saisieUI
  const { revenusUI } = useRevenus()
  const changeGammeProps = useGamme()
  const [demande, setDemande]: [Demande, (Demande) => void] = useState<Demande>(defaultDemande)
  const [loadingDemande, setLoadingDemande] = useState(true)
  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.MONTAGE)
  const { hasErrors } = useGetEntityFormState(EntryFormStateName.MONTAGE)
  const { entryFormState: { value: encours } = {} } = useGetEntityFormState(EntryFormStateName.EN_COURS)
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data = {}, loading: queryLoading } = useQueryRedirectError(GET_MONTAGE_DOSSIER, navigate, {
    fetchPolicy: 'network-only',
    variables: { id: dossierId },
  })
  const [updateMontageByDossierId, { loading: mutationLoading }] = useMutation(UPDATE_MONTAGE_DOSSIER)

  useEffect(() => {
    if (!queryLoading) {
      setDemande(formatDemadeFromGraphql(data?.partenaire_dossierEnCours?.demande || {}))
      setLoadingDemande(false)
    }
  }, [data, queryLoading])

  useEffect(() => {
    if (hasPretImmoWithPalier) {
      setDemande({ ...demande, typeAmortissement: TypeAmortissement.PALIER })
    }
  }, [hasPretImmoWithPalier])

  const { errors, showErrors } = useValidation(
    EntryFormStateName.MONTAGE,
    loadingDemande,
    {
      demande,
      isSecured: saisieUI.isSecured(),
      hasBaisseRevenu: revenusUI.hasBaisseRevenu(),
    },
    {
      encours: encours?.encoursUI,
    },
  )

  useEffect(() => {
    setErrorMessages((errors.globalErrors || []).map(e => getMontageError(e, changeGammeProps)).filter(e => e))
  }, [errors, changeGammeProps])

  const updateMontageAndRevenu = async (demandeToUpdate?: Demande) => {
    if (!hasErrors) {
      await updateMontageByDossierId({
        variables: {
          id: dossierId,
          demandeId,
          demande: toDemandeParsed(demandeToUpdate || demande),
        },
      })
      await revenusUI.store()
    }
  }

  const handleSubmit = async (event: FormEvent) => doHandleSubmit(updateMontageAndRevenu, event)

  const hasTypeAmortissement = (typeAmortissement: TypeAmortissement): boolean => demande.typeAmortissement === typeAmortissement

  const changeAmortissement = (typeAmortissement: TypeAmortissement): Demande => {
    const updatedDemande = { ...demande, typeAmortissement }
    setDemande(updatedDemande)
    revenusUI?.updatePalier(typeAmortissement === TypeAmortissement.PALIER)
    return updatedDemande
  }

  return {
    loading: loadingDemande || mutationLoading,
    demande,
    handleSubmit,
    hasTypeAmortissement,
    changeDemande: newState => setDemande(state => ({ ...state, ...newState })),
    changeAmortissement,
    revenusUI,
    errors,
    errorMessages,
    showErrors,
  }
}

function formatDemadeFromGraphql({ dureeFinancement, fraisMandat, apport, tresorerie, ...demande }) {
  return {
    ...demande,
    dureeFinancement,
    fraisMandat,
    apport,
    tresorerie: {
      confort: tresorerie?.confort,
      travaux: tresorerie?.travaux,
      autre: tresorerie?.autre,
    },
  }
}
