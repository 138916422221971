import React from 'react'
import { node, string } from 'prop-types'
import { Avatar, Card } from '@mmb/ui-components'


import styles from './Identite.module.css'

Identite.propTypes = {
  nomComplet: string.isRequired,
  identifiant: string.isRequired,
  role: string,
  children: node,
}

Identite.defaultProps = {
  role: null,
  children: null,
}

export function Identite({ nomComplet, identifiant, role, children }) {
  return (
    <Card className={styles.identite}>
      <div className={styles.identite__informations}>
        <Avatar className={styles.identite__avatar} />
        <div className={styles.identite__user}>
          <h2 className={styles.identite__header}>{nomComplet}</h2>
          <p className={styles.identite__identifiant}>
            <span className={styles.identite__label}>Identifiant :</span> {identifiant}
          </p>
          {role && (
            <p className={styles.identite__role}>
              <span className={styles.identite__label}>Rôle :</span> {role}
            </p>
          )}
        </div>
      </div>
      {children}
    </Card>
  )
}

