export const GENERIC_ERROR_MESSAGE = {
  libelle: 'My Money Bank ne peut pas faire de proposition. Merci de revoir le plan de financement ou de contacter votre commercial⸱e.',
}

export const NON_VALIDITE_DIMENSIONS = ({ max, min }: { max?: string | undefined, min?: string | undefined, taux?: string | undefined }) => ({
  IMMO_CONSO_POURCENT: {
    libelle: `La part de l'immobilier doit être inférieure à ${max} en sans garantie. Merci de revoir le plan de financement`,
    hasBascule: true,
  },
  CHAMP_RESTRUCTURATION: { libelle: 'La nature d\'un des prêts ou dettes repris n\'est pas autorisée' },
  ENDETTEMENT_AVANT: { libelle: 'L\'endettement avant opération ne nous permet pas d\'intervenir' },
  MONTANT_TRESORERIE: { libelle: 'La trésorerie minimale ou maximale est à revoir' },
  NB_CREDITS_REPRIS: { libelle: 'Le nombre de crédits repris est à revoir' },
  NB_IMPAYE_CT: { libelle: 'Il y a des impayés sur un ou des prêts conso' },
  NB_IMPAYE_IMMO: { libelle: 'Il y a des impayés sur un ou des prêts immobiliers' },
  PRET_NON_REPRIS: { libelle: 'Le/les prêt(s)/dette(s) non repris ne nous permettent pas d\'intervenir' },
  ELIGIBILITE_RESTRUCTURATION: { libelle: 'La nature d\'un prêt repris et/ou son ancienneté n\'est pas autorisée' },
  NATURE_PRET_NON_REPRIS: { libelle: 'La nature d\'un prêt non repris n\'est pas autorisée' },
  DC_STRUCTURE_FINANCEMENT: {
    libelle: `La structure de financement n'est pas autorisée au vu du nombre de crédits repris
       et de la trésorerie financée dans le montage` },
  GAMME_PRODUIT: { libelle: 'Aucun produit n\'est sélectionné' },
  DUREE_FINANCEE: { libelle: 'la durée du financement ne nous permet pas d\'intervenir' },
  TYPE_ACTIVITE_CLIENT: { libelle: 'le type d\'activité sélectionné n\'est pas autorisé.' },
  DEPARTEMENT_RESIDENCE_PRINCIPALE: { libelle: 'Le code postal d\'habitation n\'est pas autorisé' },
  ELIGIBILITE_CODE_APR: { libelle: 'Cette simulation n\'est pas disponible pour ce produit et/ou régime' },
  ELIGIBILITE_DUREE: { libelle: 'La durée et/ou le montage ne nous permettent pas de vous proposer une simulation' },
  ELIGIBILITE_BIEN_EN_GARANTIE: { libelle: 'Le bien proposé en garantie n’est pas éligible' },
  VALEUR_BIEN_EN_GARANTIE: { libelle: 'La valeur du bien proposé en garantie est inférieure à la valeur autorisée' },
  NB_CREDITS_RENOUVELABLES_REPRIS: { libelle: 'Le nombre de crédits renouvelables repris ne nous permet pas d’intervenir. Veuillez revoir votre montage.' },
})

export const ERROR_MESSAGES = ({ min, max, taux }, { gamme }) => ({
  AGE_MAX_FIN_DE_PRET: {
    libelle: `L'âge du client en fin de prêt doit être inférieur au maximum de ${max} autorisé. Merci de revoir le plan de financement.`,
  },
  TAUX_USURE: {
    libelle: gamme === 'UNSECURED'
      ? `Le TAEG calculé doit être inférieur au taux d'usure de ${max}. Vous ne pouvez pas transmettre votre dossier en l'état.`
      : `Le TAEG calculé doit être inférieur au taux d'usure de ${max}. Vous pouvez transmettre votre dossier en l'état.`,
  },
  MONTANT_TRESORERIE: gamme === 'UNSECURED'
    ? {
      libelle: getMontantTresorerieMessage({ min, max }),
      hasBascule: true,
    }
    : { libelle: `${getMontantTresorerieMessage({ min, max })}.` },
  PLAFOND_TRESORERIE_POURCENT: gamme === 'SECURED'
    ? { libelle: `La trésorerie demandée est de ${taux} du montant du prêt et elle ne doit pas dépasser ${max}. Merci de revoir le plan de financement.` }
    : {
      libelle: `La trésorerie demandée doit être inférieure à ${max} du montant du prêt. Merci de revoir le plan de financement`,
      hasBascule: true,
    },
  // eslint-disable-next-line no-nested-ternary
  MONTANT_FINANCE: gamme === 'SECURED'
    ? {
      libelle: (!!min && max)
        ? `Le montant du prêt doit être compris entre ${min} et ${max}. Merci de revoir le plan de financement.`
        : `Le montant du prêt doit être supérieur à ${min}. Merci de revoir le plan de financement.`,
    }
    : {
      libelle: `Le montant du prêt doit être compris entre ${min} et ${max}. Merci de revoir le plan de financement`,
      hasBascule: true,
    },
  ENDETTEMENT_APRES: { libelle: `L'endettement après doit être inférieur à ${max}. Merci de revoir le plan de financement.` },
  RESTE_A_VIVRE_APRES: { libelle: `Le reste à vivre doit être supérieur à ${min}. Merci de revoir le plan de financement.` },
  RATIO_HYPOTHECAIRE_MONTANT_PRET: { libelle: `Le ratio hypothécaire doit être inférieur à ${max}. Merci de revoir le plan de financement.` },
  RATIO_PATRIMOINE: { libelle: `Le ratio de patrimoine doit être inférieur à ${max}. Merci de revoir le plan de financement. ` },
  DUREE_FINANCEE: { libelle: `La durée demandée doit être compris entre ${min} et ${max} mois. Merci de revoir le plan de financement.` },
  NB_IMPAYE_IMMO: {
    libelle: 'Il n\'est pas possible d\'avoir des impayés sur le(s) prêt(s) immobilier et/ou le prêt de regroupement de crédits hypothécaire.',
  },
  PRET_NON_REPRIS: {
    libelle: 'Des prêts ou dettes non rachetés ne sont pas autorisés. Merci de revoir votre montage.',
  },
})

export const SAISIE_ERROR_MESSAGE = {
  SELECTION_PRODUIT_STRUCTURE_INVALIDE_ERROR: { libelle: 'Cette simulation n\'est pas disponible au vu de la structure des revenus. Vérifier leur cohérence' },
}

const getMontantTresorerieMessage = ({ min, max }) => max ?
  `La trésorerie demandée doit être comprise entre ${min} et ${max}. Merci de revoir le plan de financement`
  : `La trésorerie minimale doit être supérieure à ${min}. Merci de revoir le plan de financement`
