import React from 'react'
import { bool, node } from 'prop-types'

import { Placeholder, TextRow } from '@mmb/ui-components'

import styles from './AdresseFormPlaceholder.module.css'


AdresseFormPlaceholder.propTypes = {
  children: node.isRequired,
  isCoEmprunteur: bool,
  loading: bool,
}

AdresseFormPlaceholder.defaultProps = {
  loading: false,
  isCoEmprunteur: false,
}

export function AdresseFormPlaceholder({ children, loading, isCoEmprunteur }) {
  return (
    <Placeholder
      loading={loading}
      customPlaceholder={(
        <div aria-label="Chargement en cours" className={styles.adresseFormPlaceholder}>
          <TextRow width="100%" className={styles.adresseFormPlaceholder__row} />
          <TextRow width="100%" className={styles.adresseFormPlaceholder__row} />
          <TextRow width="100%" className={styles.adresseFormPlaceholder__row} />
          <TextRow width="100%" className={styles.adresseFormPlaceholder__row} />
          {!isCoEmprunteur && <TextRow width="100%" className={styles.adresseFormPlaceholder__row} />}
        </div>
      )}
    >
      {children}
    </Placeholder>
  )
}
