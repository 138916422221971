import React from 'react'
import classnames from 'classnames'
import { func, shape, string } from 'prop-types'

import { Edit, TextRow } from '@mmb/ui-components'
import { Separator } from '@core/partenaire-ui'

import { useRatiosHypothecaires } from './Resultat.hooks'

import styles from './Resultat.module.css'

Resultat.propTypes = {
  form: shape({
    codePostal: string.isRequired,
    codeInsee: string.isRequired,
    cpVille: string.isRequired,
  }).isRequired,
  setShowResult: func.isRequired,
  setForm: func.isRequired,
}

export function Resultat({ form, setForm, setShowResult }) {
  const { codePostal, codeInsee, cpVille } = form
  const { loading, ratiosHypothecaires } = useRatiosHypothecaires(codePostal, codeInsee)

  if (ratiosHypothecaires?.isEligible === false) {
    const nonEligibleValue: string = 'Bien non éligible'
    ratiosHypothecaires.ratioHypothecaireLCC = nonEligibleValue
    ratiosHypothecaires.ratioHypothecaireLS2 = nonEligibleValue
    ratiosHypothecaires.ratioMyNewTreso = nonEligibleValue
  }
  const initForm = () => {
    setForm({
      codePostal: '',
      codeInsee: '',
      cpVille: '',
    })
  }

  return (
    <div className={styles.resultat} aria-label="Visualisation du ratio hypothecaire">
      <div className={styles.resultat__search}>
        {cpVille}
        <button
          aria-label="Modifier la recherche"
          className={styles.resultat__editButton}
          onClick={() => {
            initForm()
            setShowResult(false)
          }}
        >
          <Edit />
        </button>
      </div>
      <p className={styles.resultat__produit}>My Simply One</p>
      <Separator className={styles.resultat__separator} />
      <div className={styles.resultat__table}>
        {[{ produit: 'LS2', RH: ratiosHypothecaires?.ratioHypothecaireLS2 },
          { produit: 'LCC', RH: ratiosHypothecaires?.ratioHypothecaireLCC }].map(({ produit, RH }) => {
          return (
            <div className={styles.resultat__cell} key={produit} aria-label={produit}>
              <div>{produit}</div>
              <div className={styles.resultat__value} aria-label="Ratio hypothecaire">
                {loading && (<TextRow className={styles.resultat__textRow} width="5rem" />)}
                {!loading && RH}
              </div>
            </div>
          )
        })}
      </div>
      <p className={styles.resultat__produit}>My New Treso</p>
      <Separator className={styles.resultat__separator} />
      <div
        className={classnames(styles.resultat__cell, styles.resultat__value)}
        aria-label="Ratio hypothecaire pour le produit My New Treso"
      >
        {loading && (<TextRow className={styles.resultat__textRow} width="5rem" />)}
        {!loading && ratiosHypothecaires?.ratioMyNewTreso}
      </div>
    </div>
  )
}
