import { isEmpty } from 'lodash'
import { isBienValid } from './codePostalValidation'
import { PatrimoinesUI, PatrimoineUI, RegimePropriete, UsageBien } from '../../model/patrimoine'
import { buildValidationError, ValidationErrors, ValidationFieldError } from '../../model'
import { ErrorMessage } from '../../components/SaisieErrorMessages'

function validatePatrimoine(patrimoine: PatrimoineUI): ValidationFieldError[] {
  const errors: any[] = []
  // required values
  if (!patrimoine?.bien?.nature) {
    errors.push(buildValidationError('nature', 'MISSING_VALUE'))
  }
  if (!patrimoine?.bien?.usage) {
    errors.push(buildValidationError('usage', 'MISSING_VALUE'))
  }
  if (!patrimoine?.regimePropriete) {
    errors.push(buildValidationError('regimePropriete', 'MISSING_VALUE'))
  }
  if (!!patrimoine?.bien?.numero && parseInt(patrimoine?.bien?.numero, 10) < 0) {
    errors.push(buildValidationError('numero', 'NEGATIVE_VALUE'))
  }
  if (!patrimoine?.bien?.voie) {
    errors.push(buildValidationError('voie', 'MISSING_VALUE'))
  }
  if (!patrimoine?.bien?.codePostalVille) {
    errors.push(buildValidationError('codePostalVille', 'MISSING_VALUE'))
  }
  if (!patrimoine?.bien?.codeInsee) {
    errors.push(buildValidationError('codePostalVille', 'RE_INPUT_REQUIRED'))
    errors.push(buildValidationError('codeInsee', 'MISSING_VALUE'))
  }
  if (isEmpty(patrimoine?.bien?.valeur?.toString())) {
    errors.push(buildValidationError('valeurDuBien', 'MISSING_VALUE'))
  }
  return errors
}

function validatePatrimoineWithGarantie(patrimoine): ValidationFieldError[] {
  const errors: any[] = []
  // wrong or invalid values
  if (patrimoine?.regimePropriete === 'USUFRUIT_SEUL') {
    errors.push(buildValidationError('regimePropriete', 'WRONG_VALUE'))
  }
  if (patrimoine?.bien?.prixInitial && patrimoine?.bien?.prixInitial < 0) {
    errors.push(buildValidationError('prixInitial', 'NEGATIVE_VALUE'))
  }
  if (patrimoine?.bien?.valeur) {
    if (patrimoine?.bien?.valeur < 0) {
      errors.push(buildValidationError('valeurDuBien', 'NEGATIVE_VALUE'))
    } else if (patrimoine?.bien?.valeur < 60_000) {
      errors.push(buildValidationError('valeurDuBien', 'BELOW_MINIMUM'))
    }
  }
  if (patrimoine?.bien?.codePostalVille && !isBienValid(patrimoine?.bien?.codePostalVille)) {
    errors.push(buildValidationError('codePostalVille', 'UNSUPPORTED_DEPARTMENT'))
  }
  return errors
}

function getPatrimoineErrors(patrimoinesList: PatrimoineUI[], patrimoinesWithGarantie: PatrimoineUI[]): ValidationFieldError[][] {
  return patrimoinesList.map(patrimoine => ([
    ...validatePatrimoine(patrimoine),
    ...(patrimoinesWithGarantie.includes(patrimoine) ? validatePatrimoineWithGarantie(patrimoine) : []),
  ]))
}

export interface PatrimoinesErrors extends ValidationErrors {
  bienErrors: ValidationFieldError[][]
}

export function validatePatrimoines({ patrimoines, gamme }: { patrimoines: PatrimoinesUI, gamme: string }) : PatrimoinesErrors {
  const isSecured = gamme === 'SECURED'
  const patrimoinesList = patrimoines.getSortedList()
  const patrimoinesWithGarantie = patrimoines.getByGarantie(true)
  const globalErrors: ValidationFieldError[] = []

  if (isSecured && isEmpty(patrimoinesList)) {
    globalErrors.push(buildValidationError('biens', 'BIEN_REQUIRED'))
  }

  if (isSecured && !isEmpty(patrimoinesList) && !patrimoinesWithGarantie.length) {
    globalErrors.push(buildValidationError('biens', 'GARANTIE_REQUIRED'))
  }

  if (isSecured
    && !isEmpty(patrimoinesList)
    && patrimoines.getByRegime(RegimePropriete.PLEINE_PROPRIETE).filter(hasUsage(UsageBien.LOCATIF)).length > 10) {
    globalErrors.push(buildValidationError('biens', 'TOO_MANY'))
  }

  const bienErrors = getPatrimoineErrors(patrimoinesList, patrimoinesWithGarantie)
  const hasErrors = globalErrors.length > 0 || bienErrors.flatMap(p => p).length > 0
  return {
    globalErrors,
    bienErrors,
    hasErrors,
  }
}

const hasUsage = (usage: UsageBien) => (patrimoineUI: PatrimoineUI) => patrimoineUI.bien?.usage === usage

export function getPatrimoineErrorByField({ field, message }: ValidationFieldError) : ErrorMessage | null {
  if (field === 'regimePropriete' && message === 'WRONG_VALUE') {
    return {
      title: 'Nature de la propriété',
      // eslint-disable-next-line max-len
      message: 'Ce bien ne peut pas être pris en garantie. L\'emprunteur doit avoir une partie en nue propriété.',
    }
  }
  if (message === 'UNSUPPORTED_DEPARTMENT') {
    return {
      title: 'Patrimoine',
      // eslint-disable-next-line max-len
      message: 'Le département du bien à prendre en garantie ne nous permet pas d\'intervenir.',
    }
  }

  if (field === 'codeInsee' && message === 'MISSING_VALUE') {
    return {
      title: 'Code insee',
      // eslint-disable-next-line max-len
      message: 'Veuillez saisir de nouveau le code postal ou la ville pour recalculer le code INSEE.',
    }
  }
  if (field === 'valeurDuBien' && message === 'BELOW_MINIMUM') {
    return {
      title: 'Patrimoine',
      message: 'Ce bien ne pourra pas être pris en garantie. Sa valeur doit être a minima de 60 000 €.',
    }
  }

  return null
}

export function getPatrimoinesErrorMessage({ message }: ValidationFieldError): ErrorMessage | null {
  if (message === 'BIEN_REQUIRED') {
    return {
      title: 'Information requise',
      message: 'Veuillez renseigner a minima un bien immobilier.',
    }
  }
  if (message === 'GARANTIE_REQUIRED') {
    return {
      title: 'Patrimoine',
      message: 'Veuillez renseigner au minima un bien immobilier à prendre en garantie.',
    }
  }
  if (message === 'TOO_MANY') {
    return {
      title: 'Patrimoine',
      message: 'Nous n\'intervenons pas sur les clients propriétaires de plus de 10 biens locatifs (1 immeuble détenu à 100 % par l\'emprunteur = 1 bien)',
    }
  }
  return null
}
