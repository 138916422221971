import React, { useContext } from 'react'
import styles from './ToPrintDocument.module.css'
import { FormStateContext } from '../../Contexts/FormStateContext'
import { EntryFormStateName } from '../../FormState/EntryFormState'

/**
 * Contenu de dossier à imprimer ou à télécharger
 */

export function ToPrintAnalyseComptes() {
  const formState = useContext(FormStateContext)
  const { value: bank } = formState.getEntryFormState(EntryFormStateName.SITUATION_BANCAIRE)

  return (
    <div>
      <h1 className={styles.printDocument__headband}>
        <div className={styles.printDocument__headband__text}>
          ANALYSE DES COMPTES
        </div>
      </h1>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Nombre de comptes courants</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__cell}>{bank.situationBancaire.numberOfComptes} </p>
          </div>
        </div>
      </div>
      <div className={styles.printDocument__subtitle}>
        <h1>Nombre de rejets</h1>
        <hr />
      </div>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Prêts conso</p>
          <p className={styles.printDocument__cell}>Prêt immo / RAC hypothécaire</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__cell}>{bank.situationBancaire.numberOfRejetsConso}</p>
            <p className={styles.printDocument__cell}>{bank.situationBancaire.numberOfRejetsImmo}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
