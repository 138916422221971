import React, { useContext } from 'react'
import styles from './ToPrintDocument.module.css'
import { FormStateContext } from '../../Contexts/FormStateContext'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { REGIME_PROPRIETE_MAP } from '../../../model/patrimoine/RegimePropriete'

/**
 * Contenu de dossier à imprimer ou à télécharger
 */

function PrintablePatrimoine(patrimoine) {
  return (
    <>
      <div className={styles.printDocument__subtitle}>
        <div>
          <span className={styles.printDocument__cell}>Bien immobilier {patrimoine.position}</span>
          <span className={styles.printDocument__column__inside__right}> {patrimoine.bien.isGarantie ? 'Pris en Garantie' : 'Non pris en Garantie'}</span>
          <hr />
        </div>
      </div>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Usage du bien</p>
          <p className={styles.printDocument__cell}>Nature du bien</p>
          <p className={styles.printDocument__cell}>Nature de la propriété</p>
          <p className={styles.printDocument__cell}>Complément</p>
          <p className={styles.printDocument__cell}>N°</p>
          <p className={styles.printDocument__cell}>Voie</p>
          <p className={styles.printDocument__cell}>Lieu-dit</p>
          <p className={styles.printDocument__cell}>Code postal / Ville</p>
          <p className={styles.printDocument__cell}>Code Insee</p>
          <p className={styles.printDocument__cell}>Prix d'acquisition (€)</p>
          <p className={styles.printDocument__cell}>Valeur du bien (€)</p>

        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__cell}>{patrimoine.bien.usage}</p>
            <p className={styles.printDocument__cell}>{patrimoine.bien.nature}</p>
            <p className={styles.printDocument__cell}>{REGIME_PROPRIETE_MAP.get(patrimoine.regimePropriete)}</p>
            <p className={styles.printDocument__cell}>{patrimoine.bien.complement}</p>
            <p className={styles.printDocument__cell}>{patrimoine.bien.numero}</p>
            <p className={styles.printDocument__cell}>{patrimoine.bien.voie}</p>
            <p className={styles.printDocument__cell}>{patrimoine.bien.lieuDit}</p>
            <p className={styles.printDocument__cell}>{patrimoine.bien.codePostalVille}</p>
            <p className={styles.printDocument__cell}>{patrimoine.bien.codeInsee}</p>
            <p className={styles.printDocument__cell}>{patrimoine.bien.prixInitial}</p>
            <p className={styles.printDocument__cell}>{patrimoine.bien.valeur}</p>
          </div>
        </div>
      </div>
    </>
  )
}


export function ToPrintPatrimoine() {
  const formState = useContext(FormStateContext)
  const { value: patrimoineValue } = formState.getEntryFormState(EntryFormStateName.PATRIMOINE)

  const patrimoines = patrimoineValue.patrimoines
  return (
    <div>
      <h1 className={styles.printDocument__headband}>
        <div className={styles.printDocument__headband__text}>
          PATRIMOINE
        </div>
      </h1>
      {patrimoines.list.map(patrimoine => PrintablePatrimoine(patrimoine))}
    </div>
  )
}
