import { fetch as coreCommonFrontentFetch, refreshTokenAndRefetch } from '@core/common-frontend'

const isUnauthenticatedOrForbiddenResponse = response => {
  const errorCode = response.errors ? response.errors[0].extensions.code : undefined
  return ['UNAUTHENTICATED', 'FORBIDDEN'].includes(errorCode)
}

/**
 * fetch http et rafraichis le token d'authentification si besoin, spécifique aux requetes graphql
 * @param {string} url the url of the graphql server
 * @param {*} options request options
 * @param fetch fonction fetch à utiliser
 * @returns
 */
export const fetchWithRefresh = async (url, options, fetch = coreCommonFrontentFetch) => {
  const initialResponse = await fetch(url, options)
  const initialResponseJson = await initialResponse.json()

  // dans le cas d'un appel par batch, initialResponse est un Array
  const atLeastOneUnauthenticated = initialResponseJson instanceof Array ?
    initialResponseJson.some(isUnauthenticatedOrForbiddenResponse) :
    isUnauthenticatedOrForbiddenResponse(initialResponseJson)

  if (atLeastOneUnauthenticated) {
    return refreshTokenAndRefetch(url, options, fetch)
  }

  // Si il n'y avait pas d'erreur dans la requête initiale, on doit reconstruire le resultat
  return {
    ok: true,
    status: 200,
    text: () => new Promise((resolve, reject) => {
      resolve(JSON.stringify(initialResponseJson))
    }),
  }
}

export const buildFetchWithRefresh = (fetch = coreCommonFrontentFetch) => (url, options) => fetchWithRefresh(url, options, fetch)
