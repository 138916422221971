import { DATE_DD_MM_YYYY_FR, isValidDate } from '@core/common'
import { isEqual } from 'lodash'

import { ASSURANCE_FORMULES_OPTIONS, AssuranceOption, AssuranceType } from './const'
import { ActiviteFormUI } from '../activite/ActiviteFormUI'

export interface AssuranceFormPropsUI {
  quotite?: number
  naissanceDate?: string
  activities?: ActiviteFormUI[]
  type?: AssuranceType
  formule?: AssuranceOption
}

export enum CodeCouverture {
  DC_PTIA_ITT = 'DC_PTIA_ITT',
  DC_PTIA_ITT_PE = 'DC_PTIA_ITT_PE'
}

export class AssuranceFormUI {
  public readonly props

  constructor(props?: AssuranceFormPropsUI) {
    this.props = props || { quotite: 0 }
    if (!this.props.quotite) {
      this.props.quotite = 0
    }
  }

  private static isTypeSansAssurance(type: string): boolean {
    return type === AssuranceType.SANS_ASSURANCE
  }

  public hasAssurance(): boolean {
    return this.props.type !== undefined && !AssuranceFormUI.isTypeSansAssurance(this.props.type!)
  }

  public hasType(type: string): boolean {
    return this.props.type === type
  }

  public getSelectedFormule() {
    return this.props.formule ? ASSURANCE_FORMULES_OPTIONS.find(({ value }) => value === this.props.formule) : ''
  }

  public hasFormule(formule: string): boolean {
    return this.props.formule === formule
  }

  public getQuotite(): string {
    const quotite = this.props.quotite
    return quotite === 0 || quotite === false ? quotite : quotite || ''
  }

  public hasNaissanceDate(): boolean {
    return !!this?.props.naissanceDate
  }

  public getNaissanceDate(): string | undefined {
    return this.props.naissanceDate && isValidDate(this.props.naissanceDate, DATE_DD_MM_YYYY_FR) ? this.props.naissanceDate : ''
  }

  public update(newValue: AssuranceFormPropsUI): void {
    let computedValue = { ...this.props, ...newValue } as AssuranceFormPropsUI
    if (computedValue.type && AssuranceFormUI.isTypeSansAssurance(computedValue.type)) {
      computedValue = { ...computedValue, formule: undefined, quotite: 0 }
    }
    this.props.formule = computedValue.formule || this.props.formule
    this.props.type = computedValue.type || this.props.type
    this.props.quotite = computedValue.quotite || this.props.quotite
    this.props.naissanceDate = computedValue.naissanceDate || this.props.naissanceDate
    this.props.activities = computedValue.activities || this.props.activities
  }

  public updateIfDifferentDate(newValue: AssuranceFormPropsUI): void {
    if (!isEqual(newValue.naissanceDate, this.props.naissanceDate)) {
      this.update(newValue)
    }
  }

  public updateValidActivityType(newValue: AssuranceFormPropsUI): void {
    if (!isEqual(newValue.activities, this.props.activities)) {
      this.update(newValue)
    }
  }
}
