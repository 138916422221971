import React from 'react'

import { Icon } from '@mmb/ui-components'

import styles from './Pin.module.css'

export function Pin(props) {
  return (
    <Icon
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path className={styles.pin__stroke1} d="M17.16,10.23l-2.9,4.93c1.93,2.9.05,6.09.05,6.09l-11-6.34s1.83-3.23,5.29-3l2.83-5" />
        <path className={styles.pin__stroke2} d="M18.64,10.57l1.64-2.85a.47.47,0,0,0,0-.35.42.42,0,0,0-.22-.27L12.69,2.81a.48.48,0,0,0-.35-.05.49.49,0,0,0-.28.21L10.42,5.82a.49.49,0,0,0-.06.17.55.55,0,0,0,0,.18.28.28,0,0,0,.08.15.49.49,0,0,0,.13.12L18,10.72a.45.45,0,0,0,.34,0A.5.5,0,0,0,18.64,10.57Z" />
        <path className={styles.pin__stroke2} d="M8.85,18.1,7.18,21" />
      </svg>
    </Icon>
  )
}
