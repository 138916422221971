import { gql, useMutation } from '@core/graphql'
import { toast } from '@core/ui'

const RESEND_EMAIL_ACTIVATION_PERSONNE = gql`
  mutation ($idPersonne: String!) {
    partenaire_resendEmailActivationPersonne(idPersonne: $idPersonne)
  }
`
export const useResendEmailActivationPersonne = event => {
  const endMutationAction = toastAction => {
    if (event) event()
    toastAction()
  }
  const [mutation] = useMutation(RESEND_EMAIL_ACTIVATION_PERSONNE, {
    onCompleted: () => endMutationAction(() => toast({ message: 'Un email a été envoyé à l\'utilisateur.', type: 'SUCCESS' })),
    onError: () => endMutationAction(() => toast({ message: 'L\'envoi de l\'email a échoué.', type: 'ERROR' })),
  })

  return idPersonne => mutation({ variables: { idPersonne } })
}
