import { AssuranceTypeUI, SituationAdhesionUI, StatutAssuranceGroupDCS } from './assurance/AssuranceUI'

export class AssuranceSecuredUI {
  private isEnAttente: boolean

  constructor(
    private isAssuranceDeclarableVar: boolean,
    private type?: AssuranceTypeUI | null,
    private statut?: StatutAssuranceGroupDCS | null,
    private situationAdhesion?: SituationAdhesionUI | null,
    private numeroAdhesion?: string | null,
    private isDossierSecured: boolean = false,
    private hasValidAge: boolean = false) {
    this.isEnAttente = !this.type && this.situationAdhesion === SituationAdhesionUI.NON_DEMARREE
  }

  public isEligibleToAssuranceGroupe() : boolean {
    return this.isDossierSecured && this.hasValidAge
  }

  public isAssuranceParcoursFirstStartable(): boolean {
    return this.isEligibleToAssuranceGroupe() && this.isAssuranceDeclarableVar && this.isEnAttente && !this.numeroAdhesion
  }

  public shouldShowParcoursStartable(): boolean {
    return this.isAssuranceParcoursFirstStartable() || this.isAssuranceParcoursRestartable()
  }

  public isAssuranceParcoursRestartable():boolean {
    return this.statut === StatutAssuranceGroupDCS.SANS_SUITE
  }

  public shouldShowIneligible(): boolean {
    return this.isDossierSecured && !this.hasValidAge && this.isAssuranceDeclarableVar && this.isEnAttente
  }

  public shouldShowPersonne(): boolean {
    return !!this.type || this.isAssuranceDeclarableVar || this.shouldShowNoAssuranceInformation()
  }

  public shouldShowAssuranceNotDeclarable(): boolean {
    return !this.type && !this.isAssuranceDeclarableVar && this.isDossierSecured
  }

  public shouldShowAssuranceType(): boolean {
    return !!this.type
  }

  public shouldShowAssuranceDCS(): boolean {
    return this.type === AssuranceTypeUI.ASSURANCE_GROUP_DCS
  }

  public shouldShowSansAssurance(): boolean {
    return this.type === AssuranceTypeUI.SANS_ASSURANCE
  }

  public shouldShowNoAssuranceInformation(): boolean {
    return !this.type && !this.isDossierSecured && !this.shouldShowParcoursStartable()
  }
}
