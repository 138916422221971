import React from 'react'

import { DetailLayout } from '../../Detail/DetailLayout'

import styles from './HistoriquePieces.module.css'

import { useGetTransferts } from '../Liasse.hooks'
import { TransfertUI } from '../../../model'
import { HistoriqueTransfert } from './Transfert'

export function HistoriquePieces() {
  const { id, transfertsUI, loading } = useGetTransferts()
  return loading ? null : (
    <DetailLayout
      id={id}
      title="Historique des envois"
      className={styles.historiquePieces__page}
    >
      <div aria-label="Historique des envois">
        {transfertsUI?.getTransferts().map((transfert: TransfertUI, index: number) => (
          <HistoriqueTransfert
            key={`Transfert ${index + 1}`}
            aria-label="Transfert Historique"
            transfert={transfert}
            idx={index}
            getDownloadDocumentURL={documentId => transfertsUI.getDownloadDocumentURL((window as any).GATSBY_API_URL, documentId)}
          />
        ))}
      </div>
    </DetailLayout>
  )
}
