export const VALIDATION_ERRORS_MESSAGES = {
  UNSECURED_DIVORCE_CONSENT: {
    title: 'Situation familiale',
    message: 'Nous pourrons intervenir après le prononcé du divorce.',
  },
  DIVORCE_NON_CONSENT: {
    title: 'Situation familiale',
    message: 'Nous pourrons intervenir après le prononcé du divorce.',
  },
  NBR_CHILDREN_EXCEEDED: {
    message: 'le nombre d\'enfants maximum est de 15.',
  },
}
