import React, { useContext } from 'react'
import { bool, func } from 'prop-types'

import { Button, Card, Cross, Warning } from '@mmb/ui-components'

import { modalContext } from '@core/partenaire-ui'
import styles from './ValidationDossierModal.module.css'

ValidationDossierModal.propTypes = {
  validateDossier: func,
  loading: bool,
}

ValidationDossierModal.defaultProps = {
  validateDossier: () => {},
  loading: false,
}

export function ValidationDossierModal({ validateDossier, loading }) {
  const { setOpen }: any = useContext(modalContext)

  const onValidate = async () => {
    await validateDossier()
    setOpen(false)
  }

  return (
    <Card className={styles.validationDossierModal__card}>
      <header className={styles.validationDossierModal__header}>
        <h3 className={styles.validationDossierModal__title}>
          Validation de la simulation
        </h3>
        <Warning className={styles.validationDossierModal__iconWarning} />
      </header>

      <button
        onClick={() => setOpen(false)}
        aria-label="Fermer"
        className={styles.validationDossierModal__close}
      >
        <Cross />
      </button>

      <div className={styles.validationDossierModal__form}>
        <p className={styles.validationDossierModal__warningLine}>Assurez-vous d'avoir actualisé la simulation.</p>
        <p className={styles.validationDossierModal__warningLine}>
          Une fois validé, vous ne pourrez plus modifier le plan de financement.
        </p>

        <div className={styles.validationDossierModal__buttons}>
          <Button
            type="button"
            outline
            className={styles.validationDossierModal__cancel}
            onClick={() => setOpen(false)}
          >
            Annuler
          </Button>
          <Button
            type="button"
            onClick={onValidate}
            loading={loading}
            disabled={loading}
          >
            Valider
          </Button>
        </div>
      </div>
    </Card>
  )
}
