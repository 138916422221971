import React from 'react'
import { string } from 'prop-types'

import { Card } from '@mmb/ui-components'
import { FormHeader, Separator, Tooltip } from '@core/partenaire-ui'

import { Revenus, RevenusProvider } from './Revenus'
import { Charges, ChargesProvider } from './Charges'

import { ColumnName } from '../ColumnName'
import { EntryFormStateName } from '../FormState/EntryFormState'

import styles from './SituationFinanciere.module.css'

SituationFinanciere.propTypes = {
  className: string,
}

SituationFinanciere.defaultProps = {
  className: '',
}

export function SituationFinanciere({ className }) {
  return (
    <Card className={className}>
      <FormHeader>Situation financière</FormHeader>
      <div className={styles.situationFinanciere__titleTooltipWrapper}>
        <h3 id={EntryFormStateName.REVENU} className={styles.situationFinanciere__header}>Revenus</h3>
        <Tooltip
          wrapperClassName={styles.situationFinanciere__tooltip}
          title="Revenus"
          content={(
            <div>
              <p className={styles.situationFinanciere__tooltipText}>Les revenus retenus :</p>
              <ul>
                <li>
                  <p className={styles.situationFinanciere__tooltipText}>
                    <strong>Avant : </strong>
                    tous revenus professionnels et fonciers avec les justificatifs correspondants ou visibles sur les relevés de comptes.
                  </p>
                </li>
                <li>
                  <p className={styles.situationFinanciere__tooltipText}>
                    <strong>Après : </strong>revenus justifiés et fiscalement déclarés.
                  </p>
                </li>
              </ul>
              <p className={styles.situationFinanciere__tooltipText}>
                Les allocations familiales seront plafonnées automatiquement pour le calcul de l'endettement après.
              </p>
            </div>)}
        />
      </div>
      <Separator />
      <ColumnName />
      <RevenusProvider>
        <Revenus />
      </RevenusProvider>
      <div className={styles.situationFinanciere__titleTooltipWrapper}>
        <h3 id={EntryFormStateName.CHARGE} className={styles.situationFinanciere__header}>Charges</h3>
        <Tooltip
          wrapperClassName={styles.situationFinanciere__tooltip}
          title="Charges"
            // eslint-disable-next-line max-len
          content={(
            <p className={styles.situationFinanciere__tooltipText}>
              Les charges récurrentes et expliquées sont déduites automatiquement des revenus pour le calcul de l'endettement après, excepté le loyer de la RP.
            </p>)}
        />
      </div>
      <Separator />
      <ColumnName />
      <ChargesProvider>
        <Charges />
      </ChargesProvider>
    </Card>
  )
}
