import React from 'react'

import { Section, TextRow } from '@mmb/ui-components'

import styles from './DetailExpertisePlaceholder.module.css'

export function DetailExpertisePlaceholder() {
  return (
    <Section
      title="Valeur immobilière"
      className={styles.detailExpertisePlaceholder}
    >
      <div className={styles.detailExpertisePlaceholder__content} aria-label="Chargement Expertise immobilière">
        <div className={styles.detailExpertisePlaceholder__cell} aria-label="type">
          <div className={styles.detailExpertisePlaceholder__head}>Type</div>
          <div className={styles.detailExpertisePlaceholder__value}>
            <TextRow width="10rem" className={styles.identitePlaceholder__toggle} />
          </div>
        </div>

        <div className={styles.detailExpertisePlaceholder__cell} aria-label="date de lancement">
          <div className={styles.detailExpertisePlaceholder__head}>Date de lancement</div>
          <div className={styles.detailExpertisePlaceholder__value}>
            <TextRow width="5rem" className={styles.identitePlaceholder__toggle} />
          </div>
        </div>

        <div className={styles.detailExpertisePlaceholder__cell} aria-label="montant estimé">
          <div className={styles.detailExpertisePlaceholder__head}>Montant estimé</div>
          <div>
            <TextRow width="5rem" className={styles.identitePlaceholder__toggle} />
          </div>
        </div>
        <div className={styles.detailExpertisePlaceholder__cell} aria-label="RH">
          <div className={styles.detailExpertisePlaceholder__head}>RH</div>
          <div className={styles.detailExpertisePlaceholder__value}>
            <TextRow width="3rem" className={styles.identitePlaceholder__toggle} />
          </div>
        </div>
      </div>
    </Section>
  )
}
