import React, { FunctionComponent } from 'react'

import { AssuranceUnsecured } from './AssuranceUnsecured'
import { AssuranceSecured } from './AssuranceSecured'

import { useAssurance } from './Assurance.hooks'

import styles from './Assurance.module.css'


export const Assurance: FunctionComponent<{id: string, idPersonne: string, isCoEmprunteur?: boolean, isSecured: boolean }> =
({ id, idPersonne, isCoEmprunteur, isSecured }) => {
  const { assuranceUI, isLoadingDCS } = useAssurance(id, idPersonne, isSecured)
  const ariaLabel = `${isCoEmprunteur ? 'co-emprunteur' : 'emprunteur'}.assurance`
  return assuranceUI ? (
    <div className={styles.assurance} aria-label={ariaLabel}>
      {assuranceUI.isAssuranceGroupDCU() && <AssuranceUnsecured assuranceUI={assuranceUI} />}
      {!assuranceUI.isAssuranceGroupDCU() && <AssuranceSecured isAwaiting={isLoadingDCS} assuranceUI={assuranceUI} />}
    </div>
  ) : null
}

Assurance.defaultProps = {
  isCoEmprunteur: false,
}
