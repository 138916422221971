import React from 'react'
import ReactModal from 'react-modal'
import cn from 'classnames'
import { bool, func, node, string } from 'prop-types'

import { APP_ELEMENT_ID } from '../../utils/const'

import styles from './Modal.module.css'

Modal.propTypes = {
  className: string,
  label: string,
  children: node,
  onClose: func,
  isOpen: bool,
}

Modal.defaultProps = {
  className: '',
  label: '',
  children: null,
  isOpen: false,
  onClose: () => {},
}

ReactModal.setAppElement(APP_ELEMENT_ID)

export function Modal({ children, isOpen, label, onClose, className, ...props }) {
  return (
    <ReactModal
      className={cn(styles.modal, className)}
      overlayClassName={styles.modal__overlay}
      isOpen={isOpen}
      onRequestClose={onClose}
      {...props}
      contentLabel={label}
    >
      {children}
    </ReactModal>
  )
}
