import { AssuranceType } from '../model'
import { CodeCouverture } from '../model/assurance/AssuranceFormUI'

export const CODE_COUVERTURE_MAP = new Map<CodeCouverture | undefined, string>([
  [CodeCouverture.DC_PTIA_ITT, 'Décès, PTIA, ITT, perte d\'emploi'],
  [CodeCouverture.DC_PTIA_ITT_PE, 'Décès, PTIA, ITT'],
])

export const ASSURANCE_TYPE_MAP = new Map<AssuranceType | undefined, string>([
  [AssuranceType.ASSURANCE_GROUP, 'Assurance Groupe'],
  [AssuranceType.SANS_ASSURANCE, 'Sans assurance'],
])
