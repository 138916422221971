import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import { Taeg } from '@partenaire/frontend-ui'

import styles from '../DetailPlanFinancement.module.css'
import { PlanFinancementProps } from '../../../model/DetailPlanFinancement.model'

export const MainValues: FunctionComponent<PlanFinancementProps> = ({ planFinancement }) => {
  const defaultValue = '-'
  const { duree, taux, taeg, usure } = planFinancement
  return (
    <>
      <div className={classNames(styles.planFinancement__cell, styles.planFinancement__taux)}>
        <p className={styles.planFinancement__head}>Taux</p>
        {taux &&
        (
        <p className={classNames({ [styles.planFinancement__value]: taux })}>
          {taux ? `${taux} %` : defaultValue}
        </p>)}
      </div>
      <div className={classNames(styles.planFinancement__cell, styles.planFinancement__taeg)}>
        {usure && (<p className={styles.planFinancement__head}><span>TAEG*</span><span>({`${usure} %`})</span></p>)}
        <div className={classNames({ [styles.planFinancement__value]: taeg })}>
          {taeg && usure ? <Taeg value={taeg} compare={usure} /> : defaultValue}
        </div>
      </div>
      <div className={classNames(styles.planFinancement__cell, styles.planFinancement__duree)}>
        <p className={styles.planFinancement__head}>Durée</p>
        {duree &&
        (
        <p className={classNames({ [styles.planFinancement__value]: duree })}>
          {duree ? `${duree} mois` : defaultValue}
        </p>)}
      </div>

    </>
  )
}
