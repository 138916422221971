/* eslint-disable import/no-duplicates */
import { fr } from 'date-fns/locale'
import { formatWithOptions, parseISO, compareAsc } from 'date-fns/fp'
import { addYears } from 'date-fns'

export const DATE_DD_MM_YYYY_FR = 'dd/MM/yyyy'

const parseDate = date => typeof date === 'string' ? parseISO(date) : date

export const compareDates = (a, b) => compareAsc(parseDate(a), parseDate(b))

const formatDate = format => date => {
  if (!date) return ''
  const parsedDate = parseDate(date)
  return formatWithOptions({ locale: fr }, format)(parsedDate)
}

export const formatFullDate = formatDate('dd MMMM yyyy')
export const formatShortDate = formatDate('dd MMMM')
export const formatYearDate = formatDate('yyyy')
export const formatInputDate = formatDate('yyyy-MM-dd')
export const formatMonthDate = formatDate('yyyy-MM')
export const formatCondenseDate = formatDate(DATE_DD_MM_YYYY_FR)
export const formatCondenseDateWithHour = formatDate('dd/MM/yyyy HH:mm')

export const calcYearsAgo = substractYears => (today = new Date()) => {
  const current = parseDate(today)
  const result = addYears(current, substractYears * -1)
  return formatInputDate(result)
}

export function getAgeFromBirthDate(stringifiedBirthDate = '', fromDate = new Date()) {
  const birthDate = new Date(stringifiedBirthDate)
  const numberOfYears = fromDate.getFullYear() - birthDate.getFullYear()
  const numberOfMonths = fromDate.getMonth() - birthDate.getMonth()
  const isBeforeBirthDay = fromDate.getDate() < birthDate.getDate()

  if (numberOfMonths < 0 || (numberOfMonths === 0 && isBeforeBirthDay)) {
    return numberOfYears - 1
  }
  return numberOfYears
}

export function isCloseToTodaysMonth(stringifiedBirthDate = '', fromDate = new Date()) {
  const birthDate = new Date(stringifiedBirthDate)
  const numberOfMonthsDifference = birthDate.getMonth() - fromDate.getMonth()

  return numberOfMonthsDifference <= 2
}

export function getAgeFromYearAndMonth(yearAndMonth, fromDate = new Date()) {
  return getAgeFromBirthDate(`${yearAndMonth}-01`, fromDate)
}

export function getTimeFromMonth(yearAndMonth) {
  return new Date(`${yearAndMonth}-01`).getTime()
}

export function parseDateISO(date) {
  if (!date || date === '') {
    return undefined
  }
  return parseISO(date)
}

export function getDureeRestante(nbMoisTotal) {
  const nbAnnee = Math.trunc(nbMoisTotal / 12)
  const nbMois = nbMoisTotal % 12

  let dureeDisplay = ''

  if (nbAnnee > 0) {
    dureeDisplay += `${nbAnnee} an`
  }
  if (nbAnnee > 1) {
    dureeDisplay += 's'
  }
  if (nbAnnee > 0 && nbMois > 0) {
    dureeDisplay += ' et '
  }
  if (nbMois > 0 || (nbMois === 0 && nbAnnee === 0)) {
    dureeDisplay += `${nbMois} mois`
  }

  return dureeDisplay
}
