const ALLOCATIONS = [
  {
    label: 'Allocations / Logement',
    value: 'ALLOCATIONS_LOGEMENT',
  },
  {
    label: 'Allocations / PAJE',
    value: 'ALLOCATIONS_PAJE',
  },
  {
    label: 'Allocations / Adultes handicapés (AAH)',
    value: 'ALLOCATIONS_ADULTES_HANDICAPES_AAH',
  },
  {
    label: 'Allocations / RSA',
    value: 'ALLOCATIONS_RSA',
  },
  {
    label: 'Allocations / Autre',
    value: 'ALLOCATIONS_AUTRE',
  },
]

const PENSION = [
  {
    label: 'Pension alimentaire',
    value: 'PENSION_ALIMENTAIRE',
  },
  {
    label: 'Pension de reversion',
    value: 'PENSION_DE_REVERSION',
  },
  {
    label: 'Prestation compensatoire',
    value: 'PRESTATION_COMPENSATOIRE',
  },
  {
    label: 'Pension d\'invalidité',
    value: 'PENSION_D_INVALIDITE',
  },
  {
    label: 'Rente accident de travail',
    value: 'RENTE_ACCIDENT_DE_TRAVAIL',
  },
  {
    label: 'Pension-Rente / Autre',
    value: 'PENSION_AUTRE',
  },
]
export const REVENUS_OPTIONS = [{
  label: 'Salaire',
  value: 'SALAIRE',
},
{
  label: 'Retraite',
  value: 'RETRAITE',
},
{
  label: 'BIC/BNC',
  value: 'BIC_BNC',
},
{
  label: 'Revenu foncier avec SCI',
  value: 'LOYER_AVEC_SCI',
},
{
  label: 'Revenu foncier sans SCI',
  value: 'LOYER_SANS_SCI',
},
{
  label: 'Indemnités pôle emploi',
  value: 'INDEMNITES_POLE_EMPLOI',
},
{
  label: 'Autre',
  value: 'AUTRE',
},
...ALLOCATIONS,
...PENSION,
]
