import React from 'react'
import classnames from 'classnames'
import { bool, func, shape, string, number, arrayOf } from 'prop-types'

import { Input, ToggleButton } from '@mmb/ui-components'
import { Select, Separator } from '@core/partenaire-ui'

import { ActiviteHeader } from './ActiviteHeader'

import {
  ACTIVITES_OPTIONS, CATEGORIES_ACTIVITES_OPTIONS, CATEGORIES_SOCIO_OPTIONS, TYPE_CONTRAT_OPTIONS, hasErrorForName as hasError, clearEmpty,
} from '../../../../utils'

import styles from './ActiviteForm.module.css'
import { DATE_MM_YYYY } from '../../../../model/format'

ActiviteForm.propTypes = {
  suffixes: shape({
    label: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  activite: shape({
    id: string,
    type: string,
    typeContrat: string,
    catSocio: string,
    profession: string,
    debut: string,
    isPrincipale: bool,
  }),
  setActivite: func,
  removeActivite: func,
  className: string,
  index: number,
  loading: bool,
  errors: arrayOf(
    shape({
      field: string.isRequired,
      message: string,
    }),
  ),
  showErrors: bool,
}

ActiviteForm.defaultProps = {
  activite: {
    id: '',
    type: '',
    typeContrat: '',
    catSocio: '',
    profession: '',
    debut: '',
  },
  setActivite: () => {},
  removeActivite: () => {},
  className: null,
  index: 0,
  loading: false,
  errors: [],
  showErrors: false,
}

const getCategorieSocioByValue = categorieSocio => CATEGORIES_SOCIO_OPTIONS.find(({ value }) => categorieSocio === value)
const getTypeContratByValue = typeContrat => TYPE_CONTRAT_OPTIONS.find(({ value }) => typeContrat === value)
const getTypeByValue = type => ACTIVITES_OPTIONS.find(({ value }) => type === value)

export function ActiviteForm({ index, suffixes, activite, setActivite, removeActivite, className, errors, showErrors }) {
  const hasErrorForName = name => hasError(name, errors, showErrors)
  return (
    <div>
      <ActiviteHeader activite={activite} suffixeLabel={suffixes.label} index={index} removeActivite={removeActivite} />
      <div className={className}>
        <div className={styles.activiteForm__radiosPrincipale} aria-label={`Categorie d'activité ${suffixes.label}`}>
          {CATEGORIES_ACTIVITES_OPTIONS.map(activiteCategorieOption => {
            const isPrincipale = ({ value }) => value === 'PRINCIPALE'
            const isChecked = activite.isPrincipale === isPrincipale(activiteCategorieOption)
            return (
              <ToggleButton
                {...activiteCategorieOption}
                name={`${suffixes.name}.isPrincipale.${index}`}
                className={classnames(styles.activiteForm__options, {
                  [styles.activiteForm__optionsChecked]: isChecked,
                })}
                labelClassName={styles.activiteForm__labelOptions}
                onChange={() => {
                  setActivite({ ...activite, isPrincipale: isPrincipale(activiteCategorieOption) }, index)
                }}
                hasError={hasErrorForName('isPrincipale')}
                checked={isChecked}
                key={`${index}-${activiteCategorieOption.value}-${suffixes.label}`}
                aria-label={`Categorie Activite - ${suffixes.name} - ${index} - ${activiteCategorieOption.label}`}
              />
            )
          })}
        </div>

        <Select
          hasError={hasErrorForName('type')}
          options={ACTIVITES_OPTIONS}
          onChange={event => setActivite({ ...activite, type: event?.value }, index)}
          name={`${suffixes.name}.type.${index}`}
          label={`Type d'activité ${suffixes.label} ${index}`}
          hideLabel
          value={getTypeByValue(activite.type)}
        />

        <Select
          hasError={hasErrorForName('catSocio')}
          options={CATEGORIES_SOCIO_OPTIONS}
          onChange={event => setActivite({ ...activite, catSocio: event?.value }, index)}
          name={`${suffixes.name}.catSocio.${index}`}
          label={`Catégorie socio-professionnelle ${suffixes.label} ${index}`}
          hideLabel
          value={getCategorieSocioByValue(activite.catSocio)}
        />

        <Input
          aria-invalid={hasErrorForName('profession')}
          hasError={hasErrorForName('profession')}
          className={styles.activiteForm__input}
          name={`${suffixes.name}.profession.${index}`}
          onChange={({ target }) => setActivite({ ...activite, profession: target.value }, index)}
          label={`Profession ${suffixes.label} ${index}`}
          hideLabel
          value={clearEmpty(activite?.profession)}
        />

        <Select
          hasError={hasErrorForName('typeContrat')}
          options={TYPE_CONTRAT_OPTIONS}
          onChange={({ value }) => setActivite({ ...activite, typeContrat: value }, index)}
          name={`${suffixes.name}.typeContrat.${index}`}
          label={`Type de contrat ${suffixes.label} ${index}`}
          hideLabel
          value={getTypeContratByValue(activite.typeContrat)}
        />

        <Input
          aria-invalid={hasErrorForName('debut')}
          hasError={hasErrorForName('debut')}
          className={styles.activiteForm__input}
          name={`${suffixes.name}.debut.${index}`}
          onChange={({ target }) => setActivite({ ...activite, debut: target.value }, index)}
          label={`Date de début de l'activité ${suffixes.label} ${index}`}
          hideLabel
          type="textmonth"
          placeholder={`${DATE_MM_YYYY.toLowerCase()}`}
          value={clearEmpty(activite?.debut)}
        />
        <Separator className={styles.activiteForm__separator} />
      </div>
    </div>
  )
}
