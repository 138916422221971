import React from 'react'
import classnames from 'classnames'

import { useSaisie } from '../Contexts/SaisieContext'

import styles from './ColumnName.module.css'

export function ColumnName() {
  const { saisieUI: { props: { hasCoEmprunteur } } } = useSaisie()


  return (
    <header className={classnames(styles.columnName, { [styles.situationPersonnelle__headerWithoutCoemp]: !hasCoEmprunteur })}>
      <p className={styles.columnName__title}>Emprunteur 1</p>
      {hasCoEmprunteur && <p className={styles.columnName__title}>Emprunteur 2</p>}
    </header>
  )
}
