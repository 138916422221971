import React from 'react'
import { shape, string } from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, StaticQuery } from 'gatsby'

import './Head.module.css'

PortailHead.propTypes = {
  data: shape({
    site: shape({
      siteMetadata: shape({
        title: string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

function PortailHead({ data }) {
  const description = 'Mon Espace Partenaire.'

  return (
    <Helmet
      title={data.site.siteMetadata.title}
      defaultTitle={data.site.siteMetadata.title}
      meta={[
        { name: 'description', content: description },
        { name: 'keywords', content: 'Espace partenaire' },
      ]}
    >
      <html lang="fr" />
      <link rel="apple-touch-icon" href="/icons/icon-144x144.png" />
      <script src="/env.gatsby.js" />
    </Helmet>
  )
}

export const Head = props => (
  <StaticQuery
    query={graphql`
      query HeadQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <PortailHead data={data} {...props} />
    )}
  />
)
