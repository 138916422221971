import { string } from 'prop-types'
import { useEffect } from 'react'
import { useNavigate } from '../Navigate/Navigate'

Redirect.propTypes = {
  to: string.isRequired,
}

export function Redirect({ to }) {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  })
  return null
}
