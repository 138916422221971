import React from 'react'

import 'normalize.css'
import '@mmb/ui-components/lib/main.css'
import { Router } from './src/components/Router'
import { Root } from './src/components/Router/Root'

export const shouldUpdateScroll = () => [0, 0]

export const wrapRootElement = Root

// eslint-disable-next-line object-curly-spacing
// eslint-disable-next-line react/jsx-filename-extension
export const wrapPageElement = ({ element, props }) => <Router />
