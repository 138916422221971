import { useContext } from 'react'
import { isBrowser } from '@core/common-frontend'
import get from 'lodash/get'
import { IzanamiFeatureContext } from './IzanamiFeatureContext'

const INACTIVE_FEATURE = { active: false }
const cleanPath = path => path.replace(/:/g, '.')
const getCleanedPathAsArray = path => (Array.isArray(path) ? path : [path]).map(cleanPath)

const isFeatureActive = (features, path) => get(features, path, INACTIVE_FEATURE).active
const isFeaturesActive = (features, path) => path.map(p => isFeatureActive(features, p)).every(p => Boolean(p))

const featurePrefix = () => isBrowser && `${window.GATSBY_PORTAL}.${window.GATSBY_DEPLOY_ENV}`

export function useIzanamiFeatureByName(featureName) {
  const { features, firstFetchPending } = useContext(IzanamiFeatureContext)
  const cleanedPath = getCleanedPathAsArray(`${featurePrefix()}.${featureName}`)
  const active = isFeaturesActive(features, cleanedPath)
  return { active, firstFetchPending }
}

export function useIzanamiConfigurationByName(configurationName) {
  const { configurations, firstFetchPending } = useContext(IzanamiFeatureContext)
  const cleanedPath = cleanPath(`${featurePrefix()}.${configurationName}`)
  const configuration = get(configurations, cleanedPath)
  return { configuration, firstFetchPending }
}
