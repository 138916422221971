import { gql, DO_NOT_CHECK_CACHE, useQuery } from '@core/graphql'
import { useAssurance } from '../../Assurances/Assurance/Assurance.hooks'

export const GET_DETAIL_ASSURANCES = gql`
query getDetailAssurance($id: String!) {
  partenaire_assurances(id: $id) {
    assuranceEmprunteur {
      id
    }
    assuranceCoEmprunteur {
      id
    }
  }
}
`
export function useDetailAssurance(id) {
  const { data = {}, loading: loadingDetailAssurances, error } = useQuery(GET_DETAIL_ASSURANCES, {
    ...DO_NOT_CHECK_CACHE,
    errorPolicy: 'all',
    variables: { id },
  })
  const { loading: loadingAssuranceEmprunteur, assuranceUI: assuranceEmprunteurUI } =
    useAssurance(id, data.partenaire_assurances?.assuranceEmprunteur?.id)

  const { loading: loadingAssuranceCoEmprunteur, assuranceUI: assuranceCoEmprunteurUI } =
    useAssurance(id, data.partenaire_assurances?.assuranceCoEmprunteur?.id)

  const loading = loadingDetailAssurances || loadingAssuranceEmprunteur || loadingAssuranceCoEmprunteur

  if (error) {
    return { error }
  }
  if (loading) {
    return { loading }
  }
  if (!data.partenaire_assurances) {
    return {}
  }
  return { assuranceEmprunteurUI, assuranceCoEmprunteurUI }
}
