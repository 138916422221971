import { gql } from '@core/graphql'

export const GET_PRETS_DOSSIER = gql`
query getPretsDossier($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    emprunteurs {
      id
      prets(dossierId: $id) {
        id
        repris
        position
        nature
        nomPreteur
        reference
        finalite
        debut
        fin
        taux
        regime
        montantCrd
        montantIra
        montantCapitalInitial
        sourceTaux
        sourcePreavis
        sourceIra
        sourceFin
        bienFinance
        preavis
        paliers {
          duree
          dateDebutPalier
          montantEcheance
        }
      }
    }
  }
}
`

export const CREATE_PRET_DOSSIER = gql`
mutation createPretDossier($id: ID!, $personneId: ID!, $pret: partenaire_PretInput!) {
  partenaire_createPret(id: $id, personneId: $personneId, pret: $pret){
    id
    repris
    position
    nature
    nomPreteur
    reference
    finalite
    debut
    fin
    taux
    regime
    montantCrd
    montantIra
    montantCapitalInitial
    sourceTaux
    sourcePreavis
    sourceIra
    sourceFin
    bienFinance
    preavis
    paliers {
      duree
      dateDebutPalier
      montantEcheance
    }
  }
}
`

export const UPDATE_PRET_DOSSIER = gql`
mutation updatePretDossier($id: ID!, $personneId: ID!, $pret: partenaire_PretInput!) {
  partenaire_updatePret(id: $id, personneId: $personneId, pret: $pret){
    id
    repris
    position
    nature
    nomPreteur
    reference
    finalite
    debut
    fin
    taux
    regime
    montantCrd
    montantIra
    montantCapitalInitial
    sourceTaux
    sourcePreavis
    sourceIra
    sourceFin
    bienFinance
    preavis
    paliers {
      duree
      dateDebutPalier
      montantEcheance
    }
  }
}
`

export const DELETE_PRET_DOSSIER = gql`
mutation deletePretDossier($id: ID!, $personneId: ID!, $pretId: ID!) {
  partenaire_deletePret(id: $id, personneId: $personneId, pretId: $pretId){
    id
  }
}
`
