import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { AssuranceUI } from '../../../../model/assurance/AssuranceUI'

import stylesDetail from './AssuranceDetail.module.css'

export const SansAssuranceDetail: FunctionComponent<{assuranceUI: AssuranceUI}> = ({ assuranceUI }) => !assuranceUI.props.sansAssuranceUI?.typeRefus ? null : (
  <div data-testid="sans-assurance-detail">
    <div className={stylesDetail.assuranceDetail__table}>
      <div className={stylesDetail.assuranceDetail__blue}>
        <div aria-label="Statut" className={stylesDetail.assuranceDetail__row}>
          <span>Refus</span>
          <span className={stylesDetail.assuranceDetail__content}>{assuranceUI.getLibelleStatut()}</span>
        </div>
      </div>
      <div aria-label="Date de statut" className={classNames(stylesDetail.assuranceDetail__white, stylesDetail.assuranceDetail__row)}>
        <span>Date de refus</span>
        <span className={stylesDetail.assuranceDetail__content}>{assuranceUI.getDateStatut()}</span>
      </div>
    </div>
  </div>
)
