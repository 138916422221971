import { PORTAIL_PARTENAIRE } from '@core/common'


export const STATUTS = [{
  label: 'En attente',
  value: 'EN_ATTENTE',
}, {
  label: 'En cours',
  value: 'EN_COURS',
}, {
  label: 'Confirmé',
  value: 'CONFIRME',
}]

export const SORT_BY_OPTIONS = [
  {
    value: 'nom',
    label: 'Nom',
  },
  {
    value: 'prenom',
    label: 'Prénom',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'statut',
    label: 'Statut',
  },
]

export const shouldHandleAgences = () => window.GATSBY_PORTAL === PORTAIL_PARTENAIRE
