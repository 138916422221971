import { searchContext } from '@core/partenaire-common'
import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Outils } from '../components'

export const OutilsRoute = props => {
  const searchState = useState('')
  const searchValue = {
    search: searchState,
  }

  return (
    <searchContext.Provider value={searchValue}>
      <Routes {...props}>
        <Route path="/" element={<Outils />} />
        <Route path=":rubriqueId" element={<Outils />} />
      </Routes>
    </searchContext.Provider>
  )
}

