/* eslint-disable react/require-default-props */
import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { ToggleButton } from '@mmb/ui-components'

import { CIVILITES_OPTIONS, hasErrorForName, noop } from '../../../../utils'

import styles from './Civilites.module.css'
import { LabelUI, ValidationFieldError } from '../../../../model'
import { CiviliteUI } from '../../../../model/identite/IdentiteUI'

interface CiviliteProps {
  suffixes: LabelUI
  value?: CiviliteUI
  setCivilite: (civlite: CiviliteUI) => void
  errors?: ValidationFieldError[]
  showErrors?: boolean
}

export const Civilite: FunctionComponent<CiviliteProps> = ({ suffixes, value, setCivilite, errors = [], showErrors = false }) => {
  const ariaLabel = `Civilité ${suffixes.name}`
  return (
    <div className={styles.civilites}>
      {CIVILITES_OPTIONS.map(civilite => {
        const isChecked = value === civilite.value
        const name = `${suffixes.name}.civilite.${civilite.value}`
        return (
          <ToggleButton
            {...civilite}
            name={name}
            hasError={hasErrorForName('civilite', errors, !!showErrors)}
            className={classnames(styles.civilites__options, {
              [styles.civilites__optionsChecked]: isChecked,
            })}
            labelClassName={styles.civilites__label}
            onClick={() => setCivilite(civilite.value as CiviliteUI)}
            onChange={noop}
            checked={isChecked}
            key={civilite.value}
            aria-label={`${ariaLabel} - ${civilite.label}`}
          />
        )
      })}
    </div>
  )
}
