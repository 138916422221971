/* eslint-disable no-console */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { node } from 'prop-types'
import { useLazyQuery } from '@core/graphql'
import { useSaisie } from './SaisieContext'
import { FormStateContext } from './FormStateContext'
import { GET_SIMULATION_DOSSIER } from './const'

export const SimulationContext = createContext({})

const initialValues = {
  status: 'NOT_STARTED',
  simulationErrors: [],
}

const diagnosticInitalValues = {
  hasPriority: false,
  taeg: null,
  ratioHypo: null,
  expertiseImmo: null,
  endettementAvant: null,
  endettementApres: null,
  resteAVivre: null,
}

const productInitalValues = {
  nom: null,
  gamme: null,
  regime: '',
  montantFinancement: null,
  montantApport: null,
  montantOperation: null,
  dureeFinancement: null,
  taux: null,
  tauxUsure: null,
  seuilImmo: null,
  echeance: null,
  variation: null,
  variationWithAssurance: null,
  detail: [],
}

export function useSimulation() {
  const context = useContext<any>(SimulationContext)
  if (context === undefined) {
    throw new Error('useSimulation must be used within a SimulationProvider')
  }
  return context
}

SimulationProvider.propTypes = {
  children: node,
}
SimulationProvider.defaultProps = {
  children: null,
}

export function SimulationProvider({ children }) {
  const { saisieUI: { props: { dossierId } } } = useSaisie()
  const formState = useContext(FormStateContext)
  const isFormDirty = formState.props.dirtySinceLastSubmit
  const [simulationState, setSimulationState] = useState(initialValues)
  const [simulationSubmitted, setSimulationSubmitted] = useState(false)
  const [isCleanFormSubmission, setIsCleanFormSubmission] = useState(false)
  const [simulationRunning, setSimulationRunning] = useState(false)
  const [showSimulation, setShowSimulation] = useState(false)
  const [diagnostic, setDiagnostic] = useState(diagnosticInitalValues)
  const [product, setProduct] = useState(productInitalValues)
  const [garbageTimeout, setGarbageTimeout] = useState<any>()
  const [assurances, setAssurances] = useState([])
  const [simulationRequestTimeout, setSimulationRequestTimeout] = useState<any>()
  const [isSimulationRequestTimedOut, setIsSimulationRequestTimedOut] = useState(false)
  const [isSimulationRequested, setIsSimulationRequested] = useState(false)

  const [getSimulationByDossierId, { error, called }] = useLazyQuery(
    GET_SIMULATION_DOSSIER, {
      // https://github.com/apollographql/apollo-client/issues/9137
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onError: e => {
        setShowSimulation(true)
        setLoading(false)
      },
      onCompleted: ({ partenaire_getSimulation: simulation }) => {
        setSimulationState({
          ...simulationState,
          status: simulation?.statut,
          simulationErrors: simulation?.errors || [] })
        setProduct({ ...product, ...(simulation?.produit || {}) })
        setDiagnostic({ ...diagnostic, ...(simulation?.diagnostic || {}) })
        const assurancesValues: any = assurances[0] !== simulation?.assurances[0] ? [...(simulation?.assurances || [])] : [...assurances]
        setShowSimulation(true)
        setLoading(false)
        setAssurances(assurancesValues)
      },
    },
  )
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const isLaunchableSimulation = simulationSubmitted && // une demande de simulation a été effectuée
      !formState.isLoading() && ( // les données sont chargées
      // des données ont été modifiées et on attend qu'elles soient soumises
      ((isFormDirty || isCleanFormSubmission) && formState.props.submitted) ||
      !isFormDirty || // aucune donnée n'a été mise à jour
      formState.hasErrors() // il existe des erreurs dans le formulaire
    )
    if (isLaunchableSimulation) {
      launchSimulation()
    } else if (isFormDirty) {
      setShowSimulation(false)
    }
  }, [isCleanFormSubmission, showSimulation, formState.props.submitted, isFormDirty, formState.isLoading()])

  useEffect(() => {
    if (simulationSubmitted) {
      formState.submit()
      if (!isFormDirty) {
        setIsCleanFormSubmission(true)
      }
    }
  }, [simulationSubmitted])


  const getSimulation = async () => getSimulationByDossierId({ variables: { id: dossierId } })
  const doLaunchSimulation = async () => {
    if (formState.hasErrors()) {
      // On attend 500ms pour simuler un temps d'attente lorsqu'il y a des erreurs de validation
      // Ainsi l'utilisateur est informé du traitement de ces données
      setShowSimulation(false)
      setTimeout(() => {
        formState.updateFormStateShowErrors(true)
        setLoading(false)
      }, 500)
    } else {
      setSimulationRunning(true)
      await getSimulation().catch(r => console.log('error:', r)).finally(() => {
        setSimulationRunning(false)
        deleteGarbage()
      })
    }
  }
  const launchSimulation = async () => {
    setSimulationSubmitted(false)
    setIsCleanFormSubmission(false)
    formState.submit()
    await doLaunchSimulation()
  }

  const createGarbage = async () => {
    const timeoutID = setTimeout(() => {
      if (loading && simulationSubmitted && !showSimulation && !simulationRunning) {
        launchSimulation()
      }
    }, 10000)
    setGarbageTimeout(timeoutID)
  }

  const deleteGarbage = () => {
    if (garbageTimeout) {
      clearTimeout(garbageTimeout)
    }
  }

  const submit = async () => {
    await createGarbage()
    setLoading(true)
    setShowSimulation(false)
    setSimulationSubmitted(true)
  }

  useEffect(() => {
    if (isSimulationRequested && (!isFormDirty || isSimulationRequestTimedOut)) {
      setIsSimulationRequested(false)
      setIsSimulationRequestTimedOut(false)
      clearTimeout(simulationRequestTimeout)
      submit()
    }
  }, [isSimulationRequested, isFormDirty, isSimulationRequestTimedOut])

  const requestSimulation = () => {
    setIsSimulationRequested(true)
    setSimulationRequestTimeout(setTimeout(() => { setIsSimulationRequestTimedOut(true) }, 3000))
  }

  return (
    <SimulationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={[
        { ...simulationState, showSimulation, loading, error, diagnostic, product, assurances, called, isSimulationRequested, isFormDirty },
        { getSimulation, requestSimulation },
      ]}
    >
      {children}
    </SimulationContext.Provider>
  )
}
