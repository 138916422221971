import { OptionUI } from '../OptionUI'
import { NaturePret, RegimePret } from './PretFormUI'

export const NATURE_PRET_OPTIONS: OptionUI[] = [
  new OptionUI('Prêt immobilier hypothécaire', NaturePret.PRET_IMMOBILIER_HYPOTHECAIRE),
  new OptionUI('Prêt immobilier non hypothécaire', NaturePret.PRET_IMMOBILIER_NON_HYPOTHECAIRE),
  new OptionUI('Prêt taux zéro hypothécaire', NaturePret.PRET_TAUX_ZERO_HYPOTHECAIRE),
  new OptionUI('Prêt taux zéro non hypothécaire', NaturePret.PRET_TAUX_ZERO_NON_HYPOTHECAIRE),
  new OptionUI('Prêt de restructuration hypothécaire', NaturePret.PRET_DE_RESTRUCTURATION_HYPOTHECAIRE),
  new OptionUI('Prêt de restructuration non hypothécaire', NaturePret.PRET_DE_RESTRUCTURATION_NON_HYPOTHECAIRE),
  new OptionUI('Location avec option d\'achat', NaturePret.LOCATION_AVEC_OPTION_D_ACHAT),
  new OptionUI('Prêt employeur', NaturePret.PRET_EMPLOYEUR),
  new OptionUI('Prêt patronal', NaturePret.PRET_PATRONAL),
  new OptionUI('Prêt personnel non hypothécaire', NaturePret.PRET_PERSONNEL_NON_HYPOTHECAIRE),
  new OptionUI('Prêt personnel hypothécaire', NaturePret.PRET_PERSONNEL_HYPOTHECAIRE),
  new OptionUI('Prêt travaux non hypothécaire', NaturePret.PRET_TRAVAUX_NON_HYPOTHECAIRE),
  new OptionUI('Prêt travaux hypothécaire', NaturePret.PRET_TRAVAUX_HYPOTHECAIRE),
  new OptionUI('Prêt voiture', NaturePret.PRET_VOITURE),
  new OptionUI('Crédit renouvelable', NaturePret.CREDIT_RENOUVELABLE),
  new OptionUI('Découvert', NaturePret.DECOUVERT),
]

export const REGIMES_PRET: OptionUI[] = [
  new OptionUI('CONSO', 'CONSO'),
  new OptionUI('IMMO', 'IMMO'),
]

export const getSelectedNaturePret = (natureValue?: NaturePret) => NATURE_PRET_OPTIONS.find(({ value }) => value === natureValue) || ''
export const getSelectedRegimePret = (regimeValue?: RegimePret) => REGIMES_PRET.find(({ value }) => value === regimeValue) || ''
