
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useNavigate } from '@core/routing'

import { ActionUI } from '../../../model'
import { useLiasse } from '../../Pieces/Liasse.hooks'

export function useActions() {
  const { liasseUI, loading } = useLiasse()
  const { id } = useParams()
  const navigate = useNavigate()
  const [actions, setActions] = useState<ActionUI[]>([])

  useEffect(() => {
    const currentActions: ActionUI[] = []
    if (!loading) {
      if (liasseUI?.props?.piecesUI?.hasPiecesManquantes()) {
        currentActions.push({
          title: 'Pièces Manquantes',
          label: 'Compléter',
          action: () => navigate(`/app/dossiers/${id}/pieces`),
        })
      }
    }
    setActions(currentActions)
  }, [liasseUI, id])
  return actions
}
