import React, { FunctionComponent } from 'react'
import { Placeholder } from '@mmb/ui-components'
import { useDocuments, useDocumentsFavoris, useGroupes } from './OutilsDocuments.hook'
import { Groupes, GroupesPlaceholder } from './groupes'
import { DocumentListe } from './liste'
import { Favoris } from './favoris'

interface OutilsDocumentsProps {
  rubriqueId: string
  withFavoris?: boolean
  withGroupe?: boolean
}

export const OutilsDocuments: FunctionComponent<OutilsDocumentsProps> = ({ rubriqueId, withGroupe, withFavoris }) => {
  const { loading: loadingFavoris, documents: documentsFavoris } = useDocumentsFavoris(!withFavoris, rubriqueId)
  const { loading: loadingGroupes, groupes } = useGroupes(!withGroupe, rubriqueId)
  const { loading: loadingDocuments, documents } = useDocuments(!!withGroupe, rubriqueId)
  const loading = loadingDocuments || loadingGroupes || loadingFavoris
  const customPlaceholder = <GroupesPlaceholder nbElements={withGroupe ? 3 : 1} />
  return (
    <div>
      <Placeholder loading={loading} customPlaceholder={customPlaceholder}>
        {withFavoris && <Favoris documents={documentsFavoris} />}
        {withGroupe && <Groupes groupes={groupes} idRubrique={rubriqueId} />}
        {!withGroupe && <DocumentListe documents={documents} />}
      </Placeholder>
    </div>
  )
}

OutilsDocuments.defaultProps = {
  withFavoris: false,
  withGroupe: false,
}
