import { SupportStateUI } from '../support'
import { ChargeFormUI, DEFAULT_CHARGE } from './ChargeFormUI'
import { ChargeListUI } from './ChargeListUI'

export class ChargesUI extends SupportStateUI {
  private handleStoreCharge: (personneId: string, charge: ChargeFormUI) => Promise<ChargeFormUI>

  constructor(
    public readonly emprunteursCharges: ChargeListUI,
    public readonly coEmprunteursCharges: ChargeListUI,
    public readonly hasCoEmprunteur: boolean = false) {
    super()
  }

  protected clone() {
    return new ChargesUI(
      this.emprunteursCharges.clone(),
      this.coEmprunteursCharges.clone(),
      this.hasCoEmprunteur,
    ).withUpdateState(this.setState).withHandleStoreCharge(this.handleStoreCharge)
  }

  public getTotalCharges(): number {
    return [...this.emprunteursCharges.displayableCharge(), ...this.coEmprunteursCharges.displayableCharge()]
      .reduce((total, charge) => total + Number(charge?.montant || 0), 0)
  }

  public delete(index: number, deleteOnEmprunteur: boolean): string|undefined {
    const id = this.selectChargeListUI(deleteOnEmprunteur).list[index]?.id
    this.selectChargeListUI(deleteOnEmprunteur).delete(index)
    this.refreshState()
    return id
  }

  public update(index: number, charge: any, updateOnEmprunteur: boolean): void {
    this.selectChargeListUI(updateOnEmprunteur).update(index, charge)
    this.refreshState()
  }

  public add(addOnEmprunteur: boolean = true) {
    this.selectChargeListUI(addOnEmprunteur).add(DEFAULT_CHARGE)
    this.refreshState()
  }

  public static defaultChargesUI(hasCoEmprunteur: boolean): ChargesUI {
    return new ChargesUI(new ChargeListUI(), new ChargeListUI(), hasCoEmprunteur)
  }

  public async store(): Promise<void> {
    this.emprunteursCharges.store(this.handleStoreCharge!)
    if (this.hasCoEmprunteur) {
      this.coEmprunteursCharges.store(this.handleStoreCharge!)
    }
    this.refreshState()
  }

  public withHandleStoreCharge(handleStoreCharge: (personneId: string, revenu: ChargeFormUI) => Promise<ChargeFormUI>): ChargesUI {
    this.handleStoreCharge = handleStoreCharge
    return this
  }

  private selectChargeListUI(selectOnEmprunteur: boolean): ChargeListUI {
    return selectOnEmprunteur ? this.emprunteursCharges : this.coEmprunteursCharges
  }
}
