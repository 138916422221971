/** My Access Pro (Portail partenaire pour Depot) */
export const PORTAIL_CGP = 'portailcgp'
/** My Access Pro (Portail partenaire pour DC) */
export const PORTAIL_PARTENAIRE = 'partenaire'
/** Espace Client */
export const PORTAIL_EXTRANET = 'extranet'

export const ESPACE_PERSONNEL_DOM = 'espacepersonneldom'

export const MAP_DOM = 'myaccessprodom'

export const getPartenaireActivite = portail => {
  if (!portail) {
    return undefined
  }
  switch (portail) {
    case PORTAIL_CGP: return 'DEPOT'
    case PORTAIL_PARTENAIRE: return 'DC'
    default: return undefined
  }
}

export const getPortailPartenaireFromActivite = activite => {
  if (!activite) {
    return undefined
  }
  switch (activite) {
    case 'DEPOT': return PORTAIL_CGP
    case 'DC': return PORTAIL_PARTENAIRE
    default: return undefined
  }
}
