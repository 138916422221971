import { useEffect, useState } from 'react'
import queryString from 'query-string'
import { fetch } from '@core/common-frontend'

const DEFAULT_CALLBACK_STATE = { success: undefined, error: undefined, redirectTo: undefined, flowType: undefined, id: undefined }

/**
 * Hook permettant de décoder le retour d'Azure AD.
 * Ce décodage est effectué directement par le hook en cas d'erreur, ou avec un appel au serveur pour échanger le code par un token en cas de succès
 * @param queryParams
 * @returns {{success: boolean, error: string|Error, redirectTo: string, flowType: string}}
 */
export function useHandleCallbackFromAzureAd(queryParams) {
  const [state, setState] = useState(DEFAULT_CALLBACK_STATE)
  const decodedError = decodeAzureAdError(queryString.parse(queryParams))
  const shouldCallServer = decodedError === undefined

  useEffect(() => {
    let cancelled = false
    if (shouldCallServer && window.GATSBY_API_URL) {
      setState(DEFAULT_CALLBACK_STATE)
      decodeAzureAdCallback(queryParams)
        .then(response => {
          if (!cancelled) {
            setState({ success: true, error: undefined, previousUrl: response.previousUrl, flowType: response.flowType, id: response?.id })
            if (response.refreshToken) {
              localStorage.setItem('refreshToken', response.refreshToken)
            }
          }
        })
        .catch(error => {
          if (!cancelled) { setState({ success: false, error }) }
        })
    }
    return () => { cancelled = true }
  }, [window.GATSBY_API_URL, shouldCallServer, queryParams])

  if (decodedError) {
    return { success: false, error: decodedError }
  }

  return state
}

/**
 * Retourne l'url essayée d'être atteinte et le refresh token
 */
async function decodeAzureAdCallback(queryParams) {
  const response = await fetch(`/azuread/login-callback?${new URLSearchParams(queryParams)}`)
  if (!response.ok) {
    throw new Error('Impossible de décoder le retour de AzureAd')
  }
  const responseJson = await response.json()
  return { previousUrl: responseJson.afterLogginUrl, refreshToken: responseJson.refreshToken, flowType: responseJson.flowType, id: responseJson?.id }
}

function decodeAzureAdError({ error, error_description: errorDescription, code }) {
  if (!error) {
    // Cas classique: pas d'erreur
    return undefined
  }
  if (error === 'signup_wanted') {
    return 'SIGNUP_WANTED'
  }
  // L’utilisateur a oublié son mot de passe.
  if (error === 'access_denied' && errorDescription && errorDescription.includes('AADB2C90118')) {
    return 'RESET_PASSWORD_WANTED'
  }
  // Annulation par l’utilisateur du Reset Password
  if (error === 'access_denied' && errorDescription && errorDescription.includes('AADB2C90091')) {
    return 'SIGNIN_WANTED'
  }
  // L’utilisateur a dépassé le nombre maximal de Reset Password => Annulation du Reset Password
  if (error === 'server_error' && errorDescription && errorDescription.includes('AADB2C90157')) {
    return 'SIGNIN_WANTED'
  }
  if (error === 'phone_change_wanted') {
    return 'PHONE_CHANGE_WANTED'
  }
  if (!error && !code) {
    return 'WRONG_PARAMS'
  }
  return 'UNKNOWN'
}
