export enum Regime {
    CONSO = 'CONSO',
    IMMO = 'IMMO'
}

export enum Finalite {
    INVESTISSEMENT_LOCATIF = 'INVESTISSEMENT_LOCATIF'
}

export interface Palier {
    duree?: number
    dateDebutPalier?: string
    montantEcheance: number
}

export enum PretSource {
    SUR_JUSTIFICATIF = 'SUR_JUSTIFICATIF',
    SUR_DECLARATIF = 'SUR_DECLARATIF',
    NON_COMMUNIQUE = 'NON_COMMUNIQUE'
}

export interface Pret {
    id?: string
    position?: number
    repris?: boolean
    nature?: string
    nomPreteur?: string
    reference?: string
    finalite?: Finalite
    debut?: string
    fin?: string
    taux?: number
    regime?: Regime
    bienFinance?: string
    preavis?: number
    montantCrd?: number
    montantIra?: number
    montantCapitalInitial?: number
    paliers?: Palier[]
    sourceTaux?: PretSource
    sourcePreavis?: PretSource
    sourceIra?: PretSource
    sourceFin?: PretSource
  }
