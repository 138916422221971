export enum RegimePropriete {
    PLEINE_PROPRIETE = 'PLEINE_PROPRIETE',
    USUFRUIT_SEUL = 'USUFRUIT_SEUL',
    NUE_PROPRIETE_SEULE = 'NUE_PROPRIETE_SEULE',
    USUFRUIT_ET_NUE_PROPRIETE_EN_PARTIE = 'USUFRUIT_ET_NUE_PROPRIETE_EN_PARTIE'
}

export const REGIME_PROPRIETE_MAP = new Map<RegimePropriete | undefined, string>([
  [RegimePropriete.NUE_PROPRIETE_SEULE, 'Nue propriété seule'],
  [RegimePropriete.PLEINE_PROPRIETE, 'Pleine propriété'],
  [RegimePropriete.USUFRUIT_ET_NUE_PROPRIETE_EN_PARTIE, 'Usufruit et nue propriété en partie'],
  [RegimePropriete.USUFRUIT_SEUL, 'Usufruit seul'],
])


export const REGIME_PROPRIETE_OPTIONS = [{
  label: 'Pleine propriété',
  value: RegimePropriete.PLEINE_PROPRIETE,
}, {
  label: 'Usufruit seul',
  value: RegimePropriete.USUFRUIT_SEUL,
}, {
  label: 'Nue propriété seule',
  value: RegimePropriete.NUE_PROPRIETE_SEULE,
}, {
  label: 'Usufruit et nue propriété en partie',
  value: RegimePropriete.USUFRUIT_ET_NUE_PROPRIETE_EN_PARTIE,
}]
