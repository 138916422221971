import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { WarningMessage } from '@partenaire/frontend-ui'

import styles from './SaisieWarningMessages.module.css'
import { ErrorMessage } from '../SaisieErrorMessages'

interface SaisieWarningMessagesProps {
  warnings?: ErrorMessage[]
  className?: string
}

export const SaisieWarningMessages: FunctionComponent<SaisieWarningMessagesProps> = ({ warnings = [], className = '', ...props }) => (
  <div {...props} className={classnames(styles.saisieWarningMessage, className)}>
    {warnings.map(warning => (
      <WarningMessage
        contentClassName={styles.saisieWarningMessage__warning}
        key={String(warning!.message)}
      >
        <strong className={styles.saisieWarningMessage__title}>
          {warning?.title}
        </strong>
        <span className={styles.saisieWarningMessage__message}>
          {warning?.message}
        </span>
      </WarningMessage>
    ))}
  </div>)


SaisieWarningMessages.defaultProps = {
  className: '',
  warnings: [],
}
