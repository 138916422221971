import React, { useEffect, useState } from 'react'
import { func, arrayOf, shape, string, number, bool } from 'prop-types'
import { Input } from '@mmb/ui-components'
import { InputAge, AddChild } from '@partenaire/frontend-ui'

import { NombreEnfantsPlaceholder } from '../NombreEnfantsPlaceholder'

import styles from './NombreEnfants.module.css'
import { hasErrorForName } from '../../../../utils'


NombreEnfants.propTypes = {
  enfants: arrayOf(
    shape({
      id: string.isRequired,
      age: number.isRequired,
      order: number.isRequired,
    }),
  ),
  updateEnfant: func,
  addEnfant: func,
  removeEnfant: func,
  loading: bool,
  errors: arrayOf(
    shape({
      field: string.isRequired,
      message: string.isRequired,
    }),
  ),
}

NombreEnfants.defaultProps = {
  enfants: [],
  updateEnfant: () => {},
  addEnfant: () => {},
  removeEnfant: () => {},
  loading: false,
  errors: [],
}

export function NombreEnfants({ enfants, updateEnfant, addEnfant, removeEnfant, loading, errors }) {
  const [enfantsACharge, setEnfantsACharge] = useState(0)
  const filterEnfantsUnder18 = enfantsToFilter => enfantsToFilter.filter(enfant => enfant.age < 18)
  const sortByOrder = ({ order: actualOrder }, { order: nextOrder }) => actualOrder - nextOrder

  useEffect(() => {
    setEnfantsACharge(filterEnfantsUnder18(enfants).length)
  }, [enfants])

  if (loading) {
    return (<NombreEnfantsPlaceholder />)
  }

  return (
    <div className={styles.nombreEnfants}>
      <div className={styles.nombreEnfants__inputs}>
        <p className={styles.nombreEnfants__label}>Nombre d'enfants</p>
        <Input
          disabled
          name="nbEnfants"
          label="Nombre d'enfants"
          type="number"
          hideLabel
          aria-invalid={hasErrorForName('nbEnfants', errors)}
          hasError={hasErrorForName('nbEnfants', errors)}
          value={enfants.length}
          fieldClassName={styles.nombreEnfants__inputNombre}
        />
        <p className={styles.nombreEnfants__label}>
          Dont à charge
          <span className={styles.nombreEnfants__aCharge}> de moins de 18 ans</span>
        </p>
        <Input
          disabled
          type="number"
          name="aCharge"
          label="Dont à charge de moins de 18 ans"
          hideLabel
          readOnly
          value={enfantsACharge}
          fieldClassName={styles.nombreEnfants__inputNombre}
        />
      </div>
      <div className={styles.nombreEnfants__ages}>
        {enfants.length > 0 && enfants.sort(sortByOrder).map(enfant => (
          <InputAge
            className={styles.nombreEnfants__inputAge}
            name={`situationFamiliale.enfants[${enfant.order}].age`}
            numero={String(enfant.order)}
            label={`Enfant ${String(enfant.order)}`}
            hideLabel
            key={`enfants_${enfant.order}`}
            onChange={({ target }) => updateEnfant({ ...enfant, age: target.value })}
            handleRemove={() => removeEnfant(enfant)}
            hasError={hasErrorForName(`enfantAge_${enfant.order}`, errors)}
            value={enfant.age}
          />
        ))}
        <AddChild onClick={addEnfant} className={styles.nombreEnfants__inputAge} />
      </div>
    </div>
  )
}
