import React, { useState } from 'react'
import { string, func } from 'prop-types'
import { Input, Arrow } from '@mmb/ui-components'

import styles from './LoginField.module.css'

LoginField.propTypes = {
  value: string.isRequired,
  defaultValue: string,
  onChange: func.isRequired,
  onClick: func.isRequired,
  inputClassName: string,
}

LoginField.defaultProps = {
  defaultValue: '',
  inputClassName: '',
}

export function LoginField({ defaultValue, value, onChange, onClick, inputClassName }) {
  const [showButton, setShowButton] = useState(defaultValue)

  return showButton ? (
    <div className={styles.loginField__account}>
      <span className={styles.loginField__login}>{defaultValue}</span>
      <button
        className={styles.loginField__switch}
        type="button"
        onClick={() => {
          setShowButton(!showButton)
          onClick()
        }}
      >
        <div className={styles.loginField__arrows}>
          <Arrow className={styles.loginField__arrow} />
          <Arrow className={styles.loginField__arrow} left />
        </div>
        Changer d'utilisateur
      </button>
    </div>
  ) : (
    <Input
      required
      name="login"
      label="Email"
      type="email"
      value={value}
      onChange={onChange}
      className={inputClassName}
    />
  )
}
