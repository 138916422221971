import React from 'react'
import { func, string } from 'prop-types'

import { Retry } from '@mmb/ui-components'

import { useResendEmailActivationPersonne } from './resendAction.hooks'

import styles from '../../MoreActions.module.css'

ResendButton.propTypes = {
  onClose: func.isRequired,
  id: string.isRequired,
}

export function ResendButton({ onClose, id }) {
  const resendEmailActivationPersonne = useResendEmailActivationPersonne(onClose)
  return (
    <button
      aria-label="Renvoyer l'email"
      className={styles.moreActions__button}
      onClick={() => resendEmailActivationPersonne(id)}
    >
      <Retry className={styles.moreActions__button__retry} />
    </button>
  )
}
