import { NatureDette } from './DetteFormUI'
import { NaturePret, RegimePret, SourcePret } from './PretFormUI'
import { SourcePretOptionUI } from './SourcePretUI'

export const ALL_NATURE_PRET: string[] = Object.keys(NaturePret)
export const ALL_REGIME_PRET: string[] = Object.keys(RegimePret)
export const ALL_SOURCE_PRET: string[] = Object.keys(SourcePret)

export const PRET_DE_RESTRUCTURATION: NaturePret[] = [
  NaturePret.PRET_DE_RESTRUCTURATION_HYPOTHECAIRE,
  NaturePret.PRET_DE_RESTRUCTURATION_NON_HYPOTHECAIRE,
]

export const PRET_IMMOBILIER: NaturePret[] = [
  NaturePret.PRET_IMMOBILIER_HYPOTHECAIRE,
  NaturePret.PRET_IMMOBILIER_NON_HYPOTHECAIRE,
]


export const ALL_NATURE_DETTE: string[] = Object.keys(NatureDette)

export const SECURED_NATURE_DETTE: NatureDette[] = [
  NatureDette.SOULTE,
  NatureDette.DETTE_FISCALE,
  NatureDette.DETTE_HUISSIER,
  NatureDette.RETARD_DE_LOYER,
  NatureDette.RETARD_DE_CHARGES_DE_COPRO,
  NatureDette.DETTE_SOCIETE_DE_RECOUVREMENT,
]


export const NATURE_PRET_INFOS: SourcePretOptionUI[] = [
  new SourcePretOptionUI('J', SourcePret.SUR_JUSTIFICATIF, 'justificatif', 'Justificatif'),
  new SourcePretOptionUI('D', SourcePret.SUR_DECLARATIF, 'declaratif', 'Déclaratif'),
  new SourcePretOptionUI('NC', SourcePret.NON_COMMUNIQUE, 'non-communique', 'Non communiqué'),
]

export const MAX_DAY_UNTIL_FIN = 90
export const MIN_DAY_UNTIL_FIN = 0
export const MAX_MONTANT_TOTAL = 30000
export const MIN_INPUT_VALUE = 0
export const MAX_TAUX = 100
export const MIN_DATE_FROM_BIRTHDATE = 1
