import { useEffect } from 'react'
import { gql, useLazyQuery } from '@core/graphql'
import { useSaisie } from '../Contexts/SaisieContext'
import { SaisiePropsUI } from '../../model'

export const GET_FORM_DOSSIER = gql`
query getFormDossier($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    agence
    apporteur
    gamme
    demande {
      id
    }
    emprunteurs {
      id
    }
  }
}
`

export function useForm() {
  const { saisieUI, updateSaisie } = useSaisie()
  const [getFormByDossierId, { data, loading, error }] = useLazyQuery(GET_FORM_DOSSIER, { fetchPolicy: 'network-only' })
  useEffect(() => {
    function fetchFormByDossierId(id) {
      if (!id) return
      getFormByDossierId({ variables: { id } })
    }
    fetchFormByDossierId(saisieUI.props.dossierId)
  }, [getFormByDossierId, saisieUI.props.dossierId])

  useEffect(() => {
    if (loading || error) return
    const { demande, gamme, agence, apporteur, emprunteurs = [] } = data?.partenaire_dossierEnCours || {}
    const [emprunteurData, coEmprunteurData] = emprunteurs
    const props : SaisiePropsUI = {
      gamme,
      agence,
      apporteur,
      demandeId: demande?.id,
      emprunteurId: emprunteurData?.id,
      hasCoEmprunteur: !!coEmprunteurData,
      coEmprunteurId: coEmprunteurData?.id,
    } as SaisiePropsUI
    updateSaisie(props)
  }, [data, loading, error])
  return { loading, error }
}
