import { TooltipUI } from './TooltipUI'
import { OptionUI } from './OptionUI'
import { SituationMatrimoniale } from './SituationMatrimoniale'

export class OptionFamilialeUI {
  constructor(
    public readonly option: OptionUI,
    public readonly hasCoEmprunteur : boolean) {}

  public getToolTip() : TooltipUI {
    if (this.isSeparationDeBiens()) {
      return new TooltipUI(
        'Information sur la séparation de biens',
        'Le bien en garantie, les prêts et dettes repris sont au seul nom de l\'emprunteur.')
    }
    return new TooltipUI('', '')
  }

  public hasTooltip() : boolean {
    return this.isSeparationDeBiens()
  }

  public isChecked(selectedValue : string) : boolean {
    return this.option.value === selectedValue
  }

  private isSeparationDeBiens() : boolean {
    return this.option.value === SituationMatrimoniale.MARIE && !this.hasCoEmprunteur
  }
}
