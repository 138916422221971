import React from 'react'
import { string } from 'prop-types'

import { usePlanFinancement } from './DetailPlanFinancement.hooks'
import { WithPalier } from './Components/WithPalier'
import { WithoutPalier } from './Components/WithoutPalier'

DetailPlanFinancement.propTypes = {
  id: string.isRequired,
}

export function DetailPlanFinancement({ id }) {
  const { loading, planFinancement, hasPalier } = usePlanFinancement(id)
  if (loading || planFinancement === undefined) return null
  return (
    <div aria-label="Plan de financement">
      {(hasPalier ?
        <WithPalier planFinancement={planFinancement} /> :
        <WithoutPalier planFinancement={planFinancement} />
      )}
    </div>
  )
}
