/* eslint-disable react/no-array-index-key */
import React from 'react'
import { isEmpty } from 'lodash'
import { Placeholder } from '@mmb/ui-components'

import { ListDossiersEmpty } from '@core/partenaire-ui'
import { DossierItem } from '../DossierItem'
import { DossierItemPlaceholder } from '../DossierItemPlaceholder'

import { useAccueilDossiers } from './DossiersList.hooks'

import styles from './DossiersList.module.css'

export function DossiersList() {
  const { loading, dossiers } = useAccueilDossiers()

  if (isEmpty(dossiers) && !loading) {
    return <ListDossiersEmpty />
  }

  const customPlaceholder = (
    <>
      {[...Array(5)].map((_, index) => <DossierItemPlaceholder key={index} />)}
    </>
  )

  return (
    <div
      role="table"
      aria-describedby="dossiers_table_description"
      aria-label="Liste des 5 derniers dossiers modifiés"
      className={styles.dossiersList}
    >
      <div role="rowgroup" className={styles.dossiersList__body}>
        <Placeholder loading={loading} customPlaceholder={customPlaceholder}>
          {dossiers.map((dossier, index) => <DossierItem key={dossier.id} dossier={dossier} index={index} />)}
        </Placeholder>
      </div>
    </div>
  )
}
