import React from 'react'

import { TextRow } from '@mmb/ui-components'

import styles from './SituationBancairePlaceholder.module.css'

export function SituationBancairePlaceholder() {
  return (
    <div className={styles.situationBancairePlaceholder}>
      <span aria-label="numberOfComptes.placeholder">
        <TextRow width="12rem" className={styles.situationBancairePlaceholder__row} />
      </span>
      <div className={styles.situationBancairePlaceholder__items} aria-label="rejets.placeholder">
        <div>
          <span>Prêt conso</span>
          <TextRow width="12rem" className={styles.situationBancairePlaceholder__item} />
        </div>
        <div>
          <span>Prêt immo/RAC hypothécaire</span>
          <TextRow width="12rem" className={styles.situationBancairePlaceholder__item} />
        </div>
      </div>

    </div>
  )
}
