import { gql, useMutation } from '@core/graphql'
import { toast } from '@core/ui'

import { USE_SEARCH_PERSONNES_QUERY } from '../List/List.hooks'

const DISABLE_PERSONNE = gql`
  mutation ($idPersonne: String!) {
    partenaire_disablePersonne(idPersonne: $idPersonne)
  }
`

export const useDisablePersonne = () => {
  const [mutation] = useMutation(DISABLE_PERSONNE, {
    refetchQueries: [USE_SEARCH_PERSONNES_QUERY],
    awaitRefetchQueries: true,
    onCompleted: () => toast({ message: 'Un utilisateur a été supprimé.', type: 'SUCCESS' }),
    onError: () => toast({ message: 'La suppression a échouée.', type: 'ERROR' }),
  })

  return idPersonne => mutation({ variables: { idPersonne } })
}
