import { formatCondenseDate } from '@core/common'

import { formatValeur } from '../../../utils'
import {
  SansAssuranceUI,
  AssuranceDetailDCSPropsUI,
  AssuranceDetailDCUPropsUI,
  AssuranceTypeUI,
  AssuranceUI,
  StatutAssuranceGroupDCS,
} from '../../../model/assurance/AssuranceUI'

export const toAssuranceUI = (assuranceGraphQL, idDossier: string, idPersonne: string, isDossierSecured: boolean, setAssurance, refetch): AssuranceUI => {
  const type: AssuranceTypeUI = assuranceGraphQL.type as AssuranceTypeUI
  const assuranceUI = new AssuranceUI({
    id: assuranceGraphQL.id as string,
    type,
    idDossier,
    idPersonne,
    nom: assuranceGraphQL.nom && assuranceGraphQL.prenom ? `${assuranceGraphQL.prenom} ${assuranceGraphQL.nom} ` : assuranceGraphQL.nom,
    dateNaissance: formatCondenseDate(assuranceGraphQL.dateNaissance) as string,
    situationAdhesion: assuranceGraphQL.situationAdhesion?.value,
    amorcageAdhesion: assuranceGraphQL.amorcageAdhesion,
    dateSituationAdhesion: formatCondenseDate(assuranceGraphQL.situationAdhesion?.date),
    ...toAssuranceGroupBase(getAssuranceGroupValue(assuranceGraphQL)),
    ...toAssuranceDelegationDCS(assuranceGraphQL?.delegationDCS || {}, type),
    assuranceDetailDCSPropsUI: toAssuranceGroupDCS(assuranceGraphQL?.assuranceGroupDCS || {}, type),
    assuranceDetailDCUPropsUI: toAssuranceGroupDCU(assuranceGraphQL?.assuranceGroupDCU || {}, type),
    sansAssuranceUI: toSansAssurance(assuranceGraphQL?.sansAssurance || {}),
    refetch,
    isDossierSecured,
  })
  setAssurance(assuranceUI)
  return assuranceUI
}

const getAssuranceGroupValue = graphQLAssuranceGroup => {
  let value
  if (graphQLAssuranceGroup.type === AssuranceTypeUI.ASSURANCE_GROUP_DCS) {
    value = graphQLAssuranceGroup?.assuranceGroupDCS
  } else if (graphQLAssuranceGroup.type === AssuranceTypeUI.ASSURANCE_GROUP_DCU) {
    value = graphQLAssuranceGroup?.assuranceGroupDCU
  }
  return value
}

const toAssuranceGroupBase = graphQLAssuranceGroup => ({
  organisme: graphQLAssuranceGroup?.organisme,
  quotite: graphQLAssuranceGroup?.quotite ? `${graphQLAssuranceGroup?.quotite} %` : '-',
  formule: graphQLAssuranceGroup?.formule || '',
  cotisation: graphQLAssuranceGroup?.quotite && graphQLAssuranceGroup?.cotisation ?
    formatValeur(graphQLAssuranceGroup.cotisation * (graphQLAssuranceGroup.quotite / 100.0), 2, '€') : '-',
  tauxStandard: graphQLAssuranceGroup?.tauxStandard > 0 ? formatValeur(graphQLAssuranceGroup?.tauxStandard, 4, '%') : '',
  tauxFinal: graphQLAssuranceGroup?.tauxFinal > 0 ? formatValeur(graphQLAssuranceGroup?.tauxFinal, 4, '%') : '',
})

const toAssuranceGroupDCU = (graphQLAssuranceGroupDCU, type): AssuranceDetailDCUPropsUI|undefined => type === AssuranceTypeUI.ASSURANCE_GROUP_DCU ? {
  situation: graphQLAssuranceGroupDCU?.situation,
  dateValidation: formatCondenseDate(graphQLAssuranceGroupDCU?.dateValidation),
  statut: graphQLAssuranceGroupDCU?.statut,
  libelleStatut: mapLibelleStatutDCU.get(graphQLAssuranceGroupDCU?.statut) || 'Statut inconnu',
  dateStatut: formatCondenseDate(graphQLAssuranceGroupDCU?.dateStatut),
} : undefined

const toAssuranceGroupDCS = (graphQLAssuranceGroupDCS, type): AssuranceDetailDCSPropsUI|undefined => type === AssuranceTypeUI.ASSURANCE_GROUP_DCS ? {
  numeroAdhesion: graphQLAssuranceGroupDCS.numeroAdhesion,
  webcreditorUrl: graphQLAssuranceGroupDCS.webcreditorUrl,
  statut: StatutAssuranceGroupDCS[graphQLAssuranceGroupDCS.statut],
  libelleStatut: mapLibelleStatutDCS.get(graphQLAssuranceGroupDCS.statut) || '',
  dateStatut: formatCondenseDate(graphQLAssuranceGroupDCS?.dateStatut),
  libelleDetailStatut: mapLibelleDetailStatutDCS.get(graphQLAssuranceGroupDCS.detailStatut) || '',
  detailStatut: graphQLAssuranceGroupDCS.detailStatut,
} : undefined

const toSansAssurance = (graphQLSansAssurance: any): SansAssuranceUI => ({
  typeRefus: mapLibelleStatutSansAssurance.get(graphQLSansAssurance.typeRefus),
  dateRefus: formatCondenseDate(graphQLSansAssurance.dateRefus),
})

const toAssuranceDelegationDCS = (graphQLAssuranceDelegationDCS, type) => type === AssuranceTypeUI.DELEGATION ?
  { organisme: graphQLAssuranceDelegationDCS.organisme } : {}


const mapLibelleStatutDCS = new Map<string, string>([
  ['SIMULATION', 'Simulation'],
  ['FORMALITES_ADHESION', 'Formalités Adhesion'],
  ['FORMALITES_MEDICALES', 'Formalités médicales'],
  ['DECISION', 'Décision'],
  ['FINALISATION', 'Finalisation'],
  ['SANS_SUITE', 'Sans suite'],
])

const mapLibelleDetailStatutDCS = new Map<string, string>([
  ['EN_COURS', 'En cours de saisie'],
  ['DEVIS_EMIS', 'Devis émis'],
  ['FINALISEE', 'Finalisée'],
  ['ATTENTE_CONNEXION_ESPACE_ADHERENT', 'Attente de connexion à l\'espace adhérent'],
  ['ATTENTE_SIGNATURE_IDIP', 'Attente de signature IDIP'],
  ['ATTENTE_SIGNATURE_DIC', 'Attente de signature DIC'],
  ['ATTENTE_SIGNATURE_BA', 'Attente de signature BA'],
  ['ATTENTE_SIGNATURE_QM', 'Attente de signature QM'],
  ['AVIS_MEDECIN_CONSEIL_RENDU', 'Avis médecin conseil rendu'],
  ['ATTENTE_DRC', 'Attente DRC'],
  ['ATTENTE_MER', 'Attente MER'],
  ['DRC_ADHERENT_RENDU', 'DRC Adérent rendu'],
  ['ATTENTE_BPA', 'Attente BPA'],
  ['ATTENTE_AERAS', 'Attente AERAS'],
  ['ADHESION_ACCEPTEE', 'Adhésion acceptée'],
  ['ADHESION_ACCEPTEE_AVEC_CONDITIONS', 'Adhésion acceptée avec conditions'],
  ['ADHESION_REFUSEE', 'Adhésion refusée'],
  ['CERTIFICAT_ASSURANCE_EMIS', 'Certificat assurance émis'],
  ['ENTRE_EN_CHIFFRE_AFFAIRE', 'Entrée en chiffre d\'affaire'],
  ['ABANDON', 'Abandon'],
  ['PRET_ANNULE', 'Prêt annulé'],
  ['NOUVELLE_OFFRE', 'Nouvelle offre'],
  ['BPA_REFUSE', 'BPA refusé'],
])

const mapLibelleStatutDCU = new Map([
  ['ACCORD_ASSUREUR_VALIDE', 'Validé'],
  ['ACCORD_ASSUREUR_RECU', 'Accordé par l\'assureur'],
  ['QS_TRANSMIS_ASSUREUR', 'Transmis à l\'assureur'],
])

const mapLibelleStatutSansAssurance = new Map([
  ['ASSUREUR', 'Assureur'],
  ['CLIENT', 'Client'],
])
