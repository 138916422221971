import React, { FunctionComponent, useState } from 'react'

import classNames from 'classnames'

import styles from './HistoriqueCommentaire.module.css'

interface HistoriqueCommentaireProps {
  commentaire: string
}

export const HistoriqueCommentaire: FunctionComponent<HistoriqueCommentaireProps> = ({ commentaire }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return !commentaire ? null : (
    <div
      aria-label="histo transfert commentaire"
      className={classNames({
        [styles.historique__commentaireExpanded]: isExpanded,
        [styles.historique__commentaireUnexpanded]: !isExpanded,
      }, styles.historique__commentaire)}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <span className={styles.historique__commentaireTexte}>{commentaire}</span>
    </div>
  )
}
