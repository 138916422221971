import React from 'react'
import { bool, func, node, string } from 'prop-types'

import { Card, Button, Cross, Arrow } from '@mmb/ui-components'

import styles from './ModalCard.module.css'

ModalCard.propTypes = {
  children: node,
  header: node,
  submitLabel: string,
  onValidate: func.isRequired,
  onClose: func,
  hideArrow: bool,
}

ModalCard.defaultProps = {
  children: null,
  header: null,
  submitLabel: 'Confirmer',
  onClose: () => {},
  hideArrow: false,
}

export function ModalCard({ children, submitLabel, onClose, onValidate, header, hideArrow }) {
  return (
    <Card className={styles.modalCard}>
      {header}

      <button
        onClick={() => onClose()}
        aria-label="Fermer"
        className={styles.modalCard__close}
      >
        <Cross />
      </button>

      <form
        className={styles.modalCard__form}
        onSubmit={e => {
          e.preventDefault()
          onValidate()
        }}
      >
        {children}

        <div className={styles.modalCard__buttons}>
          <Button
            type="button"
            outline
            leftIcon={args => hideArrow ? null : <Arrow {...args} left />}
            className={styles.modalCard__cancel}
            onClick={() => onClose()}
          >
            Annuler
          </Button>
          <Button
            type="submit"
            rightIcon={hideArrow ? null : Arrow}
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </Card>
  )
}
