import React, { createContext } from 'react'
import { string, node, elementType } from 'prop-types'
import cn from 'classnames'

import styles from './Menu.module.css'

export const menuContext = createContext()

Menu.propTypes = {
  className: string,
  children: node.isRequired,
  linkComponent: elementType.isRequired,
}

Menu.defaultProps = {
  className: '',
}

export function Menu({ className, children, linkComponent }) {
  return (
    <menuContext.Provider value={{ linkComponent }}>
      <nav className={cn(styles.menu, className)}>
        <ul className={styles.menu__list}>
          {children}
        </ul>
      </nav>
    </menuContext.Provider>
  )
}
