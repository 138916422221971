import React from 'react'
import { func } from 'prop-types'

import { Edit } from '@mmb/ui-components'

import styles from '../MoreActions.module.css'

UpdateButton.propTypes = {
  setModalOpen: func.isRequired,
}

export function UpdateButton({ setModalOpen }) {
  const buttonEdit = <Edit />
  return (
    <button
      aria-label="Modifier"
      className={styles.moreActions__button}
      onClick={() => setModalOpen(true)}
    >
      {buttonEdit}
    </button>
  )
}
