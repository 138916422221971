import { isValidDate } from '@core/common'
import { isEmpty } from 'lodash'
import React from 'react'
import { ActiviteFormUI, ActiviteType } from '../../model/activite/ActiviteFormUI'
import { ActiviteListUI } from '../../model/activite/ActiviteListUI'
import { ActivitesUI } from '../../model/activite/ActivitesUI'
import { DATE_MM_YYYY } from '../../model/format'
import { getBasculeHypothecaireLink } from '../gamme'
import { ValidationErrors, ValidationFieldError, buildValidationError } from '../../model'

export const VALIDATION_ERRORS_MESSAGES = ({ changeGamme, isUpdatingGamme }) => ({
  ACTIVITE_PRINCIPALE_REQUIRED: {
    title: 'Information requise',
    message: 'Veuillez renseigner une activité principale et seulement une par emprunteur.',
  },
  DATE_FORMAT_INVALID: {
    title: 'Date de début d\'activité',
    message: `Veuillez renseigner une date au format ${DATE_MM_YYYY.toLowerCase()}`,
  },
  WITHOUT_PROFESSIONNAL_ACTIVITY: {
    title: 'Type d\'activité',
    message: 'Le type d\'activité ne nous permet pas d\'intervenir.',
  },
  INVALID_TYPE: {
    title: 'Type d\'activité',
    message: (
      <>
        L'activité sélectionnée ne nous permet pas d'intervenir en sans garantie.
        {getBasculeHypothecaireLink(changeGamme, isUpdatingGamme, 'merci de basculer en hypothécaire.')}
      </>
    ),
  },
})

function validateActivite(activiteFormUI: ActiviteFormUI, activitesUI, isSecured: boolean, globalErrors: { message: string, field: string }[]) {
  const errors: any[] = []
  const fields = ['catSocio', 'profession', 'typeContrat', 'debut']
  const requiredFieldTypes = {
    catSocio: [ActiviteType.SALARIE_DU_PRIVE, ActiviteType.SALARIE_DU_PUBLIC, ActiviteType.NON_SALARIE],
    profession: [ActiviteType.SALARIE_DU_PRIVE, ActiviteType.SALARIE_DU_PUBLIC, ActiviteType.NON_SALARIE],
    typeContrat: [ActiviteType.SALARIE_DU_PRIVE, ActiviteType.SALARIE_DU_PUBLIC],
    debut: [ActiviteType.SALARIE_DU_PRIVE, ActiviteType.SALARIE_DU_PUBLIC, ActiviteType.NON_SALARIE],
  }

  const type = activiteFormUI.type
  if (!activiteFormUI.isPrincipale && activiteFormUI.isPrincipale !== false) errors.push(buildValidationError('isPrincipale', 'MISSING_VALUE'))

  if (!isValidDate(activiteFormUI.debut || '', DATE_MM_YYYY)) {
    errors.push(buildValidationError('debut', 'DATE_FORMAT_INVALID'))
  }

  if (isEmpty(type)) {
    errors.push(buildValidationError('type', 'MISSING_VALUE'))
    return errors
  }

  fields.forEach(field => {
    if (requiredFieldTypes[field].includes(type!) && isEmpty(activiteFormUI[field])) {
      errors.push(buildValidationError(field, 'MISSING_VALUE'))
    }
  })

  if (globalErrors.find(({ message }) => message === 'ACTIVITE_PRINCIPALE_REQUIRED')) {
    errors.push(buildValidationError('isPrincipale', 'ACTIVITE_PRINCIPALE_REQUIRED'))
  }

  if (type === ActiviteType.RENTIER && !isSecured) {
    errors.push(buildValidationError('type', 'INVALID_TYPE'))
  }

  if (type === ActiviteType.SANS_ACTIVITE_PROFESSIONNELLE && !activitesUI.hasCoEmprunteur && activiteFormUI.isPrincipale) {
    errors.push(buildValidationError('type', 'WITHOUT_PROFESSIONNAL_ACTIVITY'))
  }

  return errors
}

function validateActivitesGlobalErrors(activites: ActiviteListUI, isCoEmprunteur: boolean) {
  const globalErrors = activites.list.length === 0 ? [buildValidationError('activites', 'ACTIVITE_REQUIRED')] : []
  if (activites.list.find(activite => !isValidDate(activite.debut || '', DATE_MM_YYYY))) {
    globalErrors.push(buildValidationError(isCoEmprunteur ? 'activitesCoEmprunteur' : 'activitesEmprunteur', 'DATE_FORMAT_INVALID'))
  }
  if (activites.getActivitesPrincipales()?.length !== 1) {
    globalErrors.push(buildValidationError(isCoEmprunteur ? 'activitesCoEmprunteur' : 'activitesEmprunteur', 'ACTIVITE_PRINCIPALE_REQUIRED'))
  }
  return globalErrors
}

export interface ActivitesValidationErrors extends ValidationErrors {
  emprunteurErrors: ValidationFieldError[][]
  coEmprunteurErrors: ValidationFieldError[][]
}

export function validateActivites({ activitesUI, isSecured }: { activitesUI: ActivitesUI, isSecured: boolean}) : ActivitesValidationErrors {
  const globalErrors = [...validateActivitesGlobalErrors(activitesUI.emprunteursActivites, false)]
  if (activitesUI.hasCoEmprunteur) {
    globalErrors.push(...validateActivitesGlobalErrors(activitesUI.coEmprunteursActivites, true))
  }
  const emprunteurErrors = activitesUI.emprunteursActivites.list.map(activite => validateActivite(activite, activitesUI, isSecured, globalErrors))
  const coEmprunteurErrors = activitesUI.hasCoEmprunteur ?

    activitesUI.coEmprunteursActivites.list.map(activite => validateActivite(activite, activitesUI, isSecured, globalErrors)) : []
  const hasErrors = (
    (globalErrors?.length || 0) +
    (emprunteurErrors.flatMap(p => p)?.length || 0) +
    (coEmprunteurErrors.flatMap(p => p)?.length || 0)
  ) > 0
  return {
    hasErrors,
    globalErrors,
    emprunteurErrors,
    coEmprunteurErrors,
  }
}

export const buildActiviteValidationError = (error: any, gammeProps: { changeGamme: () => void, isUpdatingGamme: boolean }) => {
  if (!error || !error?.message) {
    throw new Error('No Error were provided to build validation error')
  }

  const messages = VALIDATION_ERRORS_MESSAGES(gammeProps)

  if (Object.keys(messages).includes(error?.message)) {
    return messages[error?.message]
  }

  return null
}
