import React from 'react'
import { useNavigate } from 'react-router-dom'
import { node } from 'prop-types'
import { GraphQLErrorsHandler, RedirectErrorBoundary, ToastContainer } from '@core/ui'

import styles from './Configuration.module.css'

Configuration.propTypes = {
  children: node.isRequired,
}

export function Configuration({ children }) {
  const navigate = useNavigate()
  return (
    <RedirectErrorBoundary navigate={navigate}>
      <GraphQLErrorsHandler path="/app">
        {children}
        <ToastContainer className={styles.configuration__toastContainer} position="top-right" />
      </GraphQLErrorsHandler>
    </RedirectErrorBoundary>
  )
}
