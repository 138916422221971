import { DATE_DD_MM_YYYY_FR, parseDate } from '@core/common'
import { subDays, format } from 'date-fns'
import { DATE_MM_YYYY } from '../format'
import { PositionUI } from '../support/PositionUI'
import { BaisseRevenuUI, buildDefaultRevenu as buildDefaultBaisseRevenuUI } from './BaisseRevenuUI'
import { HandleStoreRevenuFormUI } from './HandlersUI'
import { RevenuFormUI, TypeRevenu } from './RevenuFormUI'

export class RevenuListUI extends PositionUI<RevenuFormUI> {
  private baisseRevenu?: BaisseRevenuUI

  constructor(
    public readonly personneId?: string,
    public readonly list: RevenuFormUI[] = [],
    baisseRevenu?: BaisseRevenuUI) {
    super(list)
    this.baisseRevenu = baisseRevenu
  }

  public clone() {
    return new RevenuListUI(this.personneId, [...this.list], this.baisseRevenu)
  }

  public toogleBaisseRevenu(isSecured: boolean = false): string | undefined {
    const id = this.baisseRevenu?.id
    this.baisseRevenu = !this.hasBaisseRevenu() ? buildDefaultBaisseRevenuUI(isSecured) : undefined
    return id
  }

  public updateBaisseRevenu(value: any): void {
    this.baisseRevenu = { ...this.baisseRevenu, ...value }
  }

  public hasBaisseRevenu(): boolean {
    return !!this.baisseRevenu
  }

  public updateDateFin(hasPalier: boolean): void {
    const fin = hasPalier && this.baisseRevenu?.debut ? this.computeDateFinRevenu() : null
    this.list
      .forEach((revenu, index) => {
        if (revenu?.type && [TypeRevenu.SALAIRE, TypeRevenu.BIC_BNC].includes(revenu?.type)) {
          this.update(index, { ...revenu, fin })
        }
      })
    this.refreshState()
  }

  public async store(handleRevenu: HandleStoreRevenuFormUI, hasPalier: boolean): Promise<void> {
    this.updateDateFin(hasPalier)
    const updatedRevenus = await Promise.all(this.list.map(revenu => handleRevenu(this.personneId!, revenu)))
    updatedRevenus.forEach((updatedRevenu, index) => this.update(index, updatedRevenu))

    if (this.hasBaisseRevenu()) {
      this.baisseRevenu = await handleRevenu(this.personneId!, this.baisseRevenu!)
    }
  }

  public getBaisseRevenu(): BaisseRevenuUI | undefined {
    return this.baisseRevenu
  }

  private computeDateFinRevenu(): string {
    const newDateFin = parseDate(this.baisseRevenu?.debut, DATE_MM_YYYY)
    return newDateFin ? format(subDays(newDateFin, 1), DATE_DD_MM_YYYY_FR) : ''
  }
}
