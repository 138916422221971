import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { NavigationPath } from '@core/partenaire-common'
import { UnauthenticatedLayout } from '../UnauthenticatedLayout'
import { AzureADFlowType } from '../../hooks/AzureAD/AzureADInterface'

import { Callback } from '../Callback'
import { Flow } from '../Flow'

const Signin = () => <Flow flowType={AzureADFlowType.SIGNIN} defaultCompletionUrl={NavigationPath.HOME} wrapInUnauthenticatedLayout={UnauthenticatedLayout} />

export function ConnexionRoute() {
  return (
    <Routes>
      <Route exact path="/" element={<Signin />} />
      <Route path="/callback" element={<Callback />} />
    </Routes>
  )
}
