import { useUtilisateurContext } from '@core/partenaire-common'
import { useEffect, useRef, useState } from 'react'

export function useSelectionCabinetModale() {
  const { loading, idCabinetTravail } = useUtilisateurContext()
  const isNotSet = useRef(true)
  const [isOpen, setOpen] = useState(isNotSet.current)

  useEffect(() => {
    isNotSet.current = !loading && idCabinetTravail === undefined
    setOpen(isNotSet.current)
  }, [idCabinetTravail, loading])

  const onClose = () => {
    setOpen(isNotSet.current)
  }

  return { isOpen, setOpen, onClose }
}
