export enum NatureBien {
    MAISON = 'MAISON',
    APPARTEMENT = 'APPARTEMENT',
    IMMEUBLE = 'IMMEUBLE',
    HOTEL_PARTICULIER = 'HOTEL_PARTICULIER',
    TERRAIN = 'TERRAIN',
    LOCAL = 'LOCAL',
}

export const NATURE_BIEN_OPTIONS = [{
  label: 'Maison',
  value: NatureBien.MAISON,
}, {
  label: 'Appartement',
  value: NatureBien.APPARTEMENT,
}, {
  label: 'Immeuble',
  value: NatureBien.IMMEUBLE,
}]
