import React, { FunctionComponent } from 'react'

import styles from './AssuranceType.module.css'


export const AssuranceType: FunctionComponent<{libelle: string, organisme?: string}> = ({ libelle: typeLibelle, organisme }) => (
  <div data-testid="assurance-type" className={styles.assuranceType}>
    {typeLibelle}
    {organisme && <span className={styles.assuranceType__title}>{organisme}</span>}
  </div>
)

AssuranceType.defaultProps = {
  organisme: '',
}
