/* eslint-disable react/require-default-props */
import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

import { DATE_DD_MM_YYYY_FR, NATIONALITES_OPTIONS } from '@core/common'
import { Input } from '@mmb/ui-components'
import { Select } from '@core/partenaire-ui'

import { DEPARTEMENTS, hasErrorForName as hasError, clearEmpty } from '../../../../utils'
import { IdentitePlaceholder } from '../IdentitePlaceholder'

import styles from './IdentiteForm.module.css'
import { LabelUI, ValidationFieldError } from '../../../../model'
import { CiviliteUI, IdentiteUI } from '../../../../model/identite/IdentiteUI'
import { Civilite } from '../Civilites'

interface IdentiteFormProps {
  suffixes: LabelUI
  identite: IdentiteUI
  showErrors: boolean
  setIdentite: (identite: IdentiteUI) => void
  loading: boolean
  errors?: ValidationFieldError[]
}

export const IdentiteForm: FunctionComponent<IdentiteFormProps> = ({ suffixes, identite, setIdentite, errors = [], showErrors, loading }) => {
  const hasErrorForName = name => hasError(name, errors, showErrors)
  const setNaissance = newNaissance => {
    const naissance = identite?.naissance || {}
    setIdentite({ naissance: { ...naissance, ...newNaissance } })
  }

  const blurDate = ({ target }) => setNaissance({ date: target.value })

  const getNationaliteByValue = nationalite => NATIONALITES_OPTIONS.find(({ value }) => nationalite === value)
  const getDepartementByValue = departement => DEPARTEMENTS.find(({ value }) => departement === value)
  const updateCivilite = (civilite: CiviliteUI) => setIdentite({ civilite })

  if (loading) {
    return <IdentitePlaceholder />
  }

  return (
    <div className={styles.identiteForm}>
      <Civilite errors={errors} value={identite?.civilite} setCivilite={updateCivilite} suffixes={suffixes} showErrors={!!showErrors} />
      <Input
        aria-invalid={hasErrorForName('nom')}
        hasError={hasErrorForName('nom')}
        className={styles.identiteForm__input}
        name={`${suffixes.name}.nom`}
        value={clearEmpty(identite?.nom)?.toUpperCase() || ''}
        label={`Nom ${suffixes.label}`}
        onChange={({ target }) => setIdentite({ nom: target.value })}
        hideLabel
        type="text"
      />
      <Input
        className={styles.identiteForm__input}
        hasError={hasErrorForName('naissance.nom')}
        name={`${suffixes.name}.naissance.nom`}
        label={`Nom de naissance ${suffixes.label}`}
        value={clearEmpty(identite?.naissance?.nom).toUpperCase()}
        onChange={({ target }) => setNaissance({ nom: target.value })}
        hideLabel
        type="text"
      />
      <Input
        aria-invalid={hasErrorForName('prenom')}
        hasError={hasErrorForName('prenom')}
        className={styles.identiteForm__input}
        name={`${suffixes.name}.prenom`}
        label={`Prénom ${suffixes.label}`}
        value={clearEmpty(identite?.prenom)}
        onChange={({ target }) => setIdentite({ prenom: target.value })}
        hideLabel
        type="text"
      />
      <Input
        aria-invalid={hasErrorForName('naissance.date')}
        hasError={hasErrorForName('naissance.date')}
        className={classnames(styles.identiteForm__input, styles.identiteForm__date)}
        name={`${suffixes.name}.naissance.date`}
        fieldClassName={classnames({
          [styles.identiteForm__dateInput]: false,
        })}
        label={`Date de naissance ${suffixes.label}`}
        value={clearEmpty(identite?.naissance?.date)}
        hideLabel
        onChange={blurDate}
        type="text"
        placeholder={`${DATE_DD_MM_YYYY_FR.toLowerCase()}`}
      />
      <Input
        className={styles.identiteForm__input}
        hasError={hasErrorForName('naissance.ville')}
        name={`${suffixes.name}.naissance.ville`}
        label={`Ville de naissance ${suffixes.label}`}
        value={clearEmpty(identite?.naissance?.ville)}
        onChange={({ target }) => setNaissance({ ville: target.value })}
        hideLabel
        type="text"
      />
      <Select
        hasError={hasErrorForName('naissance.departement')}
        options={DEPARTEMENTS}
        label={`Département ${suffixes.label}`}
        value={getDepartementByValue(identite?.naissance?.departement)}
        onChange={({ value }) => setNaissance({ departement: value })}
        name={`${suffixes.name}.naissance.departement`}
        hideLabel
      />
      <Select
        hasError={hasErrorForName('nationalite')}
        options={NATIONALITES_OPTIONS}
        label={`Nationalité ${suffixes.label}`}
        value={getNationaliteByValue(identite?.nationalite)}
        onChange={({ value }) => setIdentite({ nationalite: value })}
        name={`${suffixes.name}.nationalite`}
        hideLabel
        filterConfig={{ matchFrom: 'start' }}
      />
    </div>
  )
}
