import { useUtilisateurContext } from '../contexts'

/**
 * Vérifie si l'utilisateur a la permission d'effectuer une action sur une resource.
 * @param scope '*' (pas de restriction) ou une chaine de type 'resource:action'
 * @param allCabinets flag indiquant si la vérification de la permission porte sur tous les cabinets de travail de l'utilisateur (sinon il
 * s'agit du cabinet courant)
 * @returns boolean
 */
export function useHasPermission(scope: string, allCabinets: boolean = false): boolean {
  const utilisateurContext = useUtilisateurContext()
  if (!utilisateurContext.cabinets) {
    return false
  }
  if (scope === '*') {
    return true
  }
  if (allCabinets) {
    return utilisateurContext.cabinets.some(cabinet => cabinet.permissionScopes.includes(scope))
  }
  if (!utilisateurContext.idCabinetTravail) {
    return false
  }
  return Boolean(utilisateurContext.cabinets.find(cabinet => cabinet.id === utilisateurContext.idCabinetTravail)?.permissionScopes?.includes(scope))
}
