import { buildGraphqlClient as coreBuildGraphqlClient, paginationFieldPolicy } from '@core/graphql'
import { SEARCH_PERSONNES_QUERY_NAME } from '@core/partenaire-users'
import { SEARCH_DOSSIERS_QUERY_NAME } from '@partenaire/frontend-common'

export const buildGraphqlClient = client => coreBuildGraphqlClient({
  typePolicies: {
    partenaire_Dossier: {
      fields: { propositionCredit: { merge: true } },
    },
    // partenaire_CabinetPersonne : représente le rôle d'une personne sur un cabinet, on ajoute le rôle à l'id pour le cache
    partenaire_CabinetPersonne: { keyFields: ['id', 'role'] },
    Query: {
      fields: {
        [SEARCH_PERSONNES_QUERY_NAME]: paginationFieldPolicy,
        [SEARCH_DOSSIERS_QUERY_NAME]: paginationFieldPolicy,
      },
    },
  },
}, true, client)
