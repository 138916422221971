import { useEffect, useState } from 'react'
import { gql, useMutation } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../Contexts/SaisieContext'
import { SaisiePropsUI } from '../../model'

export const CREATE_DOSSIER = gql`
  mutation createDossier($gamme: partenaire_Gamme!, $agence: String!, $hasCoEmprunteur: Boolean) {
    partenaire_createDossier(gamme: $gamme, agence: $agence, hasCoEmprunteur: $hasCoEmprunteur) {
      id
      demande {
        id
      }
      emprunteurs {
        id
      }
    }
  }
`

export function usePreSaisie() {
  const navigate = useNavigate()
  const { saisieUI, updateSaisie } = useSaisie()
  const { props: { gamme, agence, hasCoEmprunteur } } = saisieUI
  const [hasErrorAgence, setHasErrorAgence] = useState(false)
  const [createDossier, { data, loading, error }] = useMutation(CREATE_DOSSIER, {
    variables: { gamme, agence: agence?.codeApr, hasCoEmprunteur },
  })

  useEffect(() => {
    if (!error && !loading && data) {
      const { id: dossierId, demande: { id: demandeId }, emprunteurs = [] } = data.partenaire_createDossier
      updateSaisie({
        dossierId,
        demandeId,
        emprunteurId: emprunteurs[0]?.id,
        coEmprunteurId: hasCoEmprunteur ? emprunteurs[1]?.id : undefined,
      } as SaisiePropsUI)
      navigate(`/app/dossiers/saisie/${dossierId}`)
    }
  }, [loading, error, data])

  const createDossierForAgence = () => {
    if (!agence?.codeApr) {
      setHasErrorAgence(true)
      return
    }
    setHasErrorAgence(false)
    createDossier()
  }
  const setAgence = (newAgence: any) => {
    setHasErrorAgence(false)
    updateSaisie({ agence: newAgence } as SaisiePropsUI)
  }

  return {
    loading,
    error,
    hasErrorAgence,
    setAgence,
    createDossier: createDossierForAgence,
  }
}
