import { useEffect, useState } from 'react'
import { gql, useQueryWithError, DO_NOT_CHECK_CACHE } from '@core/graphql'
import { ColumnName, DossierResume } from '@partenaire/common'
import { SEARCH_DOSSIERS_QUERY_NAME } from '@partenaire/frontend-common'
import { PageRequest, PermissionScope } from '@core/common'
import { useHasPermission } from '@core/partenaire-common'

export const GET_ACCUEIL_DOSSIERS = gql`
query getAccueilDossiers($pageRequest: partenaire_PageRequestInput, $agences: [String]) {
  ${SEARCH_DOSSIERS_QUERY_NAME}(pageRequest: $pageRequest, agences: $agences) {
    elements {
      id
      reference
      emprunteurs {
        id
        identite {
          nom
          prenom
        }
      }
      gamme
      statut
      dateStatut
      montantFinance
   }
  }
}
`

const GET_AGENCES = gql`
query getAgencesReference {
  partenaire_agences {
    referenceExterne
  }
}
`

export function useAccueilDossiers() : { dossiers: DossierResume[], loading: boolean } {
  const hasPermissionDossierReadAll = useHasPermission(
    PermissionScope.DOSSIER_READ_ALL,
  )
  const [agences, setAgences] = useState([])
  const { data: agenceData, loading: agenceLoading } = useQueryWithError(GET_AGENCES, { notifyOnNetworkStatusChange: true })

  useEffect(() => {
    if (hasPermissionDossierReadAll) {
      setAgences([])
      return
    }

    if (agenceLoading) {
      return
    }
    setAgences(agenceData?.partenaire_agences?.map(agence => agence.referenceExterne))
  }, [agenceData])


  const pageRequest: PageRequest<string> = {
    pageNumber: 1,
    pageSize: 5,
    sortBy: ColumnName.DATE_STATUT,
    descendingOrder: true,
  }

  const {
    data = {},
    loading,
  } = useQueryWithError(GET_ACCUEIL_DOSSIERS, {
    notifyOnNetworkStatusChange: true,
    variables: { pageRequest, agences: agenceLoading ? undefined : agences },
    ...DO_NOT_CHECK_CACHE,
  })

  return {
    dossiers: data?.partenaire_dossiers?.elements ?? [],
    loading,
  }
}
