import React, { useRef } from 'react'
import classnames from 'classnames'
import { string } from 'prop-types'

import { Card, ToggleButton } from '@mmb/ui-components'
import { FormHeader } from '@core/partenaire-ui'
import { InputMontant } from '@partenaire/frontend-ui'

import { hasErrorForName as hasError, useDelayedFormBlur, clearEmpty, parseMontant } from '../../utils'

import { useMontage, MONTAGE_PALIERS } from './Montage.hooks'
import { MontagePlaceholder } from './MontagePlaceholder/MontagePlaceholder'
import { SaisieErrorMessages } from '../SaisieErrorMessages'
import styles from './Montage.module.css'
import { EntryFormStateName } from '../FormState/EntryFormState'

Montage.propTypes = {
  className: string,
}

Montage.defaultProps = {
  className: '',
}


export function Montage({ className }) {
  const {
    demande,
    changeDemande,
    changeAmortissement,
    loading, handleSubmit,
    hasTypeAmortissement,
    errors,
    errorMessages,
    showErrors,
  } = useMontage()

  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)
  const hasErrorForName = name => hasError(name, errors.globalErrors, showErrors)
  return (
    <div id={`${EntryFormStateName.MONTAGE}`}>
      <Card className={className}>
        <FormHeader>Montage</FormHeader>
        {loading
          ? <MontagePlaceholder />
          : (
            <form
              data-testid="montage-form"
              ref={formRef}
              onSubmit={handleSubmit}
              className={styles.montage}
              onBlur={onBlur}
              onFocus={onFocus}
            >
              <InputMontant
                aria-invalid={hasErrorForName('dureeFinancement')}
                hasError={hasErrorForName('dureeFinancement')}
                className={styles.montage__content}
                labelClassName={classnames(styles.montage__label, styles.montage__labelMontant)}
                fieldClassName={styles.montage__input}
                name="dureeFinancement"
                label="Durée"
                unit="mois"
                onChange={({ target }) => changeDemande({ dureeFinancement: parseMontant(target.value) })}
                value={clearEmpty(demande?.dureeFinancement)}
                hideLabel={false}
              />

              <InputMontant
                aria-invalid={hasErrorForName('fraisMandat')}
                hasError={hasErrorForName('fraisMandat')}
                className={styles.montage__content}
                labelClassName={classnames(styles.montage__label, styles.montage__labelMontant)}
                fieldClassName={styles.montage__input}
                name="fraisMandat"
                label="Frais de mandat"
                unit="€"
                onChange={({ target }) => changeDemande({ fraisMandat: parseMontant(target.value) })}
                value={clearEmpty(demande?.fraisMandat)}
                hideLabel={false}
              />

              <InputMontant
                aria-invalid={hasErrorForName('apport')}
                hasError={hasErrorForName('apport')}
                className={styles.montage__content}
                labelClassName={classnames(styles.montage__label, styles.montage__labelMontant)}
                fieldClassName={styles.montage__input}
                name="apport"
                label="Apport personnel"
                unit="€"
                onChange={({ target }) => changeDemande({ apport: parseMontant(target.value) })}
                value={demande?.apport ?? 0}
                hideLabel={false}
              />

              <div className={styles.montage__content}>
                <p className={styles.montage__label}>Besoin de trésorerie</p>
                <div className={styles.montage__tresoreries}>
                  <InputMontant
                    labelClassName={styles.montage__labelTresorerie}
                    fieldClassName={styles.montage__input}
                    hasError={hasErrorForName('tresorerie') || hasErrorForName('confort')}
                    name="confort"
                    label="Confort"
                    unit="€"
                    onChange={({ target }) => changeDemande({ tresorerie: { ...demande?.tresorerie, confort: parseMontant(target.value) } })}
                    value={clearEmpty(demande?.tresorerie?.confort)}
                    hideLabel={false}
                  />

                  <InputMontant
                    labelClassName={styles.montage__labelTresorerie}
                    fieldClassName={styles.montage__input}
                    hasError={hasErrorForName('tresorerie') || hasErrorForName('travaux')}
                    name="travaux"
                    label="Travaux"
                    unit="€"
                    onChange={({ target }) => changeDemande({ tresorerie: { ...demande?.tresorerie, travaux: parseMontant(target.value) } })}
                    value={clearEmpty(demande?.tresorerie?.travaux)}
                    hideLabel={false}
                  />

                  <InputMontant
                    labelClassName={styles.montage__labelTresorerie}
                    fieldClassName={styles.montage__input}
                    hasError={hasErrorForName('tresorerie') || hasErrorForName('autre')}
                    name="autre"
                    label="Autre"
                    unit="€"
                    onChange={({ target }) => changeDemande({ tresorerie: { ...demande?.tresorerie, autre: parseMontant(target.value) } })}
                    value={clearEmpty(demande?.tresorerie?.autre)}
                    hideLabel={false}
                  />
                </div>
              </div>
              <div className={styles.montage__content}>
                <p className={styles.montage__label}>Type d'amortissement</p>
                <div className={styles.montage__palier}>
                  {MONTAGE_PALIERS.map(palier => {
                    const name = `palier.${palier.value}`
                    return (
                      <ToggleButton
                        {...palier}
                        name={name}
                        hasError={hasErrorForName('amortissement')}
                        className={classnames(styles.montage__palier__option, {
                          [styles.montage__palier__optionChecked]: hasTypeAmortissement(palier.value),
                        })}
                        labelClassName={styles.montage__label_palier}
                        onChange={() => changeAmortissement(palier.value)}
                        checked={hasTypeAmortissement(palier.value)}
                        key={palier.value}
                        aria-label={`palier - ${palier.label}`}
                      />
                    )
                  })}
                </div>
              </div>
            </form>
          )}
        {showErrors && (<SaisieErrorMessages
          data-testid="montage__errors"
          errors={errorMessages}
        />)}
      </Card>
    </div>
  )
}
