import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { gql, useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../../Contexts'
import { useHandleSubmit, useValidation } from '../../FormState/form.state.hooks'
import { EntryFormStateName } from '../../FormState/EntryFormState'

export const GET_ADRESSES_DOSSIER = gql`
query getAdresseDossier($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    emprunteurs {
      id
      adresse {
        statut
        numero
        voie
        complement
        lieuDit
        codePostal
        ville
      }
    }
  }
}
`

export const UPDATE_ADRESSE = gql`
mutation updateAdresse($dossierId: ID!, $personneId: ID!, $adresse: partenaire_AdresseInput) {
  partenaire_updateAdresse(id: $dossierId, personneId: $personneId, adresse: $adresse) {
    id
    adresse {
      statut
      numero
      voie
      complement
      lieuDit
      codePostal
      ville
    }
  }
}
`

const initAdresse = {
  statut: '',
  complement: '',
  numero: '',
  voie: '',
  lieuDit: '',
  codePostal: '',
  ville: '',
}

const formatAdresse = ({ __typename, ...adresse }) => ({
  ...adresse,
})

const hasSameAdresse = (
  { complement: emprunteurComplement,
    numero: emprunteurNumero,
    voie: emprunteurVoie,
    lieuDit: emprunteurLieuDit,
    codePostal: emprunteurCodePostal,
    ville: emprunteurVille,
  },
  { complement: coEmprunteurComplement,
    numero: coEmprunteurNumero,
    voie: coEmprunteurVoie,
    lieuDit: coEmprunteurLieuDit,
    codePostal: coEmprunteurCodePostal,
    ville: coEmprunteurVille,
  },
) => {
  if (emprunteurComplement !== coEmprunteurComplement) return false
  if (emprunteurNumero !== coEmprunteurNumero) return false
  if (emprunteurVoie !== coEmprunteurVoie) return false
  if (emprunteurLieuDit !== coEmprunteurLieuDit) return false
  if (emprunteurCodePostal !== coEmprunteurCodePostal) return false
  if (emprunteurVille !== coEmprunteurVille) return false
  return true
}

export function useAdresse() {
  const navigate = useNavigate()
  const { saisieUI: { props: { dossierId, emprunteurId, coEmprunteurId, hasCoEmprunteur, gamme } } } = useSaisie()
  const [emprunteurAdresse, setEmprunteurAdresse] = useState(initAdresse)
  const [sameAdresseAsEmprunteur, setSameAdresseAsEmprunteur] = useState(false)
  const [coEmprunteurAdresse, setCoEmprunteurAdresse] = useState(initAdresse)
  const [loadingAdresseUI, setLoadingAdresseUI] = useState(true)
  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.ADRESSE)
  const { data, loading: queryLoading } = useQueryRedirectError(
    GET_ADRESSES_DOSSIER,
    navigate,
    { fetchPolicy: 'network-only', variables: { id: dossierId } },
  )
  const [updateAdresse, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_ADRESSE)
  const loading = loadingAdresseUI || mutationLoading

  const { errors, showErrors } = useValidation(EntryFormStateName.ADRESSE, loading, { emprunteurAdresse, coEmprunteurAdresse, hasCoEmprunteur, gamme })

  useEffect(() => {
    if (!queryLoading) {
      // eslint-disable-next-line camelcase
      const [emprunteurData, coEmprunteurData] = data?.partenaire_dossierEnCours?.emprunteurs || []
      let formatedEmprunteurAdresse: any = {}
      let formatedCoEmprunteurAdresse: any = {}

      if (emprunteurData?.adresse) {
        formatedEmprunteurAdresse = formatAdresse(emprunteurData.adresse)
        setEmprunteurAdresse(formatedEmprunteurAdresse)
      }

      if (coEmprunteurData?.adresse) {
        formatedCoEmprunteurAdresse = formatAdresse(coEmprunteurData.adresse)
        setCoEmprunteurAdresse(formatedCoEmprunteurAdresse)
      } else if (hasCoEmprunteur) {
        formatedCoEmprunteurAdresse = formatAdresse(formatedEmprunteurAdresse)
        setCoEmprunteurAdresse(formatedCoEmprunteurAdresse)
      }

      setSameAdresseAsEmprunteur(hasSameAdresse(formatedEmprunteurAdresse, formatedCoEmprunteurAdresse))
      setLoadingAdresseUI(false)
    }
  }, [queryLoading])

  useEffect(() => {
    if ((!isEmpty(emprunteurAdresse) && hasSameAdresse(emprunteurAdresse, coEmprunteurAdresse)) ||
    (isEmpty(emprunteurAdresse) && isEmpty(coEmprunteurAdresse))) {
      setSameAdresseAsEmprunteur(true)
    }
  }, [coEmprunteurAdresse, emprunteurAdresse, setSameAdresseAsEmprunteur])

  useEffect(() => {
    if (sameAdresseAsEmprunteur) {
      setCoEmprunteurAdresse(emprunteurAdresse)
    }
  }, [emprunteurAdresse, sameAdresseAsEmprunteur])

  const updateAdresses = async () => {
    await updateAdresse({ variables: { dossierId, personneId: emprunteurId, adresse: emprunteurAdresse } })
    if (!hasCoEmprunteur) return
    const updatedCoEmprunteurAdresse = sameAdresseAsEmprunteur ? emprunteurAdresse : { ...coEmprunteurAdresse, statut: emprunteurAdresse?.statut }
    await updateAdresse({ variables: { dossierId, personneId: coEmprunteurId, adresse: updatedCoEmprunteurAdresse } })
  }

  const handleSubmit = async event => doHandleSubmit(updateAdresses, event)

  return {
    loading,
    error: mutationError,
    emprunteurAdresse,
    coEmprunteurAdresse,
    sameAdresseAsEmprunteur,
    emprunteurErrors: errors.emprunteurErrors,
    coEmprunteurErrors: errors.coEmprunteurErrors,
    errorMessages: errors.globalErrors,
    showErrors,
    handleSubmit,
    changeEmprunteurAdresse: newState => setEmprunteurAdresse(state => ({ ...state, ...newState })),
    changeCoEmprunteurAdresse: newState => setCoEmprunteurAdresse(state => ({ ...state, ...newState })),
    setSameAdresseAsEmprunteur,
  }
}
