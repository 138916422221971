import React, { useContext } from 'react'
import styles from './ToPrintDocument.module.css'
import { FormStateContext } from '../../Contexts/FormStateContext'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { NATURE_CHARGE_LABEL_MAP } from '../../../model/charges/ChargeOptionUI'

/**
 * Contenu de dossier à imprimer ou à télécharger
 */
let revenuTotal = 0
let chargesTotal = 0

function RevenusWithOneEmprunteur(revenus) {
  revenuTotal = revenus.list.map(rev => (rev.montant ?? 0)).reduce((total, courant) => total + courant, 0)

  return (
    <div>
      {revenus.list.map(revenu => (
        <div className={styles.printDocument__column}>
          {/* column 1 */}
          <div className={styles.printDocument__column__titles__withEmprunteur}>
            <p className={styles.printDocument__cell}>Type de revenu</p>
            <p className={styles.printDocument__cell}>Montant</p>
          </div>
          <div className={styles.printDocument__column__answer}>
            <div className={styles.printDocument__column__answer__text}>
              {/* column 2 */}
              <p className={styles.printDocument__header}> Emprunteur 1</p>
              <p className={styles.printDocument__cell}>{revenu.type} </p>
              <p className={styles.printDocument__cell}>{revenu.montant}</p>
            </div>
          </div>
        </div>
      ),
      )}
    </div>
  )
}

function RevenusWithTwoEmprunteurs(revenus) {
  const emprunteurRevenus = revenus.emprunteurRevenus.list
  const coEmprunteurRevenus = revenus.coEmprunteurRevenus.list
  const numberMaxLines = Math.max(emprunteurRevenus.length, coEmprunteurRevenus.length)

  revenuTotal = [...emprunteurRevenus, ...coEmprunteurRevenus].map(rev => (rev.montant ?? 0)).reduce((total, courant) => total + courant, 0)

  return (
    <>
      {new Array<number>(numberMaxLines).fill(0).map((_, index) => (
        <div>
          <p className={styles.printDocument__cell}>
            <span className={styles.printDocument__inLines__column__1} />
            <span className={styles.printDocument__inLines__column__2__title}>{emprunteurRevenus[index] ? 'Emprunteur 1' : ''}</span>
            <span className={styles.printDocument__inLines__column__3__title}>{coEmprunteurRevenus[index] ? 'Emprunteur 2' : ''}</span>
          </p>
          <p className={styles.printDocument__cell}>
            <span className={styles.printDocument__inLines__column__1}>Type de revenu</span>
            <span className={styles.printDocument__inLines__column__2}>{emprunteurRevenus[index]?.type ?? ''}</span>
            <span className={styles.printDocument__inLines__column__3}>{coEmprunteurRevenus[index]?.type ?? ''}</span>
          </p>
          <p className={styles.printDocument__cell}>
            <span className={styles.printDocument__inLines__column__1}>Montant</span>
            <span className={styles.printDocument__inLines__column__2}>{emprunteurRevenus[index]?.montant ?? ''}</span>
            <span className={styles.printDocument__inLines__column__3}>{coEmprunteurRevenus[index]?.montant ?? ''}</span>
          </p>
        </div>
      ))}
    </>
  )
}

function BaisseRevenus({ emprunteurRevenus, coEmprunteurRevenus }: any) {
  if (emprunteurRevenus.baisseRevenu && coEmprunteurRevenus.baisseRevenu) {
    return <BaisseRevenuWithTwoEmprunteurs emprunteurRevenus={emprunteurRevenus} coEmprunteurRevenus={coEmprunteurRevenus} />
  }

  if (emprunteurRevenus.baisseRevenu || coEmprunteurRevenus.baisseRevenu) {
    return <BaisseRevenuSingleOneEmprunteur emprunteurRevenus={emprunteurRevenus} coEmprunteurRevenus={coEmprunteurRevenus} />
  }

  return null
}

function BaisseRevenuWithTwoEmprunteurs({ emprunteurRevenus, coEmprunteurRevenus }: any) {
  return (
    <div className={styles.printDocument__column}>
      {/* column 1 */}
      {BaisseRevenuColumn1()}
      <div className={styles.printDocument__column__answer}>
        <div className={styles.printDocument__column__answer__text}>
          {/* column 2 */}
          <p className={styles.printDocument__header}> Emprunteur 1</p>
          <p className={styles.printDocument__cell}>Baisse de revenus à prévoir</p>
          <p className={styles.printDocument__cell}>{emprunteurRevenus.baisseRevenu.montant} </p>
          <p className={styles.printDocument__cell}>{emprunteurRevenus.baisseRevenu.debut}</p>
        </div>
      </div>
      <div className={styles.printDocument__column__answer}>
        {/* column 3 */}
        <p className={styles.printDocument__header}> Emprunteur 2</p>
        <p className={styles.printDocument__cell}>Baisse de revenus à prévoir</p>
        <p className={styles.printDocument__cell}>{coEmprunteurRevenus.baisseRevenu.montant} </p>
        <p className={styles.printDocument__cell}>{coEmprunteurRevenus.baisseRevenu.debut}</p>
      </div>
    </div>
  )
}

function BaisseRevenuColumn1() {
  return (
    <div className={styles.printDocument__column__titles__withEmprunteur}>
      <p className={styles.printDocument__cell} />
      <p className={styles.printDocument__cell}>Montant prévisionnel</p>
      <p className={styles.printDocument__cell}>Date de début</p>
    </div>
  )
}

function BaisseRevenuSingleOneEmprunteur({ emprunteurRevenus, coEmprunteurRevenus }: any) {
  return (
    <div className={styles.printDocument__column}>
      {/* column 1 */}
      {BaisseRevenuColumn1()}
      {emprunteurRevenus.baisseRevenu ? BaisseRevenuEmprunteur(emprunteurRevenus) : ''}
      {coEmprunteurRevenus.baisseRevenu ? BaisseRevenuCoemprunteur(coEmprunteurRevenus) : ''}
    </div>
  )
}

function BaisseRevenuEmprunteur(revenu) {
  return (
    <div className={styles.printDocument__column__answer}>
      <div className={styles.printDocument__column__answer__text}>
        {/* column 2 */}
        <p className={styles.printDocument__header}> Emprunteur 1</p>
        <p className={styles.printDocument__cell}>Baisse de revenus à prévoir</p>
        <p className={styles.printDocument__cell}>{revenu.baisseRevenu.montant} </p>
        <p className={styles.printDocument__cell}>{revenu.baisseRevenu.debut}</p>
      </div>
    </div>
  )
}


function BaisseRevenuCoemprunteur(revenu) {
  return (
    <div className={styles.printDocument__only__column__3}>
      {/* column 3 */}
      <p className={styles.printDocument__header}> Emprunteur 2</p>
      <p className={styles.printDocument__cell}>Baisse de revenus à prévoir</p>
      <p className={styles.printDocument__cell}>{revenu.baisseRevenu.montant} </p>
      <p className={styles.printDocument__cell}>{revenu.baisseRevenu.debut}</p>
    </div>
  )
}

function ChargesWithOneEmprunteur(charges) {
  chargesTotal = charges.list.map(chrg => (chrg.montant ?? 0)).reduce((total, courant) => total + courant, 0)

  return (
    <div>
      {charges.list.map(charge => (
        <div className={styles.printDocument__column}>
          {/* column 1 */}
          <div className={styles.printDocument__column__titles}>
            <p className={styles.printDocument__cell}>Nature de charge</p>
            <p className={styles.printDocument__cell}>Montant</p>
          </div>
          <div className={styles.printDocument__column__answer}>
            <div className={styles.printDocument__column__answer__text}>
              {/* column 2 */}
              <p className={styles.printDocument__header}> Emprunteur 1</p>
              <p className={styles.printDocument__cell}> {NATURE_CHARGE_LABEL_MAP.get(charge.nature)} </p>
              <p className={styles.printDocument__cell}>{charge.montant}</p>
            </div>
          </div>
        </div>
      ),
      )}
    </div>
  )
}
function ChargesWithTwoEmprunteurs(charges) {
  const emprunteurCharges = charges.emprunteursCharges.list
  const coEmprunteurCharges = charges.coEmprunteursCharges.list
  const numberMaxLines = Math.max(emprunteurCharges.length, coEmprunteurCharges.length)

  chargesTotal = [...emprunteurCharges, ...coEmprunteurCharges].map(chrg => (chrg.montant ?? 0)).reduce((total, courant) => total + courant, 0)

  const elements: any[] = []

  for (let i = 0; i < numberMaxLines; i++) {
    elements.push(
      <div>
        <p className={styles.printDocument__cell}>
          <span className={styles.printDocument__inLines__column__1} />
          <span className={styles.printDocument__inLines__column__2__title}>{emprunteurCharges[i] ? 'Emprunteur 1' : ''}</span>
          <span className={styles.printDocument__inLines__column__3__title}>{coEmprunteurCharges[i] ? 'Emprunteur 2' : ''}</span>
        </p>
        <p className={styles.printDocument__cell}>
          <span className={styles.printDocument__inLines__column__1}>Nature de charge</span>
          <span className={styles.printDocument__inLines__column__2}>{emprunteurCharges[i]?.nature ?
            NATURE_CHARGE_LABEL_MAP.get(emprunteurCharges[i]?.nature) : ''}
          </span>
          <span className={styles.printDocument__inLines__column__3}>{coEmprunteurCharges[i]?.nature ?
            NATURE_CHARGE_LABEL_MAP.get(coEmprunteurCharges[i]?.nature) : ''}
          </span>
        </p>
        <p className={styles.printDocument__cell}>
          <span className={styles.printDocument__inLines__column__1}>Montant</span>
          <span className={styles.printDocument__inLines__column__2}>{emprunteurCharges[i]?.montant ?? ''}</span>
          <span className={styles.printDocument__inLines__column__3}>{coEmprunteurCharges[i]?.montant ?? ''}</span>
        </p>
      </div>,
    )
  }
  return elements
}

export function ToPrintFinance() {
  const formState = useContext(FormStateContext)
  const { value: revenu } = formState.getEntryFormState(EntryFormStateName.REVENU)
  const allocFam = revenu?.revenusUI?.getMontantAllocationFamiliales()

  const emprunteurRevenus = revenu.revenusUI.emprunteurRevenus
  const coEmprunteurRevenus = revenu.revenusUI.coEmprunteurRevenus
  const { value: charge } = formState.getEntryFormState(EntryFormStateName.CHARGE)

  return (
    <div>
      <h1 className={styles.printDocument__headband}>
        <div className={styles.printDocument__headband__text}>
          SITUATION FINANCIERE
        </div>
      </h1>
      <div className={styles.printDocument__subtitle}>
        <h1>Revenus (€/mois) </h1>
        <hr />
      </div>
      {revenu.revenusUI.hasCoEmprunteur ? RevenusWithTwoEmprunteurs(revenu.revenusUI) : RevenusWithOneEmprunteur(emprunteurRevenus)}

      <BaisseRevenus emprunteurRevenus={emprunteurRevenus} coEmprunteurRevenus={coEmprunteurRevenus} />

      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Allocations familiales</p>
          <p className={styles.printDocument__cell}>Total des revenus du foyer</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__cell}>{allocFam} </p>
            <p className={styles.printDocument__cell}>{revenuTotal + allocFam}</p>
          </div>
        </div>
      </div>

      <div className={styles.printDocument__subtitle}>
        <h1>Charges (€/mois)</h1>
        <hr />
      </div>
      {charge.chargesUI.hasCoEmprunteur ? ChargesWithTwoEmprunteurs(charge.chargesUI) : ChargesWithOneEmprunteur(charge.chargesUI.emprunteursCharges)}

      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles}>
          <p className={styles.printDocument__cell}>Total des charges du foyer</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__cell}>{chargesTotal} </p>
          </div>
        </div>
      </div>
    </div>
  )
}
