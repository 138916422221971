import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import { Announcement } from '@mmb/ui-components'
import { Feature } from '../Izanami'
import styles from './BandeauInfo.module.css'

BandeauInfo.propTypes = {
  className: string,
  message: string,
}

BandeauInfo.defaultProps = {
  className: '',
  message: '',
}

/**
 * Bandeau avec une icone auquel on peut passer un message, affiché avec la feature izanami bandeau-info
 * Un exemple d'utilisation https://www.figma.com/file/jjlNBoxh7fBqGIc0rkFk7Q/Error?node-id=505%3A1976
 */
export function BandeauInfo({ className, message }) {
  return (
    <Feature name="bandeau-info">
      <div className={classNames(styles.bandeau_info, className)}>
        <Announcement className={styles.bandeau_info__icon} />
        {message}
      </div>
    </Feature>
  )
}
