import React, { FunctionComponent } from 'react'
import classnames from 'classnames'

import { capitalize } from '@core/common'
import { Avatar, HourglassEmpty, CheckCircle, Clock } from '@mmb/ui-components'

import styles from './PersonneAssurance.module.css'
import { AssuranceUI, SituationAdhesionUI } from '../../../../model/assurance/AssuranceUI'

interface PersonneAssuranceProps {
  assuranceUI: AssuranceUI
  className: string
  hideStatus?: boolean
}

export const PersonneAssurance: FunctionComponent<PersonneAssuranceProps> = ({ assuranceUI, className, hideStatus }) => {
  const isEnCours: boolean = assuranceUI.props.situationAdhesion === SituationAdhesionUI.EN_COURS
  let icon = <Clock dashed className={styles.personneAssurance__clock} />
  if (isEnCours) {
    icon = <HourglassEmpty className={styles.personneAssurance__hourglass} />
  } else if (assuranceUI.isValid()) {
    icon = <CheckCircle className={styles.personneAssurance__checkCircle} />
  }
  return (
    <div className={classnames(styles.personneAssurance, className)}>
      <Avatar className={styles.personneAssurance__avatar} />
      <div className={styles.personneAssurance__informations}>
        {capitalize(assuranceUI.props.nom)}
        {!hideStatus && (
        <div
          className={classnames(styles.personneAssurance__status, {
            [styles.personneAssurance__enAttente]: isEnCours,
            [styles.personneAssurance__valide]: assuranceUI.isValid(),
          })}
        >
          {icon}{assuranceUI.getLibelleSituationAdhesion()}{assuranceUI.showDateSituationAdhesion() && ` le ${assuranceUI.props.dateSituationAdhesion}`}
        </div>
        )}
      </div>
    </div>
  )
}
PersonneAssurance.defaultProps = {
  hideStatus: false,
}

