import { useQueryWithError, gql } from '@core/graphql'
import { RubriqueUI } from '../model/OutilsUI'

const GET_RUBRIQUES = gql`
query getRubriques {
  outils_rubriques {
      id
      nom
  }
}
`

export function useOutilsRubriques(): {loading: boolean, rubriques: RubriqueUI[]} {
  const { loading, data } = useQueryWithError(GET_RUBRIQUES, {})
  return {
    loading,
    rubriques: data?.outils_rubriques || [],
  }
}
