import React, { useContext } from 'react'
import { func, node, string } from 'prop-types'

import { Card, Button, Cross } from '@mmb/ui-components'
import { modalContext } from '@core/partenaire-ui'

import styles from './Modal.module.css'

Modal.propTypes = {
  children: node,
  header: node,
  submitLabel: string,
  onValidate: func.isRequired,
  onClose: func,
}

Modal.defaultProps = {
  children: null,
  header: null,
  submitLabel: '',
  onClose: () => {},
}

export function Modal({ children, submitLabel, onValidate, header, onClose }) {
  const { setOpen } = useContext(modalContext)
  const close = () => {
    onClose()
    setOpen(false)
  }

  return (
    <Card className={styles.modal__card}>
      {header}

      <button
        onClick={close}
        aria-label="Fermer"
        className={styles.modal__close}
      >
        <Cross />
      </button>

      <form
        className={styles.modal__form}
        onSubmit={e => {
          e.preventDefault()
          onValidate()
        }}
      >
        {children}

        <div className={styles.modal__buttons}>
          <Button
            type="button"
            outline
            className={styles.modal__cancel}
            onClick={close}
          >
            Annuler
          </Button>
          <Button type="submit">
            {submitLabel}
          </Button>
        </div>
      </form>
    </Card>
  )
}
