import { useState, useEffect, FormEvent } from 'react'
import { gql, useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../../../Contexts'
import { useHandleSubmit, useValidation } from '../../../FormState/form.state.hooks'
import { buildActiviteValidationError } from '../../../../utils'
import { EntryFormStateName } from '../../../FormState/EntryFormState'
import { ActivitesUI } from '../../../../model/activite/ActivitesUI'
import { ActiviteListUI } from '../../../../model/activite/ActiviteListUI'
import { ActiviteFormUI } from '../../../../model/activite/ActiviteFormUI'
import { fromGraphql, toGraphQL } from './mappers'
import { ErrorMessage } from '../../../SaisieErrorMessages'
import { useGamme } from '../../../Gamme'

export const GET_ACTIVITES_DOSSIER = gql`
query getActivitesDossier($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    emprunteurs {
      id
      activites(dossierId: $id) {
        id
        position
        type
        catSocio
        profession
        typeContrat
        debut
        isPrincipale
      }
    }
  }
}
`
export const CREATE_ACTIVITE_DOSSIER = gql`
  mutation createActivite($id: ID!, $personneId: ID!, $activite: partenaire_ActiviteInput! ) {
    partenaire_createActivite(id: $id, personneId: $personneId, activite: $activite){
      id
      position
      type
      catSocio
      profession
      typeContrat
      debut
      isPrincipale
    }
  }
`
export const UPDATE_ACTIVITE_DOSSIER = gql`
  mutation updateActivite($id: ID!, $personneId: ID!, $activite: partenaire_ActiviteInput! ) {
    partenaire_updateActivite(id: $id, personneId: $personneId, activite: $activite){
      id
      position
      type
      catSocio
      profession
      typeContrat
      debut
      isPrincipale
    }
  }
`
const DELETE_ACTIVITE_DOSSIER = gql`
  mutation deleteActivite($id: ID!, $personneId: ID!, $activite: partenaire_ActiviteInput!) {
  partenaire_deleteActivite(id: $id, personneId: $personneId, activite: $activite) {
    id
  }
}
`

export function useActivites() {
  const navigate = useNavigate()
  const { saisieUI } = useSaisie()
  const { props: { dossierId, emprunteurId, coEmprunteurId, hasCoEmprunteur } }
    = saisieUI as { props: { dossierId, emprunteurId: string, coEmprunteurId: string, hasCoEmprunteur } }
  const [activitesUI, setActivitesUI] = useState(ActivitesUI.defaultActivitesUI(hasCoEmprunteur))
  const [loadingActivitesUI, setLoadingActivitesUI] = useState(true)
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([])
  const gammeProps = useGamme()
  useEffect(() => { activitesUI.withUpdateState(setActivitesUI) }, [activitesUI])

  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.ACTIVITE)

  const { data, loading: queryLoading }
    = useQueryRedirectError(GET_ACTIVITES_DOSSIER, navigate, { fetchPolicy: 'network-only', variables: { id: dossierId } })
  const [updateActivite, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_ACTIVITE_DOSSIER, { refetchQueries: [{ query: GET_ACTIVITES_DOSSIER, variables: { id: dossierId } }] })
  const [createActivite, { loading: createLoading, error: createError }] =
    useMutation(CREATE_ACTIVITE_DOSSIER, { refetchQueries: [{ query: GET_ACTIVITES_DOSSIER, variables: { id: dossierId } }] })
  const [deleteActivite, { loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_ACTIVITE_DOSSIER, { refetchQueries: [{ query: GET_ACTIVITES_DOSSIER, variables: { id: dossierId } }] })

  const loading = loadingActivitesUI || updateLoading || createLoading || deleteLoading
  const { errors, showErrors } = useValidation(EntryFormStateName.ACTIVITE, loading, { activitesUI, isSecured: saisieUI?.isSecured() })

  useEffect(() => {
    const allErrors = [
      ...errors.globalErrors,
      ...(errors.emprunteurErrors || []).flatMap(error => error),
      ...(errors.coEmprunteurErrors || []).flatMap(error => error),
    ]
    setErrorMessages((allErrors || []).map(e => buildActiviteValidationError(e, gammeProps)).filter(e => e))
  },
  [errors])

  useEffect(() => {
    if (!queryLoading) {
      // eslint-disable-next-line camelcase
      const [emprunteurData, coEmprunteurdata] = data?.partenaire_dossierEnCours?.emprunteurs || []

      const emprunteursActivites = new ActiviteListUI(emprunteurId, fromGraphql(emprunteurData?.activites))
      const coEmprunteursActivites = new ActiviteListUI(coEmprunteurId, fromGraphql(coEmprunteurdata?.activites))
      setActivitesUI(new ActivitesUI(emprunteursActivites, coEmprunteursActivites, hasCoEmprunteur))
      setLoadingActivitesUI(false)
    }
  }, [data, queryLoading])

  const handleActivite = (personneId, activite) => activite.id ?
    updateActivite({ variables: { id: dossierId, personneId, activite } }) :
    createActivite({ variables: { id: dossierId, personneId, activite } })

  const updatePersonneActivites = async (personneId: string, activite: ActiviteFormUI) => handleActivite(personneId, toGraphQL(activite))

  const handleEmprunteursActivites = async () => {
    await Promise.all(activitesUI.emprunteursActivites.list.map(activite => updatePersonneActivites(emprunteurId, activite)))
    if (hasCoEmprunteur) {
      await Promise.all(activitesUI.coEmprunteursActivites.list.map(activites => updatePersonneActivites(coEmprunteurId, activites)))
    }
  }

  const removeActivite = async (index: number, removeOnEmprunteur: boolean = true) => {
    const id = activitesUI.delete(index, removeOnEmprunteur)
    const personneId = removeOnEmprunteur ? emprunteurId : coEmprunteurId
    if (id) {
      await deleteActivite({ variables: { id: dossierId, personneId, activite: { id } } })
    }
  }

  const handleSubmit = async (event: FormEvent) => doHandleSubmit(handleEmprunteursActivites, event)
  const handleRemoveActivite =
    async (index: number, removeOnEmprunteur: boolean = true) => doHandleSubmit(() => removeActivite(index, removeOnEmprunteur), undefined, true)

  const error = updateError || createError || deleteError
  return ({
    loading,
    error,
    emprunteurErrors: errors.emprunteurErrors,
    coEmprunteurErrors: errors.coEmprunteurErrors,
    errorMessages,
    showErrors,
    activitesUI,
    handleSubmit,
    handleRemoveActivite,
  })
}
