export enum SignatureType {
    ELECTRONIQUE = 'ELECTRONIQUE',
    MANUSCRITE = 'MANUSCRITE',
}

export enum OffreAndAssuranceStatut {
    // statuts composés offre & assurance
    OFFRE_ET_ASSURANCE_SIGNEE = 'OFFRE_ET_ASSURANCE_SIGNEE',
    OFFRE_SIGNEE_ASSURANCE_NON_SIGNEE = 'OFFRE_SIGNEE_ASSURANCE_NON_SIGNEE',

    // status composés electronique
    OFFRE_ACCESSIBLE = 'OFFRE_ACCESSIBLE',
    OFFRE_ACCEDEE = 'OFFRE_ACCEDEE',
    OFFRE_RECEPTIONNEE = 'OFFRE_RECEPTIONNEE',
    SIGNATURE_ACCESSIBLE = 'SIGNATURE_ACCESSIBLE',
    SIGNATURE_ACCEDEE = 'SIGNATURE_ACCEDEE',
    OFFRE_DEMANDEE_MANUSCRITE = 'OFFRE_DEMANDEE_MANUSCRITE',

    // statuts signature electronique
    OFFRE_SIGNEE_ASSURANCE_ACCESSIBLE = 'OFFRE_SIGNEE_ASSURANCE_ACCESSIBLE',
    CHOIX_ASSURANCE_TRAITE = 'CHOIX_ASSURANCE_TRAITE',

    // statut offre
    OFFRE_SIGNE = 'OFFRE_SIGNE',
    OFFRE_NON_SIGNEE = 'OFFRE_NON_SIGNEE',
    OFFRE_INVALIDEE = 'OFFRE_INVALIDEE',
  }

export interface Offre {
    signatureType?: SignatureType
    statut: OffreAndAssuranceStatut
    dateCreation: Date
  }

export interface OffreEmprunteurs {
    offreEmprunteur?: Offre
    offreCoemprunteur?: Offre
  }

export interface Document {
    id: string
    filename: string
    libelle: string
}

export interface Contrat {
    documents: Document[]
}

