import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { capitalize } from 'lodash'

import { formatCondenseDate } from '@core/common'
import { getDossierRedirectPath, getStatutLabel, isDossierStatusComplete } from '@partenaire/frontend-common'
import { Montant } from '@mmb/ui-components'
import { Link } from '@core/ui'
import { DossierResume } from '@partenaire/common'

import styles from './DossierItem.module.css'
import { useAccueil } from '../Accueil.hooks'


interface DossierItemProps {
  dossier: DossierResume
  index: number
}

export const DossierItem : FunctionComponent<DossierItemProps> = ({ dossier: { id, emprunteurs, gamme, dateStatut, montantFinance, statut }, index }) => {
  const hasPermissionDossierWrite = useAccueil()
  const { nom, prenom } = emprunteurs[0]?.identite ?? {}
  const path = getDossierRedirectPath(id, statut, hasPermissionDossierWrite)
  const statutLabel = gamme && statut ? getStatutLabel(gamme, statut) : ''

  return (
    <Link
      role="row"
      aria-label={`Dossier ${nom} ${prenom}`}
      className={classnames(styles.dossierItem__line, styles.dossierItem__gamme, {
        [styles.dossierItem__lineStriped]: index % 2 !== 0,
        [styles.dossierItem__gammeSecured]: gamme === 'SECURED',
        [styles.dossierItem__gammeUnsecured]: gamme === 'UNSECURED',
      })}
      to={path}
    >
      <span role="cell" aria-label="nom">{nom?.toUpperCase() || ''}</span>
      <span role="cell" aria-label="prenom">{capitalize(prenom)}</span>
      <span role="cell" aria-label="montant emprunté" className={classnames(styles.dossierItem__bold, styles.dossierItem__montant)}>
        {(montantFinance && !Number.isNaN(montantFinance)) && (<Montant value={montantFinance} withoutDecimal wholeClassName={styles.dossierItem__whole} />)}
      </span>
      <span
        role="cell"
        aria-label="statut"
        className={classnames(styles.dossierItem__bold, {
          [styles.dossierItem__statutComplete]: isDossierStatusComplete(statut),
          [styles.dossierItem__statutIncomplete]: !isDossierStatusComplete(statut),
        })}
      >
        {capitalize(statutLabel)}
      </span>
      <span role="cell" aria-label="date dernier statut">{formatCondenseDate(dateStatut)}</span>
    </Link>
  )
}
