import React from 'react'
import { string } from 'prop-types'

import { Card } from '@mmb/ui-components'
import {
  Link,
} from '@core/ui'

import styles from '../SignUp.module.css'

SignUpConfirmation.propTypes = {
  email: string.isRequired,
}

export function SignUpConfirmation({ email }) {
  return (
    <Card>
      <p>
        <strong>Pouvez-vous confirmer votre adresse Email ?</strong>
      </p>
      <p>
        Un Email vient de vous être envoyé à l'adresse :
        <br />
        <strong>{email}</strong>
        <br />
        Cliquez sur le lien du message pour activer et protéger votre compte.
      </p>
      <p>
        Pensez à vérifier vos courriers indésirables.
      </p>
      <Link
        to="/protection-des-donnees/"
        className={styles.signUp__link}
      >
        Politique de protection de mes données personnelles
      </Link>
    </Card>
  )
}
