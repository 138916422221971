export enum NatureCharge {
    LOYER_ENFANT = 'LOYER_ENFANT',
    LOYER_RP = 'LOYER_RP',
    LOYER_AUTRE_QUE_RP = 'LOYER_AUTRE_QUE_RP',
    LOYER_FICTIF= 'LOYER_FICTIF',
    VIREMENT_RECURRENT_VERS_UN_TIERS = 'VIREMENT_RECURRENT_VERS_UN_TIERS',
    PENSION_ALIMENTAIRE = 'PENSION_ALIMENTAIRE',
    PRESTATION_COMPENSATOIRE = 'PRESTATION_COMPENSATOIRE',
    LOCATION_LONGUE_DUREE = 'LOCATION_LONGUE_DUREE',
    IMPOTS_SUR_LE_REVENU = 'IMPOTS_SUR_LE_REVENU',
    PRET_IMMOBILIER = 'PRET_IMMOBILIER',
    PRET_AUTOMOBILE = 'PRET_AUTOMOBILE',
    PRET_PERSONNEL = 'PRET_PERSONNEL',
    CREDIT_RENOUVELABLE = 'CREDIT_RENOUVELABLE',
    AUTRE_CREDIT = 'AUTRE_CREDIT',
    AUTRE = 'AUTRE',
}
