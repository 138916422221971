import { useEffect } from 'react'
import { useNavigate as useNavigateReactRouter } from 'react-router'
// eslint-disable-next-line no-restricted-imports
import { navigate as navigateGatsby } from 'gatsby'
import { isSSR } from '@core/common-frontend'
import { array, string } from 'prop-types'

export const useNavigate = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return isSSR ? navigateGatsby : useNavigateReactRouter()
}

Navigate.propTypes = {
  to: string.isRequired,
  options: array,
}

Navigate.defaultProps = {
  options: undefined,
}


/**
 * Le composant <Redirect /> de @reach/router reporte les query-params dans la route cible (en version >=1.3.4).
 * Ce comportement n'est nécessaire que lorsque la route cible répond à la même fonction métier que la route actuelle
 * (ex: nouvelle version d'une page, redirection depuis une ancienne url).
 * Pour gérer les cas de navigation "standard" de manière déclarative, il est préférable d'utiliser ce composant
 */
// eslint-disable-next-line react/function-component-definition
export function Navigate({ to, ...options }) {
  const navigate = useNavigate()
  useEffect(() => { navigate(to, options) }, [to])
  return null
}
