import React, { FunctionComponent } from 'react'

const arrows = new Map([['up', '▼'], ['down', '▲']])

export const SortArrow : FunctionComponent<any> = ({ direction, className }) => {
  return (
    <div className={className}>
      {arrows.get(direction)}
    </div>
  )
}
