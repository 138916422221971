
import { useEffect, useState } from 'react'
import { gql, useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../Contexts'
import { useGetEntityFormState, useHandleSubmit, useValidation } from '../FormState/form.state.hooks'
import { AssuranceFormUI } from '../../model'
import { useAssuranceUI } from './Assurance.ui.hooks'
import { EntryFormStateName } from '../FormState/EntryFormState'

export const GET_ASSURANCE_DOSSIER = gql`
query getAssuranceDossier($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    emprunteurs {
      id
      assurance {
        id
        type
        formule
        quotite
      }
    }
  }
}
`

export const UPDATE_ASSURANCE = gql`
  mutation updateAssurance($id: ID!, $demandeId: ID!, $personneId: ID!, $assurance: partenaire_AssuranceDemandeInput!) {
    partenaire_updateAssurance(id: $id, demandeId: $demandeId, personneId: $personneId, assurance: $assurance) {
      type
      quotite
      formule
    }
  }
`

const toModelUI = (assurance): AssuranceFormUI => !assurance ?
  new AssuranceFormUI() :
  new AssuranceFormUI({ type: assurance.type!, formule: assurance.formule, quotite: assurance.quotite, naissanceDate: assurance.naissanceDate })

const toGraphQL = (assurance: AssuranceFormUI) => ({
  type: assurance.props.type,
  formule: assurance.props.formule,
  quotite: parseFloat(assurance.props.quotite),
})


export function useAssurance() {
  const navigate = useNavigate()
  const { saisieUI: { props: { hasCoEmprunteur, dossierId, demandeId, emprunteurId, coEmprunteurId } } } = useSaisie()
  const { entryFormState: identiteState } = useGetEntityFormState(EntryFormStateName.IDENTITE)
  const { entryFormState: activiteState } = useGetEntityFormState(EntryFormStateName.ACTIVITE)
  const assuranceUI = useAssuranceUI(hasCoEmprunteur)
  const [loadingAssuranceUI, setLoadingAssuranceUI] = useState(true)

  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.ASSURANCE)

  const { data = {}, loading: queryLoading } = useQueryRedirectError(GET_ASSURANCE_DOSSIER, navigate, {
    fetchPolicy: 'network-only',
    variables: { id: dossierId },
  })
  const [updateAssuranceByDossierId, { loading: mutationLoading }] = useMutation(UPDATE_ASSURANCE, {
    refetchQueries: [{
      query: GET_ASSURANCE_DOSSIER,
      variables: { id: dossierId },
    }],
  })
  const loading = loadingAssuranceUI || mutationLoading

  const { errors, warnings, showErrors }
    = useValidation(EntryFormStateName.ASSURANCE, loading, assuranceUI.getAdditionalValues())

  useEffect(() => {
    if (!queryLoading) {
      const [emprunteurData, coEmprunteurData] = data?.partenaire_dossierEnCours?.emprunteurs || []
      assuranceUI.update(toModelUI(emprunteurData?.assurance), toModelUI(coEmprunteurData?.assurance))
      setLoadingAssuranceUI(false)
    }
  }, [data, queryLoading])

  const getAssuranceByEmprunteur = hasEmprunteur => {
    const [emprunteurData, coEmprunteurData] = data?.partenaire_dossierEnCours?.emprunteurs || []
    return hasEmprunteur ? emprunteurData?.assurance : coEmprunteurData?.assurance
  }

  const updateIfDifferentDateAssuranceFormUI = (naissanceDate, hasEmprunteur = false) => {
    assuranceUI.updateIfDifferentDateAssuranceFormUI({ naissanceDate, ...getAssuranceByEmprunteur(hasEmprunteur) }, hasEmprunteur)
  }

  const updateValidActivityTypeFormUI = (activities, hasEmprunteur = false) => {
    assuranceUI.updateValidActivityTypeFormUI({ activities, ...getAssuranceByEmprunteur(hasEmprunteur) }, hasEmprunteur)
  }

  useEffect(() => {
    updateIfDifferentDateAssuranceFormUI(identiteState?.value?.emprunteur?.naissance?.date, true)
  }, [identiteState?.value?.emprunteur?.naissance?.date, assuranceUI.emprunteurAssurance])

  useEffect(() => {
    updateIfDifferentDateAssuranceFormUI(identiteState?.value?.coEmprunteur?.naissance?.date)
  }, [identiteState?.value?.coEmprunteur?.naissance?.date, assuranceUI.coEmprunteurAssurance])

  useEffect(() => {
    updateValidActivityTypeFormUI(activiteState?.value?.activitesUI?.emprunteursActivites?.list, true)
  }, [activiteState?.value?.activitesUI?.emprunteursActivites?.list])

  useEffect(() => {
    if (activiteState?.value?.activitesUI?.coEmprunteursActivites?.personneId) {
      updateValidActivityTypeFormUI(activiteState?.value?.activitesUI?.coEmprunteursActivites?.list)
    }
  }, [activiteState?.value?.activitesUI?.coEmprunteursActivites?.list])

  const updateAssurance = async ({ assurance, personneId }) => updateAssuranceByDossierId({
    variables: {
      id: dossierId,
      demandeId,
      personneId,
      assurance,
    },
  })

  const handleSubmit = async event => doHandleSubmit(updateEmprunteursAssurance, event)

  const updateEmprunteursAssurance = async () => {
    if (emprunteurId) {
      await updateAssurance({
        personneId: emprunteurId,
        assurance: toGraphQL(assuranceUI.emprunteurAssurance),
      })
    }

    if (hasCoEmprunteur && coEmprunteurId) {
      await updateAssurance({
        personneId: coEmprunteurId,
        assurance: toGraphQL(assuranceUI.coEmprunteurAssurance),
      })
    }
  }

  return {
    loading,
    assuranceUI,
    errors,
    showErrors,
    warnings,
    handleSubmit,
  }
}
