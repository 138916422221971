import React, { useEffect, useState, createContext, useContext, FormEvent, useMemo } from 'react'

import { node } from 'prop-types'
import { gql, useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../../../Contexts'
import { useHandleSubmit, useValidation } from '../../../FormState/form.state.hooks'
import { EntryFormStateName } from '../../../FormState/EntryFormState'
import { ChargesUI } from '../../../../model/charges/ChargesUI'
import { ChargeListUI } from '../../../../model/charges/ChargeListUI'
import { ChargeFormUI } from '../../../../model/charges/ChargeFormUI'
import { ErrorMessage } from '../../../SaisieErrorMessages'
import { getChargeErrors } from '../../../../utils'

export const GET_CHARGES_DOSSIER = gql`
query getChargesDossier($id: ID!) {
  partenaire_dossierEnCours(id: $id) {
    id
    emprunteurs {
      id
      charges(dossierId: $id)  {
        id
        position
        nature
        montant
        debut
        fin
      }
    }
  }
}
`
export const CREATE_CHARGE_DOSSIER = gql`
mutation createCharge($id: ID!, $personneId: ID!, $charge: partenaire_ChargeInput!) {
  partenaire_createCharge(id: $id, personneId: $personneId, charge: $charge) {
    id
    position
    nature
    montant
  }
}
`
export const UPDATE_CHARGE_DOSSIER = gql`
  mutation updateCharge($id: ID!, $personneId: ID!, $charge: partenaire_ChargeInput! ) {
    partenaire_updateCharge(id: $id, personneId: $personneId, charge: $charge){
      id
      position
      montant
      nature
    }
  }
`
const DELETE_CHARGE_DOSSIER = gql`
mutation deleteCharge($id: ID!, $personneId: ID!, $charge: partenaire_ChargeInput!) {
  partenaire_deleteCharge(id: $id, personneId: $personneId, charge: $charge){
    id
  }
}
`

export interface ChargesContextUI {
  loading: boolean
  error: any
  errors: any
  errorMessages: ErrorMessage[]
  showErrors: boolean
  chargesUI: ChargesUI
  handleSubmit: (event: FormEvent) => void
  handleRemoveCharge: (index: number, removeOnEmprunteur: boolean) => void

}
export const ChargeContext = createContext({} as ChargesContextUI)

export function useCharges() {
  const context = useContext<ChargesContextUI>(ChargeContext)
  if (context === {} as unknown as ChargesContextUI) {
    throw new Error('useCharges must be used within a ChargesProvider')
  }
  return context
}

ChargesProvider.propTypes = {
  children: node.isRequired,
}

export function ChargesProvider({ children }) {
  const navigate = useNavigate()
  const { saisieUI: { props: { dossierId, emprunteurId, coEmprunteurId, hasCoEmprunteur } } }
    = useSaisie() as { saisieUI: { props: { dossierId, emprunteurId, coEmprunteurId, hasCoEmprunteur } } }
  const [chargesUI, setChargesUI] = useState(ChargesUI.defaultChargesUI(hasCoEmprunteur))
  const [loadingChargesUI, setLoadingChargesUI] = useState(true)
  useEffect(() => { chargesUI.withUpdateState(setChargesUI).withHandleStoreCharge(handleChargeFormUI) }, [chargesUI])

  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.CHARGE)
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([])

  const { data, loading: queryLoading } = useQueryRedirectError(GET_CHARGES_DOSSIER, navigate, {
    fetchPolicy: 'network-only',
    variables: { id: dossierId },
  })
  const [createCharge, { loading: createLoading, error: createError }] = useMutation(CREATE_CHARGE_DOSSIER, {
    refetchQueries: [{ query: GET_CHARGES_DOSSIER, variables: { id: dossierId } }],
  })
  const [updateCharge, { loading: updateLoading, error: updateError }] = useMutation(UPDATE_CHARGE_DOSSIER, {
    refetchQueries: [{ query: GET_CHARGES_DOSSIER, variables: { id: dossierId } }],
  })
  const [deleteCharge, { loading: deleteLoading, error: deleteError }] = useMutation(DELETE_CHARGE_DOSSIER, {
    refetchQueries: [{ query: GET_CHARGES_DOSSIER, variables: { id: dossierId } }],
  })

  const loading = loadingChargesUI || createLoading || updateLoading || deleteLoading
  const error = createError || updateError || deleteError

  const { errors, showErrors } = useValidation(EntryFormStateName.CHARGE, loading, { chargesUI })

  useEffect(() => {
    const formatChargesFromGraphql = charges => charges ? charges.map(({ __typename, ...charge }) => ChargeFormUI.buildChargeFormUI(charge)) : []
    if (!queryLoading) {
      const [emprunteurData, coEmprunteurData] = data?.partenaire_dossierEnCours?.emprunteurs || []
      const emprunteursCharges = new ChargeListUI(emprunteurId, formatChargesFromGraphql(emprunteurData?.charges))
      const coEmprunteursCharges = new ChargeListUI(coEmprunteurId, formatChargesFromGraphql(coEmprunteurData?.charges))
      setChargesUI(new ChargesUI(emprunteursCharges, coEmprunteursCharges, hasCoEmprunteur))
      setLoadingChargesUI(false)
    }
  }, [data, queryLoading])

  useEffect(() => {
    setErrorMessages((errors.globalErrors || []).map(getChargeErrors).filter(e => e))
  }, [errors])


  const removeCharge = async (index: number, removeOnEmprunteur: boolean = true) => {
    const personneId = removeOnEmprunteur ? emprunteurId : coEmprunteurId
    const id = chargesUI.delete(index, removeOnEmprunteur)
    if (id) {
      await deleteCharge({ variables: { id: dossierId, personneId, charge: { id } } })
    }
  }

  const toGraphQL = (charge: ChargeFormUI) => ({ ...charge, montant: Number(charge?.montant) })

  const toChargeFormUI = (chargeGraphQL: any, personneId: string, newId?: number): ChargeFormUI => {
    const id = newId || chargeGraphQL.id
    const { __typename: _, ...chargeData } = chargeGraphQL
    return ChargeFormUI.buildChargeFormUI({ ...chargeData, personneId, id })
  }

  const handleChargeFormUI = (personneId: string, charge: ChargeFormUI): Promise<ChargeFormUI> => charge.id ?
    updateCharge({ variables: { id: dossierId, personneId, charge: toGraphQL(charge) } }).then(() => charge) :
    createCharge({ variables: { id: dossierId, personneId, charge: toGraphQL(charge) } })
      .then(createdCharge => toChargeFormUI(charge, personneId, createdCharge.data?.partenaire_createCharge.id))

  const storeCharge = async () => {
    await chargesUI.store()
    chargesUI.refreshState()
  }

  const handleSubmit = async event => doHandleSubmit(storeCharge, event)
  const handleRemoveCharge =
    async (index: number, removeOnEmprunteur: boolean = true) => doHandleSubmit(() => removeCharge(index, removeOnEmprunteur), undefined, true)

  const value = useMemo(() => ({ loading, error, errors, errorMessages, showErrors, chargesUI, handleSubmit, handleRemoveCharge }),
    [loading, error, errors, errorMessages, showErrors, chargesUI, handleSubmit, handleRemoveCharge])

  return (
    <ChargeContext.Provider value={value}>
      {children}
    </ChargeContext.Provider>
  )
}
