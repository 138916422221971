import { CrudUI } from './CrudUI'
import { PositionableFormUI } from './PositionableFormUI'
import { sortByField } from './sorter'

export abstract class PositionUI<T extends PositionableFormUI> extends CrudUI<T> {
  private readonly fieldSorter?: (a: T, b: T) => number

  constructor(public readonly list: T[]) {
    super(list)
    this.fieldSorter = sortByField('position')
    this.list = this.computePosition(list)
  }

  public add(item: T): void {
    super.add(this.computePosition([item])[0])
  }

  public addAll(items: T[]): void {
    super.addAll(this.computePosition(items))
  }

  public getSortedList(): T[] {
    return this.list.sort(this.fieldSorter)
  }

  private computePosition(items: T[]): T[] {
    let maxPosition = this.getMaxPosition()
    const withPosition = (item: T, defaultPosition: number) => ({ ...item, position: item.position ?? defaultPosition })
    return items.sort(this.fieldSorter).map(item => withPosition(item, ++maxPosition))
  }

  private getMaxPosition(): number {
    const positions = this.list.map(p => p.position).filter(p => p !== undefined).map(p => p!!)
    return positions.length > 0 ? Math.max(...positions) : -1
  }
}
