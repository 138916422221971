import React, { useContext } from 'react'
import styles from './ToPrintDocument.module.css'
import { FormStateContext } from '../../Contexts/FormStateContext'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { AssuranceType } from '../../../model'
import { ASSURANCE_TYPE_MAP, CODE_COUVERTURE_MAP } from '../../../utils/assurance'


/**
 * Contenu de dossier à imprimer ou à télécharger
 */

function AssuranceCoEmprunteur(coEmprunteur) {
  return (
    <div className={styles.printDocument__column__answer}>
      {/* column 3 */}
      <p className={styles.printDocument__header}> Emprunteur 2</p>
      <p className={styles.printDocument__cell}>{ASSURANCE_TYPE_MAP.get(coEmprunteur.type)} </p>
      <p className={styles.printDocument__cell}>{coEmprunteur.type !== AssuranceType.SANS_ASSURANCE ? CODE_COUVERTURE_MAP.get(coEmprunteur.formule) : ''} </p>
      <p className={styles.printDocument__cell}>{coEmprunteur.type !== AssuranceType.SANS_ASSURANCE ? coEmprunteur.quotite : ''} </p>
    </div>
  )
}


export function ToPrintAssurance() {
  const formState = useContext(FormStateContext)
  const { value: assurance } = formState.getEntryFormState(EntryFormStateName.ASSURANCE)
  const emprunteur = assurance.emprunteur

  return (
    <div>
      <h1 className={styles.printDocument__headband}>
        <div className={styles.printDocument__headband__text}>
          ASSURANCE
        </div>
      </h1>
      <div className={styles.printDocument__column}>
        {/* column 1 */}
        <div className={styles.printDocument__column__titles__withEmprunteur}>
          <p className={styles.printDocument__cell}>Choix assurance</p>
          <p className={styles.printDocument__cell}>Garanties couvertures</p>
          <p className={styles.printDocument__cell}>Quotité assurée</p>
        </div>
        <div className={styles.printDocument__column__answer}>
          <div className={styles.printDocument__column__answer__text}>
            {/* column 2 */}
            <p className={styles.printDocument__header}> Emprunteur 1</p>
            <p className={styles.printDocument__cell}>{ASSURANCE_TYPE_MAP.get(emprunteur.type)}</p>
            <p className={styles.printDocument__cell}>{emprunteur.type !== AssuranceType.SANS_ASSURANCE ? CODE_COUVERTURE_MAP.get(emprunteur.formule) : ''} </p>
            <p className={styles.printDocument__cell}>{emprunteur.type !== AssuranceType.SANS_ASSURANCE ? emprunteur.quotite : ''} </p>
          </div>
        </div>
        {assurance.hasCoEmprunteur ? <AssuranceCoEmprunteur {...assurance.coEmprunteur} /> : ''}

      </div>
    </div>
  )
}
