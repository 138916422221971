import { isEqual } from 'lodash'
import { SupportStateUI } from '../../model/support'
import { EntryFormState, EntryFormStateName } from '../FormState/EntryFormState'

export interface FormStateProps {
  showErrors: boolean
  entries: EntryFormState[]
  dirtySinceLastSubmit: boolean
  submitted: boolean
}

export class FormState extends SupportStateUI {
  constructor(public readonly props: FormStateProps) {
    super()
  }

  protected clone() {
    return new FormState({ ...this.props }).withUpdateState(this.setState)
  }

  public hasErrors(): boolean {
    return !!this.props.entries.find(f => f.hasErrors)
  }

  public isLoading(): boolean {
    return this.props.entries.length === 0 ? true : this.props.entries.map(f => f.loading).find(p => p) ?? false
  }

  public update(formStateProps: FormStateProps): void {
    this.props.showErrors = formStateProps.showErrors
    this.props.entries = formStateProps.entries
    this.props.dirtySinceLastSubmit = formStateProps.dirtySinceLastSubmit
    this.props.submitted = formStateProps.submitted
    this.refreshState(new FormState(this.props).withUpdateState(this.setState))
  }

  public updateFormStateShowErrors(showErrors: boolean) {
    this.update({ ...this.props, showErrors })
  }

  public updateEntryFormStateSubmitting(name: EntryFormStateName, submitting: boolean) {
    this.updateEntry({ ...this.getEntryFormState(name), submitting })
  }

  public updateEntryFormStateShowErrors(name: EntryFormStateName, showErrors: boolean) {
    this.updateEntry({ ...this.getEntryFormState(name), showErrors })
  }

  public updateEntries(name: EntryFormStateName, value: any, errors: any, loading: boolean) {
    if (!loading) {
      const previousEntry = this.getEntryFormState(name)
      const newEntryFormState = {
        name,
        value,
        errors,
        positionFirstError: errors?.positionFirstError,
        showErrors: this.props.showErrors || previousEntry?.showErrors,
        hasErrors: errors?.hasErrors,
        loading,
        rawValue: JSON.parse(JSON.stringify(value)),
        submitting: previousEntry?.submitting,
      }
      const dirtySinceLastSubmit = !!(previousEntry?.rawValue && !isEqual(previousEntry?.rawValue, newEntryFormState.rawValue))
      this.updateEntry(newEntryFormState, { dirtySinceLastSubmit, submitted: this.props.submitted } as FormStateProps)
    }
  }

  public updateEntry(entry: EntryFormState, props?: FormStateProps) {
    this.update({
      ...this.props,
      entries: [
        entry,
        ...this.props.entries.filter(f => f.name !== entry.name),
      ],
      ...props,
    })
  }

  public getEntryFormState(name: EntryFormStateName): EntryFormState {
    return this.props.entries.find(f => f.name === name)!
  }

  public submit() {
    this.update({ ...this.props, submitted: false, dirtySinceLastSubmit: false })
  }
}
