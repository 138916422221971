import React, { useRef, useEffect } from 'react'
import classnames from 'classnames'

import { ToggleButton } from '@mmb/ui-components'
import { Select, Tooltip } from '@core/partenaire-ui'

import { useSaisie } from '../../Contexts/SaisieContext'
import {
  REGIME_MATRIMONIAL_OPTIONS,
  TYPE_INSTANCE,
  useDelayedFormBlur,
  hasErrorForName as hasError,
} from '../../../utils'

import { NombreEnfants } from './NombreEnfants'
import { SituationFamilialePlaceholder } from './SituationFamilialePlaceholder'

import { useSituationFamiliale } from './SituationFamiliale.hooks'


import styles from './SituationFamiliale.module.css'
import { SaisieErrorMessages } from '../../SaisieErrorMessages'

export function SituationFamiliale() {
  const { saisieUI: { props: { hasCoEmprunteur } } } = useSaisie()
  const {
    situationFamiliale,
    situationFamilialeUI,
    isLoading,
    errors,
    errorMessages,
    showErrors,
    addEnfant,
    removeEnfant,
    updateEnfant,
    setSituation,
    setRegimeMatrimonial,
    setTypeOfInstance,
    handleSubmit,
  } = useSituationFamiliale()
  const { enfants, situation, regimeMatrimonial, typeOfInstance } = situationFamiliale
  const hasErrorForName = name => hasError(name, errors, showErrors)
  const formRef = useRef(null)
  const { onBlur, onFocus } = useDelayedFormBlur(formRef)
  useEffect(() => {
    if (situationFamilialeUI.isSeparationDeBiens()) {
      const separationDeBiens = REGIME_MATRIMONIAL_OPTIONS.find(({ value }) => value === 'SEPARATION_DE_BIENS')?.value
      setRegimeMatrimonial(separationDeBiens)
    } else if (!situationFamilialeUI.isMarried()) {
      setRegimeMatrimonial(null)
    }
  }, [situation, hasCoEmprunteur])


  useEffect(() => {
    if (situation !== 'INSTANCE_DE_DIVORCE') {
      setTypeOfInstance(null)
    }
  }, [situation])
  return (
    <>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        data-testid="situation-familliale-form"
        className={styles.situationFamiliale}
        onBlur={onBlur}
        onFocus={onFocus}
      >
        <NombreEnfants
          enfants={enfants}
          addEnfant={addEnfant}
          removeEnfant={removeEnfant}
          updateEnfant={updateEnfant}
          loading={isLoading}
          errors={errors}
        />
        <div className={styles.situationFamiliale__row}>
          <p className={styles.situationFamiliale__label}>Situation familiale</p>

          <div className={styles.situationFamiliale__radios}>
            {isLoading
              ? <SituationFamilialePlaceholder />
              : situationFamilialeUI.getOptionsFamiliales().map(optionFamilialeUI => {
                const { title = undefined, content = undefined } = optionFamilialeUI.hasTooltip() ? optionFamilialeUI.getToolTip() : {}
                const label = (
                  <>
                    {optionFamilialeUI.option.label}
                    {optionFamilialeUI.hasTooltip() && (<Tooltip
                      wrapperClassName={styles.situationFamiliale__tooltipWrapper}
                      className={styles.situationFamiliale__tooltiplabel}
                      title={title}
                      content={(<p>{content}</p>)}
                    />)}
                  </>)

                return (
                  <ToggleButton
                    value={optionFamilialeUI.option.value}
                    label={label}
                    name="situationFamiliale.type"
                    hasError={hasErrorForName('type')}
                    className={classnames(styles.situationFamiliale__options, {
                      [styles.situationFamiliale__optionsChecked]: optionFamilialeUI.isChecked(situationFamilialeUI.situation),
                    })}
                    labelClassName={styles.situationFamiliale__labelOptions}
                    onChange={() => setSituation(optionFamilialeUI.option.value)}
                    checked={optionFamilialeUI.isChecked(situationFamilialeUI.situation)}
                    key={optionFamilialeUI.option.value}
                    aria-label={`Situation Familiale options - ${optionFamilialeUI.option.label}`}
                  />
                )
              })}
          </div>
        </div>

        {situationFamilialeUI.hasRegimeOptions(isLoading) && (
        <Select
          options={REGIME_MATRIMONIAL_OPTIONS}
          onChange={({ value }) => setRegimeMatrimonial(value)}
          name="situationFamiliale.regimeMatrimonial"
          hasError={hasErrorForName('regimeMatrimonial')}
          aria-invalid={hasErrorForName('regimeMatrimonial')}
          label="Régime matrimonial"
          className={styles.situationFamiliale__select}
          labelClassName={classnames(styles.situationFamiliale__label, styles.situationFamiliale__labelSelect)}
          fieldClassName={styles.situationFamiliale__fieldSelect}
          value={REGIME_MATRIMONIAL_OPTIONS.find(({ value }) => value === regimeMatrimonial)}
          id="regime-matrimonial"
        />
        )}

        {situationFamilialeUI.isDivorcing(isLoading) && (
        <div className={styles.situationFamiliale__row}>
          <p className={styles.situationFamiliale__label}>Type d'instance</p>
          <div className={styles.situationFamiliale__radios}>
            {TYPE_INSTANCE.map(typeInstance => {
              const isChecked = typeOfInstance === typeInstance.value
              return (
                <ToggleButton
                  {...typeInstance}
                  name="situationFamiliale.typeInstance"
                  hasError={hasErrorForName(`typeInstance.${typeInstance.value}`)}
                  className={classnames(styles.situationFamiliale__options, {
                    [styles.situationFamiliale__optionsChecked]: isChecked,
                  })}
                  onChange={({ target }) => setTypeOfInstance(target.value)}
                  checked={isChecked}
                  key={typeInstance.value}
                  value={typeInstance.value}
                  aria-label={`Situation Familiale typeInstance - ${typeInstance.label}`}
                />
              )
            })}
          </div>
        </div>
        )}
      </form>
      {showErrors && (<SaisieErrorMessages
        errors={errorMessages}
      />)}
    </>
  )
}
