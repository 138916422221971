import React, { useContext } from 'react'
import { Gamme } from '@partenaire/common'
import styles from './ToPrintDocument.module.css'
import { ToPrintSituationPersonnelle } from './ToPrintSituationPersonnelle'
import { ToPrintSituationProfessionnelle } from './ToPrintSituationProfessionnelle'
import { ToPrintFinance } from './ToPrintFinance'
import { ToPrintAnalyseComptes } from './ToPrintAnalyseComptes'
import { ToPrintEncours } from './ToPrintEncours'
import { ToPrintPatrimoine } from './ToPrintPatrimoine'
import { ToPrintAssurance } from './ToPrintAssurance'
import { ToPrintMontage } from './ToPrintMontage'
import { FormStateContext } from '../../Contexts/FormStateContext'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { ToPrintSimulation } from './ToPrintSimulation'
import { useSimulation } from '../../Contexts'

/**
 * Contenu de dossier à imprimer ou à télécharger
 */
export function ToPrintDocument() {
  const formState = useContext(FormStateContext)
  const { value: identity } = formState.getEntryFormState(EntryFormStateName.IDENTITE)

  const [simulation] = useSimulation()

  return (
    <div className={styles.printDocument}>
      <ToPrintSituationPersonnelle />
      <ToPrintSituationProfessionnelle />
      <ToPrintFinance />
      <ToPrintAnalyseComptes />
      <ToPrintEncours />
      {identity.gamme === Gamme.SECURED ? <ToPrintPatrimoine /> : <ToPrintAssurance />}
      <ToPrintMontage />
      {simulation.status !== 'NOT_STARTED' ? <ToPrintSimulation /> : ''}
    </div>
  )
}
