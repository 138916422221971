import { useEffect, useState } from 'react'
import { gql, useMutation, useQueryRedirectError } from '@core/graphql'
import { useNavigate } from '@core/routing'

import { useSaisie } from '../../Contexts'
import { useHandleSubmit, useValidation } from '../../FormState/form.state.hooks'
import { SituationFamilialeUI } from '../../../model/SituationFamilialeUI'
import { EntryFormStateName } from '../../FormState/EntryFormState'
import { ErrorMessage, buildValidationError } from '../../SaisieErrorMessages'

export const GET_SITUATION_FAMILIALE_DOSSIER = gql`
  query getSituationFamiliale ($id: ID!) {
    partenaire_dossierEnCours(id: $id) {
      id
      gamme
      situationFamiliale {
        relationId
        type
        regimeMatrimonial
        typeInstance
        enfants {
          id
          age
          parentId
        }
      }
    }
  }
`

export const UPDATE_SITUATION_FAMILIALE_DOSSIER = gql`
  mutation updateSituationFamiliale ($id: ID!, $emprunteurId: ID!, $coEmprunteurId: ID, $situationFamiliale: partenaire_SituationFamilialeInput!) {
    partenaire_updateSituationFamiliale(
      id: $id,
      emprunteurId: $emprunteurId,
      coEmprunteurId: $coEmprunteurId,
      situationFamiliale: $situationFamiliale
    ){
      relationId
    }
  }
`

const formatSituationFamiliale = ({ type, typeInstance, enfants, ...situationFamiliale }) => ({
  ...situationFamiliale,
  situation: type,
  typeOfInstance: typeInstance,
  enfants: (enfants || []).map((enfant, index) => ({ ...enfant, order: index + 1 })),
})

const removeTypeName = ({ enfants, situation, typeOfInstance, __typename: _, ...restOfSituationFamiliale }: any) => ({
  enfants: (enfants || []).map(({ __typename, ...enfant }) => enfant),
  situation,
  typeOfInstance,
  ...restOfSituationFamiliale,
})

const formatSituationFamilialeForMutation = ({ enfants, situation, typeOfInstance, errors, ...restOfSituationFamiliale }) => ({
  enfants: (enfants || []).map(({ order, ...enfant }) => enfant),
  type: situation,
  typeInstance: typeOfInstance,
  ...restOfSituationFamiliale,
})

export function useSituationFamiliale() {
  const navigate = useNavigate()
  const { saisieUI: { props: { dossierId, emprunteurId, coEmprunteurId, hasCoEmprunteur, gamme } } } = useSaisie()
  const [situationFamiliale, setSituationFamiliale] = useState<any>({})
  const [loadingSituationFamilialeUI, setLoadingSituationFamilialeUI] = useState(true)
  const [errorMessages, setErrorMessages] = useState<ErrorMessage[]>([])
  const { doHandleSubmit } = useHandleSubmit(EntryFormStateName.SITUATION_FAMILIALE)
  const [updateTimeout, setUpdateTimeout] = useState<any>()

  const updateAfterTimeout = newSituationFamiliale => {
    if (updateTimeout) {
      clearTimeout(updateTimeout)
    }
    setUpdateTimeout(setTimeout(() => {
      updateSituationFamilialeByDossierId({
        variables: {
          id: dossierId,
          emprunteurId,
          coEmprunteurId,
          situationFamiliale: formatSituationFamilialeForMutation(removeTypeName(newSituationFamiliale)),
        },
      })
    }, 1000))
  }

  const { data, loading: queryLoading } = useQueryRedirectError(
    GET_SITUATION_FAMILIALE_DOSSIER,
    navigate,
    { fetchPolicy: 'network-only', variables: { id: dossierId } },
  )
  const [
    updateSituationFamilialeByDossierId,
    { loading: mutationLoading },
  ] = useMutation(UPDATE_SITUATION_FAMILIALE_DOSSIER, {
    refetchQueries: [{ query: GET_SITUATION_FAMILIALE_DOSSIER, variables: { id: dossierId } }],
    awaitRefetchQueries: true,
  })

  const isLoading = loadingSituationFamilialeUI || mutationLoading
  const { errors, showErrors }
    = useValidation(EntryFormStateName.SITUATION_FAMILIALE, isLoading, { situationFamiliale, hasCoEmprunteur, gamme })

  useEffect(() => {
    setErrorMessages((errors?.globalErrors || []).map(error => buildValidationError({ error })))
  }, [errors])

  const changeSituationFamiliale = newSituationFamiliale => setSituationFamiliale(
    currentSituationFamiliale => (
      { ...currentSituationFamiliale, ...newSituationFamiliale }
    ))

  const updateSituationFamiliale = async () => {
    // eslint-disable-next-line camelcase
    await updateSituationFamilialeByDossierId({
      variables: {
        id: dossierId,
        emprunteurId,
        coEmprunteurId,
        situationFamiliale: formatSituationFamilialeForMutation(removeTypeName(situationFamiliale)),
      },
    })
  }

  useEffect(() => {
    if (!queryLoading) {
      const result = data?.partenaire_dossierEnCours?.situationFamiliale
      if (!result) return
      setSituationFamiliale(formatSituationFamiliale(result))
      setLoadingSituationFamilialeUI(false)
    }
  }, [data, queryLoading])

  const handleSubmit = async event => doHandleSubmit(updateSituationFamiliale, event)


  const updateEnfants = newEnfants => changeSituationFamiliale({ enfants: newEnfants })
  const defaultEnfant = { id: null, age: 1, order: 1 }

  const addEnfant = () => {
    const [lastEnfant] = situationFamiliale.enfants.slice(-1)
    if (!lastEnfant) {
      const updatedEnfants = [...situationFamiliale.enfants, defaultEnfant]
      updateEnfants(updatedEnfants)
      updateAfterTimeout({ ...situationFamiliale, enfants: updatedEnfants })
      return
    }
    const newEnfantOrder = lastEnfant.order + 1
    const updatedEnfants = [...situationFamiliale.enfants, { ...defaultEnfant, order: newEnfantOrder }]
    updateEnfants(updatedEnfants)
    updateAfterTimeout({ ...situationFamiliale, enfants: updatedEnfants })
  }

  const updateEnfant = enfant => {
    const enfantsWithoutUpdatedEnfant = situationFamiliale.enfants.filter(({ order }) => order !== enfant.order)
    updateEnfants([...enfantsWithoutUpdatedEnfant, { ...enfant, age: Number(enfant.age) }])
  }

  const removeEnfant = ({ order: enfantOrder }) => {
    const enfantsWithoutRemovedEnfant = situationFamiliale.enfants.filter(({ order }) => order !== enfantOrder)
    if (enfantsWithoutRemovedEnfant.length === 0) {
      updateEnfants([])
      updateAfterTimeout({ ...situationFamiliale, enfants: [] })
      return
    }
    const enfantsWithHigerOrder = enfantsWithoutRemovedEnfant.filter(({ order }) => order > enfantOrder).map(enfant => ({ ...enfant, order: enfant.order - 1 }))
    const enfantsWithLowerOrder = enfantsWithoutRemovedEnfant.filter(({ order }) => order < enfantOrder)
    const updatedEnfants = [...enfantsWithLowerOrder, ...enfantsWithHigerOrder]
    updateEnfants(updatedEnfants)
    updateAfterTimeout({ ...situationFamiliale, enfants: updatedEnfants })
  }

  return {
    situationFamiliale,
    situationFamilialeUI: new SituationFamilialeUI(hasCoEmprunteur as boolean,
      situationFamiliale.situation),
    errors: errors.globalErrors,
    errorMessages,
    showErrors,
    addEnfant,
    removeEnfant,
    updateEnfant,
    setSituation: newSituation => changeSituationFamiliale({ situation: newSituation }),
    setRegimeMatrimonial: newRegimeMatrimonial => changeSituationFamiliale({ regimeMatrimonial: newRegimeMatrimonial }),
    setTypeOfInstance: newTypeOfInstance => changeSituationFamiliale({ typeOfInstance: newTypeOfInstance }),
    handleSubmit,
    isLoading: loadingSituationFamilialeUI || mutationLoading,
  }
}
