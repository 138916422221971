import React from 'react'
import classnames from 'classnames'

import { Card, formatNumberCurrency } from '@mmb/ui-components'

import { useSimulation } from '../../../Contexts'
import { hasErrorForName } from '../../../../utils'

import styles from './Header.module.css'

export function Header() {
  const [{ product, simulationErrors }] = useSimulation()
  const { nom, montantFinancement, dureeFinancement, taux, regime, gamme, seuilImmo } = product
  const hasSecured = gamme === 'SECURED'
  const offreType = hasSecured ? 'Offre en hypothécaire' : 'Offre sans garantie'
  return (
    <div className={styles.header}>
      <Card
        className={classnames(
          styles.header__container,
          styles.header__container__content,
          styles.header__secured,
          { [styles.header__unsecured]: !hasSecured },
        )}
      >
        <div className={styles.header__title}>
          <span aria-label="Produit proposé par la simulation" className={styles.header__text}>{nom}</span>
          <span
            aria-label="Montant du financement proposé par la simulation"
            className={classnames(styles.header__montant, { [styles.header__row__error]: hasErrorForName('P_MONTANT_FINANCE', simulationErrors) })}
          >
            {montantFinancement && formatNumberCurrency({ number: montantFinancement })}
          </span>
        </div>
        <div className={styles.header__subTitle}>
          <span aria-label="Type de financement proposé par la simulation" className={styles.header__text__label_type}>{`${offreType} - ${regime}`}</span>
          <span aria-label="Durée de financement proposé par la simulation" className={styles.header__text__time}>{`${dureeFinancement} mois`}</span>
        </div>
      </Card>
      <div className={styles.header__container__content}>
        <Card
          className={classnames(
            styles.header__container__small,
            styles.header__container,
            { [styles.header__container__unsecured]: !hasSecured },
          )}
        >
          <span className={styles.header__container__taux}>Taux</span>
          <span className={styles.header__container__taux} aria-label="Taux du financement proposé par la simulation">{`${taux && taux.toFixed(2)} %`}</span>
        </Card>
        {hasSecured && (
          <Card className={classnames(styles.header__container__small, styles.header__container)}>
            <span>Seuil immo</span>
            <span aria-label="Seuil immobilier proposé par la simulation">{`${seuilImmo && seuilImmo.toFixed(2)} %`}</span>
          </Card>
        )}
      </div>
    </div>
  )
}
